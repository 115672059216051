import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOtsOfficialMeetHistoryByPersonId from './GetOtsOfficialMeetHistoryByPersonIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OtsOfficialMeetHistoryData = {
  INITIAL_STATE: { ...INITIAL_STATE },
  getOtsOfficialMeetHistoryByPersonId
};

export default OtsOfficialMeetHistoryData;