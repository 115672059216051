import { useEffect, useState } from 'react';

import validate from './AddNationalEvaluatorValidation';

import useNationalEvaluatorData from '../../../../state/otsNationalEvaluator/UseNationalEvaluatorData';

import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import useForm from '../../../../../common/utils/UseForm';
import { formatDateYMD } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_VIEW_STATE = {
  isSaving: false,
}

const INITIAL_FORM_STATE = {
  personSelected: false,
  person: {},
  personId: '',
  memberId: '',
  nationalEvaluatorId: Constants.DEFAULT_ID,
  officialPositionTypes: []
};

const useAddNationalEvaluator = (officialPositionTypesData, onAddCallback, gridData) => {
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const { getNationalEvaluator, addNationalEvaluator, editNationalEvaluator } = useNationalEvaluatorData();
  const {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    setFormData,
    setIsDirty,
    setErrors,
    isSubmitting } = useForm({ ...INITIAL_FORM_STATE }, submitFormCallback, validate);

  function submitFormCallback(formState) {
    if (formState.nationalEvaluatorId === Constants.DEFAULT_ID) {
      const officialPositionTypes = formState.officialPositionTypes
        .filter(x => x.nationalEvaluatorTitleId > 0)
        .map(position => {
          return {
            officialPositionTypeId: position.officialPositionTypeId,
            nationalEvaluatorTitleId: position.nationalEvaluatorTitleId,
            effectiveDate: position.effectiveDate,
            expirationDate: null
          };
        });
      const payload = { ...formState, officialPositionTypes };
      setViewState({ ...viewState, isSaving: true });
      addNationalEvaluator(payload)
        .then(data => {
          onAddCallback();
        })
        .catch(e => {
          console.log(e.message);
          setViewState({ ...viewState, isSaving: false });
        });
    }
    else {
      const officialPositionTypes = formState.officialPositionTypes
        .filter(x => x.nationalEvaluatorTitleId > 0)
        .map(position => {
          return {
            nationalEvaluatorOfficialPositionTypeId: position.nationalEvaluatorOfficialPositionTypeId,
            nationalEvaluatorId: formState.nationalEvaluatorId,
            officialPositionTypeId: position.officialPositionTypeId,
            nationalEvaluatorTitleId: position.nationalEvaluatorTitleId,
            effectiveDate: position.effectiveDate,
            expirationDate: position.expirationDate !== Constants.BLANK_DATE_STRING ? position.expirationDate : null
          };
        });
      const payload = { ...formState, officialPositionTypes };
      setViewState({ ...viewState, isSaving: true });
      editNationalEvaluator(formState.nationalEvaluatorId, payload)
        .then(data => {
          onAddCallback();
        })
        .catch(e => {
          console.log(e.message);
          setViewState({ ...viewState, isSaving: false });
        });
    }
  }

  const onTitleChanged = (officialPositionTypeId, newNationalEvaluatorTitleId, newNationalEvaluatorTitle) => {
    const officialPositionTypes = [...formState.officialPositionTypes];
    let valueUpdated = false;
    officialPositionTypes.forEach(type => {
      const nationalEvaluatorTitleId = ToIntIfInt(newNationalEvaluatorTitleId);
      if (type.officialPositionTypeId === officialPositionTypeId && type.nationalEvaluatorTitleId !== nationalEvaluatorTitleId) {
        valueUpdated = true;
        type.nationalEvaluatorTitleId = nationalEvaluatorTitleId;
        type.nationalEvaluatorTitle = newNationalEvaluatorTitle;
      }
    });

    if (valueUpdated === true) {
      onFormValueChanged('officialPositionTypes', officialPositionTypes);
    }
  };

  const onEffectiveDateChanged = (officialPositionTypeId, newEffectiveDate) => {
    const officialPositionTypes = [...formState.officialPositionTypes];
    let valueUpdated = false;
    officialPositionTypes.forEach(type => {
      if (type.officialPositionTypeId === officialPositionTypeId && type.effectiveDate !== newEffectiveDate) {
        valueUpdated = true;
        type.effectiveDate = newEffectiveDate;
      }
    });

    if (valueUpdated === true) {
      onFormValueChanged('officialPositionTypes', officialPositionTypes);
    }
  };

  const onExpirationDateChanged = (officialPositionTypeId, newExpirationDate) => {
    const officialPositionTypes = [...formState.officialPositionTypes];
    let valueUpdated = false;
    officialPositionTypes.forEach(type => {
      if (type.officialPositionTypeId === officialPositionTypeId && type.expirationDate !== newExpirationDate) {
        valueUpdated = true;
        type.expirationDate = newExpirationDate;
      }
    });

    if (valueUpdated === true) {
      onFormValueChanged('officialPositionTypes', officialPositionTypes);
    }
  };

  const onSaveClicked = () => {
    setIsDirty(true);
    handleSubmit();
  };

  useEffect(() => {
    const officialPositionTypes = officialPositionTypesData.map(x => {
      return {
        officialPositionTypeId: x.officialPositionTypeId,
        nationalEvaluatorTitleId: -1,
        nationalEvaluatorTitle: '',
        positionTypeName: x.positionTypeName,
        positionAbbreviation: x.positionAbbreviation,
        effectiveDate: '',
        displayOrder: x.displayOrder
      };
    });
    officialPositionTypes.sort((firstItem, secondItem) => firstItem.displayOrder - secondItem.displayOrder);
    setFormData({ ...formState, officialPositionTypes }, false);
  }, []);

  useEffect(() => {
    if (formState.personId !== '') {
      let existingNationalEvaluatorId = gridData?.find(x => x.personId === formState.personId)?.nationalEvaluatorId;
      if (existingNationalEvaluatorId) {
        getNationalEvaluator(existingNationalEvaluatorId)
          .then(data => {
            if (data && data.objData?.evaluatorName) {
              const dataObjDataCopy = JSON.parse(JSON.stringify(data.objData));
              const dataObjDataOfficialPositionTypesSortedLatestExpirationDateFirst = { ...dataObjDataCopy, officialPositionTypes: dataObjDataCopy.officialPositionTypes?.sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate)) };

              const newOfficialPositionTypes = formState.officialPositionTypes.map(officialPositionType => {
                const found = dataObjDataOfficialPositionTypesSortedLatestExpirationDateFirst.officialPositionTypes.find(f => f.officialPositionTypeId === officialPositionType.officialPositionTypeId);
                const newOfficialPositionType = found ? { ...found } : { ...officialPositionType };
                newOfficialPositionType.effectiveDate = formatDateYMD(newOfficialPositionType.effectiveDate);
                newOfficialPositionType.expirationDate = formatDateYMD(newOfficialPositionType.expirationDate);
                return newOfficialPositionType;
              });

              setFormData({
                ...formState,
                nationalEvaluatorId: existingNationalEvaluatorId,
                officialPositionTypes: newOfficialPositionTypes
              }, false);
            };
          });
      }
      setErrors({});
    }
  }, [formState.personId]);

  return {
    ...viewState,
    isSubmitting,
    formState,
    errorState,
    setFormData,
    onFormValueChanged,
    onTitleChanged,
    onEffectiveDateChanged,
    onExpirationDateChanged,
    onSaveClicked
  };
};

export default useAddNationalEvaluator;
