import { Fragment } from 'react';

import OtsOfficialNationalCertificationHistoryLargeWriteGrid from './OtsOfficialNationalCertificationHistoryLargeWriteGrid';
import OtsOfficialNationalCertificationHistorySmallWriteGrid from './OtsOfficialNationalCertificationHistorySmallWriteGrid';

const OtsOfficialsNationalCertificationHistoryWriteGrid = ({ gridState, state, formState, showError, onUpdateEffectiveDateValueChange, onUpdateExpirationDateValueChange }) => {
  return (
    <Fragment>
      <OtsOfficialNationalCertificationHistoryLargeWriteGrid
        gridState={gridState}
        state={state}
        formState={formState}
        showError={showError}
        onUpdateEffectiveDateValueChange={onUpdateEffectiveDateValueChange}
        onUpdateExpirationDateValueChange={onUpdateExpirationDateValueChange}
      />
      <OtsOfficialNationalCertificationHistorySmallWriteGrid
        gridState={gridState}
        state={state}
        formState={formState}
        showError={showError}
        onUpdateEffectiveDateValueChange={onUpdateEffectiveDateValueChange}
        onUpdateExpirationDateValueChange={onUpdateExpirationDateValueChange}
      />
    </Fragment>
  )
};

export default OtsOfficialsNationalCertificationHistoryWriteGrid;
