import React, { Fragment } from 'react';

import useOtsMeetEvaluations from './UseOtsMeetEvaluations';

import OtsMeetEvaluationsGridReadOnly from './OtsMeetEvaluationsGridReadOnly';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

const OtsMeetEvaluationsReadOnly = () => {
  const {
    meetEvaluationState,
    isLoading,
    isSaving,
    Constants,
    onAddEvaluation,
    deleteModalState,
    onDeleteEvaluationClicked,
    onDeleteEvaluation,
    onDeleteModalCanceled
  } = useOtsMeetEvaluations();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Evaluations</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className='col-xs-12'>
          <ActionIntraPageButton onClick={onAddEvaluation}>Add Evaluation</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <OtsMeetEvaluationsGridReadOnly
            state={meetEvaluationState}
            onDeleteEvaluationClicked={onDeleteEvaluationClicked}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={deleteModalState.modalTitle}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Official Name:</b> {deleteModalState.officialName}</p>
            <p><b>Position Worked:</b> {deleteModalState.positionTypeName}</p>
            <p><b>Evaluation Level:</b> {deleteModalState.evaluationLevelName}</p>
            <p><b>Evaluator Name:</b> {deleteModalState.evaluatorName}</p>
            <p><b>Completed?</b> {deleteModalState.completed}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteEvaluation}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  )
}

export default OtsMeetEvaluationsReadOnly;