import React, { Fragment } from 'react';

const DeleteUnattachedTransferModal = ({ modalState }) => {
  const clubFrom = `${modalState?.formState?.clubFromName} (${modalState?.formState?.clubFromCode}) `;
  const clubTo = `${modalState?.formState?.clubToName} (${modalState?.formState?.clubToCode}) `;

  return (
    <Fragment>
      <p><strong>Transfer Club From :</strong>&nbsp; {clubFrom}</p>
      <p><strong>Transfer Club To :</strong>&nbsp; {clubTo}</p>
      <p><strong>Reattach Date :</strong>&nbsp; {modalState?.formState?.reAttachDate}</p>
      <p>Are you sure you want to delete this transfer?</p>
    </Fragment>
  );
};

export default DeleteUnattachedTransferModal;