import React, { Fragment } from 'react';

import useMemberEthnicities from './UseMemberEthnicities';

import Headings from '../../../../common/components/headings/Headings';
import RadiobuttonList from '../../../../common/components/radiobuttonlists/RadiobuttonList';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import MultipleCheckbox from '../../../../common/components/checkboxes/MultipleCheckbox';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberEthnicitiesWrite = () => {
  const {
    basePersonState,
    ethnicityTypeState,
    hispanicOrLatinoTypeState,
    state,
    formState,
    errorState,
    ErrorHandle,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked,
    onCheckBoxChange,
    HISPANIC_OR_LATINO_TYPE_IDS,
  } = useMemberEthnicities();

  if (state.radiobuttonlist.length <= 0) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <Headings.H3>Member Ethnicities</Headings.H3>
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={state.radiobuttonlist.length <= 0} />
      </Fragment>
    );
  }

  const hispanicLatinoHeading = <Headings.H6>Hispanic/Latino Ethnicity</Headings.H6>
  return (
    <Fragment>
      <div>
        <form onSubmit={handleSubmit} noValidate>
          <div className="row">
            <div className="col-xs-12">
              <Headings.H3>Member Ethnicities</Headings.H3>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <RadiobuttonList
                isVertical={true}
                items={state.radiobuttonlist}
                label={hispanicLatinoHeading}
                name="hispanicOrLatinoTypeId"
                value={Number(formState.hispanicOrLatinoTypeId)}
                error={errorState.hispanicOrLatinoTypeId}
                message={errorState.hispanicOrLatinoTypeId}
                onChange={(value) => { onFormValueChanged('hispanicOrLatinoTypeId', Number(value)) }} />
            </div>

            {(formState.hispanicOrLatinoTypeId === HISPANIC_OR_LATINO_TYPE_IDS.hispanicOrLatino ||
              formState.hispanicOrLatinoTypeId === HISPANIC_OR_LATINO_TYPE_IDS.nonHispanicOrLatino)
              ? <div className="col-xs-12 col-sm-6">
                <div className="row">
                  <div className="col-xs-12">
                    <Headings.H6>Please select at least one but no more than four Races</Headings.H6>
                  </div>
                </div>
                <div className="row usas-extra-top-margin">
                  {formState.checkboxes?.map(item => (
                    <div className="col-xs-12" key={item.id}>
                      <MultipleCheckbox
                        name={item.name}
                        disabled={item.disabled}
                        checked={formState.ethnicityTypeIdArray?.includes(item.id)}
                        onChange={onCheckBoxChange}
                        label={item.label}
                        id={item.id}
                      />
                    </div>
                  ))}
                </div>
                {(state.selectedMoreThan4Error === true) ?
                  <p className={global.WarningMessage}>Warning: You may only select up to four races</p>
                  : ""}
                <ErrorHandle
                  error={errorState.ethnicityTypeId}
                  message={errorState.ethnicityTypeId}
                />
              </div>
              : ""}
          </div>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton>
            </div>
          </div>
        </form>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={basePersonState.isObjLoading || ethnicityTypeState.isArrayLoading ||
            hispanicOrLatinoTypeState.isArrayLoading} />
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.SAVING_MSG}
          displayPopUp={basePersonState.isSaving} />
      </div >
    </Fragment >
  );
};

export default MemberEthnicitiesWrite;