import React, { Fragment } from 'react';

import useOfficialsTrackingSystemMeetContextView from './UseOfficialsTrackingSystemMeetContextView';

import Headings from '../../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../../common/components/PageNotFound';
import MobileContextBasedNav from '../../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../../common/components/contextBasedNav/LargeContextBasedNav';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import global from '../../../../common/components/GlobalStyle.module.css';

import Constants from '../../../../common/utils/Constants';

const OfficialsTrackingSystemMeetContextView = ({ children }) => {
  const {
    otsMeetHeaderState,
    backToLabel,
    onBackClicked,
  } = useOfficialsTrackingSystemMeetContextView();

  if (!otsMeetHeaderState || Object.keys(otsMeetHeaderState.objData).length === 0) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              View/Edit OTS Meet
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={onBackClicked}>
            <LeftArrowIcon />  {backToLabel}
          </button>
        </div>
      </div>
      <div className={"row usas-extra-bottom-margin"}>
        <div className="col-xs-12 col-sm-12">
          <p className={global.HeaderText}><b>Meet Name:</b> {otsMeetHeaderState.objData?.meetName || ''} </p>
          {otsMeetHeaderState.objData?.meetSanctionNumber && <p className={global.HeaderText}><b>Sanction Number:</b> {otsMeetHeaderState.objData?.meetSanctionNumber || ''} </p>}
          <p className={global.HeaderText}><b>Meet Classification:</b> {otsMeetHeaderState.objData?.meetClassification || ''}</p>
          <p className={global.HeaderText}><b>Meet Dates:</b> {otsMeetHeaderState.objData?.dateRange || ''}</p>
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="OTSMeetDetail" renderPillsOnly={true} />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="OTSMeetDetail" renderPillsOnly={true} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={otsMeetHeaderState.isObjLoading} />
      {children}
    </Fragment>
  )
};

export default OfficialsTrackingSystemMeetContextView;