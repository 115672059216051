import { useState } from 'react';

import OrgRoleFieldData from './OrgRoleFieldData';

const useOrgRoleFieldData = () => {
  const [orgRoleFieldState, setOrgRoleFieldState] = useState(OrgRoleFieldData.INITIAL_STATE);

  const getOrgRoleFields = (orgRoleId) => OrgRoleFieldData.getOrgRoleFields(orgRoleId, orgRoleFieldState, setOrgRoleFieldState);

  const clearArrayData = () => {
    setOrgRoleFieldState({
      ...orgRoleFieldState,
      arrayData: [],
      isArrayLoaded: false,
      isArrayLoading: false
    });
  };

  return {
    orgRoleFieldState,
    getOrgRoleFields,
    clearArrayData
  };
};

export default useOrgRoleFieldData;