import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../../common/wrappers/ReactRouterDom';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import OfficialMentorLevelContextProvider from '../../../state/officialMentorLevel/OfficialMentorLevelContextProvider';
import OfficialLscCertificationContextProvider from '../../../state/officialLscCertification/OfficialLscCertificationContextProvider';
import SearchOtsOfficialContextProvider from '../../../state/searchOfficialsTrackingSystemOfficial/SearchOtsOfficialContextProvider';
import SearchOtsOfficialFilterContextProvider from '../../../state/searchOfficialsTrackingSystemOfficial/SearchOtsOfficialFilterContextProvider';
import OtsOfficialLscContextProvider from '../../../state/officialsTrackingSystemOfficialLsc/OtsOfficialLscContextProvider';
import OtsOfficialHeaderContextProvider from '../../../state/officialsTrackingSystemOfficialLsc/OtsOfficialHeaderContextProvider';
import OfficialPositionContextProvider from '../../../state/officialPosition/OfficialPositionContextProvider';
import OfficialNationalCertificationContextProvider from '../../../state/officialNationalCertification/OfficialNationalCertificationContextProvider';
import OtsOfficialNationalCertificationContextProvider from '../../../state/officialsTrackingSystemOfficialNationalCertification/OtsOfficialNationalCertificationContextProvider';
import OfficialPositionTypeContextProvider from '../../../state/officialPositionType/OfficialPositionTypeContextProvider';
import CertificationLevelContextProvider from '../../../state/certificationLevel/CertificationLevelContextProvider';
import PersonPositionCertificationContextProvider from '../../../state/personPositionCertification/PersonPositionCertificationContextProvider';
import MeetEvaluationPersonOfficialContextProvider from '../../../state/otsMeetEvaluationsPersonOfficial/MeetEvaluationPersonOfficialContextProvider';
import OfficialEvaluationContextProvider from '../../../state/otsOfficialEvaluations/OfficialEvaluationsContextProvider';
import OtsCertificationApplicationContextProvider from '../../../state/otsCertificationApplication/OtsCertificationApplicationContextProvider';
import OtsCertificationApplicationContextStateContextProvider from '../../../state/otsCertificationApplication/OtsCertificationApplicationContextStateContextProvider';

import SelectOrgUnitContextProvider from '../../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import PersonGeneralContextProvider from '../../../../common/state/personGeneral/PersonGeneralContextProvider';
import BasePersonContextProvider from '../../../../common/state/personBase/BasePersonContextProvider';
import BasePersonFiltersContextProvider from '../../../../common/state/personBase/BasePersonFiltersContextProvider';

import OfficialsTrackingSystemOfficialContextView from './OfficialsTrackingSystemOfficialContextView';
import OtsOfficialsSearch from './otsOfficialSearch/OtsOfficialsSearch';
import OtsOfficialLscCertificationWrite from './otsOfficialLscCertification/OtsOfficialLscCertificationWrite';
import OtsOfficialLscCertificationRead from './otsOfficialLscCertification/OtsOfficialLscCertificationRead';
import OtsOfficialNationalCertificationHistory from './otsOfficialNationalCertificationHistory/OtsOfficialNationalCertificationHistory';
import OtsOfficialNationalCertificationHistoryRead from './otsOfficialNationalCertificationHistory/OtsOfficialNationalCertificationHistoryRead';
import OtsOfficialNationalCertificationApplicationHistory from './otsOfficialNationalCertificationApplicationHistory/OtsOfficialNationalCertificationApplicationHistory';
import OtsOfficialNationalCertificationApplication from './otsOfficialNationalCertificationApplication/OtsOfficialNationalCertificationApplication';
import Status from './otsOfficialNationalCertificationApplicationHistory/status/Status';
import OtsOfficialMeetHistoryWrite from './otsOfficialMeetHistory/OtsOfficialMeetHistoryWrite';
import OtsOfficialMeetHistoryRead from './otsOfficialMeetHistory/OtsOfficialMeetHistoryRead';
import OtsOfficialEvaluations from './otsOfficialEvaluations/OtsOfficialEvaluations';
import OtsOfficialMeetEvaluationForm from './otsOfficialEvaluations/OtsOfficialMeetEvaluationForm';
import OtsOfficialOnlineTests from './otsOfficialOnlineTests/OtsOfficialOnlineTests';
import OtsOfficialClinics from './otsOfficialClinics/OtsOfficialClinics';
import OtsOfficialLscCertificationRecertify from './otsOfficialLscCertification/OtsOfficialLscCertificationRecertify';
import OtsOfficialLscCertificationDetail from './otsOfficialLscCertification/OtsOfficialLscCertificationDetail';
import OtsOfficialSelection from './otsOfficialSelection/OtsOfficialSelection';
import OtsOfficialReporting from './otsOfficialReporting/OtsOfficialReporting';

import LoadingModal from '../../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../../common/components/security/SecuredRouteElement';

const ContextRoutes = ({ navRoutes }) => (
  <OfficialsTrackingSystemOfficialContextView>
    <Routes>
      <Route path={navRoutes['OTS_OFFICIAL_LSCCERTIFICATIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_LSCCERTIFICATIONS']}
            editElement={<OtsOfficialLscCertificationWrite />}
            readOnlyElement={<OtsOfficialLscCertificationRead />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_MEETHISTORY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_MEETHISTORY']}
            editElement={<OtsOfficialMeetHistoryWrite />}
            readOnlyElement={<OtsOfficialMeetHistoryRead />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_LSCCERTIFICATIONS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_LSCCERTIFICATIONS_DETAIL']}
            editElement={<OtsOfficialLscCertificationDetail />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_LSCCERTIFICATIONS_RECERTIFY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_LSCCERTIFICATIONS_RECERTIFY']}
            editElement={<OtsOfficialLscCertificationRecertify />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_NATIONALCERTIFICATIONHISTORY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_NATIONALCERTIFICATIONHISTORY']}
            editElement={<OtsOfficialNationalCertificationHistory />}
            readOnlyElement={<OtsOfficialNationalCertificationHistoryRead />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_NATIONALCERTIFICATIONAPPLICATIONHISTORY']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_NATIONALCERTIFICATIONAPPLICATIONHISTORY']}
            editElement={<OtsOfficialNationalCertificationApplicationHistory />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_NATIONALCERTIFICATIONAPPLICATIONHISTORY_STATUS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_NATIONALCERTIFICATIONAPPLICATIONHISTORY_STATUS']}
            editElement={<Status />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_NATIONALCERTIFICATIONAPPLICATION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_NATIONALCERTIFICATIONAPPLICATION']}
            editElement={<OtsOfficialNationalCertificationApplication />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_ONLINETESTS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_ONLINETESTS']}
            editElement={<OtsOfficialOnlineTests />}
            readOnlyElement={<OtsOfficialOnlineTests />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_CLINICS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_CLINICS']}
            editElement={<OtsOfficialClinics/>}
            readOnlyElement={<OtsOfficialClinics />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_EVALUATIONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_EVALUATIONS']}
            editElement={<OtsOfficialEvaluations />}
          />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_EVALUATIONS_FORM']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_EVALUATIONS_FORM']}
            editElement={<OtsOfficialMeetEvaluationForm />}
          />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_REPORTING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_REPORTING']}
            editElement={<OtsOfficialReporting />} />
        }
      />
    </Routes>
  </OfficialsTrackingSystemOfficialContextView>

);

const OfficialsTrackingSystemOfficialRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['OTS_OFFICIAL_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_SEARCH']}
            editElement={<OtsOfficialsSearch />} />
        }
      />
      <Route path={navRoutes['OTS_OFFICIAL_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_SELECTION']}
            editElement={<OtsOfficialSelection />} />
        }
      />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['OTS_OFFICIAL_SEARCH']?.route} replace />} />
    </Routes>

  );
}

const OfficialsTrackingSystemOfficial = () => (
  <OtsCertificationApplicationContextProvider>
    <OtsCertificationApplicationContextStateContextProvider>
      <OfficialEvaluationContextProvider>
        <MeetEvaluationPersonOfficialContextProvider>
          <PersonGeneralContextProvider>
            <PersonOrgRoleContextProvider>
              <SelectOrgUnitContextProvider>
                <SearchOtsOfficialContextProvider>
                  <SearchOtsOfficialFilterContextProvider>
                    <OfficialNationalCertificationContextProvider>
                      <OfficialMentorLevelContextProvider>
                        <OtsOfficialLscContextProvider>
                          <OtsOfficialNationalCertificationContextProvider>
                            <OtsOfficialHeaderContextProvider>
                              <OfficialLscCertificationContextProvider>
                                <PersonPositionCertificationContextProvider>
                                  <OfficialPositionContextProvider>
                                    <OfficialPositionTypeContextProvider>
                                      <CertificationLevelContextProvider>
                                        <BasePersonContextProvider>
                                          <BasePersonFiltersContextProvider>
                                            <OfficialsTrackingSystemOfficialRoutes />
                                          </BasePersonFiltersContextProvider>
                                        </BasePersonContextProvider>
                                      </CertificationLevelContextProvider>
                                    </OfficialPositionTypeContextProvider>
                                  </OfficialPositionContextProvider>
                                </PersonPositionCertificationContextProvider>
                              </OfficialLscCertificationContextProvider>
                            </OtsOfficialHeaderContextProvider>
                          </OtsOfficialNationalCertificationContextProvider>
                        </OtsOfficialLscContextProvider>
                      </OfficialMentorLevelContextProvider>
                    </OfficialNationalCertificationContextProvider>
                  </SearchOtsOfficialFilterContextProvider>
                </SearchOtsOfficialContextProvider>
              </SelectOrgUnitContextProvider>
            </PersonOrgRoleContextProvider>
          </PersonGeneralContextProvider>
        </MeetEvaluationPersonOfficialContextProvider>
      </OfficialEvaluationContextProvider>
    </OtsCertificationApplicationContextStateContextProvider>
  </OtsCertificationApplicationContextProvider>
);

export default OfficialsTrackingSystemOfficial;