import React, { Fragment } from 'react';

import useProfileGroups from './UseProfileGroups';

import ProfileGroupsGrid from './ProfileGroupsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const ProfileGroups = () => {
  const {
    isLoading,
    gridData,
    isGridLoading,
    onApplyClicked,
    INVITE_STATUS
  } = useProfileGroups();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Groups</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <ProfileGroupsGrid
            data={gridData}
            isLoading={isGridLoading}
            onApplyClicked={onApplyClicked}
            INVITE_STATUS={INVITE_STATUS} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  );
};

export default ProfileGroups;