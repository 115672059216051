import { useState } from 'react';

import PutMemberInGoodStandingRefreshData from './PutMemberInGoodStandingRefreshData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_REFRESH_STATE = {
  ...BASIC_INITIAL_STATE
};

const useMemberInGoodStandingRefreshData = () => {
  const [refreshState, setRefreshState] = useState(INITIAL_REFRESH_STATE);

  const putMemberInGoodStandingRefresh = (personId) => PutMemberInGoodStandingRefreshData(personId, refreshState, setRefreshState);

  return {
    refreshState,
    putMemberInGoodStandingRefresh
  };
};

export default useMemberInGoodStandingRefreshData;