import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRowIcons = ({ sponsor, onAddEdit, onDelete }) => (
  <Fragment>
    <button className={global.IconButton} type="button" onClick={(e) =>
      onDelete(e, sponsor.personSponsorId, `${sponsor.sponsor?.sponsorName} (Effective Date: ${formatDate(sponsor.effectiveDate)}, Expiration Date: ${formatDate(sponsor.expirationDate)})`)}>
      <DeleteIcon />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onAddEdit(e, sponsor.personSponsorId)}>
      <EditIcon />
    </button>
  </Fragment>
);

const GridRow = ({ sponsor, onAddEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{sponsor.sponsor?.sponsorName || ''}</span>&nbsp;
      <GridRowIcons sponsor={sponsor} onAddEdit={onAddEdit} onDelete={onDelete} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Sponsor Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {sponsor.sponsor?.sponsorName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Sponsor Type
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {sponsor.sponsor?.sponsorType?.typeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {sponsor.effectiveDate ? formatDate(sponsor.effectiveDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {sponsor.expirationDate ? formatDate(sponsor.expirationDate) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const NationalTeamSponsorsSmallGrid = ({ gridState, onAddEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {gridState.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {gridState.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Sponsors</div>
            }
          </div>
        </div>
      ) : Array.isArray(gridState.objData.sponsors)
        && gridState.objData.sponsors.length > 0
        ? gridState.objData.sponsors.map((sponsor, i) => <GridRow key={i} sponsor={sponsor} onAddEdit={onAddEdit} onDelete={onDelete} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Sponsors</div>
            </div>
          </div>
        )
    }
  </div>
);

export default NationalTeamSponsorsSmallGrid;