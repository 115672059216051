import { useEffect, useState } from 'react';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import Constants from '../../../../common/utils/Constants';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';
import useNationalTeamAttachmentFilesData from '../../../state/nationalTeamAttachmentFiles/UseNationalTeamAttachmentFilesData';

const INITIAL_REMOVE_FILE_MODAL_STATE = {
  displayPopUp: false,
  fileName: '',
  modalTitle: 'Remove Uploaded File?'
};

const INITIAL_FILE_STATE = {
  attachmentUrl: '',
  attachmentDescription: '',
  attachmentName: '',
  personNationalAttachmentFileTypeId: -1,
  personNationalAttachmentFileTypeName: '',
  personNationalAttachmentId: -1
}

function getInitialRemoveFileModalState() {
  return {
    displayPopUp: false,
    fileName: '',
    modalTitle: 'Remove Uploaded File?'
  }
};

const useMemberPassportUpload = () => {
  const { basePersonState } = useBasePersonData();
  const {
    nationalTeamAttachmentFilesState,
    putNationalTeamAttachmentFiles,
    GET_MEMBER_FILES_NATIONAL_TEAM_ATTACHMENT_PATH
  } = useNationalTeamAttachmentFilesData();

  const {
    personNationalTeamAttachmentState,
    postPersonNationalTeamAttachment,
    getPersonNationalTeamAttachmentsByPersonId,
    deletePersonNationalTeamAttachmentById
  } = usePersonNationalTeamData();

  const [uiState, setUiState] = useState({ passportVisible: false });
  const [fileState, setFileState] = useState(INITIAL_FILE_STATE);
  const [removeFileModalState, setRemoveFileModalState] = useState(INITIAL_REMOVE_FILE_MODAL_STATE);
  const [state, setState] = useState({ error: '' });
  const [previousFileState, setPreviousFileState] = useState({previousFileUrl: ''});

  useEffect(() => {
    if (basePersonState?.isObjLoading === false && basePersonState?.isObjLoaded === true) {
      getPersonNationalTeamAttachmentsByPersonId(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  function createPostObject() {
    const personId = basePersonState?.objData?.personId;
    let postObj = {};
    postObj = {
      attachmentDescription: "Passport Copy", 
      attachmentName: "Passport", 
      attachmentUrl: nationalTeamAttachmentFilesState.objData.uploadUrl, 
      personNationalAttachmentFileTypeId: 3, 
      personNationalAttachmentFileTypeName: "Passport Copy", 
      personId: personId
    };
    return postObj;
  }

  useEffect(() => {
    if (
      nationalTeamAttachmentFilesState?.isSaving === false && 
      nationalTeamAttachmentFilesState?.isSaved === true 
    ) {
      const postObj = createPostObject();

      if (postObj.attachmentUrl.includes("Bad Request") === false) {
        postPersonNationalTeamAttachment(createPostObject());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamAttachmentFilesState]);


  useEffect(() => {
    if (personNationalTeamAttachmentState?.isArrayLoading === false &&
      personNationalTeamAttachmentState?.isArrayLoaded === true &&
      personNationalTeamAttachmentState?.arrayData.length > 0 &&
      personNationalTeamAttachmentState?.isObjLoaded === false
    ) {
      const passport = personNationalTeamAttachmentState?.arrayData.findLast(x => x.personNationalAttachmentFileTypeId === 3);

      setFileState({
        ...fileState,
        attachmentUrl: passport === undefined ? "" : passport.attachmentUrl,
        attachmentDescription: passport === undefined ? "" : passport.attachmentDescription,
        attachmentName: passport === undefined ? "" : passport.attachmentName,
        personNationalAttachmentFileTypeId: passport === undefined ? -1 : passport.personNationalAttachmentFileTypeId,
        personNationalAttachmentFileTypeName: passport === undefined ? "" : passport.personNationalAttachmentFileTypeName,
        personNationalAttachmentId: passport === undefined ? -1 : passport.personNationalAttachmentId
      });
    } else if (personNationalTeamAttachmentState.isObjLoaded) {
      setFileState({
        ...fileState,
        attachmentUrl: personNationalTeamAttachmentState.objData.attachmentUrl,
        attachmentDescription: personNationalTeamAttachmentState.objData.attachmentDescription,
        attachmentName: personNationalTeamAttachmentState.objData.attachmentName,
        personNationalAttachmentFileTypeId: personNationalTeamAttachmentState.objData.personNationalAttachmentFileTypeId,
        personNationalAttachmentFileTypeName: personNationalTeamAttachmentState.objData.personNationalAttachmentFileTypeName,
        personNationalAttachmentId: personNationalTeamAttachmentState.objData.personNationalAttachmentId
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personNationalTeamAttachmentState]);

  const onUploadPassport = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };
    if (personNationalTeamAttachmentState.isSaving === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_JPG) {
          putNationalTeamAttachmentFiles(file, previousFileState.previousFileUrl);
          setState({ ...state, error: '' });
        }
        else {
          setState({ ...state, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  }

  const onViewSamplePassport = () => {
    setUiState({ passportVisible: !uiState.passportVisible })
  }

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...removeFileModalState, fileName: fileState.attachmentUrl, displayPopUp: true });
  }

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...INITIAL_REMOVE_FILE_MODAL_STATE });
  };

  const onRemovePassportClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
    if (personNationalTeamAttachmentState.isSaving === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, error: '' });
      setPreviousFileState({...previousFileState, previousFileUrl: fileState.attachmentUrl});
      setFileState({ ...fileState, attachmentUrl: '' });

      deletePersonNationalTeamAttachmentById(fileState.personNationalAttachmentId);
    }
  }

  const onDownloadUploadedFile = (event) => {
    event.preventDefault();
    const fileUrl = GET_MEMBER_FILES_NATIONAL_TEAM_ATTACHMENT_PATH + fileState.attachmentUrl;
    PersonHttpHelper(fileUrl, 'GET', null, false, true)
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...state,
          message: message
        });
      });
  };

  function createTestPostObject() {
    const personId = basePersonState?.objData?.personId;
    let postObj = {};
    postObj = {
      attachmentDescription: "Passport Copy", 
      attachmentName: "Passport", 
      attachmentUrl: "https://devswimsmemberdocs.blob.core.windows.net/national-team-attachments/20240503T211250883.AntiDopingCourseWireframe.pdf", 
      personNationalAttachmentFileTypeId: 3, 
      personNationalAttachmentFileTypeName: "Passport Copy", 
      personId: personId
    };
    return postObj;
  }

  const onTestApi = () => {
    postPersonNationalTeamAttachment(createTestPostObject());
  };

  return {
    uiState,
    removeFileModalState,
    personNationalTeamAttachmentState,
    fileState,
    onUploadPassport,
    onViewSamplePassport,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onRemovePassportClicked,
    onDownloadUploadedFile,
    onTestApi
  }
}

export default useMemberPassportUpload;