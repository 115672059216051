import LargeGrid from './LargeGrid';
import SmallGrid from './SmallGrid';

const Grid = ({ gridData, onSelectClicked }) => {
  return (
    <>
      <LargeGrid
        gridData={gridData}
        onSelectClicked={onSelectClicked} />
      <SmallGrid
        gridData={gridData}
        onSelectClicked={onSelectClicked} />
    </ >
  )
};

export default Grid;
