import { useState } from "react";

import OfferingInstanceCouponDiscountsData from "./OfferingInstanceCouponDiscountsData";

const useOfferingInstanceCouponDiscountsData = () => {
  const [offeringInstanceCouponDiscountsState, setOfferingInstanceCouponDiscountsState] = useState(OfferingInstanceCouponDiscountsData.INITIAL_OFFERING_INSTANCE_COUPON_DISCOUNTS_STATE);

  const getOfferingInstanceCouponDiscounts = (orgUnitId) => {
    return OfferingInstanceCouponDiscountsData.getOfferingInstanceCouponDiscountsData(orgUnitId, offeringInstanceCouponDiscountsState, setOfferingInstanceCouponDiscountsState);
  };

  const putOfferingInstanceCouponDiscount = (offeringInstanceCouponDiscountId, offeringInstanceCouponDiscountObj) => {
    return OfferingInstanceCouponDiscountsData.putOfferingInstanceCouponDiscountData(offeringInstanceCouponDiscountId, offeringInstanceCouponDiscountObj, offeringInstanceCouponDiscountsState, setOfferingInstanceCouponDiscountsState);
  };

  return {
    offeringInstanceCouponDiscountsState,

    getOfferingInstanceCouponDiscounts,
    putOfferingInstanceCouponDiscount
  };
};

export default useOfferingInstanceCouponDiscountsData;