import { useState } from 'react';

import GetMemberCoachesData from './GetMemberCoachesData';

const INITIAL_STATE = {
  arrayData: [],
  isArrayLoading: false,
  isArrayLoaded: false,
  message: ''
};

const useMemberCoachesData = () => {
  const [memberCoachesState, setMemberCoachesState] = useState(INITIAL_STATE);
  const getMemberCoaches = (personId) => GetMemberCoachesData(personId, memberCoachesState, setMemberCoachesState); 

  return {
    memberCoachesState,
    getMemberCoaches
  };
};

export default useMemberCoachesData;