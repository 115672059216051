import { Fragment } from 'react';

import useProfileCoachSearch from './UseProfileCoachSearch';

import ProfileCoachSearchGrid from './ProfileCoachSearchGrid';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';

import Constants from '../../../../common/utils/Constants';

const MiniForm = ({ formState, errorState, onFormValueChanged, handleSubmit, resetForm, onCancelClicked }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Legal First Name or Preferred Name"
          name="firstName"
          value={formState.firstName}
          error={errorState.firstName}
          message={errorState.firstName}
          onChange={(value) => { onFormValueChanged('firstName', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Legal Last Name or Maiden Name*"
          name="lastName"
          value={formState.lastName}
          error={errorState.lastName}
          message={errorState.lastName}
          onChange={(value) => { onFormValueChanged('lastName', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker
          label="Birth Date"
          name="birthDate"
          value={formState.birthDate}
          error={errorState.birthDate}
          message={errorState.birthDate}
          onChange={(value) => { onFormValueChanged('birthDate', value); }} />
      </div>
    </div>
    {(errorState.filter || errorState.errorOnSubmitAction) &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>
    }
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Search for Coach</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={resetForm}>Clear Form</SecondaryButton>&nbsp;
        <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
      </div>
    </div>
  </form>
);

const ProfileCoachSearch = () => {
  const {
    formState,
    errorState,
    coachSearchState,
    onAddCoach,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked,
    onClearFormClicked
  } = useProfileCoachSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Coach Search</Headings.H3>
        </div>
      </div>
      <MiniForm
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged}
        handleSubmit={handleSubmit}
        resetForm={onClearFormClicked}
        onCancelClicked={onCancelClicked}
      />
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <ProfileCoachSearchGrid
            data={coachSearchState.arrayData}
            isLoading={coachSearchState.isArrayLoading === true}
            onAdd={onAddCoach}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={coachSearchState.isArrayLoading} />
    </Fragment>
  );
};

export default ProfileCoachSearch;