import { useEffect } from 'react';

import validate from './LscSearchMemberValidation';

import useBasePersonData from '../../../state/personBase/UseBasePersonData';
import useSearchMemberData from '../../../state/searchMember/UseSearchMemberData';
import useLscData from '../../../state/lsc/UseLscData';
import useOrgRoleHierarchyData from '../../../state/orgRoleHierarchy/UseOrgRoleHierarchyData';
import useEnvironmentVariableData from '../../../state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from '../../../state/security/UseSecurityData';

import useStatesCombobox from '../../comboboxes/statesCombobox/UseStatesCombobox';

import useForm from '../../../utils/UseForm';
import Constants from '../../../utils/Constants';
import HierarchicalDataUtils from '../../../utils/HierarchicalDataUtils';
import useActiveClubsData from '../../../state/activeClubs/UseActiveClubsData';

const competitionGenderOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Male", value: 1 },
  { id: 2, name: "Female", value: 2 }];

const isCurrentOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Current", value: true },
  { id: 2, name: "Expired", value: false }];

const sortByOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "First Name (A-Z)", value: 'FirstName' },
  { id: 2, name: "Last Name (A-Z)", value: 'LastName' },
  { id: 4, name: "Competition Category (Female then Male)", value: 'Gender' },
  { id: 5, name: "Birth Date (Oldest to Youngest)", value: 'BirthDate' },
  { id: 6, name: "Member Type - Member Role", value: 'RoleName' },
  { id: 7, name: "Organization (A-Z)", value: 'ClubCode' }];

const useLscSearchMember = (initialFormState, configuredRoleId = Constants.DEFAULT_ID) => {
  const { basePersonState, basePersonFiltersState, searchPersons } = useBasePersonData();
  const { searchMemberState, setSearchMemberState } = useSearchMemberData();
  const { lscState } = useLscData();
  const { activeClubsState } = useActiveClubsData();
  const { orgRoleHierarchyState } = useOrgRoleHierarchyData();
  const { getStateNameById } = useStatesCombobox();
  const { contextSecurityState } = useSecurityData();
  const { environmentVariableState, USAS_ORGANIZATION_ID, USAS_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors, onValueTextPairChanged,
    setIsDirty, isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onFiltersToggle = () => {
    setSearchMemberState({ ...searchMemberState, showFilters: !searchMemberState.showFilters });
    setIsDirty(true);
  };

  const onShowHideAdvancedSearch = () => {
    setFormState({
      ...formState,
      city: '',
      state: '',
      stateName: '',
      zip: '',
      phoneNumber: Constants.DEFAULT_PHONE_NUMBER_AREA_CODE,
      orgRole: [],
      isCurrentId: Constants.DEFAULT_ID,
      isCurrentName: '',
      memberId: '',
      email: ''
    });
    setSearchMemberState({
      ...searchMemberState,
      showAdvanced: !searchMemberState.showAdvanced
    });
  };

  useEffect(() => {
    if (formState.lscOrgUnitId === Constants.DEFAULT_ID && formState.orgUnitLabel !== Constants.USAS_ORG_UNIT_LABEL_LSC) {
      setFormState({
        ...formState,
        orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL_LSC
      });
    }
    else if (formState.lscOrgUnitId !== Constants.DEFAULT_ID && formState.orgUnitLabel !== Constants.USAS_ORG_UNIT_LABEL) {
      setFormState({
        ...formState,
        orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.lscOrgUnitId]);

  //Sets initial form state once
  useEffect(() => {
    if (lscState.isLoaded === true && environmentVariableState.isLoaded === true) {
      setFormState(getInitialFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lscState.isLoaded, environmentVariableState.isLoaded]);

  useEffect(() => {
    if (Object.keys(basePersonFiltersState.filterObject).length > 0 &&
      lscState.isLoaded === true &&
      configuredRoleId === Constants.DEFAULT_ID) {
      const oldSearchValues = JSON.parse(JSON.stringify(basePersonFiltersState.filterObject));
      let isCurrentIndex = undefined;
      for (let i = 0; i < isCurrentOptions.length; i++) {
        if (basePersonFiltersState.filterObject.isCurrent === isCurrentOptions[i].value) {
          isCurrentIndex = i;
          break;
        }
      }

      let sortByIndex = undefined;
      for (let i = 0; i < sortByOptions.length; i++) {
        if (basePersonFiltersState.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }

      const orgUnitIdIsAnLsc = oldSearchValues.orgUnitId ? lscState.data.findIndex(x => x.orgUnitId === oldSearchValues.orgUnitId) > -1 ? true : false : false;
  
      setFormState({
        ...formState,
        ...oldSearchValues,
        lscName: oldSearchValues.orgUnitId && orgUnitIdIsAnLsc === true ? lscState.data.find(x => x.orgUnitId === oldSearchValues.orgUnitId)?.orgUnitName || '' : oldSearchValues.lscOrgUnitId ? lscState.data.find(x => x.orgUnitId === oldSearchValues.lscOrgUnitId)?.orgUnitName || '' : '',
        lscOrgUnitId: oldSearchValues.orgUnitId && orgUnitIdIsAnLsc === true ? oldSearchValues.orgUnitId : oldSearchValues.lscOrgUnitId ? oldSearchValues.lscOrgUnitId : Constants.DEFAULT_ID,
        orgUnitName: oldSearchValues.orgUnitId && orgUnitIdIsAnLsc === false ? oldSearchValues.orgUnitName || '' : '',
        orgUnitId: oldSearchValues.orgUnitId && orgUnitIdIsAnLsc === false ? oldSearchValues.orgUnitId : '',
        stateName: oldSearchValues.state ? getStateNameById(oldSearchValues.state) : '',
        orgRole: oldSearchValues.roleId ? HierarchicalDataUtils.GetNameIdPairs(orgRoleHierarchyState.treeData, [oldSearchValues.roleId]) : [],
        isCurrentId: isCurrentIndex !== undefined ? isCurrentOptions[isCurrentIndex].id : isCurrentOptions[0].id,
        isCurrentName: isCurrentIndex !== undefined ? isCurrentOptions[isCurrentIndex].name : isCurrentOptions[0].name,
        nonOrganizationMember: oldSearchValues.nonOrganizationMember ?? false,
        sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
        sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
      });
      setIsDirty(true);
      if (basePersonState.isArrayLoading === false) {
        searchPersons(basePersonFiltersState.filterObject, basePersonFiltersState.sortBy, basePersonFiltersState.isAdvancedSearch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lscState.isLoaded]);

  useEffect(() => {
    if (searchMemberState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMemberState.showFilters, isDirty]);

  useEffect(() => {
    if (formState.orgUnitId === USAS_ORG_UNIT_ID) {
      setFormState({ ...formState, orgUnitId: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.memberId]);

  // Called by useForm after passing validation, makes API call and shows results grid
  function submitFormCallback(formState) {
    setSearchMemberState({ ...searchMemberState, tryRedirect: false });

    let sortBy = undefined;
    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    if (basePersonState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchPersons(filterObject, sortBy || '', searchMemberState.showAdvanced);
    }
  };

  // Builds a filter object to be used during API GET request
  function createSearchFilterObj(formState) {
    //set role id that is passed in through the <MiniSearchMember component if populated
    const roleIdFromFormState = formState.orgRole.length > 0 ? formState.orgRole[0].id : undefined;
    const roleId = roleIdFromFormState === undefined && configuredRoleId > 0 ? configuredRoleId : roleIdFromFormState;
    return {
      orgUnitId: formState.orgUnitId ? formState.orgUnitId : formState.lscOrgUnitId !== Constants.DEFAULT_ID ? formState.lscOrgUnitId : USAS_ORG_UNIT_ID,
      orgUnitName: formState.orgUnitId ? formState.orgUnitName : undefined,
      lscOrgUnitId: formState.orgUnitId && formState.lscOrgUnitId !== Constants.DEFAULT_ID ? formState.lscOrgUnitId : undefined,
      birthDate: formState.birthDate !== Constants.BLANK_DATE_STRING ? formState.birthDate : undefined,
      firstName: formState.firstName.trim() || undefined,
      middleName: formState.middleName.trim() || undefined,
      lastName: formState.lastName.trim() || undefined,
      competitionGenderTypeId: formState.competitionGenderTypeId > 0 ? formState.competitionGenderTypeId : undefined,
      city: formState.city.trim() || undefined,
      state: formState.stateName !== '' ? formState.state : undefined,
      zip: formState.zip.trim() || undefined,
      memberId: formState.memberId.trim() || undefined,
      email: formState.email.trim() || undefined,
      phoneNumber: formState.phoneNumber.trim() !== '' && !Constants.PHONE_AREA_CODES.find(x => x === formState.phoneNumber.trim()) ? formState.phoneNumber.trim() : undefined,
      roleId: roleId,
      nonOrganizationMember: formState.nonOrganizationMember === true ? formState.nonOrganizationMember : undefined,
      isCurrent: formState.isCurrentId > 0 ? isCurrentOptions.find(x => x.id === formState.isCurrentId).value : undefined
    };
  };

  // Holds initial formState values
  function getInitialFormState() {
    return {
      USAS_ORG_UNIT_ID: USAS_ORG_UNIT_ID,
      lscOrgUnitId: Constants.DEFAULT_ID,
      lscName: '',
      orgUnitId: '',
      orgUnitName: '',
      orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL_LSC,
      birthDate: Constants.BLANK_DATE_STRING,
      firstName: '',
      middleName: '',
      lastName: '',
      city: '',
      state: '',
      stateName: '',
      zip: '',
      memberId: '',
      email: '',
      phoneNumber: Constants.DEFAULT_PHONE_NUMBER_AREA_CODE,
      competitionGenderTypeId: competitionGenderOptions[0].id,
      competitionGenderTypeName: competitionGenderOptions[0].name,
      orgRole: [],
      isCurrentId: isCurrentOptions[0].id,
      isCurrentName: isCurrentOptions[0].name,
      nonOrganizationMember: false,
      sortById: sortByOptions[0].id,
      sortByName: sortByOptions[0].name,
      isLoading: basePersonState.isArrayLoading || basePersonState.isObjLoading ||
        lscState.isLoading || activeClubsState.isArrayLoading || contextSecurityState.isObjLoading,
      ...initialFormState
    };
  };

  return {
    basePersonState,
    searchMemberState,
    setSearchMemberState,
    lscState,
    activeClubsState,
    formState,
    errorState,
    onValueTextPairChanged,
    onFormValueChanged: updateFormState,
    handleSubmit,
    clearForm,
    onFiltersToggle,
    onShowHideAdvancedSearch,
    competitionGenderOptions,
    isCurrentOptions,
    sortByOptions,
    contextSecurityState,
    USAS_ORGANIZATION_ID
  };
}

export default useLscSearchMember;