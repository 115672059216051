import { useContext } from 'react';

import { MeetEvaluationPersonStateContext } from './MeetEvaluationPersonContextProvider';

import MeetEvaluationPersonData from './MeetEvaluationPersonData';

const useMeetEvaluationPersonData = () => {
  const [meetEvaluationPersonState, setMeetEvaluationPersonState] = useContext(MeetEvaluationPersonStateContext);

  const getMeetEvaluationsByPersonId = (personId) => MeetEvaluationPersonData.getMeetEvaluationsByPersonIdData(personId, meetEvaluationPersonState, setMeetEvaluationPersonState);
  const postMeetEvaluation = (otsMeetId, meetEvaluationObj) => MeetEvaluationPersonData.postMeetEvaluationData(otsMeetId, meetEvaluationObj, meetEvaluationPersonState, setMeetEvaluationPersonState);
  const putMeetEvaluation = (meetEvaluationId, meetEvaluationObj) => MeetEvaluationPersonData.putMeetEvaluationData(meetEvaluationId, meetEvaluationObj, meetEvaluationPersonState, setMeetEvaluationPersonState);
  const deleteMeetEvaluation = (meetEvaluationId) => MeetEvaluationPersonData.deleteMeetEvaluationData(meetEvaluationId, meetEvaluationPersonState, setMeetEvaluationPersonState);

  const clearMeetEvaluationPersonObjData = () => {
    setMeetEvaluationPersonState({
      ...meetEvaluationPersonState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearMeetEvaluationPersonArrayData = () => {
    setMeetEvaluationPersonState({
      ...meetEvaluationPersonState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmMeetEvaluationPersonSave = () => {
    setMeetEvaluationPersonState({
      ...meetEvaluationPersonState,
      isSaved: false
    });
  };

  return {
    meetEvaluationPersonState,
    getMeetEvaluationsByPersonId,
    postMeetEvaluation,
    putMeetEvaluation,
    deleteMeetEvaluation,
    clearMeetEvaluationPersonObjData,
    clearMeetEvaluationPersonArrayData,
    confirmMeetEvaluationPersonSave
  };
};

export default useMeetEvaluationPersonData;