import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatPhoneNumber } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRowIcons = ({ role, onAddEdit }) => (
  <Fragment>
    <button className={global.IconButton} type="button" onClick={(e) => onAddEdit(e, role.nationalTeamSupportStaffId, role.nationalTeamSupportStaffTypeId)}>
      <EditIcon />
    </button>
  </Fragment>
);

const DetailGridRow = ({ role, onAddEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{role.supportStaffType?.typeName || ''}</span>&nbsp;
      <DetailGridRowIcons role={role} onAddEdit={onAddEdit} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Staff Role
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {role.supportStaffType?.typeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.effectiveDate && formatDate(role.effectiveDate) !== Constants.DEFAULT_MIN_DATE ? formatDate(role.effectiveDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.expirationDate && formatDate(role.expirationDate) !== Constants.DEFAULT_MAX_DATE ? formatDate(role.expirationDate) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ staff, onAddEdit }) => (
  <div className={global.SmallTableDetailTable}>
    {staff.nationalTeamSupportStaffType.map((role, i) =>
      <DetailGridRow key={i} role={role} onAddEdit={onAddEdit} />)}
  </div>
);

const GridRowIcons = ({ staff, expandedId, onDelete, onAddStaffType, onExpandClicked }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, staff.nationalTeamSupportStaffId)}>
      {expandedId === staff.nationalTeamSupportStaffId ? <HideIcon /> : <ShowIcon />}
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
      onDelete(e, staff.nationalTeamSupportStaffId, `${staff.firstName} ${staff.lastName} (Email: ${staff.email}, Phone Number: ${formatPhoneNumber(staff.phoneNumber)})`)}>
      <DeleteIcon />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onAddStaffType(e, staff.nationalTeamSupportStaffId)}>
      <PlusIcon toolTipText="Add Staff Role" />
    </button>
  </Fragment>
);

const GridRow = ({ staff, expandedId, onAddEdit, onDelete, onAddStaffType, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{staff.firstName || ''} {staff.lastName || ''}</span>&nbsp;
        <GridRowIcons staff={staff} expandedId={expandedId} onDelete={onDelete} onAddStaffType={onAddStaffType} onExpandClicked={onExpandClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {staff.firstName || staff.lastName ? `${staff.firstName || ''} ${staff.lastName || ''}` : <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Email
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {staff.email || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Phone Number
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {staff.phoneNumber ? formatPhoneNumber(staff.phoneNumber) : <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    {Array.isArray(staff.nationalTeamSupportStaffType) && staff.nationalTeamSupportStaffType.length > 0 && expandedId === staff.nationalTeamSupportStaffId
      && <DetailGrid staff={staff} onAddEdit={onAddEdit} />
    }
  </Fragment>
);

const NationalTeamSupportStaffSmallGrid = ({ gridState, expandedId, onAddEdit, onDelete, onAddStaffType, onExpandClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {gridState.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {gridState.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Support Staff</div>}
          </div>
        </div>
      ) : Array.isArray(gridState.objData.supportStaffMembers)
        && gridState.objData.supportStaffMembers.length > 0
        ? gridState.objData.supportStaffMembers.map((staff, i) => (
          <GridRow
            key={i}
            expandedId={expandedId}
            staff={staff}
            onAddEdit={onAddEdit}
            onDelete={onDelete}
            onAddStaffType={onAddStaffType}
            onExpandClicked={onExpandClicked}
          />)
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Support Staff</div>
            </div>
          </div>
        )
    }
  </div>
);

export default NationalTeamSupportStaffSmallGrid;