import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOfficialNationalCertificationByOrgUnitId from './GetOfficialNationalCertificationByOrgUnitIdData';


const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  options: []
};

const OfficialLscCertificationData = {
  INITIAL_STATE,
  getOfficialNationalCertificationByOrgUnitId
}

export default OfficialLscCertificationData;