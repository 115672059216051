import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import UseForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatTimeForDisplay, formatTimeForFilterObject } from '../../../../common/utils/TimesUtils';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';

import validate from './ProfileGoalsDetailValidation';

const useProfileGoalsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personProfileState, putPersonProfile } = usePersonProfileData();
  const { formState, errorState, setIsDirty, onValueTextPairChanged, handleSubmit, setFormData
  } = UseForm(getInitialFormState, submitFormCallback, validate);
  const [dupModalState, setDupModalState] = useState(getInitialDupModalState);

  const onCancelClicked = () => {
    navigate(navRoutes.MEMBER_PROFILE_GOALS?.route);
  };

  const onModalSaveClicked = () => {
    putPersonProfile(personProfileState.objData.personId,
      createPersonObj(personProfileState.objData, { ...formState, personGoalId: dupModalState.dupPersonGoalId }));
  };

  const onModalCancelClicked = () => {
    setDupModalState(getInitialDupModalState);
    setIsDirty(true);
  };

  useEffect(() => {
    if (location.state?.personGoalId > 0 && Array.isArray(personProfileState.objData?.goals)) {
      const targetGoal = personProfileState.objData.goals.find(x => x.personGoalId === location.state.personGoalId);

      if (targetGoal) {
        setFormData({
          ...formState,
          personGoalId: targetGoal.personGoalId || Constants.DEFAULT_ID,
          eventId: targetGoal.eventId || Constants.DEFAULT_ID,
          eventName: targetGoal.event?.eventName || '',
          goalTime: targetGoal.goalTime ? formatTimeForDisplay(targetGoal.goalTime) : '',
          goalTimeValue: targetGoal.goalTime ? formatTimeForFilterObject(targetGoal.goalTime) : ''
        });
        setIsDirty(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (personProfileState.isSaving === false && personProfileState.isSaved === true) {
      navigate(navRoutes.MEMBER_PROFILE_GOALS?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState]);

  function submitFormCallback(formState) {
    if (formState.personGoalId === Constants.DEFAULT_ID) {
      const dupGoal = personProfileState.objData.goals.find(x => x.eventId === formState.eventId);

      if (dupGoal) {
        setDupModalState({
          ...dupModalState,
          displayPopUp: true,
          currentGoalTime: dupGoal.goalTime,
          dupPersonGoalId: dupGoal.personGoalId
        });
      } else {
        putPersonProfile(personProfileState.objData.personId, createPersonObj(personProfileState.objData, formState));
      }
    } else {
      putPersonProfile(personProfileState.objData.personId, createPersonObj(personProfileState.objData, formState));
    }
  };

  function createPersonObj(profileObj, formState) {
    const newProfileObj = JSON.parse(JSON.stringify(profileObj));

    if (formState.personGoalId > 0 && Array.isArray(newProfileObj.goals)) {
      const targetGoal = newProfileObj.goals.find(x => x.personGoalId === formState.personGoalId);

      if (targetGoal) {
        targetGoal.goalTime = formatTimeForFilterObject(formState.goalTimeValue);
      }
    } else if (Array.isArray(newProfileObj.goals)) {
      newProfileObj.goals.push({
        personId: profileObj.personId,
        eventId: formState.eventId,
        goalTime: formatTimeForFilterObject(formState.goalTimeValue)
      });
    }

    return newProfileObj;
  };

  function getInitialFormState() {
    return {
      personGoalId: Constants.DEFAULT_ID,
      eventId: Constants.DEFAULT_ID,
      eventName: '',
      goalTime: '',
      goalTimeValue: ''
    };
  };

  function getInitialDupModalState() {
    return {
      displayPopUp: false,
      currentGoalTime: '',
      dupPersonGoalId: Constants.DEFAULT_ID
    };
  };

  return {
    isSaving: personProfileState.isSaving,
    formState,
    errorState,
    dupModalState,
    onValueTextPairChanged,
    handleSubmit,
    onModalSaveClicked,
    onModalCancelClicked,
    onCancelClicked
  };
};

export default useProfileGoalsDetail;