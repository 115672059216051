import React, { Fragment } from 'react';

import OtsMeetOfficialCombobox from '../../../../components/comboboxes/otsMeetOfficialCombobox/OtsMeetOfficialCombobox';
import OtsPositionWorkedCombobox from '../../../../components/comboboxes/otsPositionWorkedCombobox/OtsPositionWorkedCombobox';

import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRowContent = ({ i, otsMeetHeaderState, official, showError, otsPositionWorkedEvaluationLevelEvaluatorNameState, onUpdateOfficialNameValueChange, onUpdateOfficialPositionWorkedValueChange, onUpdateEvaluatorNameValueChange, onUpdateEvaluationLevelValueChange }) => {
  let otsPositionWorkedEvaluationLevelEvaluatorNameStateForOfficialPosition = otsPositionWorkedEvaluationLevelEvaluatorNameState.arrayData.find(x => x.officialPositionTypeId === official.selectedOfficialPositionTypeId);
  let stateForEvaluationLevelOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
  otsPositionWorkedEvaluationLevelEvaluatorNameStateForOfficialPosition?.evaluationLevel?.forEach(x => {
    stateForEvaluationLevelOptions.push({ id: x.evaluationLevelId, name: x.evaluationLevelName });
  });
  let stateForEvaluatorNameOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
  otsPositionWorkedEvaluationLevelEvaluatorNameStateForOfficialPosition?.evaluator?.forEach(x => {
    stateForEvaluatorNameOptions.push({ id: x.meetEvaluatorId, name: x.evaluatorName });
  });
  return (
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-12 col-sm-3', global.SmallTableRowData].join(' ')}>
          <OtsMeetOfficialCombobox
            label="Official Name"
            name={"selectedMeetOfficialId" + i}
            valueToMatch={official?.selectedMeetOfficialName}
            onChange={(newValue, newValueLabel) => { onUpdateOfficialNameValueChange(i, newValue, newValueLabel); }}
            otsMeetId={otsMeetHeaderState?.objData?.otsMeetId}
            error={official?.selectedMeetOfficialId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
              official?.selectedEvaluationLevelId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? true : false}
            message={official?.selectedMeetOfficialId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
              official?.selectedEvaluationLevelId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? 'Please select an Official Name' : ''}
          />
        </div>
        <div className={['col-xs-12 col-sm-3', global.SmallTableRowData].join(' ')}>
          <OtsPositionWorkedCombobox
            label="Position Worked"
            name={"selectedOfficialPositionTypeId" + i}
            valueToMatch={official?.selectedOfficialPositionTypeName}
            onChange={(newValue, newValueLabel) => { onUpdateOfficialPositionWorkedValueChange(i, newValue, newValueLabel); }}
            otsMeetId={otsMeetHeaderState?.objData?.otsMeetId}
            error={official?.selectedOfficialPositionTypeId < 0 && (official?.selectedMeetOfficialId > 0 ||
              official?.selectedEvaluationLevelId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? true : false}
            message={official?.selectedOfficialPositionTypeId < 0 && (official?.selectedMeetOfficialId > 0 ||
              official?.selectedEvaluationLevelId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? 'Please select a Position Worked' : ''}
          />
        </div>
        <div className={['col-xs-12 col-sm-3', global.SmallTableRowData].join(' ')}>
          <Dropdown
            label="Evaluation Level"
            options={stateForEvaluationLevelOptions}
            name={"selectedEvaluationLevelId" + i}
            value={official?.selectedEvaluationLevelId}
            onChange={(newValue, newValueLabel, e) => { onUpdateEvaluationLevelValueChange(e, i, newValue, newValueLabel); }}
            areValuesIntegers={true}
            error={official?.selectedEvaluationLevelId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
              official?.selectedMeetOfficialId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? true : false}
            message={official?.selectedEvaluationLevelId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
              official?.selectedMeetOfficialId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? 'Please select an Evaluation Level' : ''} />
        </div>
        <div className={['col-xs-12 col-sm-3', global.SmallTableRowData].join(' ')}>
          <Dropdown
            label="Evaluator Name"
            options={stateForEvaluatorNameOptions}
            name={"selectedMeetEvaluatorId" + i}
            value={official?.selectedMeetEvaluatorId}
            onChange={(value, valueLabel, e) => { onUpdateEvaluatorNameValueChange(e, i, value, valueLabel); }}
            areValuesIntegers={true}
            error={official?.selectedMeetEvaluatorId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
              official?.selectedMeetOfficialId > 0 || official?.selectedEvaluationLevelId > 0) && showError === true ? true : false}
            message={official?.selectedMeetEvaluatorId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
              official?.selectedMeetOfficialId > 0 || official?.selectedEvaluationLevelId > 0) && showError === true ? 'Please select an Evaluator Name' : ''} />
        </div>
      </div>
    </div>
  );
}

const GridRow = ({ index, otsMeetHeaderState, official, showError, otsPositionWorkedEvaluationLevelEvaluatorNameState, onUpdateOfficialNameValueChange, onUpdateOfficialPositionWorkedValueChange, onUpdateEvaluatorNameValueChange, onUpdateEvaluationLevelValueChange }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span></span>&nbsp;
        </div>
        <GridRowContent
          i={index}
          otsMeetHeaderState={otsMeetHeaderState}
          showError={showError}
          official={official}
          otsPositionWorkedEvaluationLevelEvaluatorNameState={otsPositionWorkedEvaluationLevelEvaluatorNameState}
          onUpdateOfficialNameValueChange={onUpdateOfficialNameValueChange}
          onUpdateOfficialPositionWorkedValueChange={onUpdateOfficialPositionWorkedValueChange}
          onUpdateEvaluatorNameValueChange={onUpdateEvaluatorNameValueChange}
          onUpdateEvaluationLevelValueChange={onUpdateEvaluationLevelValueChange} />
      </div>
    </Fragment>
  );
}
const OfficialSelectionForEvaluationOverrideGridSmall = ({ state, otsMeetHeaderState, gridState, showError, otsPositionWorkedEvaluationLevelEvaluatorNameState, onUpdateOfficialNameValueChange, onUpdateOfficialPositionWorkedValueChange,
  onUpdateEvaluatorNameValueChange, onUpdateEvaluationLevelValueChange }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (<div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          &nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          {state.isArrayLoading === true
            ? <div className={global.SmallTableRowLabels}>Loading...</div>
            : <div className={global.SmallTableRowLabels}>No Results</div>}
        </div>
      </div>)
      : Array.isArray(gridState?.arrayData) === true
        && gridState?.arrayData?.length > 0
        ? gridState?.arrayData?.map((official, i) =>
          <GridRow
            key={i}
            index={i}
            otsMeetHeaderState={otsMeetHeaderState}
            showError={showError}
            official={official}
            otsPositionWorkedEvaluationLevelEvaluatorNameState={otsPositionWorkedEvaluationLevelEvaluatorNameState}
            onUpdateOfficialNameValueChange={onUpdateOfficialNameValueChange}
            onUpdateOfficialPositionWorkedValueChange={onUpdateOfficialPositionWorkedValueChange}
            onUpdateEvaluatorNameValueChange={onUpdateEvaluatorNameValueChange}
            onUpdateEvaluationLevelValueChange={onUpdateEvaluationLevelValueChange} />)
        : (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Results</div>
          </div>
        </div>)
    }
  </div>
);

export default OfficialSelectionForEvaluationOverrideGridSmall;