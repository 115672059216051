import React, { createContext, useState } from 'react';

import CoachTypeData from './CoachTypeData';

export const CoachTypeStateContext = createContext();

const CoachTypeContextProvider = ({ children }) => {
  const stateHook = useState(CoachTypeData.INITIAL_STATE);

  return (
    <CoachTypeStateContext.Provider value={stateHook}>
      {children}
    </CoachTypeStateContext.Provider>
  );
};

export default CoachTypeContextProvider;