import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetEvaluationsOfficialByPersonIdData from './GetMeetEvaluationsOfficialByPersonIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetEvaluationPersonOfficialData = {
  INITIAL_STATE, 
  getMeetEvaluationsOfficialByPersonIdData
};

export default MeetEvaluationPersonOfficialData;