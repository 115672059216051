import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import Constants from '../../../../common/utils/Constants';
import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

const TITLE = 'Missing Drug Tests';

const useNationalTeamMissedDrugTests = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personNationalTeamState, getPersonNationalTeam } = usePersonNationalTeamData();

  const onAddEditMissedDrugTests = (e, personMissingDrugTestId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_MISSING_DRUG_TESTS_DETAIL?.route, { state: { personMissingDrugTestId } });
  }

  useEffect(() => {
    if (personNationalTeamState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personNationalTeamState.objData)) {
      getPersonNationalTeam(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  return {
    title: TITLE,
    DEFAULT_ID: Constants.DEFAULT_ID,
    personNationalTeamState,
    onAddEditMissedDrugTests
  }
}

export default useNationalTeamMissedDrugTests;