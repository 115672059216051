import React from 'react';

import useAffiliationsTreeView from './UseAffiliationsTreeView';

import TreeView from '../../../common/components/tree/TreeView';

import global from '../../../common/components/GlobalStyle.module.css';

const AffiliationsTreeView = ({ label, name, value, error, message, onChange }) => {
  const { orgUnitState, affiliationsState } = useAffiliationsTreeView();

  return orgUnitState.message
    ? <span className={global.LoadingMsg}>{orgUnitState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={orgUnitState.isArrayLoading}
      options={affiliationsState}
      selectableLeavesOnly={true}
      singleSelect={true}
      onChange={onChange} />
};

export default AffiliationsTreeView;