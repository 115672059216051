
import { Fragment } from 'react';

import useLSCReportingHQSelection from './UseLSCReportingHQSelection';

import LscDropdown from '../../../../common/components/dropdowns/lscDropdown/LscDropdown';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import Headings from '../../../../common/components/headings/Headings';

const LSCReportingHQSelection = () => {
    const { handleSubmit, formState, errorState, onValueTextPairChanged } = useLSCReportingHQSelection();
    return (
        <Fragment>
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <Headings.H3>HQ LSC Reports</Headings.H3>
                    </div>
                </div>
                <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                    <div className="col-xs-12">
                        <Headings.H6>Make a Selection</Headings.H6>
                    </div>
                </div>
                <form onSubmit={handleSubmit} noValidate>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <LscDropdown
                                label='LSC*'
                                name="orgUnitId"
                                valueToMatch={formState.orgUnitName}
                                error={errorState.orgUnitId}
                                message={errorState.orgUnitId}
                                onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'orgUnitId', newValueLabel, 'orgUnitName'); }} />
                        </div>
                    </div>
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <PrimaryButton type="submit">Continue</PrimaryButton>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
};

export default LSCReportingHQSelection;