import { useContext} from 'react';

import { PersonPositionCertificationStateContext } from './PersonPositionCertificationContextProvider';

import PersonPositionCertificationData from './PersonPositionCertificationData';

const usePersonPositionCertificationData = () => {
  const [personPositionCertificationState, setPersonPositionCertificationState] = useContext(PersonPositionCertificationStateContext);

  const postPersonPositionCertificationCertification = (certificationObj) => PersonPositionCertificationData.postPersonPositionCertificationCertificationData(certificationObj, personPositionCertificationState, setPersonPositionCertificationState)

  const putPersonPositionCertification = (personPositionCertificationId, certificationObj) => PersonPositionCertificationData.putPersonPositionCertificationData(personPositionCertificationId, certificationObj, personPositionCertificationState, setPersonPositionCertificationState);

  const deletePersonPositionCertification = (personPositionCertificationId)=> PersonPositionCertificationData.deletePersonPositionCertificationData(personPositionCertificationId, personPositionCertificationState, setPersonPositionCertificationState);

  const clearPersonPositionCertificationObjData = () => {
    setPersonPositionCertificationState({
      ...personPositionCertificationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearPersonPositionCertificationArrayData = () => {
    setPersonPositionCertificationState({
      ...personPositionCertificationState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const resetPersonPositionCertificationState = () => {
    setPersonPositionCertificationState(PersonPositionCertificationData?.INITIAL_STATE);
  };

  const confirmPersonPositionCertificationSave = () => {
    setPersonPositionCertificationState({
      ...personPositionCertificationState,
      isSaved: false
    });
  };

  return {
    personPositionCertificationState,
    clearPersonPositionCertificationObjData,
    clearPersonPositionCertificationArrayData,
    confirmPersonPositionCertificationSave,
    postPersonPositionCertificationCertification,
    putPersonPositionCertification,
    resetPersonPositionCertificationState,
    deletePersonPositionCertification
  };
};

export default usePersonPositionCertificationData;