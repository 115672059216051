import React from 'react';

import QuickAddAthlete from './quickAddAthlete/QuickAddAthlete';

import PersonGeneralContextProvider from '../../../common/state/personGeneral/PersonGeneralContextProvider';
import OrgUnitAthleteContextProvider from '../../../common/state/orgUnitAthlete/OrgUnitAthleteContextProvider';

const QuickAdd = () => (
  <PersonGeneralContextProvider>
    <OrgUnitAthleteContextProvider>
      <QuickAddAthlete />
    </OrgUnitAthleteContextProvider>
  </PersonGeneralContextProvider>
);

export default QuickAdd;