import { useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import Constants from '../../../../common/utils/Constants';

const INITIAL_MODAL_STATE = {
  personIneligibleReasonId: Constants.DEFAULT_ID,
  ineligibleToCompeteReason: '',
  displayPopUp: false
};

const useMemberIneligibleToCompete = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState, putBasePerson } = useBasePersonData();
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onAddIneligibilityClicked = () => {
    navigate(navRoutes.MEMBER_INELIGIBLE_TO_COMPETE_DETAIL?.route);
  };

  const onEditIneligibilityClicked = (personIneligibleReasonId) => {
    const personIneligibleReason = basePersonState.objData?.usasPerson?.personIneligibleReason.find(e => e.personIneligibleReasonId === personIneligibleReasonId);

    if (personIneligibleReason) {
      navigate(navRoutes.MEMBER_INELIGIBLE_TO_COMPETE_DETAIL?.route, { state: { personIneligibleReason } });
    }
  };

  const onDeleteIneligibilityClicked = (personIneligibleToCompeteId, ineligibleToCompeteReason) => {
    setModalState({
      ...modalState,
      personIneligibleReasonId: personIneligibleToCompeteId,
      ineligibleToCompeteReason: ineligibleToCompeteReason,
      displayPopUp: true
    });
  };

  const onModalConfirmClicked = () => {
    const basePersonObj = JSON.parse(JSON.stringify(basePersonState.objData));
    const index = basePersonObj.usasPerson?.personIneligibleReason.findIndex(e => e.personIneligibleReasonId === modalState.personIneligibleReasonId);

    if (index >= 0) {
      basePersonObj.usasPerson?.personIneligibleReason.splice(index, 1);

      putBasePerson(basePersonState.objData.personId, basePersonObj);
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onModalCancelClicked = () => {
    setModalState(INITIAL_MODAL_STATE);
  };

  return {
    isLoading: basePersonState.isObjLoading,
    isSaving: basePersonState.isSaving,
    gridData: basePersonState.objData?.usasPerson?.personIneligibleReason || [],
    modalState,
    onAddIneligibilityClicked,
    onEditIneligibilityClicked,
    onDeleteIneligibilityClicked,
    onModalConfirmClicked,
    onModalCancelClicked
  };
};

export default useMemberIneligibleToCompete;