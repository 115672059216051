import Checkbox from '../../../../../common/components/checkboxes/Checkbox';
import NotInGoodStandingIcon from '../../../../../common/components/icons/NotInGoodStandingIcon';
import FileExclamationIcon from '../../../../../common/components/icons/FileExclamationIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

const MeetOfficialsOfficialSelectionSmallGrid = ({ passedInMeetEndDate, gridData, isLoading, onCheckboxChange }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : gridData.length > 0
        ? gridData.map((official, i) => {
          const meetEndDate = new Date(passedInMeetEndDate);
          const expirationDateForRegistration = new Date(official?.registrationExpirationDate);
          const expirationDateForApt = new Date(official?.aptExpirationDate);
          const expirationDateForBgc = new Date(official?.bgcExpirationDate);
          const expirationDateForCpt = new Date(official?.cptExpirationDate);
          return (
            <div className={global.SmallTableRow} key={official.personId + i}>
              <div className={global.SmallTableRowHead}><span className='hidden-xs'>{official.isInGoodStanding === false ? <span style={{ color: 'black' }}>{official.firstName || ''} {official.lastName || ''}{official.memberId ? ' - ' + official.memberId : ''} <NotInGoodStandingIcon /></span> : <span>{official.firstName || ''} {official.lastName || ''}{official.memberId ? ' - ' + official.memberId : ''}</span>}</span>&nbsp;
                <div className={global.CheckboxMobile}>
                  <Checkbox
                    className={global.IconButton}
                    label=""
                    name="isSelected"
                    onChange={(e) => { onCheckboxChange(e, official.personId) }}
                    checked={official.isSelected} /></div>
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{official.isInGoodStanding === false ? <span style={{ color: 'red' }}>{official.firstName || ''} {official.lastName || ''}  <NotInGoodStandingIcon /></span> : <span>{official.firstName || ''} {official.lastName || ''}</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Member ID</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{official.memberId || ''}</div>
                  <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>LSC / Club</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{official.lscCode || <span>&nbsp;</span>} / {official.clubCode || <span>&nbsp;</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 hidden-xs'].join(' ')}>Registration Expires</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Reg. Expires</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{expirationDateForRegistration < meetEndDate
                    ? <span style={{ color: 'red' }}>{official.registrationExpirationDate ? formatDate(official.registrationExpirationDate) === Constants.DEFAULT_MAX_DATE
                      ? 'No Expiration' : formatDate(official.registrationExpirationDate) : <span>No Registration</span>}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.registrationExpirationDate ? 'Registration Expired' : 'No Registration'} /></span> :
                    <span>{official.registrationExpirationDate ? formatDate(official.registrationExpirationDate) === Constants.DEFAULT_MAX_DATE
                      ? 'No Expiration' : formatDate(official.registrationExpirationDate) : <span>&nbsp;</span>}</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>APT Expires</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{expirationDateForApt < meetEndDate
                    ? <span style={{ color: 'red' }}>{official.aptExpirationDate ? formatDate(official.aptExpirationDate) === Constants.DEFAULT_MAX_DATE
                      ? 'No Expiration' : formatDate(official.aptExpirationDate) : <span>Not Completed</span>}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.aptExpirationDate ? 'Course Expired' : 'Not Completed'} /></span> :
                    <span>{official.aptExpirationDate ? formatDate(official.aptExpirationDate) === Constants.DEFAULT_MAX_DATE
                      ? 'No Expiration' : formatDate(official.aptExpirationDate) : <span>&nbsp;</span>}</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>BGC Expires</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{expirationDateForBgc < meetEndDate
                    ? <span style={{ color: 'red' }}>{official.bgcExpirationDate ? formatDate(official.bgcExpirationDate) === Constants.DEFAULT_MAX_DATE
                      ? 'No Expiration' : formatDate(official.bgcExpirationDate) : <span>Not Completed</span>}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.bgcExpirationDate ? 'BGC Expired' : 'Not Completed'} /></span> :
                    <span>{official.bgcExpirationDate ? formatDate(official.bgcExpirationDate) === Constants.DEFAULT_MAX_DATE
                      ? 'No Expiration' : formatDate(official.bgcExpirationDate) : <span>&nbsp;</span>}</span>}</div>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>CPT Expires</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{expirationDateForCpt < meetEndDate
                    ? <span style={{ color: 'red' }}>{official.cptExpirationDate ? formatDate(official.cptExpirationDate) === Constants.DEFAULT_MAX_DATE
                      ? 'No Expiration' : formatDate(official.cptExpirationDate) : <span>Not Completed</span>}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.cptExpirationDate ? 'Course Expired' : 'Not Completed'} /></span> :
                    <span>{official.cptExpirationDate ? formatDate(official.cptExpirationDate) === Constants.DEFAULT_MAX_DATE
                      ? 'No Expiration' : formatDate(official.cptExpirationDate) : <span>&nbsp;</span>}</span>}</div>
                </div>
              </div>
            </div>
          )
        }) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div >
);

export default MeetOfficialsOfficialSelectionSmallGrid;