import { useContext, useState } from 'react';

import OtsOfficialPositionTypeData from './OtsOfficialPositionTypeData';

const useOtsOfficialPositionTypeData = () => {
  const [ officialPositionTypeState, setOfficialPositionTypeState ] = useState({ ...OtsOfficialPositionTypeData.INITIAL_STATE });

  const getAvailableOfficialPositionTypes = () => {
    return OtsOfficialPositionTypeData
      .getAvailableOfficialPositionTypes(officialPositionTypeState, setOfficialPositionTypeState);
  };

  return {
    officialPositionTypeState,
    getAvailableOfficialPositionTypes
  };
};

export default useOtsOfficialPositionTypeData;