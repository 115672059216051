import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../../common/wrappers/ReactRouterDom';

import useOfferingManagementData from '../../../../../state/offeringManagement/UseOfferingManagementData';

import useNavRoutes from '../../../../../../common/state/security/UseNavRoutes';

const INITIAL_STATE = {
  reportPeriodObj: {},
  pageErrorMessage: ''
};

const useHqOfferingInstanceAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { offeringManagementInstanceState, postOfferingInstance } = useOfferingManagementData();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmitOfferingInstanceForm = (newOfferingInstanceObj) => {
    const postOfferingInstancePromise = postOfferingInstance(newOfferingInstanceObj);

    if (postOfferingInstancePromise ?? false) {
      postOfferingInstancePromise.then((newState) => {
        if (newState ?? false) {
          navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE?.route)
        }
      }).catch((e) => {
        setState({
          ...state,
          pageErrorMessage: e?.message || 'Failed to add offering'
        })
      });
    }
  };

  const onBackClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE?.route)
  }

  useEffect(() => {
    if (location.state?.reportPeriodId && location.state?.periodName) {
      setState({
        ...state,
        reportPeriodObj: {
          reportPeriodId: location.state.reportPeriodId,
          periodName: location.state.periodName
        }
      });
    } else {
      setState({
        ...state,
        pageErrorMessage: 'Failed to load report period'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSaving: offeringManagementInstanceState.isObjLoading,
    state,
    onSubmitOfferingInstanceForm,
    onBackClicked
  };
};

export default useHqOfferingInstanceAdd;