import { Fragment } from 'react';

import useOtsOfficialLscBulkRecertify from './UseOtsOfficialLscBulkRecertify';
import OtsOfficialsLscBulkRecertifyGrid from './grids/OtsOfficialLscBulkRecertifyGrid';

import OfficialPositionTypeDropdown from '../../../../components/dropdowns/officialPositionTypeDropdown/OfficialPositionTypeDropdown';
import CertificationLevelDropdown from '../../../../components/dropdowns/certificationLevelDropdown/CertificationLevelDropdown';

import Headings from '../../../../../common/components/headings/Headings';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';
import LoadingModal from '../../../../../common/components/dialogs/LoadingModal';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsOfficialLscBulkRecertify = () => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    onSendFilterInfoToGetGridData,
    onClearFilterInfo,
    searchOtsOfficialsForBulkRecertifyState,
    isLoading,
    notLoaded,
    onAddOrRemoveLscCertficationForBulkRecertify,
    recertifyBulkState,
    onNavigateToLscBulkRecertifyDetailView,
    onSelectAll,
    onUnSelectAll,
    countOfYears,
    startYearOffset,
    LSC_ORG_LEVEL_ID,
    state,
    onErrorCanceled,
    onOfficialHistoryReportLoadClick
  } = useOtsOfficialLscBulkRecertify();

  return (
    <Fragment>
      <div className='row'>
        <div className="col-xs-12 usas-extra-bottom-margin">
          <Headings.H5>
            Please re-certify the highest Position Worked and Certification Level first, then the next highest Position Worked and Certification Level, etc. When a parent record is updated, any children records are updated automatically.
          </Headings.H5>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <OfficialPositionTypeDropdown
            label="Position Worked*"
            name="officialPositionTypeId"
            value={formState.officialPositionTypeId}
            error={errorState.officialPositionTypeId}
            message={errorState.officialPositionTypeId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'officialPositionTypeId', newValueLabel, 'officialPositionName', e) }}
            orgLevelId={LSC_ORG_LEVEL_ID}
          />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <CertificationLevelDropdown
            label="Certification Level*"
            name="certificationLevelId"
            value={formState.certificationLevelId}
            error={errorState.certificationLevelId}
            message={errorState.certificationLevelId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'certificationLevelId', newValueLabel, 'levelName', e) }}
            orgLevelId={LSC_ORG_LEVEL_ID}
            officialPositionTypeId={undefined}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="From Expiration Date (Results On/After This Date)"
            name="fromExpirationDate"
            value={formState.fromExpirationDate}
            error={errorState.fromExpirationDate}
            message={errorState.fromExpirationDate}
            onChange={(value) => { onFormValueChanged('fromExpirationDate', value); }}
            countOfYears={countOfYears}
            startYearOffset={startYearOffset} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="To Expiration Date (Results On/Before This Date)"
            name="toExpirationDate"
            value={formState.toExpirationDate}
            error={errorState.toExpirationDate}
            message={errorState.toExpirationDate}
            onChange={(value) => { onFormValueChanged('toExpirationDate', value); }}
            countOfYears={countOfYears}
            startYearOffset={startYearOffset} />
        </div>
        <div className='col-xs-12 col-sm-6 usas-extra-bottom-margin'>
          <button className={global.PlainButton} onClick={(e) => onSendFilterInfoToGetGridData(e, formState)}>Filter</button>&nbsp;
          <button className={global.PlainButton} onClick={(e) => onClearFilterInfo(e)}>Clear Filter</button>
        </div>
      </div>
      <span className={searchOtsOfficialsForBulkRecertifyState?.arrayData?.length > 15 && searchOtsOfficialsForBulkRecertifyState.isArrayLoading === false ? global?.DisplayComponent : global?.HideComponent}>
        <div className='row usas-extra-top-margin usas-extra-bottom-margin visible-md visible-lg'>
          <div className='col-xs-12'>
            <PrimaryButton onClick={(e) => onSelectAll(e, searchOtsOfficialsForBulkRecertifyState)}>Select All Results</PrimaryButton>&nbsp;
            <SecondaryButton onClick={(e) => onUnSelectAll(e, searchOtsOfficialsForBulkRecertifyState)}>Unselect All Results</SecondaryButton>
          </div>
        </div>
      </span>
      <span className={recertifyBulkState?.recertifyBulkOfficialPersonIds.length > 0 && searchOtsOfficialsForBulkRecertifyState?.arrayData?.length > 15 && searchOtsOfficialsForBulkRecertifyState.isArrayLoading === false ? global.DisplayComponent : global.HideComponent}>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin visible-md visible-lg">
          <div className="col-xs-12">
            <PrimaryButton onClick={(e) => onNavigateToLscBulkRecertifyDetailView(e, formState)}>Bulk Re-Certify</PrimaryButton>
          </div>
        </div>
      </span>
      <span className={searchOtsOfficialsForBulkRecertifyState?.arrayData?.length > 0 && searchOtsOfficialsForBulkRecertifyState.isArrayLoading === false ? global?.DisplayComponent : global?.HideComponent}>
        <div className='row usas-extra-top-margin usas-extra-bottom-margin hidden-md hidden-lg'>
          <div className='col-xs-12 usas-extra-top-margin'>
            <PrimaryButton onClick={(e) => onSelectAll(e, searchOtsOfficialsForBulkRecertifyState)}>Select All Results</PrimaryButton>&nbsp;
            <SecondaryButton onClick={(e) => onUnSelectAll(e, searchOtsOfficialsForBulkRecertifyState)}>Unselect All Results</SecondaryButton>
          </div>
        </div>
      </span>
      <span className={recertifyBulkState?.recertifyBulkOfficialPersonIds.length === 0 ? global.HideComponent : global.DisplayComponent}>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin hidden-md hidden-lg">
          <div className="col-xs-12">
            <PrimaryButton onClick={(e) => onNavigateToLscBulkRecertifyDetailView(e, formState)}>Bulk Re-Certify</PrimaryButton>
          </div>
        </div>
      </span>
      <div className="row">
        <div className='col-xs-12'>
          <OtsOfficialsLscBulkRecertifyGrid
            state={searchOtsOfficialsForBulkRecertifyState}
            selectedOfficials={recertifyBulkState?.recertifyBulkOfficialPersonIds}
            onAddOrRemoveLscCertficationForBulkRecertify={onAddOrRemoveLscCertficationForBulkRecertify}
            onOfficialHistoryReportLoadClick={onOfficialHistoryReportLoadClick}
          />
        </div>
      </div>
      <span className={searchOtsOfficialsForBulkRecertifyState?.arrayData?.length === 0 ? global?.HideComponent : global?.DisplayComponent}>
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12 usas-extra-top-margin'>
            <PrimaryButton onClick={(e) => onSelectAll(e, searchOtsOfficialsForBulkRecertifyState)}>Select All Results</PrimaryButton>&nbsp;
            <SecondaryButton onClick={(e) => onUnSelectAll(e, searchOtsOfficialsForBulkRecertifyState)}>Unselect All Results</SecondaryButton>
          </div>
        </div>
      </span>
      <span className={recertifyBulkState?.recertifyBulkOfficialPersonIds.length === 0 ? global.HideComponent : global.DisplayComponent}>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PrimaryButton onClick={(e) => onNavigateToLscBulkRecertifyDetailView(e, formState)}>Bulk Re-Certify</PrimaryButton>
          </div>
        </div>
      </span>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading === true || notLoaded === true} />
      {state.showLoading === true && <LoadingModal />}
      {state.errorMessage &&
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title="Error" onModalCanceled={onErrorCanceled}>{state.errorMessage}
        </PopUpModal>}
    </Fragment>
  );
};

export default OtsOfficialLscBulkRecertify;