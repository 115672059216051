import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import MemberMergeSearchByMemberIds from './memberMergeSearchByIds/MemberMergeSearchByMemberIds';
import MiniMemberMergeSelection from './miniMemberMergeSelection/MiniMemberMergeSelection'

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const MiniMemberMerge = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['MEMBER_MERGE_MEMBER_IDS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_MERGE_MEMBER_IDS']}
            editElement={<MemberMergeSearchByMemberIds />} />
        }
      />
      <Route path={navRoutes['MEMBER_MERGE_SELECTION_MINI']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_MERGE_SELECTION_MINI']}
            editElement={<MiniMemberMergeSelection />} />
        }
      />
      <Route path={'/'} element={<Navigate to={navRoutes['MEMBER_MERGE_MEMBER_IDS']?.route} replace />} />
    </Routes>
  );
};

export default MiniMemberMerge;