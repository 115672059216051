import Constants from "../../../../../common/utils/Constants";
import { isValidMemberId, isValidName } from "../../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;

  // Host LSC
  if (formState.lscOrgUnitId !== Constants.DEFAULT_ID) {
    filterCount += 1;
  }

  // Club Name
  if (formState.clubOrgUnitName !== '') {
    if (formState.clubOrgUnitId === Constants.DEFAULT_ID || formState.clubOrgUnitId === '') {
      errors.clubOrgUnitId = 'Must be a valid Club Name';
    }
    else {
      filterCount += 1;
    }
  }

  if (formState.firstOrPreferredName.trim() !== '') {
    if (!isValidName(formState.firstOrPreferredName.trim())) {
      errors.firstOrPreferredName = 'First or Preferred Name cannot contain numbers, special characters, or exceed 100 characters';
    }
    else {
      filterCount += 1;
    }
  }

  if (formState.lastName.trim() !== '') {
    if (!isValidName(formState.lastName.trim())) {
      errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
    }
    else {
      filterCount += 1;
    }
  }

  //Member ID
  if (formState.memberId.trim() !== '') {
    if (!isValidMemberId(formState.memberId.trim())) {
      errors.memberId = 'Member ID must be 14 alphanumeric characters';
    }
    else {
      filterCount += 1;
    }
  }

  if (filterCount < 1) {
    errors.filter = 'Provide at least one filter to search';
  }

  return errors;
};

const OtsMeetOfficialsOfficialSelectionValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OtsMeetOfficialsOfficialSelectionValidation;