import Constants from '../../../../../common/utils/Constants';
import { isValidDate } from '../../../../../common/utils/validation';
import { getTodaysDate } from '../../../../../common/utils/DateFunctions';

export const localValidate = (formState) => {
  let errors = {};

  let todaysDate = getTodaysDate();
  let validationCourseCompletionDate = new Date(formState.courseCompletionDate);
  let valiationCourseExpirationDate = new Date(formState.courseExpirationDate);
  let dateToday = new Date(todaysDate);

  //Completion Date
  if (formState.courseCompletionDate === Constants.BLANK_DATE_STRING || formState.courseCompletionDate === '') {
    errors.courseCompletionDate = 'Completion Date is required';
  } else if (!isValidDate(formState.courseCompletionDate)) {
    errors.courseCompletionDate = 'Completion Date must be a valid date';
  } else if (validationCourseCompletionDate > dateToday) {
    errors.courseCompletionDate = 'Completion Date cannot be in the future'
  }


  //Expiration Date
  if (formState.noExpirationDate === true) {
    if (formState.courseExpirationDate === Constants.BLANK_DATE_STRING || formState.courseExpirationDate === '') {
      errors.courseExpirationDate = 'Expiration Date is required';
    } else if (!isValidDate(formState.courseExpirationDate)) {
      errors.courseExpirationDate = 'Expiration Date must be a valid date';
    } else if (valiationCourseExpirationDate < validationCourseCompletionDate) {
      errors.courseExpirationDate = 'Expiration Date must be a date after completion date'
    } else if (valiationCourseExpirationDate <= dateToday) {
      errors.courseExpirationDate = 'Expiration Date must be in the future'
    }
  }

  return errors;
};

const MemberRequirmentsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MemberRequirmentsDetailValidation;