import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

export const GroupPersonCoachesStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  personId: '',
  options: [],
  areOptionsLoaded: false
};

const GroupPersonCoachesContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <GroupPersonCoachesStateContext.Provider value={stateHook}>
      {children}
    </GroupPersonCoachesStateContext.Provider>
  );
};

export default GroupPersonCoachesContextProvider;