import { useEffect, useState } from "react";
import { convertNumberToCurrencyString } from "../../../../../../common/utils/CurrencyUtils";

const MAX_COUPON_VALUE = 2.0;
const MIN_COUPON_VALUE = 0.0;

const INITIAL_FORM_GRID_STATE = {
  formGridData: [],
  formGridErrorMessage: ''
};

const useOfferingInstanceCouponDiscountFormGrid = (gridData, onSubmitCoupon) => {
  const [formGridState, setFormGridState] = useState(INITIAL_FORM_GRID_STATE);

  const onCouponValueChanged = (offeringInstanceCouponDiscountId, value) => {
    const newFormGridData = JSON.parse(JSON.stringify(formGridState.formGridData));
    const targetIndex = newFormGridData.findIndex((x) => x.offeringInstanceCouponDiscountId === offeringInstanceCouponDiscountId);

    if (targetIndex >= 0) {
      newFormGridData[targetIndex].couponValue = value;
    }

    setFormGridState({
      ...formGridState,
      formGridErrorMessage: '',
      formGridData: newFormGridData
    });
  };

  const onSaveCouponValue = (e, coupon) => {
    e?.preventDefault();

    const errorMessage = isValidCouponValue(coupon.couponValue);

    if (errorMessage) {
      setFormGridState({
        ...formGridState,
        formGridErrorMessage: errorMessage
      });
    } else {
      const offeringInstanceCouponDiscountObj = {
        offeringInstanceCouponDiscountId: coupon.offeringInstanceCouponDiscountId,
        offeringInstanceCouponId: coupon.offeringInstanceCouponId,
        couponValue: coupon.couponValue,
        couponField: coupon.couponField,
        feeTypeId: coupon.feeTypeId,
        orgUnitId: coupon.orgUnitId
      };

      onSubmitCoupon(offeringInstanceCouponDiscountObj);
    }
  };

  useEffect(() => {
    if (Array.isArray(gridData)) {
      const newFormGridData = JSON.parse(JSON.stringify(gridData));

      setFormGridState({
        ...formGridState,
        formGridData: newFormGridData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  function isValidCouponValue(couponValue) {
    if (couponValue > MAX_COUPON_VALUE || couponValue < MIN_COUPON_VALUE) {
      return `LSC Fee must be between $${convertNumberToCurrencyString(MIN_COUPON_VALUE)} and $${convertNumberToCurrencyString(MAX_COUPON_VALUE)}`
    }

    return '';
  }

  return {
    ...formGridState,
    onCouponValueChanged,
    onSaveCouponValue
  };
};

export default useOfferingInstanceCouponDiscountFormGrid;