import { useContext } from 'react';

import { OtsPositionWorkedEvaluationLevelEvaluatorNameStateContext } from './OtsPositionWorkedEvaluationLevelEvaluatorNameContextProvider';
import OtsPositionWorkedEvaluationLevelEvaluatorNameData from './OtsPositionWorkedEvaluationLevelEvaluatorNameData';

const useOtsPositionWorkedEvaluationLevelEvaluatorNameData = () => {
  const [otsPositionWorkedEvaluationLevelEvaluatorNameState, setOtsPositionWorkedEvaluationLevelEvaluatorNameState] = useContext(OtsPositionWorkedEvaluationLevelEvaluatorNameStateContext);

  const getOtsPositionWorkedEvaluationLevelEvaluatorNamesByOtsMeetId = (otsMeetId) => {
    OtsPositionWorkedEvaluationLevelEvaluatorNameData.getOtsPositionWorkedEvaluationLevelEvaluatorNamesByOtsMeetId(otsMeetId, otsPositionWorkedEvaluationLevelEvaluatorNameState, setOtsPositionWorkedEvaluationLevelEvaluatorNameState);
  };

  return {
    otsPositionWorkedEvaluationLevelEvaluatorNameState,
    setOtsPositionWorkedEvaluationLevelEvaluatorNameState,
    getOtsPositionWorkedEvaluationLevelEvaluatorNamesByOtsMeetId
   };
};

export default useOtsPositionWorkedEvaluationLevelEvaluatorNameData;