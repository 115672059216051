import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOfficialLscCertificationByOrgUnitId from './GetOfficialLscCertificationByOrgUnitIdData';
import postOfficialLscCertification from './PostOfficialLscCertificationData';
import putOfficialLscCertification from './PutOfficialLscCertificationData';
import deleteOfficialLscCertification from './DeleteOfficialLscCertificationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  options: []
};

const OfficialLscCertificationData = {
  INITIAL_STATE,
  getOfficialLscCertificationByOrgUnitId,
  postOfficialLscCertification,
  putOfficialLscCertification,
  deleteOfficialLscCertification
}

export default OfficialLscCertificationData;