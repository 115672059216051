import { useEffect } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import useOtsOfficialLscData from "../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData";
import useSearchOtsOfficialData from "../../../../state/searchOfficialsTrackingSystemOfficial/UseSearchOtsOfficialData";
import useOtsOfficialMeetHistoryData from "../../../../state/officialsTrackingSystemOfficialMeetHistory/UseOtsOfficialMeetHistoryData";
import useOtsOfficialNationalCertificationData from "../../../../state/officialsTrackingSystemOfficialNationalCertification/UseOtsOfficialNationalCertificationData";

import useBasePersonData from "../../../../../common/state/personBase/UseBasePersonData";
import useSecurityData from "../../../../../common/state/security/UseSecurityData";
import usePersonOrgRoleData from "../../../../../common/state/personOrgRole/UsePersonOrgRoleData";
import useEnvironmentVariableData from "../../../../../common/state/environmentVariable/UseEnvironmentVariableData";

const TAXONOMIES = ['OTSOfficialDetail'];
const SCOPE = 'Official';

const useOtsOfficialSelection = () => {
    const { clearOtsOfficialState, clearOtsOfficialHeaderObjData, otsOfficialHeaderState, setOtsOfficialHeaderState } = useOtsOfficialLscData();
    const { resetSearchOtsOfficialFilterState } = useSearchOtsOfficialData();
    const { basePersonState, getBasePerson } = useBasePersonData();
    const { contextSecurityState, getContextSecurity } = useSecurityData();
    const { clearOtsOfficialMeetHistoryState } = useOtsOfficialMeetHistoryData();
    const { clearOtsOfficialNationalCertificationState } = useOtsOfficialNationalCertificationData();
    const { personOrgRoleState, getPersonOrgRoleByRoleGroup } = usePersonOrgRoleData();
    const { environmentVariableState, OFFICIALS_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();

    const navigate = useNavigate();

    useEffect(() => {
        //get base person and clear out any official search data
        getBasePerson(0);
        clearOtsOfficialState();
        clearOtsOfficialHeaderObjData();
        clearOtsOfficialMeetHistoryState();
        clearOtsOfficialNationalCertificationState();
        resetSearchOtsOfficialFilterState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        //This is used for the My Official History view
        if (environmentVariableState.isLoaded === true) {
            getPersonOrgRoleByRoleGroup(0, OFFICIALS_ROLES_ROLE_GROUP_ID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [environmentVariableState])

    useEffect(() => {
        //This is used for the My Official History view
        if (basePersonState?.objData?.personId && personOrgRoleState.isArrayLoaded === true) {
            const roleName = personOrgRoleState?.arrayData[0]?.orgRoleName || '';
            const lscOrgUnitId = personOrgRoleState?.arrayData[0]?.parentOrgUnitId || '';
            const parentOrgUnitId = personOrgRoleState?.arrayData[0]?.parentOrgUnitName || '';
            const officialHeaderObj = {
                firstName: basePersonState?.objData?.firstName || '',
                preferredName: basePersonState?.objData?.preferredName || '',
                middleName: basePersonState?.objData?.middleName || '',
                lastName: basePersonState?.objData?.lastName || '',
                roleName: roleName,
                memberId: basePersonState?.objData?.memberId || '',
                lscName: parentOrgUnitId,
                orgUnitId: lscOrgUnitId,
                personId: basePersonState?.objData?.personId || ''
            };
            setOtsOfficialHeaderState({
                ...otsOfficialHeaderState,
                objData: officialHeaderObj,
                orgUnitId: lscOrgUnitId,
                isLoggedInUser: true,
                personId: basePersonState?.objData?.personId || ''
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basePersonState, personOrgRoleState])

    useEffect(() => {
        //This is used for the My Official History view
        if (basePersonState.isObjLoaded === true) {
            if (contextSecurityState.isObjLoaded === false ||
                (contextSecurityState.isObjLoaded === true
                    && contextSecurityState.contextId !== basePersonState.objData.personId)) {
                getContextSecurity(basePersonState.objData.personId, TAXONOMIES, SCOPE);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basePersonState, contextSecurityState]);

    useEffect(() => {
        //This is used for the My Official History view
        if (contextSecurityState.contextId === basePersonState.objData.personId && Object.keys(otsOfficialHeaderState.objData)?.length > 0) {
            navigate('/ots/official/lsccertifications', { state: { getLscCertifications: true } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextSecurityState, basePersonState, otsOfficialHeaderState.objData])

    return {
        isLoading: contextSecurityState.isObjLoading || personOrgRoleState.isArrayLoading
    };
};

export default useOtsOfficialSelection;