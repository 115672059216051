import { useEffect } from 'react';

import useActiveClubsData from '../../../state/activeClubs/UseActiveClubsData';

const useActiveClubsCombobox = (lscId) => {
  const { activeClubsState, getActiveClubs } = useActiveClubsData();

  useEffect(() => {
    if ((lscId !== activeClubsState.lscId || activeClubsState.isArrayLoaded === false)
      && activeClubsState.isArrayLoading === false) {
      getActiveClubs(lscId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lscId, activeClubsState]);

  return {
    activeClubsState
  };
};

export default useActiveClubsCombobox;