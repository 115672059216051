import { Fragment } from 'react';

import useLscOfferingInstanceOrgUnitAdd from './UseLscOfferingInstanceOrgUnitAdd';

import LscOfferingManagementContextLoadingModal from '../../LscOfferingManagementContextLoadingModal';

import OfferingInstanceOrgUnitAddForm from '../../../components/forms/offeringInstanceOrgUnit/OfferingInstanceOrgUnitAddForm';
import OfferingInstanceOrgUnitAddAdditionalForm from '../../../components/forms/offeringInstanceOrgUnit/OfferingInstanceOrgUnitAddAdditionalForm';

import { ADD_VIEW_TYPE_ADDITIONAL_OFFERING, ADD_VIEW_TYPE_NEW_OFFERING } from '../../../utils/OfferingManagementConstants';

import Headings from '../../../../../../common/components/headings/Headings';

import { SAVING_MSG } from '../../../../../../common/utils/Constants';

const LscOfferingInstanceOrgUnitAdd = () => {
  const {
    isSaving,
    state,
    onSubmitOfferingInstanceOrgUnitForm,
    onBackClicked
  } = useLscOfferingInstanceOrgUnitAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{state.viewType === ADD_VIEW_TYPE_ADDITIONAL_OFFERING ? 'Add Offering Period' : 'Add LSC Offering'}</Headings.H3>
        </div>
      </div>
      {state.viewType === ADD_VIEW_TYPE_NEW_OFFERING
        ? (
          <OfferingInstanceOrgUnitAddForm
            reportPeriodObj={state.reportPeriodObj}
            orgUnitObj={state.orgUnitObj}
            hideOrgUnitName={true}
            submitButtonText='Add LSC Offering'
            onSubmitFormCallback={onSubmitOfferingInstanceOrgUnitForm}
            onBackClicked={onBackClicked} />
        ) : state.viewType === ADD_VIEW_TYPE_ADDITIONAL_OFFERING
          ? (
            <OfferingInstanceOrgUnitAddAdditionalForm
              offeringInstanceObj={state.offeringInstanceObj}
              reportPeriodObj={state.reportPeriodObj}
              orgUnitObj={state.orgUnitObj}
              hideOrgUnitName={true}
              submitButtonText='Add Offering Period'
              onSubmitFormCallback={onSubmitOfferingInstanceOrgUnitForm}
              onBackClicked={onBackClicked} />
          ) : <Fragment />
      }
      <LscOfferingManagementContextLoadingModal
        isLoading={isSaving}
        loadingMessage={SAVING_MSG}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default LscOfferingInstanceOrgUnitAdd;