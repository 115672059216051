import React, { createContext, useState } from 'react';

import MemberAdministrationFoundationBoardData from './MemberAdministrationFoundationBoardData';

export const MemberAdministrationFoundationBoardStateContext = createContext();

const MemberAdministrationFoundationBoardContextProvider = ({ children }) => {
  const stateHook = useState(MemberAdministrationFoundationBoardData.INITIAL_STATE);

  return (
    <MemberAdministrationFoundationBoardStateContext.Provider value={stateHook}>
      {children}
    </MemberAdministrationFoundationBoardStateContext.Provider>
  );
};

export default MemberAdministrationFoundationBoardContextProvider;