import { useContext } from 'react';

import MemberAdministrationHQCommitteeData from './MemberAdministrationHQCommitteeData';
import { MemberAdministrationHQCommitteeStateContext } from './MemberAdministrationHQCommitteeContextProvider';
import { MemberAdministrationHQCommitteeFilterStateContext } from './MemberAdministrationHQCommitteeFilterContextProvider';

const useMemberAdministrationHQCommitteeData = () => {
  const [memberAdministrationHQCommitteeState, setMemberAdministrationHQCommitteeState] = useContext(MemberAdministrationHQCommitteeStateContext);
  const [memberAdminHqCommitteeFilterState, setMemberAdminHqCommitteeFilterState] = useContext(MemberAdministrationHQCommitteeFilterStateContext);

  const getHQCommitteeMembers = () => MemberAdministrationHQCommitteeData.getHQCommitteeMembersData(memberAdministrationHQCommitteeState, setMemberAdministrationHQCommitteeState);
  const postHQCommitteeMember = (orgUnitId, roleGroupId, staff) => MemberAdministrationHQCommitteeData.postHQCommitteeMember(orgUnitId, roleGroupId, staff, memberAdministrationHQCommitteeState, setMemberAdministrationHQCommitteeState);
  const putHQCommitteeMember = (orgUnitId, personId, roleGroupId, staff) => MemberAdministrationHQCommitteeData.putHQCommitteeMember(orgUnitId, personId, roleGroupId, staff, memberAdministrationHQCommitteeState, setMemberAdministrationHQCommitteeState);
  const deleteHQCommitteeMember = (personOrgRoleDurationId) => MemberAdministrationHQCommitteeData.deleteHQCommitteeMember(personOrgRoleDurationId, memberAdministrationHQCommitteeState, setMemberAdministrationHQCommitteeState);

  const confirmSave = () => {
    setMemberAdministrationHQCommitteeState({
      ...memberAdministrationHQCommitteeState,
      isSaved: false
    });
  };

  return {
    memberAdministrationHQCommitteeState,
    getHQCommitteeMembers,
    postHQCommitteeMember,
    putHQCommitteeMember,
    deleteHQCommitteeMember,
    confirmSave,
    memberAdminHqCommitteeFilterState,
    setMemberAdminHqCommitteeFilterState
  };
};

export default useMemberAdministrationHQCommitteeData;