
export const navLinks = {
  MEMBER_ADMINISTRATION_ROOT: '/memberadministration',
  SELECTION: '/memberadministration/hqboardofdirectors/selection',
  HQ_BOARD: '/memberadministration/hqboardofdirectors',
  HQ_BOARD_ADD: '/memberadministration/hqboardofdirectors/add',
  HQ_BOARD_EDIT: '/memberadministration/hqboardofdirectors/edit',
  FOUNDATION_BOARD: '/memberadministration/foundationboard',
  FOUNDATION_BOARD_ADD: '/memberadministration/foundationboard/add',
  FOUNDATION_BOARD_EDIT: '/memberadministration/foundationboard/edit',
  HQ_COMMITTEE: '/memberadministration/committees',
  HQ_COMMITTEE_ADD: '/memberadministration/committees/add',
  HQ_COMMITTEE_EDIT: '/memberadministration/committees/edit'
};