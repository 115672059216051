import { Fragment } from "react";

import useHqOfferingTypeUSASEdit from "./UseHqOfferingTypeUSASEdit";

import HqOfferingManagementContextLoadingModal from "../../HqOfferingManagementContextLoadingModal";

import OfferingTypeUSASForm from "../../../components/forms/offering/OfferingTypeUSASForm";

import Headings from "../../../../../../common/components/headings/Headings";
import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

import { SAVING_MSG } from "../../../../../../common/utils/Constants";

const HqOfferingTypeUSASEdit = () => {
  const {
    isSaving,
    state,
    onSubmitOfferingForm,
    onBackClicked,
    onDeleteClicked,
    onDeletePopupConfirmClicked,
    onDeletePopupCancelClicked
  } = useHqOfferingTypeUSASEdit();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit USA Swimming Offering</Headings.H3>
        </div>
      </div>
      {state.offeringObj?.offeringId > 0 &&
        <OfferingTypeUSASForm
          offeringObj={state.offeringObj}
          onSubmitFormCallback={onSubmitOfferingForm}
          onBackClicked={onBackClicked}
          onDeleteClicked={onDeleteClicked} />
      }
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete HQ Offering?'}
        displayPopUp={state.displayDeletePopup === true}
        onModalCanceled={onDeletePopupCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Offering Name</b>: &nbsp;{state.deletePopupOfferingObj?.offeringName} </p>
            <p><b>Age Range</b>: &nbsp;{state.deletePopupOfferingObj?.ageStart} - {state.deletePopupOfferingObj?.ageEnd}</p>
            <p><b>Offering Org Roles</b>: &nbsp;{state.deletePopupOfferingObj?.orgRoleNames}</p>
            <p><b>Offering Description</b>: &nbsp;{state.deletePopupOfferingObj?.offeringDescription}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeletePopupConfirmClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeletePopupCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <HqOfferingManagementContextLoadingModal
        isLoading={isSaving}
        loadingMessage={SAVING_MSG}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default HqOfferingTypeUSASEdit;