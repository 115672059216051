import { useContext } from 'react';
import { GroupPersonCoachesStateContext } from './GroupPersonCoachesContextProvider';

import GroupPersonCoachesData from './GroupPersonCoachesData';

const useGroupPersonCoachesData = () => {
  const [groupPersonCoachesState, setGroupPersonCoachesState] = useContext(GroupPersonCoachesStateContext);

  const getGroupPersonCoaches = (personId, isActive = null) => {
    return GroupPersonCoachesData.getGroupPersonCoachesData(personId, isActive, groupPersonCoachesState, setGroupPersonCoachesState);
  };

  return {
    groupPersonCoachesState,
    setGroupPersonCoachesState,
    getGroupPersonCoaches
  };
};

export default useGroupPersonCoachesData;