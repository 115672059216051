import { useState } from 'react';

import PersonbyMemberIdData from './PersonbyMemberIdData';

const usePersonByMemberIdData = () => {
  const [personByMemberIdState, setPersonByMemberIdState] = useState({ ...PersonbyMemberIdData.INITIAL_STATE });

  const getPersonByMemberId = (memberId) => {
    return PersonbyMemberIdData.getPersonByMemberIdData(memberId, personByMemberIdState, setPersonByMemberIdState);
  };

  const getFlatPersonByMemberId = (memberId) => {
    return PersonbyMemberIdData.getFlatPersonByMemberIdData(memberId, personByMemberIdState, setPersonByMemberIdState);
  };

  const getPersonByMemberIdIsInGoodStanding = (memberId, isAthleteRole, organizationId) => {
    return PersonbyMemberIdData.getPersonByMemberIdIsInGoodStandingData(memberId, isAthleteRole, organizationId, personByMemberIdState, setPersonByMemberIdState);
  };

  const resetState = () => {
    setPersonByMemberIdState({ ...PersonbyMemberIdData.INITIAL_STATE });
  };

  return {
    personByMemberIdState,
    getPersonByMemberId,
    getFlatPersonByMemberId,
    getPersonByMemberIdIsInGoodStanding,
    resetState
  };
};

export default usePersonByMemberIdData;