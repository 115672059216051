import { Fragment } from 'react';

import useMemberContactDetail from './UseMemberContactDetail';

import ContactRelationshipTypeDropdown from '../../../../common/components/dropdowns/contactRelationshipTypeDropdown/ContactRelationshipTypeDropdown';
import NoSelfContactRelationshipTypeDropdown from '../../../../common/components/dropdowns/noSelfContactTypeDropdown/NoSelfContactRelationshipTypeDropdown';
import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const ContactDetailForm = ({ selfAlreadyExistsOrMemberIsTooYoung, state, formState, errorState, onFormValueChanged, onCancelClicked, onSaveContact, onValueTextPairChanged }) => {
  return (
    <form onSubmit={onSaveContact} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="First Name*"
            name="firstName"
            value={formState.firstName}
            error={errorState.firstName}
            message={errorState.firstName}
            onChange={(value) => { onFormValueChanged('firstName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Last Name*"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { onFormValueChanged('lastName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {formState.contactRelationshipTypeName === 'Self' && formState.personContactId > 0 //TODO
            ? (
              <ReadOnly
                label='Relationship'
                name="contactRelationshipTypeId"
                value={formState.contactRelationshipTypeName} />
            ) : (
              selfAlreadyExistsOrMemberIsTooYoung === true ? (
                <NoSelfContactRelationshipTypeDropdown
                  label="Relationship*"
                  name="contactRelationshipTypeId"
                  value={formState.contactRelationshipTypeId}
                  error={errorState.contactRelationshipTypeId}
                  message={errorState.contactRelationshipTypeId}
                  onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'contactRelationshipTypeId', newValueLabel, 'contactRelationshipTypeName'); }} />
              ) :
                (<ContactRelationshipTypeDropdown
                  label="Relationship*"
                  name="contactRelationshipTypeId"
                  value={formState.contactRelationshipTypeId}
                  error={errorState.contactRelationshipTypeId}
                  message={errorState.contactRelationshipTypeId}
                  onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'contactRelationshipTypeId', newValueLabel, 'contactRelationshipTypeName'); }} />)
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Email*"
            name="email"
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => { onFormValueChanged('email', value); }} />
        </div>
        <span className={formState.contactRelationshipTypeName === 'Self' ? global.HideComponent : global.DisplayComponent}>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Primary Emergency Contact?"
              name="isPrimary"
              checked={formState.isPrimary === true}
              error={errorState.isPrimary}
              message={errorState.isPrimary}
              onChange={(value) => { onFormValueChanged('isPrimary', value); }}
              disabled={state.disablePrimaryCheckbox === true} />
          </div>
        </span>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
        </div>
      </div>
      {formState.personId === '' ? <hr className={['usas-bar-gray', global.ThinBar].join(' ')} /> : null}
    </form>
  );
}

const MemberContactDetail = () => {
  const {
    state,
    formState,
    errorState,
    personGeneralState,
    contactRelationshipTypeState,
    isPrimaryModalState,
    onSaveContact,
    onCancelClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    onIsPrimaryModalCancelClicked,
    putPersonGeneralContacts,
    DEFAULT_ID,
    selfAlreadyExistsOrMemberIsTooYoung
  } = useMemberContactDetail();

  return (
    <Fragment>
      {personGeneralState.isObjLoaded !== true
        ? <Fragment />
        : <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <Headings.H3>{formState.personContactId > 0 ? "Edit" : "Add"} Contact</Headings.H3>
            </div>
          </div>
          <ContactDetailForm
            selfAlreadyExistsOrMemberIsTooYoung={selfAlreadyExistsOrMemberIsTooYoung}
            state={state}
            formState={formState}
            errorState={errorState}
            onValueTextPairChanged={onValueTextPairChanged}
            onFormValueChanged={onFormValueChanged}
            onSaveContact={onSaveContact}
            onCancelClicked={onCancelClicked}
            DEFAULT_ID={DEFAULT_ID}
          />
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personGeneralState.isObjLoading || contactRelationshipTypeState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personGeneralState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={isPrimaryModalState.modalTitle}
        displayPopUp={isPrimaryModalState.displayPopUp}
        onModalCanceled={onIsPrimaryModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Current Primary Contact:</b> {isPrimaryModalState.currentPrimaryInfo}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={() => putPersonGeneralContacts(true)}>Yes</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onIsPrimaryModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment >
  );
};

export default MemberContactDetail;