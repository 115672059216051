import { useEffect, useState } from 'react';
import useOtsCertificationApplicationData from '../../../../state/otsCertificationApplication/UseOtsCertificationApplicationData';

const INITIAL_VIEW_STATE = {
  gridData: [],
};

const useSearch = (activeOfficialsWithApplications) => {
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const { otsCertificationApplicationContextState, setOtsCertificationApplicationContextState, localOtsCertificationApplicationState } = useOtsCertificationApplicationData();

  useEffect(() => {
    if (otsCertificationApplicationContextState.lastNameFilter === '') {
      setState({
        ...state,
        gridData: activeOfficialsWithApplications
      });
    }
    else {
      const activeOfficialsWithApplicationsFiltered = activeOfficialsWithApplications.filter(official => official.lastName && official.lastName[0]?.toLowerCase() === otsCertificationApplicationContextState.lastNameFilter);
      setState({ ...state, gridData: activeOfficialsWithApplicationsFiltered });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOfficialsWithApplications, otsCertificationApplicationContextState.lastNameFilter]);

  const alphabeticalFilterClicked = (letter) => {
    setOtsCertificationApplicationContextState({ ...otsCertificationApplicationContextState, lastNameFilter: letter });
  };

  return {
    ...state,
    ...otsCertificationApplicationContextState,
    localOtsCertificationApplicationState,
    alphabeticalFilterClicked
  };
};

export default useSearch;