import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getCertificationLevelData from './GetCertificationLevelData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  orgLevelId: '',
  officialPositionTypeId: ''
};

const CertificationLevelData = {
  INITIAL_STATE, getCertificationLevelData
}

export default CertificationLevelData;