import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../../common/wrappers/ReactRouterDom';


import { USA_CLUB_MEMBERSHIP_LABEL } from '../../../utils/OfferingManagementConstants';

import useOfferingTypesData from '../../../../../state/offeringTypes/UseOfferingTypesData';

import useNavRoutes from '../../../../../../common/state/security/UseNavRoutes';

import { DEFAULT_ID } from '../../../../../../common/utils/Constants';

const INITIAL_STATE = {
  offeringTypeItems: [],
  offeringTypeId: DEFAULT_ID,
  offeringTypeName: '',
  pageErrorMessage: ''
};

const useHQSelectOfferingType = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { offeringTypesState, getOfferingTypes } = useOfferingTypesData();
  const [state, setState] = useState(INITIAL_STATE);

  const onContinueClicked = (e) => {
    e?.preventDefault();

    if (state.offeringTypeName === USA_CLUB_MEMBERSHIP_LABEL) {
      navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_TYPE_CLUB_ADD?.route, { state: { offeringTypeId: state.offeringTypeId, offeringTypeName: state.offeringTypeName } });
    } else {
      navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_TYPE_USAS_ADD?.route, { state: { offeringTypeId: state.offeringTypeId, offeringTypeName: state.offeringTypeName } });
    }
  };

  const onBackClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERINGS?.route);
  };

  const onUpdateOfferingType = (value) => {
    const offeringTypeItem = state.offeringTypeItems.find((x) => x.value === value);

    setState({ ...state, offeringTypeId: offeringTypeItem?.id, offeringTypeName: offeringTypeItem?.value });
  };

  useEffect(() => {
    if (offeringTypesState.isArrayLoaded === true) {
      const items = offeringTypesState.arrayData.map((x) => {
        return { id: x.offeringTypeId, value: x.typeName, label: x.typeName }
      });

      setState({
        ...state,
        offeringTypeItems: items
      });
    } else {
      const getOfferingTypesPromise = getOfferingTypes();

      if (getOfferingTypesPromise ?? false) {
        getOfferingTypesPromise.then((newState) => {
          if (newState ?? false) {
            const items = newState.arrayData.map((x) => {
              return { id: x.offeringTypeId, value: x.typeName, label: x.typeName }
            });

            setState({
              ...state,
              offeringTypeItems: items
            });
          }
        }).catch((e) => {
          setState({
            ...state,
            pageErrorMessage: e?.message || 'Failed to retrieve offering types',
          });
        })
      } else {
        setState({
          ...state,
          pageErrorMessage: 'Failed to load offering types',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: offeringTypesState.isArrayLoading,
    state,

    onBackClicked,
    onContinueClicked,
    onUpdateOfferingType
  };
};

export default useHQSelectOfferingType;