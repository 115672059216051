export const localValidate = (formState) => {
  let errors = {};

  // Narrative Type
  if (formState.narrativeTypeId < 0) {
    errors.narrativeTypeId = 'Narrative Name is required';
  }

  // Narrative Description
  if (!formState.narrativeDescription.trim()) {
    errors.narrativeDescription = 'Narrative is required';
  }
  else if (formState.narrativeDescription.trim().length > 7000) {
    errors.narrativeDescription = 'Narrative cannot exceed 7000 characters';
  }

  return errors;
};

const MemberNarrativeDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MemberNarrativeDetailValidation;