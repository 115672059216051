import { useEffect, useState } from 'react';

const INITIAL_VIEW_STATE = {
  reportParameters: {},
  routeName: ''
};

const useOtsReporting = () => {
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });

  useEffect(() => {
    setViewState({
      ...viewState,
      reportParameters: {},
      routeName: 'OTS_REPORTING'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...viewState };
};

export default useOtsReporting;