import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const getNationalEvaluators = (otsMeetId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const otsMeetIdForUrl = otsMeetId ? encodeURIComponent(otsMeetId) : 'NaN';
  const url = otsMeetId ? `/otsnationalevaluator?otsMeetId=${otsMeetIdForUrl}` : '/otsnationalevaluator';

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const getNationalEvaluator = (id, state, setState) => {
  if (id) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/otsnationalevaluator/${id}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const postNationalEvaluator = (payload, state, setState) => {
  if (payload) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = '/otsnationalevaluator';

    return api?.executeObject ? api.executeObject(url, 'POST', payload) : null;
  }
};

const putNationalEvaluator = (id, payload, state, setState) => {
  if (payload) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/otsnationalevaluator/${id}`;

    return api?.executeObject ? api.executeObject(url, 'PUT', payload) : null;
  }
};

const deleteNationalEvaluator = (id, state, setState) => {
  if (id) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/otsnationalevaluator/${id}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const NationalEvaluatorData = {
  getNationalEvaluators,
  getNationalEvaluator,
  postNationalEvaluator,
  putNationalEvaluator,
  deleteNationalEvaluator,
  INITIAL_STATE
};

export default NationalEvaluatorData;