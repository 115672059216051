import { useContext } from 'react';

import { OrgRoleStaffRoleHierarchyStateContext } from './OrgRoleStaffRoleHierarchyContextProvider';
import OrgRoleStaffRoleHierarchyData from './OrgRoleStaffRoleHierarchyData';

const useOrgRoleStaffRoleHierarchyData = () => {
  const [orgRoleStaffRoleHierarchyState, setOrgRoleStaffRoleHierarchyState] = useContext(OrgRoleStaffRoleHierarchyStateContext);

  const getOrgRolesStaffRolesHierarchy = (roleGroupId) => OrgRoleStaffRoleHierarchyData.getOrgRolesStaffRolesHierarchy(roleGroupId, orgRoleStaffRoleHierarchyState, setOrgRoleStaffRoleHierarchyState);

  return {
    orgRoleStaffRoleHierarchyState,
    getOrgRolesStaffRolesHierarchy
  };
};

export default useOrgRoleStaffRoleHierarchyData;