import React, { Fragment } from "react";

import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import EditIcon from "../../../../common/components/icons/EditIcon";
import HideIcon from "../../../../common/components/icons/HideIcon";
import ShowIcon from "../../../../common/components/icons/ShowIcon";

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ personId, role, onEdit, onDelete }) => (
  <tr>
    <td>{role.orgRoleName || ''}</td>
    <td>{role.effectiveDate || ''}</td>
    <td>{role.expirationDate || ''}</td>
    <td>
      {onEdit !== undefined && role.isCurrent === true &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onEdit(personId, role.personOrgRoleDurationId)}>
          <EditIcon />
        </button>
      }
      {onDelete !== undefined && role.canDelete === true &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDelete(personId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const DetailGrid = ({ personId, roleDurations, onEdit, onDelete }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Role</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {roleDurations.map((role, i) => <DetailGridRow key={i} personId={personId} role={role} onEdit={onEdit} onDelete={onDelete} />)}
    </tbody>
  </table>
);

const GridRow = ({ staff, expandedId, onEdit, onDelete, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td colSpan="2">{staff.name || ''}</td>
      <td>{staff.email || ''}</td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, staff.personId)}>
          {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {Array.isArray(staff.roleDurations) && staff.roleDurations.length > 0 && expandedId === staff.personId &&
      <tr className={global.Expanded}>
        <td colSpan="4">
          <DetailGrid personId={staff.personId} roleDurations={staff.roleDurations} onEdit={onEdit} onDelete={onDelete} />
        </td>
      </tr>
    }
  </Fragment>
);

const FoundationBoardMembersGridLarge = ({ data, expandedId, isLoading, onEdit, onDelete, onExpandClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th colSpan="2">Foundation Board Member Name</th>
        <th>Email</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : data.length > 0
          ? data.map((staff, i) => <GridRow key={i} staff={staff} expandedId={expandedId} onEdit={onEdit} onDelete={onDelete} onExpandClicked={onExpandClicked} />)
          : <tr><td colSpan="4">No Foundation Board Members</td></tr>
      }
    </tbody>
  </table>
);

export default FoundationBoardMembersGridLarge;