import { useState } from 'react';
import MeetEvaluatorPositionLevelData from './MeetEvaluatorPositionLevelData';

const useMeetEvaluatorPositionLevelData = () => {
  const [state, setState] = useState({ ...MeetEvaluatorPositionLevelData.INITIAL_STATE });

  const getMeetEvaluatorPositionLevelsByOtsMeetId = (otsMeetId) => {
    return MeetEvaluatorPositionLevelData.getMeetEvaluatorPositionLevelsByOtsMeetId(otsMeetId, state, setState);
  };

  return {
    MeetEvaluatorPositionLevelState: { ...state },
    SetMeetEvaluatorPositionLevelState: setState,
    getMeetEvaluatorPositionLevelsByOtsMeetId
  };
};

export default useMeetEvaluatorPositionLevelData;
