import React, { Fragment } from 'react';

const DeleteAttachmentModal = ({ modalState }) => {
  return (
    <Fragment>
      <p className='col-xs-12 col-sm-6'><strong>File Name:</strong>&nbsp;{modalState?.fileName}</p>
      <p className='col-xs-12 col-sm-6'><strong>File Type Name: </strong> &nbsp;{modalState?.fileTypeName}</p>
      <p className='col-xs-12'><strong>File Description: </strong> &nbsp;{modalState?.attachmentDescription}</p>
      <p className='col-xs-12'><strong>File Url:</strong>&nbsp;{modalState?.attachmentUrl}</p>
      <p className='col-xs-12'>Are you sure you want to delete this attachment?</p>
    </Fragment>
  );
};

export default DeleteAttachmentModal;