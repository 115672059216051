import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOfficialMentorLevels from './GetOfficialMentorLevelData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: []
};

const OfficialMentorLevelData = {
  INITIAL_STATE,
  getOfficialMentorLevels
}

export default OfficialMentorLevelData;