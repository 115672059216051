import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import Constants from '../../../common/utils/Constants';
import { navigateToCrossUiRoute } from '../../../common/utils/FormatCrossUiRoute';

import useMemberPersistentStorage from '../../../common/state/memberPersistentStorage/UseMemberPersistentStorage';

const INITIAL_VIEW_STATE = {
  returnUrl: '',
  uIProjectName: '',
  backToSystemLabel: ''
};

const useBackToSystemLink = () => {
  const navigate = useNavigate();
  const { getPersistentStorage } = useMemberPersistentStorage();
  const [ state, setState ] = useState(INITIAL_VIEW_STATE);

  const onBackToSelectionClicked = (e) => {
    e?.preventDefault();
    
    navigateToCrossUiRoute(state.uIProjectName, state.returnUrl, navigate);
  };

  useEffect(() => {
    const { returnUrl, uIProjectName, backToSystemLabel } = getPersistentStorage();
    setState({
      returnUrl: returnUrl || '/',
      uIProjectName: uIProjectName || Constants.UI_PROJECT_NAMES.MEMBER,
      backToSystemLabel: backToSystemLabel || 'Back to Member Search'
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    label: state.backToSystemLabel,
    onBackToSelectionClicked
  };
};

export default useBackToSystemLink;