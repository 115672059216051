import React, { createContext, useState } from 'react';


export const MemberAdministrationHQCommitteeFilterStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
};

const MemberAdministrationHQCommitteeFilterContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <MemberAdministrationHQCommitteeFilterStateContext.Provider value={stateHook}>
      {children}
    </MemberAdministrationHQCommitteeFilterStateContext.Provider>
  );
};

export default MemberAdministrationHQCommitteeFilterContextProvider;