import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ProfileCoachTypeDetailValidation';

import useCoachTypeData from '../../../state/coachType/UseCoachTypeData';
import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useProfileCoachTypeDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personProfileState, putPersonProfile } = usePersonProfileData();
  const { coachTypeState } = useCoachTypeData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, onValueTextPairChanged, resetForm } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [uiState, setUiState] = useState(getInitialUiState());

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_PROFILE_COACH?.route);
  };

  useEffect(() => {
    if (coachTypeState.isLoaded === true) {
      if (location.state && location.state.personCoachTypeId && personProfileState.isObjLoaded === true) {
        const coachPersonId = location.state.coachPersonId;
        const personCoachTypeId = location.state.personCoachTypeId;
        const coachName = location.state.coachName;
        const selectedCoach = personProfileState.objData.coaches.find(e => e.coachPersonId === coachPersonId);

        // Edit
        if (personCoachTypeId > 0) {
          const selectedCoachType = selectedCoach.personCoachType.find(e => e.personCoachTypeId === personCoachTypeId);
          setFormData({
            ...formState,
            coachPersonId: selectedCoach.coachPersonId,
            coachName: coachName || '',
            personCoachTypeId: personCoachTypeId || Constants.DEFAULT_ID,
            coachTypeId: selectedCoachType.coachTypeId || Constants.DEFAULT_ID,
            isCoachOfRecord: selectedCoachType.isCoachOfRecord ?? '',
            isPrimary: selectedCoachType.isPrimary ?? '',
            confirmedDates: selectedCoachType.confirmedDates ?? '',
            noExpirationDate: formatDate(selectedCoachType?.expirationDate) === Constants?.DEFAULT_MAX_DATE ? true : false,
            effectiveDate: formatDate(selectedCoachType.effectiveDate) || Constants.BLANK_DATE_STRING,
            expirationDate: formatDate(selectedCoachType.expirationDate) || Constants.BLANK_DATE_STRING
          });
        } else {
          setFormData({
            ...formState,
            coachName: coachName
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personProfileState, coachTypeState]);

  useEffect(() => {
    if (uiState.tryRedirect === true && personProfileState.isObjLoading === false && personProfileState.isSaving === false) {
      navigate(navRoutes.MEMBER_PROFILE_COACH?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState, uiState.tryRedirect]);


  // Creates a copy of the personProfileState.objData and makes changes to it based on formState
  function createPutPersonObject() {
    const personToPut = JSON.parse(JSON.stringify(personProfileState.objData));
    let personCoachArray = personToPut.coaches;
    const coach = personCoachArray.find(e => e.coachPersonId === location.state.coachPersonId);
    const coachIndex = personCoachArray.findIndex(e => e.coachPersonId === location.state.coachPersonId);
    const expirationDate = formState?.noExpirationDate === true ? Constants?.DEFAULT_MAX_DATE : formState?.expirationDate;
    // Is Edit
    if (formState.personCoachTypeId > 0) {
      let coachType = coach.personCoachType.find(e => e.personCoachTypeId === location.state.personCoachTypeId);
      const coachTypeIndex = coach.personCoachType.findIndex(e => e.personCoachTypeId === location.state.personCoachTypeId);
      coachType = {
        ...coachType,
        coachTypeId: formState.coachTypeId,
        effectiveDate: formState.effectiveDate,
        expirationDate: expirationDate,
        isPrimary: formState.isPrimary,
        confirmedDates: formState.confirmedDates,
        isCoachOfRecord: formState.isCoachOfRecord,
        coachType: null
      };

      coach.personCoachType[coachTypeIndex] = coachType;
      personToPut.coaches[coachIndex] = coach;
    }
    // Is Add
    else {
      let personCoachTypeObj = {
        coachTypeId: formState.coachTypeId,
        personCoachId: coach.personCoachId,
        effectiveDate: formState.effectiveDate,
        expirationDate: expirationDate,
        isPrimary: formState.isPrimary,
        confirmedDates: formState.confirmedDates,
        isCoachOfRecord: formState.isCoachOfRecord,
        coachType: null
      };

      coach.personCoachType.push(personCoachTypeObj);
      personToPut.coaches[coachIndex] = coach;
    }

    return personToPut;
  }

  function submitFormCallback(formState) {
    if (personProfileState.isSaving === false) {
      const personToPut = createPutPersonObject();
      if (personToPut !== null) {
        putPersonProfile(personProfileState.objData.personId, personToPut);
      }
    }

    setUiState({
      ...uiState,
      tryRedirect: true
    });
  };

  function getInitialFormState() {
    return {
      coachPersonId: Constants.DEFAULT_ID,
      coachName: '',
      personCoachTypeId: Constants.DEFAULT_ID,
      coachTypeId: Constants.DEFAULT_ID,
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      isPrimary: '',
      confirmedDates: '',
      isCoachOfRecord: '',
      noExpirationDate: ''
    };
  };

  function getInitialUiState() {
    return {
      tryRedirect: false
    };
  };

  return {
    personProfileState,
    formState,
    errorState,
    uiState,
    coachTypeState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    resetForm,
    onCancelClicked
  };
};

export default useProfileCoachTypeDetail;