import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOtsOfficialNationalCertificationByPersonId from './GetOtsOfficialNationalCertificationByPersonIdData';
import putOtsOfficialNationalCertificationByPersonId from './PutOtsOfficialNationalCertificationByPersonIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OtsOfficialNationalCertificationData = {
  INITIAL_STATE: { ...INITIAL_STATE },
  getOtsOfficialNationalCertificationByPersonId,
  putOtsOfficialNationalCertificationByPersonId
};

export default OtsOfficialNationalCertificationData;