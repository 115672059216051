import React, { Fragment } from 'react';

import useHQBoardMembers from './UseHQBoardMembers';

import HQBoardMembersGrid from './HQBoardMembersGrid';

import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

const HQBoardMembers = () => {
  const {
    isSaving,
    isGridLoading,
    viewState,
    gridState,
    onFilterCurrentMembers,
    onEditBoardMember,
    onAddBoardMember,
    onDeleteBoardMember
  } = useHQBoardMembers();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3 excludeBar={true}>HQ Board of Directors</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-7 col-md-8 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={onAddBoardMember}>Add Board Member</ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-5 col-md-4">
          <YesNoSwitch
            label="Show Only Current Board of Directors?"
            name="showCurrentBoard"
            checked={viewState.toggleCurrentStaffRoles}
            onChange={onFilterCurrentMembers} />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <HQBoardMembersGrid
            data={gridState}
            isLoading={isGridLoading}
            onEdit={onEditBoardMember}
            onDelete={onDeleteBoardMember} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isGridLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default HQBoardMembers;