import { isValidDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  //Organization Id
  if (formState.orgUnit.length < 1) {
    errors.orgUnit = 'Organization is required'
  }

  //Org Role Id
  if (formState.orgRole.length < 1) {
    errors.orgRole = 'Member Type or Member Role is required';
  }

  //Effective Date
  if (formState.effectiveDate === Constants.BLANK_DATE_STRING || formState.effectiveDate === '') {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  }

  return errors;
};

const MemberAffiliationsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MemberAffiliationsDetailValidation;