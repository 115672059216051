import { useContext, useEffect } from 'react';

import { FunFactsTypeStateContext } from './FunFactsTypeContextProvider';

import FunFactsTypeData, { defaultFunFactsTypeState } from './FunFactsTypeData';

const useFunFactsTypeData = () => {
  const [funFactsTypeState, setFunFactsTypeState] = useContext(FunFactsTypeStateContext);

  useEffect(() => {
    if (funFactsTypeState.isLoading !== true && funFactsTypeState.isLoaded !== true) {
      FunFactsTypeData.getFunFactsTypes(funFactsTypeState, setFunFactsTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    funFactsTypeState,
    defaultFunFactsTypeState
  };
};

export default useFunFactsTypeData;