import React, { useState, createContext } from 'react';
import EnrollmentData from './EnrollmentData';

export const EnrollmentInCertificationStateContext = createContext();

const EnrollmentInCertificationContextProvider = ({ children }) => {
  const stateHook = useState({...EnrollmentData.INITIAL_STATE});

  return (
    <EnrollmentInCertificationStateContext.Provider value={stateHook}>
      {children}
    </EnrollmentInCertificationStateContext.Provider>
  );
};

export default EnrollmentInCertificationContextProvider;