import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import PlusIcon from '../../../../../common/components/icons/PlusIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import { formatPhoneNumber } from '../../../../../common/utils/validation';
import { DEFAULT_ID } from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailGridRowPhoneIcons = ({ phone, contactId, onAddEditPhone, onDeletePhone }) => (
  <Fragment>
    {!phone.isPrimary === true && onDeletePhone &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
        onDeletePhone(e, phone.personContactPhoneId,
          `${formatPhoneNumber(phone.phoneNumber)} (${phone.phoneType?.typeName})`)}>
        <DeleteIcon />
      </button>
    }
    {onAddEditPhone &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
        onAddEditPhone(e, phone.personContactPhoneId, contactId)}>
        <EditIcon />
      </button>
    }
  </Fragment >
);

const DetailGridRowAddressIcons = ({ address, contactId, onAddEditAddress, onDeleteAddress }) => (
  <Fragment>
    {!address.isPrimary === true && onDeleteAddress &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
        onDeleteAddress(e, address.personContactAddressId,
          `${address.address?.address1}${address.address?.address2 ? ' ' + address.address?.address2 : ''}, ${address.address?.city}, ${address.address?.stateCode} ${address.address?.postalCode} (${address.addressType?.typeName})`)}>
        <DeleteIcon />
      </button>
    }
    {onAddEditAddress &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
        onAddEditAddress(e, address.personContactAddressId, contactId)}>
        <EditIcon />
      </button>
    }
  </Fragment>
);

const DetailGridRowPhone = ({ phone, contactId, onAddEditPhone, onDeletePhone }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{phone.phoneNumber ? formatPhoneNumber(phone.phoneNumber) : ''}</span>&nbsp;
      <DetailGridRowPhoneIcons phone={phone} contactId={contactId} onAddEditPhone={onAddEditPhone} onDeletePhone={onDeletePhone} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Phone Number
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {phone.phoneNumber ? formatPhoneNumber(phone.phoneNumber) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Type
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {phone.phoneType?.typeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Primary?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {phone.isPrimary === true ? 'Yes' : 'No'}
        </div>
      </div>
    </div>
  </div>
);

const DetailGridRowAddress = ({ address, contactId, onAddEditAddress, onDeleteAddress }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{address.address?.address1 || ''} {address.address?.address2 || ''}</span>&nbsp;
      <DetailGridRowAddressIcons address={address} contactId={contactId} onAddEditAddress={onAddEditAddress} onDeleteAddress={onDeleteAddress} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Address
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {address.address?.address1 || ''} {address.address?.address2 || ''}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          City
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {address.address?.city || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          State
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {address.address?.stateCode || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Zip Code
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {address.address?.postalCode || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Type
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {address.addressType?.typeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Primary?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {address.isPrimary === true ? 'Yes' : 'No'}
        </div>
      </div>
    </div>
  </div>
);

const DetailGridPhones = ({ phones, contactId, onAddEditPhone, onDeletePhone }) => (
  <div className={global.SmallTableDetailTable}>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span>Phones</span>&nbsp;
        {onAddEditPhone &&
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
            onAddEditPhone(e, DEFAULT_ID, contactId)}>
            <PlusIcon />
          </button>
        }
      </div>
    </div>
    {(!Array.isArray(phones) || phones.length <= 0) &&
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            No Phones
          </div>
        </div>
      </div>
    }
    {Array.isArray(phones) && phones.length > 0 &&
      phones.map((phone, i) => <DetailGridRowPhone key={i} phone={phone} contactId={contactId} onAddEditPhone={onAddEditPhone} onDeletePhone={onDeletePhone} />)
    }
  </div>
);

const DetailGridAddresses = ({ addresses, contactId, onAddEditAddress, onDeleteAddress }) => (
  <div className={global.SmallTableDetailTable}>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span>Addresses</span>&nbsp;
        {onAddEditAddress &&
          <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
            onAddEditAddress(e, DEFAULT_ID, contactId)}>
            <PlusIcon />
          </button>
        }
      </div>
    </div>
    {(!Array.isArray(addresses) || addresses.length <= 0) &&
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            No Addresses
          </div>
        </div>
      </div>
    }
    {Array.isArray(addresses) && addresses.length > 0 &&
      addresses.map((address, i) => <DetailGridRowAddress key={i} address={address} contactId={contactId} onAddEditAddress={onAddEditAddress} onDeleteAddress={onDeleteAddress} />)
    }
  </div>
);

const SmallGridRowIcons = ({ contact, expandedId, onEditPerson, onDeletePerson, onExpandClicked }) => (
  <Fragment>
    <button
      className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, contact.id)}>
      {expandedId === contact.id ? <HideIcon /> : <ShowIcon />}
    </button>
    {contact.canDelete && onDeletePerson &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDeletePerson(e, contact.id, `${contact.name}, ${contact.email} (${contact.relationship})`)}>
        <DeleteIcon />
      </button>
    }
    {contact.canEdit && onEditPerson &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditPerson(e, contact.id)}>
        <EditIcon />
      </button>
    }
  </Fragment>
);

const SmallGridRow = ({ contact, expandedId, onEditPerson, onDeletePerson,
  onAddEditPhone, onDeletePhone, onAddEditAddress, onDeleteAddress, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{contact.name || ''}</span>&nbsp;
        <SmallGridRowIcons contact={contact} expandedId={expandedId} onEditPerson={onEditPerson} onDeletePerson={onDeletePerson} onExpandClicked={onExpandClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {contact.name || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Relationship
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {contact.relationship || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Email
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {contact.canEdit === true ? (contact.email || <span>&nbsp;</span>) : 'Not collected for members under 16'}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Phone Number
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {contact.phoneNumber || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Primary Emergency Contact?
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {contact.isPrimaryDisplay || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    {expandedId === contact.id &&
      <Fragment>
        <DetailGridPhones phones={contact.contactPhones} contactId={contact.id} onAddEditPhone={onAddEditPhone} onDeletePhone={onDeletePhone} />
        <DetailGridAddresses addresses={contact.contactAddresses} contactId={contact.id} onAddEditAddress={onAddEditAddress} onDeleteAddress={onDeleteAddress} />
      </Fragment >
    }
  </Fragment>
);


const MemberContactsGridSmall = ({ contacts, isLoading, expandedId, onEditPerson, onDeletePerson,
  onAddEditPhone, onDeletePhone, onAddEditAddress, onDeleteAddress, onExpandClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {contacts.length > 0
      ? contacts.map((contact, i) => <SmallGridRow key={i} contact={contact} expandedId={expandedId} onEditPerson={onEditPerson} onDeletePerson={onDeletePerson} onAddEditPhone={onAddEditPhone} onDeletePhone={onDeletePhone} onAddEditAddress={onAddEditAddress} onDeleteAddress={onDeleteAddress} onExpandClicked={onExpandClicked} />)
      : (
        <div>
          {isLoading
            ? (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
              </div>
            ) : (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Contacts</div></div>
              </div>
            )
          }
        </div>
      )
    }
  </div>
);

export default MemberContactsGridSmall;