import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOtsOfficialSelectionData from './GetOtsOfficialSelectionData';
import postOtsOfficialSelectionData from './PostOtsOfficialSelectionData';
import deleteMeetOfficialData from  './DeleteMeetOfficialData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OfficialsTrackingSystemOfficialSelectionData = {
  INITIAL_STATE, getOtsOfficialSelectionData, postOtsOfficialSelectionData, deleteMeetOfficialData
}

export default OfficialsTrackingSystemOfficialSelectionData;