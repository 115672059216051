import { useContext } from 'react';

import { OrgGroupPropertiesStateContext } from './OrgGroupPropertiesContextProvider';
import OrgGroupPropertiesData from './OrgGroupPropertiesData';

const useOrgGroupPropertiesData = () => {
  const [orgGroupPropertiesState, setOrgGroupPropertiesState] = useContext(OrgGroupPropertiesStateContext);

  const getOrgGroupProperties = (groupTypeId) => {
    return OrgGroupPropertiesData.getOrgGroupProperties(groupTypeId, orgGroupPropertiesState, setOrgGroupPropertiesState);
  };

  return {
    orgGroupPropertiesState,
    getOrgGroupProperties
  };
};

export default useOrgGroupPropertiesData;