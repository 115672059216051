import React, { Fragment } from 'react';

import useOtsMeetStaff from './UseOtsMeetStaff';

import OtsMeetStaffGrid from './OtsMeetStaffGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

const OtsMeetStaff = () => {
  const {
    otsMeetStaffState,
    isLoading,
    Constants,
    onEditStaff
  } = useOtsMeetStaff();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Staff</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <OtsMeetStaffGrid
            gridData={otsMeetStaffState.arrayData}
            isLoading={otsMeetStaffState.isArrayLoading}
            onEditStaff={onEditStaff}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  )
}

export default OtsMeetStaff;