import { useState } from "react";
import { BASIC_INITIAL_STATE } from "../../utils/HttpHelper";

import MemberGroupsFileData from "./MemberGroupsFileData";

const MEMBER_GROUPS_FILE_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MEMBER_GROUPS_FILE_DOWNLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const BASE_UPLOAD_URL = 'https://memberdocs.blob.core.windows.net/gpa/';

const useMemberGroupsFileData = () => {
  const [memberGroupsFileUploadState, setMemberGroupsFileUploadState] = useState(MEMBER_GROUPS_FILE_UPLOAD_INITIAL_STATE);
  const [memberGroupsFileDownloadState, setMemberGroupsFileDownloadState] = useState(MEMBER_GROUPS_FILE_DOWNLOAD_INITIAL_STATE);

  const postGroupMemberGPAFile = (file) => {
    return MemberGroupsFileData.postGroupMemberGPAFileData(file, memberGroupsFileUploadState, setMemberGroupsFileUploadState);
  };

  const getGroupMemberGPAFile = (fileUrl) => {
    return MemberGroupsFileData.getGroupMemberGPAFileData(fileUrl, memberGroupsFileDownloadState, setMemberGroupsFileDownloadState);
  };

  const getFileNameFromUrl = (url) => {
    try {
      const fileNameWithDateTime = url.replace(BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);

      return decodeURI(fileName) || '';
    } catch (e) {
      return url;
    }
  };

  return {
    memberGroupsFileUploadState,
    memberGroupsFileDownloadState,
    postGroupMemberGPAFile,
    getGroupMemberGPAFile,
    getFileNameFromUrl
  };
};

export default useMemberGroupsFileData;