import useOrgUnitCombobox from './UseOrgUnitCombobox';

import Combobox from '../Combobox';

import global from '../../GlobalStyle.module.css';

const OrgUnitCombobox = ({ label, name, valueToMatch, error, message, onChange, organizationId, lscId }) => {
  const { orgUnitLowestChildrenState } = useOrgUnitCombobox(organizationId, lscId);

  return orgUnitLowestChildrenState.message
    ? <span className={global.LoadingMsg}>{orgUnitLowestChildrenState.message}</span>
    : (
      <Combobox
        label={label}
        name={name}
        isLoading={orgUnitLowestChildrenState.isLoading}
        items={orgUnitLowestChildrenState.items}
        valueToMatch={valueToMatch}
        error={error}
        message={message}
        onChange={onChange} />
    );
};

export default OrgUnitCombobox;