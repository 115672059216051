import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useOtsMeetData from '../../../state/officialsTrackingSystemMeet/UseOtsMeetData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const useOfficialsTrackingSystemMeetContextView = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { otsMeetFiltersState, otsMeetHeaderState,
    clearOtsMeetObjData, clearOtsMeetHeaderObjData, clearOtsMeetArrayData } = useOtsMeetData();
  const BACK_TO_LABELS = {
    otsMeetBackToLabel: (otsMeetHeaderState.myOtsMeets === true || (!navRoutes.OTS_MEET_SEARCH && navRoutes.OTS_MEET_MY_OTS_MEETS)) ? 'Back to My OTS Meets' :
      (otsMeetHeaderState.myLscOtsMeetSearch === true || (!navRoutes.OTS_MEET_SEARCH && navRoutes.OTS_MEET_MY_LSC_OTS_MEETS_SEARCH)) ? 'Back to My LSC OTS Meet Search' : 'Back to OTS Meet Search',
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    switch ((otsMeetFiltersState.backToSystem || '').toLowerCase()) {
      default:
        goToOtsMeetSearch();
        break;
    }
  };

  function goToOtsMeetSearch() {
    if (otsMeetHeaderState.myOtsMeets === true || (!navRoutes.OTS_MEET_SEARCH && navRoutes.OTS_MEET_MY_OTS_MEETS)) {
      clearOtsMeetObjData();
      clearOtsMeetHeaderObjData();
      clearOtsMeetArrayData();

      navigate(navRoutes.OTS_MEET_MY_OTS_MEETS?.route, { state: { keepSearchData: true } });
    }
    else if (otsMeetHeaderState.myLscOtsMeetSearch === true || (!navRoutes.OTS_MEET_SEARCH && navRoutes.OTS_MEET_MY_LSC_OTS_MEETS_SEARCH)) {
      clearOtsMeetObjData();
      clearOtsMeetHeaderObjData();
      clearOtsMeetArrayData();

      navigate(navRoutes.OTS_MEET_MY_LSC_OTS_MEETS_SEARCH?.route, { state: { keepSearchData: true } });
    }
    else if (otsMeetHeaderState.myOtsMeets === false && otsMeetHeaderState.myLscOtsMeetSearch === false) {
      clearOtsMeetObjData();
      clearOtsMeetHeaderObjData();
      clearOtsMeetArrayData();
      navigate(navRoutes.OTS_MEET_SEARCH?.route, { state: { keepSearchData: true } });
    }
  };

  function getBackToLabel() {
    return BACK_TO_LABELS[`${(otsMeetFiltersState.backToSystem || '').toLowerCase()}BackToLabel`] || BACK_TO_LABELS.otsMeetBackToLabel;
  };

  return {
    otsMeetHeaderState,
    backToLabel: getBackToLabel(),
    onBackClicked
  };
};

export default useOfficialsTrackingSystemMeetContextView;