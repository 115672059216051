import { useEffect } from 'react';

import useMeetOfficialRoleTypeData from '../../../state/meetOfficialRoleType/UseMeetOfficialRoleTypeData';

const useMeetOfficialRoleTypeDropdown = (orgLevelId, hasEvaluationForm) => {
  const { meetOfficialRoleTypeState, getMeetOfficialRoleTypes } = useMeetOfficialRoleTypeData();

  useEffect(() => {
    if (meetOfficialRoleTypeState.isArrayLoading !== true && (meetOfficialRoleTypeState.isArrayLoaded !== true ||
      orgLevelId !== meetOfficialRoleTypeState.orgLevelId ||
      hasEvaluationForm !== meetOfficialRoleTypeState.hasEvaluationForm)) {
      getMeetOfficialRoleTypes(orgLevelId, hasEvaluationForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetOfficialRoleTypeState, orgLevelId, hasEvaluationForm]);

  return {
    meetOfficialRoleTypeState
  };
};

export default useMeetOfficialRoleTypeDropdown;