const INITIAL_STATE = {
  tryRedirect: false,
  showFilters: true,
  showAdvanced: false
};

const SearchMemberData = {
  INITIAL_STATE,
}

export default SearchMemberData;