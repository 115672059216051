import { useEffect } from 'react';

import validate from './SearchMemberValidation';

import useBasePersonData from '../../../state/personBase/UseBasePersonData';
import useSearchMemberData from '../../../state/searchMember/UseSearchMemberData';
import useOrgUnitData from '../../../state/orgUnit/UseOrgUnitData';
import useOrgRoleHierarchyData from '../../../state/orgRoleHierarchy/UseOrgRoleHierarchyData';
import useEnvironmentVariableData from '../../../state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from '../../../state/security/UseSecurityData';

import useStatesCombobox from '../../comboboxes/statesCombobox/UseStatesCombobox';

import useForm from '../../../utils/UseForm';
import Constants from '../../../utils/Constants';
import HierarchicalDataUtils from '../../../utils/HierarchicalDataUtils';

const competitionGenderOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Male", value: 1 },
  { id: 2, name: "Female", value: 2 }];

const isCurrentOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Current", value: true },
  { id: 2, name: "Expired", value: false }];

const sortByOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "First Name (A-Z)", value: 'FirstName' },
  { id: 2, name: "Last Name (A-Z)", value: 'LastName' },
  { id: 4, name: "Competition Category (Female then Male)", value: 'Gender' },
  { id: 5, name: "Birth Date (Oldest to Youngest)", value: 'BirthDate' },
  { id: 6, name: "Member Type - Member Role", value: 'RoleName' },
  { id: 7, name: "Organization (A-Z)", value: 'ClubCode' }];

const useSearchMember = (initialFormState, configuredRoleId = Constants.DEFAULT_ID) => {
  const { basePersonState, basePersonFiltersState, searchPersons } = useBasePersonData();
  const { searchMemberState, setSearchMemberState } = useSearchMemberData();
  const { orgUnitState, getOrgUnitByOrgUnitId } = useOrgUnitData();
  const { orgRoleHierarchyState } = useOrgRoleHierarchyData();
  const { getStateNameById } = useStatesCombobox();
  const { contextSecurityState } = useSecurityData();
  const { environmentVariableState, USAS_ORGANIZATION_ID, NCAA_ORGANIZATION_ID, ISL_ORGANIZATION_ID,
    FINA_ORGANIZATION_ID, USAS_ORG_UNIT_ID, LSC_ORG_LEVEL_ID, CLUB_ORG_LEVEL_ID } = useEnvironmentVariableData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors, onValueTextPairChanged,
    setIsDirty, isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onFiltersToggle = () => {
    setSearchMemberState({ ...searchMemberState, showFilters: !searchMemberState.showFilters });
    setIsDirty(true);
  };

  const onShowHideAdvancedSearch = () => {
    setFormState({
      ...formState,
      city: '',
      state: '',
      stateName: '',
      zip: '',
      phoneNumber: Constants.DEFAULT_PHONE_NUMBER_AREA_CODE,
      orgRole: [],
      isCurrentId: Constants.DEFAULT_ID,
      isCurrentName: '',
      memberId: '',
      email: ''
    });
    setSearchMemberState({
      ...searchMemberState,
      showAdvanced: !searchMemberState.showAdvanced
    });
  };

  const getSelfOrgLevelIdForIdRecurse = (id, node) => {
    let selfOrgLevelId = null;
    if (id === node.id) {
      selfOrgLevelId = node.selfOrgLevelId;
    } else if (Array.isArray(node.children) && node.children.length > 0) {
      node.children.forEach(child => {
        if (!selfOrgLevelId) {
          selfOrgLevelId = getSelfOrgLevelIdForIdRecurse(id, child);
        }
      });
    }

    return selfOrgLevelId;
  };

  const getParentOrgUnitIdForIdRecurse = (id, node) => {
    let parentOrgUnitId = null;
    if (id === node.id) {
      parentOrgUnitId = node.parentOrgUnitId;
    } else if (Array.isArray(node.children) && node.children.length > 0) {
      node.children.forEach(child => {
        if (!parentOrgUnitId) {
          parentOrgUnitId = getParentOrgUnitIdForIdRecurse(id, child);
        }
      });
    }

    return parentOrgUnitId;
  };

  //Sets initial form state once
  useEffect(() => {
    if (orgUnitState.isArrayLoaded === true && environmentVariableState.isLoaded === true) {
      setFormState(getInitialFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState.isArrayLoaded, environmentVariableState.isLoaded]);

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && orgUnitState.isArrayLoaded === true &&
      formState.orgUnit.length > 0) {
      if (orgUnitState.isObjLoaded === false || (orgUnitState.isObjLoaded === true &&
        orgUnitState.objData.orgUnitId !== formState.orgUnit[0].id)) {
        getOrgUnitByOrgUnitId(formState.orgUnit[0].id);
      }
      if (orgUnitState.isObjLoaded === true) {
        const usasOrganizationsTreeData = JSON.parse(JSON.stringify(orgUnitState.treeData)).find(x => x.id === USAS_ORG_UNIT_ID);
        const usasOrganizationsArrayData = JSON.parse(JSON.stringify(orgUnitState.arrayData)).find(x => x.id === USAS_ORG_UNIT_ID);

        let usasOrg = [];
        let usasLscOrClub = false;
        if (formState.orgUnit.length > 0) {
          usasOrg = HierarchicalDataUtils.GetNameIdPairs([usasOrganizationsTreeData], [formState.orgUnit[0].id]) || [];

          let newObj = null;
          let id = formState.orgUnit[0].id;

          [usasOrganizationsArrayData].forEach(node => {
            if (!newObj) {
              const selfOrgLevelIdForIdRecurse = getSelfOrgLevelIdForIdRecurse(id, node);
              if (selfOrgLevelIdForIdRecurse) {
                newObj = { id, selfOrgLevelIdForIdRecurse }
              }
            }
          });
          if (newObj?.selfOrgLevelIdForIdRecurse === LSC_ORG_LEVEL_ID) {
            usasLscOrClub = true;
          }
          else if (newObj?.selfOrgLevelIdForIdRecurse === CLUB_ORG_LEVEL_ID) {
            let newObj2 = null;
            [usasOrganizationsArrayData].forEach(node => {
              if (!newObj2) {
                const parentOrgUnitIdForIdRecurse = getParentOrgUnitIdForIdRecurse(id, node);
                if (parentOrgUnitIdForIdRecurse) {
                  newObj2 = { id, parentOrgUnitIdForIdRecurse }
                }
              }
            });
            usasLscOrClub = true;
          }
        }
        if (orgUnitState.objData.organization.organizationId === USAS_ORGANIZATION_ID
          && formState.organizationId !== USAS_ORGANIZATION_ID) {
          setFormState({
            ...formState,
            organizationId: USAS_ORGANIZATION_ID,
            orgRole: formState.keepOrgRole === true ? formState.orgRole : [],
            usasOrg: usasOrg.length > 0 ? true : false,
            usasLscOrClub: usasLscOrClub
          });
        }
        else if (orgUnitState.objData.organization.organizationId === FINA_ORGANIZATION_ID
          && formState.organizationId !== FINA_ORGANIZATION_ID) {
          setFormState({
            ...formState,
            organizationId: FINA_ORGANIZATION_ID,
            orgRole: formState.keepOrgRole === true ? formState.orgRole : [],
            usasOrg: usasOrg.length > 0 ? true : false,
            usasLscOrClub: usasLscOrClub
          });
        }
        else if (orgUnitState.objData.organization.organizationId === ISL_ORGANIZATION_ID
          && formState.organizationId !== ISL_ORGANIZATION_ID) {
          setFormState({
            ...formState,
            organizationId: ISL_ORGANIZATION_ID,
            orgRole: formState.keepOrgRole === true ? formState.orgRole : [],
            usasOrg: usasOrg.length > 0 ? true : false,
            usasLscOrClub: usasLscOrClub
          });
        }
        else if (orgUnitState.objData.organization.organizationId === NCAA_ORGANIZATION_ID
          && formState.organizationId !== NCAA_ORGANIZATION_ID) {
          setFormState({
            ...formState,
            organizationId: NCAA_ORGANIZATION_ID,
            orgRole: formState.keepOrgRole === true ? formState.orgRole : [],
            usasOrg: usasOrg.length > 0 ? true : false,
            usasLscOrClub: usasLscOrClub
          });
        }
        else {
          setFormState({
            ...formState,
            usasOrg: usasOrg.length > 0 ? true : false,
            usasLscOrClub: usasLscOrClub
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState.isLoaded, orgUnitState.isArrayLoaded, orgUnitState.isObjLoaded, formState.orgUnit]);

  useEffect(() => {
    if (Object.keys(basePersonFiltersState.filterObject).length > 0 &&
      orgUnitState.isArrayLoaded === true &&
      configuredRoleId === Constants.DEFAULT_ID) {
      const oldSearchValues = JSON.parse(JSON.stringify(basePersonFiltersState.filterObject));
      let isCurrentIndex = undefined;
      for (let i = 0; i < isCurrentOptions.length; i++) {
        if (basePersonFiltersState.filterObject.isCurrent === isCurrentOptions[i].value) {
          isCurrentIndex = i;
          break;
        }
      }

      let sortByIndex = undefined;
      for (let i = 0; i < sortByOptions.length; i++) {
        if (basePersonFiltersState.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }

      setFormState({
        ...formState,
        ...oldSearchValues,
        orgUnit: HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, [oldSearchValues.orgUnitId]) || [],
        stateName: oldSearchValues.state ? getStateNameById(oldSearchValues.state) : '',
        orgRole: oldSearchValues.roleId ? HierarchicalDataUtils.GetNameIdPairs(orgRoleHierarchyState.treeData, [oldSearchValues.roleId]) : [],
        keepOrgRole: oldSearchValues.roleId ? true : false,
        isCurrentId: isCurrentIndex !== undefined ? isCurrentOptions[isCurrentIndex].id : isCurrentOptions[0].id,
        isCurrentName: isCurrentIndex !== undefined ? isCurrentOptions[isCurrentIndex].name : isCurrentOptions[0].name,
        nonOrganizationMember: oldSearchValues.nonOrganizationMember ?? false,
        sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
        sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
      });
      setIsDirty(true);
      if (basePersonState.isArrayLoading === false) {
        searchPersons(basePersonFiltersState.filterObject, basePersonFiltersState.sortBy, basePersonFiltersState.isAdvancedSearch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState.isArrayLoaded]);

  useEffect(() => {
    if (Object.keys(basePersonFiltersState.filterObject).length > 0
      && orgUnitState.isArrayLoaded === true) {
      const oldSearchValues = JSON.parse(JSON.stringify(basePersonFiltersState.filterObject));
      if (oldSearchValues.roleId) {
        if (orgUnitState.objData.orgUnitId === formState.orgUnit[0].id &&
          orgUnitState.objData.organization.organizationId === formState.organizationId) {
          setFormState({
            ...formState,
            keepOrgRole: false
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState.isArrayLoaded, orgUnitState.isObjLoaded, formState.orgUnit, formState.organizationId]);

  useEffect(() => {
    if (searchMemberState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMemberState.showFilters, isDirty]);

  // Called by useForm after passing validation, makes API call and shows results grid
  function submitFormCallback(formState) {
    setSearchMemberState({ ...searchMemberState, tryRedirect: false });

    let sortBy = undefined;
    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    if (basePersonState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchPersons(filterObject, sortBy || '', searchMemberState.showAdvanced);
    }
  };

  // Builds a filter object to be used during API GET request
  function createSearchFilterObj(formState) {
    //set role id that is passed in through the <MiniSearchMember component if populated
    const roleIdFromFormState = formState.orgRole.length > 0 ? formState.orgRole[0].id : undefined;
    const roleId = roleIdFromFormState === undefined && configuredRoleId > 0 ? configuredRoleId : roleIdFromFormState;

    return {
      orgUnitId: formState.orgUnit.length > 0 ? formState.orgUnit[0].id : undefined,
      birthDate: formState.birthDate !== Constants.BLANK_DATE_STRING ? formState.birthDate : undefined,
      firstName: formState.firstName.trim() || undefined,
      middleName: formState.middleName.trim() || undefined,
      lastName: formState.lastName.trim() || undefined,
      competitionGenderTypeId: formState.competitionGenderTypeId > 0 ? formState.competitionGenderTypeId : undefined,
      city: formState.city.trim() || undefined,
      state: formState.stateName !== '' ? formState.state : undefined,
      zip: formState.zip.trim() || undefined,
      memberId: formState.memberId.trim() || undefined,
      email: formState.email.trim() || undefined,
      phoneNumber: formState.phoneNumber.trim() !== '' && !Constants.PHONE_AREA_CODES.find(x => x === formState.phoneNumber.trim()) ? formState.phoneNumber.trim() : undefined,
      roleId: roleId,
      nonOrganizationMember: formState.nonOrganizationMember === true ? formState.nonOrganizationMember : undefined,
      isCurrent: formState.isCurrentId > 0 ? isCurrentOptions.find(x => x.id === formState.isCurrentId).value : undefined
    };
  };

  // Holds initial formState values
  function getInitialFormState() {
    return {
      USAS_ORG_UNIT_ID: USAS_ORG_UNIT_ID,
      organizationId: Constants.DEFAULT_ID,
      orgUnit: orgUnitState.treeData.length > 0
        ? HierarchicalDataUtils.GetNameIdPairs(orgUnitState.treeData, [orgUnitState.treeData[0]?.id]) : [],
      usasOrg: false,
      usasLscOrClub: false,
      birthDate: Constants.BLANK_DATE_STRING,
      firstName: '',
      middleName: '',
      lastName: '',
      city: '',
      state: '',
      stateName: '',
      zip: '',
      memberId: '',
      email: '',
      phoneNumber: Constants.DEFAULT_PHONE_NUMBER_AREA_CODE,
      competitionGenderTypeId: competitionGenderOptions[0].id,
      competitionGenderTypeName: competitionGenderOptions[0].name,
      orgRole: [],
      keepOrgRole: false,
      isCurrentId: isCurrentOptions[0].id,
      isCurrentName: isCurrentOptions[0].name,
      nonOrganizationMember: false,
      sortById: sortByOptions[0].id,
      sortByName: sortByOptions[0].name,
      ...initialFormState
    };
  };

  return {
    basePersonState,
    searchMemberState,
    setSearchMemberState,
    orgUnitState,
    formState,
    errorState,
    onValueTextPairChanged,
    onFormValueChanged: updateFormState,
    handleSubmit,
    clearForm,
    onFiltersToggle,
    onShowHideAdvancedSearch,
    competitionGenderOptions,
    isCurrentOptions,
    sortByOptions,
    contextSecurityState,
    isLoading: basePersonState.isArrayLoading || basePersonState.isObjLoading || orgUnitState.isArrayLoading || contextSecurityState.isObjLoading
  };
}

export default useSearchMember;