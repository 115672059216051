import { useEffect } from 'react';

import { navLinks } from './UseMemberAdministration';

import useSecurityData from '../../../common/state/security/UseSecurityData';
import useEnvironmentVariableData from '../../../common/state/environmentVariable/UseEnvironmentVariableData';

import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

const TAXONOMIES = ['HQMemberAdministrationDetail'];
const SCOPE = 'MemberAdmin';

const useMemberAdministrationContextView = () => {
  const navigate = useNavigate();
  const { environmentVariableState, USAS_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.HQ_BOARD);
  }

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      if (contextSecurityState.isObjLoading !== true && USAS_ORG_UNIT_ID === contextSecurityState.contextId
        && contextSecurityState.scope === SCOPE) {
        getContextSecurity(USAS_ORG_UNIT_ID, TAXONOMIES, SCOPE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, contextSecurityState]);

  return {
    onBackClicked
  };
};

export default useMemberAdministrationContextView;