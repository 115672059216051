import { Fragment } from 'react';

import DatePicker from '../../../../../../common/components/datepickers/DatePicker';

import { formatDate, getTodaysDate } from '../../../../../../common/utils/DateFunctions';
import { isValidDate } from '../../../../../../common/utils/validation';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ position, formState, showError, onUpdateEffectiveDateValueChange, onUpdateExpirationDateValueChange }) => {
  const todayDate = getTodaysDate();
  const today = new Date(todayDate);
  const expirationDateLocal = new Date(position?.expirationDate);
  const effectiveDate = position?.selectedEffectiveDate ? position?.selectedEffectiveDate : formatDate(position?.effectiveDate);
  const expirationDate = position?.selectedExpirationDate ? position?.selectedExpirationDate : formatDate(position?.expirationDate);
  const effectiveDateDateFormat = new Date(effectiveDate);
  const expirationDateDateFormat = new Date(expirationDate);
  
  if (formState?.showAllNationalCertificationHistory === false && (today <= expirationDateLocal)) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{position?.positionTypeName} - {position?.positionAbbreviation}</span>&nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                Position
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {position?.positionTypeName} - {position?.positionAbbreviation}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Natl Certification
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {position?.levelName || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-12', global.SmallTableRowLabels].join(' ')}>
                Effective Date
              </div>
              <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
                <DatePicker
                  label=""
                  name={"effectiveDate" + position?.personPositionCertificationId + "personPositionCertificationId"}
                  value={position?.selectedEffectiveDate}
                  onChange={(value) => { onUpdateEffectiveDateValueChange(position, value); }}
                  countOfYears={24}
                  startYearOffset={-24}
                  error={showError === true && ((effectiveDateDateFormat > expirationDateDateFormat) || !isValidDate(effectiveDate))}
                  message={!isValidDate(effectiveDate) ? 'Effective Date must be a valid date' : (effectiveDateDateFormat > expirationDateDateFormat) ? 'Effective Date must be on or before the Expiration Date' : ''}
                />
              </div>
              <div className={['col-xs-12', global.SmallTableRowLabels].join(' ')}>
                Expiration Date
              </div>
              <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
                <DatePicker
                  label=""
                  name={"expirationDate" + position?.personPositionCertificationId + "personPositionCertificationId"}
                  value={position?.selectedExpirationDate}
                  onChange={(value) => { onUpdateExpirationDateValueChange(position, value); }}
                  countOfYears={27}
                  startYearOffset={-24}
                  error={showError === true && ((effectiveDateDateFormat > expirationDateDateFormat) || !isValidDate(expirationDate))}
                  message={!isValidDate(expirationDate) ? 'Expiration Date must be a valid date' : (effectiveDateDateFormat > expirationDateDateFormat) ? 'Expiration Date must be on or after the Effective Date' : ''}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  else if (formState?.showAllNationalCertificationHistory === true) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{position?.positionTypeName} - {position?.positionAbbreviation}</span>&nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                Position
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {position?.positionTypeName} - {position?.positionAbbreviation}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Natl Certification
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {position?.levelName || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-12', global.SmallTableRowLabels].join(' ')}>
                Effective Date
              </div>
              <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
                <DatePicker
                  label=""
                  name={"effectiveDate" + position?.personPositionCertificationId + "personPositionCertificationId"}
                  value={position?.selectedEffectiveDate}
                  onChange={(value) => { onUpdateEffectiveDateValueChange(position, value); }}
                  countOfYears={24}
                  startYearOffset={-24}
                  error={showError === true && ((effectiveDateDateFormat > expirationDateDateFormat) || !isValidDate(effectiveDate))}
                  message={!isValidDate(effectiveDate) ? 'Effective Date must be a valid date' : (effectiveDateDateFormat > expirationDateDateFormat) ? 'Effective Date must be on or before the Expiration Date' : ''}
                />
              </div>
              <div className={['col-xs-12', global.SmallTableRowLabels].join(' ')}>
                Expiration Date
              </div>
              <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
                <DatePicker
                  label=""
                  name={"expirationDate" + position?.personPositionCertificationId + "personPositionCertificationId"}
                  value={position?.selectedExpirationDate}
                  onChange={(value) => { onUpdateExpirationDateValueChange(position, value); }}
                  countOfYears={27}
                  startYearOffset={-24}
                  error={showError === true && ((effectiveDateDateFormat > expirationDateDateFormat) || !isValidDate(expirationDate))}
                  message={!isValidDate(expirationDate) ? 'Expiration Date must be a valid date' : (effectiveDateDateFormat > expirationDateDateFormat) ? 'Expiration Date must be on or after the Effective Date' : ''}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  else {
    return (
      <Fragment />
    )
  }
}

const OtsOfficialNationalCertificationHistorySmallWriteGrid = ({ gridState, state, formState, showError, onUpdateEffectiveDateValueChange, onUpdateExpirationDateValueChange }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(gridState?.arrayData) && gridState?.arrayData?.length > 0
      ? gridState?.arrayData?.map((position, i) =>
        <SmallGridRow
          key={i}
          position={position}
          formState={formState}
          showError={showError}
          onUpdateEffectiveDateValueChange={onUpdateEffectiveDateValueChange}
          onUpdateExpirationDateValueChange={onUpdateExpirationDateValueChange}
        />)
      : (
        <div>
          {state?.isArrayLoading
            ? (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
              </div>
            ) : (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
              </div>
            )
          }
        </div>
      )
    }
  </div>
);

export default OtsOfficialNationalCertificationHistorySmallWriteGrid;