import { API_NAMES, initApi } from "../../utils/HttpApiHelper";

const getGroupPersonCoachesData = (personId, isActive, state, setState) => {
  if (personId) {
    const newState = {
      ...state,
      personId,
      areOptionsLoaded: false
    };

    const api = initApi(API_NAMES.PERSON, newState, setState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : '';
    const url = `/Person/${personIdForUrl}/orgUnitCoach${isActive === null ? '' : `?isActive=${isActive}`}`;
    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const GroupPersonCoachesData = {
  getGroupPersonCoachesData
};

export default GroupPersonCoachesData;