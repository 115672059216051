import { DEFAULT_ID } from "../../../common/utils/Constants";
import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const postOfferingInstanceOrgUnitData = (offeringInstanceOrgUnitObj, state, setState) => {
  if (offeringInstanceOrgUnitObj) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const url = `/OfferingInstanceOrgUnit`;

    return api?.executeObject ? api.executeObject(url, 'POST', offeringInstanceOrgUnitObj) : null;
  }
};

const getOfferingInstancesOrgUnitData = (orgUnitId, reportPeriodId, state, setState) => {
  if (orgUnitId && reportPeriodId > 0) {
    const newState = {
      ...state,
      orgUnitId,
      reportPeriodId
    };

    const api = initApi(API_NAMES.OMR, newState, setState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const reportPeriodIdForUrl = reportPeriodId ? encodeURIComponent(reportPeriodId) : 'NaN';
    const url = `/OfferingInstanceOrgUnit/${orgUnitIdForUrl}?reportPeriodId=${reportPeriodIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putOfferingInstanceOrgUnitData = (offeringInstanceOrgUnitId, offeringInstanceOrgUnitObj, state, setState) => {
  if (offeringInstanceOrgUnitId > 0 && offeringInstanceOrgUnitObj) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const offeringInstanceOrgUnitIdForUrl = offeringInstanceOrgUnitId ? encodeURIComponent(offeringInstanceOrgUnitId) : 'NaN';
    const url = `/OfferingInstanceOrgUnit/${offeringInstanceOrgUnitIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'PUT', offeringInstanceOrgUnitObj) : null;
  }
};

const deleteOfferingInstanceOrgUnitData = (offeringInstanceOrgUnitId, state, setState) => {
  if (offeringInstanceOrgUnitId) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const offeringInstanceOrgUnitIdForUrl = offeringInstanceOrgUnitId ? encodeURIComponent(offeringInstanceOrgUnitId) : 'NaN';
    const url = `/OfferingInstanceOrgUnit/${offeringInstanceOrgUnitIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const INITIAL_OFFERING_MANAGEMENT_INSTANCE_ORG_UNIT_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  reportPeriodId: DEFAULT_ID
};

const OfferingManagementInstanceOrgUnitData = {
  postOfferingInstanceOrgUnitData,
  getOfferingInstancesOrgUnitData,
  putOfferingInstanceOrgUnitData,
  deleteOfferingInstanceOrgUnitData,

  INITIAL_OFFERING_MANAGEMENT_INSTANCE_ORG_UNIT_STATE
};

export default OfferingManagementInstanceOrgUnitData;