import { useContext } from 'react';

import MemberAdministrationFoundationBoardData from './MemberAdministrationFoundationBoardData';
import { MemberAdministrationFoundationBoardStateContext } from './MemberAdministrationFoundationBoardContextProvider';

const useMemberAdministrationFoundationBoardData = () => {
  const [memberAdministrationFoundationBoardState, setMemberAdministrationFoundationBoardState] = useContext(MemberAdministrationFoundationBoardStateContext);

  const getFoundationBoardDirectors = () => 
    MemberAdministrationFoundationBoardData.getFoundationBoardDirectors(
        memberAdministrationFoundationBoardState, setMemberAdministrationFoundationBoardState);
  const postFoundationBoardDirector = (orgUnitId, roleGroupId, staff) => 
    MemberAdministrationFoundationBoardData.postFoundationBoardDirector(
        orgUnitId, roleGroupId, staff, memberAdministrationFoundationBoardState, setMemberAdministrationFoundationBoardState);
  const putFoundationBoardDirector = (orgUnitId, personId, roleGroupId, staff) => 
    MemberAdministrationFoundationBoardData.putFoundationBoardDirector(
        orgUnitId, personId, roleGroupId, staff, memberAdministrationFoundationBoardState, setMemberAdministrationFoundationBoardState);
  const deleteFoundationBoardDirector = (personOrgRoleDurationId) => 
    MemberAdministrationFoundationBoardData.deleteFoundationBoardDirector(
        personOrgRoleDurationId, memberAdministrationFoundationBoardState, setMemberAdministrationFoundationBoardState);

  const confirmSave = () => {
    setMemberAdministrationFoundationBoardState({
      ...memberAdministrationFoundationBoardState,
      isSaved: false
    });
  };

  return {
    memberAdministrationFoundationBoardState,
    getFoundationBoardDirectors,
    postFoundationBoardDirector,
    putFoundationBoardDirector,
    deleteFoundationBoardDirector,
    confirmSave
  };
};

export default useMemberAdministrationFoundationBoardData;