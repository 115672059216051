import { Fragment } from 'react';

import MemberCoursesLargeWriteGrid from './MemberCoursesLargeWriteGrid';
import MemberCoursesSmallWriteGrid from './MemberCoursesSmallWriteGrid';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MemberCoursesWriteGrid = ({ gridData, isLoading, onEditCourse, viewLocation }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MemberCoursesLargeWriteGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditCourse={onEditCourse}
        viewLocation={viewLocation}
        expandedId={expandedId}
        onClick={onClick} />
      <MemberCoursesSmallWriteGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditCourse={onEditCourse}
        viewLocation={viewLocation}
        expandedId={expandedId}
        onClick={onClick} />
    </Fragment>
  );
};

export default MemberCoursesWriteGrid;
