import { createContext, useState } from 'react';

import OfferingManagementInstanceOrgUnitData from './OfferingManagementInstanceOrgUnitData';

export const OfferingManagementInstanceOrgUnitStateContext = createContext();

const OfferingManagementInstanceOrgUnitContextProvider = ({ children }) => {
  const stateHook = useState(OfferingManagementInstanceOrgUnitData.INITIAL_OFFERING_MANAGEMENT_INSTANCE_ORG_UNIT_STATE);

  return (
    <OfferingManagementInstanceOrgUnitStateContext.Provider value={stateHook}>
      {children}
    </OfferingManagementInstanceOrgUnitStateContext.Provider>
  );
};

export default OfferingManagementInstanceOrgUnitContextProvider;