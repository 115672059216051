import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getOfferingInstanceCouponDiscountsData = (orgUnitId, state, setState) => {
  if (orgUnitId) {
    const newState = {
      ...state,
      orgUnitId
    };

    const api = initApi(API_NAMES.OMR, newState, setState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/OfferingInstanceCouponDiscount/orgUnit/${orgUnitIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putOfferingInstanceCouponDiscountData = (offeringInstanceCouponDiscountId, offeringInstanceCouponDiscountObj, state, setState) => {
  if (offeringInstanceCouponDiscountId > 0 && offeringInstanceCouponDiscountObj) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const offeringInstanceCouponDiscountIdForUrl = offeringInstanceCouponDiscountId ? encodeURIComponent(offeringInstanceCouponDiscountId) : 'NaN';
    const url = `/OfferingInstanceCouponDiscount/${offeringInstanceCouponDiscountIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'PUT', offeringInstanceCouponDiscountObj) : null;
  }
};

const INITIAL_OFFERING_INSTANCE_COUPON_DISCOUNTS_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: ''
};

const OfferingInstanceCouponDiscountsData = {
  INITIAL_OFFERING_INSTANCE_COUPON_DISCOUNTS_STATE,

  getOfferingInstanceCouponDiscountsData,
  putOfferingInstanceCouponDiscountData
};

export default OfferingInstanceCouponDiscountsData;