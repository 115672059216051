import React from 'react';

import useOtsMeetReporting from './UseOtsMeetReporting';

import ReportList from '../../../../../common/components/embeddedReports/ReportList';

const OtsMeetReporting = () => {
  const { reportParameters, routeName } = useOtsMeetReporting();

  return (
    <ReportList
      listTitle="OTS Meet Reports"
      routeName={routeName}
      reportParameters={reportParameters} />
  );
};

export default OtsMeetReporting;