import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';
import Constants from '../../../../common/utils/Constants';

import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const TITLE = 'Narratives';

const useMemberNarratives = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personProfileState, getPersonProfile, putPersonProfile } = usePersonProfileData();
  const [modalState, setModalState] = useState(getInitialModalState());

  const onAddEditNarrative = (e, personNarrativeId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NARRATIVES_DETAIL?.route, { state: { personNarrativeId } });
  };

  const onDeleteNarrative = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const personObj = JSON.parse(JSON.stringify(personProfileState.objData));
    const index = personObj.narratives.findIndex(e => e.personNarrativeId === modalState.personNarrativeId);
    if (index > Constants.DEFAULT_ID) {
      personObj.narratives.splice(index, 1);
      putPersonProfile(personProfileState.objData.personId, personObj);
    }

    setModalState({
      ...getInitialModalState()
    });
  };

  // Called by grid delete button, populates modal with target narrative info
  const onOpenModalClicked = (e, personNarrativeId, narrativeName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      personNarrativeId: personNarrativeId,
      narrativeName: narrativeName,
      displayPopUp: true
    });
  };

  // Clears modalState and closes modal
  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...getInitialModalState()
    });
  };

  useEffect(() => {
    if (personProfileState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personProfileState.objData)) {
      getPersonProfile(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  // Holds initial state for delete modal
  function getInitialModalState() {
    return {
      personNarrativeId: Constants.DEFAULT_ID,
      narrativeName: '',
      displayPopUp: false,
      modalTitle: 'Delete this Narrative?'
    };
  };

  return {
    title: TITLE,
    DEFAULT_ID: Constants.DEFAULT_ID,
    personProfileState,
    modalState,
    onAddEditNarrative,
    onOpenModalClicked,
    onModalCanceled,
    onDeleteNarrative
  };
};

export default useMemberNarratives;