import React from 'react';

import DateData from '../../../common/state/date/DateData';

import global from '../../../common/components/GlobalStyle.module.css';

//TODO maybe add to common?
const YearSpecificDropdown = ({ label, name, options, value, error, message, onChange, id, showEmptySelection, isLoading }) => {
  const LogicDropdown = ({ error, label, message, options, name, value, onChange, isLoading }) => (
    <div className={global.ComponentFlex}>
      {label && <label className={global.UsasLabel} htmlFor={name}>{label}</label>}
      {isLoading === true
        ? <div className={global.InputLoading}>Loading...</div>
        : showEmptySelection === true
          ? (
            <select
              className={error ? global.Error : global.BaseInputBox}
              value={value}
              name={name}
              id={id}
              onChange={onChange}>
              <option value="" disabled hidden>--</option>
              {options.map((option, i) => <option key={i} value={option.value}>{option.name}</option>)}
            </select>
          )
          : (
            <select
              className={error ? global.Error : global.BaseInputBox}
              value={value}
              name={name}
              id={id}
              onChange={onChange}>
              {options.map((option, i) => <option key={i} value={option.value}>{option.name}</option>)}
            </select>
          )}
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </div>
  );

  return (
    <LogicDropdown
      error={error}
      label={label}
      message={message}
      options={options}
      name={name}
      value={value}
      isLoading={isLoading}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

const YearDropdown = ({ label, name, value, error, message, onChange, countOfYears, startYearOffset }) => {
  return (
    <YearSpecificDropdown
      error={error}
      label={label}
      message={message}
      options={DateData.getYearsAsOptions(countOfYears, startYearOffset)}
      name={name}
      value={value}
      onChange={(newValue) => onChange(newValue)} />);
};

export default YearDropdown;