import { isValidDate, isValidBirthDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  //passport #
  // if (formState.passportNumber.trim() === '') {
  //   errors.passportNumber = "Passport Number is required";
  // }

  //passport effective date
  if (formState.passportEffectiveDate === Constants.BLANK_DATE_STRING) {
    errors.passportEffectiveDate = 'Passport Effective Date is required';
  } else if (!isValidDate(formState.passportEffectiveDate)) {
    errors.passportEffectiveDate = 'Passport Effective Date must be a valid date';
  } else if (!isValidBirthDate(formState.passportEffectiveDate)) {
    errors.passportEffectiveDate = 'Passport Effective Date cannot be in the future';
  }

  //passport expire date
  if (formState.passportExpirationDate === Constants.BLANK_DATE_STRING) {
    errors.passportExpirationDate = 'Passport Expiration Date is required';
  } else if (!isValidDate(formState.passportExpirationDate)) {
    errors.passportExpirationDate = 'Passport Expiration Date must be a valid date';
  }

  const startDate = new Date(formState.passportEffectiveDate);
  const endDate = new Date(formState.passportExpirationDate);

  //Effective Date
  if (formState.passportEffectiveDate !== Constants.BLANK_DATE_STRING) {
    if (endDate < startDate && formState.passportEffectiveDate !== formState.passportExpirationDate) {
      errors.passportEffectiveDate = 'Passport Effective Date must be before the Passport Expiration Date';
    }
  }

  //Expiration Date
  if (formState.passportExpirationDate !== Constants.BLANK_DATE_STRING) {
    if (endDate < startDate && formState.passportEffectiveDate !== formState.passportExpirationDate) {
      errors.passportExpirationDate = 'Passport Expiration Date must be after the Passport Effective Date';
    }
  }

  return errors;
};

const NationalTeamAdvancedInfoValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default NationalTeamAdvancedInfoValidation;