import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MemberNarrativeDetailValidation';

import useNarrativeTypeData from '../../../state/narrativeType/UseNarrativeTypeData';
import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const useMemberNarrativeDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personProfileState, putPersonProfile } = usePersonProfileData();
  const { narrativeTypeState } = useNarrativeTypeData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, onValueTextPairChanged } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState(getInitialState());

  // Redirects user to previous page
  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NARRATIVES?.route);
  };

  // Redirects the user to the previous page once all API calls have finished
  useEffect(() => {
    if (state.tryRedirect === true && personProfileState.isObjLoading === false && personProfileState.isSaving === false) {
      navigate(navRoutes.MEMBER_NARRATIVES?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState, state.tryRedirect]);


  // Setup useEffect, determines if the form is going to be used for add or edit, then populates formState accordingly
  useEffect(() => {
    if (narrativeTypeState.isLoaded === true) {
      if (location.state && location.state.personNarrativeId && personProfileState.isObjLoaded === true) {
        const personNarrativeId = location.state.personNarrativeId;

        // Edit
        if (personNarrativeId > 0) {
          const selectedNarrative = personProfileState.objData.narratives.find(e => e.personNarrativeId === personNarrativeId);
          setFormState({
            ...formState,
            personNarrativeId: selectedNarrative.personNarrativeId,
            narrativeTypeId: selectedNarrative.narrativeTypeId,
            narrativeDescription: selectedNarrative.narrativeDescription
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personProfileState, narrativeTypeState]);

  // Creates a copy of the personProfileState.objData and makes changes to it based on formState
  function createPutPersonObject() {
    const personToPut = JSON.parse(JSON.stringify(personProfileState.objData));

    // Is Edit
    if (formState.personNarrativeId > 0) {
      const index = personToPut.narratives.findIndex(e => e.personNarrativeId === formState.personNarrativeId);

      if (personToPut.narratives[index].narrativeTypeId === formState.narrativeTypeId &&
        personToPut.narratives[index].narrativeDescription === formState.narrativeDescription) {
        return null;
      }

      personToPut.narratives[index] = {
        ...personToPut.narratives[index],
        narrativeTypeId: formState.narrativeTypeId,
        narrativeDescription: formState.narrativeDescription.trim(),
        narrativeType: undefined
      };
    }

    // Is Add
    else {
      personToPut.narratives.push({
        personId: personProfileState.objData.personId,
        narrativeTypeId: formState.narrativeTypeId,
        narrativeDescription: formState.narrativeDescription.trim()
      });
    }

    return personToPut;
  };

  function submitFormCallback(formState) {
    if (personProfileState.isSaving === false) {
      const personToPut = createPutPersonObject();
      if (personToPut !== null) {
        putPersonProfile(personProfileState.objData.personId, personToPut);
      }
      else {
        onCancelClicked();
      }
    }

    setState({
      ...state,
      tryRedirect: true
    });
  };

  // Holds initial formState
  function getInitialFormState() {
    return {
      personNarrativeId: Constants.DEFAULT_ID,
      narrativeTypeId: Constants.DEFAULT_ID,
      narrativeTypeName: '',
      narrativeDescription: ''
    };
  };

  // Holds initial view state
  function getInitialState() {
    return {
      tryRedirect: false
    };
  };

  return {
    personProfileState,
    formState,
    errorState,
    state,
    narrativeTypeState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked
  };
};

export default useMemberNarrativeDetail;