import React, { createContext, useState } from 'react';

import MeetOfficialSessionData from './MeetOfficialSessionData';

export const MeetOfficialSessionStateContext = createContext();

const MeetOfficialSessionContextProvider = ({ children }) => {
  const stateHook = useState(MeetOfficialSessionData.INITIAL_STATE);

  return (
    <MeetOfficialSessionStateContext.Provider value={stateHook}>
      {children}
    </MeetOfficialSessionStateContext.Provider>
  );
};

export default MeetOfficialSessionContextProvider;