export const ADD_VIEW_TYPE_NEW_OFFERING = 'AddNewOffering';
export const ADD_VIEW_TYPE_ADDITIONAL_OFFERING = 'AddAdditionalOffering';

export const EDIT_VIEW_TYPE_SINGLE_OFFERING = 'EditSingleOffering';
export const EDIT_VIEW_TYPE_MAIN_OFFERING = 'EditMainOffering';
export const EDIT_VIEW_TYPE_ADDITIONAL_OFFERING = 'EditAdditionalOffering';

export const USA_CLUB_MEMBERSHIP_LABEL = 'USA Swimming Club Membership';

const OfferingManagementConstants = {
  ADD_VIEW_TYPE_NEW_OFFERING,
  ADD_VIEW_TYPE_ADDITIONAL_OFFERING,
  EDIT_VIEW_TYPE_SINGLE_OFFERING,
  EDIT_VIEW_TYPE_MAIN_OFFERING,
  EDIT_VIEW_TYPE_ADDITIONAL_OFFERING,
  USA_CLUB_MEMBERSHIP_LABEL
};

export default OfferingManagementConstants;