import { isValidName, isValidEmail, evaluateBirthDate } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //First Name
  if (formState.firstName.trim() === '') {
    errors.firstName = 'Legal First Name is required';
  } else if (!isValidName(formState.firstName.trim())) {
    errors.firstName = 'Legal First Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  //Preferred Name
  if (formState.preferredName.trim() !== '') {
    if (!isValidName(formState.preferredName.trim())) {
      errors.preferredName = 'Preferred Name cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  //Middle Name
  if (formState.middleName.trim() !== '') {
    if (!isValidName(formState.middleName.trim())) {
      errors.middleName = 'Middle Name or Middle Initial cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  //Last Name
  if (formState.lastName.trim() === '') {
    errors.lastName = 'Legal Last Name is required';
  } else if (!isValidName(formState.lastName.trim())) {
    errors.lastName = 'Legal Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  //Maiden Name
  if (formState.maidenName.trim() !== '') {
    if (!isValidName(formState.maidenName.trim())) {
      errors.maidenName = 'Maiden Name cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  //Email
  const birthDateObj = evaluateBirthDate(formState.birthDate);
  if (birthDateObj.isJunior === true || birthDateObj.isAdult === true) {
    if (formState.email.trim() === '' && formState.isEmailRequired === true) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(formState.email.trim()) && formState.isEmailRequired === true) {
      errors.email = 'Email must be in a valid format';
    } else if (formState.email.trim().length > 512) {
      errors.email = 'Email cannot exceed 512 characters'
    }
  }

  //Us Citizen
  if (formState.isUsCitizen !== false && formState.isUsCitizen !== true) {
    errors.isUsCitizen = 'Must select yes or no for this field';
  }

  return errors;
};

const MemberInfoValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MemberInfoValidation;