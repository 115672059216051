import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useOfficialPositionTypeData from '../../../state/officialPositionType/UseOfficialPositionTypeData';
import useCertificationLevelData from '../../../state/certificationLevel/UseCertificationLevelData';
import useOtsOfficialsBulkRecertifyData from '../../../state/officialsTrackingSystemBulkRecertification/UseOtsOfficialsBulkRecertifyData';

import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import usePersonOrgRoleData from '../../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const useOfficialsTrackingSystemLscBulkRecertifyContextView = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { securityState, userInfo, login, contextSecurityState } = useSecurityData();
  const { basePersonState } = useBasePersonData();
  const { selectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();
  const { personOrgRoleState, clearArrayData } = usePersonOrgRoleData();
  const { clearOfficialPositionTypeArrayData } = useOfficialPositionTypeData();
  const { clearCertificationLevelArrayData } = useCertificationLevelData();
  const { resetOtsOfficialBulkRecertifyState, resetSearchOtsOfficialsForBulkRecertifyFilterState } = useOtsOfficialsBulkRecertifyData();

  function goToOtsLscBulkRecertificationRoot() {
    navigate(navRoutes.OTS_LSC_BULK_RECERTIFICATION_SELECTION?.route);
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_LSC_BULK_RECERTIFICATION?.route);
  }

  const onBackToSelectionClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    resetSelectOrgUnitState();
    clearArrayData();
    resetOtsOfficialBulkRecertifyState();
    resetSearchOtsOfficialsForBulkRecertifyFilterState();
    clearOfficialPositionTypeArrayData();
    clearCertificationLevelArrayData();
    goToOtsLscBulkRecertificationRoot();
  }

  useEffect(() => {
    if (userInfo?.isLoggedIn !== true) {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState])

  return {
    isLoggedIn: securityState.isLoggedIn,
    isLoading: securityState.isLoading || contextSecurityState.isObjLoading,
    basePersonState,
    onBackClicked,
    onBackToSelectionClicked,
    selectOrgUnitState,
    personOrgRoleState
  };
};

export default useOfficialsTrackingSystemLscBulkRecertifyContextView;