import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import { API_NAMES, initApi } from '../../utils/HttpApiHelper';

import getPersonByMemberIdData from './GetPersonByMemberIdData';
import getFlatPersonByMemberIdData from './GetFlatPersonByMemberIdData';

const getPersonByMemberIdIsInGoodStandingData = (memberId, isAthleteRole, organizationId, state, setState) => {
  if (memberId && organizationId > 0) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const memberIdForUrl = encodeURIComponent(memberId);
    const isAthleteRoleForUrl = encodeURIComponent(isAthleteRole === true);
    const organizationIdForUrl = encodeURIComponent(organizationId);
    const url = `/Person/memberId/${memberIdForUrl}/GoodStanding?isAthleteRole=${isAthleteRoleForUrl}&organizationId=${organizationIdForUrl}`;
  
    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonbyMemberIdData = {
  INITIAL_STATE, getPersonByMemberIdData, getFlatPersonByMemberIdData, getPersonByMemberIdIsInGoodStandingData
};

export default PersonbyMemberIdData;