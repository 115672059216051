import React, { Fragment } from 'react';

import NationalTeamFrequentFlyerLargeGrid from './NationalTeamFrequentFlyerLargeGrid';
import NationalTeamFrequentFlyerSmallGrid from './NationalTeamFrequentFlyerSmallGrid';

const NationalTeamFrequentFlyerGrid = ({ gridState, onAddEdit, onDelete }) => {

  return (
    <Fragment>
      <NationalTeamFrequentFlyerLargeGrid
        gridState={gridState}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
      />
      <NationalTeamFrequentFlyerSmallGrid
        gridState={gridState}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
      />
    </Fragment>
  )
};

export default NationalTeamFrequentFlyerGrid;