import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ProfileSocialMediaDetailValidation';

import useSocialMediaTypeData from '../../../state/socialMediaType/UseSocialMediaTypeData';
import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useProfileSocialMediaDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personProfileState, putPersonProfile } = usePersonProfileData();
  const { socialMediaTypeState } = useSocialMediaTypeData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, onValueTextPairChanged,
    isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [uiState, setUiState] = useState(getInitialUiState());

  const onSaveClicked = (e) => {
    if (isDirty === false && formState.personSocialMediaId > 0) {
      onCancelClicked(e);
    }
    else {
      handleSubmit(e);
    }
  };

  // Redirects user to previous page
  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_PROFILE_SOCIAL_MEDIA.route);
  };

  // Setup useEffect, determines if the form is going to be used for add or edit, then populates formState accordingly
  useEffect(() => {
    if (socialMediaTypeState.isLoaded === true) {
      if (location.state && location.state.personSocialMediaId && personProfileState.isObjLoaded === true) {
        const personSocialMediaId = location.state.personSocialMediaId;

        // Edit
        if (personSocialMediaId > 0) {
          const selectedPlatform = personProfileState.objData.socialMedia.find(e => e.personSocialMediaId === personSocialMediaId);
          setFormState({
            ...formState,
            personSocialMediaId: selectedPlatform.personSocialMediaId,
            socialMediaTypeId: selectedPlatform.socialMediaTypeId,
            socialHandle: selectedPlatform.socialHandle
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personProfileState, socialMediaTypeState]);

  // Redirects the user to the previous page once all API calls have finished
  useEffect(() => {
    if (uiState.tryRedirect === true && personProfileState.isObjLoading === false && personProfileState.isSaving === false) {
      navigate(navRoutes.MEMBER_PROFILE_SOCIAL_MEDIA?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState, uiState.tryRedirect]);


  // Creates a copy of the personProfileState.objData and makes changes to it based on formState
  function createPutPersonObject() {
    const personToPut = JSON.parse(JSON.stringify(personProfileState.objData));

    // Is Edit
    if (formState.personSocialMediaId > 0) {
      const index = personToPut.socialMedia.findIndex(e => e.personSocialMediaId === formState.personSocialMediaId);

      if (personToPut.socialMedia[index].socialMediaTypeId === formState.socialMediaTypeId &&
        personToPut.socialMedia[index].socialHandle === formState.socialHandle) {
        return null;
      }

      personToPut.socialMedia[index] = {
        ...personToPut.socialMedia[index],
        socialMediaTypeId: formState.socialMediaTypeId,
        socialHandle: formState.socialHandle.trim(),
        socialMediaType: null
      };
    }
    // Is Add
    else {
      let socialMedia = personToPut.socialMedia;

      socialMedia.push({
        personId: personToPut.personId,
        socialMediaTypeId: formState.socialMediaTypeId,
        socialHandle: formState.socialHandle.trim(),
        socialMediaType: null
      });

      personToPut.socialMedia = socialMedia;
    }

    return personToPut;
  }

  function submitFormCallback(formState) {
    if (personProfileState.isSaving === false) {
      const personToPut = createPutPersonObject();
      if (personToPut !== null) {
        putPersonProfile(personProfileState.objData.personId, personToPut);
      }
    }

    setUiState({
      ...uiState,
      tryRedirect: true
    });
  };

  // Holds initial formState
  function getInitialFormState() {
    return {
      personSocialMediaId: Constants.DEFAULT_ID,
      socialMediaTypeId: Constants.DEFAULT_ID,
      socialHandle: ''
    };
  };

  // Holds initial view state
  function getInitialUiState() {
    return {
      tryRedirect: false
    };
  };

  return {
    personProfileState,
    formState,
    errorState,
    uiState,
    socialMediaTypeState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  };
};

export default useProfileSocialMediaDetail;