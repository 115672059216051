import { Fragment } from 'react';

import LookupIcon from '../../../../../../common/components/icons/LookupIcon';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ position, onStatusClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{position?.positionTypeName} - {position?.positionAbbreviation}</span>&nbsp;
        <button
          className={global.IconButton}
          type="button"
          onClick={() => onStatusClicked(position)}>
          <LookupIcon toolTipText='Review (Re)certification' />
        </button>
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Position
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {position?.positionTypeName} - {position?.positionAbbreviation}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Natl Certification
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {position?.levelName || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Effective Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {position?.status === 'Approved' && formatDate(position?.effectiveDate) ?
              formatDate(position?.effectiveDate) : <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Expiration Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {position?.status === 'Approved' && position?.expirationDate !== '3000-01-01T00:00:00' && formatDate(position?.expirationDate) ?
              formatDate(position?.expirationDate) :
              <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Status
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {position?.status || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

const OtsOfficialNationalCertificationApplicationHistorySmallGrid = ({ state, onStatusClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(state?.arrayData) && state?.arrayData?.length > 0
      ? state?.arrayData?.map((position, i) =>
        <SmallGridRow
          key={i}
          position={position}
          onStatusClicked={onStatusClicked}
        />)
      : (
        <div>
          {state?.isArrayLoading
            ? (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
              </div>
            ) : (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
              </div>
            )
          }
        </div>
      )
    }
  </div>
);

export default OtsOfficialNationalCertificationApplicationHistorySmallGrid;