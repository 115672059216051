import useOrgRoleTreeView from './UseOrgRoleTreeView';

import TreeView from '../TreeView';

import Constants from '../../../utils/Constants';

import global from '../../GlobalStyle.module.css';

const OrgRoleTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange, organizationId }) => {
  const { orgRoleHierarchyState } = useOrgRoleTreeView(organizationId || Constants.DEFAULT_ID);

  return orgRoleHierarchyState.message
    ? <span className={global.LoadingMsg}>{orgRoleHierarchyState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={orgRoleHierarchyState.isArrayLoading}
      options={orgRoleHierarchyState.treeData}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default OrgRoleTreeView;