import { useNavigate } from "../../../common/wrappers/ReactRouterDom";
import useNavRoutes from "../../../common/state/security/UseNavRoutes";

const useMergeButton = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();

  const onClick = (e) => {
    e?.preventDefault();

    navigate(navRoutes.MEMBER_MERGE_SEARCH?.route);
  };
  
  return {
    onClick
  };
};

export default useMergeButton;