import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const SmallGridRowIcons = ({ socialMediaItem, onAddEdit, onDelete }) => (
  <Fragment>
    <button className={global.IconButton} type="button" onClick={(e) =>
      onDelete(e, socialMediaItem.personSocialMediaId, `${socialMediaItem.socialMediaType?.typeName} (Handle / Username / URL: ${socialMediaItem.socialHandle})`)}>
      <DeleteIcon />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onAddEdit(e, socialMediaItem.personSocialMediaId)}>
      <EditIcon />
    </button>
  </Fragment>
);

const SmallGridRow = ({ socialMediaItem, onAddEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{socialMediaItem.socialMediaType?.typeName || ''}</span>&nbsp;
      <SmallGridRowIcons socialMediaItem={socialMediaItem} onAddEdit={onAddEdit} onDelete={onDelete} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Platform Name
        </div>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>
          {socialMediaItem.socialMediaType?.typeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
          Handle / Username / URL
        </div>
        <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
          {socialMediaItem.socialHandle || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const ProfileSocialMediaSmallGrid = ({ gridState, onAddEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {gridState.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {gridState.isObjLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Social Media Accounts</div>
            }
          </div>
        </div>
      ) : Array.isArray(gridState.objData.socialMedia) && gridState.objData.socialMedia.length > 0
        ? gridState.objData.socialMedia.map((socialMediaItem, i) => <SmallGridRow key={i} socialMediaItem={socialMediaItem} onAddEdit={onAddEdit} onDelete={onDelete} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Social Media Accounts</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ProfileSocialMediaSmallGrid;