import { Fragment } from 'react';

import EditIcon from '../../../../../../common/components/icons/EditIcon';
import HideIcon from "../../../../../../common/components/icons/HideIcon";
import ShowIcon from "../../../../../../common/components/icons/ShowIcon";

import global from '../../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ offeringDescription }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      Offering Description
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
          {offeringDescription || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const DetailTable = ({ offering }) => (
  <div className={global.SmallTableDetailTable}>
    <DetailTableRow offeringDescription={offering.offeringDescription} />
  </div>
);

const OfferingRowIcons = ({ offering, expandedId, onEditClicked, onExpandClicked }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, offering.offeringId)}>
      {expandedId === offering.offeringId ? <HideIcon /> : <ShowIcon />}
    </button>
    {offering.offeringInstance?.length === 0
      ? <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditClicked(e, offering)}><EditIcon /></button>
      : <Fragment />
    }
  </Fragment>
);

const GridRow = ({ offering, expandedId, onEditClicked, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{offering.offeringType?.typeName || ''}</span>&nbsp;
        <OfferingRowIcons offering={offering} expandedId={expandedId} onEditClicked={onEditClicked} onExpandClicked={onExpandClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Offering Type
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {offering.offeringType?.typeName || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Offering Name
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {offering.offeringName || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Age Range
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {offering.offeringOptionalAttributes[0]?.ageStart >= 0
              ? offering.offeringOptionalAttributes[0].ageStart + '-' + offering.offeringOptionalAttributes[0].ageEnd
              : <span>&nbsp;</span>
            }
          </div>
        </div>
      </div>
    </div >
    {expandedId === offering.offeringId
      && <DetailTable offering={offering} />
    }
  </Fragment>
);

const OfferingsGridSmall = ({ gridData, isLoading, expandedId, onEditClicked, onExpandClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : (Array.isArray(gridData) === true && gridData.length > 0)
        ? gridData.map((offering, i) => (
          <GridRow
            key={i}
            offering={offering}
            expandedId={expandedId}
            onEditClicked={onEditClicked}
            onExpandClicked={onExpandClicked} />)
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>&nbsp;&nbsp;No Offerings</div>
          </div>
        )
    }
  </div>
);

export default OfferingsGridSmall;