import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import Constants from '../../utils/Constants';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  organizationId: Constants.DEFAULT_ID,
  lscId: Constants.DEFAULT_ID,
  data: [],
  items: [],
  message: ''
};

const getOrgUnitsByOrganization = (organizationId, state, setState) => {
  if (state.isLoading !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      organizationId,
      lscId: Constants.DEFAULT_ID,
      message: 'Loading...'
    };
    setState(newState);

    const organizationIdForUrl = organizationId ? encodeURIComponent(organizationId) : 'NaN';
    const url = `/OrgUnit/Organization/LowestChildren/Flat/${organizationIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          //Sort data in A-Z order
          data.sort(function (a, b) {
            const textA = a.orgUnitName.toUpperCase();
            const textB = b.orgUnitName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            items: data.map((x) => { return { id: x.orgUnitId, name: `${x.orgUnitName} (${x.parentOrgUnitCode ? x.parentOrgUnitCode + '/' : ''}${x.orgUnitCode})` }; }),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const getOrgUnitsByLsc = (organizationId, lscId, state, setState) => {
  if (state.isLoading !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      organizationId,
      lscId,
      message: 'Loading...'
    };
    setState(newState);

    const lscIdForUrl = lscId ? encodeURIComponent(lscId) : 'NaN';
    const url = `/OrgUnit/GetChildrenForOrgUnit/${lscIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          //Sort data in A-Z order
          data.sort(function (a, b) {
            const textA = a.orgUnitName.toUpperCase();
            const textB = b.orgUnitName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            items: data.map((x) => { return { id: x.orgUnitId, name: `${x.orgUnitName} (${x.orgUnitCode})` }; }),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const OrgUnitLowestChildrenData = { INITIAL_STATE, getOrgUnitsByOrganization, getOrgUnitsByLsc };

export default OrgUnitLowestChildrenData;