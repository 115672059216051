import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './NationalTeamContactPhoneDetailValidation';


import usePersonGeneralNationalTeamData from '../../../../common/state/personGeneralNationalTeam/UsePersonGeneralNationalTeamData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../common/utils/Constants';
import useForm from '../../../../common/utils/UseForm';
import { formatPhoneNumber } from '../../../../common/utils/validation';

const useNationalTeamContactPhoneDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personGeneralNationalTeamState, putPersonGeneralNationalTeam } = usePersonGeneralNationalTeamData();
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setFormState, setIsDirty, onValueTextPairChanged
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState(getInitialState());
  const [modalState, setModalState] = useState(getInitialModalState());

  const onSavePhone = (e) => {
    if (isDirty === false) {
      onCancelClicked(e);
    } else {
      handleSubmit(e);
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_CONTACTS?.route, { state: { personNationalTeamContactId: location?.state?.personNationalTeamContactId } });
  };

  const onModalCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    setModalState(getInitialModalState());
  };

  const putPersonGeneralContactPhones = (doPrimaryCheck) => {
    if (personGeneralNationalTeamState?.isSaving === false) {
      putPersonGeneralNationalTeam(personGeneralNationalTeamState?.objData?.personId, createPersonGeneralObject(doPrimaryCheck));
    }

    setState({
      ...state,
      tryRedirect: true
    });

    setModalState(getInitialModalState);
  };

  useEffect(() => {
    if (state?.tryRedirect === true && personGeneralNationalTeamState?.isObjLoading === false && personGeneralNationalTeamState?.isSaving === false) {
      navigate(navRoutes.MEMBER_NT_CONTACTS?.route, { state: { personNationalTeamContactId: location?.state?.personNationalTeamContactId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralNationalTeamState, state.tryRedirect]);

  useEffect(() => {
    if (personGeneralNationalTeamState.isObjLoaded === true && location?.state?.personNationalTeamContactId > 0) {
      const targetContact = personGeneralNationalTeamState?.objData?.personNationalTeamContact?.find(x => x.personNationalTeamContactId === location?.state?.personNationalTeamContactId);

      if (targetContact) {
        if (location?.state?.personNationalTeamContactPhoneId > 0) { // edit
          const targetPhone = targetContact?.personNationalTeamContactPhone?.find(x => x.personNationalTeamContactPhoneId === location?.state?.personNationalTeamContactPhoneId);

          if (targetPhone) {
            setFormState({
              ...formState,
              personNationalTeamContactPhoneId: targetPhone?.personNationalTeamContactPhoneId,
              isPrimary: targetPhone?.isPrimary === true,
              phoneTypeId: targetPhone?.phoneTypeId || Constants.DEFAULT_ID,
              phoneTypeName: targetPhone?.phoneType?.typeName || '',
              phoneNumber: targetPhone?.phoneNumber
            });

            setState({
              ...state,
              personNationalTeamContactId: targetContact?.personNationalTeamContactId,
              personContactName: `${targetContact?.firstName || ''} ${targetContact?.lastName || ''}`,
              disablePrimaryCheckbox: targetPhone?.isPrimary === true
            });
          }
        } else if (targetContact?.personNationalTeamContactPhone?.length === 0) { // add first phone, force isPrimary true
          setFormState({ ...formState, isPrimary: true });
          setState({
            ...state,
            personNationalTeamContactId: targetContact?.personNationalTeamContactId,
            personContactName: `${targetContact?.firstName || ''} ${targetContact?.lastName || ''}`,
            disablePrimaryCheckbox: true
          });
        } else { // add
          setState({
            ...state,
            personNationalTeamContactId: targetContact?.personNationalTeamContactId,
            personContactName: `${targetContact?.firstName || ''} ${targetContact?.lastName || ''}`
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personGeneralNationalTeamState]);

  function submitFormCallback(formState) {
    if (state?.disablePrimaryCheckbox === false && formState?.isPrimary === true) {
      const targetContact = personGeneralNationalTeamState?.objData?.personNationalTeamContact?.find(x => x.personNationalTeamContactId === state?.personNationalTeamContactId);

      if (targetContact) {
        const currentPrimary = targetContact?.personNationalTeamContactPhone?.find(x => x.isPrimary === true);

        if (currentPrimary) {
          setModalState({
            ...modalState,
            displayPopUp: true,
            currentPrimaryInfo: `${formatPhoneNumber(currentPrimary?.phoneNumber)} (${currentPrimary?.phoneType?.typeName || 'Unknown Phone Type'})`
          });
        } else {
          putPersonGeneralContactPhones(true);
        }
      }
    } else {
      putPersonGeneralContactPhones(false);
    }
  };

  function createPersonGeneralObject(doPrimaryCheck) {
    const newPersonGeneralObj = JSON.parse(JSON.stringify(personGeneralNationalTeamState?.objData));
    const personNationalTeamContactIndex = newPersonGeneralObj?.personNationalTeamContact?.findIndex(x => x.personNationalTeamContactId === state?.personNationalTeamContactId);

    if (personNationalTeamContactIndex >= 0) {
      if (doPrimaryCheck === true) {
        for (let i = 0; i < newPersonGeneralObj?.personNationalTeamContact[personNationalTeamContactIndex].personNationalTeamContactPhone?.length; i++) {
          newPersonGeneralObj.personNationalTeamContact[personNationalTeamContactIndex].personNationalTeamContactPhone[i].isPrimary = false;
        }
      }

      const newPersonContactPhone = {
        phoneTypeId: formState.phoneTypeId,
        isPrimary: formState.isPrimary,
        phoneNumber: formState.phoneNumber,
        phoneType: undefined
      };

      if (formState.personNationalTeamContactPhoneId > 0) {
        const personNationalTeamContactPhoneIndex = newPersonGeneralObj?.personNationalTeamContact[personNationalTeamContactIndex]?.personNationalTeamContactPhone?.findIndex(x => x.personNationalTeamContactPhoneId === formState.personNationalTeamContactPhoneId);

        if (personNationalTeamContactPhoneIndex >= 0) {
          newPersonGeneralObj.personNationalTeamContact[personNationalTeamContactIndex].personNationalTeamContactPhone[personNationalTeamContactPhoneIndex] = {
            ...newPersonGeneralObj.personNationalTeamContact[personNationalTeamContactIndex].personNationalTeamContactPhone[personNationalTeamContactPhoneIndex],
            ...newPersonContactPhone
          };
        }
      } else {
        newPersonGeneralObj.personNationalTeamContact[personNationalTeamContactIndex].personNationalTeamContactPhone.push({
          personNationalTeamContactId: state.personNationalTeamContactId,
          ...newPersonContactPhone
        });
      }
    }

    return newPersonGeneralObj;
  };

  function getInitialFormState() {
    return {
      personNationalTeamContactPhoneId: Constants.DEFAULT_ID,
      isPrimary: false,
      phoneTypeId: Constants.DEFAULT_ID,
      phoneTypeName: '',
      phoneNumber: ''
    };
  };

  function getInitialState() {
    return {
      personNationalTeamContactId: Constants.DEFAULT_ID,
      personContactName: '',
      disablePrimaryCheckbox: false,
      tryRedirect: false
    };
  };

  function getInitialModalState() {
    return {
      displayPopUp: false,
      currentPrimaryInfo: '',
      modalTitle: 'Change the primary phone number?'
    };
  };

  return {
    personGeneralNationalTeamState,
    formState,
    errorState,
    state,
    modalState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onSavePhone,
    onCancelClicked,
    onModalCancelClicked,
    putPersonGeneralContactPhones,
    phoneTypeOptions: [ //TODO probably get from database instead
      { id: Constants.DEFAULT_ID, name: "--" },
      { id: 1, name: "Mobile" },
      { id: 2, name: "Work" },
      { id: 3, name: "Home" },
      { id: 4, name: "Other" }
    ]
  };
};

export default useNationalTeamContactPhoneDetail;