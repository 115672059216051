import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postPersonFilesProfilePhotoData from './PostPersonFilesProfilePhotoData';

const BASE_UPLOAD_URL = 'https://memberdocs.blob.core.windows.net/profile-pics/';

const getPersonProfilePhotoData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/profilePhoto?fileUrl=${fileUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const PERSON_PROFILE_PHOTO_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PERSON_PROFILE_PHOTO_DOWNLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonFilesProfilePhotoData = {
  BASE_UPLOAD_URL,
  PERSON_PROFILE_PHOTO_UPLOAD_INITIAL_STATE,
  PERSON_PROFILE_PHOTO_DOWNLOAD_INITIAL_STATE,
  postPersonFilesProfilePhotoData,
  getPersonProfilePhotoData
};

export default PersonFilesProfilePhotoData;