import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getHQCommitteeMembersData from './GetHQCommitteeMembersData';
import postHQCommitteeMember from '../../orgUnitStaff/PostOrgUnitStaffData';
import putHQCommitteeMember from '../../orgUnitStaff/PutOrgUnitStaffData';
import deleteHQCommitteeMember from '../../orgUnitStaff/DeletePersonOrgRoleDurationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberAdministrationHQBoardData = {
  INITIAL_STATE,
  getHQCommitteeMembersData,
  postHQCommitteeMember,
  putHQCommitteeMember,
  deleteHQCommitteeMember
};

export default MemberAdministrationHQBoardData;