import { Fragment } from 'react';

import LookupIcon from '../../../../../../common/components/icons/LookupIcon';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ position, onStatusClicked }) => { 
  return (
    <Fragment>
      <tr>
        <td>{position?.positionTypeName} - {position?.positionAbbreviation}</td>
        <td>{position?.levelName}</td>
        <td>{position?.status === 'Approved' && formatDate(position.effectiveDate)}</td>
        <td>{position?.status === 'Approved' && position.expirationDate !== '3000-01-01T00:00:00' && formatDate(position.expirationDate)}</td>
        <td>{position?.status}</td>
        <td>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onStatusClicked(position)}>
            <LookupIcon toolTipText='Review (Re)certification' />
          </button>
        </td>
      </tr>
    </Fragment>
  )
}

const OtsOfficialNationalCertificationApplicationHistoryLargeGrid = ({ state, onStatusClicked }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Position</th>
          <th>National Certification</th>
          <th>Effective Date</th>
          <th>Expiration Date</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state?.arrayData?.length > 0 ?
          state?.arrayData?.map((position, i) =>
            <LargeGridRow
              key={i}
              position={position}
              onStatusClicked={onStatusClicked}
            />)
          : state?.isArrayLoading
            ? <tr><td colSpan="6">Loading...</td></tr>
            : <tr><td colSpan="6">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default OtsOfficialNationalCertificationApplicationHistoryLargeGrid;