import { useState } from 'react';

import CoachSearchData from './CoachSearchData';

const INITIAL_STATE = {
  arrayData: [],
  isArrayLoading: false,
  isArrayLoaded: false,
  message: ''
};

const useCoachSearchData = () => {
  const [coachSearchState, setCoachSearchState] = useState(INITIAL_STATE);
  const coachSearch = (filterObj, sortBy) => CoachSearchData(filterObj, sortBy, coachSearchState, setCoachSearchState); 

  return {
    coachSearchState,
    coachSearch
  };
};

export default useCoachSearchData;