import React, { Fragment } from 'react';

import OtsOfficialLscCertificationLargeWriteGrid from './OtsOfficialLscCertificationLargeWriteGrid';
import OtsOfficialLscCertificationSmallWriteGrid from './OtsOfficialLscCertificationSmallWriteGrid';

import useExpandedRow from '../../../../../../common/components/grids/UseExpandedRow';

const OtsOfficialsLscCertificationWriteGrid = ({ state, formState, onEditLscCert, onOpenDeleteLscCertificationModal, selectedLscCertifications, onAddOrRemoveLscCertficationForRecertify }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <OtsOfficialLscCertificationLargeWriteGrid
        state={state}
        formState={formState}
        onEditLscCert={onEditLscCert}
        onOpenDeleteLscCertificationModal={onOpenDeleteLscCertificationModal}
        selectedLscCertifications={selectedLscCertifications}
        onAddOrRemoveLscCertficationForRecertify={onAddOrRemoveLscCertficationForRecertify}
        expandedId={expandedId}
        onExpandClicked={onClick} />
      <OtsOfficialLscCertificationSmallWriteGrid
        state={state}
        formState={formState}
        onEditLscCert={onEditLscCert}
        selectedLscCertifications={selectedLscCertifications}
        onAddOrRemoveLscCertficationForRecertify={onAddOrRemoveLscCertficationForRecertify}
        onOpenDeleteLscCertificationModal={onOpenDeleteLscCertificationModal}
        expandedId={expandedId}
        onExpandClicked={onClick} />
    </Fragment>
  )
};

export default OtsOfficialsLscCertificationWriteGrid;
