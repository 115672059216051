import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../../../common/wrappers/ReactRouterDom";

import useOfferingManagementData from "../../../../../state/offeringManagement/UseOfferingManagementData";

import useNavRoutes from "../../../../../../common/state/security/UseNavRoutes";

const INITIAL_STATE = {
  offeringObj: {},
  pageErrorMessage: ''
};

const useHqOfferingTypeClubAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { offeringManagementState, postOffering } = useOfferingManagementData();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmitOfferingForm = (newOfferingObj) => {
    const postOfferingPromise = postOffering(newOfferingObj);

    if (postOfferingPromise ?? false) {
      postOfferingPromise.then((newState) => {
        if (newState ?? false) {
          navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERINGS?.route)
        }
      }).catch((e) => {
        setState({
          ...state,
          pageErrorMessage: e?.message || 'Failed to add offering'
        })
      });
    }
  };

  const onBackClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_TYPE_SELECT?.route)
  }

  useEffect(() => {
    if (location.state?.offeringTypeId && location.state?.offeringTypeName) {
      setState({
        ...state,
        offeringObj: {
          offeringTypeId: location.state.offeringTypeId,
          offeringTypeName: location.state.offeringTypeName
        }
      })
    } else {
      setState({
        ...state,
        pageErrorMessage: 'Failed to load offering type'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSaving: offeringManagementState.isObjLoading,
    state,
    onSubmitOfferingForm,
    onBackClicked
  };
};

export default useHqOfferingTypeClubAdd;