import useFileTypeDropdown from './UseFileTypeDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const FileTypeDropdown = ({ label, name, value, error, message, onChange }) => {
  const { personNationalTeamFileTypeState } = useFileTypeDropdown();

  return personNationalTeamFileTypeState?.message
    ? <span className={global.LoadingMsg}>{personNationalTeamFileTypeState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={personNationalTeamFileTypeState?.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={personNationalTeamFileTypeState?.isArrayLoading}
      />
    );
};

export default FileTypeDropdown;