import { useEffect, useState } from "react";

import { DEFAULT_MERGE_ERROR_MESSAGE, DEFAULT_MERGE_CONFIRMATION_PUT_ERROR_MESSAGE, DEFAULT_MERGE_INFO_GET_ERROR_MESSAGE, DEFAULT_MERGE_VALIDATION_GET_ERROR_MESSAGE } from "./utils/MemberMergeConstants";

import useMemberMergeToolData from "../../state/memberMergeTool/UseMemberMergeToolData";

const INITIAL_STATE = {
  isError: false,
  displayConfirmationPopup: false,
  errorMessages: [],
  personMergeObj: {}
};

const useMemberMergeTool = (personAId, personBId, memberAId, memberBId, onSuccessfulMerge) => {
  const { memberMergeValidationState, memberMergeInfoState, memberMergeConfirmationState,
    getIsValidMergeByPersonIds, getIsValidMergeByMemberIds, getPersonMergeInfoByPersonIds,
    getPersonMergeInfoByMemberIds, putPersonMerge
  } = useMemberMergeToolData();
  const [state, setState] = useState(INITIAL_STATE);

  const onMergeClicked = (personMergeObj) => {
    setState({
      ...state,
      displayConfirmationPopup: true,
      personMergeObj
    });
  };

  const onConfirmMergeClicked = () => {
    const personAId = memberMergeInfoState.objData?.personA?.personId;
    const personBId = memberMergeInfoState.objData?.personB?.personId;

    if (personAId && personBId && state.personMergeObj) {
      const putPersonMergePromise = putPersonMerge(personAId, personBId, state.personMergeObj);

      setState({
        ...state,
        displayConfirmationPopup: false
      });

      if (putPersonMergePromise ?? false) {
        putPersonMergePromise.then((newState) => {
          if (newState ?? false) {
            onSuccessfulMerge(newState.objData);
          }
        }).catch((e) => {
          setState({
            ...state,
            isError: true,
            errorMessages: [
              { message: DEFAULT_MERGE_CONFIRMATION_PUT_ERROR_MESSAGE },
              { message: e?.message || DEFAULT_MERGE_ERROR_MESSAGE }
            ]
          });
        })
      } else {
        setState({
          ...state,
          isError: true,
          errorMessages: [{ message: DEFAULT_MERGE_CONFIRMATION_PUT_ERROR_MESSAGE }]
        });
      }
    } else {
      setState({
        ...state,
        isError: true,
        errorMessages: [
          { message: DEFAULT_MERGE_CONFIRMATION_PUT_ERROR_MESSAGE },
          { message: `PersonAId: ${personAId} PersonBId: ${personBId}` },
          { message: `Person Merge Object Loaded: ${state.personMergeObj || false ? 'Yes' : 'No'}` }
        ]
      });
    }
  };

  const onCloseConfirmationPopup = () => {
    setState(INITIAL_STATE);
  };

  useEffect(() => {
    let getValidationPromise = null;

    if (personAId && personBId) {
      getValidationPromise = getIsValidMergeByPersonIds(personAId, personBId)
    } else if (memberAId && memberBId) {
      getValidationPromise = getIsValidMergeByMemberIds(memberAId, memberBId)
    }

    if (getValidationPromise ?? false) {
      getValidationPromise.catch((e) => {
        setState({
          ...state,
          isError: true,
          errorMessages: [
            { message: DEFAULT_MERGE_VALIDATION_GET_ERROR_MESSAGE },
            { message: e?.message || DEFAULT_MERGE_ERROR_MESSAGE }
          ]
        });
      });
    } else {
      setState({
        ...state,
        isError: true,
        errorMessages: [
          { message: DEFAULT_MERGE_VALIDATION_GET_ERROR_MESSAGE },
          { message: `PersonAId: ${personAId} PersonBId: ${personBId}` },
          { message: `MemberAId: ${memberAId} MemberAId: ${memberBId}` },
        ]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personAId, personBId, memberAId, memberBId]);

  useEffect(() => {
    if (memberMergeValidationState.isObjLoaded === true && memberMergeValidationState.isObjLoading === false) {
      if (memberMergeValidationState.objData?.membersAbleToMerge === true) {
        let getInfoPromise = null;

        if (memberMergeValidationState.personAId && memberMergeValidationState.personBId) {
          getInfoPromise = getPersonMergeInfoByPersonIds(memberMergeValidationState.personAId, memberMergeValidationState.personBId);
        } else if (memberMergeValidationState.memberAId && memberMergeValidationState.memberBId) {
          getInfoPromise = getPersonMergeInfoByMemberIds(memberMergeValidationState.memberAId, memberMergeValidationState.memberBId);
        }

        if (getInfoPromise ?? false) {
          getInfoPromise.catch((e) => {
            setState({
              ...state,
              isError: true,
              errorMessages: [
                { message: DEFAULT_MERGE_INFO_GET_ERROR_MESSAGE },
                { message: e?.message || DEFAULT_MERGE_ERROR_MESSAGE }
              ]
            });
          });
        } else {
          setState({
            ...state,
            isError: true,
            errorMessages: [
              { message: DEFAULT_MERGE_INFO_GET_ERROR_MESSAGE },
              { message: `PersonAId: ${memberMergeValidationState?.personAId} PersonBId: ${memberMergeValidationState?.personBId}` },
              { message: `MemberAId: ${memberMergeValidationState?.memberAId} MemberAId: ${memberMergeValidationState?.memberBId}` },
            ]
          });
        }
      } else {
        const apiErrorMessages = memberMergeValidationState.objData?.validationMessage;

        setState({
          ...state,
          isError: true,
          errorMessages: Array.isArray(apiErrorMessages) && apiErrorMessages.length > 0
            ? apiErrorMessages
            : [
              { message: DEFAULT_MERGE_VALIDATION_GET_ERROR_MESSAGE },
              { message: DEFAULT_MERGE_ERROR_MESSAGE }
            ]
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberMergeValidationState]);

  return {
    isLoading: memberMergeValidationState.isObjLoading || memberMergeInfoState.isObjLoading,
    isSaving: memberMergeConfirmationState.isObjLoading,
    isMergeInfoLoaded: memberMergeInfoState.isObjLoaded,
    personAData: memberMergeInfoState.objData?.personA || {},
    personBData: memberMergeInfoState.objData?.personB || {},
    state,

    onMergeClicked,
    onConfirmMergeClicked,
    onCloseConfirmationPopup
  };
};

export default useMemberMergeTool;