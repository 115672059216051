import { useState } from 'react';
import NationalEvaluatorData from './NationalEvaluatorData';

const useNationalEvaluatorData = () => {
  const [ state, setState ] = useState({ ...NationalEvaluatorData.INITIAL_STATE });

  const getNationalEvaluators = (otsMeetId) => {
    return NationalEvaluatorData.getNationalEvaluators(otsMeetId, state, setState);
  };

  const getNationalEvaluator = (nationalEvaluatorId) => {
    return NationalEvaluatorData.getNationalEvaluator(nationalEvaluatorId, state, setState);
  };

  const addNationalEvaluator = (payload) => {
    return NationalEvaluatorData.postNationalEvaluator(payload, state, setState);
  };

  const editNationalEvaluator = (nationalEvaluatorId, payload) => {
    return NationalEvaluatorData.putNationalEvaluator(nationalEvaluatorId, payload, state, setState);
  };

  const deleteNationalEvaluator = (nationalEvaluatorId) => {
    return NationalEvaluatorData.deleteNationalEvaluator(nationalEvaluatorId, state, setState);
  };

  return {
    NationalEvaluatorState: {...state },
    getNationalEvaluators,
    getNationalEvaluator,
    addNationalEvaluator,
    editNationalEvaluator,
    deleteNationalEvaluator
  };
};

export default useNationalEvaluatorData;
