import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import searchOtsMeets from './SearchOtsMeetsData';
import getOtsMeetData from './GetOtsMeetData';
import postOtsMeetData from './PostOtsMeetData';
import putOtsMeetData from './PutOtsMeetData';
import getOtsMeetMeetById from './GetOtsMeetMeetByIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OtsMeetData = {
  INITIAL_STATE, searchOtsMeets, getOtsMeetData, postOtsMeetData, putOtsMeetData,
  getOtsMeetMeetById
};

export default OtsMeetData;