import { useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AddHQCommitteeMemberValidation';

import { navLinks } from '../../UseMemberAdministration';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useMemberAdministrationHQCommitteeData from '../../../../../common/state/memberAdministration/hQCommittee/UseMemberAdministrationHQCommitteeData';
import useOrgRoleStaffRoleHierarchyData from '../../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useAddHQCommitteeMember = () => {
  const navigate = useNavigate();
  const { USAS_ORG_UNIT_ID, HQ_COMMITTEE_ROLE_GROUP_ID, ORG_ROLE_FIELD_TERM_LENGTH_FIELD_NAME,
    ORG_ROLE_FIELD_REPRESENTATIVE_TYPE_FIELD_NAME, environmentVariableState } = useEnvironmentVariableData();
  const { orgRoleStaffRoleHierarchyState } = useOrgRoleStaffRoleHierarchyData();
  const { memberAdministrationHQCommitteeState, postHQCommitteeMember, putHQCommitteeMember } = useMemberAdministrationHQCommitteeData();
  const { formState, errorState, isSubmitting, onFormValueChanged, onValueTextPairChanged, handleSubmit, setErrors, setFormData, setIsDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);

  const onSaveClicked = () => {
    setIsDirty(true);
    handleSubmit();
  };

  const onBackClicked = () => {
    navigate(navLinks.HQ_COMMITTEE);
  };

  useEffect(() => {
    if (memberAdministrationHQCommitteeState.isSaved === true) {
      navigate(navLinks.HQ_COMMITTEE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationHQCommitteeState.isSaved]);

  function submitFormCallback() {
    const existingPersonOrgRoles = getExistingPersonOrgRoles(formState.personId);

    if (existingPersonOrgRoles) {
      if (isDurationValid(formState.orgRole[0].id, formState.effectiveDate, formState.expirationDate, existingPersonOrgRoles)) {
        putHQCommitteeMember(USAS_ORG_UNIT_ID, formState.personId, HQ_COMMITTEE_ROLE_GROUP_ID, createStaffObject(formState, existingPersonOrgRoles));
      } else {
        const overlap = getOverlappingDates(formState.orgRole[0].id, formState.effectiveDate, formState.expirationDate, existingPersonOrgRoles);
        setErrors({
          multipleActiveRoles: `This member has an existing ${formState.orgRole[0].name} role from ${formatDate(overlap?.effectiveDate)} - ${formatDate(overlap?.expirationDate)}. Date overlaps are not allowed.`
        });
      }
    } else {
      postHQCommitteeMember(USAS_ORG_UNIT_ID, HQ_COMMITTEE_ROLE_GROUP_ID, createStaffObject(formState));
    }
  };

  function getExistingPersonOrgRoles(personId) {
    const existingPerson = memberAdministrationHQCommitteeState.arrayData.find(x => x.personId === personId);

    if (existingPerson && Array.isArray(existingPerson.orgRoles)) {
      return existingPerson.orgRoles;
    } else {
      return null;
    }
  };

  function isDurationValid(orgRoleId, effectiveDate, expirationDate, orgRoles) {
    let overlappingDate = false;

    for (const role of orgRoles) {
      if (role.orgRoleId === orgRoleId) {
        if (new Date(effectiveDate) <= new Date(role.expirationDate)
          && new Date(expirationDate) >= new Date(role.effectiveDate)) {
          overlappingDate = true;
          break;
        }
      }
    }

    return !overlappingDate;
  };

  function getOverlappingDates(orgRoleId, effectiveDate, expirationDate, orgRoles) {
    let overlappingDuration = {};

    for (const role of orgRoles) {
      if (role.orgRoleId === orgRoleId) {
        if (new Date(effectiveDate) <= new Date(role.expirationDate)
          && new Date(expirationDate) >= new Date(role.effectiveDate)) {
            overlappingDuration = role;
          break;
        }
      }
    }

    return overlappingDuration;
  };

  function createStaffObject(formState, existingPersonOrgRoles) {
    const filteredPersonOrgRoles = [];

    if (Array.isArray(existingPersonOrgRoles)) {
      for (const role of existingPersonOrgRoles) {
        if (role.isCurrent === true) {
          filteredPersonOrgRoles.push({
            orgRoleId: role.orgRoleId,
            orgRoleName: role.orgRoleName,
            personOrgRoleId: role.personOrgRoleId,
            personOrgRoleDurationId: role.personOrgRoleDurationId
          });
        }
      }
    }

    return {
      personId: formState.personId,
      personOrgRole: [
        {
          orgRoleId: formState.orgRole[0].id,
          orgRoleName: formState.orgRole[0].name,
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.expirationDate,
          personOrgRoleFields: [
            {
              fieldName: ORG_ROLE_FIELD_TERM_LENGTH_FIELD_NAME,
              orgRoleFieldId: getOrgRoleFieldId(orgRoleStaffRoleHierarchyState.arrayData, formState.orgRole[0].id, ORG_ROLE_FIELD_TERM_LENGTH_FIELD_NAME),
              fieldValue: formState.termLengthName
            },
            {
              fieldName: ORG_ROLE_FIELD_REPRESENTATIVE_TYPE_FIELD_NAME,
              orgRoleFieldId: getOrgRoleFieldId(orgRoleStaffRoleHierarchyState.arrayData, formState.orgRole[0].id, ORG_ROLE_FIELD_REPRESENTATIVE_TYPE_FIELD_NAME),
              fieldValue: formState.representativeTypeName
            }
          ]
        },
        ...filteredPersonOrgRoles
      ]
    };
  };

  function getOrgRoleFieldId(orgRoleTreeArray, orgRoleId, orgRoleFieldName) {
    if (Array.isArray(orgRoleTreeArray) && orgRoleTreeArray.length > 0 && orgRoleId && orgRoleFieldName) {
      for (const parentRole of orgRoleTreeArray) {
        if (Array.isArray(parentRole.children) && parentRole.children.length > 0) {
          const targetOrgRole = parentRole.children.find(x => x.id === orgRoleId);

          if (targetOrgRole && Array.isArray(targetOrgRole.orgRoleField) && targetOrgRole.orgRoleField.length > 0) {
            const targetOrgRoleField = targetOrgRole.orgRoleField.find(x => x.fieldName === orgRoleFieldName);

            if (targetOrgRoleField && targetOrgRoleField.orgRoleFieldId) {
              return targetOrgRoleField.orgRoleFieldId;
            }
          }
        }
      }
    }

    return null;
  };

  function getInitialFormState() {
    return {
      personId: '',
      orgRole: [],
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      memberId: '',
      representativeTypeId: Constants.DEFAULT_ID,
      representativeTypeName: '--',
      termLengthId: Constants.DEFAULT_ID,
      termLengthName: '--'
    };
  };

  return {
    isLoading: environmentVariableState.isLoading || isSubmitting,
    isSaving: memberAdministrationHQCommitteeState.isSaving,
    formState,
    errorState,
    HQ_COMMITTEE_ROLE_GROUP_ID: HQ_COMMITTEE_ROLE_GROUP_ID || Constants.DEFAULT_ID,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onBackClicked,
    representativeTypeOptions: [
      { id: Constants.DEFAULT_ID, name: "--" },
      { id: 1, name: "Athlete" },
      { id: 2, name: "Non-Athlete" }
    ],
    termLengthOptions: [
      { id: Constants.DEFAULT_ID, name: "--" },
      { id: 1, name: "1 Year" },
      { id: 2, name: "2 Years" },
      { id: 3, name: "3 Years" },
      { id: 4, name: "4 Years" },
      { id: 5, name: "5 Years" },
      { id: 6, name: "6 Years" },
      { id: 7, name: "Ex-Officio" },
      { id: 8, name: "N/A" }
    ],
    setFormData
  };
};

export default useAddHQCommitteeMember;