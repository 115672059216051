import { Fragment, } from 'react';

import OtsMeetOfficialCombobox from '../../../../components/comboboxes/otsMeetOfficialCombobox/OtsMeetOfficialCombobox';
import OtsPositionWorkedCombobox from '../../../../components/comboboxes/otsPositionWorkedCombobox/OtsPositionWorkedCombobox';

import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OfficialSelectionForEvaluationGridLarge = ({ state, otsMeetHeaderState, gridState, showError, evaluators, otsPositionWorkedEvaluationLevelEvaluatorNameState,
  onUpdateOfficialNameValueChange, onUpdateOfficialPositionWorkedValueChange, onUpdateEvaluatorNameValueChange, onUpdateOverrideEvaluatorNameValueChange,
  onUpdateEvaluationLevelValueChange, onUpdateOverrideEvaluationLevelValueChange }) => {
  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
        <thead>
          <tr>
            <th style={{ width: '25%' }}>Official Name</th>
            <th style={{ width: '25%' }}>Position Worked</th>
            <th style={{ width: '25%' }}>Evaluation Level</th>
            <th style={{ width: '25%' }}>Evaluator Name</th>
          </tr>
        </thead>
        <tbody>
          {state?.isArrayLoaded !== true
            ? (
              <tr>
                {state?.isArrayLoading === true
                  ? <td colSpan="4">Loading...</td>
                  : <td colSpan="4">No Results</td>}
              </tr>)
            : Array.isArray(gridState.arrayData) === true && gridState.arrayData.length > 0
              ? gridState.arrayData.map((official, i) => {

                if (official.isOverride === false) {
                  let evaluatorsForOfficialPosition = evaluators.find(x => x.officialPositionTypeId === official.officialPositionTypeId)?.evaluator;
                  let evaluatorsForOfficialPositionOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
                  evaluatorsForOfficialPosition?.forEach(x => {
                    evaluatorsForOfficialPositionOptions.push({ id: x.meetEvaluatorId, name: x.evaluatorName });
                  });
                  return (
                    <tr key={i}>
                      <td>{official?.firstName || ''} {official?.lastName || ''}</td>
                      <td>{official?.positionTypeName || ''}</td>
                      <td>
                        <Dropdown
                          label=""
                          options={official?.evaluationLevels}
                          name={"evaluationLevel" + official?.meetOfficialId + "meetOfficialId" + official?.officialPositionTypeId + "officialPositionTypeId"}
                          value={official?.selectedEvaluationLevelId}
                          onChange={(newValue, newValueLabel, e) => { onUpdateEvaluationLevelValueChange(e, official, newValue, newValueLabel); }}
                          areValuesIntegers={true}
                          error={official?.selectedMeetEvaluatorId >= 0 && official?.selectedEvaluationLevelId < 0 && showError === true ? true : false}
                          message={official?.selectedMeetEvaluatorId >= 0 && official?.selectedEvaluationLevelId < 0 && showError === true ? 'Please select an Evaluation Level' : ''} />
                      </td>
                      <td>
                        <Dropdown
                          label=""
                          options={evaluatorsForOfficialPositionOptions}
                          name={"evaluator" + official?.meetOfficialId + "meetOfficialId" + official?.officialPositionTypeId + "officialPositionTypeId"}
                          value={official?.selectedMeetEvaluatorId}
                          onChange={(value, valueLabel, e) => { onUpdateEvaluatorNameValueChange(e, official, value, valueLabel); }}
                          areValuesIntegers={true}
                          error={official?.selectedMeetEvaluatorId < 0 && official?.selectedEvaluationLevelId >= 0 && showError === true ? true : false}
                          message={official?.selectedMeetEvaluatorId < 0 && official?.selectedEvaluationLevelId >= 0 && showError === true ? 'Please select an Evaluator Name' : ''} />
                      </td>
                    </tr>
                  )
                }
                else if (official.isOverride === true) {
                  let otsPositionWorkedEvaluationLevelEvaluatorNameStateForOfficialPosition = otsPositionWorkedEvaluationLevelEvaluatorNameState.arrayData.find(x => x.officialPositionTypeId === official.selectedOfficialPositionTypeId);
                  let stateForEvaluationLevelOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
                  otsPositionWorkedEvaluationLevelEvaluatorNameStateForOfficialPosition?.evaluationLevel?.forEach(x => {
                    stateForEvaluationLevelOptions.push({ id: x.evaluationLevelId, name: x.evaluationLevelName });
                  });
                  let stateForEvaluatorNameOptions = [{ id: Constants.DEFAULT_ID, name: '--' }];
                  otsPositionWorkedEvaluationLevelEvaluatorNameStateForOfficialPosition?.evaluator?.forEach(x => {
                    stateForEvaluatorNameOptions.push({ id: x.meetEvaluatorId, name: x.evaluatorName });
                  });

                  return (
                    <tr key={i}>
                      <td><OtsMeetOfficialCombobox
                        label=""
                        name={"selectedMeetOfficialId" + i}
                        valueToMatch={official?.selectedMeetOfficialName}
                        onChange={(newValue, newValueLabel) => { onUpdateOfficialNameValueChange(i, newValue, newValueLabel); }}
                        otsMeetId={otsMeetHeaderState?.objData?.otsMeetId}
                        error={official?.selectedMeetOfficialId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
                          official?.selectedEvaluationLevelId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? true : false}
                        message={official?.selectedMeetOfficialId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
                          official?.selectedEvaluationLevelId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? 'Please select an Official Name' : ''}
                      />
                      </td>
                      <td><OtsPositionWorkedCombobox
                        label=""
                        name={"selectedOfficialPositionTypeId" + i}
                        valueToMatch={official?.selectedOfficialPositionTypeName}
                        onChange={(newValue, newValueLabel) => { onUpdateOfficialPositionWorkedValueChange(i, newValue, newValueLabel); }}
                        otsMeetId={otsMeetHeaderState?.objData?.otsMeetId}
                        error={official?.selectedOfficialPositionTypeId < 0 && (official?.selectedMeetOfficialId > 0 ||
                          official?.selectedEvaluationLevelId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? true : false}
                        message={official?.selectedOfficialPositionTypeId < 0 && showError === true ? 'Please select a Position Worked' : ''}
                      />
                      </td>
                      <td>
                        <Dropdown
                          label=""
                          options={stateForEvaluationLevelOptions}
                          name={"selectedEvaluationLevelId" + i}
                          value={official?.selectedEvaluationLevelId}
                          onChange={(newValue, newValueLabel, e) => { onUpdateOverrideEvaluationLevelValueChange(e, i, newValue, newValueLabel); }}
                          areValuesIntegers={true}
                          error={official?.selectedEvaluationLevelId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
                            official?.selectedMeetOfficialId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? true : false}
                          message={official?.selectedEvaluationLevelId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
                            official?.selectedMeetOfficialId > 0 || official?.selectedMeetEvaluatorId > 0) && showError === true ? 'Please select an Evaluation Level' : ''} />
                      </td>
                      <td>
                        <Dropdown
                          label=""
                          options={stateForEvaluatorNameOptions}
                          name={"selectedMeetEvaluatorId" + i}
                          value={official?.selectedMeetEvaluatorId}
                          onChange={(value, valueLabel, e) => { onUpdateOverrideEvaluatorNameValueChange(e, i, value, valueLabel); }}
                          areValuesIntegers={true}
                          error={official?.selectedMeetEvaluatorId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
                            official?.selectedMeetOfficialId > 0 || official?.selectedEvaluationLevelId > 0) && showError === true ? true : false}
                          message={official?.selectedMeetEvaluatorId < 0 && (official?.selectedOfficialPositionTypeId > 0 ||
                            official?.selectedMeetOfficialId > 0 || official?.selectedEvaluationLevelId > 0) && showError === true ? 'Please select an Evaluator Name' : ''} />
                      </td>
                    </tr>
                  )
                }
              }
              )
              : (
                <tr>
                  <td colSpan="4">No Results</td>
                </tr>
              )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default OfficialSelectionForEvaluationGridLarge;