import React, { Fragment } from 'react';

import OtsOfficialsLargeGrid from './OtsOfficialsLargeGrid';
import OtsOfficialsSmallGrid from './OtsOfficialsSmallGrid';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsOfficialsSearchGrid = ({ state, onSelectMember, onFiltersToggle, showFilters }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      {state?.isArrayLoaded !== false
        ? (
          <div className="row">
            <div className="col-xs-12 col-sm-8">
              {state?.arrayData?.length > 499
                ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
                : <p>{state?.arrayData?.length === 1 ? `${state?.arrayData?.length} Search Result` : `${state?.arrayData?.length} Search Results`}</p>}
            </div>
            {onFiltersToggle
              ? <div className="col-xs-12 col-sm-4">
                <button
                  className={global.ToggleButton}
                  type="button"
                  onClick={onFiltersToggle}>{showFilters === true ? 'Hide Filters' : 'Show Filters'}
                </button>
              </div>
              : <Fragment />}
          </div>)
        : <Fragment>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          </div>
        </Fragment >}
      <OtsOfficialsLargeGrid
        state={state}
        onSelectMember={onSelectMember}
        expandedId={expandedId}
        onExpandClicked={onClick}
        onFiltersToggle={onFiltersToggle}
        showFilters={showFilters} />
      <OtsOfficialsSmallGrid
        state={state}
        onSelectMember={onSelectMember}
        expandedId={expandedId}
        onExpandClicked={onClick}
        onFiltersToggle={onFiltersToggle}
        showFilters={showFilters} />
    </Fragment >
  )
};

export default OtsOfficialsSearchGrid;
