import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useSearchMemberData from '../../../../common/state/searchMember/UseSearchMemberData';

const useMemberMergeSearch = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { searchMemberState } = useSearchMemberData();

  const onSelectPersonB = (e, personId) => {
    e?.preventDefault();

    const personAId = basePersonState.objData?.personId;
    const personBId = personId;

    if (personAId && personBId) {
      navigate(navRoutes.MEMBER_MERGE_SELECTION?.route, { state: { personAId, personBId } });
    }
  };

  return {
    personAId: basePersonState.objData?.personId,
    searchMemberState,
    onSelectPersonB
  };
};

export default useMemberMergeSearch;