import { Fragment } from 'react';

import useMemberCoursesRead from './UseMemberCoursesRead';
import MemberCoursesReadGrid from './MemberCoursesReadGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';

const MemberCoursesRead = () => {
  const {
    basePersonState,
    gridDataState,
    isGridLoading
  } = useMemberCoursesRead();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Courses</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <MemberCoursesReadGrid gridData={gridDataState} isLoading={isGridLoading} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || isGridLoading} />
    </Fragment>
  );
};

export default MemberCoursesRead;