import { useContext } from "react";
import { OfferingTypesStateContext } from "./OfferingTypesContextProvider";
import OfferingTypesData from "./OfferingTypesData";

const useOfferingTypesData = () => {
  const [offeringTypesState, setOfferingTypesState] = useContext(OfferingTypesStateContext);

  const getOfferingTypes = () => {
    return OfferingTypesData.getOfferingTypesData(offeringTypesState, setOfferingTypesState);
  };

  return {
    offeringTypesState,

    getOfferingTypes
  };
};

export default useOfferingTypesData;