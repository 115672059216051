import Constants from '../../../common/utils/Constants';
import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  dataAsOptionsWithNames: [],
  message: ''
};

export const defaultDrugTestStatusState = {
  drugTestStatusId: Constants.DEFAULT_ID,
  statusName: ''
};

const getDrugTestStatus = (state, setState) => {
  if (state.isLoading !== true && state.isLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      data: [],
      dataAsOptionsWithNames: [],
      message: 'Loading...'
    };
    setState(newState);

    const url = `/DrugTestStatus`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const blankStatus = { drugTestStatusId: Constants.DEFAULT_ID, statusName: '--' };
          data.unshift(blankStatus);
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((status) => { return { id: status.drugTestStatusId, name: status.statusName } }),
            message: '',
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  };
};

const DrugTestStatusData = { INITIAL_STATE, getDrugTestStatus };

export default DrugTestStatusData;