import { useEffect, useState } from 'react';

import ToIntIfInt from '../../utils/ToIntIfInt';

import usePersonAcknowledgementData from '../../state/personAcknowledgement/UsePersonAcknowledgementData';
import usePersonAcknowledgementInfoData from '../../state/personAcknowledgementInfo/UsePersonAcknowledgementInfoData';
import useBasePersonData from '../../state/personBase/UseBasePersonData';
import usePersonGeneralData from '../../state/personGeneral/UsePersonGeneralData';
import usePersonAcknowledgementAddAFamilyMemberStateData from '../../state/personAcknowledgementAddAFamilyMember/UsePersonAcknowledgementAddAFamilyMemberData';
import usePersonSubscriptionsData from '../../state/personSubscriptions/UsePersonSubscriptionsData';
import useSecurityData from '../../state/security/UseSecurityData';

const INITIAL_VIEW_STATE = {
  isLoading: true,
  isSaving: false,
  showModal: false,
  arrayData: [],
  signature: '',
  signatureErrorMessage: '',
  message: ''
};

const SUBSCRIPTIONS_INITIAL_VIEW_STATE = {
  splashOptIn: true,
  splashOptInErrorMessage: ''
};

const useAcknowledgements = (memberId, addANewFamilyMemberView) => {
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const [subscriptionsState, setSubscriptionsState] = useState({ ...SUBSCRIPTIONS_INITIAL_VIEW_STATE });
  const { basePersonState, getBasePerson, clearBasePersonObjData } = useBasePersonData();
  const { clearArrayData } = usePersonGeneralData();
  const { userInfo } = useSecurityData();
  const { personAcknowledgementState, getPersonAcknowledgements, postPersonAcknowledgements } = usePersonAcknowledgementData(true);
  const { personAcknowledgementAddAFamilyMemberState, getPersonAcknowledgementsAddAFamilyMember, postPersonAcknowledgementsAddAFamilyMember } = usePersonAcknowledgementAddAFamilyMemberStateData();
  const { personAcknowledgementInfoState, getPersonAcknowledgementInfo, personAcknowledgementInfoPageViewState,
    setPersonAcknowledgementInfoPageViewState } = usePersonAcknowledgementInfoData();
  const { personSubscriptionsState, getPersonSubscriptions, postPersonSubscriptions } = usePersonSubscriptionsData();

  const onYesContactInfoIsTheSameClicked = (e) => {
    e?.preventDefault();
    const loggedInUser = personAcknowledgementInfoState?.arrayData.find(x => x.memberId === userInfo?.memberId);
    const getPersonSubscriptionsPromise = getPersonSubscriptions(loggedInUser?.personId);

    if (getPersonSubscriptionsPromise ?? false) {
      getPersonSubscriptionsPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.splashOptIn === null) {
            setPersonAcknowledgementInfoPageViewState({ ...personAcknowledgementInfoPageViewState, selectedPerson: {}, showConfirmContactInfoView: false, showChangeContactInfoView: false, showSubscriptionOptInView: false, showAcknowledgeSwitchesView: true });
          }
          else {
            setPersonAcknowledgementInfoPageViewState({ ...personAcknowledgementInfoPageViewState, selectedPerson: {}, showConfirmContactInfoView: false, showChangeContactInfoView: false, showSubscriptionOptInView: true, showAcknowledgeSwitchesView: false });
          }
        }
      }).catch(() => {
      });
    }
  };

  const onEditContactInfo = (e, person) => {
    e?.preventDefault();
    getBasePerson(person?.personId);
    setPersonAcknowledgementInfoPageViewState({ ...personAcknowledgementInfoPageViewState, selectedPerson: person, showConfirmContactInfoView: false, showChangeContactInfoView: true, showSubscriptionOptInView: false, showAcknowledgeSwitchesView: false });
  };

  const onSubscriptionOptInContinueClicked = (e) => {
    e?.preventDefault();
    if (subscriptionsState.splashOptIn !== true && subscriptionsState.splashOptIn !== false) {
      setSubscriptionsState({ ...subscriptionsState, splashOptInErrorMessage: 'A selection is required' });
    }
    else {
      const loggedInUser = personAcknowledgementInfoState?.arrayData.find(x => x.memberId === userInfo?.memberId);
      const postPersonSubscriptionsPromise = postPersonSubscriptions(loggedInUser?.personId, { splashOptIn: subscriptionsState.splashOptIn });

      if (postPersonSubscriptionsPromise ?? false) {
        postPersonSubscriptionsPromise.then((newState) => {
          if (newState ?? false) {
            setPersonAcknowledgementInfoPageViewState({ ...personAcknowledgementInfoPageViewState, selectedPerson: {}, showConfirmContactInfoView: false, showChangeContactInfoView: false, showSubscriptionOptInView: false, showAcknowledgeSwitchesView: true });
          }
        }).catch(() => {
        });
      }
    }
  };

  const onContinueClicked = (e) => {
    e?.preventDefault();
    clearBasePersonObjData();
    clearArrayData();
    setPersonAcknowledgementInfoPageViewState({ ...personAcknowledgementInfoPageViewState, selectedPerson: {}, showConfirmContactInfoView: true, showChangeContactInfoView: false, showSubscriptionOptInView: false, showAcknowledgeSwitchesView: false });
  };

  const onItemClick = (id, value) => {
    let changed = false;
    const newArray = state.arrayData.map(x => {
      const isAcknowledged = x.acknowledgementFormId === ToIntIfInt(id) ? value : x.isAcknowledged;
      changed = changed === true || isAcknowledged !== x.isAcknowledged;

      return {
        ...x,
        isAcknowledged: x.acknowledgementFormId === ToIntIfInt(id) ? value : x.isAcknowledged
      };
    });

    if (changed === true) {
      setState({ ...state, arrayData: newArray });
    }
  };

  const onSubmit = (e) => {
    const found = state.arrayData.find(x => x.isAcknowledged !== true);
    if (found) {
      setState({ ...state, message: 'You must acknowledge all of the items in order to continue.' });
    } else {
      setState({ ...state, message: '', showModal: true });
    }
  };

  const onSplashOptInChanged = (value) => {
    setSubscriptionsState({
      ...subscriptionsState,
      splashOptIn: value
    });
  };

  const onSignatureChanged = (value) => {
    setState({
      ...state,
      signature: value
    });
  };

  const onSigned = (e) => {
    e?.preventDefault();
    if (state.signature?.length > 2) {
      const acknowledgements = state.arrayData.filter(x => x.isAcknowledged === true)?.map(x => {
        return {
          acknowledgementFormId: x.acknowledgementFormId,
          esignature: state.signature
        };
      });

      if (Array.isArray(acknowledgements) && acknowledgements.length > 0) {
        if (addANewFamilyMemberView === false) {
          postPersonAcknowledgements(memberId, acknowledgements);
        }
        else {
          postPersonAcknowledgementsAddAFamilyMember(memberId, acknowledgements);
        }
      }
    } else {
      setState({
        ...state,
        signatureErrorMessage: 'Please type your full name to sign.'
      });
    }
  };

  const onModalCanceled = (e) => {
    e?.preventDefault();

    setState({ ...state, showModal: false });
  };

  useEffect(() => {
    if (addANewFamilyMemberView === false) {
      if (personAcknowledgementInfoPageViewState?.showConfirmContactInfoView === true) {
        getPersonAcknowledgementInfo(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personAcknowledgementInfoPageViewState?.showConfirmContactInfoView]);

  useEffect(() => {
    if (addANewFamilyMemberView === true && memberId) {
      getPersonAcknowledgementsAddAFamilyMember(memberId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (personAcknowledgementState.isSaving !== true && !personAcknowledgementAddAFamilyMemberState.isSaving) {
      if (personAcknowledgementState.isSaved === true || personAcknowledgementAddAFamilyMemberState.isSaved === true) {
        setState({ ...state, isSaving: false });
      } else if (personAcknowledgementState.isArrayLoaded !== true) {
        getPersonAcknowledgements(memberId);
        setState({ ...state, isSaving: false });
      } else if ((state.isLoading === true && personAcknowledgementState.isArrayLoading !== true && addANewFamilyMemberView === false)
        || (personAcknowledgementAddAFamilyMemberState.isArrayLoading !== true && addANewFamilyMemberView === true)) {
        const arrayDataFormatted = addANewFamilyMemberView === false ? (personAcknowledgementState.arrayData || []).map(x => { return { ...x, isAcknowledged: false }; }) :
          (personAcknowledgementAddAFamilyMemberState.arrayData || []).map(x => { return { ...x, isAcknowledged: false }; });
          setState({
          ...state,
          isLoading: false,
          isSaving: false,
          arrayData: arrayDataFormatted
        });
      }
    } else {
      setState({ ...state, isSaving: true, showModal: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personAcknowledgementState, personAcknowledgementAddAFamilyMemberState]);

  return {
    personAcknowledgementInfoPageViewState,
    ...state,
    ...subscriptionsState,
    personAcknowledgementInfoState,
    setPersonAcknowledgementInfoPageViewState,
    basePersonState,
    personSubscriptionsState,
    onYesContactInfoIsTheSameClicked,
    onEditContactInfo,
    onSubscriptionOptInContinueClicked,
    onContinueClicked,
    isReadyToSign: state.arrayData.every(x => x.isAcknowledged === true),
    onItemClick,
    onSubmit,
    onSplashOptInChanged,
    onSignatureChanged,
    onSigned,
    onModalCanceled
  };
};

export default useAcknowledgements;