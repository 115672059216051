import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ socialMediaItem, onAddEdit, onDelete }) => (
  <tr>
    <td>{socialMediaItem.socialMediaType?.typeName || ''}</td>
    <td>{socialMediaItem.socialHandle || ''}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddEdit(e, socialMediaItem.personSocialMediaId)}>
        <EditIcon />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={(e) =>
        onDelete(e, socialMediaItem.personSocialMediaId, `${socialMediaItem.socialMediaType?.typeName} (Handle / Username / URL: ${socialMediaItem.socialHandle})`)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const ProfileSocialMediaLargeGrid = ({ gridState, onAddEdit, onDelete }) => {
  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
        <thead>
          <tr>
            <th>Social Media Platform Name</th>
            <th>Handle / Username / URL</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {gridState.isObjLoaded !== true
            ? (
              <tr><td colSpan="3">{gridState.isObjLoading === true ? 'Loading...' : 'No Social Media Accounts'}</td></tr>
            ) : Array.isArray(gridState.objData.socialMedia) && gridState.objData.socialMedia.length > 0
              ? gridState.objData.socialMedia.map((socialMediaItem, i) => <LargeGridRow key={i} socialMediaItem={socialMediaItem} onAddEdit={onAddEdit} onDelete={onDelete} />
              ) : <tr><td colSpan="3">No Social Media Accounts</td></tr>
          }
        </tbody>
      </table>
    </Fragment>
  );
};

export default ProfileSocialMediaLargeGrid;