import React, { Fragment } from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ state, MALE_COMPETITION_GENDER_ID }) => (
  <tr>
    <td>{state.firstName || ''} {state.lastName || ''}</td>
    <td>{state.birthDate ? formatDate(state.birthDate) : ''}</td>
    <td>{state.isAthlete === false
      ? 'Unknown'
      : state.competitionGenderTypeId === MALE_COMPETITION_GENDER_ID
        ? 'Male'
        : 'Female'
    }</td>
    <td>{state.isAthlete === false
      ? ''
      : `${state.organizationName || ''} | ${state.orgUnitName || ''}`
    }</td>
    <td>{state.isAthlete === false
      ? ''
      : state.effectiveDate
        ? formatDate(state.effectiveDate)
        : ''
    }</td>
    <td>{state.isAthlete === false
      ? ''
      : state.expirationDate
        ? formatDate(state.expirationDate)
        : ''
    }</td>
  </tr>
);

const LargeGrid = ({ state, MALE_COMPETITION_GENDER_ID }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Birth Date</th>
        <th>Competition Category</th>
        <th>Organization</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
      </tr>
    </thead>
    <tbody>
      <LargeGridRow state={state} MALE_COMPETITION_GENDER_ID={MALE_COMPETITION_GENDER_ID} />
    </tbody>
  </table>
);

const SmallGridRow = ({ state, MALE_COMPETITION_GENDER_ID }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'> {state.firstName || ''} {state.lastName || ''}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {state.firstName || state.lastName ? `${state.firstName || ''} ${state.lastName || ''}` : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Birth Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {state.birthDate ? formatDate(state.birthDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Competition Category
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {state.isAthlete === false
            ? 'Unknown'
            : state.competitionGenderTypeId === MALE_COMPETITION_GENDER_ID
              ? 'Male'
              : 'Female'
          }
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Organization
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {state.isAthlete === false
            ? <span>&nbsp;</span>
            : state.organizationName || state.orgUnitName
              ? `${state.organizationName || ''} | ${state.orgUnitName || ''}`
              : <span>&nbsp;</span>
          }
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {state.isAthlete === false
            ? <span>&nbsp;</span>
            : state.effectiveDate
              ? formatDate(state.effectiveDate)
              : <span>&nbsp;</span>
          }
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {state.isAthlete === false
            ? <span>&nbsp;</span>
            : state.expirationDate
              ? formatDate(state.expirationDate)
              : <span>&nbsp;</span>
          }
        </div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ state, MALE_COMPETITION_GENDER_ID }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    <SmallGridRow state={state} MALE_COMPETITION_GENDER_ID={MALE_COMPETITION_GENDER_ID} />
  </div>
);

const AthleteConfirmationGrid = ({ state, MALE_COMPETITION_GENDER_ID }) => (
  <Fragment>
    <b>Are you sure you would like to add the person listed below?</b>
    <LargeGrid state={state} MALE_COMPETITION_GENDER_ID={MALE_COMPETITION_GENDER_ID} />
    <SmallGrid state={state} MALE_COMPETITION_GENDER_ID={MALE_COMPETITION_GENDER_ID} />
  </Fragment>
);

export default AthleteConfirmationGrid;