import useCertificationReviewSearch from './UseCertificationReviewSearch';

import Search from '../search/Search';
import Headings from '../../../../../common/components/headings/Headings';

import global from '../../../../../common/components/GlobalStyle.module.css';

const CertificationReviewSearch = () => {
  const {
    activeOfficialsWithApplications,
    isLoading,
    buttonClickHandlers } = useCertificationReviewSearch();

  return (
    <>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <button className={global.HeaderButton}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              Review National Certification Applications
            </Headings.H3>
          </button>
        </div>
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Search activeOfficialsWithApplications={activeOfficialsWithApplications}
            isLoading={isLoading}
            buttonClickHandlers={buttonClickHandlers} />
        </div>
      </div>
    </>
  );
}

export default CertificationReviewSearch;