import EditIcon from '../../../../../common/components/icons/EditIcon';

import { formatDate } from "../../../../../common/utils/DateFunctions";

import SGColumnHeader from '../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../common/utils/SGConstants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ official, onSelectClicked }) => (
  <tr>
    <td>
      {official.preferredName || official.firstName} {official.lastName} - {official.memberId}
    </td>
    <td>{official.email || ''}</td>
    <td>{official.applicationDate ? formatDate(official.applicationDate) : ''}</td>
    <td>{official.status || ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => onSelectClicked(official)}>
        <EditIcon />
      </button>
    </td>
  </tr>
);

const LargeGrid = ({ gridData, onSelectClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name - Member ID'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Email'} columnField={'email'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Submission Date'} columnField={'applicationDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Status'} columnField={'status'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sortableGridState.sortedGridData?.length > 0
          ? sortableGridState.sortedGridData?.map((official, i) => (
            <LargeGridRow
              key={i}
              official={official}
              onSelectClicked={onSelectClicked} />
          ))
          : <tr><td colSpan="5">No Results</td></tr>
        }
      </tbody>
    </table>
  );
}

export default LargeGrid