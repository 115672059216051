import { useEffect } from 'react';

import useCertificationLevelData from '../../../state/certificationLevel/UseCertificationLevelData';

const useCertificationLevelDropdown = (orgLevelId, officialPositionTypeId) => {
  const { certificationLevelState, getCertificationLevels } = useCertificationLevelData();

  useEffect(() => {
    if (certificationLevelState.isArrayLoading !== true &&
      (orgLevelId !== certificationLevelState.orgLevelId ||
        officialPositionTypeId !== certificationLevelState.officialPositionTypeId)) {
      getCertificationLevels(orgLevelId, officialPositionTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificationLevelState, orgLevelId, officialPositionTypeId]);

  return {
    certificationLevelState
  };
};

export default useCertificationLevelDropdown;