import { Fragment } from 'react';

import useOfferingInstanceOrgUnitGrid from './UseOfferingInstanceOrgUnitGrid';

import OfferingInstanceOrgUnitGridLarge from './OfferingInstanceOrgUnitGridLarge';
import OfferingInstanceOrgUnitGridSmall from './OfferingInstanceOrgUnitGridSmall';

const OfferingInstanceOrgUnitGrid = ({ gridData, isLoading, canAddAdditionalInstance = false, noResultsMessage = 'No Results',
  onEditInstance, onAddAdditionalInstance, onEditAdditionalInstance }) => {
  const { formattedGridData } = useOfferingInstanceOrgUnitGrid(gridData, canAddAdditionalInstance)

  return (
    <Fragment>
      <OfferingInstanceOrgUnitGridLarge gridData={formattedGridData} isLoading={isLoading} noResultsMessage={noResultsMessage} onEditInstance={onEditInstance} onAddAdditionalInstance={onAddAdditionalInstance} onEditAdditionalInstance={onEditAdditionalInstance} />
      <OfferingInstanceOrgUnitGridSmall gridData={formattedGridData} isLoading={isLoading} noResultsMessage={noResultsMessage} onEditInstance={onEditInstance} onAddAdditionalInstance={onAddAdditionalInstance} onEditAdditionalInstance={onEditAdditionalInstance} />
    </Fragment>
  );
};

export default OfferingInstanceOrgUnitGrid;