import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import usePersonClubTransfer from '../../../state/member/memberClubTransfer/UsePersonClubTransferData';

const useMemberClubTransferMember = () => {
  const navigate = useNavigate()
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { memberTransferState, getPrimaryPersonOrgRoleDurations } = usePersonClubTransfer();

  const onClubTransferClicked = (e, personOrgRoleDurationId, orgUnitId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const clubPersonRole = getTargetClubPersonRole(memberTransferState.arrayData, personOrgRoleDurationId, orgUnitId);

    if (clubPersonRole.club && clubPersonRole.personRole) {
      navigate(navRoutes.MEMBER_CLUB_TRANSFER_DETAIL?.route, { state: { club: clubPersonRole.club, personRole: clubPersonRole.personRole } });
    }
  };

  useEffect(() => {
    if (basePersonState.objData.personId && memberTransferState.isArrayLoaded === false
      && memberTransferState.isArrayLoading === false) {
      getPrimaryPersonOrgRoleDurations(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberTransferState, basePersonState]);

  function getTargetClubPersonRole(gridData, personOrgRoleDurationId, orgUnitId) {
    for (const club of gridData) {
      if (club.orgUnitId === orgUnitId) {
        for (const personRole of club.personRoles) {
          if (personRole.personOrgRoleDurationId === personOrgRoleDurationId) {
            return { club, personRole };
          }
        }
      }
    }

    return null;
  };

  return {
    isLoading: memberTransferState.isArrayLoading,
    gridData: memberTransferState.arrayData || [],
    onClubTransferClicked
  };
};

export default useMemberClubTransferMember;