import { Fragment } from 'react';

import useLSCReportingSelection from './UseLSCReportingSelection';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import Constants from '../../../../common/utils/Constants';

const LSCReportingSelection = () => {
  const {
    isLoaded,
    NavLink,
    roleGroupId
  } = useLSCReportingSelection();

  return (
    <Fragment>
      {isLoaded === true
        ? (
          <SelectOrgUnit
            pageTitle={'LSC Reports'}
            navLink={NavLink}
            roleGroupId={roleGroupId} />
        )
        : (
          <PopUpModal
            widthPct={50}
            maxWidth={250}
            title={Constants.LOADING_MSG}
            displayPopUp={true} />
        )
      }
    </Fragment>
  );
};

export default LSCReportingSelection;