import { useContext, useEffect } from 'react';

import { IneligibleReasonStateContext } from './IneligibleReasonContextProvider';
import IneligibleReasonData, { defaultIneligibleReasonState } from './IneligibleReasonData';

const useIneligibleReasonData = () => {
  const [ineligibleReasonState, setIneligibleReasonState] = useContext(IneligibleReasonStateContext);

  useEffect(() => {
    if (ineligibleReasonState.isLoading !== true && ineligibleReasonState.isLoaded !== true) {
      IneligibleReasonData.getIneligibleReasons(ineligibleReasonState, setIneligibleReasonState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ineligibleReasonState,
    defaultIneligibleReasonState
  };
};

export default useIneligibleReasonData;