import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOfficialPositionTypeData from './GetOfficialPositionTypeData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  orgLevelId: '',
  hasEvaluationForm: ''
};

const OfficialPositionTypeData = {
  INITIAL_STATE, getOfficialPositionTypeData
}

export default OfficialPositionTypeData;