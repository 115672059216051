import { useEffect } from 'react';

import useAffiliationsOrgRoleData from '../../state/member/memberAffiliations/affiliationsOrgRole/UseAffiliationsOrgRoleData';

const useAffiliationsOrgRoleTreeView = (organizationId) => {
  const { affiliationsOrgRoleState, getAffiliationsOrgRolesByOrganizationId } = useAffiliationsOrgRoleData();

  useEffect(() => {
    if (organizationId > 0 && organizationId !== affiliationsOrgRoleState.organizationId) {
      getAffiliationsOrgRolesByOrganizationId(organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, affiliationsOrgRoleState]);

  return {
    affiliationsOrgRoleState
  };
};

export default useAffiliationsOrgRoleTreeView;