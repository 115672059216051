import { useContext } from 'react';

import GenderIdentityTypeData from './GenderIdentityTypeData';
import { GenderIdentityTypeStateContext } from './GenderIdentityTypeContextProvider';

const useGenderIdentityTypeData = () => {
  const [genderIdentityState, setGenderIdentityState] = useContext(GenderIdentityTypeStateContext);

  const getGenderIdentityTypes = () =>
  GenderIdentityTypeData.getGenderIdentityTypes(genderIdentityState, setGenderIdentityState);

  return {
    genderIdentityState,
    getGenderIdentityTypes,
    setGenderIdentityState
  }
}

export default useGenderIdentityTypeData;