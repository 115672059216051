import { useState, useEffect } from 'react';

import usePersonCancelMembershipData from '../../../state/member/memberCancelMembership/UsePersonCancelMembershipData';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import Constants from '../../../../common/utils/Constants';

const INITIAL_STATE = {
  modalState: {
    modalTitle: 'Cancel Membership',
    displayPopUp: false,
    personOrgRoleDurationId: Constants.DEFAULT_ID
  },
  tryGet: false
};

const useMemberCancelMembership = () => {
  const { basePersonState } = useBasePersonData();
  const { getPersonCancelMembership, personCancelMembershipState, deletePersonMembership } = usePersonCancelMembershipData();
  const [state, setState] = useState(INITIAL_STATE);

  const onOpenCancelModalClicked = (e, person) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      modalState: {
        ...state.modalState,
        displayPopUp: true,
        personOrgRoleDurationId: person.personOrgRoleDurationId
      }
    });
  };

  const onModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      modalState: {
        ...state.modalState,
        displayPopUp: false,
      }
    });
  };

  const onCancelMembership = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (basePersonState?.objData?.personId && state?.modalState?.personOrgRoleDurationId > 0) {
      const personId = basePersonState?.objData?.personId;

      deletePersonMembership(personId);
    };
  };

  useEffect(() => {
    if (personCancelMembershipState.isSaved === true) {

      setState({
        ...state,
        tryGet: true,
        modalState: {
          ...state.modalState,
          displayPopUp: false,
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personCancelMembershipState.isSaved]);


  useEffect(() => {
    if (basePersonState?.objData?.personId !== undefined || state.tryGet === true) {
      const personId = basePersonState.objData.personId;

      getPersonCancelMembership(personId);
      setState({ ...state, tryGet: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, state.tryGet]);


  return {
    modalState: state?.modalState,
    onModalCanceled,
    onOpenCancelModalClicked,
    onCancelMembership,
    personCancelMembershipState,
  };
};

export default useMemberCancelMembership;