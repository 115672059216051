import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../../common/wrappers/ReactRouterDom';

import OfferingManagementContextView from './HqOfferingManagementContextView';

import HqOffering from './hqOffering/HqOffering';
import HqOfferingType from './hqOffering/hqOfferingType/HqOfferingType';
import HqOfferingTypeClubAdd from './hqOffering/hqOfferingTypeClubAdd/HqOfferingTypeClubAdd';
import HqOfferingTypeUSASAdd from './hqOffering/hqOfferingTypeUSASAdd/HqOfferingTypeUSASAdd';
import HqOfferingTypeClubEdit from './hqOffering/hqOfferingTypeClubEdit/HqOfferingTypeClubEdit';
import HqOfferingTypeUSASEdit from './hqOffering/hqOfferingTypeUSASEdit/HqOfferingTypeUSASEdit';

import HqOfferingInstance from './hqOfferingInstance/HqOfferingInstance';
import HqOfferingInstanceAdd from './hqOfferingInstance/hqOfferingInstanceAdd/HqOfferingInstanceAdd';
import HqOfferingInstanceEdit from './hqOfferingInstance/hqOfferingInstanceEdit/HqOfferingInstanceEdit';

import HqLscOfferingInstanceOrgUnit from './hqLscOfferingInstanceOrgUnit/HqLscOfferingInstanceOrgUnit';
import HqLscOfferingInstanceOrgUnitAdd from './hqLscOfferingInstanceOrgUnit/hqLscOfferingInstanceOrgUnitAdd/HqLscOfferingInstanceOrgUnitAdd';
import HqLscOfferingInstanceOrgUnitEdit from './hqLscOfferingInstanceOrgUnit/hqLscOfferingInstanceOrgUnitEdit/HqLscOfferingInstanceOrgUnitEdit';

import OfferingTypesContextProvider from '../../../state/offeringTypes/OfferingTypesContextProvider';
import OfferingManagementContextProvider from '../../../state/offeringManagement/OfferingManagementContextProvider';
import OfferingManagementInstanceContextProvider from '../../../state/offeringManagement/OfferingManagementInstanceContextProvider';
import OfferingManagementInstanceOrgUnitContextProvider from '../../../state/offeringManagementInstanceOrgUnit/OfferingManagementInstanceOrgUnitContextProvider';

import LoadingModal from '../../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import ReportPeriodSeasonContextProvider from '../../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider';

const HqOfferingManagementRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <OfferingManagementContextView>
      <Routes>
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_OFFERINGS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_OFFERINGS']}
              editElement={<HqOffering />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_TYPE_SELECT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_TYPE_SELECT']}
              editElement={<HqOfferingType />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_TYPE_CLUB_ADD']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_TYPE_CLUB_ADD']}
              editElement={<HqOfferingTypeClubAdd />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_TYPE_USAS_ADD']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_TYPE_USAS_ADD']}
              editElement={<HqOfferingTypeUSASAdd />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_TYPE_CLUB_EDIT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_TYPE_CLUB_EDIT']}
              editElement={<HqOfferingTypeClubEdit />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_TYPE_USAS_EDIT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_TYPE_USAS_EDIT']}
              editElement={<HqOfferingTypeUSASEdit />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE']}
              editElement={<HqOfferingInstance />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE_ADD']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE_ADD']}
              editElement={<HqOfferingInstanceAdd />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE_EDIT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE_EDIT']}
              editElement={<HqOfferingInstanceEdit />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_LSC_OFFERINGS']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_LSC_OFFERINGS']}
              editElement={<HqLscOfferingInstanceOrgUnit />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_LSC_OFFERING_ADD']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_LSC_OFFERING_ADD']}
              editElement={<HqLscOfferingInstanceOrgUnitAdd />} />
          } />
        <Route path={navRoutes['OFFERING_MANAGMENT_HQ_LSC_OFFERING_EDIT']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OFFERING_MANAGMENT_HQ_LSC_OFFERING_EDIT']}
              editElement={<HqLscOfferingInstanceOrgUnitEdit />} />
          } />
        <Route path={'/'} element={<Navigate to={navRoutes['OFFERING_MANAGMENT_HQ_OFFERINGS']?.route} replace />} />
      </Routes>
    </OfferingManagementContextView>
  );
};

const HqOfferingManagement = () => (
  <ReportPeriodSeasonContextProvider>
    <OfferingTypesContextProvider>
      <OfferingManagementContextProvider>
        <OfferingManagementInstanceContextProvider>
          <OfferingManagementInstanceOrgUnitContextProvider>
            <HqOfferingManagementRoutes />
          </OfferingManagementInstanceOrgUnitContextProvider>
        </OfferingManagementInstanceContextProvider>
      </OfferingManagementContextProvider>
    </OfferingTypesContextProvider>
  </ReportPeriodSeasonContextProvider>
);

export default HqOfferingManagement;