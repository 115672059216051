import { useContext } from 'react';

import { OtsOfficialNationalCertificationStateContext } from './OtsOfficialNationalCertificationContextProvider';
import OtsOfficialNationalCertificationData from './OtsOfficialNationalCertificationData';

const useOtsOfficialNationalCertificationData = () => {
  const [otsOfficialNationalCertificationState, setOtsOfficialNationalCertificationState] = useContext(OtsOfficialNationalCertificationStateContext);

  const getOtsOfficialNationalCertificationByPersonId = (personId) => OtsOfficialNationalCertificationData?.getOtsOfficialNationalCertificationByPersonId(personId, otsOfficialNationalCertificationState, setOtsOfficialNationalCertificationState);

  const putOtsOfficialNationalCertificationByPersonId = (personId, otsOfficialCertArray) => OtsOfficialNationalCertificationData?.putOtsOfficialNationalCertificationByPersonId(personId, otsOfficialCertArray, otsOfficialNationalCertificationState, setOtsOfficialNationalCertificationState);

  const clearOtsOfficialNationalCertificationState = () => {
    setOtsOfficialNationalCertificationState({ ...OtsOfficialNationalCertificationData?.INITIAL_STATE });
  };

  return {
    otsOfficialNationalCertificationState,
    setOtsOfficialNationalCertificationState,
    getOtsOfficialNationalCertificationByPersonId,
    putOtsOfficialNationalCertificationByPersonId,
    clearOtsOfficialNationalCertificationState
  };
};

export default useOtsOfficialNationalCertificationData;