import CurrencyInput from '../../../../../../common/components/inputs/CurrencyInput';
import ActionIntraPageButton from '../../../../../../common/components/buttons/ActionIntraPageButton';

import { convertNumberToCurrencyString } from '../../../../../../common/utils/CurrencyUtils';

import global from '../../../../../../common/components/GlobalStyle.module.css';
import style from '../../../OfferingManagement.module.css';

const GridRow = ({ coupon, onCouponValueChanged, onSaveCouponValue }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span>{coupon.couponName}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          LSC Fee
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData, style.CouponFormGridInputCell].join(' ')}>
          {coupon.isActive === true
            ? (
              <CurrencyInput
                label=''
                name={"couponValue" + coupon.offeringInstanceCouponDiscountId}
                value={coupon.couponValue}
                onChange={(value) => { onCouponValueChanged(coupon.offeringInstanceCouponDiscountId, value); }} />
            ) : <span>${convertNumberToCurrencyString(coupon.couponValue)}</span>
          }
        </div>
        <div className={['col-xs-12 col-sm-3 usas-extra-top-margin', global.SmallTableRowData].join(' ')}>
          {coupon.isActive === true
            ? <ActionIntraPageButton onClick={(e) => onSaveCouponValue(e, coupon)}>Save</ActionIntraPageButton>
            : <span>&nbsp;</span>
          }
        </div>
      </div>
    </div>
  </div>
);

const OfferingInstanceCouponDiscountFormGridSmall = ({ gridData, isLoading, onCouponValueChanged, onSaveCouponValue }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) === true && gridData.length > 0
        ? gridData.map((coupon, i) =>
          <GridRow
            key={i}
            coupon={coupon}
            onCouponValueChanged={onCouponValueChanged}
            onSaveCouponValue={onSaveCouponValue} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div>
);

export default OfferingInstanceCouponDiscountFormGridSmall;