import React, { useState, createContext } from 'react';

export const SearchOtsOfficialsForBulkRecertifyFilterStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: '',
  backToSystem: 'Ots Official'
};

const SearchOtsOfficialsForBulkRecertifyFilterContextProvider = ({ children }) => {
  const stateHook = useState({ INITIAL_STATE });

  return (
    <SearchOtsOfficialsForBulkRecertifyFilterStateContext.Provider value={stateHook}>
      {children}
    </SearchOtsOfficialsForBulkRecertifyFilterStateContext.Provider>
  );
};

export default SearchOtsOfficialsForBulkRecertifyFilterContextProvider;