import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getDecryptedIdData = (encryptedIdObj, state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/App/DecryptId`;

  return api ? api.executeObject(url, 'POST', encryptedIdObj) : null;
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const DecryptedIdData = {
  INITIAL_STATE,
  getDecryptedIdData
};

export default DecryptedIdData;