import { useContext, useEffect } from 'react';

import { SocialMediaTypeStateContext } from './SocialMediaTypeContextProvider';

import SocialMediaTypeData, { defaultSocialMediaTypeState } from './SocialMediaTypeData';

const useSocialMediaTypeData = () => {
  const [socialMediaTypeState, setSocialMediaTypeState] = useContext(SocialMediaTypeStateContext);

  useEffect(() => {
    if (socialMediaTypeState.isLoading !== true && socialMediaTypeState.isLoaded !== true) {
      SocialMediaTypeData.getSocialMediaTypes(socialMediaTypeState, setSocialMediaTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    socialMediaTypeState,
    defaultSocialMediaTypeState
  };
};

export default useSocialMediaTypeData;