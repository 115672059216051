
import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useMeetEvaluationData from '../../../../state/otsMeetEvaluations/UseMeetEvaluationData';
import useOtsMeetOfficialsData from '../../../../state/officialsTrackingSystemMeetOfficials/UseOtsMeetOfficialsData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../../common/utils/Constants';

const useOtsMeetEvaluations = () => {
  const navigate = useNavigate();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { meetEvaluationState, getMeetEvaluationsByOtsMeetId, deleteMeetEvaluation, confirmMeetEvaluationSave } = useMeetEvaluationData();
  const { clearOtsMeetOfficialsArrayData } = useOtsMeetOfficialsData();
  const { navRoutes } = useNavRoutes();
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState());

  const onAddEvaluation = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_EVALUATIONS_DETAIL?.route);
  }

  const onGoToEvaluationFormClicked = (e, evaluation) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_EVALUATIONS_FORM?.route, {
      state: {
        evaluation: evaluation
      }
    });
  };

  const onReadOnlyEvaluationClicked = (e, evaluation) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(navRoutes.OTS_MEET_EVALUATIONS_FORM?.route, {
      state: {
        evaluation: evaluation
      }
    });
  }

  const onDeleteEvaluationClicked = (e, evaluation) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setDeleteModalState({
      ...deleteModalState,
      displayPopUp: true,
      meetEvaluationId: evaluation.meetEvaluationId || Constants.DEFAULT_ID,
      officialName: `${evaluation.officialFirstName || ''} ${evaluation.officialLastName || ''}`,
      positionTypeName: evaluation.positionTypeName,
      evaluatorName: `${evaluation.evaluatorFirstName || ''} ${evaluation.evaluatorLastName || ''}`,
      evaluationLevelName: evaluation.evaluationLevelName,
      completed: evaluation.hasEvaluation === true ? 'Yes' : 'No'
    });
  };

  const onDeleteEvaluation = () => {
    deleteMeetEvaluation(deleteModalState.meetEvaluationId);
    setDeleteModalState(getInitialDeleteModalState());
  };

  const onDeleteModalCanceled = () => {
    setDeleteModalState(getInitialDeleteModalState());
  };

  useEffect(() => {
    clearOtsMeetOfficialsArrayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (otsMeetHeaderState.objData?.otsMeetId) {
      getMeetEvaluationsByOtsMeetId(otsMeetHeaderState.objData?.otsMeetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (otsMeetHeaderState.objData?.otsMeetId && meetEvaluationState.isSaved === true &&
      meetEvaluationState.isArrayLoading === false) {
      confirmMeetEvaluationSave();
      getMeetEvaluationsByOtsMeetId(otsMeetHeaderState.objData?.otsMeetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetHeaderState, meetEvaluationState.isSaved])

  function getInitialDeleteModalState() {
    return {
      meetEvaluationId: Constants.DEFAULT_ID,
      officialName: '',
      positionTypeName: '',
      evaluatorName: '',
      evaluationLevelName: '',
      completed: '',
      modalTitle: 'Delete Meet Evaluation?',
      displayPopUp: false
    };
  }

  return {
    meetEvaluationState,
    isLoading: meetEvaluationState.isArrayLoading,
    isSaving: meetEvaluationState.isSaving,
    Constants,
    onAddEvaluation,
    onGoToEvaluationFormClicked,
    onReadOnlyEvaluationClicked,
    deleteModalState,
    onDeleteEvaluationClicked,
    onDeleteEvaluation,
    onDeleteModalCanceled
  };
}

export default useOtsMeetEvaluations;