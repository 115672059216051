import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetOfficialRoleTypeData from './GetMeetOfficialRoleTypeData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  orgLevelId: '',
  officialPositionTypeId: ''
};

const MeetOfficialRoleTypeData = {
  INITIAL_STATE, getMeetOfficialRoleTypeData
}

export default MeetOfficialRoleTypeData;