import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../../common/wrappers/ReactRouterDom';

import MyLscOtsMeetSelection from './otsMeetSearch/myLscOtsMeetSelection/MyLscOtsMeetSelection';
import MyLscOtsMeetSearch from './otsMeetSearch/MyLscOtsMeetSearch';
import MyLscOtsMeetSearchContextView from './otsMeetSearch/MyLscOtsMeetSearchContextView';

import SecuredRouteElement from '../../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../../common/components/dialogs/LoadingModal';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const ContextRoutes = ({ navRoutes }) => (
  <MyLscOtsMeetSearchContextView>
    <Routes>
      <Route path={navRoutes['OTS_MEET_MY_LSC_OTS_MEETS_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_MEET_MY_LSC_OTS_MEETS_SEARCH']}
            editElement={<MyLscOtsMeetSearch />} />
        }
      />
    </Routes>
  </MyLscOtsMeetSearchContextView>
);

const MyLscOfficialsTrackingSystemMeetRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['OTS_MEET_MY_LSC_OTS_MEETS_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_MEET_MY_LSC_OTS_MEETS_SELECTION']}
            editElement={<MyLscOtsMeetSelection />} />
        }
      />

      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['OTS_MEET_MY_LSC_OTS_MEETS_SELECTION']?.route} replace />} />
    </Routes>
  );
}

const MyLscOfficialsTrackingSystemMeet = () => (
      <MyLscOfficialsTrackingSystemMeetRoutes />
);

export default MyLscOfficialsTrackingSystemMeet;