import React, { Fragment } from 'react';

import useMemberCancelMembership from './UseMemberCancelMembership';
import LargeCancelMembershipGrid from './LargeCancelMembershipGrid';
import SmallCancelMembershipGrid from './SmallCancelMembershipGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberCancelMembership = () => {
  const {
    modalState,
    onModalCanceled,
    onOpenCancelModalClicked,
    onCancelMembership,
    personCancelMembershipState
  } = useMemberCancelMembership();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Cancel Membership</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <span className={global.UsasLabel}>Eligible Membership(s) To Cancel</span>
          <LargeCancelMembershipGrid state={personCancelMembershipState} onOpenCancelModalClicked={onOpenCancelModalClicked} />
          <SmallCancelMembershipGrid state={personCancelMembershipState} onOpenCancelModalClicked={onOpenCancelModalClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={720}
        title="Cancel Membership"
        onModalCanceled={onModalCanceled}
        displayPopUp={modalState.displayPopUp}>
        <div className="row">
          <div className="col-xs-12">
            <p className={['col-xs-12 text-center', global.Bold].join(' ')}>You are about to cancel all your current USA Swimming Memberships</p>
            <p className={['col-xs-12 text-center', global.Bold].join(' ')}>Are you sure you want to cancel your memberships? You cannot undo this action.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin text-center">
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>&nbsp;
            <PrimaryButton type="button" onClick={(e) => onCancelMembership(e)}>Yes, Cancel Memberships</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personCancelMembershipState?.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personCancelMembershipState?.isSaving} />
    </Fragment >
  );
};

export default MemberCancelMembership;