import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRowIcons = ({ coachType, coachName, coachPersonId, onAddEditCoachType, onDeleteCoachType }) => (
  <Fragment>
    <button className={global.IconButton} type="button" onClick={(e) =>
      onDeleteCoachType(e, coachPersonId, coachType.personCoachTypeId, `${coachType.coachType} (Effective Date: ${formatDate(coachType.effectiveDate)}, Expiration Date: ${formatDate(coachType.expirationDate)})`)}>
      <DeleteIcon />
    </button>
    {coachType.confirmedDates === false ?
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
        onAddEditCoachType(e, coachPersonId, coachType.personCoachTypeId, coachName)}>
        <EditIcon />
      </button> : <Fragment />
    }
  </Fragment>
);

const DetailGridRow = ({ coachType, onAddEditCoachType, onDeleteCoachType, coachPersonId, coachName }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{coachType.coachType || ''}</span>&nbsp;
      <DetailGridRowIcons coachType={coachType} coachName={coachName} coachPersonId={coachPersonId} onAddEditCoachType={onAddEditCoachType} onDeleteCoachType={onDeleteCoachType} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Coach Type
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {coachType.coachType || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {coachType.effectiveDate ? formatDate(coachType.effectiveDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {coachType.expirationDate ? formatDate(coachType.expirationDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Primary?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {coachType.isPrimary === true ? 'Yes' : 'No'}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Confirmed Dates?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {coachType.confirmedDates === true ? 'Yes' : 'No'}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ coach, onAddEditCoachType, onDeleteCoachType }) => (
  <div className={global.SmallTableDetailTable}>
    {coach.coachType.map((coachType, i) =>
      <DetailGridRow coachType={coachType} key={i} onAddEditCoachType={onAddEditCoachType} onDeleteCoachType={onDeleteCoachType} coachPersonId={coach.coachPersonId} coachName={coach.coachFirstName + ' ' + coach.coachLastName} />)}
  </div>
);

const GridRowIcons = ({ coach, expandedId, onAddEditCoach, onAddEditCoachType, onDelete, onExpandClicked, coachName }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, coach.coachPersonId)}>
      {expandedId === coach.coachPersonId ? <HideIcon /> : <ShowIcon />}
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
      onDelete(e, coach.coachPersonId, `${coachName} (Last Modified Date: ${formatDate(coach.modifiedDatetime)})`)}>
      <DeleteIcon />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onAddEditCoachType(e, coach.coachPersonId, -1, coach.coachFirstName + ' ' + coach.coachLastName)}>
      <PlusIcon toolTipText="Add Coach Type" />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
      onAddEditCoach(e, coach.coachPersonId, coachName)}>
      <EditIcon />
    </button>
  </Fragment>
);

const GridRow = ({ coach, expandedId, onDelete, onDeleteCoachType, onAddEditCoach, onAddEditCoachType, coachName, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{coach.coachFirstName || ''} {coach.coachLastName || ''}</span>&nbsp;
        <GridRowIcons coach={coach} expandedId={expandedId} onAddEditCoach={onAddEditCoach} onAddEditCoachType={onAddEditCoachType} onDelete={onDelete} onExpandClicked={onExpandClicked} coachName={coachName} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Coach Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {coach.coachFirstName || ''} {coach.coachLastName || ''}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Email
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {coach.coachEmail || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Is Active?
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {coach.isActiveCoach === 1 ? 'Yes' : 'No'}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Coach Incentive?
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {coach.qualifiesForCoachIncentive === true ? 'Yes' : 'No'}
          </div>
        </div>
      </div>
    </div>
    {Array.isArray(coach.coachType) && coach.coachType.length > 0 && expandedId === coach.coachPersonId
      && <DetailGrid coach={coach} onAddEditCoachType={onAddEditCoachType} onDeleteCoachType={onDeleteCoachType} />
    }
  </Fragment>
);

const ProfileCoachSmallGrid = ({ gridState, expandedId, onDelete, onDeleteCoachType, onAddEditCoach, onAddEditCoachType, onExpandClicked }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {gridState.isArrayLoading === true ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridState?.arrayData?.personCoach) && gridState?.arrayData?.personCoach?.length > 0
        ? gridState?.arrayData?.personCoach?.map((coach, i) => (
          <GridRow
            key={i}
            expandedId={expandedId}
            coach={coach}
            onDelete={onDelete}
            onDeleteCoachType={onDeleteCoachType}
            onAddEditCoach={onAddEditCoach}
            onAddEditCoachType={onAddEditCoachType}
            coachName={coach.coachFirstName + ' ' + coach.coachLastName}
            onExpandClicked={onExpandClicked}
          />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Coaches</div>
            </div>
          </div>
        )
      }
    </div>
  </Fragment >
);


export default ProfileCoachSmallGrid;