import { createContext, useState } from 'react';

import OfficialPositionCertificationTypeProgressionData from './OfficialPositionCertificationTypeProgressionData';

export const OfficialPositionCertificationTypeProgressionStateContext = createContext();

const OfficialPositionCertificationTypeProgressionContextProvider = ({ children }) => {
  const stateHook = useState(OfficialPositionCertificationTypeProgressionData.INITIAL_STATE);

  return (
    <OfficialPositionCertificationTypeProgressionStateContext.Provider value={stateHook}>
      {children}
    </OfficialPositionCertificationTypeProgressionStateContext.Provider>
  );
};

export default OfficialPositionCertificationTypeProgressionContextProvider;