import { useContext } from 'react';

import { OrgRoleHierarchyStateContext } from './OrgRoleHierarchyContextProvider';
import OrgRoleHierarchyData from './OrgRoleHierarchyData';

const useOrgRoleHierarchyData = () => {
  const [orgRoleHierarchyState, setOrgRoleHierarchyState] = useContext(OrgRoleHierarchyStateContext);

  const getOrgRolesHierarchyByOrganizationId = (organizationId) => OrgRoleHierarchyData.getOrgRolesHierarchyByOrganizationId(organizationId, orgRoleHierarchyState, setOrgRoleHierarchyState);

  return {
    orgRoleHierarchyState,
    getOrgRolesHierarchyByOrganizationId
  };
};

export default useOrgRoleHierarchyData;