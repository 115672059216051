import { useEffect } from 'react';

import useOrgUnitData from '../../../state/orgUnitLowestChildren/UseOrgUnitLowestChildrenData';

import useEnvironmentVariableData from '../../../state/environmentVariable/UseEnvironmentVariableData';
import Constants from '../../../utils/Constants';

const useOrgUnitCombobox = (organizationId, lscId) => {
  const { orgUnitLowestChildrenState, getOrgUnitsByOrganization, getOrgUnitsByLsc } = useOrgUnitData();
  const { environmentVariableState, USAS_ORGANIZATION_ID } = useEnvironmentVariableData();

  useEffect(() => {
    if (environmentVariableState.isLoaded === true
      && orgUnitLowestChildrenState.isLoading === false) {
      //USAS with LSC selected
      if (organizationId === USAS_ORGANIZATION_ID && lscId !== Constants.DEFAULT_ID && lscId !== '' && lscId !== undefined
        && (organizationId !== orgUnitLowestChildrenState.organizationId || lscId !== orgUnitLowestChildrenState.lscId)) {
        getOrgUnitsByLsc(organizationId, lscId);
      }

      //FINA, NCAA, and USAS without LSC selected
      else if (organizationId > 0
        && (lscId === Constants.DEFAULT_ID || lscId === '' || lscId === undefined)
        && (organizationId !== orgUnitLowestChildrenState.organizationId || lscId !== orgUnitLowestChildrenState.lscId)) {
        getOrgUnitsByOrganization(organizationId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, lscId, environmentVariableState, orgUnitLowestChildrenState.isLoading]);

  return { orgUnitLowestChildrenState, getOrgUnitsByOrganization, getOrgUnitsByLsc };
};

export default useOrgUnitCombobox;