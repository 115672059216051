import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOfficialPositionCertificationTypeProgressionData from './GetOfficialPositionCertificationTypeProgressionData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  personId: '',
  personPositionCertificationRequestId: ''
};

const OfficialPositionCertificationTypeProgressionData = {
  INITIAL_STATE, getOfficialPositionCertificationTypeProgressionData
}

export default OfficialPositionCertificationTypeProgressionData;