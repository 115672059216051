import { useState, } from 'react';

import PersonClubTransferData from './PersonClubTransfersData';

const usePersonClubTransfers = () => {
  const [memberClubTransferState, setMemberClubTransferState] = useState(PersonClubTransferData.INITIAL_STATE);
  const [deleteMemberClubTransferState, setDeleteMemberClubTransferState] = useState(PersonClubTransferData.INITIAL_STATE);

  const getPersonClubTransfers = (personId) => {
    PersonClubTransferData.getPersonClubTransfers(personId, memberClubTransferState, setMemberClubTransferState);
  };

  const putPersonClubTransfer = (personId, personObj) => {
    PersonClubTransferData.putPersonClubTransfer(personId, personObj, memberClubTransferState, setMemberClubTransferState);
  }

  const deletePersonClubTransfer = (personOrgRoleUnattachedId) => {
    PersonClubTransferData.deletePersonClubTransfer(personOrgRoleUnattachedId, deleteMemberClubTransferState, setDeleteMemberClubTransferState);
  };


  return {
    memberClubTransferState,
    setMemberClubTransferState,
    getPersonClubTransfers,
    putPersonClubTransfer,
    deletePersonClubTransfer,
    deleteMemberClubTransferState, 
    setDeleteMemberClubTransferState

  };
};

export default usePersonClubTransfers;