import { useEffect, useState } from 'react';

import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import LSCReportingNavLinks from '../LSCReportingNavLinks';

const INITIAL_VIEW_STATE = {
  reportParameters: { orgUnitId: '' },
  routeName: ''
};

const useLSCReportingReports = () => {
  const navigate = useNavigate();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });

  const onBackToSelectionClicked = () => {
    navigate(LSCReportingNavLinks.LSC_REPORTING_SELECTION);
  };

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId) {
      setViewState({
        ...viewState,
        reportParameters: { orgUnitId: selectOrgUnitState.orgUnitId },
        orgUnitId: selectOrgUnitState.orgUnitId,
        orgUnitName: selectOrgUnitState.orgUnitName,
        routeName: 'LSC_REPORTING_ROOT'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId]);

  return {
    ...viewState,
    selectOrgUnitState,
    onBackToSelectionClicked
  };
};

export default useLSCReportingReports;