import React from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ frequentFlyer, onAddEdit, onDelete }) => (
  <tr>
    <td>{frequentFlyer.frequentFlyerType?.typeName || ''}</td>
    <td>{frequentFlyer.frequentFlyerNumber || ''}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddEdit(e, frequentFlyer.personFrequentFlyerId)}>
        <EditIcon />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={(e) =>
        onDelete(e, frequentFlyer.personFrequentFlyerId, `${frequentFlyer.frequentFlyerType.typeName} (Frequent Flyer Number: ${frequentFlyer.frequentFlyerNumber})`)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const NationalFrequentFlyerLargeGrid = ({ gridState, onAddEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Carrier Name</th>
        <th>Frequent Flyer Number</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {gridState.isObjLoaded !== true
        ? (
          <tr><td colSpan="3">{gridState.isObjLoading === true ? 'Loading...' : 'No Frequent Flyer Info'}</td></tr>
        ) : Array.isArray(gridState.objData.frequentFlyerDetails) && gridState.objData.frequentFlyerDetails.length > 0
          ? gridState.objData.frequentFlyerDetails.map((frequentFlyer, i) => (
            <GridRow
              key={i}
              frequentFlyer={frequentFlyer}
              onAddEdit={onAddEdit}
              onDelete={onDelete}
            />))
          :
          <tr><td colSpan="3">No Frequent Flyer Info</td></tr>
      }
    </tbody>
  </table>
);

export default NationalFrequentFlyerLargeGrid;