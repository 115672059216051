import { useEffect, useState } from "react";

import { MERGE_FIELDS } from "../utils/MemberMergeConstants";
import { getMergeFieldDisplayValue } from "../utils/MemberMergeUtils";

const useMemberMergeConfirmationPopup = (personMergeObj) => {
  const [gridState, setGridState] = useState([]);
  
  useEffect(() => {
    if (personMergeObj) {
      const newGridState = [];

      for (const mergeField of MERGE_FIELDS) {
        const displayValue = getMergeFieldDisplayValue(mergeField.displayValueKey, personMergeObj);

        newGridState.push({
          ...mergeField,
          displayValue
        });
      }

      setGridState(newGridState);
    }
  }, [personMergeObj]);

  return {
    gridState
  };
};

export default useMemberMergeConfirmationPopup;