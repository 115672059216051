import Checkbox from '../../../../../common/components/checkboxes/Checkbox';
import NotInGoodStandingIcon from '../../../../../common/components/icons/NotInGoodStandingIcon';
import FileExclamationIcon from '../../../../../common/components/icons/FileExclamationIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

const GridRow = ({ passedInMeetEndDate, official, onCheckboxChange, index }) => {
  const meetEndDate = new Date(passedInMeetEndDate);
  const expirationDateForRegistration = new Date(official?.registrationExpirationDate);
  const expirationDateForApt = new Date(official?.aptExpirationDate);
  const expirationDateForBgc = new Date(official?.bgcExpirationDate);
  const expirationDateForCpt = new Date(official?.cptExpirationDate);
  return (
    <tr key={official.personId + index}>
      <td>{official.isInGoodStanding === false ? <span style={{ color: 'red' }}>{official.firstName || ''} {official.lastName || ''}{official.memberId ? ' - ' + official.memberId : ''} <NotInGoodStandingIcon /></span> : <span>{official.firstName || ''} {official.lastName || ''}{official.memberId ? ' - ' + official.memberId : ''}</span>}</td>
      <td>{official.lscCode || ''} / {official.clubCode || ''}</td>
      <td>{expirationDateForRegistration < meetEndDate
        ? <span style={{ color: 'red' }}>{official.registrationExpirationDate ? formatDate(official.registrationExpirationDate) === Constants.DEFAULT_MAX_DATE
          ? 'No Expiration' : formatDate(official.registrationExpirationDate) : 'No Registration'}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.registrationExpirationDate ? 'Registration Expired' : 'No Registration'} /></span> :
        <span>{official.registrationExpirationDate ? formatDate(official.registrationExpirationDate) === Constants.DEFAULT_MAX_DATE
          ? 'No Expiration' : formatDate(official.registrationExpirationDate) : ''}</span>}</td>
      <td>{expirationDateForApt < meetEndDate
        ? <span style={{ color: 'red' }}>{official.aptExpirationDate ? formatDate(official.aptExpirationDate) === Constants.DEFAULT_MAX_DATE
          ? 'No Expiration' : formatDate(official.aptExpirationDate) : 'Not Completed'}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.aptExpirationDate ? 'Course Expired' : 'Not Completed'} /></span> :
        <span>{official.aptExpirationDate ? formatDate(official.aptExpirationDate) === Constants.DEFAULT_MAX_DATE
          ? 'No Expiration' : formatDate(official.aptExpirationDate) : ''}</span>}</td>
      <td>{expirationDateForBgc < meetEndDate
        ? <span style={{ color: 'red' }}>{official.bgcExpirationDate ? formatDate(official.bgcExpirationDate) === Constants.DEFAULT_MAX_DATE
          ? 'No Expiration' : formatDate(official.bgcExpirationDate) : 'Not Completed'}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.bgcExpirationDate ? 'BGC Expired' : 'Not Completed'} /></span> :
        <span>{official.bgcExpirationDate ? formatDate(official.bgcExpirationDate) === Constants.DEFAULT_MAX_DATE
          ? 'No Expiration' : formatDate(official.bgcExpirationDate) : ''}</span>}</td>
      <td>{expirationDateForCpt < meetEndDate
        ? <span style={{ color: 'red' }}>{official.cptExpirationDate ? formatDate(official.cptExpirationDate) === Constants.DEFAULT_MAX_DATE
          ? 'No Expiration' : formatDate(official.cptExpirationDate) : 'Not Completed'}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.cptExpirationDate ? 'Course Expired' : 'Not Completed'} /></span> :
        <span>{official.cptExpirationDate ? formatDate(official.cptExpirationDate) === Constants.DEFAULT_MAX_DATE
          ? 'No Expiration' : formatDate(official.cptExpirationDate) : ''}</span>}</td>
      <td>
        <Checkbox
          label=""
          name="isSelected"
          onChange={(e) => { onCheckboxChange(e, official.personId) }}
          checked={official.isSelected} />
      </td>
    </tr>
  );
};

const MeetOfficialsOfficialSelectionLargeGrid = ({ passedInMeetEndDate, gridData, isLoading, onCheckboxChange }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name - Member ID</th>
        <th>LSC / Club</th>
        <th style={{ minWidth: '110px' }}>Reg. Expires</th>
        <th style={{ minWidth: '110px' }}>APT Expires</th>
        <th style={{ minWidth: '110px' }}>BGC Expires</th>
        <th style={{ minWidth: '110px' }}>CPT Expires</th>
        <th>Select</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan={"7"}>Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((official, i) =>
            <GridRow
              key={i}
              passedInMeetEndDate={passedInMeetEndDate}
              official={official}
              onCheckboxChange={onCheckboxChange}
              index={i}
            />)
          :
          <tr>
            <td colSpan={"7"}>No Results</td>
          </tr>
      }
    </tbody>
  </table>
);

export default MeetOfficialsOfficialSelectionLargeGrid;