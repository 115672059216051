import { useContext } from 'react';

import { CertificationLevelStateContext } from './CertificationLevelContextProvider';

import CertificationLevelData from './CertificationLevelData';

const useCertificationLevelData = () => {
  const [certificationLevelState, setCertificationLevelState] = useContext(CertificationLevelStateContext);

  const getCertificationLevels = (orgLevelId, officialPositionTypeId) => CertificationLevelData.getCertificationLevelData(orgLevelId, officialPositionTypeId, certificationLevelState, setCertificationLevelState);

  const clearCertificationLevelObjData = () => {
    setCertificationLevelState({
      ...certificationLevelState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearCertificationLevelArrayData = () => {
    setCertificationLevelState({
      ...certificationLevelState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: '',
      positionType: ''
    });
  };

  const confirmCertificationLevelSave = () => {
    setCertificationLevelState({
      ...certificationLevelState,
      isSaved: false
    });
  };

  return {
    certificationLevelState,
    getCertificationLevels,
    clearCertificationLevelObjData,
    clearCertificationLevelArrayData,
    confirmCertificationLevelSave
  };
};

export default useCertificationLevelData;