import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetOfficialSessionsByOtsMeetIdData from './GetMeetOfficialSessionsByOtsMeetIdData';
import getMeetOfficialSessionsByOtsMeetIdAndPersonIdData from './GetMeetOfficialSessionsByOtsMeetIdAndPersonIdData';
import postMeetOfficialSessionData from './PostMeetOfficialSessionData';
import postMeetOfficialSessionByOtsMeetIdAndPersonIdData from './PostMeetOfficialSessionByOtsMeetIdAndPersonIdData';
import putMeetOfficialSessionData from './PutMeetOfficialSessionData';
import putMeetOfficialSessionByOtsMeetIdAndPersonIdData from './PutMeetOfficialSessionByOtsMeetIdAndPersonIdData';
import deleteMeetOfficialSessionData from './DeleteMeetOfficialSessionData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetOfficialSessionData = {
  INITIAL_STATE, getMeetOfficialSessionsByOtsMeetIdData, getMeetOfficialSessionsByOtsMeetIdAndPersonIdData,
  postMeetOfficialSessionData, postMeetOfficialSessionByOtsMeetIdAndPersonIdData,
  putMeetOfficialSessionData, putMeetOfficialSessionByOtsMeetIdAndPersonIdData,
  deleteMeetOfficialSessionData
};

export default MeetOfficialSessionData;