import { useContext, useState } from 'react';

import { OtsOfficialLscStateContext } from './OtsOfficialLscContextProvider';
import { OtsOfficialHeaderStateContext } from './OtsOfficialHeaderContextProvider';
import OtsOfficialLscData from './OtsOfficialLscData';

import Constants from '../../../common/utils/Constants';

const useOtsOfficialLscData = () => {
  const [otsOfficialLscState, setOtsOfficialLscState] = useContext(OtsOfficialLscStateContext);
  const [otsOfficialHeaderState, setOtsOfficialHeaderState] = useContext(OtsOfficialHeaderStateContext);
  const [otsLscRecertifyState, setOtsLscRecertifyState] = useState(OtsOfficialLscData.INITIAL_STATE)

  const getOtsOfficialLscCertificationsByPersonId = (personId) => OtsOfficialLscData?.getOtsOfficialLscCertificationsByPersonId(personId, otsOfficialLscState, setOtsOfficialLscState);

  const putOtsOfficialLscCertificationRecertify = (personId, otsOfficialCertObj) => OtsOfficialLscData.putOtsOfficialLscCertificationRecertify(personId, otsOfficialCertObj, otsLscRecertifyState, setOtsLscRecertifyState);

  const clearOtsOfficialState = () => {
    setOtsOfficialLscState({
      ...otsOfficialLscState,
      isObjLoading: false,
      isObjLoaded: false,
      isArrayLoaded: false,
      isArrayLoading: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      arrayData: [],
      message: ''
    });
  };

  const clearOtsOfficialHeaderObjData = () => {
    setOtsOfficialHeaderState({
      ...otsOfficialHeaderState,
      objData: {},
      orgUnitId: Constants.DEFAULT_ID,
      personId: Constants.DEFAULT_ID,
      isLoggedInUser: false,
    });
  };

  return {
    otsOfficialLscState,
    setOtsOfficialLscState,
    clearOtsOfficialHeaderObjData,
    otsOfficialHeaderState,
    setOtsOfficialHeaderState,
    getOtsOfficialLscCertificationsByPersonId,
    clearOtsOfficialState,
    putOtsOfficialLscCertificationRecertify,
    otsLscRecertifyState,
    setOtsLscRecertifyState
  };
};

export default useOtsOfficialLscData;