import { Fragment } from 'react';

import Checkbox from '../../../../../../common/components/checkboxes/Checkbox';
import ActionIntraPageButton from '../../../../../../common/components/buttons/ActionIntraPageButton';

import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ official, foundIndex, onAddOrRemoveLscCertficationForBulkRecertify, onOfficialHistoryReportLoadClick }) => (
  <Fragment>
    <tr>
      <td>{official?.firstName} {official?.middleName} {official?.lastName}</td>
      <td>{official?.positionTypeName} ({official?.positionAbbreviation})</td>
      <td>{official?.levelName}</td>
      <td>{official?.sessionsWorked >= 0 ? official?.sessionsWorked : ''}</td>
      <td>{formatDate(official?.expirationDate)}</td>
      <td>{official?.hasClinicTesting === true ? 'Yes' : 'No'}</td>
      <td><Checkbox
        name="personId"
        checked={foundIndex >= 0 ? true : false}
        onChange={(checked) => { onAddOrRemoveLscCertficationForBulkRecertify(checked, official, official?.personId) }} />
      </td>
      <td>
        <ActionIntraPageButton onClick={() => onOfficialHistoryReportLoadClick(official)}>Open Official&apos;s History Report</ActionIntraPageButton>
      </td>
    </tr>
  </Fragment>
);

const OtsOfficialLscBulkRecertifyLargeGrid = ({ state, selectedOfficials, onAddOrRemoveLscCertficationForBulkRecertify, onOfficialHistoryReportLoadClick }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Position</th>
        <th>Certification Level</th>
        <th>Sessions Worked</th>
        <th>Expiration Date</th>
        <th>Testing / Clinics Completed?</th>
        <th>Select</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state?.arrayData?.length > 0 ?
        state?.arrayData?.map((official, i) => {
          const foundIndex = selectedOfficials.findIndex(x => x.personId === official.personId);
          return (
            <LargeGridRow
              key={i}
              official={official}
              foundIndex={foundIndex}
              onAddOrRemoveLscCertficationForBulkRecertify={onAddOrRemoveLscCertficationForBulkRecertify}
              onOfficialHistoryReportLoadClick={onOfficialHistoryReportLoadClick} />)
        })
        : state?.isArrayLoading
          ? <tr><td colSpan="8">Loading...</td></tr>
          : state?.isArrayLoaded === true && state?.arrayData?.length === 0
            ? <tr><td colSpan="8">No Results</td></tr>
            : <tr><td colSpan="8">Please select a Position Worked and a Certification Level and click 'Filter' to generate results</td></tr>
      }
    </tbody>
  </table>
);

export default OtsOfficialLscBulkRecertifyLargeGrid