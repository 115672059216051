import { useState } from "react";

import OfferingsData from "./OfferingsData";

const useOfferingsData = () => {
  const [offeringsState, setOfferingsState] = useState(OfferingsData.INITIAL_OFFERINGS_STATE);

  const getAvailableOfferings = (reportPeriodId) => {
    return OfferingsData.getAvailableOfferingsData(reportPeriodId, offeringsState, setOfferingsState);
  };

  return {
    offeringsState,

    getAvailableOfferings
  };
};

export default useOfferingsData;