import { useEffect, useState } from 'react';

import useOtsOfficialNationalCertificationData from '../../../../state/officialsTrackingSystemOfficialNationalCertification/UseOtsOfficialNationalCertificationData';
import useOtsOfficialData from '../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';

import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';
import useSecurityData from '../../../../../common/state/security/UseSecurityData';

import useForm from '../../../../../common/utils/UseForm';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import { isValidDate } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  showAllNationalCertificationHistory: false,
};

const INITIAL_VIEW_STATE = {
  showError: false
};

const useOtsOfficialNationalCertificationHistory = () => {
  const { userInfo } = useSecurityData();
  const { basePersonState } = useBasePersonData();
  const { otsOfficialHeaderState } = useOtsOfficialData();
  const { otsOfficialNationalCertificationState, setOtsOfficialNationalCertificationState,
    getOtsOfficialNationalCertificationByPersonId, putOtsOfficialNationalCertificationByPersonId } = useOtsOfficialNationalCertificationData();
  const [gridState, setGridState] = useState({ arrayData: [] });
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { formState, errorState, handleSubmit, onFormValueChanged, resetForm } = useForm(INITIAL_FORM_STATE);

  const onUpdateEffectiveDateValueChange = (official, value) => {
    if (official) {
      const gridStateArrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      const gridStateSelectedIndex = gridStateArrayDataCopy.findIndex(x => (x.personPositionCertificationId === official.personPositionCertificationId));
      gridStateArrayDataCopy[gridStateSelectedIndex].selectedEffectiveDate = value;
      setGridState({ ...gridState, arrayData: gridStateArrayDataCopy });

      const arrayDataCopy = JSON.parse(JSON.stringify(otsOfficialNationalCertificationState.arrayData));
      const selectedIndex = arrayDataCopy.findIndex(x => (x.personPositionCertificationId === official.personPositionCertificationId));
      arrayDataCopy[selectedIndex].selectedEffectiveDate = value;
      setOtsOfficialNationalCertificationState({ ...otsOfficialNationalCertificationState, arrayData: arrayDataCopy });
    }
  };

  const onUpdateExpirationDateValueChange = (official, value) => {
    if (official) {
      const gridStateArrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      const gridStateSelectedIndex = gridStateArrayDataCopy.findIndex(x => (x.personPositionCertificationId === official.personPositionCertificationId));
      gridStateArrayDataCopy[gridStateSelectedIndex].selectedExpirationDate = value;
      setGridState({ ...gridState, arrayData: gridStateArrayDataCopy });

      const arrayDataCopy = JSON.parse(JSON.stringify(otsOfficialNationalCertificationState.arrayData));
      const selectedIndex = arrayDataCopy.findIndex(x => (x.personPositionCertificationId === official.personPositionCertificationId));
      arrayDataCopy[selectedIndex].selectedExpirationDate = value;
      setOtsOfficialNationalCertificationState({ ...otsOfficialNationalCertificationState, arrayData: arrayDataCopy });
    }
  };

  const onSaveClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const personId = otsOfficialHeaderState?.objData?.personId;
    const otsOfficialCertArray = otsOfficialNationalCertificationState.arrayData?.map((x) => {
      return ({
        personPositionCertificationId: x.personPositionCertificationId,
        effectiveDate: x.selectedEffectiveDate ? x.selectedEffectiveDate : formatDate(x.effectiveDate),
        expirationDate: x.selectedExpirationDate ? x.selectedExpirationDate : formatDate(x.expirationDate)
      })
    })

    let error = false;
    for (let i = 0; i < otsOfficialCertArray.length; i++) {
      if (!isValidDate(otsOfficialCertArray[i].effectiveDate)) {
        error = true
      }
      if (!isValidDate(otsOfficialCertArray[i].expirationDate)) {
        error = true
      }
      let effectiveDate = new Date(otsOfficialCertArray[i].effectiveDate);
      let expirationDate = new Date(otsOfficialCertArray[i].expirationDate);
      if (effectiveDate > expirationDate) {
        error = true;
      }
    }

    if (error === false) {
      putOtsOfficialNationalCertificationByPersonId(personId, otsOfficialCertArray);
      setState({ ...state, showError: false, tryGet: true });
    }
    else {
      setState({ ...state, showError: true });
    }
  };

  useEffect(() => {
    if (otsOfficialNationalCertificationState.isArrayLoading === false && (otsOfficialNationalCertificationState.isArrayLoaded === false || state.tryGet === true)) {
      const personId = otsOfficialHeaderState?.objData?.personId
      getOtsOfficialNationalCertificationByPersonId(personId)
      if (state.tryGet === true) {
        setState({ ...state, tryGet: false });
      }
    }
    else if (otsOfficialNationalCertificationState.isArrayLoading === false && otsOfficialNationalCertificationState.isArrayLoaded === true) {
      let formattedArrayData = otsOfficialNationalCertificationState.arrayData?.map((x) => {
        return (
          {
            ...x,
            selectedExpirationDate: x.selectedExpirationDate ? formatDate(x.selectedExpirationDate) : x.expirationDate ? formatDate(x.expirationDate) : Constants.BLANK_DATE_STRING,
            selectedEffectiveDate: x.selectedEffectiveDate ? formatDate(x.selectedEffectiveDate) : x.effectiveDate ? formatDate(x.effectiveDate) : Constants.BLANK_DATE_STRING,
            uniqueEffectiveDatePickerId: "effectiveDate" + x?.personPositionCertificationId + "personPositionCertificationId",
            uniqueExpirationDatePickerId: "expirationDate" + x?.personPositionCertificationId + "personPositionCertificationId"
          }
        );
      })
      setGridState({ ...gridState, arrayData: formattedArrayData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsOfficialNationalCertificationState.isArrayLoading, otsOfficialNationalCertificationState.isArrayLoaded, otsOfficialNationalCertificationState.isSaving, state.tryGet])

  return {
    ...state,
    gridState,
    otsOfficialHeaderState,
    otsOfficialNationalCertificationState,
    basePersonState,
    userInfo,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    resetForm,
    onUpdateEffectiveDateValueChange,
    onUpdateExpirationDateValueChange,
    onSaveClicked
  };
};

export default useOtsOfficialNationalCertificationHistory;