import React, { Fragment } from 'react';

import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import global from '../../../../../common/components/GlobalStyle.module.css';

const MemberClubTransferMemberStatus = ({ transferState, onBackClicked }) => (
  <Fragment>
    <div className={[global.HeaderText, 'row usas-extra-bottom-margin'].join(' ')}>
      <div className="col-xs-6 col-md-3">
        <p><b>Club Transfer Status:</b></p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p>Pending</p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p><b>Member Role:</b></p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p>{transferState.orgRoleName || <span>&nbsp;</span>}</p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p><b>Current Club:</b></p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p>{transferState.currentOrgUnitName || <span>&nbsp;</span>}</p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p><b>New Club:</b></p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p>{transferState.personClubTransfer?.transferOrgUnitName || <span>&nbsp;</span>}</p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p><b>Transfer Requested By:</b></p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p>{transferState.personClubTransfer?.transferSignature || <span>&nbsp;</span>}</p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p><b>Transfer Request Date:</b></p>
      </div>
      <div className="col-xs-6 col-md-3">
        <p>{transferState.personClubTransfer?.transferSignatureDate ? formatDate(transferState.personClubTransfer.transferSignatureDate) : <span>&nbsp;</span>}</p>
      </div>
    </div>
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
      </div>
    </div>
  </Fragment>
);

export default MemberClubTransferMemberStatus;