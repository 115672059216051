import { useContext } from 'react';

import { MeetEvaluationStateContext } from './MeetEvaluationsContextProvider';

import MeetEvaluationData from './MeetEvaluationData';

const useMeetEvaluationData = () => {
  const [meetEvaluationState, setMeetEvaluationState] = useContext(MeetEvaluationStateContext);

  const getMeetEvaluationsByOtsMeetId = (otsMeetId) => MeetEvaluationData.getMeetEvaluationsByOtsMeetIdData(otsMeetId, meetEvaluationState, setMeetEvaluationState);
  const postMeetEvaluation = (otsMeetId, meetEvaluationObj) => MeetEvaluationData.postMeetEvaluationData(otsMeetId, meetEvaluationObj, meetEvaluationState, setMeetEvaluationState);
  const putMeetEvaluation = (meetEvaluationId, meetEvaluationObj) => MeetEvaluationData.putMeetEvaluationData(meetEvaluationId, meetEvaluationObj, meetEvaluationState, setMeetEvaluationState);
  const deleteMeetEvaluation = (meetEvaluationId) => MeetEvaluationData.deleteMeetEvaluationData(meetEvaluationId, meetEvaluationState, setMeetEvaluationState);

  const clearMeetEvaluationObjData = () => {
    setMeetEvaluationState({
      ...meetEvaluationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearMeetEvaluationArrayData = () => {
    setMeetEvaluationState({
      ...meetEvaluationState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmMeetEvaluationSave = () => {
    setMeetEvaluationState({
      ...meetEvaluationState,
      isSaved: false
    });
  };

  return {
    meetEvaluationState,
    getMeetEvaluationsByOtsMeetId,
    postMeetEvaluation,
    putMeetEvaluation,
    deleteMeetEvaluation,
    clearMeetEvaluationObjData,
    clearMeetEvaluationArrayData,
    confirmMeetEvaluationSave
  };
};

export default useMeetEvaluationData;