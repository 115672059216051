import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  message: '',
};

const getPersonAudit = (memberId, state, setState) => {
  if (state.isLoading !== true && memberId !== '' && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const memberIdForUrl = memberId ? encodeURIComponent(memberId) : 'NaN';
    const url = `/Person/audit/${memberIdForUrl}`;
    PersonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const PersonAuditData = { INITIAL_STATE, getPersonAudit };

export default PersonAuditData;