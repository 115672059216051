import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

export const INITIAL_STATE = {
  organizationId: Constants.DEFAULT_ID,
  isLoading: false,
  isLoaded: false,
  data: [],
  dataAsOptionsWithNames: [],
  message: ''
};

const getReportPeriodsFlatByOrganizationId = (organizationId, state, setState) => {
  if (state.isLoading !== true && organizationId > 0 && !state.message) {
    let newState = {
      ...state,
      organizationId,
      isLoading: true,
      isLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const organizationIdForUrl = organizationId ? encodeURIComponent(organizationId) : 'NaN';
    const url = `/ReportPeriod/Organization/Flat/${organizationIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((o) => { return { id: o.reportPeriodId, name: o.periodName } }),
            message: '',
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const ReportPeriodFlatData = { INITIAL_STATE, getReportPeriodsFlatByOrganizationId };

export default ReportPeriodFlatData;