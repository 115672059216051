import { Fragment } from 'react';

import useOtsMeetSessions from './UseOtsMeetSessions';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Sessions from '../../../../../common/components/meetSessions/Sessions';

const OtsMeetSessions = () => {
  const {
    otsMeetHeaderState,
    otsMeetSessionsState,
    isLoading,
    isSaving,
    Constants,
    navRoutes,
    onAddSession,
    onEditSession,
    onDeleteSession
  } = useOtsMeetSessions();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Sessions</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <Sessions
            showOptionalFields={true}
            showOtsField={true}
            requiredFieldsReadOnly={false}
            meetSessionArray={otsMeetSessionsState?.arrayData}
            meetId={otsMeetHeaderState?.objData?.meetId}
            onAdd={onAddSession}
            onEdit={onEditSession}
            onDelete={onDeleteSession}
            deleteButtonFirst={true}
            saveNavLink={navRoutes.OTS_MEET_SESSIONS?.route}
            backNavLink={navRoutes.OTS_MEET_SESSIONS?.route}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  )
}

export default OtsMeetSessions;