import { Fragment } from 'react';

import OtsOfficialNationalCertificationApplicationHistoryLargeGrid from './OtsOfficialNationalCertificationApplicationHistoryLargeGrid';
import OtsOfficialNationalCertificationApplicationHistorySmallGrid from './OtsOfficialNationalCertificationApplicationHistorySmallGrid';

const OtsOfficialsNationalCertificationApplicationHistoryGrid = ({ state, onStatusClicked }) => {
  return (
    <Fragment>
      <OtsOfficialNationalCertificationApplicationHistoryLargeGrid
        state={state}
        onStatusClicked={onStatusClicked}
      />
      <OtsOfficialNationalCertificationApplicationHistorySmallGrid
        state={state}
        onStatusClicked={onStatusClicked}
      />
    </Fragment>
  )
};

export default OtsOfficialsNationalCertificationApplicationHistoryGrid;
