
export const localValidate = (formState) => {
  let errors = {};

  if (formState.meetSessionIds?.length === 0) {
    errors.meetSessionIds = 'Selecting at least one Session is required';
  }

  if (formState.meetOfficialRoleTypeId < 0) {
    errors.meetOfficialRoleTypeId = 'Role Worked is required';
  }

  if (formState.isApprentice !== true && formState.isApprentice !== false ) {
    errors.isApprentice = 'A selection is required';
  }

  return errors;
};

const OtsMeetOfficialsOfficialDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OtsMeetOfficialsOfficialDetailValidation;