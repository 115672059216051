import React, { Fragment } from 'react';

import DeleteIcon from '../../../../../../common/components/icons/DeleteIcon';
import Checkbox from '../../../../../../common/components/checkboxes/Checkbox';
import { formatDate, getTodaysDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ officialLscCert, formState, onEditLscCert, onOpenDeleteLscCertificationModal, foundIndex, onAddOrRemoveLscCertficationForRecertify }) => {
  const todayDate = getTodaysDate();
  const today = new Date(todayDate);
  const expirationDateLocal = new Date(officialLscCert?.expirationDate);
  if (formState?.showAllLscCertificationHistory === false && (officialLscCert?.isActive === true || today <= expirationDateLocal)) {
    return (
      <Fragment>
        <tr>
          <td>{officialLscCert?.positionTypeName} - {officialLscCert?.positionAbbreviation}</td>
          <td>{officialLscCert?.sessionsWorked}</td>
          <td>{officialLscCert?.levelName}</td>
          <td>{formatDate(officialLscCert?.effectiveDate)}</td>
          <td>{formatDate(officialLscCert?.expirationDate)}</td>
          <td>
            {officialLscCert?.isRecertifyAvailable === true &&
              <Checkbox
                name="personPositionCertificationId"
                checked={foundIndex >= 0 ? true : false}
                onChange={(checked) => { onAddOrRemoveLscCertficationForRecertify(checked, officialLscCert, officialLscCert?.personPositionCertificationId) }} />}
          </td>
          <td>
            <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditLscCert(e, officialLscCert)}>
              Expire
            </button>&nbsp;
            {officialLscCert?.canBeDeleted === true
              ? <button className={global.IconButtonMargin} type="button" onClick={(e) => onOpenDeleteLscCertificationModal(e, officialLscCert)}><DeleteIcon /></button>
              : <Fragment />
            }
          </td>
        </tr>
      </Fragment>
    )
  } else if (formState?.showAllLscCertificationHistory === true) {
    return (
      <Fragment>
        <tr>
          <td>{officialLscCert?.positionTypeName} - {officialLscCert?.positionAbbreviation}</td>
          <td>{officialLscCert?.sessionsWorked}</td>
          <td>{officialLscCert?.levelName}</td>
          <td>{formatDate(officialLscCert?.effectiveDate)}</td>
          <td>{formatDate(officialLscCert?.expirationDate)}</td>
          <td>
            {officialLscCert?.isRecertifyAvailable === true &&
              <Checkbox
                name="personPositionCertificationId"
                checked={foundIndex >= 0 ? true : false}
                onChange={(checked) => { onAddOrRemoveLscCertficationForRecertify(checked, officialLscCert, officialLscCert?.personPositionCertificationId) }} />}</td>
          <td>
            <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditLscCert(e, officialLscCert)}>Expire</button>&nbsp;
            {officialLscCert?.canBeDeleted === true
              ? <button className={global.IconButtonMargin} type="button" onClick={(e) => onOpenDeleteLscCertificationModal(e, officialLscCert)}><DeleteIcon /></button>
              : <Fragment />
            }
          </td>
        </tr>
      </Fragment>
    )
  } else {
    return (
      <Fragment />
    )
  }
}

const OtsOfficialLscCertificationLargeWriteGrid = ({ state, formState, onEditLscCert, onOpenDeleteLscCertificationModal, selectedLscCertifications, onAddOrRemoveLscCertficationForRecertify }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Position</th>
        <th>Sessions Worked</th>
        <th>Certification Level</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th>Re-Certify?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state?.arrayData?.certifications?.length > 0 ?
        state?.arrayData?.certifications?.map((officialLscCert, i) => {
          const foundIndex = selectedLscCertifications.findIndex(x => x.personPositionCertificationId === officialLscCert.personPositionCertificationId);
          return (
            <LargeGridRow
              key={i}
              officialLscCert={officialLscCert}
              formState={formState}
              onEditLscCert={onEditLscCert}
              onOpenDeleteLscCertificationModal={onOpenDeleteLscCertificationModal}
              selectedLscCertifications={selectedLscCertifications}
              onAddOrRemoveLscCertficationForRecertify={onAddOrRemoveLscCertficationForRecertify}
              foundIndex={foundIndex}
            />)
        })
        : state?.isArrayLoading
          ? <tr><td colSpan="7">Loading...</td></tr>
          : <tr><td colSpan="7">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default OtsOfficialLscCertificationLargeWriteGrid