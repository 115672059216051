import React, { Fragment } from 'react';

import useMemberSearch from './UseMemberSearch';

import SearchMember from '../../../../common/components/searches/searchMember/SearchMember';

const MemberSearch = () => {
  const { searchMemberState, onEditPerson } = useMemberSearch();

  return (
    <Fragment>
      <SearchMember
        pageTitle={"Member Search"}
        onEdit={onEditPerson}
        searchMemberState={searchMemberState}
      />
    </Fragment>
  );
};

export default MemberSearch;