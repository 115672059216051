import { Fragment } from 'react';

import useMemberMergeSearch from './UseMemberMergeSearch';

import SearchMember from '../../../../common/components/searches/searchMember/SearchMember';

const MemberMergeSearch = () => {
  const {
    personAId,
    searchMemberState,
    onSelectPersonB
  } = useMemberMergeSearch();

  return (
    <Fragment>
      <SearchMember
        pageTitle={"Member Merge - Member B Search"}
        searchMemberState={searchMemberState}
        selectedPersonId={personAId}
        onEdit={onSelectPersonB} />
    </Fragment>
  );
};

export default MemberMergeSearch;