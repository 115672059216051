import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';

const GetPersonMembershipUpgradeData = (personId, personOrgRoleDurationId, state, setState) => {
  if (state.isObjLoading !== true && (personId || personOrgRoleDurationId) && !state.message) {
    let newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'NaN';
    const personOrgRoleDurationIdForUrl = personOrgRoleDurationId ? encodeURIComponent(personOrgRoleDurationId) : 'NaN';
    const url = `/Person/${personIdForUrl}/personOrgRoleDuration/${personOrgRoleDurationIdForUrl}/MembershipUpgrade`
    PersonHttpHelper(url, 'GET')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData,
            message: ''
          };

          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          message: message
        });
      });
  }
};

export default GetPersonMembershipUpgradeData;