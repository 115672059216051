import { DEFAULT_ID } from "../../../common/utils/Constants";
import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getAvailableOfferingsData = (reportPeriodId, state, setState) => {
  if (reportPeriodId > 0) {
    const newState = {
      ...state,
      reportPeriodId
    };

    const api = initApi(API_NAMES.OMR, newState, setState);
    const reportPeriodIdForUrl = reportPeriodId ? encodeURIComponent(reportPeriodId) : 'NaN';
    const url = `/Offering/ReportPeriod/${reportPeriodIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_OFFERINGS_STATE = {
  ...BASIC_INITIAL_STATE,
  reportPeriodId: DEFAULT_ID
};

const OfferingsData = {
  INITIAL_OFFERINGS_STATE,

  getAvailableOfferingsData
};

export default OfferingsData;