import React, { Fragment } from 'react';

import useMemberDisabilities from './UseMemberDisabilities';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberDisabilitiesRead = () => {
  const {
    state,
    disabilityTypeState,
    basePersonState,
    personGeneralState
  } = useMemberDisabilities();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Disabilities</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H6>
            {(state.isAthlete === true) ? "Athlete Abilities" : "Non-Athlete Abilities"}
          </Headings.H6>
        </div>
      </div>
      <div className={[global.HeaderText, 'row usas-extra-top-margin usas-extra-bottom-margin'].join(' ')}>
        <div className="col-xs-6 col-md-3">
          <p><b>Disability Category:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{state.disabilityCategory || <span>&nbsp;</span>}</p>
        </div>
        {(state.displayDisabilityTypeNames === false)
          ? <Fragment />
          : (
            <Fragment>
              <div className="col-xs-6 col-md-3">
                <p><b>Disability Types:</b></p>
              </div>
              <div className="col-xs-6 col-md-3">
                <p>{state.disabilityTypeNames || <span>&nbsp;</span>}</p>
              </div>
            </Fragment>
          )
        }
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || disabilityTypeState.isArrayLoading || personGeneralState.isObjLoading} />
    </Fragment>
  );
};

export default MemberDisabilitiesRead;

