import { useEffect, useRef } from 'react';
import useMemberPersistentStorage from '../../../common/state/memberPersistentStorage/UseMemberPersistentStorage';
import useBasePersonData from '../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../common/state/security/UseSecurityData';
import { useLocation } from '../../../common/wrappers/ReactRouterDom';

const TAXONOMIES = ['MemberDetail'];
const SCOPE = 'Member';

const useMemberContextRoutes = () => {
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { getPersistentStorage, resetExpiration } = useMemberPersistentStorage();
  const { basePersonState, getBasePerson } = useBasePersonData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const loadingStatus = useRef('not started');

  useEffect(() => {
    resetExpiration();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const persistentStorage = getPersistentStorage();
    if (loadingStatus.current === 'not started') {
      getBasePerson(persistentStorage?.personId || '0');
      loadingStatus.current = 'getting base person';
    } else if (loadingStatus.current === 'getting base person' && basePersonState.isObjLoaded === true) {
      const ctxStatus = getContextSecurity(basePersonState.objData?.personId || '0', TAXONOMIES, SCOPE);
      loadingStatus.current = ctxStatus === 'sent' ? 'getting context security' : 'loaded';
    } else if (loadingStatus.current === 'getting context security' && contextSecurityState.isObjLoaded === true) {
      loadingStatus.current = 'loaded';
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, contextSecurityState]);

  return {
    isLoading: loadingStatus.current !== 'loaded',
    navRoutes
  };
};

export default useMemberContextRoutes;