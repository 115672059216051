import { useEffect } from 'react';

import usePersonCoursesForAdminRoleData from '../../../state/member/memberAdminCourses/UsePersonCoursesForAdminRoleData';

const useMemberCoursesForAdminRoleDropdown = (roleName) => {
  const { memberCoursesAdminRoleState, getCoursesForAdminRole, } = usePersonCoursesForAdminRoleData();

  useEffect(() => {
    if (memberCoursesAdminRoleState.isArrayLoading !== true && memberCoursesAdminRoleState.isArrayLoaded !== true && roleName !== '') {
      getCoursesForAdminRole(roleName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCoursesAdminRoleState, roleName]);

  return {
    memberCoursesAdminRoleState
  };
};

export default useMemberCoursesForAdminRoleDropdown;