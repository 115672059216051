import React, { Fragment } from 'react';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const SmallGridRowIcons = ({ funFact, onAddEdit, onDelete }) => (
  <Fragment>
    <button className={global.IconButton} type="button" onClick={(e) =>
      onDelete(e, funFact.personFunFactsId, `${funFact.funFacts?.funFactsName}: ${funFact.textValue.length > 80 ? funFact.textValue.substring(0, 80) + '...' : funFact.textValue} (Last Modified Date: ${formatDate(funFact.modifiedDatetime)})`)}>
      <DeleteIcon />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onAddEdit(e, funFact.personFunFactsId)}>
      <EditIcon />
    </button>
  </Fragment>
);

const SmallGridRow = ({ funFact, onAddEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{funFact.funFacts?.funFactsName || ''}</span>&nbsp;
      <SmallGridRowIcons funFact={funFact} onAddEdit={onAddEdit} onDelete={onDelete} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Fun Fact Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {funFact.funFacts?.funFactsName || ''}
        </div>
        <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
          Last Modified Date
        </div>
        <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
          {funFact.modifiedDatetime ? formatDate(funFact.modifiedDatetime) : ''}
        </div>
      </div>
    </div>
  </div>
);

const ProfileFunFactsSmallGrid = ({ gridState, onAddEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {gridState.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {gridState.isObjLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Fun Facts</div>
            }
          </div>
        </div>
      ) : Array.isArray(gridState.objData.funFacts) && gridState.objData.funFacts.length > 0
        ? gridState.objData.funFacts.map((funFact, i) => <SmallGridRow key={i} funFact={funFact} onAddEdit={onAddEdit} onDelete={onDelete} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Fun Facts</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ProfileFunFactsSmallGrid;