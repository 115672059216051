import React, { createContext, useState } from 'react';

import OfficialsTrackingSystemOfficialSelectionData from './OfficialsTrackingSystemOfficialSelectionData';

export const OfficialsTrackingSystemOfficialSelectionStateContext = createContext();

const OfficialsTrackingSystemOfficialSelectionContextProvider = ({ children }) => {
  const stateHook = useState(OfficialsTrackingSystemOfficialSelectionData.INITIAL_STATE);

  return (
    <OfficialsTrackingSystemOfficialSelectionStateContext.Provider value={stateHook}>
      {children}
    </OfficialsTrackingSystemOfficialSelectionStateContext.Provider>
  );
};

export default OfficialsTrackingSystemOfficialSelectionContextProvider;