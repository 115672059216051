import React, { createContext, useState } from 'react';

import OtsMeetData from './OtsMeetData';

export const OtsMeetStateContext = createContext();

const OtsMeetContextProvider = ({ children }) => {
  const stateHook = useState(OtsMeetData.INITIAL_STATE);

  return (
    <OtsMeetStateContext.Provider value={stateHook}>
      {children}
    </OtsMeetStateContext.Provider>
  );
};

export default OtsMeetContextProvider;