import React, { createContext, useState } from 'react';

import TestingBodyData from './TestingBodyData';

export const TestingBodyStateContext = createContext();

const TestingBodyContextProvider = ({ children }) => {
  const stateHook = useState(TestingBodyData.INITIAL_STATE);

  return (
    <TestingBodyStateContext.Provider value={stateHook}>
      {children}
    </TestingBodyStateContext.Provider>
  );
};

export default TestingBodyContextProvider;