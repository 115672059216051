import { useContext } from 'react';

import { OfficialPositionCertificationTypeProgressionStateContext } from './OfficialPositionCertificationTypeProgressionContextProvider';

import OfficialPositionCertificationTypeProgressionData from './OfficialPositionCertificationTypeProgressionData';

const useOfficialPositionCertificationTypeProgressionData = () => {
  const [officialPositionCertificationTypeProgressionState, setOfficialPositionCertificationTypeProgressionState] = useContext(OfficialPositionCertificationTypeProgressionStateContext);

  const getOfficialPositionCertificationTypeProgressions = (personId, personPositionCertificationRequestId) => OfficialPositionCertificationTypeProgressionData.getOfficialPositionCertificationTypeProgressionData(personId, personPositionCertificationRequestId, officialPositionCertificationTypeProgressionState, setOfficialPositionCertificationTypeProgressionState);

  const clearOfficialPositionCertificationTypeProgressionObjData = () => {
    setOfficialPositionCertificationTypeProgressionState({
      ...officialPositionCertificationTypeProgressionState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOfficialPositionCertificationTypeProgressionArrayData = () => {
    setOfficialPositionCertificationTypeProgressionState({
      ...officialPositionCertificationTypeProgressionState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: '',
      options: [],
      orgLevelId: '',
      hasEvaluationForm: ''
    });
  };

  const confirmOfficialPositionCertificationTypeProgressionSave = () => {
    setOfficialPositionCertificationTypeProgressionState({
      ...officialPositionCertificationTypeProgressionState,
      isSaved: false
    });
  };

  return {
    officialPositionCertificationTypeProgressionState,
    getOfficialPositionCertificationTypeProgressions,
    clearOfficialPositionCertificationTypeProgressionObjData,
    clearOfficialPositionCertificationTypeProgressionArrayData,
    confirmOfficialPositionCertificationTypeProgressionSave
  };
};

export default useOfficialPositionCertificationTypeProgressionData;