import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ evaluation, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked }) => (
  <tr>
    {evaluation.hasEvaluation === false || !onReadOnlyEvaluationClicked ? <td> {evaluation.officialFirstName || ''} {evaluation.officialLastName || ''}</td> :
      <td><a onClick={(e) => onReadOnlyEvaluationClicked(e, evaluation)}>{evaluation.officialFirstName || ''} {evaluation.officialLastName || ''}</a></td>}
    <td>{evaluation.positionTypeName || ''}</td>
    <td>{evaluation.evaluationLevelName || ''}</td>
    <td>{evaluation.evaluatorFirstName || ''} {evaluation.evaluatorLastName || ''}</td>
    <td>{evaluation.hasEvaluation === true ? 'Yes' : 'No'}</td>
    <td>
      {(evaluation.hasEvaluation === false || !onReadOnlyEvaluationClicked) &&
        <Fragment>
          {onGoToEvaluationFormClicked &&
            <button className={global.IconButtonMargin} type="button" onClick={(e) => onGoToEvaluationFormClicked(e, evaluation)}>
              <EditIcon />
            </button>}
          {onDeleteEvaluationClicked &&
            <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteEvaluationClicked(e, evaluation)}>
              <DeleteIcon />
            </button>}
        </Fragment>}
    </td>
  </tr>
);

const LargeGrid = ({ state, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Official Name</th>
        <th>Position Worked</th>
        <th>Evaluation Level</th>
        <th>Evaluator Name</th>
        <th>Completed?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true
        ? (
          <tr><td colSpan="6">{state.isArrayLoading === true ? 'Loading...' : 'No Meet Evaluations'}</td></tr>
        ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
          ? state.arrayData.map((evaluation, i) => <LargeGridRow key={i} evaluation={evaluation} onGoToEvaluationFormClicked={onGoToEvaluationFormClicked} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} onDeleteEvaluationClicked={onDeleteEvaluationClicked} />
          ) : (
            <tr>
              <td colSpan="6">No Meet Evaluations</td>
            </tr>
          )
      }
    </tbody>
  </table >
);

const SmallGridRowIcons = ({ evaluation, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked }) => (
  <Fragment>
    {evaluation.hasEvaluation === false || !onReadOnlyEvaluationClicked ?
      <Fragment>
        {onDeleteEvaluationClicked &&
          <button className={global.IconButton} type="button" onClick={(e) => onDeleteEvaluationClicked(e, evaluation)}>
            <DeleteIcon />
          </button>}
        {onGoToEvaluationFormClicked &&
          <button className={onDeleteEvaluationClicked ? global.IconButtonMobileMargin : global.IconButton} type="button" onClick={(e) => onGoToEvaluationFormClicked(e, evaluation)}>
            <EditIcon />
          </button>}
      </Fragment>
      :
      <Fragment>
        {onReadOnlyEvaluationClicked &&
          <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onReadOnlyEvaluationClicked(e, evaluation)}>View</ActionIntraPageButton></div>
        }
      </Fragment>}
  </Fragment >
);

const SmallGridRow = ({ evaluation, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      {evaluation.hasEvaluation === true ? <span /> : <span className='hidden-xs'>{evaluation.officialFirstName || ''} {evaluation.officialLastName || ''}</span>}
      &nbsp;
      <SmallGridRowIcons evaluation={evaluation} onGoToEvaluationFormClicked={onGoToEvaluationFormClicked} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} onDeleteEvaluationClicked={onDeleteEvaluationClicked} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        {evaluation.hasEvaluation === true ?
          <Fragment>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Official Name
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.officialFirstName || <span>&nbsp;</span>}  {evaluation.officialLastName || <span>&nbsp;</span>}
            </div>
          </Fragment> :
          <Fragment>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
              Official Name
            </div>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
              {evaluation.officialFirstName || <span>&nbsp;</span>}  {evaluation.officialLastName || <span>&nbsp;</span>}
            </div>
          </Fragment>}
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Position Worked
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {evaluation.positionTypeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Evaluation Level
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {evaluation.evaluationLevelName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Evaluator Name
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {evaluation.evaluatorFirstName || <span>&nbsp;</span>}  {evaluation.evaluatorLastName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Completed?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {evaluation.hasEvaluation === true ? 'Yes' : 'No'}
        </div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ state, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div> :
              <div className={global.SmallTableRowLabels}>No Meet Evaluations</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((evaluation, i) => <SmallGridRow key={i} evaluation={evaluation} onGoToEvaluationFormClicked={onGoToEvaluationFormClicked} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} onDeleteEvaluationClicked={onDeleteEvaluationClicked} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Meet Evaluations</div>
            </div>
          </div>
        )
    }
  </div >
);

const OtsMeetEvaluationsGridWrite = ({ state, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked }) => (
  <Fragment>
    <LargeGrid state={state} onGoToEvaluationFormClicked={onGoToEvaluationFormClicked} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} onDeleteEvaluationClicked={onDeleteEvaluationClicked} />
    <SmallGrid state={state} onGoToEvaluationFormClicked={onGoToEvaluationFormClicked} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} onDeleteEvaluationClicked={onDeleteEvaluationClicked} />
  </Fragment>
);

export default OtsMeetEvaluationsGridWrite;