import { Fragment } from 'react';

import MemberRequirementsLargeAdvancedWriteGrid from './MemberRequirementsLargeAdvancedWriteGrid';
import MemberRequirementsSmallAdvancedWriteGrid from './MemberRequirementsSmallAdvancedWriteGrid';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MemberRequirementsAdvancedGrid = ({ gridData, isLoading, onEditRequirement, formState, onOpenDeleteEnrollmentModal }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MemberRequirementsLargeAdvancedWriteGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditRequirement={onEditRequirement}
        formState={formState}
        onOpenDeleteEnrollmentModal={onOpenDeleteEnrollmentModal}
        expandedId={expandedId}
        onClick={onClick} />
      <MemberRequirementsSmallAdvancedWriteGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditRequirement={onEditRequirement}
        formState={formState}
        onOpenDeleteEnrollmentModal={onOpenDeleteEnrollmentModal}
        expandedId={expandedId}
        onClick={onClick} />
    </Fragment>
  );
};

export default MemberRequirementsAdvancedGrid;