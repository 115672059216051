import { useEffect } from 'react';

import usePersonNationalTeamAuditData from '../../../state/member/memberNationalTeamAudit/UsePersonNationalTeamAuditData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import Constants from '../../../../common/utils/Constants';

const TITLE = 'National Team Audit';

const useNationalTeamAudit = () => {
  const { basePersonState } = useBasePersonData();
  const { personNationalTeamAuditState, getPersonNationalTeamAudit } = usePersonNationalTeamAuditData();

  useEffect(() => {
    if (basePersonState.isObjLoaded === true) {
      getPersonNationalTeamAudit(basePersonState.objData.memberId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  return { title: TITLE, Constants, personNationalTeamAuditState, basePersonState };
};

export default useNationalTeamAudit;