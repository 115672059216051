import React, { Fragment } from 'react';

import useLscMemberSearch from './UseLscMemberSearch';

import LscSearchMember from '../../../../common/components/searches/searchMember/LscSearchMember';

const LscMemberSearch = () => {
  const { searchMemberState, onEditPerson } = useLscMemberSearch();

  return (
    <Fragment>
      <LscSearchMember
        pageTitle={"Member Search"}
        onEdit={onEditPerson}
        searchMemberState={searchMemberState}
      />
    </Fragment>
  );
};

export default LscMemberSearch;