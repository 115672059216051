import { useContext } from 'react';

import { OtsMeetStateContext } from './OtsMeetContextProvider';
import { OtsMeetFiltersStateContext } from './OtsMeetFiltersContextProvider';
import { OtsMeetHeaderStateContext } from './OtsMeetHeaderContextProvider';

import OtsMeetData from './OtsMeetData';

const useOtsMeetData = () => {
  const [otsMeetState, setOtsMeetState] = useContext(OtsMeetStateContext);
  const [otsMeetFiltersState, setOtsMeetFiltersState] = useContext(OtsMeetFiltersStateContext);
  const [otsMeetHeaderState, setOtsMeetHeaderState] = useContext(OtsMeetHeaderStateContext);

  const searchOtsMeets = (filterObject, sortBy) => {
    setOtsMeetFiltersState({ ...otsMeetFiltersState, filterObject, sortBy });
    OtsMeetData.searchOtsMeets(JSON.stringify(filterObject), sortBy, otsMeetState, setOtsMeetState);
  };

  const getOtsMeet = (otsMeetId) => OtsMeetData.getOtsMeetData(otsMeetId, otsMeetState, setOtsMeetState);
  const postOtsMeet = (otsMeetObj) => OtsMeetData.postOtsMeetData(otsMeetObj, otsMeetState, setOtsMeetState);
  const putOtsMeet = (otsMeetId, otsMeetObj) => OtsMeetData.putOtsMeetData(otsMeetId, otsMeetObj, otsMeetState, setOtsMeetState);

  const getOtsMeetMeetById = (meetId) => OtsMeetData.getOtsMeetMeetById(meetId, otsMeetHeaderState, setOtsMeetHeaderState);

  const clearOtsMeetObjData = () => {
    setOtsMeetState({
      ...otsMeetState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOtsMeetArrayData = () => {
    setOtsMeetState({
      ...otsMeetState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmOtsMeetSave = () => {
    setOtsMeetState({
      ...otsMeetState,
      isSaved: false
    });
  };

  const clearOtsMeetHeaderObjData = () => {
    setOtsMeetHeaderState({
      ...otsMeetHeaderState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: '',
      myOtsMeets: false,
      myLscOtsMeetSearch: false
    });
  };

  return {
    otsMeetState,
    otsMeetFiltersState,
    setOtsMeetFiltersState,
    otsMeetHeaderState,
    setOtsMeetHeaderState,
    searchOtsMeets,
    getOtsMeet,
    postOtsMeet,
    putOtsMeet,
    getOtsMeetMeetById,
    clearOtsMeetObjData,
    clearOtsMeetArrayData,
    confirmOtsMeetSave,
    clearOtsMeetHeaderObjData
  };
};

export default useOtsMeetData;