import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OtsOfficialLscCertificationRecertifyValidation';

import useOfficialPositionData from '../../../../state/officialPosition/UseOfficialPositionData';
import useOtsOfficialData from '../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate, getTodaysDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  expirationDate: Constants?.BLANK_DATE_STRING,
};

const INITIAL_STATE = {
  selectedLscCertifications: [],
  errorMessage: ''
};

const useOtsOfficialLscCertificationRecertify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const [state, setState] = useState(INITIAL_STATE);
  const { officialPositionState } = useOfficialPositionData();
  const { otsOfficialLscState, otsOfficialHeaderState, putOtsOfficialLscCertificationRecertify, otsLscRecertifyState, getOtsOfficialLscCertificationsByPersonId } = useOtsOfficialData();
  const {
    formState,
    errorState,
    onValueTextPairChanged,
    resetForm,
    onFormValueChanged,
    setFormData,
    handleSubmit
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  function submitFormCallback() {
    if (otsLscRecertifyState.isSaving === false) {
      const personId = otsOfficialHeaderState?.objData?.personId;

      putOtsOfficialLscCertificationRecertify(personId, createPutLscRecertifyCertificationObject(formState));
    }
  };

  function createPutLscRecertifyCertificationObject(formState) {
    const putObj = {
      recertifyExpirationDate: formState?.expirationDate,
      certifications: state?.selectedLscCertifications
    }
    return putObj;
  };

  const onBackToLscCert = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_OFFICIAL_LSCCERTIFICATIONS?.route);
  };

  const calculateCountOfYears = () => {
    let countOfYears = 0;
    const today = new Date();
    const year = today.getFullYear();
    countOfYears = year - 2000 + 9;
    return countOfYears;
  };

  const calculateStartYearOffset = () => {
    let startYearOffset = 0;
    const today = new Date();
    const year = today.getFullYear();
    startYearOffset = 2000 - year;
    return startYearOffset;
  };

  useEffect(() => {
    if (otsLscRecertifyState?.isSaved && Object.keys(otsLscRecertifyState?.objData)?.length > 0) {
      if (otsOfficialLscState) {
        getOtsOfficialLscCertificationsByPersonId(otsOfficialHeaderState?.objData.personId)
        setTimeout(() => {
          onBackToLscCert();
        }, 750);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsLscRecertifyState?.isSaved, otsOfficialLscState]);

  useEffect(() => {
    if (Object?.keys(state?.selectedLscCertifications)?.length > 0 && formState?.expirationDate === Constants?.BLANK_DATE_STRING) {
      const today = getTodaysDate();
      const selectedLscCertificationsSortedByEffectiveDate = state?.selectedLscCertifications.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.effectiveDate) - new Date(a.effectiveDate);
      });
      setFormData({
        ...formState,
        expirationDate: formatDate(today),
        effectiveDate: formatDate(selectedLscCertificationsSortedByEffectiveDate[0].effectiveDate),
      }, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectedLscCertifications]);

  const mapIt = (array) => {
    const newArray = array.map(lscCert => {
      return {
        canBeDeleted: lscCert?.canBeDeleted,
        levelName: lscCert?.levelName,
        effectiveDate: lscCert?.effectiveDate,
        expirationDate: lscCert?.expirationDate,
        isActive: lscCert?.isActive,
        officialPositionId: lscCert?.officialPositionId,
        officialPositionTypeId: lscCert?.officialPositionTypeId,
        personPositionCertificationId: lscCert?.personPositionCertificationId,
        positionCertificationTypeId: lscCert?.positionCertificationTypeId,
        positionAbbreviation: lscCert?.positionAbbreviation,
        positionTypeName: lscCert?.positionTypeName,
        certificationLevelId: lscCert?.certificationLevelId,
        orgLevelId: lscCert?.orgLevelId,
        status: lscCert?.status,
        isRecertifyAvailable: lscCert?.isRecertifyAvailable,
        reCertify: true,
        sessionsWorked: lscCert?.sessionsWorked,
      }
    })

    return newArray;
  };

  useEffect(() => {
    if (location?.state) {
      setState({
        ...state,
        selectedLscCertifications: mapIt(location?.state?.recertifyStateArray) || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onSubmit: handleSubmit,
    formState,
    errorState,
    onValueTextPairChanged,
    resetForm,
    isLoading: officialPositionState.isArrayLoading,
    state,
    onBackToLscCert,
    onFormValueChanged,
    otsOfficialLscState,
    otsOfficialHeaderState,
    otsLscRecertifyState,
    countOfYears: calculateCountOfYears(),
    startYearOffset: calculateStartYearOffset()
  };
};

export default useOtsOfficialLscCertificationRecertify;