import React, { Fragment } from 'react';

import useOtsOfficialLscBulkRecertifyDetail from './UseOtsOfficialLscBulkRecertifyDetail';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../../common/components/headings/Headings';
import Constants from '../../../../../common/utils/Constants';

const OtsOfficialLscBulkRecertifyDetail = () => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    officialCount,
    onBackToLscBulkRecertifyGrid,
    state,
    countOfYears,
    startYearOffset,
    searchOtsOfficialsForBulkRecertifyState
  } = useOtsOfficialLscBulkRecertifyDetail();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3 excludeBar={true}>Bulk Re-Certify LSC Certification</Headings.H3>
        </div>
        <div className="col-xs-12">
          <p><strong>Position Name (Code):</strong>&nbsp;{state?.positionTypeName} ({state?.positionAbbreviation})</p>
          <p><strong>Certification Name:</strong>&nbsp;{state?.levelName} </p>
          <p><strong>Number Of Officials To Re-Certify:</strong>&nbsp;{officialCount}</p>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
          <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-bottom-margin">
            <DatePicker
              label="Extend Expiration Date To*"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              onChange={(value) => { onFormValueChanged('expirationDate', value); }}
              countOfYears={countOfYears}
              startYearOffset={startYearOffset} />
          </div>
        </div>
        <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
          <div className="col-xs-12">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton onClick={onBackToLscBulkRecertifyGrid}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={searchOtsOfficialsForBulkRecertifyState.isSaving} />
    </Fragment>
  );
};

export default OtsOfficialLscBulkRecertifyDetail;