import Constants from '../../../common/utils/Constants';
import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  dataAsOptionsWithNames: [],
  message: ''
};

export const defaultFunFactsTypeState = {
  funFactsId: Constants.DEFAULT_ID,
  funFactsName: ''
};

const getFunFactsTypes = (state, setState) => {
  if (state.isLoading !== true && state.isLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      data: [],
      dataAsOptionsWithNames: [],
      message: 'Loading...'
    };
    setState(newState);

    const url = `/FunFacts`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const blankFunFactsType = { funFactsId: Constants.DEFAULT_ID, funFactsName: '--' };
          data.unshift(blankFunFactsType);
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((funFactType) => { return { id: funFactType.funFactsId, name: funFactType.funFactsName } }),
            message: '',
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  };
};

const FunFactsTypeData = { INITIAL_STATE, getFunFactsTypes };

export default FunFactsTypeData;