import React, { Fragment } from 'react';

import useOtsOfficialMeetEvaluationForm from './UseOtsOfficialMeetEvaluationForm';

import EvaluationFormYesNoSwitch from '../../meet/otsMeetEvaluations/EvaluationFormYesNoSwitch';
import EvaluationFormTextInput from '../../meet/otsMeetEvaluations/EvaluationFormTextInput';
import EvaluationFormIntegerInput from '../../meet/otsMeetEvaluations/EvaluationFormIntegerInput';

import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import LoadingModal from '../../../../../common/components/dialogs/LoadingModal';
import Headings from '../../../../../common/components/headings/Headings';
import ViewReport from '../../../../../common/components/embeddedReports/ViewReport';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsOfficialMeetEvaluationForm = () => {
  const {
    viewState,
    setViewState,
    INITIAL_VIEW_STATE,
    evaluation,
    isLoading,
    arrayData,
    message,
    onItemClick,
    onItemClickDropdown,
    onBackClicked,
    onRunMeetEvaluationReport } = useOtsOfficialMeetEvaluationForm();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Evaluation Form</Headings.H3>
        </div>
        <div className="col-xs-12 col-sm-6">
          <p><b>Official Name:</b> {evaluation?.officialFirstName || ''} {evaluation?.officialLastName || ''}</p>
          <p><b>Meet Name:</b> {evaluation?.meetName || ''}</p>
          <p><b>OQM Number:</b> {evaluation?.oqmNumber || ''}</p>
          <p><b>Meet Dates:</b> {evaluation?.meetStartDate ? formatDate(evaluation?.meetStartDate) : ''} - {evaluation?.meetEndDate ? formatDate(evaluation?.meetEndDate) : ''}</p>
        </div>
        <div className="col-xs-12 col-sm-6">
          <p><b>Position Worked:</b> {evaluation?.positionTypeName || ''}</p>
          <p><b>Evaluation Level:</b> {evaluation?.evaluationLevelName || ''}</p>
          <p><b>Evaluator Name:</b> {evaluation?.evaluatorFirstName || ''} {evaluation?.evaluatorLastName || ''}</p>
          <p><b>Evaluation Expiration Date:</b> {evaluation?.evaluationExpirationDate ? formatDate(evaluation?.evaluationExpirationDate) : ''}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {isLoading === true
            ? <LoadingModal />
            : arrayData.map((question, i) => {
              if (question?.typeName === "Boolean") {
                return (<EvaluationFormYesNoSwitch key={i} question={question} onItemClick={onItemClick} disabled={evaluation.hasEvaluation === true ? true : false} />);
              }
              else if (question?.typeName === "Text") {
                return (<EvaluationFormTextInput key={i} question={question} onItemClick={onItemClick} onItemClickDropdown={onItemClickDropdown} disabled={evaluation.hasEvaluation === true ? true : false} />);

              }
              else if (question?.typeName === "Integer") {
                return (<EvaluationFormIntegerInput key={i} question={question} onItemClick={onItemClick} disabled={evaluation.hasEvaluation === true ? true : false} />);
              }
              else {
                return (<Fragment />);
              }
            })}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {message && <p className={global.ErrorMessage}>{message}</p>}&nbsp;
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {evaluation.hasEvaluation === true &&
            <Fragment>
              <ActionIntraPageButton type="button" onClick={() => onRunMeetEvaluationReport(evaluation?.meetEvaluationId)}>Run Meet Evaluation Report</ActionIntraPageButton>&nbsp;
            </Fragment>}
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      {viewState.showReport === true &&
        <ViewReport reportInfoId={viewState.reportInfoId} reportParameters={viewState.reportParameters} onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />}
    </Fragment>
  );
};

export default OtsOfficialMeetEvaluationForm;