import { useEffect } from 'react';

import useOrgRoleStaffRoleHierarchyData from '../../../state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';

const useOrgRoleStaffRoleTreeView = (roleGroupId) => {
  const { orgRoleStaffRoleHierarchyState, getOrgRolesStaffRolesHierarchy } = useOrgRoleStaffRoleHierarchyData();

  useEffect(() => {
    if (roleGroupId > 0 && orgRoleStaffRoleHierarchyState.roleGroupId !== roleGroupId &&
      orgRoleStaffRoleHierarchyState.isArrayLoading === false) {
      getOrgRolesStaffRolesHierarchy(roleGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleGroupId])

  return {
    orgRoleStaffRoleHierarchyState
  };
};

export default useOrgRoleStaffRoleTreeView;