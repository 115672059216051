import { useEffect } from 'react';

import usePersonPositionCertificationNoteData from '../../../state/personPositionCertificationNote/UsePersonPositionCertificationNoteData';

const usePersonPositionCertificationNoteDropdown = () => {
  const { personPositionCertificationNoteState, getPersonPositionCertificationNotes } = usePersonPositionCertificationNoteData();

  useEffect(() => {
    if (personPositionCertificationNoteState.isArrayLoading !== true && personPositionCertificationNoteState.isArrayLoaded !== true) {
      getPersonPositionCertificationNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    personPositionCertificationNoteState
  };
};

export default usePersonPositionCertificationNoteDropdown;