import React, { Fragment } from 'react';

import NationalTeamMissedDrugTestsLargeGrid from './NationalTeamMissedDrugTestsLargeGrid';
import NationalTeamMissedDrugTestsSmallGrid from './NationalTeamMissedDrugTestsSmallGrid';

const NationalTeamMissedDrugTestsGrid = ({ gridState, onAddEdit, onDelete }) => {

  return (
    <Fragment>
      <NationalTeamMissedDrugTestsLargeGrid
        gridState={gridState}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
      />
      <NationalTeamMissedDrugTestsSmallGrid
        gridState={gridState}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
      />
    </Fragment>
  )
};

export default NationalTeamMissedDrugTestsGrid;