import { useState} from 'react';

import ApprovedClubsData from './ApprovedClubsData';

const useApprovedClubsData = () => {
  const [approvedClubsState, setApprovedClubsState] = useState(ApprovedClubsData.INITIAL_STATE);

  const getApprovedClubs = (lscId) => ApprovedClubsData.getApprovedClubs(lscId, approvedClubsState, setApprovedClubsState);

  return {
    approvedClubsState,
    getApprovedClubs
  };
};

export default useApprovedClubsData;