import React, { createContext, useState } from 'react';

import OfficialLscCertificationData from './OfficialLscCertificationData';

export const OfficialLscCertificationStateContext = createContext();

const OfficialLscCertificationContextProvider = ({ children }) => {
  const stateHook = useState(OfficialLscCertificationData.INITIAL_STATE);

  return (
    <OfficialLscCertificationStateContext.Provider value={stateHook}>
      {children}
    </OfficialLscCertificationStateContext.Provider>
  );
};

export default OfficialLscCertificationContextProvider;