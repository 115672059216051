import { useEffect, useState } from 'react';
import useOtsCertificationRuleData from '../../../../state/otsCertificationRule/UseOtsCertificationRuleData';
import useOtsCertificationApplicationData from '../../../../state/otsCertificationApplication/UseOtsCertificationApplicationData';

const useStatus = (personPositionCertificationProgressionId, position) => {
  const [ruleData, setRuleData] = useState([]);
  const [positionCertificationProgression, setPositionCertificationProgression] = useState({});
  const { otsCertificationApplicationState } = useOtsCertificationApplicationData();
  const { getOtsCertificationRulesForPersonProgression } = useOtsCertificationRuleData();

  useEffect(() => {
    getOtsCertificationRulesForPersonProgression(personPositionCertificationProgressionId)
      .then(data => {
        setRuleData({ ...ruleData, positionCertificationRule: data.arrayData?.positionCertificationRule || '' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPositionCertificationProgressionId]);

  useEffect(() => {
    if (personPositionCertificationProgressionId > 0 && position && Object.keys(position).length > 0) {
      const found = otsCertificationApplicationState?.arrayData?.find(x => x.personPositionCertificationProgressionId === personPositionCertificationProgressionId &&
        x.personPositionCertificationId === position.personPositionCertificationId
      );
      if (found) {
        setPositionCertificationProgression({ ...found });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPositionCertificationProgressionId, position]);

  return {
    ruleData,
    positionCertificationProgression
  };
};

export default useStatus;