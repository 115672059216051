import { useEffect, useState } from "react";

import { createPersonMergeObj, getMergeFieldForFormGrid } from "../utils/MemberMergeUtils";
import { MERGE_FIELDS } from "../utils/MemberMergeConstants";

const useMemberMergeFormGrid = (personAData, personBData, onMergeClicked) => {
  const [formGridState, setFormGridState] = useState([]);

  const onMergeSelectionChanged = (isChecked, fieldKey, fieldName) => {
    if (isChecked !== true) {
      const newFormGridState = JSON.parse(JSON.stringify(formGridState));

      for (let i = 0; i < newFormGridState.length; i++) {
        if (fieldKey === newFormGridState[i].displayValueKey) {
          if (fieldName === 'personADisplayValue') {
            newFormGridState[i].isPersonBSelected = false;
          } else if (fieldName === 'personBDisplayValue') {
            newFormGridState[i].isPersonBSelected = true;
          }
          
          break;
        }
      }

      setFormGridState(newFormGridState);
    }
  };

  const onSubmitFormGrid = (formGridState) => {
    const personMergeObj = createPersonMergeObj(formGridState, personAData, personBData);

    onMergeClicked(personMergeObj);
  };

  useEffect(() => {
    if (personAData && personBData) {
      const newFormGridState = [];

      for (const mergeField of MERGE_FIELDS) {
        if (mergeField.isReadOnly !== true) {
          newFormGridState.push(getMergeFieldForFormGrid(mergeField, personAData, personBData));
        }
      }

      setFormGridState(newFormGridState);
    }
  }, [personAData, personBData]);

  function getPersonNameForHeader(personData) {
    return `${personData?.firstName || ''} ${personData?.lastName || ''}`;
  }

  return {
    isLoading: formGridState.length <= 0,
    personAName: getPersonNameForHeader(personAData),
    personBName: getPersonNameForHeader(personBData),
    formGridState,
    onSubmitFormGrid,
    onMergeSelectionChanged
  };
};

export default useMemberMergeFormGrid;