import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsCertificationApplicationData from '../../../../state/otsCertificationApplication/UseOtsCertificationApplicationData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

const useCertificationReview = () => {
  const { navRoutes } = useNavRoutes();
  const navigate = useNavigate();
  const [state, setState] = useState({ tryRedirectToOfficialCertification: false, tryRedirectToOfficial: false });
  const { getPersonPositionCertificationProgression,
    otsCertificationApplicationContextState, setOtsCertificationApplicationContextState }
    = useOtsCertificationApplicationData();

  const onReviewClicked = (personPositionCertificationProgressionId) => {
    getPersonPositionCertificationProgression(personPositionCertificationProgressionId)
      .then((data) => {
        setOtsCertificationApplicationContextState({
          ...otsCertificationApplicationContextState,
          personPositionCertificationProgressionId,
          personPositionCertificationProgression: data.objData
        });
        setState({ ...state, tryRedirectToOfficialCertification: true, tryRedirectToOfficial: false });
      });
  };

  const onSaveSuccessful = () => {
    setOtsCertificationApplicationContextState({
      ...otsCertificationApplicationContextState,
      personPositionCertificationProgressionId: -1
    });
    setState({ ...state, tryRedirectToOfficialCertification: false, tryRedirectToOfficial: true });
  };

  const onCancelClicked = () => {
    setOtsCertificationApplicationContextState({
      ...otsCertificationApplicationContextState,
      personPositionCertificationProgressionId: -1
    });
    setState({ ...state, tryRedirectToOfficialCertification: false, tryRedirectToOfficial: true });
  };

  const onGoToOfficialsWithActiveApplicationsClicked = () => {
    navigate(navRoutes.OTS_CERT_APP_WORKFLOW_SEARCH?.route);
  };

  useEffect(() => {
    if (state.tryRedirectToOfficialCertification === true) {
      if (otsCertificationApplicationContextState.personPositionCertificationProgressionId !== -1 &&
        otsCertificationApplicationContextState.personPositionCertificationProgression &&
        Object.keys(otsCertificationApplicationContextState.personPositionCertificationProgression).length > 0) {
        navigate(navRoutes.OTS_CERT_APP_WORKFLOW_OFFICIAL_CERTIFICATION?.route);
      }
    }
    else if (state.tryRedirectToOfficial === true) {
      if (otsCertificationApplicationContextState.personPositionCertificationProgressionId === -1) {
        navigate(navRoutes.OTS_CERT_APP_WORKFLOW_OFFICIAL.route);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, otsCertificationApplicationContextState]);

  return {
    ...otsCertificationApplicationContextState,
    buttonClickHandlers: {
      onReviewClicked,
      onSaveSuccessful,
      onCancelClicked,
      onGoToOfficialsWithActiveApplicationsClicked
    }
  };
};

export default useCertificationReview;