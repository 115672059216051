import useLscOfferingManagementContextView from './UseLscOfferingManagementContextView';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

import { ERROR_MSG, LOADING_MSG } from '../../../../common/utils/Constants';

const LscOfferingManagementContextLoadingModal = ({ isLoading, loadingMessage = LOADING_MSG,
  isError, errorMessage = ERROR_MSG }) => {
  const {
    onBackToSelectionClicked
  } = useLscOfferingManagementContextView();

  if (isError === true) {
    return (
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Offering Management Error'}
        displayPopUp={true}
        onModalCanceled={onBackToSelectionClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{errorMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onBackToSelectionClicked}>Reload LSC Offering Management</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
    );
  } else {
    return (
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={loadingMessage}
        displayPopUp={isLoading === true} />
    );
  }
};

export default LscOfferingManagementContextLoadingModal;