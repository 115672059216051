import React from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ sponsor, onAddEdit, onDelete }) => (
  <tr>
    <td>{sponsor.sponsor?.sponsorName || ''}</td>
    <td>{sponsor.sponsor?.sponsorType?.typeName || ''}</td>
    <td>{sponsor.effectiveDate ? formatDate(sponsor.effectiveDate) : ''}</td>
    <td>{sponsor.expirationDate ? formatDate(sponsor.expirationDate) : ''}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddEdit(e, sponsor.personSponsorId)}>
        <EditIcon />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={(e) =>
        onDelete(e, sponsor.personSponsorId, `${sponsor.sponsor?.sponsorName} (Effective Date: ${formatDate(sponsor.effectiveDate)}, Expiration Date: ${formatDate(sponsor.expirationDate)})`)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const NationalTeamSponsorsLargeGrid = ({ gridState, onAddEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Sponsor Name</th>
        <th>Sponsor Type</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {gridState.isObjLoaded !== true
        ? (
          <tr><td colSpan="5">{gridState.isObjLoading === true ? 'Loading...' : 'No Sponsors'}</td></tr>
        ) : Array.isArray(gridState.objData.sponsors) && gridState.objData.sponsors.length > 0
          ? gridState.objData.sponsors.map((sponsor, i) => <GridRow key={i} sponsor={sponsor} onAddEdit={onAddEdit} onDelete={onDelete} />)
          : <tr><td colSpan="5">No Sponsors</td></tr>
      }
    </tbody>
  </table>
);

export default NationalTeamSponsorsLargeGrid;