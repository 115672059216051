import React, { useState, createContext } from 'react';

export const SearchOtsOfficialFilterStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: '',
  backToSystem: 'OtsOfficial'
};

const SearchOtsOfficialFilterContextProvider = ({ children }) => {
  const stateHook = useState({ INITIAL_STATE });

  return (
    <SearchOtsOfficialFilterStateContext.Provider value={stateHook}>
      {children}
    </SearchOtsOfficialFilterStateContext.Provider>
  );
};

export default SearchOtsOfficialFilterContextProvider;