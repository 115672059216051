import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetPersonNationalTeamAttachmentFileTypesData = (state, setState) => {
  if (state.isArrayLoading !== true && state.isArrayLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      arrayData: [],
      dataAsOptionsWithNames: [],
      message: 'Loading...'
    };
    setState(newState);

    const url = `/PersonNationalAttachmentFileType`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: arrayData,
            dataAsOptionsWithNames: arrayData.map((fileType) => { return { id: fileType.personNationalAttachmentFileTypeId, name: fileType.typeName } }),
            message: '',
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetPersonNationalTeamAttachmentFileTypesData;