import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../../common/wrappers/ReactRouterDom';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import OfficialPositionTypeContextProvider from '../../../state/officialPositionType/OfficialPositionTypeContextProvider';
import CertificationLevelContextProvider from '../../../state/certificationLevel/CertificationLevelContextProvider';
import SearchOtsOfficialsForBulkRecertifyContextProvider from '../../../state/officialsTrackingSystemBulkRecertification/SearchOtsOfficialsForBulkRecertifyContextProvider';
import SearchOtsOfficialsForBulkRecertifyFilterContextProvider from '../../../state/officialsTrackingSystemBulkRecertification/SearchOtsOfficialsForBulkRecertifyFilterContextProvider';

import BasePersonContextProvider from '../../../../common/state/personBase/BasePersonContextProvider';
import BasePersonFiltersContextProvider from '../../../../common/state/personBase/BasePersonFiltersContextProvider';
import SelectOrgUnitContextProvider from '../../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../../common/state/personOrgRole/PersonOrgRoleContextProvider';

import OtsOfficialBulkRecertifySelection from './otsOfficialLscBulkRecertifySelection/OtsOfficialLscBulkRecertifySelection';
import OfficialsTrackingSystemLscBulkRecertifyContextView from './OfficialsTrackingSystemLscBulkRecertifyContextView';
import OtsOfficialLscBulkRecertify from './otsOfficialLscBulkRecertify/OtsOfficialLscBulkRecertify';
import OtsOfficialLscBulkRecertifyDetail from './otsOfficialLscBulkRecertify/OtsOfficialLscBulkRecertifyDetail';

import LoadingModal from '../../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../../common/components/security/SecuredRouteElement';

const ContextRoutes = ({ navRoutes }) => {
  return (
    <OfficialsTrackingSystemLscBulkRecertifyContextView>
      <Routes>
        <Route path={navRoutes['OTS_LSC_BULK_RECERTIFICATION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OTS_LSC_BULK_RECERTIFICATION']}
              editElement={<OtsOfficialLscBulkRecertify />} />
          }
        />
        <Route path={navRoutes['OTS_LSC_BULK_RECERTIFICATION_DETAIL']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['OTS_LSC_BULK_RECERTIFICATION_DETAIL']}
              editElement={<OtsOfficialLscBulkRecertifyDetail />} />
          }
        />
      </Routes>
    </OfficialsTrackingSystemLscBulkRecertifyContextView>
  )
};

const OfficialsTrackingSystemOfficialRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (

    <Routes>
      <Route path={navRoutes['OTS_LSC_BULK_RECERTIFICATION_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_LSC_BULK_RECERTIFICATION_SELECTION']}
            editElement={<OtsOfficialBulkRecertifySelection />} />
        }
      />

      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['OTS_LSC_BULK_RECERTIFICATION_SELECTION']?.route} replace />} />
    </Routes>
  );
}

const OfficialsTrackingSystemLscBulkRecertify = () => (
  <PersonOrgRoleContextProvider>
    <SelectOrgUnitContextProvider>
      <SearchOtsOfficialsForBulkRecertifyContextProvider>
        <SearchOtsOfficialsForBulkRecertifyFilterContextProvider>
          <BasePersonContextProvider>
            <BasePersonFiltersContextProvider>
              <OfficialPositionTypeContextProvider>
                <CertificationLevelContextProvider>
                  <OfficialsTrackingSystemOfficialRoutes />
                </CertificationLevelContextProvider>
              </OfficialPositionTypeContextProvider>
            </BasePersonFiltersContextProvider>
          </BasePersonContextProvider>
        </SearchOtsOfficialsForBulkRecertifyFilterContextProvider>
      </SearchOtsOfficialsForBulkRecertifyContextProvider>
    </SelectOrgUnitContextProvider>
  </PersonOrgRoleContextProvider>
);

export default OfficialsTrackingSystemLscBulkRecertify;