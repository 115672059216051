import { Fragment } from 'react';

import useNationalTeamAdvancedInfo from './UseNationalTeamAdvancedInfo';

import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import Textarea from '../../../../common/components/inputs/Textarea';

import style from '../Member.module.css';

const NationalTeamAdvancedInfo = () => {
  const {
    title,
    Constants,
    personNationalTeamAdvancedState,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    resetForm,
  } = useNationalTeamAdvancedInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{title}</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Passport Number"
              name="passportNumber"
              value={formState.passportNumber}
              error={errorState.passportNumber}
              message={errorState.passportNumber}
              onChange={(value) => { onFormValueChanged('passportNumber', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Passport Effective Date"
              name="passportEffectiveDate"
              value={formState.passportEffectiveDate}
              error={errorState.passportEffectiveDate}
              message={errorState.passportEffectiveDate}
              countOfYears={150}
              startYearOffset={-149}
              onChange={(value) => { onFormValueChanged('passportEffectiveDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Passport Expiration Date"
              name="passportExpirationDate"
              value={formState.passportExpirationDate}
              error={errorState.passportExpirationDate}
              message={errorState.passportExpirationDate}
              countOfYears={150}
              startYearOffset={-140}
              onChange={(value) => { onFormValueChanged('passportExpirationDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Professional Swimmer"
              name="professionalSwimmer"
              checked={formState.professionalSwimmer === true}
              error={errorState.professionalSwimmer}
              message={errorState.professionalSwimmer}
              onChange={(value) => { onFormValueChanged('professionalSwimmer', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Passport Certified"
              name="passportVarified"
              checked={formState.passportVarified === true}
              error={errorState.passportVarified}
              message={errorState.passportVarified}
              onChange={(value) => { onFormValueChanged('passportVarified', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <Textarea
              label="Comments"
              className={style.CommentBoxMinHeight}
              name="comments"
              value={formState.comments || ''}
              onChange={(value) => { onFormValueChanged('comments', value); }}
            />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personNationalTeamAdvancedState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personNationalTeamAdvancedState.isSaving} />
    </Fragment>
  );
}

export default NationalTeamAdvancedInfo;