import React from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ funFact, onAddEdit, onDelete }) => (
  <tr>
    <td>{funFact.funFacts?.funFactsName || ''}</td>
    <td>{funFact.modifiedDatetime ? formatDate(funFact.modifiedDatetime) : ''}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddEdit(e, funFact.personFunFactsId)}>
        <EditIcon />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={(e) =>
        onDelete(e, funFact.personFunFactsId, `${funFact.funFacts?.funFactsName}: ${funFact.textValue.length > 80 ? funFact.textValue.substring(0, 80) + '...' : funFact.textValue} (Last Modified Date: ${formatDate(funFact.modifiedDatetime)})`)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);


const ProfileFunFactsLargeGrid = ({ gridState, onAddEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Fun Fact Name</th>
        <th>Last Modified Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {gridState.isObjLoaded !== true
        ? (
          <tr><td colSpan="3">{gridState.isObjLoading === true ? 'Loading...' : 'No Fun Facts'}</td></tr>
        ) : Array.isArray(gridState.objData.funFacts) && gridState.objData.funFacts.length > 0
          ? gridState.objData.funFacts.map((funFact, i) => (
            <GridRow
              key={i}
              funFact={funFact}
              onAddEdit={onAddEdit}
              onDelete={onDelete}
            />))
          : <tr><td colSpan="3">No Fun Facts</td></tr>
      }
    </tbody>
  </table>
);

export default ProfileFunFactsLargeGrid;