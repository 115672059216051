import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getPersonPositionCertificationNoteData from './GetPersonPositionCertificationNoteData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: []
};

const PersonPositionCertificationNoteData = {
  INITIAL_STATE, getPersonPositionCertificationNoteData
}

export default PersonPositionCertificationNoteData;