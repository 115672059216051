import React, { Fragment } from 'react';

import useMemberNarratives from './UseMemberNarratives';

import MemberNarrativesGrid from './MemberNarrativesGrid';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

const MemberNarratives = () => {
  const { 
    title,
    DEFAULT_ID,
    personProfileState,
    modalState,
    onAddEditNarrative,
    onOpenModalClicked,
    onModalCanceled,
    onDeleteNarrative } = useMemberNarratives();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{title}</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={(e) => onAddEditNarrative(e, DEFAULT_ID)}>Add Narrative</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <MemberNarrativesGrid state={personProfileState} onAddEdit={onAddEditNarrative} onDelete={onOpenModalClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personProfileState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personProfileState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.narrativeName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteNarrative}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default MemberNarratives;