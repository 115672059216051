import React, { Fragment } from 'react';

import ProfileFunFactsLargeGrid from './ProfileFunFactsLargeGrid';
import ProfileFunFactsSmallGrid from './ProfileFunFactsSmallGrid';

const ProfileFunFactsGrid = ({ gridState, onAddEdit, onDelete }) => {

  return (
    <Fragment>
      <ProfileFunFactsLargeGrid
        gridState={gridState}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
      />
      <ProfileFunFactsSmallGrid
        gridState={gridState}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
      />
    </Fragment>
  );
};

export default ProfileFunFactsGrid;