import { isValidDate } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  if (formState.expirationDate === Constants.BLANK_DATE_STRING
    || !isValidDate(formState.expirationDate)) {
    errors.expirationDate = 'End Date must be a valid date';
  }

  if (expirationDate < effectiveDate) {
    errors.effectiveDate = 'Expiration Date must be before the Effective Date';
  }

  if (effectiveDate > expirationDate) {
    errors.expirationDate = 'Expiration Date must be after the Effective Date';
  }

  if (formState.effectiveDate !== Constants.BLANK_DATE_STRING
    && formState.expirationDate === Constants.BLANK_DATE_STRING) {
    errors.expirationDate = 'Expiration Date is required if there is a Effective Date';
  }

  if (formState.termLengthId < 0 || formState.termLengthName === '--' || formState.termLengthName === '') {
    errors.termLengthId = 'Selecting Term Length is required';
  }

  if (formState.representativeTypeId < 0 || formState.representativeTypeName === '--' || formState.representativeTypeName === '') {
    errors.representativeTypeId = 'Selecting Representative Type is required';
  }

  return errors;
};

const EditHQCommitteeMemberValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default EditHQCommitteeMemberValidation;