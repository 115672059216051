import { Fragment } from 'react';
import useMemberInfo from './UseMemberInfo';

import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberInfoWrite = () => {
  const {
    state,
    fileState,
    removeFileModalState,
    radioButtonListState,
    basePersonState,
    personGeneralState,
    memberFilesBirthCertificateState,
    genderIdentityState,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    resetForm,
    onUploadBirthCertificateClicked,
    onRemoveBirthCertificateClicked,
    onDownloadUploadedFile,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    suffixOptions,
    usCitizenModalState,
    onUsCitizenshipIsChangingClicked,
    onUsCitizenshipIsNotChangingClicked,
    memberName
  } = useMemberInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Info</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H6>Birth Certificate (Optional)</Headings.H6>
        </div>
      </div>
      {state.showUploadBirthCertificate === true ?
        <Fragment>
          {fileState.birthCertificateFileUrl === '' ?
            <Fragment>
              <div className="row usas-extra-top-margin">
                <div className="col-xs-12">
                  <label htmlFor="birth-certificate-file-upload" className={global.FileUpload}>
                    Upload Birth Certificate
                  </label>&nbsp;(*.pdf), (*.doc), or (*.docx)
                </div>
              </div>
              <input hidden id="birth-certificate-file-upload" type="file" onChange={onUploadBirthCertificateClicked} />
            </Fragment>
            : <Fragment>
              <div className="row usas-extra-top-margin">
                <div className="col-xs-12">
                  <SecondaryButton onClick={onOpenRemoveFileModalClicked}>Remove Uploaded File</SecondaryButton>&nbsp;
                  {fileState.birthCertificateFileName.includes("Bad Request") === false &&
                    <PrimaryButton type="button" onClick={(event) => onDownloadUploadedFile(event)}>Download Uploaded File</PrimaryButton>
                  }
                </div>
              </div>
            </Fragment>}
          <div className="row usas-extra-bottom-margin" hidden={fileState.birthCertificateFileUrl === ''}>
            <div className="col-xs-12">
              {fileState.birthCertificateFileName.includes("Bad Request")
                ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
                : <p><b>Uploaded File Name:</b> {fileState.birthCertificateFileName}</p>
              }
            </div>
          </div>
          {state.error ?
            <div className="row">
              <div className="col-xs-12">
                <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
              </div>
            </div> : <Fragment />}
        </Fragment>
        : <Fragment>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              Birth Date is confirmed
            </div>
          </div>
        </Fragment>}
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Legal First Name*"
              name="firstName"
              value={formState.firstName}
              error={errorState.firstName}
              message={errorState.firstName}
              onChange={(value) => { onFormValueChanged('firstName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Preferred Name"
              name="preferredName"
              value={formState.preferredName}
              error={errorState.preferredName}
              message={errorState.preferredName}
              onChange={(value) => { onFormValueChanged('preferredName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Middle Name or Middle Initial"
              name="middleName"
              value={formState.middleName}
              error={errorState.middleName}
              message={errorState.middleName}
              onChange={(value) => { onFormValueChanged('middleName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Legal Last Name*"
              name="lastName"
              value={formState.lastName}
              error={errorState.lastName}
              message={errorState.lastName}
              onChange={(value) => { onFormValueChanged('lastName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Maiden Name"
              name="maidenName"
              value={formState.maidenName}
              error={errorState.maidenName}
              message={errorState.maidenName}
              onChange={(value) => { onFormValueChanged('maidenName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Suffix"
              name="suffix"
              options={suffixOptions}
              value={formState.suffixId}
              error={errorState.suffixId}
              message={errorState.suffixId}
              isLoading={false}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, 'suffixId', newValueLabel, 'suffixName');
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {state.showEmail === true ?
              <Input
                label={formState.isEmailRequired === true ? "Email*" : "Email"}
                name="email"
                value={formState.email}
                error={errorState.email}
                message={errorState.email}
                onChange={(value) => { onFormValueChanged('email', value); }} />
              :
              <ReadOnly
                label="Email*"
                name="email"
                value="Not collected for members under 16" />}
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="US Citizen*"
              name="isUsCitizen"
              checked={formState.isUsCitizen}
              error={errorState.isUsCitizen}
              message={errorState.isUsCitizen}
              onChange={(value) => { onFormValueChanged('isUsCitizen', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Birth Date"
              name="birthDate"
              value={formState.birthDate} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Competition Category"
              name="competitorGender"
              value={formState.competitionGender} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label={"Gender Identity*"}
              options={radioButtonListState?.radiobuttonlistGenderIdentity}
              name="genderIdentityTypeId"
              value={formState.genderIdentityTypeId}
              error={errorState.genderIdentityTypeId}
              message={errorState.genderIdentityTypeId}
              areValuesIntegers={true}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, 'genderIdentityTypeId', newValueLabel, 'genderIdentity');
              }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || personGeneralState.isObjLoading || genderIdentityState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={basePersonState.isSaving || memberFilesBirthCertificateState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={removeFileModalState.modalTitle}
        displayPopUp={removeFileModalState.displayPopUp}
        onModalCanceled={onRemoveFileModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> Birth Certificate</p>
            {removeFileModalState.fileName.includes("Bad Request")
              ? <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
              : <p><b>Uploaded File Name:</b> {removeFileModalState.fileName}</p>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onRemoveBirthCertificateClicked}>Yes, Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onRemoveFileModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={usCitizenModalState.modalTitle}
        displayPopUp={usCitizenModalState.displayPopUp}>
        <div className="row">
          <div className="col-xs-12">
            <p>Are you sure that US Citizenship is changing for {memberName}?</p>
            <p>New US Citizen Selection: {formState.isUsCitizen === true ? 'Yes' : formState.isUsCitizen === false ? 'No' : ''}</p>
            <p><b>This change has an impact on National Team and National Junior Team Selection.</b></p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onUsCitizenshipIsChangingClicked}>Yes, Accept Change</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onUsCitizenshipIsNotChangingClicked}>No, Undo Change</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default MemberInfoWrite;