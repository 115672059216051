import useEducationReporting from './UseEducationReporting';

import ReportList from '../../../common/components/embeddedReports/ReportList';

const EducationReporting = () => {
  const { reportParameters, routeName } = useEducationReporting();
  
  return (
    <ReportList listTitle="Education Reports"
      routeName={routeName}
      reportParameters={reportParameters} />
  );
};

export default EducationReporting;