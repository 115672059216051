import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getLatestCompetitionData from './GetLatestCompetitionData';
import getPrimaryPersonOrgRoleDurationsData from './GetPrimaryPersonOrgRoleDurationsData';
import postPersonClubTransferData from './PostPersonClubTransferData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonClubTransferData = {
  INITIAL_STATE, getLatestCompetitionData, postPersonClubTransferData, getPrimaryPersonOrgRoleDurationsData
};

export default PersonClubTransferData;