import React from 'react';

import useOtsMeetOfficialCombobox from './UseOtsMeetOfficialCombobox';

import Combobox from '../../../../common/components/comboboxes/Combobox';

import global from '../../../../common/components/GlobalStyle.module.css';

const OtsMeetOfficialCombobox = ({ otsMeetId, label, name, valueToMatch, error, message, onChange }) => {
  const { otsMeetOfficialState } = useOtsMeetOfficialCombobox(otsMeetId);

  return otsMeetOfficialState.message
    ? <span className={global.LoadingMsg}>{otsMeetOfficialState.message}</span>
    :
    <Combobox
      label={label}
      name={name}
      isLoading={otsMeetOfficialState.isArrayLoading}
      items={otsMeetOfficialState.items}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
};

export default OtsMeetOfficialCombobox;