import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getOfferingTypesData = (state, setState) => {
  const api = initApi(API_NAMES.OMR, state, setState);
  const url = '/OfferingType';

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const INITIAL_OFFERING_TYPES_STATE = {
  ...BASIC_INITIAL_STATE
};

const OfferingTypesData = {
  INITIAL_OFFERING_TYPES_STATE,

  getOfferingTypesData
};

export default OfferingTypesData;