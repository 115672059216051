import GridLarge from './GridLarge';
import GridSmall from './GridSmall';

const Grid = ({ gridData, onStatusClicked }) => {
  return (
    <>
      <GridLarge gridData={gridData} onStatusClicked={onStatusClicked} />
      <GridSmall gridData={gridData} onStatusClicked={onStatusClicked} />
    </>
  );
};

export default Grid;