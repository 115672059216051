export const localValidate = (formState) => {

  const DISABILITY_TYPE_IDS = {
    P2: 7,
    P3: 8,
    DISABILITY: 21
  }

  let errors = {};

  if (formState.disabilityTypes.length < 1) {
    errors.disabilityTypes = 'Please make a selection';
  }

  //If P2 selected and no checkbox has been selected
  if (formState.disabilityTypes.some((d) => d.id === DISABILITY_TYPE_IDS.P2)
    && formState.disabilityTypes.length < 2) {
    errors.disabilityTypes = "Please select one or more P2 disabilties that apply"
  }

  //If P2 selected and no checkbox has been selected
  if (formState.disabilityTypes.some((d) => d.id === DISABILITY_TYPE_IDS.P3)
    && formState.disabilityTypes.length < 2) {
    errors.disabilityTypes = "Please select one or more P3 disabilties that apply"
  }

  //If Disability selected and no checkbox has been selected
  if (formState.disabilityTypes.some((d) => d.id === DISABILITY_TYPE_IDS.DISABILITY)
    && formState.disabilityTypes.length < 2) {
    errors.disabilityTypes = "Please select one or more disabilties that apply"
  }

  return errors;
};

const MemberDisabilitiesValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default MemberDisabilitiesValidation;