import { useContext, useEffect } from 'react';

import { ReportPeriodFlatStateContext } from './ReportPeriodFlatContextProvider';

import ReportPeriodFlatData from './ReportPeriodFlatData';

const useReportPeriodFlatData = (organizationId) => {
  const [reportPeriodFlatState, setReportPeriodFlatState] = useContext(ReportPeriodFlatStateContext);
  const getReportPeriodsFlatByOrganizationId = (organizationId) => {
    ReportPeriodFlatData.getReportPeriodsFlatByOrganizationId(organizationId, reportPeriodFlatState, setReportPeriodFlatState);
  };

  useEffect(() => {
    if (reportPeriodFlatState.isLoading !== true && organizationId > 0 && organizationId !== reportPeriodFlatState.organizationId) {
      ReportPeriodFlatData.getReportPeriodsFlatByOrganizationId(organizationId, reportPeriodFlatState, setReportPeriodFlatState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return {
    reportPeriodFlatState,
    getReportPeriodsFlatByOrganizationId
  };
};

export default useReportPeriodFlatData;