import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ evaluator, onDeleteButtonClicked }) => (
  <tr>
    <td>{evaluator.evaluatorName}</td>
    <td>{evaluator.nationalCertification}</td>
    <td>{evaluator.mentorStatus}</td>
    <td>
      <button 
        className={global.IconButtonMargin}
        type="button" 
        onClick={(e) => onDeleteButtonClicked(evaluator)}>
          <DeleteIcon />
      </button>
    </td>
  </tr>
);

const MeetEvaluatorsGridLarge = ({ gridData, onDeleteButtonClicked }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Evaluator Name</th>
          <th>National Certifications</th>
          <th>Mentor Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(gridData) && gridData.length > 0
          ? gridData?.map((evaluator, i) => <LargeGridRow key={i} evaluator={evaluator} onDeleteButtonClicked={onDeleteButtonClicked} />)
          : <tr><td colSpan="4">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default MeetEvaluatorsGridLarge;