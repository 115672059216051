import React, { createContext, useState } from 'react';

import DrugTestStatusData from './DrugTestStatusData';

export const DrugTestStatusStateContext = createContext();

const DrugTestStatusContextProvider = ({ children }) => {
  const stateHook = useState(DrugTestStatusData.INITIAL_STATE);

  return (
    <DrugTestStatusStateContext.Provider value={stateHook}>
      {children}
    </DrugTestStatusStateContext.Provider>
  );
};

export default DrugTestStatusContextProvider;