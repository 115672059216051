import { useEffect, useState } from 'react';

import validate from './EditNationalEvaluatorValidation';

import useNationalEvaluatorData from '../../../../state/otsNationalEvaluator/UseNationalEvaluatorData';

import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import { formatDateYMD } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';
import useForm from '../../../../../common/utils/UseForm';

const INITIAL_VIEW_STATE = {
  isSaving: false,
}

const INITIAL_FORM_STATE = {
  nationalEvaluatorId: -addEventListener,
  personId: '',
  officialPositionTypes: []
};

const useEditNationalEvaluator = (nationalEvaluatorId, officialPositionTypesData, onEditCallback) => {
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const [evaluatorName, setEvaluatorName] = useState('');
  const [initialized, setInitialized] = useState(false);
  const { getNationalEvaluator, editNationalEvaluator } = useNationalEvaluatorData();
  const {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    setFormData,
    setIsDirty,
    isSubmitting } = useForm({ ...INITIAL_FORM_STATE, nationalEvaluatorId }, submitFormCallback, validate);

  function submitFormCallback(formState) {
    const officialPositionTypes = formState.officialPositionTypes
      .filter(x => x.nationalEvaluatorTitleId > 0)
      .map(position => {
        return {
          nationalEvaluatorOfficialPositionTypeId: position.nationalEvaluatorOfficialPositionTypeId,
          nationalEvaluatorId,
          officialPositionTypeId: position.officialPositionTypeId,
          nationalEvaluatorTitleId: position.nationalEvaluatorTitleId,
          effectiveDate: position.effectiveDate,
          expirationDate: position.expirationDate !== Constants.BLANK_DATE_STRING ? position.expirationDate : null
        };
      });
    const payload = { ...formState, officialPositionTypes };
    setViewState({ ...viewState, isSaving: true });
    editNationalEvaluator(nationalEvaluatorId, payload)
      .then(data => {
        onEditCallback();
      })
      .catch(e => {
        setViewState({ ...viewState, isSaving: false });
        console.log(e.message);
      });
  }

  const onTitleChanged = (officialPositionTypeId, newNationalEvaluatorTitleId, newNationalEvaluatorTitle) => {
    const officialPositionTypes = [...formState.officialPositionTypes];
    let valueUpdated = false;
    officialPositionTypes.forEach(type => {
      const nationalEvaluatorTitleId = ToIntIfInt(newNationalEvaluatorTitleId);
      if (type.officialPositionTypeId === officialPositionTypeId && type.nationalEvaluatorTitleId !== nationalEvaluatorTitleId) {
        valueUpdated = true;
        type.nationalEvaluatorTitleId = nationalEvaluatorTitleId;
        type.nationalEvaluatorTitle = newNationalEvaluatorTitle;
        if (!type.nationalEvaluatorOfficialPositionTypeId || type.nationalEvaluatorOfficialPositionTypeId <= 0) {
          type.effectiveDate = new Date().toLocaleDateString('en-CA', { day: '2-digit', month: '2-digit', year: 'numeric' }) + 'T00:00:00';
          const todayPlus3Years = new Date();
          todayPlus3Years.setFullYear(todayPlus3Years.getFullYear() + 3);
          type.expirationDate = todayPlus3Years.toLocaleDateString('en-CA', { day: '2-digit', month: '2-digit', year: 'numeric' }) + 'T00:00:00';
        }
      }
    });

    if (valueUpdated === true) {
      onFormValueChanged('officialPositionTypes', officialPositionTypes);
    }
  };

  const onEffectiveDateChanged = (officialPositionTypeId, newEffectiveDate) => {
    const officialPositionTypes = [...formState.officialPositionTypes];
    let valueUpdated = false;
    officialPositionTypes.forEach(type => {
      if (type.officialPositionTypeId === officialPositionTypeId && type.effectiveDate !== newEffectiveDate) {
        valueUpdated = true;
        type.effectiveDate = newEffectiveDate;
      }
    });

    if (valueUpdated === true) {
      onFormValueChanged('officialPositionTypes', officialPositionTypes);
    }
  };

  const onExpirationDateChanged = (officialPositionTypeId, newExpirationDate) => {
    const officialPositionTypes = [...formState.officialPositionTypes];
    let valueUpdated = false;
    officialPositionTypes.forEach(type => {
      if (type.officialPositionTypeId === officialPositionTypeId && type.expirationDate !== newExpirationDate) {
        valueUpdated = true;
        type.expirationDate = newExpirationDate;
      }
    });

    if (valueUpdated === true) {
      onFormValueChanged('officialPositionTypes', officialPositionTypes);
    }
  };

  const onSaveClicked = () => {
    setIsDirty(true);
    handleSubmit();
  };

  useEffect(() => {
    const officialPositionTypes = officialPositionTypesData.map(x => {
      return {
        nationalEvaluatorOfficialPositionTypeId: -1,
        officialPositionTypeId: x.officialPositionTypeId,
        nationalEvaluatorTitleId: -1,
        nationalEvaluatorTitle: '',
        positionTypeName: x.positionTypeName,
        positionAbbreviation: x.positionAbbreviation,
        effectiveDate: '',
        displayOrder: x.displayOrder
      };
    });

    setFormData({ ...formState, officialPositionTypes }, false);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized === true) {
      getNationalEvaluator(nationalEvaluatorId)
        .then(data => {
          if (data && data.objData?.evaluatorName) {
            setEvaluatorName(data.objData.evaluatorName);
            const dataObjDataCopy = JSON.parse(JSON.stringify(data.objData));
            const dataObjDataOfficialPositionTypesSortedLatestExpirationDateFirst = { ...dataObjDataCopy, officialPositionTypes: dataObjDataCopy.officialPositionTypes?.sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate)) };

            const newOfficialPositionTypes = formState.officialPositionTypes.map(officialPositionType => {
              const found = dataObjDataOfficialPositionTypesSortedLatestExpirationDateFirst.officialPositionTypes.find(f => f.officialPositionTypeId === officialPositionType.officialPositionTypeId);
              const newOfficialPositionType = found ? { ...found } : { ...officialPositionType };
              newOfficialPositionType.effectiveDate = formatDateYMD(newOfficialPositionType.effectiveDate);
              newOfficialPositionType.expirationDate = formatDateYMD(newOfficialPositionType.expirationDate);
              return newOfficialPositionType;
            });

            setFormData({
              ...formState,
              officialPositionTypes: newOfficialPositionTypes
            }, false);
          };
        });
    }
  }, [initialized]);

  return {
    ...viewState,
    isSubmitting,
    evaluatorName,
    formState,
    errorState,
    onTitleChanged,
    onEffectiveDateChanged,
    onExpirationDateChanged,
    onSaveClicked
  };
};

export default useEditNationalEvaluator;