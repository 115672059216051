export const localValidate = (formState) => {
  let errors = {};

  if (formState.funFactsId < 0) {
    errors.funFactsId = 'Fun Fact Name is required';
  }

  if (!formState.textValue.trim()) {
    errors.textValue = 'Fun Fact Description is required';
  } else if (formState.textValue.trim().length > 7000) {
    errors.textValue = 'Fun Fact Description cannot exceed 7000 characters';
  }
  
  return errors;
};

const ProfileFunFactsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ProfileFunFactsDetailValidation;