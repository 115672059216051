import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../../common/wrappers/ReactRouterDom';

import { EDIT_VIEW_TYPE_ADDITIONAL_OFFERING, EDIT_VIEW_TYPE_MAIN_OFFERING, EDIT_VIEW_TYPE_SINGLE_OFFERING } from '../../../utils/OfferingManagementConstants';

import useOfferingManagementInstanceOrgUnitData from '../../../../../state/offeringManagementInstanceOrgUnit/UseOfferingManagementInstanceOrgUnitData';

import useNavRoutes from '../../../../../../common/state/security/UseNavRoutes';
import useSelectOrgUnitData from '../../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

import { DEFAULT_ID } from '../../../../../../common/utils/Constants';

const INITIAL_STATE = {
  displayDeletePopup: false,
  deletePopupOfferingObj: {},
  offeringInstanceOrgUnitId: DEFAULT_ID,
  offeringInstanceObj: {},
  reportPeriodObj: {},
  orgUnitObj: {},
  viewType: null,
  pageErrorMessage: ''
};

const useLscOfferingInstanceOrgUnitEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { offeringManagementInstanceOrgUnitState, putOfferingInstanceOrgUnit, deleteOfferingInstanceOrgUnit } = useOfferingManagementInstanceOrgUnitData();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmitOfferingInstanceOrgUnitForm = (newOfferingInstanceOrgUnitObj) => {
    const putOfferingInstanceOrgUnitPromise = putOfferingInstanceOrgUnit(newOfferingInstanceOrgUnitObj.offeringInstanceOrgUnitId, newOfferingInstanceOrgUnitObj);

    if (putOfferingInstanceOrgUnitPromise ?? false) {
      putOfferingInstanceOrgUnitPromise.then((newState) => {
        if (newState ?? false) {
          navigate(navRoutes.OFFERING_MANAGMENT_LSC_OFFERINGS?.route)
        }
      }).catch((e) => {
        setState({
          ...state,
          pageErrorMessage: e?.message || 'Failed to update offering'
        })
      });
    }
  };

  const onBackClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_LSC_OFFERINGS?.route)
  };

  const onDeleteClicked = (e, formState) => {
    e?.preventDefault();

    setState({
      ...state,
      displayDeletePopup: true,
      deletePopupOfferingObj: {
        ...state.offeringInstanceObj,
        ...formState
      }
    });
  };

  const onDeletePopupConfirmClicked = (e) => {
    e?.preventDefault();

    //TODO all offering instance org units stop appearing in grid when any are deleted 
    const deleteOfferingInstanceOrgUnitPromise = deleteOfferingInstanceOrgUnit(state.offeringInstanceOrgUnitId);

    if (deleteOfferingInstanceOrgUnitPromise ?? false) {
      deleteOfferingInstanceOrgUnitPromise.then((newState) => {
        if (newState ?? false) {
          navigate(navRoutes.OFFERING_MANAGMENT_LSC_OFFERINGS?.route)
        }
      }).catch((e) => {
        setState({
          ...state,
          displayDeletePopup: false,
          pageErrorMessage: e?.message || 'Failed to delete offering'
        })
      });
    }
  };

  const onDeletePopupCancelClicked = (e) => {
    e?.preventDefault();

    setState({
      ...state,
      displayDeletePopup: false,
      deletePopupOfferingObj: {}
    });
  };

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId && selectOrgUnitState.orgUnitName
      && location.state?.offeringInstance && location.state?.reportPeriod) {
      const newState = {
        ...state,
        offeringInstanceObj: location.state.offeringInstance,
        reportPeriodObj: location.state.reportPeriod,
        orgUnitObj: {
          orgUnitId: selectOrgUnitState.orgUnitId,
          orgUnitName: selectOrgUnitState.orgUnitName
        }
      };

      if (location.state?.offeringInstanceOrgUnitId > 0) {
        setState({
          ...newState,
          offeringInstanceOrgUnitId: location.state.offeringInstanceOrgUnitId,
          viewType: EDIT_VIEW_TYPE_ADDITIONAL_OFFERING
        });
      } else if (Array.isArray(newState.offeringInstanceObj.offeringInstanceOrgUnit) && newState.offeringInstanceObj.offeringInstanceOrgUnit.length > 0) {
        setState({
          ...newState,
          offeringInstanceOrgUnitId: newState.offeringInstanceObj.offeringInstanceOrgUnit[0].offeringInstanceOrgUnitId,
          viewType: EDIT_VIEW_TYPE_MAIN_OFFERING
        });
      } else if (newState.offeringInstanceObj.offeringInstanceOrgUnitId > 0) {
        setState({
          ...newState,
          offeringInstanceOrgUnitId: newState.offeringInstanceObj.offeringInstanceOrgUnitId,
          viewType: EDIT_VIEW_TYPE_SINGLE_OFFERING
        });
      } else {
        setState({
          ...state,
          pageErrorMessage: 'Failed to load offering form'
        });
      }
    } else {
      setState({
        ...state,
        pageErrorMessage: 'Failed to load offering'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSaving: offeringManagementInstanceOrgUnitState.isObjLoading,
    state,
    onSubmitOfferingInstanceOrgUnitForm,
    onBackClicked,
    onDeleteClicked,
    onDeletePopupConfirmClicked,
    onDeletePopupCancelClicked
  };
};

export default useLscOfferingInstanceOrgUnitEdit;