import React, { Fragment } from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ person }) => {
  let orgUnitName = '';
  let effectiveDate = '';
  let expirationDate = '';
  if (person.orgDurations.length > 0) {
    for (let i = 0; i < person.orgDurations.length; i++) {
      if (person.orgDurations[i] !== null) {
        if (orgUnitName === '') {
          orgUnitName = orgUnitName.concat(person.orgDurations[i].orgUnitName);
          effectiveDate = effectiveDate.concat(formatDate(person.orgDurations[i].effectiveDate))
          expirationDate = expirationDate.concat(formatDate(person.orgDurations[i].expirationDate))
        }
        else {
          orgUnitName = orgUnitName.concat('\n' + person.orgDurations[i].orgUnitName);
          effectiveDate = effectiveDate.concat('\n' + formatDate(person.orgDurations[i].effectiveDate))
          expirationDate = expirationDate.concat('\n' + formatDate(person.orgDurations[i].expirationDate))
        }
      }
    }
  }

  return (
    <tr>
      <td>{person.firstName || ''} {person.lastName || ''}</td>
      <td>{person.birthDate ? formatDate(person.birthDate) : ''}</td>
      <td>{person.competitionGender || ''}</td>
      <td>{orgUnitName || ''}</td>
      <td>{effectiveDate || ''}</td>
      <td>{expirationDate || ''}</td>
    </tr>
  );
};

const LargeGrid = ({ state }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Birth Date</th>
        <th>Competition Category</th>
        <th>Organization</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(state.arrayData) && state.arrayData.length
        ? state.arrayData.map((person, i) => <LargeGridRow person={person} key={i} />)
        : <tr><td colSpan="6">No Duplicate Persons</td></tr>
      }
    </tbody>
  </table>
);

const SmallGridRow = ({ person }) => {
  let orgUnitName = '';
  let effectiveDate = '';
  let expirationDate = '';
  if (person.orgDurations.length > 0) {
    for (let i = 0; i < person.orgDurations.length; i++) {
      if (person.orgDurations[i] !== null) {
        if (orgUnitName === '') {
          orgUnitName = orgUnitName.concat(person.orgDurations[i].orgUnitName);
          effectiveDate = effectiveDate.concat(formatDate(person.orgDurations[i].effectiveDate))
          expirationDate = expirationDate.concat(formatDate(person.orgDurations[i].expirationDate))
        }
        else {
          orgUnitName = orgUnitName.concat('\n' + person.orgDurations[i].orgUnitName);
          effectiveDate = effectiveDate.concat('\n' + formatDate(person.orgDurations[i].effectiveDate))
          expirationDate = expirationDate.concat('\n' + formatDate(person.orgDurations[i].expirationDate))
        }
      }
    }
  }

  return (
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{person.firstName || ''} {person.lastName || ''}</span>&nbsp;
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {person.firstName || person.lastName ? `${person.firstName || ''} ${person.lastName || ''}` : <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Birth Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {person.birthDate ? formatDate(person.birthDate) : <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>
            Competition Category
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Comp. Category
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {person.competitionGender || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Organization
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {orgUnitName || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Effective Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {effectiveDate || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Expiration Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {expirationDate || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

const SmallGrid = ({ state }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(state.arrayData) && state.arrayData.length > 0
      ? state.arrayData.map((person, i) => <SmallGridRow person={person} key={i} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>&nbsp;&nbsp;No Duplicate Persons</div>
        </div>
      )
    }
  </div>
);

const DupPersonGrid = ({ state }) => (
  <Fragment>
    <b>Database Matches on First Name, Last Name, and Birth Date</b>
    <LargeGrid state={state} />
    <SmallGrid state={state} />
  </Fragment>
);

export default DupPersonGrid;