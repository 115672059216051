import { useState } from 'react';

import PersonNationalTeamAuditData from './PersonNationalTeamAuditData';

const usePersonNationalTeamAuditData = () => {
  const [personNationalTeamAuditState, setPersonAuditState] = useState(PersonNationalTeamAuditData.INITIAL_STATE);
  const getPersonNationalTeamAudit = (memberId) => PersonNationalTeamAuditData.getPersonNationalTeamAudit(memberId, personNationalTeamAuditState, setPersonAuditState);

  return {
    personNationalTeamAuditState,
    getPersonNationalTeamAudit
  };
};

export default usePersonNationalTeamAuditData;