import React, { Fragment } from 'react';

import useMemberAffiliations from './UseMemberAffiliations';

import MemberAffiliationsGrid from './MemberAffiliationsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const MemberAffiliationsRead = () => {
  const {
    isLoading,
    affiliationsState,
  } = useMemberAffiliations();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Affiliations</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <MemberAffiliationsGrid gridData={affiliationsState.arrayData} isLoading={affiliationsState.isArrayLoading} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default MemberAffiliationsRead;