import { useState } from 'react';
import NationalEvaluatorTitleData from './NationalEvaluatorTitleData';

const useNationalEvaluatorTitleData = () => {
  const [ state, setState ] = useState({ ...NationalEvaluatorTitleData.INITIAL_STATE });

  const getNationalEvaluatorTitles = (includeSelectOption) => {
    return NationalEvaluatorTitleData.getNationalEvaluatorTitlesData(state, setState)
      .then(data => {
        const options = data.arrayData.map(x => {
              return {
                id: x.nationalEvaluatorTitleId,
                name: x.titleName }});
        if (includeSelectOption === true) {
          options.unshift({id: -1, name: 'Select Title'});
        }
        
        setState({
            ...NationalEvaluatorTitleData.INITIAL_STATE,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: data,
            options,
            message: ''});
        
        return { data, options };
      });
  }

  return {
    nationalEvaluatorTitleState: { ...state },
    getNationalEvaluatorTitles
  };
};

export default useNationalEvaluatorTitleData;
