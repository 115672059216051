import React, { createContext, useState } from 'react';

import EthnicityTypeData from './EthnicityTypeData';

export const EthnicityTypeStateContext = createContext();

const EthnicityTypeContextProvider = ({ children }) => {
  const stateHook = useState(EthnicityTypeData.INITIAL_STATE);

  return (
    <EthnicityTypeStateContext.Provider value={stateHook}>
      {children}
    </EthnicityTypeStateContext.Provider>
  );
};

export default EthnicityTypeContextProvider;