import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRowIcons = ({ organizationId, affiliation, onEditClicked, USAS_ORGANIZATION_ID }) => (
  <Fragment>
    {onEditClicked && organizationId !== USAS_ORGANIZATION_ID && Array.isArray(affiliation.personOrgRoleFields) &&
      <button className={global.IconButton}
        type="button"
        onClick={(e) => onEditClicked(e, affiliation.personOrgRoleDurationId)}>
        <EditIcon />
      </button>
    }
  </Fragment >
);

const DetailGridRow = ({ organizationId, affiliation, onEditClicked, USAS_ORGANIZATION_ID }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{affiliation.parentOrgUnitCode || ''} - {affiliation.orgUnitName || ''}</span>&nbsp;
      <DetailGridRowIcons organizationId={organizationId} affiliation={affiliation} onEditClicked={onEditClicked} USAS_ORGANIZATION_ID={USAS_ORGANIZATION_ID} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Team
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {affiliation.parentOrgUnitCode || ''} - {affiliation.orgUnitName || ''}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Member Role
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {affiliation.orgRoleName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {affiliation.effectiveDate ? formatDate(affiliation.effectiveDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {affiliation.expirationDate ? formatDate(affiliation.expirationDate) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ organizationId, affiliations, onEditClicked, USAS_ORGANIZATION_ID }) => (
  <div className={global.SmallTableDetailTable}>
    {affiliations.map((affiliation, i) => <DetailGridRow key={i} organizationId={organizationId} affiliation={affiliation} onEditClicked={onEditClicked} USAS_ORGANIZATION_ID={USAS_ORGANIZATION_ID} />)}
  </div>
);

const SmallGridRowIcons = ({ organization, expandedId, onExpandClicked }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, organization.organizationId)}>
      {expandedId === organization.organizationId ? <HideIcon /> : <ShowIcon />}
    </button>
  </Fragment>
);

const GridRow = ({ organization, expandedId, onEditClicked, onExpandClicked, USAS_ORGANIZATION_ID }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{organization.organizationName || ''}</span>&nbsp;
        <SmallGridRowIcons organization={organization} expandedId={expandedId} onExpandClicked={onExpandClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Organization
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {organization.organizationName || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    {Array.isArray(organization.personAffiliationDurations) && organization.personAffiliationDurations.length > 0 && expandedId === organization.organizationId
      && <DetailGrid organizationId={organization.organizationId} affiliations={organization.personAffiliationDurations} onEditClicked={onEditClicked} USAS_ORGANIZATION_ID={USAS_ORGANIZATION_ID} />
    }
  </Fragment>
);

const MemberAffiliationsGridSmall = ({ gridData, isLoading, expandedId, onEditClicked, onExpandClicked, USAS_ORGANIZATION_ID }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true ? (
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          <div className={global.SmallTableRowLabels}>Loading...</div>
        </div>
      </div>
    ) : Array.isArray(gridData) && gridData.length > 0 ?
      gridData.map((organization, i) => Array.isArray(organization.personAffiliationDurations) && <GridRow key={i} organization={organization} expandedId={expandedId} onEditClicked={onEditClicked} onExpandClicked={onExpandClicked} USAS_ORGANIZATION_ID={USAS_ORGANIZATION_ID} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Affiliations</div>
          </div>
        </div>
      )
    }
  </div>
);

export default MemberAffiliationsGridSmall;

