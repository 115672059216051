import { Fragment } from "react";

import useMemberMergeFormGrid from "./UseMemberMergeFormGrid";

import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

import style from '../MemberMergeTool.module.css';

const SelectionRadioButton = ({ fieldKey, fieldName, fieldValue, isChecked, isDisabled, onMergeSelectionChanged }) => (
  <input
    type="radio"
    name={`${fieldKey}${fieldName}`}
    value={fieldValue}
    checked={isChecked}
    disabled={isDisabled}
    onChange={() => { onMergeSelectionChanged(isChecked, fieldKey, fieldName) }} />
);

const FormGridRow = ({ mergeField, onMergeSelectionChanged }) => (
  <tr>
    <td className={`${style.PersonValue} ${mergeField.isPersonBSelected === false ? style.IsSelected : ''}`}>
      {mergeField.personADisplayValue}
    </td>
    <td className={`${style.RadioButton} ${mergeField.isPersonBSelected === false ? style.IsSelected : ''}`}>
      <SelectionRadioButton
        fieldKey={mergeField.displayValueKey}
        fieldName={`personADisplayValue`}
        fieldValue={mergeField.personADisplayValue}
        isChecked={mergeField.isPersonBSelected === false}
        isDisabled={mergeField.isSelectionDisabled === true}
        onMergeSelectionChanged={onMergeSelectionChanged} />
    </td>
    <td className={style.MergeField}>
      {mergeField.valueLabel}
    </td>
    <td className={`${style.RadioButton} ${mergeField.isPersonBSelected === true ? style.IsSelected : ''}`}>
      <SelectionRadioButton
        fieldKey={mergeField.displayValueKey}
        fieldName={`personBDisplayValue`}
        fieldValue={mergeField.personBDisplayValue}
        isChecked={mergeField.isPersonBSelected === true}
        isDisabled={mergeField.isSelectionDisabled === true}
        onMergeSelectionChanged={onMergeSelectionChanged} />
    </td>
    <td className={`${style.PersonValue} ${mergeField.isPersonBSelected === true ? style.IsSelected : ''}`}>
      {mergeField.personBDisplayValue}
    </td>
  </tr>
);

const MemberMergeFormGrid = ({ personAData, personBData, onMergeClicked, onCancelClicked }) => {
  const {
    isLoading,
    personAName,
    personBName,
    formGridState,
    onSubmitFormGrid,
    onMergeSelectionChanged
  } = useMemberMergeFormGrid(personAData, personBData, onMergeClicked);

  return (
    <Fragment>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <table className={style.MergeTable}>
            <thead>
              <tr>
                <th>{personAName} - Member A</th>
                <th colSpan={3}></th>
                <th>{personBName} - Member B</th>
              </tr>
            </thead>
            <tbody>
              {isLoading
                ? <tr><td colSpan={5}>Loading...</td></tr>
                : formGridState.map((mergeField, i) => <FormGridRow key={i} mergeField={mergeField} onMergeSelectionChanged={onMergeSelectionChanged} />)
              }
            </tbody>
          </table>
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton isDisabled={isLoading} type="button" onClick={() => onSubmitFormGrid(formGridState)}>Merge</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default MemberMergeFormGrid;