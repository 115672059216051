import { useState } from "react";
import { BASIC_INITIAL_STATE } from "../../utils/HttpHelper";

import MemberGroupsData from "./MemberGroupsData";

const MEMBER_GROUPS_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MEMBER_GROUPS_QUALIFICATION_EVENT_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useMemberGroupsData = () => {
  const [memberGroupsState, setMemberGroupsState] = useState(MEMBER_GROUPS_INITIAL_STATE);
  const [memberGroupsQualificationEventState, setMemberGroupQualificationEventState] = useState(MEMBER_GROUPS_QUALIFICATION_EVENT_INITIAL_STATE);

  const postGroupMember = (groupMemberObj) => {
    return MemberGroupsData.postGroupMemberData(groupMemberObj, memberGroupsState, setMemberGroupsState);
  };

  const getMemberGroups = (personId) => {
    return MemberGroupsData.getMemberGroupsData(personId, memberGroupsState, setMemberGroupsState);
  };

  const getPersonOrgGroup = (personOrgGroupId) => {
    return MemberGroupsData.getPersonOrgGroupData(personOrgGroupId, memberGroupsState, setMemberGroupsState);
  };

  const getGroupQualificationEvents = (personOrgGroupQualificationId, isSelected = false) => {
    return MemberGroupsData.getGroupQualificationEventsData(personOrgGroupQualificationId, isSelected, memberGroupsQualificationEventState, setMemberGroupQualificationEventState);
  };

  const putGroupMember = (personOrgGroupId, groupMemberObj) => {
    return MemberGroupsData.putGroupMemberData(personOrgGroupId, groupMemberObj, memberGroupsState, setMemberGroupsState);
  };

  return {
    memberGroupsState,
    memberGroupsQualificationEventState,
    postGroupMember,
    getMemberGroups,
    getPersonOrgGroup,
    getGroupQualificationEvents,
    putGroupMember
  };
};

export default useMemberGroupsData;