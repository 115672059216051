import useApply from './UseApply';

import MultiSelectCheckboxes from '../../../../../common/components/checkboxes/MultiSelectCheckboxes';
import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import RulesList from './RulesList';

import global from '../../../../../common/components/GlobalStyle.module.css';

const Apply = ({ availableCerts, buttonClickHandlers, officialName, memberId }) => {
  const {
    showRules,
    rulePageNumber,
    positionCertificationProgressions,
    checkboxlist,
    formState,
    errorState,
    handleSubmit,
    error,
    message,
    onCheckboxFormValueChanged,
    onApply,
    onBackClicked,
    onNextRuleClicked,
    onBackToPreviousRuleClicked,
    modalState } = useApply(availableCerts, buttonClickHandlers);

  return (
    <div className="row">
      {showRules
        ? (
          <>
            <div className="col-xs-12">
              <RulesList positionCertificationProgressions={positionCertificationProgressions} rulePageNumber={rulePageNumber} onApply={onApply} onBackClicked={onBackClicked}
                onNextRuleClicked={onNextRuleClicked}
                onBackToPreviousRuleClicked={onBackToPreviousRuleClicked}
                buttonClickHandlers={buttonClickHandlers}
                modalState={modalState}
                officialName={officialName}
                memberId={memberId}
                formState={formState} />
            </div>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit} noValidate>
              <div className="col-xs-12 usas-extra-top-margin">
                <Headings.H3>Select Position(s) to Apply for</Headings.H3>
              </div>
              <div className="col-xs-12 usas-extra-top-margin">
                <MultiSelectCheckboxes
                  options={checkboxlist}
                  label={""}
                  name="positionCertificationProgressionIds"
                  value={formState.positionCertificationProgressionIds}
                  error={errorState.positionCertificationProgressionIds}
                  message={errorState.positionCertificationProgressionIds}
                  onChange={(value) => onCheckboxFormValueChanged(value)}
                />
              </div>
              {error && <p className={global.ErrorMessage}>{message}</p>}
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton isDisabled={formState.positionCertificationProgressionIds.length === 0} type="submit" onClick={handleSubmit}>Select</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={buttonClickHandlers.onCancelClicked}>Cancel</SecondaryButton>
              </div>
            </form>
          </>
        )}
    </div>
  );
};

export default Apply;