import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getIsValidMergeByPersonIdsData = (personAId, personBId, state, setState) => {
  if (personAId && personBId) {
    const newState = {
      ...state,
      personAId,
      personBId,
      memberAId: '',
      memberBId: ''
    };

    const api = initApi(API_NAMES.PERSON, newState, setState);
    const personAIdForUrl = encodeURIComponent(personAId);
    const personBIdForUrl = encodeURIComponent(personBId);

    const url = `/Person/mergeValidation?personAId=${personAIdForUrl}&personBId=${personBIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const getIsValidMergeByMemberIdsData = (memberAId, memberBId, state, setState) => {
  if (memberAId && memberBId) {
    const newState = {
      ...state,
      personAId: '',
      personBId: '',
      memberAId,
      memberBId
    };

    const api = initApi(API_NAMES.PERSON, newState, setState);
    const memberAIdForUrl = encodeURIComponent(memberAId);
    const memberBIdForUrl = encodeURIComponent(memberBId);

    const url = `/Person/member/mergeValidation?memberAId=${memberAIdForUrl}&memberBId=${memberBIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const getPersonMergeInfoByPersonIdsData = (personAId, personBId, state, setState) => {
  if (personAId && personBId) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personAIdForUrl = encodeURIComponent(personAId);
    const personBIdForUrl = encodeURIComponent(personBId);

    const url = `/Person/mergeInfo?personAId=${personAIdForUrl}&personBId=${personBIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const getPersonMergeInfoByMemberIdsData = (memberAId, memberBId, state, setState) => {
  if (memberAId && memberBId) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const memberAIdForUrl = encodeURIComponent(memberAId);
    const memberBIdForUrl = encodeURIComponent(memberBId);

    const url = `/Person/member/mergeInfo?memberAId=${memberAIdForUrl}&memberBId=${memberBIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const putPersonMergeData = (personAId, personBId, personMergeObj, state, setState) => {
  if (personAId && personBId && personMergeObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personAIdForUrl = encodeURIComponent(personAId);
    const personBIdForUrl = encodeURIComponent(personBId);

    const url = `/Person/merge?personAId=${personAIdForUrl}&personBId=${personBIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'PUT', personMergeObj) : null;
  }
};

const INITIAL_MEMBER_MERGE_INFO_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_MEMBER_MERGE_VALIDATION_STATE = {
  ...BASIC_INITIAL_STATE,
  personAId: '',
  personBId: '',
  memberAId: '',
  memberBId: ''
};

const INITIAL_MEMBER_MERGE_CONFIRMATION_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberMergeToolData = {
  INITIAL_MEMBER_MERGE_INFO_STATE,
  INITIAL_MEMBER_MERGE_VALIDATION_STATE,
  INITIAL_MEMBER_MERGE_CONFIRMATION_STATE,

  getIsValidMergeByPersonIdsData,
  getIsValidMergeByMemberIdsData,
  getPersonMergeInfoByPersonIdsData,
  getPersonMergeInfoByMemberIdsData,
  putPersonMergeData
};

export default MemberMergeToolData;