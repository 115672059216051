import React, { Fragment } from "react";

import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import EditIcon from "../../../../common/components/icons/EditIcon";
import HideIcon from "../../../../common/components/icons/HideIcon";
import ShowIcon from "../../../../common/components/icons/ShowIcon";

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRowIcons = ({ personId, role, onEdit, onDelete }) => (
  <Fragment>
    {onDelete !== undefined && role.canDelete === true &&
      <button
        className={global.IconButton}
        type="button"
        onClick={() => onDelete(personId, role.personOrgRoleDurationId)}>
        <DeleteIcon />
      </button>
    }
    {onEdit !== undefined && role.isCurrent === true &&
      <button
        className={global.IconButtonMobileMargin}
        type="button"
        onClick={() => onEdit(personId, role.personOrgRoleDurationId)}>
        <EditIcon />
      </button>
    }
  </Fragment>
);

const DetailGridRow = ({ personId, role, onEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{role.orgRoleName || ''}</span>&nbsp;
      <DetailGridRowIcons personId={personId} role={role} onEdit={onEdit} onDelete={onDelete} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Role
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {role.orgRoleName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.effectiveDate || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.expirationDate || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Representative Type
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.representativeTypeValue || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Term Length
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.termLengthValue || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ staff, onEdit, onDelete }) => (
  <div className={global.SmallTableDetailTable}>
    {staff.roleDurations.map((role, i) =>
      <DetailGridRow key={i} personId={staff.personId} role={role} onEdit={onEdit} onDelete={onDelete} />)}
  </div>
);

const GridRowIcons = ({ staff, expandedId, onExpandClicked }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, staff.personId)}>
      {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
    </button>
  </Fragment>
);

const GridRow = ({ staff, onEdit, onDelete, formState, expandedId, onExpandClicked }) => {
  const committeeObJExistisObj = staff?.roleDurations?.find(x => x.orgRoleId === formState?.committeeId);
  if (formState?.committeeId !== '' && committeeObJExistisObj !== undefined) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{staff.name || ''}</span>&nbsp;
            <GridRowIcons staff={staff} expandedId={expandedId} onExpandClicked={onExpandClicked} />
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className="row">
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                HQ Committee Member Name
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {staff.name || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
                Email
              </div>
              <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
                {staff.email || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
                Is In Good Standing Expiration Date
              </div>
              <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
                {staff?.roleDurations[0].isInGoodStandingExpirationDate || <span>&nbsp;</span>}
              </div>
            </div>
          </div>
        </div>
        {Array.isArray(staff.roleDurations) && staff.roleDurations.length > 0 && expandedId === staff.personId
          && <DetailGrid staff={staff} onEdit={onEdit} onDelete={onDelete} />
        }
      </Fragment>
    );
  } else if (formState?.memberName !== '' && formState?.memberName === staff.name) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{staff.name || ''}</span>&nbsp;
            <GridRowIcons staff={staff} expandedId={expandedId} onExpandClicked={onExpandClicked} />
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className="row">
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                HQ Committee Member Name
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {staff.name || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
                Email
              </div>
              <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
                {staff.email || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
                Is In Good Standing Expiration Date
              </div>
              <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
                {staff?.roleDurations[0].isInGoodStandingExpirationDate || <span>&nbsp;</span>}
              </div>
            </div>
          </div>
        </div>
        {Array.isArray(staff.roleDurations) && staff.roleDurations.length > 0 && expandedId === staff.personId
          && <DetailGrid staff={staff} onEdit={onEdit} onDelete={onDelete} />
        }
      </Fragment>
    )
  } else if (formState?.committeeId === '' && formState?.memberName === '') {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{staff.name || ''}</span>&nbsp;
            <GridRowIcons staff={staff} expandedId={expandedId} onExpandClicked={onExpandClicked} />
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className="row">
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                HQ Committee Member Name
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {staff.name || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
                Email
              </div>
              <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
                {staff.email || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
                Is In Good Standing Expiration Date
              </div>
              <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
                {staff?.roleDurations[0].isInGoodStandingExpirationDate || <span>&nbsp;</span>}
              </div>
            </div>
          </div>
        </div>
        {Array.isArray(staff.roleDurations) && staff.roleDurations.length > 0 && expandedId === staff.personId
          && <DetailGrid staff={staff} onEdit={onEdit} onDelete={onDelete} />
        }
      </Fragment>)
  } else {
    return (
      <Fragment />
    )
  }
};
const HQCommitteeMembersGridSmall = ({ data, isLoading, onEdit, onDelete, formState, expandedId, onExpandClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>&nbsp;&nbsp;Loading...</div>
        </div>
      )
      : data.length > 0
        ? data.map((staff, i) =>
          <GridRow
            key={i}
            staff={staff}
            onEdit={onEdit}
            onDelete={onDelete}
            formState={formState}
            expandedId={expandedId}
            onExpandClicked={onExpandClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>&nbsp;&nbsp;No HQ Committee Members</div>
          </div>
        )
    }
  </div>
);

export default HQCommitteeMembersGridSmall;