import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getBaseMemberData from './GetBaseMemberData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const BaseMemberData = {
  INITIAL_STATE, getBaseMemberData
};

export default BaseMemberData;