import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const useMiniMemberMergeSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();

  const onSuccessfulMerge = () => {
    navigate(navRoutes.MEMBER_MERGE?.route);
  };

  const onCancelMergeClicked = () => {
    navigate(navRoutes.MEMBER_MERGE?.route);
  };

  return {
    memberAId: location.state?.memberAId,
    memberBId: location.state?.memberBId,
    onSuccessfulMerge,
    onCancelMergeClicked
  };
};

export default useMiniMemberMergeSelection;