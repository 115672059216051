import React, { Fragment } from 'react';

import OtsOfficialOnlineTestsGridLarge from './OtsOfficialOnlineTestsGridLarge';
import OtsOfficialOnlineTestsGridSmall from './OtsOfficialOnlineTestsGridSmall';

const OtsOfficialOnlineTestsGrid = ({isLoading, gridData}) => {
  return (
    <Fragment>
      <OtsOfficialOnlineTestsGridLarge gridData={gridData} />
      <OtsOfficialOnlineTestsGridSmall gridData={gridData} />
    </Fragment>
  );
};

export default OtsOfficialOnlineTestsGrid;