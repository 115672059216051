import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './MemberRequirmentsDetailValidation';

import usePersonRequirementsData from '../../../../state/member/memberRequriements/UsePersonRequriementsData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useEnrollmentData from '../../../../../common/state/lmsEnrollment/UseEnrollmentData';
import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';

import useForm from '../../../../../common/utils/UseForm';
import { formatDate, getTomorrowsDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  requirementName: '',
  requirementId: Constants.DEFAULT_ID,
  courseCompletionDate: Constants.BLANK_DATE_STRING,
  courseExpirationDate: Constants.BLANK_DATE_STRING,
  noExpirationDate: false,
  hasExemption: false
};

const useMemberRequirementsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { enrollmentState } = useEnrollmentData();
  const { basePersonState } = useBasePersonData();
  const { adminEnrollmentState, postEnrollmentMemberRequirmentAdmin } = usePersonRequirementsData();
  const { formState, errorState, setFormData, onFormValueChanged, handleSubmit } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const setCourseExpirationDate = () => {
    let courseExpirationDate;
    if(formState.noExpirationDate === false){
      courseExpirationDate = Constants.DEFAULT_MAX_DATE
    } else {
      courseExpirationDate = formState.courseExpirationDate
    }
    
    return courseExpirationDate
  }

  function submitFormCallback() {
    const courseId = formState?.course?.lmsCourseId;
    const personId = basePersonState?.objData?.personId;
    const courseExpirationDate = setCourseExpirationDate();
    const dateObj = {
      courseCompletionDate: formState.courseCompletionDate,
      courseExpirationDate: courseExpirationDate,
      hasExemption: formState.hasExemption
    }

    postEnrollmentMemberRequirmentAdmin(courseId, personId, dateObj);
  };

  const onCancelClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_REQUIREMENTS?.route);
  };


  useEffect(() => {
    if (adminEnrollmentState?.isSaved === true) {

      navigate(navRoutes.MEMBER_REQUIREMENTS?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminEnrollmentState?.isSaved]);


  useEffect(() => {
    if (Object?.keys(location?.state?.course)?.length > 0) {
      const name = location?.state?.course?.courseName;
      const code = location?.state?.course?.courseCode;
      const nameCode = `${name}  (${code})`
      const initialCourseExpirationDateIfNotSet = getTomorrowsDate();
      
      setFormData({
        ...formState,
        requirementName: nameCode,
        courseExpirationDate: formatDate(initialCourseExpirationDateIfNotSet),
        course: location?.state?.course,
        hasExemption: location?.state?.course.hasExemption
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleSubmit,
    formState,
    errorState,
    onFormValueChanged,
    onCancelClicked,
    enrollmentState,
    adminEnrollmentState
  };
};

export default useMemberRequirementsDetail;