import useOtsReporting from './UseOtsReporting';

import ReportList from '../../../../common/components/embeddedReports/ReportList';

const OtsReporting = () => {
  const { reportParameters, routeName } = useOtsReporting();

  return (
    <ReportList listTitle="OTS Reports"
      routeName={routeName}
      reportParameters={reportParameters} />
  );
};

export default OtsReporting;