import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MemberMergeSearchByMemberIdsValidation';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import useForm from '../../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  memberAId: '',
  memberBId: ''
};

const UseMemberMergeSearchByMemberIds = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { formState, errorState, isSubmitting, handleSubmit, updateFormState, setFormState, setErrors
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const clearForm = () => {
    setFormState(INITIAL_FORM_STATE);
    setErrors({});
  };
  
  function onSubmitFormCallback(formState) {
    const memberAId = formState.memberAId;
    const memberBId = formState.memberBId;

    if (memberAId && memberBId) {
      navigate(navRoutes.MEMBER_MERGE_SELECTION_MINI?.route, { state: { memberAId, memberBId } })
    }
  }

  return {
    isLoading: isSubmitting,
    formState,
    errorState,
    clearForm,
    handleSubmit,
    onFormValueChanged: updateFormState
  };
};

export default UseMemberMergeSearchByMemberIds;