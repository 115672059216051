import { isValidName, isValidDate, isValidBirthDate, isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber, isValidEmail } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.supportStaffTypeId < 0) {
    errors.supportStaffTypeId = 'Support Staff Role is required';
  }

  if (!formState.firstName.trim()) {
    errors.firstName = 'First Name is required'
  } else if (!isValidName(formState.firstName.trim())) {
    errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (!formState.lastName.trim()) {
    errors.lastName = 'Last Name is required'
  } else if (!isValidName(formState.lastName.trim())) {
    errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  if (formState.email.trim() !== '') {
    if (!isValidEmail(formState.email.trim())) {
      errors.email = 'Email must be in a valid format';
    } else if (formState.email.trim().length > 512) {
      errors.email = 'Email cannot exceed 512 characters'
    }
  }

  //Phone Number
  if (formState.phoneNumber.trim() !== '' && formState.phoneNumber.trim() !== Constants.DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== Constants.DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO) {
    if (!isValidUSOrCanadianPhoneNumber(formState.phoneNumber) && !isValidMexicanPhoneNumber(formState.phoneNumber)) {
      errors.phoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format'
    }
  }

  //Effective date
  if (formState.effectiveDate !== Constants.BLANK_DATE_STRING) {
    if (!isValidDate(formState.effectiveDate)) {
      errors.effectiveDate = 'Effective Date must be a valid date';
    } else if (!isValidBirthDate(formState.effectiveDate)) {
      errors.effectiveDate = 'Effective Date cannot be in the future';
    }
  }

  //Expiration date
  if (formState.expirationDate !== Constants.BLANK_DATE_STRING) {
    if (!isValidDate(formState.expirationDate)) {
      errors.expirationDate = 'Expiration Date must be a valid date';
    }
  }

  const startDate = new Date(formState.effectiveDate);
  const endDate = new Date(formState.expirationDate);

  //Effective Date
  if (formState.effectiveDate !== Constants.BLANK_DATE_STRING) {
    if (endDate < startDate && formState.effectiveDate !== formState.expirationDate) {
      errors.effectiveDate = 'Effective Date must be before the Expiration Date';
    }
  }

  //Expiration Date
  if (formState.expirationDate !== Constants.BLANK_DATE_STRING) {
    if (endDate < startDate && formState.effectiveDate !== formState.expirationDate) {
      errors.expirationDate = 'Expiration Date must be after the Effective Date';
    }
  }

  return errors;
};

const NationalTeamSupportStaffDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default NationalTeamSupportStaffDetailValidation;