import useMemberMergeConfirmationPopup from "./UseMemberMergeConfirmationPopup";

import MemberMergeConfirmationGrid from "../grids/MemberMergeConfirmationGrid";

import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

const MemberMergeConfirmationPopup = ({ personMergeObj, onConfirmMergeClicked, onClosePopupClicked }) => {
  const {
    gridState
  } = useMemberMergeConfirmationPopup(personMergeObj);

  return (
    <PopUpModal
      widthPct={90}
      maxWidth={750}
      title={"Merge Confirmation"}
      displayPopUp={true}
      onModalCanceled={onClosePopupClicked}>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <p>Please confirm that you want to complete a merge with the below member information.</p>
          <p><b>The merge will delete Member B.</b></p>
        </div>
        <div className="col-xs-12 usas-extra-bottom-margin">
          <MemberMergeConfirmationGrid gridData={gridState} isLoading={gridState.length === 0} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={() => onConfirmMergeClicked()}>Yes, Merge</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onClosePopupClicked}>Cancel</SecondaryButton>
        </div>
      </div>
    </PopUpModal>
  );
};

export default MemberMergeConfirmationPopup;