
import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useOtsMeetOfficialsData from '../../../../state/officialsTrackingSystemMeetOfficials/UseOtsMeetOfficialsData';
import useMeetEvaluatorPositionLevelData from '../../../../state/otsMeetEvaluatorPositionLevel/UseMeetEvaluatorPositionLevelData';
import useOfficialSelectionForEvaluationData from '../../../../state/otsOfficialSelectionForEvaluations/UseOfficialSelectionForEvaluationData';
import useOtsMeetOfficialData from '../../../../state/otsMeetOfficial/UseOtsMeetOfficialData';
import useOtsPositionWorkedEvaluationLevelEvaluatorNameData from '../../../../state/otsPositionWorkedEvaluationLevelEvaluatorName/UseOtsPositionWorkedEvaluationLevelEvaluatorNameData';
import useMeetEvaluationData from '../../../../state/otsMeetEvaluations/UseMeetEvaluationData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../../common/utils/Constants';
import useForm from '../../../../../common/utils/UseForm';

const INITIAL_VIEW_STATE = {
  tryRedirect: false,
  showError: false
};

const useOtsMeetEvaluationsDetail = () => {
  const navigate = useNavigate();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { otsMeetOfficialsState, clearOtsMeetOfficialsArrayData } = useOtsMeetOfficialsData();
  const { officialSelectionForEvaluationState, getOfficialsForEvaluationSelection, postOfficialSelectionForEvaluation, confirmOfficialSelectionForEvaluationSave } = useOfficialSelectionForEvaluationData();
  const { MeetEvaluatorPositionLevelState, SetMeetEvaluatorPositionLevelState, getMeetEvaluatorPositionLevelsByOtsMeetId } = useMeetEvaluatorPositionLevelData();
  const { otsMeetOfficialState } = useOtsMeetOfficialData();
  const { otsPositionWorkedEvaluationLevelEvaluatorNameState } = useOtsPositionWorkedEvaluationLevelEvaluatorNameData();
  const { meetEvaluationState } = useMeetEvaluationData();
  const { navRoutes } = useNavRoutes();
  const [gridState, setGridState] = useState({ arrayData: [] });
  const { formState, errorState, setErrors, handleSubmit, setIsDirty } = useForm({}, submitFormCallback, () => { });
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [isLarge, setIsLarge] = useState(window.innerWidth > 991);

  const onAddOverrideEvaluationClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
    arrayDataCopy.push({
      isOverride: true,
      personId: Constants.DEFAULT_ID,
      selectedMeetOfficialId: Constants.DEFAULT_ID,
      selectedMeetOfficialName: '',
      selectedOfficialPositionTypeId: Constants.DEFAULT_ID,
      selectedOfficialPositionTypeName: '',
      selectedMeetEvaluatorId: Constants.DEFAULT_ID,
      selectedMeetEvaluatorName: '',
      selectedEvaluationLevelId: Constants.DEFAULT_ID,
      selectedEvaluationLevelName: ''
    });

    setGridState({
      ...gridState,
      arrayData: arrayDataCopy
    });
  }

  // const onAddOverrideEvaluationClicked = (e) => {
  //   if (e?.preventDefault) {
  //     e.preventDefault();
  //   }

  //   navigate(navRoutes.OTS_MEET_EVALUATIONS_OVERRIDE?.route);
  // };

  const onSaveClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    handleSubmit();
  }

  const onBackClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_EVALUATIONS?.route);
  };

  const onUpdateEvaluatorNameValueChange = (e, official, value, valueLabel) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      const selectedIndex = arrayDataCopy.findIndex(x => (x.meetOfficialId === official.meetOfficialId && x.officialPositionTypeId === official.officialPositionTypeId));
      arrayDataCopy[selectedIndex].selectedMeetEvaluatorId = value;
      arrayDataCopy[selectedIndex].selectedMeetEvaluatorName = valueLabel;
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  };

  const onUpdateEvaluationLevelValueChange = (e, official, value, valueLabel) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      const selectedIndex = arrayDataCopy.findIndex(x => (x.meetOfficialId === official.meetOfficialId && x.officialPositionTypeId === official.officialPositionTypeId));
      arrayDataCopy[selectedIndex].selectedEvaluationLevelId = value;
      arrayDataCopy[selectedIndex].selectedEvaluationLevelName = valueLabel;
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  };

  const onUpdateOfficialNameValueChange = (i, value, valueLabel) => {
    const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
    const selectedIndex = i;

    const selectedPersonId = otsMeetOfficialState.arrayData?.find(x => x.meetOfficialId === value)?.personId;
    arrayDataCopy[selectedIndex].personId = selectedPersonId;
    arrayDataCopy[selectedIndex].selectedMeetOfficialId = value;
    arrayDataCopy[selectedIndex].selectedMeetOfficialName = valueLabel;
    setGridState({ ...gridState, arrayData: arrayDataCopy });
  };

  const onUpdateOfficialPositionWorkedValueChange = (i, value, valueLabel) => {
    const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
    const selectedIndex = i;
    arrayDataCopy[selectedIndex].selectedOfficialPositionTypeId = value;
    arrayDataCopy[selectedIndex].selectedOfficialPositionTypeName = valueLabel;
    arrayDataCopy[selectedIndex].selectedMeetEvaluatorId = Constants.DEFAULT_ID;
    arrayDataCopy[selectedIndex].selectedMeetEvaluatorName = '';
    arrayDataCopy[selectedIndex].selectedEvaluationLevelId = Constants.DEFAULT_ID;
    arrayDataCopy[selectedIndex].selectedEvaluationLevelName = '';
    setGridState({ ...gridState, arrayData: arrayDataCopy });
  };

  const onUpdateOverrideEvaluatorNameValueChange = (e, i, value, valueLabel) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      const selectedIndex = i;
      arrayDataCopy[selectedIndex].selectedMeetEvaluatorId = value;
      arrayDataCopy[selectedIndex].selectedMeetEvaluatorName = valueLabel;
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  };

  const onUpdateOverrideEvaluationLevelValueChange = (e, i, value, valueLabel) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      const selectedIndex = i;
      arrayDataCopy[selectedIndex].selectedEvaluationLevelId = value;
      arrayDataCopy[selectedIndex].selectedEvaluationLevelName = valueLabel;
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  };

  const updateMedia = () => {
    setIsLarge(window.innerWidth > 991);
  };

  useEffect(() => {
    //deal with combobox option issue by setting a state to use on a
    // ternary for the large and small selection grids
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    getOfficialsForEvaluationSelection(otsMeetHeaderState.objData?.otsMeetId, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetHeaderState.objData?.otsMeetId])

  useEffect(() => {
    getMeetEvaluatorPositionLevelsByOtsMeetId(otsMeetHeaderState.objData?.otsMeetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetHeaderState.objData?.otsMeetId])

  useEffect(() => {
    if (MeetEvaluatorPositionLevelState.isArrayLoading !== true && MeetEvaluatorPositionLevelState.isArrayLoaded === true &&
      MeetEvaluatorPositionLevelState.options?.length < 1) {
      const evaluators = MeetEvaluatorPositionLevelState.arrayData;
      SetMeetEvaluatorPositionLevelState({
        ...MeetEvaluatorPositionLevelState, options: evaluators
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MeetEvaluatorPositionLevelState]);

  useEffect(() => {
    if (officialSelectionForEvaluationState.isArrayLoaded === true) {
      let arrayDataCopy = JSON.parse(JSON.stringify(officialSelectionForEvaluationState.arrayData));
      let formattedArrayData = arrayDataCopy.map((x) => {
        let evaluationLevels = [{ id: Constants.DEFAULT_ID, name: '--' }];
        x.evaluationLevel?.forEach(y => {
          evaluationLevels.push({ id: y.evaluationLevelId, name: y.evaluationLevelName });
        });
        return (
          {
            ...x,
            isOverride: false,
            evaluationLevels,
            selectedMeetEvaluatorId: Constants.DEFAULT_ID,
            selectedMeetEvaluatorName: '',
            selectedEvaluationLevelId: Constants.DEFAULT_ID,
            selectedEvaluationLevelName: '',
            uniqueMeetEvaluatorDropdownId: "evaluator" + x?.meetOfficialId + "meetOfficialId" + x?.officialPositionTypeId + "officialPositionTypeId",
            uniqueEvaluationLevelDropdownId: "evaluationLevel" + x?.meetOfficialId + "meetOfficialId" + x?.officialPositionTypeId + "officialPositionTypeId"
          }
        );
      })
      setGridState({ ...gridState, arrayData: formattedArrayData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officialSelectionForEvaluationState.isArrayLoaded])

  useEffect(() => {
    if (state.tryRedirect === true && (officialSelectionForEvaluationState.isSaved === true ||
      (officialSelectionForEvaluationState.isSaved === false && officialSelectionForEvaluationState.isSaving === false))) {
      clearOtsMeetOfficialsArrayData();
      confirmOfficialSelectionForEvaluationSave();
      navigate(navRoutes.OTS_MEET_EVALUATIONS?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, officialSelectionForEvaluationState]);

  function submitFormCallback(formState) {
    const officialSelectionForEvaluationArray = JSON.parse(JSON.stringify(gridState.arrayData));
    const officialSelectionForEvaluationArrayFormatted = officialSelectionForEvaluationArray.map((x) => ({
      meetOfficialId: x.meetOfficialId || x.selectedMeetOfficialId,
      officialPositionTypeId: x.officialPositionTypeId || x.selectedOfficialPositionTypeId,
      meetEvaluatorId: x.selectedMeetEvaluatorId,
      evaluationLevelId: x.selectedEvaluationLevelId,
      isOverride: x.isOverride
    }));

    let error = false;
    let evaluatorSelectionError = false;
    let duplicateErrorInGrid = false;
    let duplicateErrorInExistingData = false;

    for (let i = 0; i < officialSelectionForEvaluationArrayFormatted.length; i++) {
      //Check to see if there are any rows where only one dropdown has a selection
      if (officialSelectionForEvaluationArrayFormatted[i].isOverride === false) {
        if (officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId > 0 && officialSelectionForEvaluationArrayFormatted[i].evaluationLevelId < 0 ||
          officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId < 0 && officialSelectionForEvaluationArrayFormatted[i].evaluationLevelId > 0) {
          error = true;
        }
      }
      else if (officialSelectionForEvaluationArrayFormatted[i].isOverride === true) {
        if ((officialSelectionForEvaluationArrayFormatted[i].meetOfficialId < 0 || officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId < 0 ||
          officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId < 0 || officialSelectionForEvaluationArrayFormatted[i].evaluationLevelId < 0) && (
            officialSelectionForEvaluationArrayFormatted[i].meetOfficialId > 0 || officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId > 0 ||
            officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId > 0 || officialSelectionForEvaluationArrayFormatted[i].evaluationLevelId > 0)
        ) {
          error = true;
        }
      }

      if (officialSelectionForEvaluationArrayFormatted[i].isOverride === false) {
        if (officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId > 0) {
          //Check to see if there are any rows where the official and the selected evaluator are the same
          const selectedOfficialPersonId = officialSelectionForEvaluationArray.find(x => (x.meetOfficialId === officialSelectionForEvaluationArrayFormatted[i].meetOfficialId) && (x.officialPositionTypeId === officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId))?.personId;

          const selectedMeetEvaluatorPosition = MeetEvaluatorPositionLevelState.arrayData?.find(x => x.officialPositionTypeId === officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId);
          const selectedMeetEvaluatorPersonId = selectedMeetEvaluatorPosition?.evaluator?.find(x => x.meetEvaluatorId === officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId)?.personId;

          if (selectedMeetEvaluatorPersonId === selectedOfficialPersonId) {
            evaluatorSelectionError = true;
          }
        }
      }
      else if (officialSelectionForEvaluationArrayFormatted[i].isOverride === true) {
        if (officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId > 0) {
          //Check to see if there are any rows where the official and the selected evaluator are the same
          const selectedOfficialPersonId = officialSelectionForEvaluationArray.find(x => (x.selectedMeetOfficialId === officialSelectionForEvaluationArrayFormatted[i].meetOfficialId) && (x.selectedOfficialPositionTypeId === officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId))?.personId;

          const selectedMeetEvaluatorPosition = otsPositionWorkedEvaluationLevelEvaluatorNameState.arrayData?.find(x => x.officialPositionTypeId === officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId);
          const selectedMeetEvaluatorPersonId = selectedMeetEvaluatorPosition?.evaluator?.find(x => x.meetEvaluatorId === officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId)?.personId;

          if (selectedMeetEvaluatorPersonId === selectedOfficialPersonId) {
            evaluatorSelectionError = true;
          }
        }
      }

      //Check to see if there are any grid rows that are duplicates of other grid rows
      let officialSelectionForEvaluationArrayFormattedWithoutCurrentSelection = JSON.parse(JSON.stringify(officialSelectionForEvaluationArrayFormatted))?.filter((x, index) => index !== i);
      let findDupeInGrid = officialSelectionForEvaluationArrayFormattedWithoutCurrentSelection.find(x => (x.meetOfficialId === officialSelectionForEvaluationArrayFormatted[i].meetOfficialId) &&
        (x.officialPositionTypeId === officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId) &&
        (x.meetEvaluatorId === officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId) &&
        (x.evaluationLevelId === officialSelectionForEvaluationArrayFormatted[i].evaluationLevelId));
      if (findDupeInGrid) {
        duplicateErrorInGrid = true;
      }

      //Check to see if there are any grid rows that are duplicates with existing evaluations
      let meetEvaluationStateArrayDataCopy = JSON.parse(JSON.stringify(meetEvaluationState.arrayData));
      let findDupeInExistingData = meetEvaluationStateArrayDataCopy.find(x => (x.meetOfficialId === officialSelectionForEvaluationArrayFormatted[i].meetOfficialId) &&
        (x.officialPositionTypeId === officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId) &&
        (x.meetEvaluatorId === officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId) &&
        (x.evaluationLevelId === officialSelectionForEvaluationArrayFormatted[i].evaluationLevelId));
      if (findDupeInExistingData) {
        duplicateErrorInExistingData = true;
      }
    }

    if (error === false && evaluatorSelectionError === false && duplicateErrorInGrid === false && duplicateErrorInExistingData === false) {
      //Filter out any blank rows and POST
      const officialSelectionForEvaluationArrayFormattedFiltered = officialSelectionForEvaluationArrayFormatted.filter(x => (x.meetOfficialId >= 0 && x.officialPositionTypeId >= 0 && x.meetEvaluatorId >= 0 && x.evaluationLevelId >= 0));
      const officialSelectionForEvaluationArrayFormattedFilteredWithoutIsOverride = officialSelectionForEvaluationArrayFormattedFiltered.map((x) => ({
        meetOfficialId: x.meetOfficialId,
        officialPositionTypeId: x.officialPositionTypeId,
        meetEvaluatorId: x.meetEvaluatorId,
        evaluationLevelId: x.evaluationLevelId
      }));
      if (officialSelectionForEvaluationArrayFormattedFilteredWithoutIsOverride?.length > 0) {
        postOfficialSelectionForEvaluation(otsMeetHeaderState.objData?.otsMeetId, officialSelectionForEvaluationArrayFormattedFilteredWithoutIsOverride);
      }
      setState({ ...state, tryRedirect: true });
    }
    else {
      if (evaluatorSelectionError === true || duplicateErrorInGrid === true || duplicateErrorInExistingData === true) {
        let generalErrorMsg = '';
        if (evaluatorSelectionError === true) {
          generalErrorMsg = 'An Official cannot have themselves as an Evaluator. Please make the selected Evaluator(s) different from the listed Official(s).'
        }
        if (duplicateErrorInGrid === true) {
          generalErrorMsg += generalErrorMsg !== '' ? `\nAt least one of the grid rows has the same Official Name, Position Worked, Evaluation Level, and Evaluator Name as at least one other grid row. Duplicate evaluations are not allowed.` : 'At least one of the grid rows has the same Official Name, Position Worked, Evaluation Level, and Evaluator Name as at least one other grid row. Duplicate evaluations are not allowed.'
        }
        if (duplicateErrorInExistingData === true) {
          generalErrorMsg += generalErrorMsg !== '' ? `\nAt least one of the grid rows has the same Official Name, Position Worked, Evaluation Level, and Evaluator Name as an existing evaluation. Duplicate evaluations are not allowed.` : 'At least one of the grid rows has the same Official Name, Position Worked, Evaluation Level, and Evaluator Name as an existing evaluation. Duplicate evaluations are not allowed.'
        }
        setErrors({ ...errorState, general: generalErrorMsg });
      }

      else {
        setErrors({});
      }
      setState({ ...state, showError: error });
    }
  }

  return {
    ...state,
    gridState,
    otsMeetHeaderState,
    officialSelectionForEvaluationState,
    MeetEvaluatorPositionLevelState,
    otsPositionWorkedEvaluationLevelEvaluatorNameState,
    isLoading: officialSelectionForEvaluationState.isArrayLoading || otsMeetOfficialsState.isArrayLoading ||
      MeetEvaluatorPositionLevelState.isArrayLoading || otsMeetOfficialState.isArrayLoading || otsPositionWorkedEvaluationLevelEvaluatorNameState.isArrayLoading,
    isSaving: officialSelectionForEvaluationState.isSaving,
    formState,
    errorState,
    onUpdateOfficialNameValueChange,
    onUpdateOfficialPositionWorkedValueChange,
    onUpdateEvaluatorNameValueChange,
    onUpdateOverrideEvaluatorNameValueChange,
    onUpdateEvaluationLevelValueChange,
    onUpdateOverrideEvaluationLevelValueChange,
    handleSubmit,
    Constants,
    onSaveClicked,
    onBackClicked,
    onAddOverrideEvaluationClicked,
    isLarge
  };
}

export default useOtsMeetEvaluationsDetail;