import React from "react";

import global from '../../../common/components/GlobalStyle.module.css'
import style from './Checkbox.module.css';
import useCheckbox from "./UseCheckbox";

const MultipleCheckbox = ({ label, name, disabled, checked = false, onChange, error, message, id }) => {
  const {isDisabled} = useCheckbox('', error, style, global, disabled);

  return (
    <div className={style.Container}>
      <label htmlFor={name}></label>
      <input
        disabled={isDisabled}
        className={style.Checkbox}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        id={id}
      />
      <span>{label}</span>
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </div>
  );
}

export default MultipleCheckbox;