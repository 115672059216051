import React, { Fragment } from 'react';

import Headings from '../../../../../common/components/headings/Headings';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Input from '../../../../../common/components/inputs/Input';
import ActiveClubCombobox from '../../../../../common/components/comboboxes/activeClubCombobox/ActiveClubCombobox';

import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const CurrentClubInfo = ({ transferState }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <Headings.H4>Current Club Info</Headings.H4>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Club"
          name="currentOrgUnitName"
          value={transferState.currentOrgUnitName || ''} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Member Role"
          name="orgRoleName"
          value={transferState.orgRoleName || ''} />
      </div>
      {transferState.lastMeet?.competitionDate && transferState.lastMeet?.competitionDate !== Constants.BLANK_DATE_STRING
        && (
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Last Competition Date Representing Club"
              name="competitionDate"
              value={transferState.lastMeet.competitionDate ? formatDate(transferState.lastMeet.competitionDate) : ''} />
          </div>
        )
      }
      {transferState.lastMeet?.meetName
        && (
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Meet Name"
              name="meetName"
              value={transferState.lastMeet.meetName || ''} />
          </div>
        )
      }
      {transferState.lastMeet?.city
        && (
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="City"
              name="city"
              value={transferState.lastMeet.city || ''} />
          </div>
        )
      }
      {transferState.lastMeet?.stateCode
        && (
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="State"
              name="stateCode"
              value={transferState.lastMeet.stateCode || ''} />
          </div>
        )
      }
    </div>
  </Fragment>
);

const MemberClubTransferMemberForm = ({ transferState, formState, errorState, onFormValueChanged, onValueTextPairChanged,
  handleSubmit, onBackClicked, clubTransferNotice }) => (
  <Fragment>
    <div>
      <CurrentClubInfo transferState={transferState} />
    </div>
    <form onSubmit={handleSubmit}>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H4>Transfer Form</Headings.H4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ActiveClubCombobox
            label="New Club*"
            name="transferOrgUnitId"
            valueToMatch={formState.transferOrgUnitName}
            error={errorState.transferOrgUnitId}
            message={errorState.transferOrgUnitId}
            onChange={(newValue, newValueLabel, e) => {
              onValueTextPairChanged(newValue, 'transferOrgUnitId', newValueLabel, 'transferOrgUnitName', e);
            }} />
        </div>
        <div className="col-xs-12">
          <p>{clubTransferNotice.part1}</p>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="First and Last Name*"
            name="transferSignature"
            value={formState.transferSignature}
            error={errorState.transferSignature}
            message={errorState.transferSignature}
            onChange={(value) => { onFormValueChanged('transferSignature', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Date"
            name="transferSignatureDate"
            value={formState.transferSignatureDate ? formatDate(formState.transferSignatureDate) : ''} />
        </div>
        <div className="col-xs-12">
          <p>{clubTransferNotice.part2}</p>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Submit Transfer</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
    </form>
  </Fragment>
);

export default MemberClubTransferMemberForm;