/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';



const DetailTableSingleRow = ({ split }) => (
  <tr>
    <td>{split.splitNumber || ''}</td>
    <td>{split.cumulativeDistance || ''}</td>
    <td>{split.splitTime ? formatTimeForDisplay(split.splitTime) : ''}</td>
  </tr>
);

const GridRowDetailTableSingle = ({ splits }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Split</th>
        <th>Cumulative Distance</th>
        <th>Time</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(splits) && splits.map((split, i) => <DetailTableSingleRow key={i} split={split} />)}
    </tbody>
  </table>
);

const GridRow = ({ time, expandedId, onEdit, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td><a onClick={() => onEdit(time.swimTimeId)}>{time.time || 'Time'}</a></td>
      <td>{time.competitor || ''}</td>
      <td>{time.orgUnitName}</td>
      <td>{time.meetName || ''}</td>
      <td>{time.swimDate || ''}</td>
      <td>{time.eventCourse || ''} {time.eventGender || ''}</td>
      <td>{time.standardName}</td>
      <td>{time.session || ''} {time.finishPosition || ''}</td>
      <td>
        <button
          className={global.IconButton}
          type="button"
          onClick={(e) => onExpandClicked(e, time.swimTimeId)}>
          {expandedId === time.swimTimeId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {expandedId === time.swimTimeId &&
      <tr className={global.Expanded}>
        {Array.isArray(time.splits) && time.splits.length > 0
          ? <td colSpan="7">
            <GridRowDetailTableSingle splits={time.splits} />
          </td>
          : <td colSpan="7">
            <table className={global.DetailTable}>
              <thead>
                <tr>
                  <th>No Split Data</th>
                </tr>
              </thead>
            </table>
          </td>
        }
      </tr>
    }
  </Fragment>
);

const MemberTimesLargeGrid = ({ gridData, expandedId, isLoading, onEdit, onExpandClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Time'} columnField={'time'}
            sortType={SGConstants.SORT_TYPE_TIME} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Competitor'} columnField={'competitor'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Team'} columnField={'OrgUnitName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Date'} columnField={'swimDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Event'} columnField={'eventId'}
            sortType={SGConstants.SORT_TYPE_LENGTH} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Standard'} columnField={'standardName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Session</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="7">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0 ?
            sortableGridState.sortedGridData.map((time, i) => <GridRow key={i} time={time} expandedId={expandedId} onEdit={onEdit} onExpandClicked={onExpandClicked} />)
            :
            <tr><td colSpan="7">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default MemberTimesLargeGrid;
