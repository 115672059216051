import { Fragment } from 'react';

import useOtsMeetEvaluationsOverride from './UseOtsMeetEvaluationsOverride';

import OfficialSelectionForEvaluationOverrideGrid from './OfficialSelectionForEvaluationOverrideGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsMeetEvaluationsOverride = () => {
  const {
    showError,
    gridState,
    officialSelectionForEvaluationState,
    otsPositionWorkedEvaluationLevelEvaluatorNameState,
    otsMeetHeaderState,
    isLoading,
    isSaving,
    errorState,
    onUpdateOfficialNameValueChange,
    onUpdateOfficialPositionWorkedValueChange,
    onUpdateEvaluatorNameValueChange,
    onUpdateEvaluationLevelValueChange,
    handleSubmit,
    Constants,
    onSaveClicked,
    onBackClicked,
    isLarge,
    onAddOverrideEvaluationClicked
  } = useOtsMeetEvaluationsOverride();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <Headings.H3>Add Override Evaluation</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-bottom-margin">
          <Headings.H5>To add an override evaluation for an official's position worked, please select an Official Name, a Position Worked, an Evaluator Name, and an Evaluation Level. If you would like to add more than one override evaluation at a time, please use the 'Add Another Override Evaluation' button to create another grid row.</Headings.H5>
        </div>
        <div className="col-xs-12 usas-extra-bottom-margin">
         <ActionIntraPageButton onClick={(e)=> onAddOverrideEvaluationClicked(e)}>Add Another Override Evaluation</ActionIntraPageButton>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-bottom-margin">
          <div className='col-xs-12'>
            <OfficialSelectionForEvaluationOverrideGrid
              state={officialSelectionForEvaluationState}
              otsMeetHeaderState={otsMeetHeaderState}
              gridState={gridState}
              showError={showError}
              otsPositionWorkedEvaluationLevelEvaluatorNameState={otsPositionWorkedEvaluationLevelEvaluatorNameState}
              onUpdateOfficialNameValueChange={onUpdateOfficialNameValueChange}
              onUpdateOfficialPositionWorkedValueChange={onUpdateOfficialPositionWorkedValueChange}
              onUpdateEvaluatorNameValueChange={onUpdateEvaluatorNameValueChange}
              onUpdateEvaluationLevelValueChange={onUpdateEvaluationLevelValueChange}
              isLarge={isLarge}
            />
          </div>
        </div>
        {errorState.general &&
          <div className="row">
            <div className="col-xs-12">
              <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className='col-xs-12 usas-extra-top-margin'>
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  )
}

export default OtsMeetEvaluationsOverride;