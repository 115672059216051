import Constants from '../../../../common/utils/Constants';
import { isValidDate } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.ineligibleReasonId < 0) {
    errors.ineligibleReasonId = 'Ineligible to Compete Reason is required'
  }

  let effectiveDate = new Date(formState.effectiveDate);
  let expirationDate = new Date(formState.expirationDate);

  //Effective Date
  if (formState.effectiveDate === Constants.BLANK_DATE_STRING || formState.effectiveDate === '') {
    errors.effectiveDate = 'Ineligible to Compete Start Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Ineligible to Compete Start Date must be a valid date';
  } else if (expirationDate < effectiveDate || formState.effectiveDate === formState.expirationDate) {
    errors.effectiveDate = 'Ineligible to Compete Start Date must be before the End Date';
  }

  //Expiration Date
  if (formState.isPermanent === false) {
    if (formState.expirationDate === Constants.BLANK_DATE_STRING || formState.expirationDate === '') {
      errors.expirationDate = 'Ineligible to Compete End Date is required';
    } else if (!isValidDate(formState.expirationDate)) {
      errors.expirationDate = 'Ineligible to Compete End Date must be a valid date';
    } else if (expirationDate < effectiveDate || formState.effectiveDate === formState.expirationDate) {
      errors.expirationDate = 'Ineligible to Compete End Date must be after the Start Date';
    }
  }

  return errors;
};

const MemberIneligibleToCompeteDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MemberIneligibleToCompeteDetailValidation;