import useOtsOfficialClinics from './UseOtsOfficialClinics';

import OtsOfficialClinicsGrid from './OtsOfficialClinicsGrid';

import Headings from '../../../../../common/components/headings/Headings';
import LoadingModal from '../../../../../common/components/dialogs/LoadingModal';

const OtsOfficialClinics = () => {
  const { isLoading, gridData } = useOtsOfficialClinics();

  return (
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <Headings.H3>Clinics</Headings.H3>
      </div>
      <div className="col-xs-12">
        {isLoading ? <LoadingModal /> : <OtsOfficialClinicsGrid gridData={gridData} />}
      </div>
    </div>
  );
};

export default OtsOfficialClinics;