import React, { createContext, useState } from 'react';

import PersonCoursesForAdminRoleData from './PersonCoursesForAdminRoleData';

export const PersonCoursesForAdminRoleStateContext = createContext();

const PersonCoursesForAdminRoleContextProvider = ({ children }) => {
  const stateHook = useState({ ...PersonCoursesForAdminRoleData.INITIAL_STATE });

  return (
    <PersonCoursesForAdminRoleStateContext.Provider value={stateHook}>
      {children}
    </PersonCoursesForAdminRoleStateContext.Provider>
  );
};

export default PersonCoursesForAdminRoleContextProvider;