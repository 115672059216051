import {
  isValidDate, isValidBirthDate, isValidName, isValidMemberId, isValidAddressLine, isValid5DigitZip, isValid9DigitZip, isValidCanadianZip,
  isValidEmail, isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber
} from '../../../utils/validation';
import Constants from '../../../utils/Constants';

export const localValidate = (formState) => {
  const validFilterCombos = {
    hasOrgUnit: false,
    hasFirstName: false,
    hasLastName: false,
    hasMemberId: false,
    hasEmail: false,
    hasPhoneNumber: false,
    secondaryFilterCount: 0
  };
  let errors = {};

  if (formState.orgUnitName) {
    if (formState.orgUnitId === '' || formState.orgUnitId === Constants.DEFAULT_ID) {
      errors.orgUnitId = 'Must select a valid club'
    } else {
      validFilterCombos.hasOrgUnit = true;
    }
  }

  if (formState.lscOrgUnitId !== Constants.DEFAULT_ID) {
    validFilterCombos.hasOrgUnit = true;
  }

  if (formState.nonOrganizationMember === true) {
    validFilterCombos.hasOrgUnit = true;
  }

  //Birth Date
  if (formState.birthDate !== Constants.BLANK_DATE_STRING) {
    validFilterCombos.secondaryFilterCount += 1;

    if (!isValidDate(formState.birthDate)) {
      errors.birthDate = 'Birth Date must be a valid date';
    } else if (!isValidBirthDate(formState.birthDate)) {
      errors.birthDate = 'Birth Date cannot be in the future'
    }
  }

  //First Name
  if (formState.firstName.trim() !== '') {
    validFilterCombos.secondaryFilterCount += 1;
    validFilterCombos.hasFirstName = true;

    if (!isValidName(formState.firstName.trim())) {
      errors.firstName = 'Legal First Name or Preferred Name cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  //Middle Name
  if (formState.middleName.trim() !== '') {
    if (!isValidName(formState.middleName.trim())) {
      errors.middleName = 'Middle Name or Middle Initial cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  //Last Name
  if (formState.lastName.trim() !== '') {
    validFilterCombos.secondaryFilterCount += 1;
    validFilterCombos.hasLastName = true;

    if (!isValidName(formState.lastName.trim())) {
      errors.lastName = 'Legal Last Name or Maiden Name cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  //City
  if (formState.city.trim() !== '') {
    validFilterCombos.secondaryFilterCount += 1;

    if (!isValidAddressLine(formState.city.trim())) {
      errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }

  //State
  if (formState.stateName !== '') {
    validFilterCombos.secondaryFilterCount += 1;

    if (formState.state === Constants.DEFAULT_ID || formState.state === '') {
      errors.state = 'Must be a valid State';
    }
  }

  //Zip Code
  if (formState.zip.trim() !== '') {
    validFilterCombos.secondaryFilterCount += 1;

    if (!isValid5DigitZip(formState.zip.trim()) && !isValid9DigitZip(formState.zip.trim()) && !isValidCanadianZip(formState.zip.trim())) {
      errors.zip = 'Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format, or Canadian A1A 1A1 format'
    }
  }

  //Member Id
  if (formState.memberId.trim() !== '') {
    validFilterCombos.secondaryFilterCount += 1;
    validFilterCombos.hasMemberId = true;

    if (!isValidMemberId(formState.memberId.trim())) {
      errors.memberId = 'Member Id must be 14 alphanumeric characters';
    }
  }

  //Email
  if (formState.email.trim() !== '') {
    validFilterCombos.secondaryFilterCount += 1;
    validFilterCombos.hasEmail = true;

    if (!isValidEmail(formState.email.trim())) {
      errors.email = 'Email must be in a valid format';
    } else if (formState.email.trim().length > 512) {
      errors.email = 'Email cannot exceed 512 characters'
    }
  }

  //Phone Number
  if (formState.phoneNumber.trim() !== '' && !Constants.PHONE_AREA_CODES.find(x => x === formState.phoneNumber.trim())) {
    validFilterCombos.secondaryFilterCount += 1;
    validFilterCombos.hasPhoneNumber = true;

    if (!isValidUSOrCanadianPhoneNumber(formState.phoneNumber.trim()) && !isValidMexicanPhoneNumber(formState.phoneNumber.trim())) {
      errors.phoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format'
    }
  }

  const getFilterError = (validFilterCombos) => {
    if (validFilterCombos.hasMemberId || validFilterCombos.hasEmail || validFilterCombos.hasPhoneNumber) {
      return undefined; // Having any of these filters allows a search
    } else if (validFilterCombos.hasFirstName && validFilterCombos.hasLastName) {
      return undefined; // Having first & last name allows a search
    } else if (validFilterCombos.hasOrgUnit && validFilterCombos.secondaryFilterCount > 0) {
      return undefined; // Having an orgUnit and any other filter allows a search
    } else if (validFilterCombos.hasOrgUnit === true) {
      return 'Please provide at least one of the following filters: Birth Date, Legal First Name or Preferred Name, Legal Last Name or Maiden Name, City, State, Zip Code, Member Id, Email, or Phone Number.'
    } else if (validFilterCombos.secondaryFilterCount > 0) {
      return 'Please provide a LSC or Club Name. Or, to search within the entire USAS Organization, provide either a Member Id, Email, or Phone Number, or a Legal First Name or Preferred Name and a Legal Last Name or Maiden Name.'
    } else {
      return 'Please provide a LSC or Club Name and at least one of the following filters: Birth Date, Legal First Name or Preferred Name, Legal Last Name or Maiden Name, City, State, Zip Code, Email, or Phone Number. Or, to search within the entire USAS Organization, provide either a Member Id, Email, or Phone Number, or a Legal First Name or Preferred Name and a Legal Last Name or Maiden Name.';
    }
  };

  const filterError = getFilterError(validFilterCombos);
  if (filterError) {
    errors.filter = filterError;
  }

  return errors;
};

const LscSearchMemberValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default LscSearchMemberValidation;