import React from 'react';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';

const MemberTabsAndPills = () => {
  const { navRoutes } = useNavRoutes();

  return (
    <div className="row">
      <div className="visible-xs col-xs-12">
        <MobileContextBasedNav taxonomyName="MemberDetail" />
      </div>
      <div className="hidden-xs col-xs-12">
        <LargeContextBasedNav taxonomyName="MemberDetail" homeRoute={navRoutes.MEMBER_INFO?.route} />
      </div>
    </div>
  );
};

export default MemberTabsAndPills;