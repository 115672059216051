import { useState } from 'react';

import PersonOrgRoleAffiliationsData from './PersonOrgRoleAffiliationsData';

const usePersonOrgRoleAffiliationsData = () => {
  const [affiliationsState, setAffiliationsState] = useState(PersonOrgRoleAffiliationsData.INITIAL_STATE);

  const getAffiliations = (personId) => PersonOrgRoleAffiliationsData.getPersonOrgRoleAffiliationsData(personId, affiliationsState, setAffiliationsState);

  const postAffiliation = (affiliationObj) => PersonOrgRoleAffiliationsData.postPersonOrgRoleAffiliationData(affiliationObj, affiliationsState, setAffiliationsState);

  const putAffiliation = (affiliationObj) => PersonOrgRoleAffiliationsData.putPersonOrgRoleAffiliationData(affiliationObj, affiliationsState, setAffiliationsState);

  return {
    affiliationsState,
    getAffiliations,
    postAffiliation,
    putAffiliation
  };
};

export default usePersonOrgRoleAffiliationsData;