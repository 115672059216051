import { Fragment } from "react";

import useOfferingInstanceOrgUnitAddForm from "./UseOfferingInstanceOrgUnitAddForm";

import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import Dropdown from "../../../../../../common/components/dropdowns/Dropdown";
import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";
import DatePicker from "../../../../../../common/components/datepickers/DatePicker";
import CurrencyInput from "../../../../../../common/components/inputs/CurrencyInput";
import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";

import { convertNumberToCurrencyString } from "../../../../../../common/utils/CurrencyUtils";

const OfferingInstanceOrgUnitAddForm = ({ reportPeriodObj, orgUnitObj, hideOrgUnitName = false,
  submitButtonText = 'Save', onSubmitFormCallback, onBackClicked }) => {
  const {
    state,
    formState,
    errorState,
    updateFormState,
    onOfferingInstanceChanged,
    handleSubmit
  } = useOfferingInstanceOrgUnitAddForm(onSubmitFormCallback, reportPeriodObj, orgUnitObj);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Season Year"
              name="periodName"
              value={reportPeriodObj?.periodName} />
          </div>
          {hideOrgUnitName !== true &&
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label="LSC"
                name="orgUnitName"
                value={orgUnitObj?.orgUnitName} />
            </div>
          }
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Available Offerings*"
              name="offeringInstanceId"
              value={formState.offeringInstanceId}
              error={errorState.offeringInstanceId}
              message={errorState.offeringInstanceId}
              options={state.offeringOptions}
              isLoading={state.areOfferingOptionsLoaded !== true}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value && onOfferingInstanceChanged(newValue)
              }} />
          </div>
          {formState.offeringInstanceId > 0 &&
            <Fragment>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <ReadOnly
                  label="Maximum Fee Amount"
                  name="maxLscFeeAmount"
                  value={formState.maxLscFeeAmount === null
                    ? 'No Maximum'
                    : `$${convertNumberToCurrencyString(formState.maxLscFeeAmount)}`
                  } />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <CurrencyInput
                  label="Fee Amount*"
                  name="feeAmount"
                  value={formState.feeAmount}
                  error={errorState.feeAmount}
                  message={errorState.feeAmount}
                  onChange={(value) => { updateFormState('feeAmount', value); }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <DatePicker
                  label="Registration Start Date*"
                  name="registrationStartDate"
                  value={formState.registrationStartDate}
                  error={errorState.registrationStartDate}
                  message={errorState.registrationStartDate}
                  onChange={(value) => { updateFormState('registrationStartDate', value); }}
                  countOfYears={35}
                  startYearOffset={-20} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <DatePicker
                  label="Registration End Date*"
                  name="registrationEndDate"
                  value={formState.registrationEndDate}
                  error={errorState.registrationEndDate}
                  message={errorState.registrationEndDate}
                  onChange={(value) => { updateFormState('registrationEndDate', value); }}
                  countOfYears={35}
                  startYearOffset={-20} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <DatePicker
                  label="Effective Start Date*"
                  name="effectiveDate"
                  value={formState.effectiveDate}
                  error={errorState.effectiveDate}
                  message={errorState.effectiveDate}
                  onChange={(value) => { updateFormState('effectiveDate', value); }}
                  countOfYears={35}
                  startYearOffset={-20} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <DatePicker
                  label="Expiration End Date*"
                  name="expirationDate"
                  value={formState.expirationDate}
                  error={errorState.expirationDate}
                  message={errorState.expirationDate}
                  onChange={(value) => { updateFormState('expirationDate', value); }}
                  countOfYears={35}
                  startYearOffset={-20} />
              </div>
            </Fragment>
          }
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type='submit'>{submitButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Available Offerings Error'}
        displayPopUp={state.apiErrorMessage !== ''}
        onModalCanceled={onBackClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{state.apiErrorMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onBackClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default OfferingInstanceOrgUnitAddForm;