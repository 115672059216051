import React, { createContext, useState } from 'react';

import OrgUnitAthleteData from './OrgUnitAthleteData';

export const OrgUnitAthleteStateContext = createContext();

const OrgUnitAthleteContextProvider = ({ children }) => {
  const stateHook = useState(OrgUnitAthleteData.INITIAL_STATE);

  return (
    <OrgUnitAthleteStateContext.Provider value={stateHook}>
      {children}
    </OrgUnitAthleteStateContext.Provider>
  );
};

export default OrgUnitAthleteContextProvider;