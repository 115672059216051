import React, { Fragment } from 'react';

import useMemberContacts from './UseMemberContacts';

import MemberContactsGrid from './grids/MemberContactsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const MemberContactsRead = () => {
  const {
    viewState,
    personGeneralState,
    basePersonState
  } = useMemberContacts();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Contact Info</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <MemberContactsGrid
            contacts={viewState.contactDisplayList}
            isLoading={personGeneralState.isObjLoading} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personGeneralState.isObjLoading || basePersonState.isObjLoading} />
    </Fragment>
  );
};

export default MemberContactsRead;