import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ProfileCoachSearchValidation';

import useCoachSearchData from '../../../state/coachSearch/UseCoachSearchData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useProfileCoachSearch = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  //TODO orgUnitId as environment variable, will this work with string?
  const { COACH_ROLE_ID, USAS_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { coachSearchState, coachSearch } = useCoachSearchData();
  const { formState, errorState, setFormData, setErrors, handleSubmit, onFormValueChanged } = useForm(getInitialFormState(), submitFormCallback, validate);

  const onClearFormClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormData({
      ...formState,
      lastName: '',
      firstName: '',
      birthDate: Constants.BLANK_DATE_STRING
    });
    setErrors({});
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_PROFILE_COACH?.route);
  }

  function createSearchFilterObj(formState) {
    return {
      orgUnitId: USAS_ORG_UNIT_ID,
      birthDate: formState.birthDate !== Constants.BLANK_DATE_STRING ? formState.birthDate : undefined,
      firstName: formState.firstName.trim() || undefined,
      lastName: formState.lastName.trim() || undefined,
      roleId: COACH_ROLE_ID
    };
  };

  // Called by useForm after passing validation, makes API call and shows results grid
  function submitFormCallback(formState) {
    let sortBy = undefined;

    if (coachSearchState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      coachSearch(JSON.stringify(filterObject), sortBy || '');
    }
  };

  const onAddCoach = (e, coachPersonId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_PROFILE_COACH_DETAIL?.route, { state: { coachPersonId } });
  };

  function getInitialFormState() {
    return {
      lastName: '',
      firstName: '',
      birthDate: Constants.BLANK_DATE_STRING
    };
  };

  return {
    formState,
    errorState,
    coachSearchState,
    onAddCoach,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked,
    onClearFormClicked
  };
};

export default useProfileCoachSearch;