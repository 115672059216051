import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';

export const getOfficialsTrackingSystemOnlineTests = (personId, isClinic, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'NaN';
    const isClinicForUrl = isClinic === true || isClinic === false ? encodeURIComponent(isClinic) : '';
    const url = `/OfficialCourse/Person/${personIdForUrl}?isClinic=${isClinicForUrl}`;
    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
}

const OfficialsTrackingSystemOnlineTestsData = {
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  getOfficialsTrackingSystemOnlineTests
};

export default OfficialsTrackingSystemOnlineTestsData;