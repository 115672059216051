import { isValidName } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Signature
  if (formState.transferSignature.trim() === '') {
    errors.transferSignature = 'First and Last Name is required';
  } else if (!isValidName(formState.transferSignature.trim())) {
    errors.transferSignature = 'First and Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  //New Club
  if (formState.transferOrgUnitId === undefined || formState.transferOrgUnitId < 1) {
    errors.transferOrgUnitId = 'New Club is required';
  } else if (formState.currentOrgUnitId === formState.transferOrgUnitId) {
    errors.transferOrgUnitId = 'New Club cannot be the same as the current club';
  }

  return errors;
};

const MemberClubTransferMemberValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MemberClubTransferMemberValidation;