import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsCertificationApplicationData from '../../../../state/otsCertificationApplication/UseOtsCertificationApplicationData';
import useOfficialPositionCertificationTypeProgressionData from '../../../../state/officialPositionCertificationTypeProgression/UseOfficialPositionCertificationTypeProgressionData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useEmbeddedReportData from '../../../../../common/state/embeddedReport/UseEmbeddedReportData';

import UseForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate, getTodaysDate } from '../../../../../common/utils/DateFunctions';
import usePersonPositionCertificationNoteData from '../../../../state/personPositionCertificationNote/UsePersonPositionCertificationNoteData';

const INITIAL_VIEW_STATE = {
  isLoading: false,
  gridData: [],
  personId: '',
  personPositionCertificationRequestId: '',
  error: '',
  showError: false,
  showLoading: false,
  errorMessage: '',
  tryRedirect: false
};

const INITIAL_FORM_STATE = {
  expirationDateId: Constants.DEFAULT_ID,
  expirationDateName: '--',
  statusId: Constants.DEFAULT_ID,
  statusName: '--',
  personPositionCertificationNoteId: Constants.DEFAULT_ID,
  personPositionCertificationNoteName: '--',
  publicNotes: '',
  privateNotes: ''
};

const usePersonApplications = (personId, personPositionCertificationRequestId) => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const { localOtsCertificationApplicationState, getCertificationApplicationsForPersonByPersonPositionCertificationRequestId, saveProgressionStatus } = useOtsCertificationApplicationData();
  const { officialPositionCertificationTypeProgressionState } = useOfficialPositionCertificationTypeProgressionData();
  const { personPositionCertificationNoteState } = usePersonPositionCertificationNoteData();
  const [gridState, setGridState] = useState({ arrayData: [] });
  const { formState, errorState, setErrors, handleSubmit, onValueTextPairChanged, onFormValueChanged, setFormState, setIsDirty } = UseForm(INITIAL_FORM_STATE, submitFormCallback, () => { });
  const { getEmbeddedReportListForRoute, getEmbeddedReport } = useEmbeddedReportData();

  const today = new Date();
  const year = today.getFullYear();
  const yearPlus3 = year + 3;
  const expirationDateOptions = [
    { id: 1, name: `06/30/${yearPlus3}` },
    { id: 2, name: `12/31/${yearPlus3}` }];

  const statusOptions = [
    { id: 1, name: "In Process" },
    { id: 2, name: "Approved" },
    { id: 3, name: "Denied" }];

  const onUpdatePositionCertificationProgressionIdValueChange = (e, position, value, valueLabel) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      let selectedIndex = arrayDataCopy.findIndex(x => (x.personPositionCertificationProgressionId === position.personPositionCertificationProgressionId));
      arrayDataCopy[selectedIndex].selectedPositionCertificationProgressionId = value;
      arrayDataCopy[selectedIndex].selectedProgressionName = valueLabel;
      let newEffectiveDate = officialPositionCertificationTypeProgressionState.arrayData.find(x => x.positionCertificationProgressionId === value)?.effectiveDate;
      arrayDataCopy[selectedIndex].effectiveDate = formatDate(newEffectiveDate);
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  };

  const onUpdateExpirationDateValueChange = (e, position, value, valueLabel) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      let selectedIndex = arrayDataCopy.findIndex(x => (x.personPositionCertificationProgressionId === position.personPositionCertificationProgressionId));
      arrayDataCopy[selectedIndex].selectedExpirationDateId = value;
      arrayDataCopy[selectedIndex].selectedExpirationDateName = valueLabel;
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  };

  const onUpdateStatusValueChange = (e, position, value, valueLabel) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      let selectedIndex = arrayDataCopy.findIndex(x => (x.personPositionCertificationProgressionId === position.personPositionCertificationProgressionId));
      arrayDataCopy[selectedIndex].selectedStatusId = value;
      arrayDataCopy[selectedIndex].selectedStatusName = valueLabel;
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  };

  const onAddRow = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      const newRow = {
        index: arrayDataCopy.length,
        personPositionCertificationProgressionId: 'newRow' + arrayDataCopy.length,
        effectiveDate: getTodaysDate(),
        selectedPositionCertificationProgressionId: Constants.DEFAULT_ID,
        selectedProgressionName: '--',
        selectedExpirationDateId: expirationDateOptions[0]?.id,
        selectedExpirationDateName: expirationDateOptions[0]?.name,
        selectedStatusId: statusOptions[0]?.id,
        selectedStatusName: statusOptions[0]?.name
      }

      arrayDataCopy.push(newRow);
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  }

  const onDeleteRow = (e, position) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      arrayDataCopy.splice(position.index, 1);
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  }

  const onSaveClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    let error = false;
    const progressionStatusArray = gridState.arrayData?.map((x) => {
      if (x.selectedPositionCertificationProgressionId === Constants.DEFAULT_ID ||
        x.selectedExpirationDateId === Constants.DEFAULT_ID || x.selectedStatusId === Constants.DEFAULT_ID) {
        error = true;
      }
      return (
        {
          personPositionCertificationProgressionId: x.personPositionCertificationProgressionId,
          positionCertificationProgressionId: x.selectedPositionCertificationProgressionId,
          expirationDate: x.selectedExpirationDateName,
          status: x.selectedStatusName
        }
      );
    })
    //if there are multiple grid rows and two or more of the grid rows have the same position & level selected, throw an error
    for (let i = 0; i < progressionStatusArray.length; i++) {
      if (progressionStatusArray.find(x => x.positionCertificationProgressionId === progressionStatusArray[i].positionCertificationProgressionId &&
        x.personPositionCertificationProgressionId !== progressionStatusArray[i].personPositionCertificationProgressionId)) {
        error = true;
      }
    }
    if (error === true) {
      setState({ ...state, showError: true });
    }
    else {
      let privateNotesError = false;
      let publicNotesError = false;
      let publicNotesRequiredError = false;
      if (formState.privateNotes.trim().length > 1000) {
        privateNotesError = true;
      }
      if (formState.publicNotes.trim().length > 1000) {
        publicNotesError = true;
      }
      if (formState.personPositionCertificationNoteId === Constants.DEFAULT_ID && formState.publicNotes.trim().length === 0) {
        publicNotesRequiredError = true;
      }
      if (privateNotesError === false && publicNotesError === false && publicNotesRequiredError === false) {
        const progressionStatusArrayWithAnyNulls = progressionStatusArray.map((x) => {
          return ({
            ...x,
            personPositionCertificationProgressionId: x.personPositionCertificationProgressionId?.toString()?.includes("newRow") ? null : x.personPositionCertificationProgressionId
          })
        })
        saveProgressionStatus(personId, personPositionCertificationRequestId,
          {
            privateNotes: formState.privateNotes.trim(),
            publicNotes: formState.publicNotes.trim(),
            certificationPublicNoteId: formState.personPositionCertificationNoteId !== Constants.DEFAULT_ID ? formState.personPositionCertificationNoteId : null,
            personCertificationRequest:
              progressionStatusArrayWithAnyNulls
          })
        setState({ ...state, tryRedirect: true });
      }
      else {
        if (publicNotesRequiredError === true) {
          setErrors({
            personPositionCertificationNoteId: 'If Additional Public Notes are not provided, a Public Notes dropdown selection is required',
            publicNotes: 'If a Public Notes dropdown selection is not provided, Additional Public Notes are required'
          })
        }
        else {
          if (privateNotesError === true && publicNotesError === false) {
            setErrors({ privateNotes: 'Private Notes cannot exceed 1000 characters' })
          }
          else if (privateNotesError === false && publicNotesError === true) {
            setErrors({ publicNotes: 'Additional Public Notes cannot exceed 1000 characters' })
          }
          else if (privateNotesError === true && publicNotesError === true) {
            setErrors({ privateNotes: 'Private Notes cannot exceed 1000 characters', publicNotes: 'Additional Public Notes cannot exceed 1000 characters' })
          }
        }
      }
    }
  }

  const onBackClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_CERT_APP_WORKFLOW_SEARCH?.route);
  }

  const showErrorMessage = (errorMessage) => {
    setState({ ...state, errorMessage, showLoading: false });
  };

  const onErrorCanceled = () => {
    setState({ ...state, errorMessage: '', showLoading: false });
  };

  const onOfficialHistoryReportLoadClick = () => {
    const newState = {
      ...state,
      showLoading: true
    };
    setState({ ...newState });
    getEmbeddedReportListForRoute('OTS_CERT_APP_WORKFLOW_ROOT')
      .then(data => {
        if (data?.arrayData?.[0].reportInfoId > 0) {
          getEmbeddedReport(data?.arrayData?.[0].reportInfoId, { personId: personId })
            .then(rptData => {
              if (rptData?.objData?.reportUrl) {
                setState({ ...newState, showLoading: false });
                window.open(rptData.objData.reportUrl, '_blank').focus();
              } else {
                showErrorMessage('There was an error building the report.');
              }
            })
            .catch(() => {
              showErrorMessage('There was an error building the report.');
            });
        } else {
          showErrorMessage('There was an error building the report.');
        }
      })
      .catch(() => {
        showErrorMessage('There was an error building the report.');
      });
  };

  const onOfficialEvaluationReportLoadClick = () => {
    const newState = {
      ...state,
      showLoading: true
    };
    setState({ ...newState });
    getEmbeddedReportListForRoute('OTS_CERT_APP_WORKFLOW_ROOT')
      .then(data => {
        if (data?.arrayData?.[1].reportInfoId > 0) {
          getEmbeddedReport(data?.arrayData?.[1].reportInfoId, { personId: personId })
            .then(rptData => {
              if (rptData?.objData?.reportUrl) {
                setState({ ...newState, showLoading: false });
                window.open(rptData.objData.reportUrl, '_blank').focus();
              } else {
                showErrorMessage('There was an error building the report.');
              }
            })
            .catch(() => {
              showErrorMessage('There was an error building the report.');
            });
        } else {
          showErrorMessage('There was an error building the report.');
        }
      })
      .catch(() => {
        showErrorMessage('There was an error building the report.');
      });
  };

  useEffect(() => {
    setIsDirty(true);
    if (personPositionCertificationNoteState.isArrayLoaded === true && personId && personPositionCertificationRequestId && (personId !== state.personId || personPositionCertificationRequestId !== state.personPositionCertificationRequestId)) {
      const newState = { ...INITIAL_VIEW_STATE, personId, isLoading: true };
      setState({ ...newState });
      getCertificationApplicationsForPersonByPersonPositionCertificationRequestId(personId, personPositionCertificationRequestId)
        .then(data => {
          setFormState({
            ...formState,
            expirationDateId: expirationDateOptions[0]?.id || Constants.DEFAULT_ID,
            expirationDateName: expirationDateOptions[0]?.name || '--',
            statusId: statusOptions[0]?.id || Constants.DEFAULT_ID,
            statusName: statusOptions[0]?.name || '--',
            privateNotes: data.arrayData?.privateNotes || '',
            publicNotes: data.arrayData?.publicNotes || '',
            personPositionCertificationNoteId: data.arrayData?.certificationPublicNoteId || Constants.DEFAULT_ID,
            personPositionCertificationNoteName: data.arrayData?.certificationPublicNoteId ? personPositionCertificationNoteState.arrayData?.find(x => x.personPositionCertificationNoteId === data.arrayData?.certificationPublicNoteId)?.note : '--',
          })
          let formattedArrayData = data.arrayData?.personCertificationRequest?.map((x) => {
            return (
              {
                ...x,
                selectedPositionCertificationProgressionId: x.progressionName ? officialPositionCertificationTypeProgressionState.options?.find(option => option.name === x.progressionName)?.id || Constants.DEFAULT_ID : Constants.DEFAULT_ID,
                selectedProgressionName: x.progressionName ? officialPositionCertificationTypeProgressionState.options?.find(option => option.name === x.progressionName)?.name || '--' : '--',
                selectedExpirationDateId: x.expirationDate ? expirationDateOptions?.find(option => option.name === formatDate(x.expirationDate))?.id || Constants.DEFAULT_ID : Constants.DEFAULT_ID,
                selectedExpirationDateName: x.expirationDate ? expirationDateOptions?.find(option => option.name === formatDate(x.expirationDate))?.name || '--' : '--',
                selectedStatusId: x.status ? statusOptions?.find(option => option.name === x.status)?.id || Constants.DEFAULT_ID : Constants.DEFAULT_ID,
                selectedStatusName: x.status ? statusOptions?.find(option => option.name === x.status)?.name || '--' : '--',
                uniqueExpirationDateDropdownId: "expirationDate" + x?.personPositionCertificationProgressionId + "personPositionCertificationProgressionId",
                uniqueStatusDropdownId: "status" + x?.personPositionCertificationProgressionId + "personPositionCertificationProgressionId"
              }
            );
          })
          setGridState({ ...gridState, arrayData: formattedArrayData });
          setState({
            ...newState,
            isLoading: false
          });
        })
        .catch((e) => {
          setState({
            ...INITIAL_VIEW_STATE,
            error: `There was an error getting the data for this person. Please click 'Back' and try again.`
          }
          );
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId, personPositionCertificationRequestId, personPositionCertificationNoteState]);

  useEffect(() => {
    if (officialPositionCertificationTypeProgressionState.isArrayLoading === false && officialPositionCertificationTypeProgressionState.isArrayLoaded === true &&
      personId && personPositionCertificationRequestId && (personId === officialPositionCertificationTypeProgressionState.personId && personPositionCertificationRequestId === officialPositionCertificationTypeProgressionState.personPositionCertificationRequestId)
    ) {
      let formattedArrayData = gridState.arrayData?.map((x) => {
        return (
          {
            ...x,
            selectedPositionCertificationProgressionId: x.progressionName ? officialPositionCertificationTypeProgressionState.options?.find(option => option.name === x.progressionName)?.id || Constants.DEFAULT_ID : Constants.DEFAULT_ID,
            selectedProgressionName: x.progressionName ? officialPositionCertificationTypeProgressionState.options?.find(option => option.name === x.progressionName)?.name || '--' : '--'
          }
        );
      })
      setGridState({ ...gridState, arrayData: formattedArrayData });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [personId, personPositionCertificationRequestId, officialPositionCertificationTypeProgressionState])

  useEffect(() => {
    if (state.tryRedirect === true && localOtsCertificationApplicationState.isArrayLoading === false) {
      onBackClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, localOtsCertificationApplicationState.isArrayLoading])

  function submitFormCallback(formState) {
    const newArrayData = gridState.arrayData?.map((x) => {
      return (
        {
          ...x,
          selectedExpirationDateId: formState.expirationDateId,
          selectedExpirationDateName: formState.expirationDateName,
          selectedStatusId: formState.statusId,
          selectedStatusName: formState.statusName
        }
      );
    })
    setGridState({ ...gridState, arrayData: newArrayData });
  }

  return {
    ...state,
    personPositionCertificationNoteState,
    localOtsCertificationApplicationState,
    officialPositionCertificationTypeProgressionState,
    gridState,
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onFormValueChanged,
    expirationDateOptions,
    statusOptions,
    onUpdatePositionCertificationProgressionIdValueChange,
    onUpdateExpirationDateValueChange,
    onUpdateStatusValueChange,
    onSaveClicked,
    onBackClicked,
    onOfficialHistoryReportLoadClick,
    onOfficialEvaluationReportLoadClick,
    onErrorCanceled,
    onAddRow,
    onDeleteRow
  }
};

export default usePersonApplications;