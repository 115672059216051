import { Fragment } from 'react';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import InternationalPhoneInput from '../../../../common/components/inputs/InternationalPhoneInput';

import useNationalTeamSupportStaffDetail from './UseNationalTeamSupportStaffDetail';

const NationalTeamSupportStaffDetail = () => {
  const {
    Constants,
    title,
    personNationalTeamState,
    formState,
    errorState,
    supportStaffTypeState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  } = useNationalTeamSupportStaffDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.nationalTeamSupportStaffId > 0 ? 'Edit' : 'Add'} {title}</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="First Name*"
              name="firstName"
              value={formState.firstName}
              error={errorState.firstName}
              message={errorState.firstName}
              onChange={(value) => { onFormValueChanged('firstName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Last Name*"
              name="lastName"
              value={formState.lastName}
              error={errorState.lastName}
              message={errorState.lastName}
              onChange={(value) => { onFormValueChanged('lastName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Email"
              name="email"
              value={formState.email}
              error={errorState.email}
              message={errorState.email}
              onChange={(value) => { onFormValueChanged('email', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <InternationalPhoneInput
              label="Phone Number"
              name="phoneNumber"
              value={formState.phoneNumber}
              onChange={(value) => onFormValueChanged('phoneNumber', value)}
              error={errorState.phoneNumber}
              message={errorState.phoneNumber} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Support Staff Role*"
              name="supportStaffTypeId"
              value={formState.supportStaffTypeId}
              error={errorState.supportStaffTypeId}
              message={errorState.supportStaffTypeId}
              isLoading={supportStaffTypeState.isLoading}
              options={supportStaffTypeState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'supportStaffTypeId', newValueLabel, 'typeName') }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Effective Date"
              name="effectiveDate"
              value={formState.effectiveDate}
              error={errorState.effectiveDate}
              message={errorState.effectiveDate}
              countOfYears={150}
              startYearOffset={-149}
              onChange={(value) => { onFormValueChanged('effectiveDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Expiration Date"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              countOfYears={150}
              startYearOffset={-140}
              onChange={(value) => { onFormValueChanged('expirationDate', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onSaveClicked(e)}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={(e) => onCancelClicked(e)}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personNationalTeamState.isObjLoading || supportStaffTypeState.isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personNationalTeamState.isSaving} />
    </Fragment>);
}

export default NationalTeamSupportStaffDetail;
