import React, { createContext, useState } from 'react';

import PersonGeneralNationalTeamData from './PersonGeneralNationalTeamData';

export const PersonGeneralNationalTeamStateContext = createContext();

const PersonGeneralNationalTeamContextProvider = ({ children }) => {
  const stateHook = useState(PersonGeneralNationalTeamData.INITIAL_STATE);

  return (
    <PersonGeneralNationalTeamStateContext.Provider value={stateHook}>
      {children}
    </PersonGeneralNationalTeamStateContext.Provider>
  );
};

export default PersonGeneralNationalTeamContextProvider;