import React, { Fragment } from 'react';
import useReport from './UseReport';
import Headings from '../headings/Headings';
import global from '../GlobalStyle.module.css';

const Report = ({ embedSlotName, minHeight, maxHeight, reportParameters }) => {
  const { 
    frameId, 
    title, 
    src, 
    containerStyle } = useReport(embedSlotName, minHeight, maxHeight, reportParameters);

  return (
    <Fragment>
      {title && <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{ title }</Headings.H3>
        </div>
      </div>}
      <div className='row'>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <div className={global.CenterVertAndHoriz}>Loading...</div>
          {src && 
          <div style={{position: 'relative', zIndex: 1}}>
            <iframe 
              id={frameId}
              title={title}
              style={containerStyle}
              src={src}
              width="100%"
              height="100%"
              frameborder="0"
              scrolling="auto"></iframe>
          </div>}
        </div>
      </div>
    </Fragment>
  );
};

export default Report; 