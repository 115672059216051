import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useOtsOfficialData from '../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';
import useSearchOtsOfficialData from '../../../state/searchOfficialsTrackingSystemOfficial/UseSearchOtsOfficialData';
import useOfficialLscCertificationData from '../../../state/officialLscCertification/UseOfficialLscCertificationData';
import useOtsOfficialMeetHistoryData from '../../../state/officialsTrackingSystemOfficialMeetHistory/UseOtsOfficialMeetHistoryData';
import useOtsOfficialNationalCertificationData from '../../../state/officialsTrackingSystemOfficialNationalCertification/UseOtsOfficialNationalCertificationData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import usePersonOrgRoleData from '../../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';

const BACK_TO_LABELS = {
  otsOfficialBackToLabel: 'Back to Official Search',
};

const useOfficialsTrackingSystemOfficialContextView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personGeneralState, getPersonGeneral } = usePersonGeneralData();
  const { searchOtsOfficialFilterState } = useSearchOtsOfficialData();
  const { otsOfficialLscState, otsOfficialHeaderState, clearOtsOfficialHeaderObjData, getOtsOfficialLscCertificationsByPersonId } = useOtsOfficialData();
  const { resetOfficialLscCertificationState } = useOfficialLscCertificationData();
  const { clearOtsOfficialMeetHistoryState } = useOtsOfficialMeetHistoryData();
  const { clearOtsOfficialNationalCertificationState } = useOtsOfficialNationalCertificationData();
  const { contextSecurityState } = useSecurityData();
  const { personOrgRoleState, clearArrayData } = usePersonOrgRoleData();

  function getBackToLabel() {
    return BACK_TO_LABELS[`${(searchOtsOfficialFilterState.backToSystem || '').toLowerCase()}BackToLabel`]
      || BACK_TO_LABELS.otsOfficialBackToLabel;
  };

  const getEmailAddress = (personGeneralState) => {
    if (Array.isArray(personGeneralState.objData?.personContact) && personGeneralState.objData.personContact.length > 0) {
      const contact = personGeneralState.objData.personContact.find(x => x?.contactRelationshipType?.typeName === 'Self');
      return contact?.emailAddress || '';
    }
    return '';
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    clearArrayData();
    clearOtsOfficialHeaderObjData();
    resetOfficialLscCertificationState();
    clearOtsOfficialNationalCertificationState();
    clearOtsOfficialMeetHistoryState();
    navigate(navRoutes.OTS_OFFICIAL_SEARCH?.route);
  };

  useEffect(() => {
    if (location.state && location.state.getLscCertifications === true && basePersonState.objData?.personId) {
      getOtsOfficialLscCertificationsByPersonId(basePersonState.objData.personId);
      getPersonGeneral(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, basePersonState])

  return {
    otsOfficialLscState,
    otsOfficialHeaderState,
    emailAddress: getEmailAddress(personGeneralState),
    backToLabel: getBackToLabel(),
    onBackClicked,
    isLoading: contextSecurityState.isObjLoading || basePersonState.isObjLoading || personOrgRoleState.isArrayLoading
  };
};

export default useOfficialsTrackingSystemOfficialContextView;