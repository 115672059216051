import Constants from "../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  // Attachment Description
  if (!formState.attachmentDescription.trim()) {
    errors.attachmentDescription = 'Attachment Description is required';
  }
  else if (formState.attachmentDescription.trim().length > 7000) {
    errors.attachmentDescription = 'Attachment Description cannot exceed 7000 characters';
  }

  // File Name
  if (!formState.fileName.trim()) {
    errors.fileName = 'File Name is required';
  }

  // File TypeId
  if (formState.fileTypeId === Constants?.DEFAULT_ID) {
    errors.fileTypeId = 'File Name is required';
  }

  //Attachment URL
  if (formState.attachmentUrl === '' || formState.attachmentUrl === undefined) {
    errors.attchmentError = 'Attachment is required';
  }

  if (formState.attachmentUrl.includes("Bad Request")) {
    errors.attchmentError = 'Attachment has an invalid file type';
  }

  return errors;
};

const NationalTeamAttachmentDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default NationalTeamAttachmentDetailValidation;