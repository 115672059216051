import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './HqOtsOfficialLscBulkCertificationDetailValidation';

import useOtsOfficialsBulkRecertifyData from '../../../state/officialsTrackingSystemBulkRecertification/UseOtsOfficialsBulkRecertifyData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate, getTodaysDate } from '../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  expirationDate: Constants?.BLANK_DATE_STRING,
  effectiveDate: Constants?.BLANK_DATE_STRING
};

const INITIAL_STATE = {
  lscName: '',
  count: 0,
  selectedOfficials: [],
  officialPositionTypeId: Constants.DEFAULT_ID,
  positionTypeName: '',
  positionAbbreviation: '',
  certificationLevelId: Constants.DEFAULT_ID,
  levelName: '',
};

const useHqOtsOfficialLscBulkRecertifyDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const [state, setState] = useState(INITIAL_STATE);
  const { searchOtsOfficialsForBulkRecertifyState, putOtsOfficialBulkRecertify, resetOtsOfficialBulkRecertifyState, clearSearchOtsOfficialsForBulkRecertifyArrayData } = useOtsOfficialsBulkRecertifyData();
  const { formState, errorState, onFormValueChanged, handleSubmit, setFormData } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  function submitFormCallback(formState) {
    if (searchOtsOfficialsForBulkRecertifyState?.isSaving === false) {
      const putObject = createPutObj(formState, state);
      putOtsOfficialBulkRecertify(putObject);
      clearSearchOtsOfficialsForBulkRecertifyArrayData();
    }
  };

  function createPutObj(formState, state) {
    return {
      recertifyExpirationDate: formState?.expirationDate,
      officials: state?.selectedOfficials
    }
  };

  const onBackToLscBulkRecertifyGrid = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.HQ_OTS_LSC_BULK_RECERTIFICATION?.route);
  };

  const getOfficialCount = () => {
    const officialCount = state?.selectedOfficials?.length;
    return officialCount
  };

  const mapIt = (array) => {
    const newArray = array.map(official => {
      return {
        personId: official?.personId,
        memberId: official?.memberId,
        firstName: official?.firstName,
        middleName: official?.middleName,
        preferredName: official?.preferredName,
        lastName: official?.lastName,
        birthDate: official?.birthDate,
        officialPositionTypeId: official?.officialPositionTypeId,
        positionTypeName: official?.positionTypeName,
        positionAbbreviation: official?.positionAbbreviation,
        personPositionCertificationId: official?.personPositionCertificationId,
        positionCertificationTypeId: official?.positionCertificationTypeId,
        certificationLevelId: official?.certificationLevelId,
        levelName: official?.levelName,
        effectiveDate: official?.effectiveDate,
        expirationDate: official?.expirationDate,
        reCertify: true
      }
    })

    return newArray;
  };

  const calculateCountOfYears = () => {
    let countOfYears = 0;
    const today = new Date();
    const year = today.getFullYear();
    countOfYears = year - 2000 + 9;
    return countOfYears;
  };

  const calculateStartYearOffset = () => {
    let startYearOffset = 0;
    const today = new Date();
    const year = today.getFullYear();
    startYearOffset = 2000 - year;
    return startYearOffset;
  };

  useEffect(() => {
    if (searchOtsOfficialsForBulkRecertifyState?.isSaved === true) {
      resetOtsOfficialBulkRecertifyState();
      navigate(navRoutes.HQ_OTS_LSC_BULK_RECERTIFICATION?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOtsOfficialsForBulkRecertifyState?.isSaved]);

  useEffect(() => {
    if (location?.state) {
      const today = getTodaysDate();
      const selectedLscCertificationsSortedByEffectiveDate = location?.state?.recertifyBulkStateArray?.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.effectiveDate) - new Date(a.effectiveDate);
      });
      setFormData({
        ...formState,
        expirationDate: formatDate(today),
        effectiveDate: formatDate(selectedLscCertificationsSortedByEffectiveDate[0].effectiveDate),
      }, true)

      const firstItemInfoToSet = location?.state?.recertifyBulkStateArray[0];
      setState({
        ...state,
        lscName: location?.state?.lscName,
        selectedOfficials: mapIt(location?.state?.recertifyBulkStateArray),
        officialPositionTypeId: firstItemInfoToSet?.officialPositionTypeId,
        positionTypeName: firstItemInfoToSet?.positionTypeName,
        positionAbbreviation: firstItemInfoToSet?.positionAbbreviation,
        certificationLevelId: firstItemInfoToSet?.certificationLevelId,
        levelName: firstItemInfoToSet?.levelName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    officialCount: getOfficialCount(),
    onBackToLscBulkRecertifyGrid,
    state,
    searchOtsOfficialsForBulkRecertifyState,
    countOfYears: calculateCountOfYears(),
    startYearOffset: calculateStartYearOffset()
  };
};

export default useHqOtsOfficialLscBulkRecertifyDetail;