import { useEffect, useState } from 'react';

import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useMeetEvaluationPersonData from '../../../../state/otsMeetEvaluationsPerson/UseMeetEvaluationPersonData';
import useOtsMeetOfficialsData from '../../../../state/officialsTrackingSystemMeetOfficials/UseOtsMeetOfficialsData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useEmbeddedReportData from '../../../../../common/state/embeddedReport/UseEmbeddedReportData';

import useForm from '../../../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  showAllEvaluations: false,
};

const INITIAL_VIEW_STATE = {
  reportParameters: {},
  contextId: '',
  routeName: '',
  isLoading: false,
  showReport: false,
  reportViewerType: '',
  reportName: ''
};

const useMyOtsEvaluations = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { meetEvaluationPersonState, getMeetEvaluationsByPersonId } = useMeetEvaluationPersonData();
  const { clearOtsMeetOfficialsArrayData } = useOtsMeetOfficialsData();
  const { getEmbeddedReportListForRoute } = useEmbeddedReportData();
  const { formState, errorState, handleSubmit, onFormValueChanged } = useForm(INITIAL_FORM_STATE);
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);

  const onGoToEvaluationFormClicked = (e, evaluation) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_MY_OTS_EVALUATIONS_FORM?.route, {
      state: {
        evaluation: evaluation
      }
    });
  };

  const onReadOnlyEvaluationClicked = (e, evaluation) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(navRoutes.OTS_MEET_MY_OTS_EVALUATIONS_FORM?.route, {
      state: {
        evaluation: evaluation
      }
    });
  };

  const onRunViewHistoryReport = (e, evaluation) => {
    setViewState({
      ...viewState,
      reportParameters: { personId: evaluation.personId },
      routeName: 'OTS_MEET_MY_OTS_EVALUATIONS',
      reportViewerType: 'ActiveReports',
      reportName: 'Official History',
      isLoading: true
    });
  };

  useEffect(() => {
    clearOtsMeetOfficialsArrayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    //handle initial page load when navigating to page for the first time
    getMeetEvaluationsByPersonId(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const reportIdx = stateObj.arrayData.findIndex(x => ((x.reportViewerType === viewState.reportViewerType) && (x.reportName === viewState.reportName)));

            setViewState({
              ...viewState,
              isLoading: false,
              showReport: stateObj.arrayData.length > 0,
              reportInfoId: stateObj.arrayData[reportIdx].reportInfoId || -1,
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName, viewState.reportViewerType, viewState.routeName]);

  return {
    viewState,
    setViewState,
    INITIAL_VIEW_STATE,
    handleSubmit,
    formState,
    errorState,
    onFormValueChanged,
    meetEvaluationPersonState,
    onGoToEvaluationFormClicked,
    onReadOnlyEvaluationClicked,
    onRunViewHistoryReport
  };
};

export default useMyOtsEvaluations;