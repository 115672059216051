import { Fragment } from "react";

import ProfileGroupsGridLarge from "./ProfileGroupsGridLarge";
import ProfileGroupsGridSmall from "./ProfileGroupsGridSmall";

const ProfileGroupsGrid = ({ data, isLoading, onApplyClicked, INVITE_STATUS }) => (
  <Fragment>
    <ProfileGroupsGridLarge data={data} isLoading={isLoading} onApply={onApplyClicked} INVITE_STATUS={INVITE_STATUS} />
    <ProfileGroupsGridSmall data={data} isLoading={isLoading} onApply={onApplyClicked} INVITE_STATUS={INVITE_STATUS} />
  </Fragment>
);

export default ProfileGroupsGrid;