import { Fragment } from 'react';

import useMemberCoursesDetail from './UseMemberCoursesDetail';

import MemberCoursesForAdminRoleDropdown from '../../../../components/dropdowns/memberCoursesForAdminRoleDropdown/MemberCoursesForAdminRoleDropdown';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../../common/components/headings/Headings';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import style from './MemberCoursesDetail.module.css';
import global from '../../../../../common/components/GlobalStyle.module.css';

const MemberCoursesDetail = () => {
  const {
    isSaving,
    view,
    viewService,
    apiErrorMessage,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onReloadClicked,
    onBackToMemberAllCourseWrite,
  } = useMemberCoursesDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{viewService} Member Courses Detail</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className='col-xs-12 col-sm-4'>
            {viewService === 'Edit'
              ? <ReadOnly
                label='Name of Course'
                name='courseName'
                value={formState.courseName} />
              : <MemberCoursesForAdminRoleDropdown
                label="Courses"
                name="lmsCourseId"
                value={formState.lmsCourseId}
                error={errorState.lmsCourseId}
                message={errorState.lmsCourseId}
                onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'lmsCourseId', newValueLabel, 'courseName', e) }}
                roleName={view} />
            }
          </div>
          <div className="col-xs-12 col-sm-4">
            <DatePicker
              label="Completion Date*"
              name="courseCompletionDate"
              value={formState.courseCompletionDate}
              error={errorState.courseCompletionDate}
              message={errorState.courseCompletionDate}
              onChange={(value) => { onFormValueChanged('courseCompletionDate', value); }} />
          </div>
          <div className='col-xs-12 col-sm-4 usas-extra-top-margin'>
            <span className={style.YesNoFormatting}>
              <label>Expiration Date*</label>
              <YesNoSwitch
                name="noExpirationDate"
                checked={formState.noExpirationDate}
                error={errorState.noExpirationDate}
                message={errorState.noExpirationDate}
                onChange={(value) => { onFormValueChanged('noExpirationDate', value); }} />
            </span>
          </div>
          <span className={formState.noExpirationDate === null || formState.noExpirationDate === false ? global.HideComponent : global.DisplayComponent}>
            <div className="col-xs-12 col-sm-4">
              <DatePicker
                label="Expiration Date*"
                name="courseExpirationDate"
                value={formState.courseExpirationDate}
                error={errorState.courseExpirationDate}
                message={errorState.courseExpirationDate}
                onChange={(value) => { onFormValueChanged('courseExpirationDate', value); }}
                countOfYears={3}
                startYearOffset={0} />
            </div>
          </span>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type='button' onClick={onBackToMemberAllCourseWrite}>Cancel</SecondaryButton>&nbsp;
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={`${viewService} Course Error`}
        displayPopUp={apiErrorMessage !== ''}
        onModalCanceled={onBackToMemberAllCourseWrite}>
        <div className="row">
          <div className="col-xs-12">
            <p>{apiErrorMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            {viewService === 'Edit'
              ? <PrimaryButton type="button" onClick={onBackToMemberAllCourseWrite}>Cancel</PrimaryButton>
              : (
                <Fragment>
                  <PrimaryButton type="button" onClick={onReloadClicked}>Reload</PrimaryButton>&nbsp;
                  <SecondaryButton type='button' onClick={onBackToMemberAllCourseWrite}>Cancel</SecondaryButton>&nbsp;
                </Fragment>
              )
            }
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving && apiErrorMessage === ''} />
    </Fragment>
  );
};

export default MemberCoursesDetail;