import NavLinks from '../NavLinks';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from "../../../../../common/state/security/UseSecurityData";

const TAXONOMIES = ['MainMenu'];
const SCOPE = 'OtsLscBulk';

const useOtsOfficialLscBulkRecertifySelection = () => {
    const { OTS_LSC_BULK_RECERTIFICATION_ROLE_GROUP_ID } = useEnvironmentVariableData();;
    const { contextSecurityState } = useSecurityData();

    return {
        TAXONOMIES,
        SCOPE,
        OTS_LSC_BULK_RECERTIFICATION_ROLE_GROUP_ID,
        NavLinks,
        contextSecurityState
    };
};

export default useOtsOfficialLscBulkRecertifySelection;