import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsOfficialData from '../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';
import usePersonPositionCertificationData from '../../../../state/personPositionCertification/UsePersonPositionCertificationData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  showAllLscCertificationHistory: false,
};

const INITIAL_STATE = {
  isViewSet: false,
  isHeaderInfoSet: false,
};

const INITIAL_RECERTIFY_STATE = {
  recertifyPersonPositionCertificationIds: []
};

const INITIAL_DELETE_LSC_CERTIFICATION_MODAL_STATE = {
  personPositionCertificationId: Constants.DEFAULT_ID,
  officialPositionId: Constants.DEFAULT_ID,
  officialPositionTypeId: Constants.DEFAULT_ID,
  positionTypeName: '',
  positionAbbreviation: '',
  sessionsWorked: 0,
  effectiveDate: Constants.BLANK_DATE_STRING,
  expirationDate: Constants.BLANK_DATE_STRING,
  modalTitle: 'Delete LSC Certification?',
  certificationLevelId: Constants.DEFAULT_ID,
  levelName: '',
  isActive: '',
  reCertify: '',
  displayPopUp: false
};

const useOtsOfficialLscCertification = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    resetForm
  } = useForm(INITIAL_FORM_STATE);
  const [deleteLscCertificationModalState, setDeleteLscCertificationModalState] = useState(INITIAL_DELETE_LSC_CERTIFICATION_MODAL_STATE);
  const [state, setState] = useState(INITIAL_STATE);
  const [recertifyState, setRecertifyState] = useState(INITIAL_RECERTIFY_STATE);
  const { basePersonState } = useBasePersonData();
  const { otsOfficialLscState, otsOfficialHeaderState, getOtsOfficialLscCertificationsByPersonId } = useOtsOfficialData();
  const { personPositionCertificationState, deletePersonPositionCertification, confirmPersonPositionCertificationSave } = usePersonPositionCertificationData();

  const onAddLscCert = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes?.OTS_OFFICIAL_LSCCERTIFICATIONS_DETAIL?.route);
  };

  const onNavigateToLscRecertifyView = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const recertifyStateArray = recertifyState.recertifyPersonPositionCertificationIds

    navigate(navRoutes?.OTS_OFFICIAL_LSCCERTIFICATIONS_RECERTIFY?.route, { state: { recertifyStateArray } });
  };

  const onEditLscCert = (e, positionObj) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const personPositionCertificationId = positionObj?.personPositionCertificationId;
    navigate(navRoutes?.OTS_OFFICIAL_LSCCERTIFICATIONS_DETAIL?.route, { state: { positionObj, personPositionCertificationId } });
  };

  const onOpenDeleteLscCertificationModal = (e, officialLscCert) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setDeleteLscCertificationModalState({
      ...deleteLscCertificationModalState,
      personPositionCertificationId: officialLscCert?.personPositionCertificationId,
      officialPositionId: officialLscCert?.officialPositionId,
      officialPositionTypeId: officialLscCert?.officialPositionTypeId,
      positionTypeName: officialLscCert?.positionTypeName,
      positionAbbreviation: officialLscCert?.positionAbbreviation,
      sessionsWorked: officialLscCert?.sessionsWorked,
      effectiveDate: officialLscCert?.effectiveDate,
      expirationDate: officialLscCert?.expirationDate,
      displayPopUp: true,
      certificationLevelId: officialLscCert?.certificationLevelId,
      levelName: officialLscCert?.levelName,
      isActive: officialLscCert?.isActive,
      reCertify: officialLscCert?.reCertify,
    });
  };

  const onDeleteLscCertificationModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setDeleteLscCertificationModalState({ ...INITIAL_DELETE_LSC_CERTIFICATION_MODAL_STATE });
  };

  const onDeleteOfficialLscCertification = (e, state) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const personPositionLscCertificationId = state?.personPositionCertificationId;
    deletePersonPositionCertification(personPositionLscCertificationId);
    onDeleteLscCertificationModalCanceled();
    setLocalViewSetStateToFalse();
  };

  const setLocalViewSetStateToTrue = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ isViewSet: true });
  };

  const setLocalViewSetStateToFalse = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({ isViewSet: false });
  };

  const onAddOrRemoveLscCertficationForRecertify = (checked, lscCertifcation, personPositionCertificationId) => {
    const newArray = [...recertifyState.recertifyPersonPositionCertificationIds];
    const object = newArray?.find(x => x.personPositionCertificationId === personPositionCertificationId);
    let index = newArray.findIndex(x => x.personPositionCertificationId === personPositionCertificationId);

    if (checked === true) {
      //add to state array
      if (object === undefined) {
        newArray.push(lscCertifcation);
      }
    } else {
      //remove from state array
      if (Object?.keys(object)?.length > 0) {
        newArray.splice(index, 1);
      }
    }

    setRecertifyState({
      ...recertifyState,
      recertifyPersonPositionCertificationIds: newArray
    });
  };

  useEffect(() => {
    if (((Array.isArray(otsOfficialLscState?.arrayData?.certifications) === true && otsOfficialLscState?.arrayData?.certifications?.length >= 0
      && Array.isArray(personPositionCertificationState?.arrayData) && personPositionCertificationState?.arrayData.length > 0
      && state?.isViewSet === false)
      || (personPositionCertificationState?.isSaved === true && Object?.keys(personPositionCertificationState?.objData)?.length > 0))) {
      const personId = otsOfficialHeaderState?.objData?.personId
      getOtsOfficialLscCertificationsByPersonId(personId);
      setLocalViewSetStateToTrue();
      if (personPositionCertificationState?.isSaved === true) {
        confirmPersonPositionCertificationSave();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsOfficialLscState?.arrayData, personPositionCertificationState?.objData, personPositionCertificationState?.isSaved, state?.isViewSet]);

  return {
    handleSubmit,
    formState,
    errorState,
    onFormValueChanged,
    resetForm,
    basePersonState,
    onEditLscCert,
    onAddLscCert,
    onOpenDeleteLscCertificationModal,
    onDeleteLscCertificationModalCanceled,
    deleteLscCertificationModalState,
    otsOfficialLscState,
    personPositionCertificationState,
    onNavigateToLscRecertifyView,
    otsOfficialHeaderState,
    onDeleteOfficialLscCertification,
    onAddOrRemoveLscCertficationForRecertify,
    recertifyState
  };
};

export default useOtsOfficialLscCertification;