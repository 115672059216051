import { useState } from 'react';

import OrgRoleStatusData from './OrgRoleStatusData';

const useOrgRoleStatusData = () => {
  const [orgRoleStatusState, setOrgRoleStatusState] = useState(OrgRoleStatusData.INITIAL_STATE);
  const getOrgRoleStatus = (orgRoleId) => OrgRoleStatusData.GetOrgRoleStatusData(orgRoleId, orgRoleStatusState, setOrgRoleStatusState);

  return {
    orgRoleStatusState,
    getOrgRoleStatus
  };
};

export default useOrgRoleStatusData;