import React, { Fragment } from 'react';

import MemberIneligibleToCompeteLargeGrid from './MemberIneligibleToCompeteLargeGrid';
import MemberIneligibleToCompeteSmallGrid from './MemberIneligibleToCompeteSmallGrid';

const MemberIneligibleToCompeteGrid = ({ gridData, isLoading, onEdit, onDelete }) => (
  <Fragment>
    <MemberIneligibleToCompeteLargeGrid
      gridData={gridData}
      isLoading={isLoading}
      onEdit={onEdit}
      onDelete={onDelete} />
    <MemberIneligibleToCompeteSmallGrid
      gridData={gridData}
      isLoading={isLoading}
      onEdit={onEdit}
      onDelete={onDelete} />
  </Fragment>
);

export default MemberIneligibleToCompeteGrid;