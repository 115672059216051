import OfficialPositionCertificationTypeProgressionDropdown from '../../../../components/dropdowns/officialPositionCertificationTypeProgressionDropdown/OfficialPositionCertificationTypeProgressionDropdown';

import PlusIcon from '../../../../../common/components/icons/PlusIcon';
import LookupIcon from '../../../../../common/components/icons/LookupIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ gridStateArrayData, position, personId, personPositionCertificationRequestId, onDeleteRow,
  showError, onReviewClicked, expirationDateOptions, statusOptions, onUpdatePositionCertificationProgressionIdValueChange,
  onUpdateExpirationDateValueChange, onUpdateStatusValueChange }) => {
  return (
    <tr>
      <td>
        <OfficialPositionCertificationTypeProgressionDropdown
          label=""
          personId={personId}
          personPositionCertificationRequestId={personPositionCertificationRequestId}
          name={"positionCertificationProgressionId" + position?.personPositionCertificationProgressionId + "personPositionCertificationProgressionId"}
          value={position?.selectedPositionCertificationProgressionId}
          onChange={(newValue, newValueLabel, e) => { onUpdatePositionCertificationProgressionIdValueChange(e, position, newValue, newValueLabel); }}
          areValuesIntegers={true}
          error={showError === true && (position?.selectedPositionCertificationProgressionId < 0 || gridStateArrayData.find(x => x.selectedPositionCertificationProgressionId === position?.selectedPositionCertificationProgressionId &&
            x.personPositionCertificationProgressionId !== position?.personPositionCertificationProgressionId)) ? true : false}
          message={position?.selectedPositionCertificationProgressionId < 0 && showError === true ? 'Please select a Position & Level' : gridStateArrayData.find(x => x.selectedPositionCertificationProgressionId === position?.selectedPositionCertificationProgressionId &&
            x.personPositionCertificationProgressionId !== position?.personPositionCertificationProgressionId) && showError === true ? 'At least one other row has the same Position & Level selected. Position & Level must be unique for each row.' : ''}
        />
      </td>
      <td>{position.effectiveDate ? formatDate(position.effectiveDate) : <span>&nbsp;</span>}</td>
      <td>
        <Dropdown
          label=""
          options={expirationDateOptions}
          name={"expirationDate" + position?.personPositionCertificationProgressionId + "personPositionCertificationProgressionId"}
          value={position?.selectedExpirationDateId}
          onChange={(newValue, newValueLabel, e) => { onUpdateExpirationDateValueChange(e, position, newValue, newValueLabel); }}
          areValuesIntegers={true}
          error={position?.selectedExpirationDateId < 0 && showError === true ? true : false}
          message={position?.selectedExpirationDateId < 0 && showError === true ? 'Please select an Expiration Date' : ''}
        />
      </td>
      <td>
        <Dropdown
          label=""
          options={statusOptions}
          name={"status" + position?.personPositionCertificationProgressionId + "personPositionCertificationProgressionId"}
          value={position?.selectedStatusId}
          onChange={(newValue, newValueLabel, e) => { onUpdateStatusValueChange(e, position, newValue, newValueLabel); }}
          areValuesIntegers={true}
          error={position?.selectedStatusId < 0 && showError === true ? true : false}
          message={position?.selectedStatusId < 0 && showError === true ? 'Please select a Status' : ''}
        />
      </td>
      <td className="text-center">
        {position.index >= 0 ?
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onDeleteRow(e, position)}>
            <DeleteIcon toolTipText='Delete Row' />
          </button> :
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onReviewClicked(position.personPositionCertificationProgressionId)}>
            <LookupIcon toolTipText='Review (Re)certification' />
          </button>
        }
      </td>
    </tr>
  );
};

const GridLarge = ({ personId, personPositionCertificationRequestId, showError, gridState,
  officialPositionCertificationTypeProgressionState, onAddRow, onDeleteRow, onReviewClicked,
  expirationDateOptions, statusOptions, onUpdatePositionCertificationProgressionIdValueChange,
  onUpdateExpirationDateValueChange, onUpdateStatusValueChange }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Position & Level</th>
          <th>Effective Date</th>
          <th>Expiration Date</th>
          <th>Status</th>
          <th className='pull-right'>
            {onAddRow && gridState.arrayData.length < (officialPositionCertificationTypeProgressionState.options.length - 1) &&
              <button className={global.IconButtonMargin} type="button" onClick={(e) =>
                onAddRow(e)}>
                <PlusIcon toolTipText='Add Row' />
              </button>
            }
          </th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(gridState.arrayData) && gridState.arrayData.length > 0
          && gridState.arrayData.map((position, i) => <GridRow key={i} gridStateArrayData={gridState.arrayData} position={position} personId={personId}
            personPositionCertificationRequestId={personPositionCertificationRequestId} showError={showError} onDeleteRow={onDeleteRow} onReviewClicked={onReviewClicked}
            expirationDateOptions={expirationDateOptions} statusOptions={statusOptions} onUpdatePositionCertificationProgressionIdValueChange={onUpdatePositionCertificationProgressionIdValueChange}
            onUpdateExpirationDateValueChange={onUpdateExpirationDateValueChange} onUpdateStatusValueChange={onUpdateStatusValueChange} />)}
        {(!Array.isArray(gridState.arrayData) || gridState.arrayData.length === 0)
          && <tr><td colSpan={5}>There are no applications available to review.</td></tr>}
      </tbody>
    </table >
  );
};

export default GridLarge;