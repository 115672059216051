import { useEffect, useState } from 'react';

import validate from './ApplyValidation';

import useOtsCertificationApplicationData from '../../../../state/otsCertificationApplication/UseOtsCertificationApplicationData';
import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';
import useForm from '../../../../../common/utils/UseForm';

const INITIAL_VIEW_STATE = {
  checkboxlist: [],
  positionCertificationProgressions: [],
  showRules: false,
  rulePageNumber: 1,
  error: false,
  message: ''
};

const INITIAL_FORM_STATE = {
  positionCertificationProgressionIds: []
};

const INITIAL_MODAL_STATE = {
  modalTitle: 'Application Confirmation',
  displayPopUp: false,
}

const useApply = (availableCerts, buttonClickHandlers) => {
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const [modalState, setModalState] = useState({ ...INITIAL_MODAL_STATE });
  const { basePersonState } = useBasePersonData();
  const { addNewApplications } = useOtsCertificationApplicationData();
  const {
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onCheckboxFormValueChanged = (value) => {
    onFormValueChanged('positionCertificationProgressionIds', value);
  };

  const onOpenModal = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
    });
  };

  const onApply = () => {
    if (formState.positionCertificationProgressionIds.length > 0) {
      const positionCertificationProgressionIdsFormatted = formState.positionCertificationProgressionIds.map(x => { return ({ positionCertificationProgressionId: x.id }) });
      addNewApplications(basePersonState.objData.personId, positionCertificationProgressionIdsFormatted)
        .then(data => {
          if (data.objData?.message) {
            onOpenModal();
          } else {
            buttonClickHandlers.onCancelClicked();
          }
        })
        .catch(e => {
          console.log(e);

          setState({
            ...state,
            error: true,
            message: 'There was an error while creating your application. Please start over.'
          });
        });
    }
  };

  function submitFormCallback() {
    onSelect();
  }

  const onSelect = () => {
    if (formState.positionCertificationProgressionIds.length > 0) {
      let positionCertificationProgressions = [];
      for (let i = 0; i < formState.positionCertificationProgressionIds.length; i++) {
        let availableCertsFiltered = availableCerts.find(x => x.positionCertificationProgressionId === formState.positionCertificationProgressionIds[i].id);
        if (availableCertsFiltered) {
          positionCertificationProgressions.push(availableCertsFiltered)
        }
      }
      setState({
        ...state,
        showRules: true,
        rulePageNumber: 1,
        positionCertificationProgressions
      });
    }
  };

  const onBackClicked = () => {
    setState({
      ...state,
      showRules: false,
      rulePageNumber: 1
    });
    setIsDirty(true);
  };

  const onNextRuleClicked = () => {
    setState({
      ...state,
      rulePageNumber: state.rulePageNumber + 1
    });
  };

  const onBackToPreviousRuleClicked = () => {
    setState({
      ...state,
      rulePageNumber: state.rulePageNumber - 1,
    });
  };

  useEffect(() => {
    if (Array.isArray(availableCerts) === true) {
      if (availableCerts.length > 0) {
        const checkboxlist = availableCerts.map(x => {
          return {
            id: x.positionCertificationProgressionId,
            name: x.progressionName
          };
        });
        setState({
          ...state,
          checkboxlist,
          error: false,
          message: ''
        });
      } else {
        setState({
          ...state,
          checkboxlist: [],
          error: true,
          message: 'There are no available positions to add to your membership'
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCerts]);

  return {
    ...state,
    formState,
    errorState,
    handleSubmit,
    onCheckboxFormValueChanged,
    onApply,
    onSelect,
    onBackClicked,
    onNextRuleClicked,
    onBackToPreviousRuleClicked,
    modalState
  };
};

export default useApply;