export function formatPlaceString(finalPosition) {
  if (finalPosition !== null) {
    const position = finalPosition.toString();

    if (position) {
      if (position.length > 1) {
        const lastTwoDigits = position.slice(-2);

        if (lastTwoDigits === '11' || lastTwoDigits === '12' || lastTwoDigits === '13') {
          return `${position}th`;
        }
      }
      const lastDigit = position.slice(-1);

      if (lastDigit === '1') {
        return `${position}st`;
      }
      else if (lastDigit === '2') {
        return `${position}nd`;
      }
      else if (lastDigit === '3') {
        return `${position}rd`;
      }
      else {
        return `${position}th`;
      }
    }
  }

  return finalPosition;
}