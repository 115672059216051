import { useContext, useState } from 'react';

import { UserStateContext } from './UserContextProvider';
import UserData from './UserData';

const useUserData = () => {
  const [userState, setUserState] = useContext(UserStateContext);
  const [postUserCanvasState, setPostUserCanvasState] = useState(UserData.INITIAL_STATE);
  const [postUserCssState, setPostUserCssState] = useState(UserData.INITIAL_STATE);

  const getUser = () => {
    UserData.getUser(userState, setUserState);
  };

  const postUserCanvas = () => {
    UserData.postUserCanvas(postUserCanvasState, setPostUserCanvasState);
  }

  const postUserCss = () => {
    UserData.postUserCss(postUserCssState, setPostUserCssState);
  }

  return {
    userState,
    setUserState,
    getUser,
    postUserCanvas,
    postUserCss,
    postUserCanvasState, 
    setPostUserCanvasState,
    postUserCssState, 
    setPostUserCssState
  };
};

export default useUserData;