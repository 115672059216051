import React, { Fragment } from 'react';

import useOtsMeetEvaluationsDetail from './UseOtsMeetEvaluationsDetail';

import OfficialSelectionForEvaluationGrid from './OfficialSelectionForEvaluationGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsMeetEvaluationsDetail = () => {
  const {
    showError,
    gridState,
    otsMeetHeaderState,
    officialSelectionForEvaluationState,
    MeetEvaluatorPositionLevelState,
    otsPositionWorkedEvaluationLevelEvaluatorNameState,
    isLoading,
    isSaving,
    errorState,
    onUpdateOfficialNameValueChange,
    onUpdateOfficialPositionWorkedValueChange,
    onUpdateEvaluatorNameValueChange,
    onUpdateOverrideEvaluatorNameValueChange,
    onUpdateEvaluationLevelValueChange,
    onUpdateOverrideEvaluationLevelValueChange,
    handleSubmit,
    Constants,
    onSaveClicked,
    onBackClicked,
    onAddOverrideEvaluationClicked,
    isLarge
  } = useOtsMeetEvaluationsDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <Headings.H3>Add Evaluation(s)</Headings.H3>
        </div>
        <div className="col-xs-12 usas-extra-bottom-margin">
          <Headings.H5>To add an evaluation for an official's position worked, please select both an Evaluator Name and an Evaluation Level in the corresponding row. If you do not wish to add an evaluation for an official's position worked, simply leave both dropdowns in that row blank.</Headings.H5>
        </div>
        <div className="usas-extra-bottom-margin">
          <div className='col-xs-12 col-md-3 usas-extra-top-margin'>
            <ActionIntraPageButton onClick={(e) => onAddOverrideEvaluationClicked(e)}>Add Override Evaluation</ActionIntraPageButton>
          </div>
          <div className='col-xs-12 col-md-9'>
            <p>**The 'Add Override Evaluation' button creates a new grid row. Please select an Official Name, a Position Worked, an Evaluation Level, and an Evaluator Name on the new grid row.
            </p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-bottom-margin">
          <div className='col-xs-12'>
            <OfficialSelectionForEvaluationGrid
              state={officialSelectionForEvaluationState}
              otsMeetHeaderState={otsMeetHeaderState}
              gridState={gridState}
              showError={showError}
              evaluators={MeetEvaluatorPositionLevelState.options}
              otsPositionWorkedEvaluationLevelEvaluatorNameState={otsPositionWorkedEvaluationLevelEvaluatorNameState}
              onUpdateOfficialNameValueChange={onUpdateOfficialNameValueChange}
              onUpdateOfficialPositionWorkedValueChange={onUpdateOfficialPositionWorkedValueChange}
              onUpdateEvaluatorNameValueChange={onUpdateEvaluatorNameValueChange}
              onUpdateOverrideEvaluatorNameValueChange={onUpdateOverrideEvaluatorNameValueChange}
              onUpdateEvaluationLevelValueChange={onUpdateEvaluationLevelValueChange}
              onUpdateOverrideEvaluationLevelValueChange={onUpdateOverrideEvaluationLevelValueChange}
              isLarge={isLarge}
            />
          </div>
        </div>
        {errorState.general &&
          <div className="row">
            <div className="col-xs-12">
              <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className='col-xs-12 usas-extra-top-margin'>
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  )
}

export default OtsMeetEvaluationsDetail;