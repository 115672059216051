import ToIntIfInt from '../../utils/ToIntIfInt';

const useRadioListWithMultiCheckboxChildren = (hierarchicalOptions, currentValues, onChange) => {
  const formatChildLabel = (parentName, childOptionsName) => {
    const regex = /(?<p>^P[1-9])/i;
    const match = regex.exec(parentName);
    return `${match && match.groups && match.groups.p ? match.groups.p : parentName} ${childOptionsName ? childOptionsName : 'options'} - select one or more that apply`;
  };

  const formatOptions = () => {
    const options = [];
    hierarchicalOptions.forEach(option => {
      const newOption = {
        ...option,
        childObjects: [...(option.childObjects)],
        isChecked: false,
        currentChildValues: []
      };
      const isRadioChecked = currentValues.find(x => x.id === option.id) !== undefined;
      newOption.isChecked = isRadioChecked;
      newOption.childObjects.forEach(child => {
        if (currentValues.find(x => x.id === child.id)) {
          newOption.currentChildValues.push({ id: child.id, name: child.name })
        }
      });
      options.push(newOption);
    });

    return options;
  };

  const onRadioCheckedChanged = (e) => {
    const radioValue = ToIntIfInt(e.target.value);
    const found = hierarchicalOptions.find(x => x.id === radioValue);
    if (found) {
      onChange([{ id: found.id, name: found.name }]);
    }
  };

  const onCheckboxCheckedChanged = (newValue, parentId) => {
    const parent = hierarchicalOptions.find(x => x.id === parentId);
    const valueToSend = parent && Array.isArray(newValue) ? [{ id: parent.id, name: parent.name }, ...newValue] : newValue;
    onChange(valueToSend);
  };

  return {
    formatChildLabel,
    options: formatOptions(),
    onRadioCheckedChanged,
    onCheckboxCheckedChanged
  };
};

export default useRadioListWithMultiCheckboxChildren;