import GridLarge from './GridLarge';
import GridSmall from './GridSmall';

const Grid = ({ personId, personPositionCertificationRequestId, showError, gridState, officialPositionCertificationTypeProgressionState, 
  onAddRow, onDeleteRow, onReviewClicked, expirationDateOptions, statusOptions,
  onUpdatePositionCertificationProgressionIdValueChange, onUpdateExpirationDateValueChange, onUpdateStatusValueChange }) => {
  return (
    <>
      <GridLarge personId={personId} personPositionCertificationRequestId={personPositionCertificationRequestId} showError={showError} gridState={gridState}
        officialPositionCertificationTypeProgressionState={officialPositionCertificationTypeProgressionState} onAddRow={onAddRow} onDeleteRow={onDeleteRow} onReviewClicked={onReviewClicked}
        expirationDateOptions={expirationDateOptions} statusOptions={statusOptions} onUpdatePositionCertificationProgressionIdValueChange={onUpdatePositionCertificationProgressionIdValueChange}
        onUpdateExpirationDateValueChange={onUpdateExpirationDateValueChange} onUpdateStatusValueChange={onUpdateStatusValueChange} />
      <GridSmall personId={personId} personPositionCertificationRequestId={personPositionCertificationRequestId} showError={showError} gridState={gridState}
        officialPositionCertificationTypeProgressionState={officialPositionCertificationTypeProgressionState} onAddRow={onAddRow} onDeleteRow={onDeleteRow} onReviewClicked={onReviewClicked}
        expirationDateOptions={expirationDateOptions} statusOptions={statusOptions} onUpdatePositionCertificationProgressionIdValueChange={onUpdatePositionCertificationProgressionIdValueChange}
        onUpdateExpirationDateValueChange={onUpdateExpirationDateValueChange} onUpdateStatusValueChange={onUpdateStatusValueChange} />
    </>
  );
};

export default Grid;