import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOfferingManagementInstanceOrgUnitData from '../../../../state/offeringManagementInstanceOrgUnit/UseOfferingManagementInstanceOrgUnitData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useReportPeriodSeasonData from '../../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import { DEFAULT_ID } from '../../../../../common/utils/Constants';

const INITIAL_STATE = {
  reportPeriodId: DEFAULT_ID,
  periodName: '',
  reportPeriodObj: {},
  pageErrorMessage: ''
};

const useLscOfferingInstanceOrgUnit = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { reportPeriodSeasonState, getReportPeriodSeason } = useReportPeriodSeasonData();
  const { offeringManagementInstanceOrgUnitState, getOfferingInstanceOrgUnits
  } = useOfferingManagementInstanceOrgUnitData();
  const [state, setState] = useState(INITIAL_STATE);

  const onAddOfferingInstanceOrgUnitClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_LSC_OFFERING_ADD?.route, {
      state: {
        reportPeriod: state.reportPeriodObj
      }
    });
  };

  const onEditOfferingInstanceOrgUnitClicked = (e, offeringInstance) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_LSC_OFFERING_EDIT?.route, {
      state: {
        offeringInstance,
        reportPeriod: state.reportPeriodObj
      }
    });
  };

  const onAddAdditionalOfferingInstanceOrgUnitClicked = (e, offeringInstance) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_LSC_OFFERING_ADD?.route, {
      state: {
        offeringInstance,
        reportPeriod: state.reportPeriodObj
      }
    });
  };

  const onEditAdditionalOfferingInstanceOrgUnitClicked = (e, offeringInstance, offeringInstanceOrgUnitId) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_LSC_OFFERING_EDIT?.route, {
      state: {
        offeringInstance,
        offeringInstanceOrgUnitId,
        reportPeriod: state.reportPeriodObj
      }
    });
  };

  const onReportPeriodChanged = (reportPeriodId, periodName) => {
    if (offeringManagementInstanceOrgUnitState.isArrayLoading === false) {
      const reportPeriodObj = reportPeriodSeasonState.arrayData.find((x) => x.reportPeriodId === reportPeriodId) || {};

      setState({
        ...state,
        reportPeriodId,
        periodName,
        reportPeriodObj
      });

      getOfferingInstanceOrgUnitsHelper(reportPeriodId);
    }
  };

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoading === false) {
      if (reportPeriodSeasonState.isArrayLoaded === true && reportPeriodSeasonState.organizationId === USAS_ORGANIZATION_ID) {
        const defaultReportPeriod = reportPeriodSeasonState.options.find((x) => x.id === offeringManagementInstanceOrgUnitState.reportPeriodId) || reportPeriodSeasonState.currentAndFutureOptions[0];

        if (defaultReportPeriod) {
          onReportPeriodChanged(defaultReportPeriod.id, defaultReportPeriod.name);
        }
      } else if (USAS_ORGANIZATION_ID) {
        getReportPeriodSeason(USAS_ORGANIZATION_ID, false, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodSeasonState, USAS_ORGANIZATION_ID]);

  function getOfferingInstanceOrgUnitsHelper(reportPeriodId) {
    const orgUnitId = selectOrgUnitState.orgUnitId;

    // Note: objData is only used for POST/PUT/DELETE so if objData is loaded, refresh arrayData
    if (offeringManagementInstanceOrgUnitState.isArrayLoaded === false || reportPeriodId !== offeringManagementInstanceOrgUnitState.reportPeriodId
      || orgUnitId !== offeringManagementInstanceOrgUnitState.orgUnitId || offeringManagementInstanceOrgUnitState.isObjLoaded === true) {
      const getOfferingInstanceOrgUnitsPromise = getOfferingInstanceOrgUnits(orgUnitId, reportPeriodId);

      if (getOfferingInstanceOrgUnitsPromise ?? false) {
        getOfferingInstanceOrgUnitsPromise.catch((e) => {
          setState({
            ...state,
            pageErrorMessage: e?.message || 'Failed to retrieve offerings'
          });
        });
      }
    }
  }
  
  function getCanAddAdditionalInstance(reportPeriodObj) {
    const today = new Date();
    const reportPeriodEndDate = new Date(reportPeriodObj?.endDate);

    return today < reportPeriodEndDate;
  }

  return {
    organizationId: USAS_ORGANIZATION_ID,
    canAddAdditionalInstance: getCanAddAdditionalInstance(state.reportPeriodObj),
    state,
    offeringManagementInstanceOrgUnitState,
    onReportPeriodChanged,
    onAddOfferingInstanceOrgUnitClicked,
    onEditOfferingInstanceOrgUnitClicked,
    onAddAdditionalOfferingInstanceOrgUnitClicked,
    onEditAdditionalOfferingInstanceOrgUnitClicked
  };
};

export default useLscOfferingInstanceOrgUnit;