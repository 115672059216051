import React, { useState, createContext } from 'react';
import UserData from './UserData';

export const UserStateContext = createContext();

const UserContextProvider = ({ children }) => {
  const stateHook = useState({...UserData.INITIAL_STATE});

  return (
    <UserStateContext.Provider value={stateHook}>
      {children}
    </UserStateContext.Provider>
  );
};

export default UserContextProvider;