import React, { Fragment } from "react";

import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import EditIcon from "../../../../common/components/icons/EditIcon";
import HideIcon from "../../../../common/components/icons/HideIcon";
import ShowIcon from "../../../../common/components/icons/ShowIcon";
import SGColumnHeader from "../../../../common/components/grids/SGColumnHeader";
import SGConstants from "../../../../common/utils/SGConstants";
import useSortableGrid from "../../../../common/utils/UseSortableGrid";

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ personId, role, onEdit, onDelete }) => (
  <tr>
    <td>{role.orgRoleName || ''}</td>
    <td>{role.effectiveDate || ''}</td>
    <td>{role.expirationDate || ''}</td>
    <td>{role.representativeTypeValue || ''}</td>
    <td>{role.termLengthValue || ''}</td>
    <td>
      {onEdit !== undefined && role.isCurrent === true &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onEdit(personId, role.personOrgRoleDurationId)}>
          <EditIcon />
        </button>
      }
      {onDelete !== undefined && role.canDelete === true &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDelete(personId, role.personOrgRoleDurationId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const DetailGrid = ({ personId, roleDurations, onEdit, onDelete }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Role</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th>Representative Type</th>
        <th>Term Length</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {roleDurations.map((role, i) => <DetailGridRow key={i} personId={personId} role={role} onEdit={onEdit} onDelete={onDelete} />)}
    </tbody>
  </table>
);

const GridRow = ({ staff, onEdit, onDelete, formState, expandedId, onExpandClicked }) => {
  const committeeObJExistisObj = staff?.roleDurations?.find(x => x.orgRoleId === formState?.committeeId);
  if (formState?.committeeId !== '' && committeeObJExistisObj !== undefined) {
    return (
      <Fragment>
        <tr>
          <td colSpan="3">{staff.name || ''}</td>
          <td colSpan="2">{staff.email || ''}</td>
          <td colSpan="2">{staff?.roleDurations[0].isInGoodStandingExpirationDate}</td>
          <td>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onExpandClicked(e, staff.personId)}>
              {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
            </button>
          </td>
        </tr>
        {Array.isArray(staff.roleDurations) && staff.roleDurations.length > 0 && expandedId === staff.personId &&
          <tr className={global.Expanded}>
            <td colSpan="8">
              <DetailGrid personId={staff.personId} roleDurations={staff.roleDurations} onEdit={onEdit} onDelete={onDelete} />
            </td>
          </tr>
        }
      </Fragment>
    )
  } else if (formState?.memberName !== '' && formState?.memberName === staff.name) {
    return (
      <Fragment>
        <tr>
          <td colSpan="3">{staff.name || ''}</td>
          <td colSpan="2">{staff.email || ''}</td>
          <td colSpan="2">{staff?.roleDurations[0].isInGoodStandingExpirationDate}</td>
          <td>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onExpandClicked(e, staff.personId)}>
              {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
            </button>
          </td>
        </tr>
        {Array.isArray(staff.roleDurations) && staff.roleDurations.length > 0 && expandedId === staff.personId &&
          <tr className={global.Expanded}>
            <td colSpan="8">
              <DetailGrid personId={staff.personId} roleDurations={staff.roleDurations} onEdit={onEdit} onDelete={onDelete} />
            </td>
          </tr>
        }
      </Fragment>
    )
  } else if (formState?.committeeId === '' && formState?.memberName === '') {
    return (
      <Fragment>
        <tr>
          <td colSpan="3">{staff.name || ''}</td>
          <td colSpan="2">{staff.email || ''}</td>
          <td colSpan="2">{staff?.roleDurations[0].isInGoodStandingExpirationDate}</td>
          <td>
            <button
              className={global.IconButtonMargin}
              type="button"
              onClick={(e) => onExpandClicked(e, staff.personId)}>
              {expandedId === staff.personId ? <HideIcon /> : <ShowIcon />}
            </button>
          </td>
        </tr>
        {Array.isArray(staff.roleDurations) && staff.roleDurations.length > 0 && expandedId === staff.personId &&
          <tr className={global.Expanded}>
            <td colSpan="8">
              <DetailGrid personId={staff.personId} roleDurations={staff.roleDurations} onEdit={onEdit} onDelete={onDelete} />
            </td>
          </tr>
        }
      </Fragment>
    );
  }
  else {
    return (
      <Fragment />
    )
  }
};

const HQCommitteeMembersGridLarge = ({ data, isLoading, onEdit, onDelete, formState, expandedId, onExpandClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader colSpan="3" sortableGridState={sortableGridState} columnLabel={'HQ Committee Member Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th colSpan="2">Email</th>
          <th>Is In Good Standing Expiration Date</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="8">Loading...</td></tr>
          : sortableGridState?.sortedGridData?.length > 0
            ? sortableGridState?.sortedGridData?.map((staff, i) =>
              <GridRow
                key={i}
                staff={staff}
                onEdit={onEdit}
                onDelete={onDelete}
                formState={formState}
                expandedId={expandedId}
                onExpandClicked={onExpandClicked} />)
            : <tr><td colSpan="8">No HQ Committee Members</td></tr>
        }
      </tbody>
    </table>
  );
};

export default HQCommitteeMembersGridLarge;