import { useEffect, useState } from 'react';
import useForm from '../../../../common/utils/UseForm';
import validate from './MemberCardLookupValidation';

import useMemberCardData from '../../../../common/state/memberCard/UseMemberCardData';
import usePersonByMemberIdData from '../../../../common/state/personByMemberId/UsePersonByMemberIdData';
import usePersonPersonasNoContextData from '../../../state/personPersonas/UsePersonPersonasNoContextData';
import Constants from '../../../../common/utils/Constants';

const useMemberCardLookup = () => {
  const { formState, errorState, handleSubmit, updateFormState, onValueTextPairChanged, setFormData, setIsDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const { memberCardState, getMemberCard } = useMemberCardData();
  const { personByMemberIdState, getFlatPersonByMemberId } = usePersonByMemberIdData();
  const { personPersonasState, getPersonPersonas } = usePersonPersonasNoContextData();
  const [ uiState, setUiState ] = useState({memberFound: undefined})

  function submitFormCallback(formState) {
    if (formState.personaId !== Constants.DEFAULT_ID) {
      getMemberCard(personByMemberIdState.objData.personId, formState.personaId);
    }
  };

  const isRequirementCurrent = (reqDate) => {
    const requirementExpDate = new Date(reqDate);
    const expDd = requirementExpDate.getDate();
    const expMm = requirementExpDate.getMonth() + 1;
    const expYy = requirementExpDate.getFullYear();
    const expDt = new Date(expMm + '-' + expDd + '-' + expYy);

    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yy = today.getFullYear();
    const todayDt = new Date(mm + '-' + dd + '-' + yy);

    return expDt >= todayDt;
  }

  useEffect(() => {
    if ((formState.memberId.length === 14 && 
      formState.memberId !== personByMemberIdState.objData.memberId && 
      (uiState.memberFound === true || uiState.memberFound === undefined)) || personByMemberIdState.message.length > 0
      ) {
      getFlatPersonByMemberId(formState.memberId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  useEffect(() => {
    if (personByMemberIdState.isObjLoaded === true && personByMemberIdState.message.length === 0) {
      setUiState({...uiState, memberFound: true});
      getPersonPersonas(personByMemberIdState.objData.personId);
    } else if (formState.memberId.length === 14) {
      setUiState({...uiState, memberFound: false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personByMemberIdState]);

  useEffect(() => {
    if (personPersonasState.isArrayLoaded === true && personPersonasState.arrayData.length > 0) {
      setFormData({
        ...formState,
        personaId: personPersonasState.arrayData[0].personaId
      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPersonasState]);

  function getInitialFormState() {
    return {
      memberId: '',
      personaId: Constants.DEFAULT_ID, 
      personaName: ''
    }
  };

  return {
    memberCardState,
    personPersonasState,
    formState,
    errorState,
    uiState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    isRequirementCurrent
  };
};

export default useMemberCardLookup;