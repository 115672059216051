import React, { createContext, useState } from 'react';

import CertificationLevelData from './CertificationLevelData';

export const CertificationLevelStateContext = createContext();

const CertificationLevelContextProvider = ({ children }) => {
  const stateHook = useState(CertificationLevelData.INITIAL_STATE);

  return (
    <CertificationLevelStateContext.Provider value={stateHook}>
      {children}
    </CertificationLevelStateContext.Provider>
  );
};

export default CertificationLevelContextProvider;