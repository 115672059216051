import Constants from '../../../utils/Constants';

import { isValid5DigitZip, isValid9DigitZip, isValidAddressLine, isValidDate, isValidNumber } from '../../../utils/validation';

export const NationalTeamRegistrationFormValidation = (formState) => {
  let errors = {};

  const passportEffectiveDate = new Date(formState.passportEffectiveDate);
  const passportExpirationDate = new Date(formState.passportExpirationDate);

  if (formState.groupAttendeeTypeId === Constants.DEFAULT_ID || formState.groupAttendeeTypeId === '') {
    errors.groupAttendeeTypeId = 'Attendee Type is required';
  }

  if (formState.groupRoleTypeId === Constants.DEFAULT_ID || formState.groupRoleTypeId === '') {
    errors.groupRoleTypeId = 'Role is required';
  }

  if (!formState.professionalSwimmer || formState.professionalSwimmer.trim() === '') {
    errors.professionalSwimmer = 'Professional Swimmer is required';
  }

  if (!formState.cityOfBirth || formState.cityOfBirth.trim() === '') {
    errors.cityOfBirth = 'City of Birth is required';
  }

  if (!formState.countryOfBirth || formState.countryOfBirth.trim() === '') {
    errors.countryOfBirth = 'Country of Birth is required';
  }

  if (!formState.fanMailAddress || formState.fanMailAddress.trim() === '') {
    errors.fanMailAddress = 'Fan Mail Address is required';
  } else if (!isValidAddressLine(formState.fanMailAddress.trim())) {
    errors.fanMailAddress = 'Fan Mail Address cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  if (!formState.fanMailCity || formState.fanMailCity.trim() === '') {
    errors.fanMailCity = 'Fan Mail City is required';
  }

  if (formState.fanMailStateName === '') {
    errors.fanMailStateCode = 'Fan Mail State is required';
  } else if (formState.fanMailStateCode === Constants.DEFAULT_ID || formState.fanMailStateCode === '') {
    errors.fanMailStateCode = 'Fan Mail State must be valid';
  }

  if (!formState.fanMailZip || formState.fanMailZip.trim() === '') {
    errors.fanMailZip = 'Fan Mail Zip Code is required';
  } else if (!isValid5DigitZip(formState.fanMailZip.trim()) && !isValid9DigitZip(formState.fanMailZip.trim())) {
    errors.fanMailZip = 'Fan Mail Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  }

  if (!formState.passportNumber || formState.passportNumber.trim() === '') {
    errors.passportNumber = 'Passport Number is required';
  }

  if (formState.passportEffectiveDate === Constants.BLANK_DATE_STRING || formState.passportEffectiveDate === '') {
    errors.passportEffectiveDate = 'Passport Effective Date is required';
  } else if (!isValidDate(formState.passportEffectiveDate)) {
    errors.passportEffectiveDate = 'Passport Effective Date must be a valid date';
  } else if (passportExpirationDate < passportEffectiveDate || formState.passportEffectiveDate === formState.passportExpirationDate) {
    errors.passportEffectiveDate = 'Passport Effective Date must be before the Passport Expiration Date';
  }

  if (formState.passportExpirationDate === Constants.BLANK_DATE_STRING || formState.passportExpirationDate === '') {
    errors.passportExpirationDate = 'Passport Expiration Date is required';
  } else if (!isValidDate(formState.passportExpirationDate)) {
    errors.passportExpirationDate = 'Passport Expiration Date must be a valid date';
  } else if (passportExpirationDate < passportEffectiveDate || formState.passportEffectiveDate === formState.passportExpirationDate) {
    errors.passportExpirationDate = 'Passport Expiration Date must be after the Passport Effective Date';
  }

  if (!formState.knownTravelerNumber || formState.knownTravelerNumber.trim() === '') {
    errors.knownTravelerNumber = 'Known Traveler Number is required';
  }

  if (formState.preferredAirlineId === Constants.DEFAULT_ID || formState.preferredAirlineId === '') {
    errors.preferredAirlineId = 'Preferred Airline is required';
  }

  if (formState.aisleWindowPreferenceId === Constants.DEFAULT_ID || formState.aisleWindowPreferenceId === '') {
    errors.aisleWindowPreferenceId = 'Aisle/Window Preference is required';
  }

  if (!formState.airlineStatus || formState.airlineStatus.trim() === '') {
    errors.airlineStatus = 'Airline Status is required';
  }

  if (!formState.primaryHealthProvider || formState.primaryHealthProvider.trim() === '') {
    errors.primaryHealthProvider = 'Primary Health Provider is required';
  }

  if (!formState.agent || formState.agent.trim() === '') {
    errors.agent = 'Agent is required';
  }

  if (!formState.highSchoolName || formState.highSchoolName.trim() === '') {
    errors.highSchoolName = 'High School Name is required';
  }

  if (!isValidNumber(formState.highSchoolGraduationYear)) {
    errors.highSchoolGraduationYear = 'High School Graduation Year is required';
  }

  if (!formState.collegeName || formState.collegeName.trim() === '') {
    errors.collegeName = 'College is required';
  }

  if (!isValidNumber(formState.collegeGraduationYear)) {
    errors.collegeGraduationYear = 'College Graduation Year is required';
  }

  return errors;
};

export default NationalTeamRegistrationFormValidation;