import React, { createContext, useState } from 'react';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import Constants from '../../utils/Constants';

export const OrgGroupPropertiesStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  groupTypeId: Constants.DEFAULT_ID
};

const OrgGroupPropertiesContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <OrgGroupPropertiesStateContext.Provider value={stateHook}>
      {children}
    </OrgGroupPropertiesStateContext.Provider>
  );
};

export default OrgGroupPropertiesContextProvider;