import { createGroupMemberObj, getNewGroupStatus } from "../../../../../common/utils/GroupsUtils";

const useListGroupForm = (personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj, onFormCompletion) => {
  
  function handleSubmit() {
    const orgGroupId = groupObj?.orgGroupId;
    const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
      ? memberGroupObj.personOrgGroupQualification[0]
      : undefined;

    if (personId && orgGroupId) {
      const groupStatus = getNewGroupStatus(personOrgGroupQualificationStatus, groupObj.requiresApproval);

      const groupMemberObj = createGroupMemberObj(personId, orgGroupId, [], [], personOrgGroupId, personOrgGroupQualification, groupStatus);

      onFormCompletion(groupMemberObj);
    }
  };

  return {
    handleSubmit
  };
};

export default useListGroupForm;