import { Fragment } from "react";

import useHqOfferingTypeClubAdd from "./UseHqOfferingTypeClubAdd";

import HqOfferingManagementContextLoadingModal from "../../HqOfferingManagementContextLoadingModal";

import OfferingTypeClubForm from "../../../components/forms/offering/OfferingTypeClubForm";

import Headings from "../../../../../../common/components/headings/Headings";

import { SAVING_MSG } from "../../../../../../common/utils/Constants";

const HqOfferingTypeClubAdd = () => {
  const {
    isSaving,
    state,
    onSubmitOfferingForm,
    onBackClicked
  } = useHqOfferingTypeClubAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add USA Swimming Club Offering</Headings.H3>
        </div>
      </div>
      {state.offeringObj?.offeringTypeId > 0 &&
        <OfferingTypeClubForm
          offeringObj={state.offeringObj}
          submitButtonText='Add Offering'
          onSubmitFormCallback={onSubmitOfferingForm}
          onBackClicked={onBackClicked} />
      }
      <HqOfferingManagementContextLoadingModal
        isLoading={isSaving}
        loadingMessage={SAVING_MSG}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default HqOfferingTypeClubAdd;