import { Fragment } from 'react';

import useHqOfferingInstanceEdit from './UseHqOfferingInstanceEdit';

import HqOfferingManagementContextLoadingModal from '../../HqOfferingManagementContextLoadingModal';

import OfferingInstanceEditForm from '../../../components/forms/offeringInstance/OfferingInstanceEditForm';

import Headings from '../../../../../../common/components/headings/Headings';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';

import { convertNumberToCurrencyString } from '../../../../../../common/utils/CurrencyUtils';
import { SAVING_MSG } from '../../../../../../common/utils/Constants';

const HqOfferingInstanceEdit = () => {
  const {
    isSaving,
    state,
    onSubmitOfferingInstanceForm,
    onBackClicked,
    onDeleteClicked,
    onDeletePopupConfirmClicked,
    onDeletePopupCancelClicked
  } = useHqOfferingInstanceEdit();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit Offering Instance</Headings.H3>
        </div>
      </div>
      {state.offeringInstanceObj?.offeringInstanceId > 0 && state.reportPeriodObj?.reportPeriodId > 0 &&
        <OfferingInstanceEditForm
          reportPeriodObj={state.reportPeriodObj}
          offeringInstanceObj={state.offeringInstanceObj}
          onSubmitFormCallback={onSubmitOfferingInstanceForm}
          onBackClicked={onBackClicked}
          onDeleteClicked={onDeleteClicked} />
      }
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete HQ Offering Instance?'}
        displayPopUp={state.displayDeletePopup === true}
        onModalCanceled={onDeletePopupCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Offering Name</b>: &nbsp;{state.deletePopupOfferingObj?.offeringName} </p>
            <p><b>Price</b>: &nbsp;${convertNumberToCurrencyString(state.deletePopupOfferingObj?.feeAmount)} </p>
            <p><b>Registration Start/End Dates</b>: &nbsp;{state.deletePopupOfferingObj?.defaultRegStartDate} - {state.deletePopupOfferingObj?.defaultRegEndDate}</p>
            <p><b>Effective/Expiration Dates</b>: &nbsp;{state.deletePopupOfferingObj?.defaultEffectiveDate} - {state.deletePopupOfferingObj?.defaultExpirationDate}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeletePopupConfirmClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeletePopupCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <HqOfferingManagementContextLoadingModal
        isLoading={isSaving}
        loadingMessage={SAVING_MSG}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default HqOfferingInstanceEdit;