import React from "react";

import Dropdown from "../../../common/components/dropdowns/Dropdown";
import useOrgRoleFieldDropdown from "./UseOrgRoleFieldDropdown";

const OrgRoleFieldDropdown = ({ field, formState, errorState, onFormValueChanged }) => {
  const {
    options
  } = useOrgRoleFieldDropdown(field.orgRoleFieldLookup);

  return (
    <div className="col-xs-12 col-sm-6 col-md-4">
      <Dropdown
        label={field.fieldLabel}
        name={field.fieldName}
        value={formState[field.fieldName]}
        error={errorState[field.fieldName]}
        message={errorState[field.fieldName]}
        areValuesIntegers={false}
        isLoading={options.length === 0}
        options={options}
        onChange={(newValue, newLabel, e) => {
          e && e.target && e.target.value &&
          onFormValueChanged(`${field.fieldName}`, newValue);
        }} />
    </div>
  );
};

export default OrgRoleFieldDropdown;