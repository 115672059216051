import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useOfferingManagementInstanceOrgUnitData from '../../../state/offeringManagementInstanceOrgUnit/UseOfferingManagementInstanceOrgUnitData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import usePersonOrgRoleData from '../../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const TAXONOMIES = ['LSCOfferingManagement'];
const SCOPE = 'Project';

const useLscOfferingManagementContextView = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { securityState, getContextSecurity } = useSecurityData();
  const { clearArrayData } = usePersonOrgRoleData();
  const { selectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();
  const { resetOfferingManagementInstanceOrgUnitState } = useOfferingManagementInstanceOrgUnitData();

  const onHomeClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_LSC_OFFERINGS?.route);
  };

  const onBackToSelectionClicked = (e) => {
    e?.preventDefault();

    clearArrayData();
    resetSelectOrgUnitState();
    resetOfferingManagementInstanceOrgUnitState();

    navigate(navRoutes.OFFERING_MANAGEMENT_LSC_SELECTION?.route);
  }

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId && selectOrgUnitState.orgUnitId !== securityState.contextId) {
      getContextSecurity(selectOrgUnitState.orgUnitId, TAXONOMIES, SCOPE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId]);

  return {
    homeRoute: navRoutes.OFFERING_MANAGMENT_LSC_OFFERINGS?.route,
    selectOrgUnitState,
    onHomeClicked,
    onBackToSelectionClicked
  };
};

export default useLscOfferingManagementContextView;
