import { Fragment } from 'react';

import useProfileCoachTypeDetail from './UseProfileCoachTypeDetail';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Headings from '../../../../common/components/headings/Headings';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const ProfileCoachTypeDetail = () => {
  const {
    personProfileState,
    formState,
    errorState,
    coachTypeState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked
  } = useProfileCoachTypeDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.personCoachTypeId < 0 ? 'Add' : 'Edit'} Coach Type</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Coach Name"
              name="coachName"
              value={formState.coachName}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Support Staff Type*"
              name="coachTypeId"
              value={formState.coachTypeId}
              error={errorState.coachTypeId}
              message={errorState.coachTypeId}
              isLoading={coachTypeState.isLoading}
              options={coachTypeState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'coachTypeId', newValueLabel, 'typeName'); }}
            />
          </div>
          <div className="col-md-4 hidden-xs hidden-sm">
            <span className={global.LoadingMsg}></span>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Primary Coach?"
              name="isPrimary"
              checked={formState.isPrimary}
              error={errorState.isPrimary}
              message={errorState.isPrimary}
              onChange={(value) => { onFormValueChanged('isPrimary', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Coach of Record?"
              name="isCoachOfRecord"
              checked={formState.isCoachOfRecord}
              error={errorState.isCoachOfRecord}
              message={errorState.isCoachOfRecord}
              onChange={(value) => { onFormValueChanged('isCoachOfRecord', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Confirmed Dates?"
              name="confirmedDates"
              checked={formState.confirmedDates}
              error={errorState.confirmedDates}
              message={errorState.confirmedDates}
              onChange={(value) => { onFormValueChanged('confirmedDates', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Effective Date*"
              name="effectiveDate"
              value={formState.effectiveDate}
              error={errorState.effectiveDate}
              message={errorState.effectiveDate}
              countOfYears={150}
              startYearOffset={-149}
              onChange={(value) => { onFormValueChanged('effectiveDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="No Expiration Date?"
              name="noExpirationDate"
              checked={formState.noExpirationDate}
              error={errorState.noExpirationDate}
              message={errorState.noExpirationDate}
              onChange={(value) => { onFormValueChanged('noExpirationDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <span className={formState?.noExpirationDate === false ? global.DisplayComponent : global.HideComponent}>
              <DatePicker
                label="Expiration Date*"
                name="expirationDate"
                value={formState.expirationDate}
                error={errorState.expirationDate}
                message={errorState.expirationDate}
                countOfYears={150}
                startYearOffset={-149}
                onChange={(value) => { onFormValueChanged('expirationDate', value); }} />
            </span>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={coachTypeState.isLoading || formState.coachName === ''} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personProfileState.isSaving} />
    </Fragment>
  );
};

export default ProfileCoachTypeDetail;