import { Fragment } from 'react';

import useMemberRequirementsAdvanced from './UseMemberRequriementsAdvanced';
import MemberRequirementsAdvancedWriteGrid from './MemberRequirementsAdvancedWriteGrid';
import DeletePersonRequirementEnrollmentModal from '../DeletePersonRequirementEnrollmentModal';

import MemberRequriementsAdvancedFilterDropdown from '../../../../components/dropdowns/memberRequirementsAdvancedFilterDropdown/MemberRequirementsAdvancedFilterDropdown';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

const MemberRequirementsAdvancedWrite = () => {
  const {
    basePersonState,
    isGridLoading,
    gridDataState,
    onEditRequirement,
    onDeleteRequirement,
    formState,
    errorState,
    onValueTextPairChanged,
    onOpenDeleteEnrollmentModal,
    onDeleteEnrollmentModalCanceled,
    deleteEnrollmentState,
    deleteEnrollmentModalState
  } = useMemberRequirementsAdvanced();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Requirements</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12 col-sm-4 pull-right'>
          <MemberRequriementsAdvancedFilterDropdown
            label="Roles"
            name="goodStandingId"
            value={formState.goodStandingId}
            error={errorState.goodStandingId}
            message={errorState.goodStandingId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'goodStandingId', newValueLabel, 'goodStandingName', e); }} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <MemberRequirementsAdvancedWriteGrid
            gridData={gridDataState}
            isLoading={isGridLoading}
            onEditRequirement={onEditRequirement}
            formState={formState}
            onOpenDeleteEnrollmentModal={onOpenDeleteEnrollmentModal} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={deleteEnrollmentModalState.modalTitle}
        displayPopUp={deleteEnrollmentModalState.displayPopUp}
        onModalCanceled={onDeleteEnrollmentModalCanceled}>
        <DeletePersonRequirementEnrollmentModal modalState={deleteEnrollmentModalState} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={(e) => onDeleteRequirement(e, deleteEnrollmentModalState?.course)}>Yes, Delete</SecondaryButton>&nbsp;&nbsp;
            <PrimaryButton onClick={onDeleteEnrollmentModalCanceled}>Cancel</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={Constants.SAVING_MSG}
        displayPopUp={deleteEnrollmentState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || isGridLoading} />
    </Fragment>
  );
};

export default MemberRequirementsAdvancedWrite;