import React from 'react';

import useOtsPositionWorkedCombobox from './UseOtsPositionWorkedCombobox';

import Combobox from '../../../../common/components/comboboxes/Combobox';

import global from '../../../../common/components/GlobalStyle.module.css';

const OtsPositionWorkedCombobox = ({ otsMeetId, label, name, valueToMatch, error, message, onChange }) => {
  const { otsPositionWorkedEvaluationLevelEvaluatorNameState } = useOtsPositionWorkedCombobox(otsMeetId);

  return otsPositionWorkedEvaluationLevelEvaluatorNameState.message
    ? <span className={global.LoadingMsg}>{otsPositionWorkedEvaluationLevelEvaluatorNameState.message}</span>
    :
    <Combobox
      label={label}
      name={name}
      isLoading={otsPositionWorkedEvaluationLevelEvaluatorNameState.isArrayLoading}
      items={otsPositionWorkedEvaluationLevelEvaluatorNameState.items}
      valueToMatch={valueToMatch}
      error={error}
      message={message}
      onChange={onChange} />
};

export default OtsPositionWorkedCombobox;