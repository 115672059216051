import { useState } from 'react';

import PersonClubTransferData from './PersonClubTransferData';

const usePersonClubTransfer = () => {
  const [memberTransferState, setMemberTransferState] = useState(PersonClubTransferData.INITIAL_STATE);

  const getLatestCompetition = (personId, state, setState) => {
    PersonClubTransferData.getLatestCompetitionData(personId, state, setState);
  };

  const getPrimaryPersonOrgRoleDurations = (personId) => {
    PersonClubTransferData.getPrimaryPersonOrgRoleDurationsData(personId, memberTransferState, setMemberTransferState);
  };

  const postPersonClubTransfer = (transferObj) => {
    PersonClubTransferData.postPersonClubTransferData(transferObj, memberTransferState, setMemberTransferState);
  };

  const updateClubTransferObj = (transferObj) => {
    setMemberTransferState({
      ...memberTransferState,
      isObjLoaded: true,
      objData: transferObj
    });
  };

  const confirmTransferSaved = () => {
    setMemberTransferState({
      ...memberTransferState,
      isSaved: false
    });
  };

  return {
    memberTransferState,
    getLatestCompetition,
    getPrimaryPersonOrgRoleDurations,
    postPersonClubTransfer,
    updateClubTransferObj,
    confirmTransferSaved
  };
};

export default usePersonClubTransfer;