import { Fragment } from 'react';

import MemberRequirementsLargeReadGrid from './MemberRequirementsLargeReadGrid';
import MemberRequirementsSmallReadGrid from './MemberRequirementsSmallReadGrid';
import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MemberRequirementsGrid = ({ gridData, isLoading, formState }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MemberRequirementsLargeReadGrid
        gridData={gridData}
        isLoading={isLoading}
        formState={formState}
        expandedId={expandedId}
        onClick={onClick} />
      <MemberRequirementsSmallReadGrid
        gridData={gridData}
        isLoading={isLoading}
        formState={formState}
        expandedId={expandedId}
        onClick={onClick} />
    </Fragment>
  );
};

export default MemberRequirementsGrid;
