import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getPersonPersonasData from './GetPersonPersonasData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonPersonasData = {
  INITIAL_STATE, getPersonPersonasData
};

export default PersonPersonasData;