import { Fragment } from 'react';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import OfficialPositionCertificationTypeProgressionDropdown from '../../../../components/dropdowns/officialPositionCertificationTypeProgressionDropdown/OfficialPositionCertificationTypeProgressionDropdown';

import LookupIcon from '../../../../../common/components/icons/LookupIcon';
import PlusIcon from '../../../../../common/components/icons/PlusIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ gridStateArrayData, position, personId, personPositionCertificationRequestId, showError, onDeleteRow, onReviewClicked, expirationDateOptions, statusOptions,
  onUpdatePositionCertificationProgressionIdValueChange, onUpdateExpirationDateValueChange, onUpdateStatusValueChange }) => {
  return (
    <>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          &nbsp;
          {position.index >= 0 ?
            <button
              className={global.IconButton}
              type="button"
              onClick={(e) => onDeleteRow(e, position)}>
              <DeleteIcon toolTipText='Delete Row' />
            </button> :
            <button
              className={global.IconButton}
              type="button"
              onClick={() => onReviewClicked(position.personPositionCertificationProgressionId)}>
              <LookupIcon toolTipText='Review (Re)certification' />
            </button>
          }
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-12', global.SmallTableRowLabels].join(' ')}>
              Position & Level
            </div>
            <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
              <OfficialPositionCertificationTypeProgressionDropdown
                label=""
                personId={personId}
                personPositionCertificationRequestId={personPositionCertificationRequestId}
                name={"positionCertificationProgressionId" + position?.personPositionCertificationProgressionId + "personPositionCertificationProgressionId"}
                value={position?.selectedPositionCertificationProgressionId}
                onChange={(newValue, newValueLabel, e) => { onUpdatePositionCertificationProgressionIdValueChange(e, position, newValue, newValueLabel); }}
                areValuesIntegers={true}
                error={showError === true && (position?.selectedPositionCertificationProgressionId < 0 || gridStateArrayData.find(x => x.selectedPositionCertificationProgressionId === position?.selectedPositionCertificationProgressionId &&
                  x.personPositionCertificationProgressionId !== position?.personPositionCertificationProgressionId)) ? true : false}
                message={position?.selectedPositionCertificationProgressionId < 0 && showError === true ? 'Please select a Position & Level' : gridStateArrayData.find(x => x.selectedPositionCertificationProgressionId === position?.selectedPositionCertificationProgressionId &&
                  x.personPositionCertificationProgressionId !== position?.personPositionCertificationProgressionId) && showError === true ? 'At least one other row has the same Position & Level selected. Position & Level must be unique for each row.' : ''}
              />
            </div>
            <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
              Effective Date
            </div>
            <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
              {position.effectiveDate ? formatDate(position.effectiveDate) : <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-12', global.SmallTableRowLabels].join(' ')}>
              Expiration Date
            </div>
            <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
              <Dropdown
                label=""
                options={expirationDateOptions}
                name={"expirationDate" + position?.personPositionCertificationProgressionId + "personPositionCertificationProgressionId"}
                value={position?.selectedExpirationDateId}
                onChange={(newValue, newValueLabel, e) => { onUpdateExpirationDateValueChange(e, position, newValue, newValueLabel); }}
                areValuesIntegers={true}
                error={position?.selectedExpirationDateId < 0 && showError === true ? true : false}
                message={position?.selectedExpirationDateId < 0 && showError === true ? 'Please select an Expiration Date' : ''}
              />
            </div>
            <div className={['col-xs-12', global.SmallTableRowLabels].join(' ')}>
              Status
            </div>
            <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
              <Dropdown
                label=""
                options={statusOptions}
                name={"status" + position?.personPositionCertificationProgressionId + "personPositionCertificationProgressionId"}
                value={position?.selectedStatusId}
                onChange={(newValue, newValueLabel, e) => { onUpdateStatusValueChange(e, position, newValue, newValueLabel); }}
                areValuesIntegers={true}
                error={position?.selectedStatusId < 0 && showError === true ? true : false}
                message={position?.selectedStatusId < 0 && showError === true ? 'Please select a Status' : ''}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const GridSmall = ({ personId, personPositionCertificationRequestId, showError, gridState, officialPositionCertificationTypeProgressionState, onAddRow, onDeleteRow, onReviewClicked, expirationDateOptions, statusOptions,
  onUpdatePositionCertificationProgressionIdValueChange, onUpdateExpirationDateValueChange, onUpdateStatusValueChange }) => {
  return (
    <Fragment>
      <div className='row hidden-md hidden-lg'>
        <div className='col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'>
          {onAddRow && gridState.arrayData?.length < (officialPositionCertificationTypeProgressionState.options.length - 1) &&
            <button className={global.IconButton} type="button" onClick={(e) =>
              onAddRow(e)}>
              <PlusIcon toolTipText='Add Row' /> Add Row
            </button>
          }
        </div>
      </div>
      <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
        {Array.isArray(gridState.arrayData) && gridState.arrayData.length > 0
          && gridState.arrayData.map((position, i) => <SmallGridRow key={i} gridStateArrayData={gridState.arrayData} position={position}
            personId={personId} personPositionCertificationRequestId={personPositionCertificationRequestId} showError={showError} onDeleteRow={onDeleteRow} onReviewClicked={onReviewClicked}
            expirationDateOptions={expirationDateOptions} statusOptions={statusOptions} onUpdatePositionCertificationProgressionIdValueChange={onUpdatePositionCertificationProgressionIdValueChange}
            onUpdateExpirationDateValueChange={onUpdateExpirationDateValueChange} onUpdateStatusValueChange={onUpdateStatusValueChange} />)}
        {(!Array.isArray(gridState.arrayData) || !gridState.arrayData?.length) && (
          <>
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>
                &nbsp;
              </div></div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-12', global.SmallTableRowLabels].join(' ')}>
                  There are no applications available to review.
                </div>
              </div>
            </div>
          </>)}
      </div >
    </Fragment>
  );
};

export default GridSmall;