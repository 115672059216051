import React from 'react';

import { formatDate } from '../../../utils/DateFunctions';
import { formatTimeForDisplay } from '../../../utils/TimesUtils';

import global from '../../GlobalStyle.module.css';

const GridRow = ({ eventQualification }) => (
  <tr>
    <td>{eventQualification.event?.eventName || ''}</td>
    <td>{eventQualification.swimTime ? formatTimeForDisplay(eventQualification.swimTime) : ''}</td>
    <td>{eventQualification.swimDate ? formatDate(eventQualification.swimDate) : ''}</td>
    <td>{eventQualification.meetName || ''}</td>
  </tr>
);

const GroupQualificationEventGridLarge = ({ data, isLoading }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event</th>
        <th>Swim Time</th>
        <th>Swim Date</th>
        <th>Meet Name</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(data) && data.length > 0
          ? data.map((eventQualification, i) => <GridRow key={i} eventQualification={eventQualification} />)
          : <tr><td colSpan="4">No Event Qualifications</td></tr>
      }
    </tbody>
  </table>
);

export default GroupQualificationEventGridLarge;