import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ narrative, onAddEdit, onDelete }) => (
  <tr>
    <td>{narrative.narrativeType?.typeName || ''}</td>
    <td>{narrative.modifiedDatetime ? formatDate(narrative.modifiedDatetime) : ''}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddEdit(e, narrative.personNarrativeId)}>
        <EditIcon />
      </button>
      <button className={global.IconButtonMargin} type="button" onClick={(e) =>
        onDelete(e, narrative.personNarrativeId, `${narrative.narrativeType?.typeName} (Last Modified Date: ${formatDate(narrative.modifiedDatetime)})`)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const LargeGrid = ({ state, onAddEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Narrative Name</th>
        <th>Last Modified Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isObjLoaded !== true
        ? (
          <tr>
            <td colSpan="3">{state.isLoading === true ? 'Loading...' : 'No Narratives'}</td>
          </tr>
        )
        : Array.isArray(state.objData.narratives) && state.objData.narratives.length > 0
          ? state.objData.narratives.map((narrative, i) => <LargeGridRow key={i} narrative={narrative} onAddEdit={onAddEdit} onDelete={onDelete} />
          ) : (
            <tr>
              <td colSpan="3">No Narratives</td>
            </tr>
          )
      }
    </tbody>
  </table >
);

const SmallGridRowIcons = ({ narrative, onAddEdit, onDelete }) => (
  <Fragment>
    <button className={global.IconButton} type="button" onClick={(e) =>
      onDelete(e, narrative.personNarrativeId, `${narrative.narrativeType?.typeName} (Last Modified Date: ${formatDate(narrative.modifiedDatetime)})`)}>
      <DeleteIcon />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onAddEdit(e, narrative.personNarrativeId)}>
      <EditIcon />
    </button>
  </Fragment>
);

const SmallGridRow = ({ narrative, onAddEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{narrative.narrativeType?.typeName}</span>&nbsp;
      <SmallGridRowIcons narrative={narrative} onAddEdit={onAddEdit} onDelete={onDelete} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Narrative Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {narrative.narrativeType?.typeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
          Last Modified Date
        </div>
        <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
          {narrative.modifiedDatetime ? formatDate(narrative.modifiedDatetime) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ state, onAddEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isObjLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Narratives</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.objData.narratives) && state.objData.narratives.length > 0
        ? state.objData.narratives.map((narrative, i) => <SmallGridRow key={i} narrative={narrative} onAddEdit={onAddEdit} onDelete={onDelete} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Narratives</div>
            </div>
          </div>
        )
    }
  </div>
);

const MemberNarrativesGrid = ({ state, onAddEdit, onDelete }) => (
  <Fragment>
    <LargeGrid state={state} onAddEdit={onAddEdit} onDelete={onDelete} />
    <SmallGrid state={state} onAddEdit={onAddEdit} onDelete={onDelete} />
  </Fragment>
);

export default MemberNarrativesGrid;