import React, { createContext, useState } from 'react';

import SearchOtsMeetData from './SearchOtsMeetData';

export const SearchOtsMeetStateContext = createContext();

const SearchOtsMeetContextProvider = ({ children }) => {
  const stateHook = useState(SearchOtsMeetData.INITIAL_STATE);

  return (
    <SearchOtsMeetStateContext.Provider value={stateHook}>
      {children}
    </SearchOtsMeetStateContext.Provider>
  );
};

export default SearchOtsMeetContextProvider;