import React, { createContext, useState } from 'react';

import MeetEvaluationPersonData from './MeetEvaluationPersonData';

export const MeetEvaluationPersonStateContext = createContext();

const MeetEvaluationPersonContextProvider = ({ children }) => {
  const stateHook = useState(MeetEvaluationPersonData.INITIAL_STATE);

  return (
    <MeetEvaluationPersonStateContext.Provider value={stateHook}>
      {children}
    </MeetEvaluationPersonStateContext.Provider>
  );
};

export default MeetEvaluationPersonContextProvider;