import { Fragment } from "react";

import ReadOnly from "../../../common/components/readOnly/ReadOnly";
import OrgRoleFieldDropdown from "./OrgRoleFieldDropdown";

const FormField = ({ field, formState, errorState, onFormValueChanged }) => {
  if (Array.isArray(field.orgRoleFieldLookup) && field.orgRoleFieldLookup.length > 0) { // dropdown case
    return (
      <OrgRoleFieldDropdown
        field={field}
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged} />
    );
  } else { //TODO future cases
    return (
      <ReadOnly
        label={field.fieldLabel}
        name={field.fieldName}
        value={''} />
    );
  }
};

const OrgRoleFieldsSubForm = ({ orgRoleFieldsData, formState, errorState, onFormValueChanged }) => (
  <Fragment>
    {Array.isArray(orgRoleFieldsData) && orgRoleFieldsData.length > 0 &&
      orgRoleFieldsData.map((field, i) => <FormField key={i} field={field} formState={formState} errorState={errorState} onFormValueChanged={onFormValueChanged} />)
    }
  </Fragment>
);

export default OrgRoleFieldsSubForm;