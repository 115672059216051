import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOfferingManagementInstanceOrgUnitData from '../../../../state/offeringManagementInstanceOrgUnit/UseOfferingManagementInstanceOrgUnitData';

import useLscData from '../../../../../common/state/lsc/UseLscData';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useReportPeriodSeasonData from '../../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import { DEFAULT_ID } from '../../../../../common/utils/Constants';

const INITIAL_STATE = {
  reportPeriodId: DEFAULT_ID,
  periodName: '',
  reportPeriodObj: {},
  orgUnitId: '',
  orgUnitName: '',
  pageErrorMessage: '',
  filterErrorMessage: ''
};

const useHqLscOfferingInstanceOrgUnit = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { lscState, getLscs } = useLscData();
  const { reportPeriodSeasonState, getReportPeriodSeason } = useReportPeriodSeasonData();
  const { offeringManagementInstanceOrgUnitState, getOfferingInstanceOrgUnits, clearArrayData
  } = useOfferingManagementInstanceOrgUnitData();
  const [state, setState] = useState(INITIAL_STATE);

  const onAddOfferingInstanceOrgUnitClicked = (e) => {
    e?.preventDefault();

    if (getAreFiltersValid(state.orgUnitId, state.reportPeriodId)) {
      navigate(navRoutes.OFFERING_MANAGMENT_HQ_LSC_OFFERING_ADD?.route, {
        state: {
          reportPeriod: state.reportPeriodObj,
          orgUnitId: state.orgUnitId,
          orgUnitName: state.orgUnitName
        }
      });
    } else {
      setState({
        ...state,
        filterErrorMessage: 'Must select an LSC and Report Period'
      });
    }
  };

  const onEditOfferingInstanceOrgUnitClicked = (e, offeringInstance) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_LSC_OFFERING_EDIT?.route, {
      state: {
        offeringInstance,
        reportPeriod: state.reportPeriodObj,
        orgUnitId: state.orgUnitId,
        orgUnitName: state.orgUnitName
      }
    });
  };

  const onAddAdditionalOfferingInstanceOrgUnitClicked = (e, offeringInstance) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_LSC_OFFERING_ADD?.route, {
      state: {
        offeringInstance,
        reportPeriod: state.reportPeriodObj,
        orgUnitId: state.orgUnitId,
        orgUnitName: state.orgUnitName
      }
    });
  };

  const onEditAdditionalOfferingInstanceOrgUnitClicked = (e, offeringInstance, offeringInstanceOrgUnitId) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_LSC_OFFERING_EDIT?.route, {
      state: {
        offeringInstance,
        offeringInstanceOrgUnitId,
        reportPeriod: state.reportPeriodObj,
        orgUnitId: state.orgUnitId,
        orgUnitName: state.orgUnitName
      }
    });
  };

  const onReportPeriodChanged = (reportPeriodId, periodName) => {
    if (offeringManagementInstanceOrgUnitState.isArrayLoading === false) {
      const reportPeriodObj = reportPeriodSeasonState.arrayData.find((x) => x.reportPeriodId === reportPeriodId) || {};

      setState({
        ...state,
        reportPeriodId,
        periodName,
        reportPeriodObj,
        filterErrorMessage: ''
      });

      getOfferingInstanceOrgUnitsHelper(state.orgUnitId, reportPeriodId);
    }
  };

  const onLscChanged = (orgUnitId, orgUnitName) => {
    if (offeringManagementInstanceOrgUnitState.isArrayLoading === false) {
      setState({
        ...state,
        orgUnitId,
        orgUnitName,
        filterErrorMessage: ''
      });

      getOfferingInstanceOrgUnitsHelper(orgUnitId, state.reportPeriodId);
    }
  };

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoading === false && USAS_ORGANIZATION_ID) {
      if (reportPeriodSeasonState.isArrayLoaded !== true || reportPeriodSeasonState.organizationId !== USAS_ORGANIZATION_ID) {
        getReportPeriodSeason(USAS_ORGANIZATION_ID, false, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodSeasonState, USAS_ORGANIZATION_ID]);

  useEffect(() => {
    if (lscState.isLoading === false && lscState.isLoaded !== true) {
      getLscs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lscState]);

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoading === false && lscState.isLoading === false) {
      if (lscState.isLoaded === true && reportPeriodSeasonState.isArrayLoaded === true
        && reportPeriodSeasonState.organizationId === USAS_ORGANIZATION_ID) {
        const defaultReportPeriod = reportPeriodSeasonState.options.find((x) => x.id === offeringManagementInstanceOrgUnitState.reportPeriodId) || reportPeriodSeasonState.currentAndFutureOptions[0];
        const defaultOrgUnit = lscState.dataAsOptionsWithNames.find((x) => x.id === offeringManagementInstanceOrgUnitState.orgUnitId) || lscState.dataAsOptionsWithNames[0];
        let newState = { ...state };

        if (defaultReportPeriod) {
          newState = {
            ...newState,
            reportPeriodId: defaultReportPeriod.id,
            periodName: defaultReportPeriod.name,
            reportPeriodObj: reportPeriodSeasonState.arrayData.find((x) => x.reportPeriodId === defaultReportPeriod.id) || {}
          }
        }

        if (defaultOrgUnit) {
          newState = {
            ...newState,
            orgUnitId: defaultOrgUnit.id,
            orgUnitName: defaultOrgUnit.name,
          }
        }

        setState(newState);

        getOfferingInstanceOrgUnitsHelper(newState.orgUnitId, newState.reportPeriodId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodSeasonState, lscState]);

  function getOfferingInstanceOrgUnitsHelper(orgUnitId, reportPeriodId) {
    if (getAreFiltersValid(orgUnitId, reportPeriodId)) {
      // Note: objData is only used for POST/PUT/DELETE so if objData is loaded, refresh arrayData
      if (offeringManagementInstanceOrgUnitState.isArrayLoaded === false || reportPeriodId !== offeringManagementInstanceOrgUnitState.reportPeriodId
        || orgUnitId !== offeringManagementInstanceOrgUnitState.orgUnitId || offeringManagementInstanceOrgUnitState.isObjLoaded === true) {
        const getOfferingInstanceOrgUnitsPromise = getOfferingInstanceOrgUnits(orgUnitId, reportPeriodId);

        if (getOfferingInstanceOrgUnitsPromise ?? false) {
          getOfferingInstanceOrgUnitsPromise.catch((e) => {
            setState({
              ...state,
              pageErrorMessage: e?.message || 'Failed to retrieve offerings'
            });
          });
        }
      }
    } else {
      clearArrayData();
    }
  }

  function getAreFiltersValid(orgUnitId, reportPeriodId) {
    return orgUnitId && orgUnitId !== DEFAULT_ID && reportPeriodId > 0;
  }

  function getCanAddAdditionalInstance(reportPeriodObj) {
    const today = new Date();
    const reportPeriodEndDate = new Date(reportPeriodObj?.endDate);

    return today < reportPeriodEndDate;
  }

  return {
    organizationId: USAS_ORGANIZATION_ID,
    areFiltersValid: getAreFiltersValid(state.orgUnitId, state.reportPeriodId),
    canAddAdditionalInstance: getCanAddAdditionalInstance(state.reportPeriodObj),
    state,
    offeringManagementInstanceOrgUnitState,
    onReportPeriodChanged,
    onLscChanged,
    onAddOfferingInstanceOrgUnitClicked,
    onEditOfferingInstanceOrgUnitClicked,
    onAddAdditionalOfferingInstanceOrgUnitClicked,
    onEditAdditionalOfferingInstanceOrgUnitClicked
  };
};

export default useHqLscOfferingInstanceOrgUnit;