import { useEffect } from 'react';

import useGroupPersonCoachesData from '../../../state/groupPersonCoaches/UseGroupPersonCoachesData';

const useGroupPersonCoachesDropdown = (personId, isActive) => {
  const { groupPersonCoachesState, setGroupPersonCoachesState, getGroupPersonCoaches } = useGroupPersonCoachesData();

  useEffect(() => {
    if (personId && personId !== groupPersonCoachesState.personId) {
      const getGroupPersonCoachesPromise = getGroupPersonCoaches(personId, isActive);

      if (getGroupPersonCoachesPromise !== null) {
        getGroupPersonCoachesPromise.then((newState) => {
          if (newState !== null) {
            const options = newState.arrayData.map((coach) => {
              return { id: coach.personId, name: `${coach.firstName} ${coach.lastName}` };
            });
            
            options.unshift({ id: '', name: '--' });

            setGroupPersonCoachesState({
              ...newState,
              options,
              areOptionsLoaded: true
            });
          }
        }).catch((e) => {
          //TODO context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId, isActive]);

  return {
    groupPersonCoachesState
  };
};

export default useGroupPersonCoachesDropdown;