export const localValidate = (formState) => {
  const errors = {};

  if (formState.positionCertificationProgressionIds.length === 0) {
    errors.positionCertificationProgressionIds = 'At least one option must be selected';
  }

  return errors;
};

const ApplyValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ApplyValidation;