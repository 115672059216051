import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

const GetEvaluationLevelData = (officialPositionTypeId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      officialPositionTypeId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const officialPositionTypeIdForUrl  = officialPositionTypeId ? encodeURIComponent(officialPositionTypeId) : '';
    const url = `/EvaluationLevel?officialPositionTypeId=${officialPositionTypeIdForUrl}`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const data = JSON.parse(JSON.stringify(arrayData));
          const blankPosition = { evaluationLevelId: Constants.DEFAULT_ID };
          data.unshift(blankPosition);
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options: data.map((evaluationLevel) => { return { id: evaluationLevel.evaluationLevelId, name: evaluationLevel.evaluationLevelId !== Constants.DEFAULT_ID ? `${evaluationLevel.evaluationLevelName || ''}` : '--' } }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetEvaluationLevelData;