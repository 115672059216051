import { Fragment } from 'react';

import useOfferingManagementContextView from './UseOfferingManagementContextView';

import Headings from '../../../../common/components/headings/Headings';
import MobileContextBasedNav from '../../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../../common/components/contextBasedNav/LargeContextBasedNav';

import global from '../../../../common/components/GlobalStyle.module.css';

const HqOfferingManagementContextView = ({ children }) => {
  const {
    homeRoute,
    onHomeClicked
  } = useOfferingManagementContextView();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              HQ Offering Management
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="OfferingManagement" />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="OfferingManagement" homeRoute={homeRoute} />
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default HqOfferingManagementContextView;