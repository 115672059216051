import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetOfficialData from './GetMeetOfficialsData';
import putMeetOfficialData from './PutMeetOfficialsData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  otsMeetId: Constants.DEFAULT_ID,
  officialPositionTypeId: Constants.DEFAULT_ID,
  hasEvaluator: ''
};

const OtsMeetOfficialsData = {
  INITIAL_STATE, getMeetOfficialData, putMeetOfficialData
}

export default OtsMeetOfficialsData;