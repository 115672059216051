import { useEffect, useState } from 'react';

import validate from './QuickAddAthleteValidation';

import usePersonData from '../../../../common/state/person/UsePersonData';
import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';
import useOrgRoleStatusData from '../../../../common/state/orgRoleStatus/UseOrgRoleStatusData';
import useOrganizationData from '../../../../common/state/organization/UseOrganizationData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useReportPeriodFlatData from "../../../../common/state/reportPeriodFlat/UseReportPeriodFlatData";
import useCompetitionGenderTypeData from '../../../../common/state/competitionGender/UseCompetitionGenderTypeData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useQuickAddAthlete = () => {
  //TODO environment variables for orgRoleId, not sure if this will work with them being strings
  const { FINA_ORGANIZATION_ID, FINA_ORGANIZATION_NAME, FINA_ATHLETE_ORG_ROLE_ID, ISL_ORGANIZATION_ID,
    ISL_ORGANIZATION_NAME, ISL_ATHLETE_ORG_ROLE_ID, NCAA_ORGANIZATION_ID,
    NCAA_ORGANIZATION_NAME, NCAA_ATHLETE_ORG_ROLE_ID, NCAA_SEASON_START_MONTH_DAY, NCAA_SEASON_END_MONTH_DAY,
    MALE_COMPETITION_GENDER_ID, FEMALE_COMPETITION_GENDER_ID, UNKNOWN_COMPETITION_GENDER_ID, environmentVariableState } = useEnvironmentVariableData();
  const { personState, getPotentialPersonDupes, postBasePerson, resetPersonState } = usePersonData();
  const { personGeneralState, getPersonGeneral, putPersonGeneral, clearObjData } = usePersonGeneralData();
  const { orgRoleStatusState, getOrgRoleStatus } = useOrgRoleStatusData();
  const { organizationState } = useOrganizationData();
  const { reportPeriodFlatState, getReportPeriodsFlatByOrganizationId } = useReportPeriodFlatData();
  const { competitionGenderState } = useCompetitionGenderTypeData();
  const [dateState, setDateState] = useState({ effectiveDateISL: Constants.BLANK_DATE_STRING, expirationDateISL: Constants.BLANK_DATE_STRING });
  const [listState, setListState] = useState([]);
  const { formState, setFormState, setFormData, errorState, onFormValueChanged, onValueTextPairChanged, handleSubmit,
    setIsDirty, setErrors } = useForm(getFormInitialState(), submitFormCallback, validate);

  // Before useForm validation, sets isDirty to true allowing for repeated adds
  const onAddAthlete = (e) => {
    setIsDirty(true);
    handleSubmit(e);
  };

  function submitFormCallback(formState) {
    getPotentialPersonDupes(formState.firstName.trim(), formState.lastName.trim(), formState.birthDate);
  };

  const getOrgValues = () => {
    const today = new Date();
    const month = today.getMonth();
    const date = today.getDate();
    const year = today.getFullYear();
    let effectiveDate;
    let expirationDate;

    switch (formState.organizationId) {
      case FINA_ORGANIZATION_ID:
        return {
          organizationId: formState.organizationId,
          organizationName: formState.organizationName || FINA_ORGANIZATION_NAME,
          orgUnitLabel: Constants.FINA_ORG_UNIT_LABEL + '*',
          orgRoleId: FINA_ATHLETE_ORG_ROLE_ID,
          effectiveDate: formState.birthDate || Constants.BLANK_DATE_STRING,
          expirationDate: Constants.DEFAULT_MAX_DATE
        };
      case NCAA_ORGANIZATION_ID:
        effectiveDate = month >= 3 && month <= 7
          ? `${NCAA_SEASON_START_MONTH_DAY}/${(year).toString()}`
          : `${(month + 1).toString()}/${date.toString()}/${year.toString()}`
        expirationDate = month >= 0 && month <= 2
          ? `${NCAA_SEASON_END_MONTH_DAY}/${(year).toString()}`
          : `${NCAA_SEASON_END_MONTH_DAY}/${(year + 1).toString()}`
        return {
          organizationId: formState.organizationId,
          organizationName: formState.organizationName || NCAA_ORGANIZATION_NAME,
          orgUnitLabel: Constants.NCAA_ORG_UNIT_LABEL + '*',
          orgRoleId: NCAA_ATHLETE_ORG_ROLE_ID,
          effectiveDate: month >= 3 && month <= 7 ? effectiveDate : formatDate(effectiveDate),
          expirationDate: expirationDate
        };
      case ISL_ORGANIZATION_ID:
        return {
          organizationId: formState.organizationId,
          organizationName: formState.organizationName || ISL_ORGANIZATION_NAME,
          orgUnitLabel: Constants.ISL_ORG_UNIT_LABEL + '*',
          orgRoleId: ISL_ATHLETE_ORG_ROLE_ID,
          effectiveDate: dateState.effectiveDateISL,
          expirationDate: dateState.expirationDateISL
        };
      default:
        return {
          organizationId: FINA_ORGANIZATION_ID,
          organizationName: FINA_ORGANIZATION_NAME,
          orgUnitLabel: Constants.FINA_ORG_UNIT_LABEL + '*',
          orgRoleId: FINA_ATHLETE_ORG_ROLE_ID,
          effectiveDate: formState.birthDate || Constants.BLANK_DATE_STRING,
          expirationDate: Constants.DEFAULT_MAX_DATE
        };
    }
  };

  const resetFormInfo = () => {
    setErrors({});
    setFormData(getFormInitialState());
  };

  const saveAthlete = () => {
    const athlete = {
      firstName: formState.firstName.trim(),
      lastName: formState.lastName.trim(),
      competitionGenderTypeId: formState.isAthlete === true ? formState.competitionGenderTypeId : UNKNOWN_COMPETITION_GENDER_ID,
      birthDate: formState.birthDate,
      isDeleted: false
    };

    postBasePerson(athlete);
  };

  const onAddDupAthleteClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    saveAthlete();
  };

  const onCancelDupAthleteClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    resetFormInfo();
    resetPersonState();
  };

  const createPutPersonGeneralObj = (personGeneralObjData, formState) => {
    personGeneralObjData.personOrgRole.push({
      personId: personGeneralObjData.personId,
      orgRoleId: formState.orgRoleId,
      status: formState.orgRoleStatus,
      active: true,
      orgRole: undefined,
      personOrgRoleDuration: [
        {
          orgUnitId: formState.orgUnitId,
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.expirationDate,
          orgUnit: undefined
        }
      ]
    });

    return personGeneralObjData;
  };

  useEffect(() => {
    //Get flat report periods to figure out ISL effective and expiration dates
    if (environmentVariableState.isLoaded === true && reportPeriodFlatState.isLoading !== true &&
      reportPeriodFlatState.organizationId !== ISL_ORGANIZATION_ID) {
      getReportPeriodsFlatByOrganizationId(ISL_ORGANIZATION_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [reportPeriodFlatState, environmentVariableState]);

  useEffect(() => {
    //Get ISL effective and expiration dates from report periods
    if (environmentVariableState.isLoaded === true && reportPeriodFlatState.isLoaded === true
      && reportPeriodFlatState.organizationId === ISL_ORGANIZATION_ID) {
      const today = new Date();
      const todayDate = formatDate(today);
      const todayFormatted = new Date(todayDate);
      const currentReportPeriods = [];
      const futureReportPeriods = [];
      let startDate;
      let endDate;
      for (let i = 0; i < reportPeriodFlatState.data.length; i++) {
        const selectedArrayData = reportPeriodFlatState.data[i];
        startDate = new Date(formatDate(selectedArrayData.startDate));
        endDate = new Date(formatDate(selectedArrayData.endDate));
        if (todayFormatted <= endDate && todayFormatted >= startDate) {
          currentReportPeriods.push(selectedArrayData);
        }
        if (todayFormatted < startDate) {
          futureReportPeriods.push(selectedArrayData)
        }
      }
      if (currentReportPeriods.length > 0) {
        const expirationDate = formatDate(currentReportPeriods[0].endDate);
        setDateState({ ...dateState, effectiveDateISL: todayDate, expirationDateISL: expirationDate });
      }
      else if (futureReportPeriods.length > 0) {
        const effectiveDate = formatDate(futureReportPeriods[0].startDate);
        const expirationDate = formatDate(futureReportPeriods[0].endDate);
        setDateState({ ...dateState, effectiveDateISL: effectiveDate, expirationDateISL: expirationDate });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [reportPeriodFlatState, environmentVariableState]);

  // get new org units & set up dependent formState fields if org id changes
  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      const newState = {
        ...formState,
        ...(getOrgValues())
      };
      setFormState(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.organizationId, formState.birthDate, formState.effectiveDate, formState.expirationDate,
  dateState.effectiveDateISL, dateState.expirationDateISL, environmentVariableState.isLoaded]);

  // save athlete once dup data is loaded and there are no matches
  useEffect(() => {
    if (
      personState.isArrayLoaded === true
      && personState.isSaved !== true
      && personState.isSaving !== true
      && (!Array.isArray(personState.arrayData) || personState.arrayData.length === 0)
    ) {
      saveAthlete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.arrayData]);

  // add athlete to list and reset data when post data is complete
  useEffect(() => {
    if (personState.isSaved === true && personState.objData.personId) {
      getPersonGeneral(personState.objData.personId);
    } else if (personState.isSaving && personState.message) {
      //TODO: handle error message
      resetPersonState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState.objData]);

  useEffect(() => {
    if (personGeneralState.isObjLoaded === true && personGeneralState.isObjLoading === false &&
      personGeneralState.isSaving === false && personGeneralState.isSaved === false) {
      if (formState.isAthlete === true) {
        putPersonGeneral(personGeneralState.objData.personId, createPutPersonGeneralObj(JSON.parse(JSON.stringify(personGeneralState.objData)), formState));
      } else {
        const gridDataObject = { ...personGeneralState.objData, firstName: formState.firstName, lastName: formState.lastName, birthDate: formState.birthDate, competitionGenderType: { typeName: 'Unknown' } } //TODO make constant?
        setListState([...listState, { ...(gridDataObject) }]);
        clearObjData();
        resetPersonState();
        resetFormInfo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState]);

  useEffect(() => {
    if (personGeneralState.isSaving === false && personGeneralState.isSaved === true) {
      const gridDataObject = { ...personGeneralState.objData, firstName: formState.firstName, lastName: formState.lastName, birthDate: formState.birthDate, competitionGenderType: { typeName: formState.competitionGenderTypeId === MALE_COMPETITION_GENDER_ID ? 'Male' : 'Female' } }
      setListState([...listState, { ...(gridDataObject) }]);
      clearObjData();
      resetPersonState();
      resetFormInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState.isSaving, personGeneralState.isSaved]);

  useEffect(() => {
    if (formState.orgRoleId) {
      getOrgRoleStatus(formState.orgRoleId);

      setFormState({
        ...formState,
        orgRoleStatus: ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.orgRoleId]);

  useEffect(() => {
    if (orgRoleStatusState.isLoaded) {
      setFormState({
        ...formState,
        orgRoleStatus: orgRoleStatusState.data.orgRoleName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleStatusState]);

  function getFormInitialState() {
    return {
      firstName: '',
      lastName: '',
      birthDate: Constants.BLANK_DATE_STRING,
      isAthlete: false,
      competitionGenderTypeId: '',
      organizationId: Constants.DEFAULT_ID,
      organizationName: '',
      orgUnitId: '',
      orgUnitName: '',
      orgUnitLabel: Constants.FINA_ORG_UNIT_LABEL + '*',
      orgRoleId: '',
      orgRoleStatus: '',
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      lscId: Constants.DEFAULT_ID
    };
  };

  return {
    Constants,
    organizationState,
    formState,
    errorState,
    setErrors,
    listState,
    dupPersonState: personState,
    personGeneralState,
    competitionGenderState,
    onFormValueChanged,
    onValueTextPairChanged,
    onAddAthlete,
    resetFormInfo,
    onAddDupAthleteClicked,
    onCancelDupAthleteClicked,
    FINA_ORGANIZATION_ID,
    FINA_ORGANIZATION_NAME,
    NCAA_ORGANIZATION_ID,
    NCAA_ORGANIZATION_NAME,
    ISL_ORGANIZATION_NAME,
    MALE_COMPETITION_GENDER_ID,
    FEMALE_COMPETITION_GENDER_ID
  };
};

export default useQuickAddAthlete;