import { useContext, useEffect } from 'react';
import Constants from '../../../common/utils/Constants';

import { NarrativeTypeStateContext } from './NarrativeTypeContextProvider';

import NarrativeTypeData, { defaultNarrativeTypeState } from './NarrativeTypeData';

const useNarrativeTypeData = () => {
  const [narrativeTypeState, setNarrativeTypeState] = useContext(NarrativeTypeStateContext);

  useEffect(() => {
    if (narrativeTypeState.isLoading !== true && narrativeTypeState.isLoaded !== true) {
      NarrativeTypeData.getNarrativeTypes(narrativeTypeState, setNarrativeTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    narrativeTypeState,
    defaultNarrativeTypeState: { ...defaultNarrativeTypeState, narrativeTypeId: Constants.DEFAULT_ID, narrativeTypeName: '' }
  };
};

export default useNarrativeTypeData;