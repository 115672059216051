import { Fragment } from 'react';

import useMemberMergeSearchByMemberIds from './UseMemberMergeSearchByMemberIds';

import Input from '../../../../common/components/inputs/Input';
import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const Form = ({ handleSubmit, formState, errorState, onFormValueChanged, clearForm }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Member A Id*"
          name="memberAId"
          value={formState.memberAId}
          error={errorState.memberAId}
          message={errorState.memberAId}
          onChange={(value) => { onFormValueChanged('memberAId', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Member B Id*"
          name="memberBId"
          value={formState.memberBId}
          error={errorState.memberBId}
          message={errorState.memberBId}
          onChange={(value) => { onFormValueChanged('memberBId', value) }} />
      </div>
    </div>
    {(errorState.filter || errorState.errorOnSubmitAction) &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>
    }
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Search for Members</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>
      </div>
    </div>
  </form>
);

const MemberMergeSearchByMemberIds = () => {
  const {
    isLoading,
    formState,
    errorState,
    clearForm,
    handleSubmit,
    onFormValueChanged
  } = useMemberMergeSearchByMemberIds();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Merge - Search</Headings.H3>
        </div>
      </div>
      <Form
        formState={formState}
        errorState={errorState}
        clearForm={clearForm}
        handleSubmit={handleSubmit}
        onFormValueChanged={onFormValueChanged} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default MemberMergeSearchByMemberIds;