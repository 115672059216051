import React, { Fragment } from 'react';

import useNationalTeamContacts from './UseNationalTeamContacts';

import NationalTeamContactsGrid from './grids/NationalTeamContactsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

const NationalTeamContacts = () => {
  const {
    deleteModalState,
    viewState,
    personGeneralNationalTeamState,
    basePersonState,
    onAddPerson,
    onEditPerson,
    onAddEditAddress,
    onAddEditPhone,
    onDeletePerson,
    onDeleteAddress,
    onDeletePhone,
    onDeleteModalConfirmClicked,
    onDeleteModalCanceledClicked
  } = useNationalTeamContacts();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>National Team Contact Info</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddPerson}>Add Contact</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <NationalTeamContactsGrid
            contacts={viewState.contactDisplayList}
            isLoading={personGeneralNationalTeamState.isObjLoading}
            onEditPerson={onEditPerson}
            onDeletePerson={onDeletePerson}
            onAddEditAddress={onAddEditAddress}
            onDeleteAddress={onDeleteAddress}
            onAddEditPhone={onAddEditPhone}
            onDeletePhone={onDeletePhone} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personGeneralNationalTeamState.isObjLoading || basePersonState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personGeneralNationalTeamState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={deleteModalState.modalTitle}
        displayPopUp={deleteModalState.displayPopUp === true}
        onModalCanceled={onDeleteModalCanceledClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{deleteModalState.targetObjectName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalConfirmClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCanceledClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default NationalTeamContacts;