import { Fragment } from 'react';

import EditIcon from '../../../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../../common/components/icons/ShowIcon';
import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ offeringDescription }) => (
  <tr><td colSpan='5' className={global.SmallText}>{offeringDescription || <span>&nbsp;</span>}</td></tr>
);

const DetailGrid = ({ offering }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr><th colSpan='5'>Offering Description</th></tr>
    </thead>
    <tbody>
      <DetailGridRow offeringDescription={offering.offeringDescription} />
    </tbody>
  </table>
);

const GridRow = ({ offering, expandedId, onEditClicked, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td>{offering.offeringType?.typeName}</td>
      <td>{offering.offeringName}</td>
      <td>
        {offering.offeringOptionalAttributes[0]?.ageStart >= 0
          ? offering.offeringOptionalAttributes[0].ageStart + '-' + offering.offeringOptionalAttributes[0].ageEnd
          : ''
        }
      </td>
      <td>
        {offering.offeringInstance?.length === 0
          ? <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditClicked(e, offering)}><EditIcon /></button>
          : <Fragment />
        }
      </td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, offering.offeringId)}>
          {expandedId === offering.offeringId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {expandedId === offering.offeringId &&
      <tr className={global.Expanded}>
        <td colSpan="5">
          <DetailGrid offering={offering} />
        </td>
      </tr>
    }
  </Fragment>
);

const OfferingGridLarge = ({ gridData, isLoading, expandedId, onEditClicked, onExpandClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Offering Type'} columnField={'offeringTypeId'}
            sortType={SGConstants.SORT_TYPE_LENGTH} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Offering Name'} columnField={'offeringName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Age Range</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="5">Loading...</td></tr>
          : (Array.isArray(sortableGridState.sortedGridData) === true && sortableGridState.sortedGridData.length > 0)
            ? sortableGridState.sortedGridData.map((offering, i) => (
              <GridRow
                key={i}
                offering={offering}
                expandedId={expandedId}
                onEditClicked={onEditClicked}
                onExpandClicked={onExpandClicked} />)
            ) : <tr><td colSpan="5">No Offerings</td></tr>
        }
      </tbody>
    </table>
  );
};

export default OfferingGridLarge;