import { useContext, useEffect } from 'react';

import { TestingBodyStateContext } from './TestingBodyContextProvider';

import TestingBodyData, { defaultTestingBodyState } from './TestingBodyData';

const useTestingBodyData = () => {
  const [testingBodyState, setTestingBodyState] = useContext(TestingBodyStateContext);

  useEffect(() => {
    if (testingBodyState.isLoading !== true && testingBodyState.isLoaded !== true) {
      TestingBodyData.getTestingBodies(testingBodyState, setTestingBodyState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    testingBodyState,
    defaultTestingBodyState
  };
};

export default useTestingBodyData;