import { Fragment } from 'react';

import useOtsOfficialsSearch from './UseOtsOfficialsSearch';
import OtsOfficialsSearchGrid from './OtsOfficialsSearchGrid';

import OfficialPositionTypeDropdown from '../../../../components/dropdowns/officialPositionTypeDropdown/OfficialPositionTypeDropdown';
import CertificationLevelDropdown from '../../../../components/dropdowns/certificationLevelDropdown/CertificationLevelDropdown';

import Headings from '../../../../../common/components/headings/Headings';
import Input from '../../../../../common/components/inputs/Input';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import LscDropdown from '../../../../../common/components/dropdowns/lscDropdown/LscDropdown';
import ApprovedClubCombobox from '../../../../../common/components/comboboxes/approvedClubCombobox/ApprovedClubCombobox';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const ErrorHandle = ({ error, message }) => (
  <Fragment>
    {error && <p className={global.ErrorMessage}>{message}</p>}
  </Fragment>
)

const OtsOfficialsSearch = () => {
  const {
    searchOtsOfficialState,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    sortByOptions,
    clearForm,
    showFilters,
    onFiltersToggle,
    showResults,
    onSelectMember,
    isLoading,
    isCurrentOptions
  } = useOtsOfficialsSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Official Search</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate className={showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <LscDropdown
              label="LSC"
              name="lscOrgUnitId"
              value={formState.lscOrgUnitId}
              error={errorState.lscOrgUnitId}
              message={errorState.lscOrgUnitId}
              onChange={(newValue, newValueLabel, e) => {
                onValueTextPairChanged(newValue, 'lscOrgUnitId', newValueLabel, 'lscName', e);
                onValueTextPairChanged('', 'clubOrgUnitId', '', 'clubOrgUnitName', e);
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ApprovedClubCombobox
              label='Club Name'
              name="clubOrgUnitName"
              valueToMatch={formState.clubOrgUnitName}
              error={errorState.clubOrgUnitId}
              message={errorState.clubOrgUnitId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'clubOrgUnitId', newValueLabel, 'clubOrgUnitName', e); }}
              lscId={formState.lscOrgUnitId} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Email"
              name="email"
              value={formState.email}
              error={errorState.email}
              message={errorState.email}
              onChange={(value) => { onFormValueChanged('email', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Legal First Name or Preferred Name"
              name="firstName"
              value={formState.firstName}
              error={errorState.firstName}
              message={errorState.firstName}
              onChange={(value) => { onFormValueChanged('firstName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Legal Last Name or Maiden Name"
              name="lastName"
              value={formState.lastName}
              error={errorState.lastName}
              message={errorState.lastName}
              onChange={(value) => { onFormValueChanged('lastName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Member ID"
              name="memberId"
              value={formState.memberId}
              error={errorState.memberId}
              message={errorState.memberId}
              onChange={(value) => { onFormValueChanged('memberId', value) }} />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <OfficialPositionTypeDropdown
              label="Position Worked"
              name="officialPositionTypeId"
              value={formState.officialPositionTypeId}
              error={errorState.officialPositionTypeId}
              message={errorState.officialPositionTypeId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'officialPositionTypeId', newValueLabel, 'officialPositionName', e) }}
              orgLevelId={undefined}
            />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <CertificationLevelDropdown
              label="Certification Level"
              name="certificationLevelId"
              value={formState.certificationLevelId}
              error={errorState.certificationLevelId}
              message={errorState.certificationLevelId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'certificationLevelId', newValueLabel, 'levelName', e) }}
              orgLevelId={undefined}
              officialPositionTypeId={undefined}
            />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <Dropdown
              label="Current or Expired Membership?"
              name="isCurrent"
              options={isCurrentOptions}
              value={formState.isCurrentId}
              error={errorState.isCurrentId}
              message={errorState.isCurrentId}
              isLoading={false}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, 'isCurrentId', newValueLabel, 'isCurrentName');
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Sort Results By"
              name="sortById"
              options={sortByOptions}
              value={formState.sortById}
              error={errorState.sortById}
              message={errorState.sortById}
              isLoading={false}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
              }} />
          </div>
        </div>
        <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
          <div className='col-xs-12'>
            <ErrorHandle error={errorState?.filter} message={errorState?.filter} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Search for Official</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>&nbsp;
          </div>
        </div>
      </form>
      <div className={showResults === true ? global.DisplayComponent : global.HideComponent}>
        <OtsOfficialsSearchGrid
          state={searchOtsOfficialState}
          onSelectMember={onSelectMember}
          onFiltersToggle={onFiltersToggle}
          showFilters={showFilters === true} />
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={searchOtsOfficialState?.isArrayLoading || isLoading === true} />
    </Fragment>
  );
};

export default OtsOfficialsSearch;