import React, { Fragment } from 'react';

import useMemberClubTransferMember from './UseMemberClubTransferMember';

import Headings from '../../../../common/components/headings/Headings';
import MemberClubTransferMemberGrid from './components/MemberClubTransferMemberGrid';
import Constants from '../../../../common/utils/Constants';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const MemberClubTransferMember = () => {
  const {
    isLoading,
    gridData,
    onClubTransferClicked
  } = useMemberClubTransferMember();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Club Transfer</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <MemberClubTransferMemberGrid
            gridData={gridData}
            isLoading={isLoading}
            onEdit={onClubTransferClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default MemberClubTransferMember;