import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const getOtsCertificationRulesForProgressions = (positionCertificationProgressionIds, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const url = `/personpositioncertificationprogressionrule/certificationprogression/${positionCertificationProgressionIds}`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const getOtsCertificationRulesForPersonProgression = (personPositionCertificationProgressionId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const url = `/personpositioncertificationprogressionrule/personpositioncertificationprogression/${personPositionCertificationProgressionId}`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const postPersonPositionCertificationProgressionRule = (personPositionCertificationProgressionRule, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const url = '/personpositioncertificationprogressionrule';
  return api?.executeObject ? api.executeObject(url, 'POST', personPositionCertificationProgressionRule) : null;
};

const OtsCertificationApplicationData = {
  INITIAL_STATE, 
  getOtsCertificationRulesForProgressions,
  getOtsCertificationRulesForPersonProgression,
  postPersonPositionCertificationProgressionRule
}

export default OtsCertificationApplicationData;