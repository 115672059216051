import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';
import useGroupFieldNamesData from '../../../../../common/state/groupFieldNames/UseGroupFieldNamesData';
import useOrgGroupPropertiesData from '../../../../../common/state/orgGroupProperties/UseOrgGroupPropertiesData';
import useMemberGroupsData from '../../../../../common/state/memberGroups/UseMemberGroupsData';

const useMemberGroupsRegistration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { orgGroupPropertiesState } = useOrgGroupPropertiesData();
  const { memberGroupsState, memberGroupsQualificationEventState, getPersonOrgGroup, postGroupMember, putGroupMember,
    getGroupQualificationEvents
  } = useMemberGroupsData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();

  const onBackClicked = () => {
    navigate(navRoutes.MEMBER_PROFILE_GROUPS?.route);
  };

  const onFormCompletion = (groupMemberObj) => {
    const personOrgGroupId = groupMemberObj.personOrgGroupId;

    if (personOrgGroupId > 0) {
      const putGroupMemberPromise = putGroupMember(personOrgGroupId, groupMemberObj);

      if (putGroupMemberPromise !== null) {
        putGroupMemberPromise.then((newState) => {
          if (newState !== null) {
            navigate(navRoutes.MEMBER_PROFILE_GROUPS?.route);
          }
        }).catch((e) => {
          console.log(e);
        });
      }
    } else {
      const postGroupMemberPromise = postGroupMember(groupMemberObj);

      if (postGroupMemberPromise !== null) {
        postGroupMemberPromise.then((newState) => {
          if (newState !== null) {
            navigate(navRoutes.MEMBER_PROFILE_GROUPS?.route);
          }
        }).catch((e) => {
          console.log(e);
        });
      }
    }
  };

  useEffect(() => {
    if (location.state?.group?.personOrgGroupId > 0) {
      getPersonOrgGroup(location.state.group.personOrgGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (location.state?.group?.personOrgGroupQualificationId > 0) {
      getGroupQualificationEvents(location.state.group.personOrgGroupQualificationId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return {
    isSaving: memberGroupsState.isSaving,
    isLoading: orgGroupPropertiesState.isArrayLoading || memberGroupsState.isObjLoading,
    isMemberGroupObjLoaded: memberGroupsState.isObjLoaded,
    isGridLoading: memberGroupsQualificationEventState.isArrayLoading,
    groupTypeName: location.state?.group?.groupTypeName || '',
    groupObj: location.state?.group || {},
    memberGroupObj: memberGroupsState.objData || {},
    groupQualificationEvents: memberGroupsQualificationEventState.arrayData || [],
    personId: basePersonState.objData?.personId || '',
    GROUP_CONSTANTS,
    onBackClicked,
    onFormCompletion
  };
};

export default useMemberGroupsRegistration;