import React, { Fragment } from 'react';

import useOtsMeetSearch from './UseOtsMeetSearch';

import SearchOtsMeet from '../../../../components/searchOfficialsTrackingSystemMeet/SearchOtsMeet';

const OtsMeetSearch = () => {
  const { searchOtsMeetState, onPostOtsMeet, onEditOtsMeet } = useOtsMeetSearch();

  return (
    <Fragment>
      <SearchOtsMeet
        pageTitle={"OTS Meet Search"}
        searchButtonText={'Search for OTS Meet'}
        onPost={onPostOtsMeet}
        onEdit={onEditOtsMeet}
        searchOtsMeetState={searchOtsMeetState}
      />
    </Fragment>
  );
};

export default OtsMeetSearch;