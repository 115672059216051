import React, { createContext, useState } from 'react';

import BaseMemberData from './BaseMemberData';

export const BaseMemberStateContext = createContext();

const BaseMemberContextProvider = ({ children }) => {
  const stateHook = useState(BaseMemberData.INITIAL_STATE);

  return (
    <BaseMemberStateContext.Provider value={stateHook}>
      {children}
    </BaseMemberStateContext.Provider>
  );
};

export default BaseMemberContextProvider;