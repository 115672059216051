import { Fragment } from 'react';

import useEditNationalEvaluator from './UseEditNationalEvaluator';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import Input from '../../../../../common/components/inputs/Input';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const EditNationalEvaluator = ({nationalEvaluatorId, nationalEvaluatorTitles, officialPositionTypes, onEditCallback, onBackClicked}) => {
  const {
    isSaving,
    evaluatorName,
    formState,
    errorState,
    onTitleChanged,
    onEffectiveDateChanged,
    onExpirationDateChanged,
    onSaveClicked } = useEditNationalEvaluator(nationalEvaluatorId, officialPositionTypes, onEditCallback);

  return (
    <Fragment>
      <form noValidate>
        <div className="row">
          <div className="col-xs-12">
          <p><b>Name:</b> {evaluatorName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            &nbsp;
          </div>
          <div className="col-xs-12 col-md-4">
            &nbsp;
          </div>
          <div className="col-xs-6 col-md-2">
            <Headings.H5>Effective Date</Headings.H5>
          </div>
          <div className="col-xs-6 col-md-2">
            <Headings.H5>Expiration Date</Headings.H5>
          </div>
        </div>
        {formState.officialPositionTypes.map((position, i) => (
          <div className="row" key={i}>
            <div className="col-xs-12 col-md-4">
              <Headings.H5>{position.positionTypeName} - {position.positionAbbreviation}</Headings.H5>
            </div>
            <div className="col-xs-12 col-md-4">
              <Dropdown
                error={errorState.nationalEvaluatorTitleId}
                message={errorState.nationalEvaluatorTitleId}
                options={nationalEvaluatorTitles}
                name="nationalEvaluatorTitleId"
                value={position.nationalEvaluatorTitleId}
                onChange={(newValue, newValueLabel, e) => onTitleChanged(position.officialPositionTypeId, newValue, newValueLabel)}
                areValuesIntegers={true} />
            </div>
            <div className="col-xs-6 col-md-2">
                {position.nationalEvaluatorTitleId > 0 && <Input
                    type="date"
                    name="effectiveDate"
                    value={position.effectiveDate}
                    error={undefined}
                    message={undefined}
                    onChange={(value) => { onEffectiveDateChanged(position.officialPositionTypeId, value) }} />}
            </div>
            <div className="col-xs-6 col-md-2">
                {position.nationalEvaluatorTitleId > 0 && <Input
                    type="date"
                    name="expirationDate"
                    value={position.expirationDate}
                    error={undefined}
                    message={undefined}
                    onChange={(value) => { onExpirationDateChanged(position.officialPositionTypeId, value) }} />}
            </div>
          </div>
        ))}
        {errorState.generalError && (
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.generalError}</p>
            </div>
          </div>)}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal widthPct={50} maxWidth={250} title={Constants.SAVING_MSG} displayPopUp={isSaving === true} />
    </Fragment>
  );
};

export default EditNationalEvaluator;