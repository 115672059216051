import { Routes, Route } from 'react-router-dom';

import OfficialsTrackingSystemOfficial from './official/OfficialsTrackingSystemOfficial';
import OfficialsTrackingSystemMeet from './meet/OfficialsTrackingSystemMeet';
import HqOfficialsTrackingSystemOfficialBulkRecertify from './hqLscBulkRecertify/HqOfficialsTrackingSystemLscBulkRecertify';
import OfficialsTrackingSystemLscBulkRecertify from './lscBulkRecertify/OfficialsTrackingSystemLscBulkRecertify';
import OtsReporting from './otsReporting/OtsReporting';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import NationalEvaluators from './nationalEvaluators/NationalEvaluators';
import CertificationApplication from './certificationApplication/certificationApplication/CertificationApplication';
import CertificationReview from './certificationApplication/certificationReview/CertificationReview';

const OfficialsTrackingSystemRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['OTS_OFFICIAL_ROOT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_OFFICIAL_ROOT']}
            editElement={<OfficialsTrackingSystemOfficial />} />
        }
      />
      <Route path={navRoutes['OTS_MEET_ROOT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_MEET_ROOT']}
            editElement={<OfficialsTrackingSystemMeet />} />
        }
      />
      <Route path={navRoutes['HQ_OTS_LSC_BULK_ROOT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['HQ_OTS_LSC_BULK_ROOT']}
            editElement={<HqOfficialsTrackingSystemOfficialBulkRecertify />} />
        }
      />
      <Route path={navRoutes['OTS_LSC_BULK_ROOT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_LSC_BULK_ROOT']}
            editElement={<OfficialsTrackingSystemLscBulkRecertify />} />
        }
      />
      <Route path={navRoutes['OTS_NATIONAL_EVALUATORS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_NATIONAL_EVALUATORS']}
            editElement={<NationalEvaluators />} />
        }
      />
      <Route path={navRoutes['OTS_CERT_APPLICATION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_CERT_APPLICATION']}
            editElement={<CertificationApplication />} />
        }
      />
      <Route path={navRoutes['OTS_CERT_APP_WORKFLOW_ROOT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_CERT_APP_WORKFLOW_ROOT']}
            editElement={<CertificationReview />} />
        }
      />
      <Route path={navRoutes['OTS_REPORTING']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_REPORTING']}
            editElement={<OtsReporting />} />
        }
      />
    </Routes>
  );
}

const OfficialsTrackingSystem = () => (
  <OfficialsTrackingSystemRoutes />

);

export default OfficialsTrackingSystem;