import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MemberContextRoutes from './MemberContextRoutes';

import MemberSearch from './memberSearch/MemberSearch';
import LscMemberSearch from './memberSearch/LscMemberSearch';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import BasePersonFiltersContextProvider from '../../../common/state/personBase/BasePersonFiltersContextProvider';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const MemberRoutes = () => {
  const { navRoutes } = useNavRoutes();

  return (
    <Routes>
      <Route path={navRoutes.MEMBER_SEARCH?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.MEMBER_SEARCH}
            editElement={<MemberSearch />}
            readOnlyElement={<LscMemberSearch />} />
        }
      />
      <Route path={'/*'} element={<MemberContextRoutes />} />
    </Routes>
  );
};

const BasicMemberRoutes = () => {
  return (
    <BasePersonFiltersContextProvider>
      <MemberRoutes />
    </BasePersonFiltersContextProvider>
  );
};

export default BasicMemberRoutes;