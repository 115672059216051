import Headings from '../../../../common/components/headings/Headings';
import style from './AtoZFilter.module.css';

const AtoZFilter = ({ lastNameFilter, alphabeticalFilterClicked }) => {
  return (
    <>
      <div className='row'>
        <div className='col-xs-12'><Headings.H6>Filter Results By First Letter of Last Name</Headings.H6></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'a' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('a')}>A</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'b' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('b')}>B</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'c' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('c')}>C</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'd' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('d')}>D</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'e' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('e')}>E</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'f' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('f')}>F</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'g' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('g')}>G</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'h' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('h')}>H</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'i' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('i')}>I</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'j' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('j')}>J</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'k' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('k')}>K</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'l' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('l')}>L</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'm' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('m')}>M</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'n' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('n')}>N</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'o' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('o')}>O</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'p' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('p')}>P</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'q' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('q')}>Q</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'r' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('r')}>R</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 's' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('s')}>S</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 't' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('t')}>T</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'u' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('u')}>U</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'v' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('v')}>V</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'w' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('w')}>W</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'x' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('x')}>X</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'y' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('y')}>Y</a></div>
        <div className='col-xs-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === 'z' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('z')}>Z</a></div>
        <div className='col-xs-6 col-sm-3 col-md-2 col-lg-1' style={{ marginTop: '25px' }}><a className={lastNameFilter === '' ? style.Active : ''} onClick={() => alphabeticalFilterClicked('')}>No Filter</a></div>
      </div>
    </ >
  )
};

export default AtoZFilter;
