import { Fragment } from 'react';

import OtsOfficialNationalCertificationHistoryWrite from './OtsOfficialNationalCertificationHistoryWrite';
import OtsOfficialNationalCertificationHistoryRead from './OtsOfficialNationalCertificationHistoryRead';

import useOtsOfficialNationalCertificationHistory from './UseOtsOfficialNationalCertificationHistory';

const OtsOfficialNationalCertificationHistory = () => {
  const {
    otsOfficialHeaderState,
    userInfo
  } = useOtsOfficialNationalCertificationHistory();
  
  return (
    <Fragment>
      {otsOfficialHeaderState?.objData?.memberId !== userInfo?.memberId ?
      <OtsOfficialNationalCertificationHistoryWrite /> :
      <OtsOfficialNationalCertificationHistoryRead />}
    </Fragment >
  );
};

export default OtsOfficialNationalCertificationHistory;