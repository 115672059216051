import React, { createContext, useState } from 'react';

import GenderIdentityTypeData from './GenderIdentityTypeData';

export const GenderIdentityTypeStateContext = createContext();

const GenderIdentityTypeContextProvider = ({ children }) => {
  const stateHook = useState(GenderIdentityTypeData.INITIAL_STATE);

  return (
    <GenderIdentityTypeStateContext.Provider value={stateHook}>
      {children}
    </GenderIdentityTypeStateContext.Provider>
  );
};

export default GenderIdentityTypeContextProvider;