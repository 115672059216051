import getUserEnrollment from './GetEnrollmentData';
import postEnrollmentByExternalLmsCourseId from './PostEnrollmentByExternalLmsCourseIdData';
import postEnrollmentByCertificationId from './PostEnrollmentByCertificationIdData';
import putEnrollment from './PutEnrollmentData';
import postCoachEnrollmentByCertification from './PostCoachEnrollmentByCertificationData';
import postCoachEnrollmentByEligibleCoaches from './PostCoachEnrollmentByEligibleCoachesData';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const INITIAL_STATE = {
    ...BASIC_INITIAL_STATE,
    filteredEnrollmentCertList: [],
    filteredEligibileCoachList: []
};

const EnrollmentData = {
    INITIAL_STATE: { ...INITIAL_STATE },
    getUserEnrollment,
    postEnrollmentByExternalLmsCourseId,
    postEnrollmentByCertificationId,
    putEnrollment,
    postCoachEnrollmentByCertification,
    postCoachEnrollmentByEligibleCoaches,
};
export default EnrollmentData;