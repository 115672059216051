import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useOtsMeetStaffData from '../../../../state/otsMeetStaff/UseOtsMeetStaffData';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../../common/utils/Constants';

const useOtsMeetStaff = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { otsMeetStaffState, getOtsMeetStaff } = useOtsMeetStaffData();
  const [state, setState] = useState({ tryGet: false });

  const onEditStaff = (e, staff) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(navRoutes.OTS_MEET_STAFF_DETAIL?.route, {
      state: {
        meetStaffTypeId: staff.meetStaffTypeId,
        meetStaffTypeName: staff.meetStaffTypeName,
        memberId: staff?.memberId || '',
        personId: staff?.personId || ''
      }
    });
  }

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      setState({ ...state, tryGet: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (otsMeetHeaderState.isObjLoaded === true && otsMeetStaffState.isArrayLoading !== true
      && (otsMeetStaffState.isArrayLoaded !== true || state.tryGet === true)) {
      getOtsMeetStaff(otsMeetHeaderState.objData?.meetId);
      if (state.tryGet === true) {
        setState({ ...state, tryGet: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetHeaderState.isObjLoaded, otsMeetStaffState, state.tryGet])

  return {
    otsMeetStaffState,
    isLoading: otsMeetStaffState.isArrayLoading,
    Constants,
    onEditStaff
  };
}

export default useOtsMeetStaff;