import { Fragment } from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

const DeletePersonRequirementEnrollmentModal = ({ modalState }) => (
  <Fragment>
    <p className='col-xs-12'><strong>Course Name:</strong>&nbsp;{modalState.course?.courseName} ({modalState.course?.courseCode})</p>
    <p className='col-xs-12 col-sm-6'><strong>Date Completed:</strong>&nbsp;{formatDate(modalState.course?.courseCompletionDate)}</p>
    <p className='col-xs-12 col-sm-6'><strong>Expiration Date:</strong>&nbsp;{formatDate(modalState.course?.courseExpirationDate) === Constants.DEFAULT_MAX_DATE ? `Doesn't Expire` : formatDate(modalState.course?.courseExpirationDate)}</p>
    <p className='col-xs-12'>Are you sure you want to delete this course enrollment?</p>
  </Fragment>
);

export default DeletePersonRequirementEnrollmentModal;