import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postNationalTeamAttachmentFilesData from './PostNationalTeamAttachmentFilesData';
import putNationalTeamAttachmenFilesData from './PutNationalTeamAttachmentFilesData';
import deleteNationalTeamAttachmentFilesData from './DeleteNationalTeamAttachmentFilesData';

const BASE_UPLOAD_URL = 'https://memberdocs.blob.core.windows.net/national-team-attachments/';
const GET_MEMBER_FILES_NATIONAL_TEAM_ATTACHMENT_PATH = '/PersonFiles/nationalTeamAttachment?fileUrl=';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const NationalTeamAttachmentFilesData = {
  BASE_UPLOAD_URL,
  GET_MEMBER_FILES_NATIONAL_TEAM_ATTACHMENT_PATH,
  INITIAL_STATE,
  postNationalTeamAttachmentFilesData,
  putNationalTeamAttachmenFilesData,
  deleteNationalTeamAttachmentFilesData
};

export default NationalTeamAttachmentFilesData;