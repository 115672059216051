import { isValidDate, } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

export const localValidate = (formState) => {
  let errors = {};

  let effectiveDate = new Date(formState.effectiveDate);
  let expirationDate = new Date(formState.expirationDate);

  //Expiration Date
  if (formState.expirationDate === Constants.BLANK_DATE_STRING) {
    errors.expirationDate = 'Expiration Date is required';
  } else if (!isValidDate(formState.expirationDate)) {
    errors.expirationDate = 'Expiration Date must be a valid date';
  } else if (expirationDate < effectiveDate) {
    errors.expirationDate = `Expiration Date cannot be before Effective Date (${formatDate(formState.effectiveDate)})`;
  }

  return errors;
};

const OtsOfficialLscBulkCertificationDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OtsOfficialLscBulkCertificationDetailValidation;