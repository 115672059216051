import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../../common/utils/HttpHelper';
import { isValidMemberId } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Official
  if (formState.meetOfficialMemberId.trim() === '') {
    errors.meetOfficialMemberId = 'Official Member ID is required';
  } else if (!isValidMemberId(formState.meetOfficialMemberId.trim())) {
    errors.meetOfficialMemberId = 'Official Member ID must be 14 alphanumeric characters';
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  //Official
  const meetOfficialMemberIdForUrl = formState.meetOfficialMemberId ? encodeURIComponent(formState.meetOfficialMemberId) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${meetOfficialMemberIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (objData.isOrgRoleDurationActive === false || !(objData.personId)) {
          errors.meetOfficialMemberId = "Official Member ID does not have an active member registration";
        } else {
          formState.meetOfficialPersonId = objData.personId;
          formState.firstName = objData.firstName;
          formState.lastName = objData.lastName;
        }
      }
    })
    .catch(() => {
      errors.meetOfficialMemberId = 'Official Member ID is not valid';
    });

  const meetOfficialPersonIdForUrl = formState.meetOfficialPersonId ? encodeURIComponent(formState.meetOfficialPersonId) : 'NaN';
  const roleGroupIdForUrl = formState.OFFICIALS_ROLES_ROLE_GROUP_ID ? encodeURI(formState.OFFICIALS_ROLES_ROLE_GROUP_ID) : 'NaN';
  await PersonHttpHelper(`/Person/${meetOfficialPersonIdForUrl}/hasActiveRegistration?roleGroupId=${roleGroupIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        if (objData.hasActiveRegistration === false) {
          errors.meetOfficialMemberId = "Official Member ID does not have an active official registration";
        }
        else {
          formState.selectedValidMemberId = true;
        }
      }
    })
    .catch(() => {
      errors.meetOfficialMemberId = "Official Member ID does not have an active official registration";
    });

  return errors;
};

const OtsMeetOfficialsOfficialFormValidation = async (formState) => {
  let errors = localValidate(formState);
  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default OtsMeetOfficialsOfficialFormValidation;