import React, { Fragment } from 'react';

import useMyLscOtsMeetSelection from './UseMyLscOtsMeetSelection';

import SelectOrgUnit from '../../../../../../common/components/selectOrgUnit/SelectOrgUnit';

const MyLscOtsMeetSelection = () => {
    const { TAXONOMIES, SCOPE, MY_LSC_OTS_MEET_SEARCH_ROLE_GROUP_ID, NavLinks } = useMyLscOtsMeetSelection();

    return (
        <Fragment>
            <SelectOrgUnit
                pageTitle={'Select LSC'}
                navLink={NavLinks.MY_LSC_OTS_MEETS_SEARCH}
                getFunction={() => { }}
                roleGroupId={MY_LSC_OTS_MEET_SEARCH_ROLE_GROUP_ID}
                TAXONOMIES={TAXONOMIES}
                SCOPE={SCOPE} />
        </Fragment>
    );
};

export default MyLscOtsMeetSelection;