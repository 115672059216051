import React, { Fragment } from 'react';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { FormatMoneyWithSymbol } from '../../../../common/utils/FormatMoney';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeUpgradeGrid = ({ state, gridData, onCheckboxChange }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Team</th>
          <th>Current Membership (Price)</th>
          <th>Upgraded Membership (Price)</th>
          <th>Upgraded Expiration Date</th>
          <th>Price Difference</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr>
              {state.isArrayLoading === true
                ? <td colSpan="6">Loading...</td>
                : <td colSpan="6">You are not eligible for any membership upgrade at this time</td>}
            </tr>)
          : Array.isArray(gridData) === true && gridData.length > 0
            ? gridData.map((member, i) => (
              <tr key={i}>
                <td>{member?.orgUnitName} ({member?.orgUnitCode})</td>
                <td>{member?.upgradeFromOfferingName || ''} ({FormatMoneyWithSymbol(member?.upgradeFromOfferingTotalFeeAmount)})</td>
                <td>{member?.upgradeToOfferingName || ''} ({FormatMoneyWithSymbol(member.upgradeToOfferingTotalFeeAmount)})</td>
                <td>{formatDate(member?.expirationDate) || Constants.BLANK_DATE_STRING}</td>
                <td>{FormatMoneyWithSymbol(member?.priceDifference) || ''}</td>
                <td>
                  <Checkbox
                    label=""
                    name="isSelected"
                    onChange={(e) => { onCheckboxChange(e, member.memberRegistrationId, member.upgradeToOfferingInstanceId) }}
                    checked={member.isSelected || false} />
                </td>
              </tr>))
            : (
              <tr>
                <td colSpan="6">You are not eligible for any membership upgrade at this time</td>
              </tr>
            )
        }
      </tbody>
    </table>
  </Fragment>
);

export default LargeUpgradeGrid;