import { useEffect } from 'react';

import validate from './OtsOfficialsMeetHistoryValidation';

import useOtsOfficialMeetHistoryData from '../../../../state/officialsTrackingSystemOfficialMeetHistory/UseOtsOfficialMeetHistoryData';
import useOtsOfficialLscData from '../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';

import usePersonGeneralData from '../../../../../common/state/personGeneral/UsePersonGeneralData';
import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate, getTodaysDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  startDate: Constants?.BLANK_DATE_STRING,
  endDate: Constants?.BLANK_DATE_STRING,
};

const useOtsOfficialMeetHistory = () => {
  const {
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onFormValueChanged,
    setFormData,
    resetForm
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const { personGeneralState } = usePersonGeneralData();
  const { basePersonState } = useBasePersonData();
  const { getOtsOfficialMeetHistoryByPersonId, otsOfficialMeetHistoryState, clearOtsOfficialMeetHistoryState } = useOtsOfficialMeetHistoryData();
  const { otsOfficialHeaderState } = useOtsOfficialLscData();

  function onSubmitFormCallback(formState) {
    clearOtsOfficialMeetHistoryState();
  };

  const get39MonthsInPastFromTodaysDate = () => {
    const current = new Date();
    const date = `${current.getMonth() - 3}/${current.getDate()}/${current.getFullYear() - 3}`;
    return date
  };

  function createMeetHistoryFilterObj(formState) {
    return {
      startDate: formState.startDate !== Constants.BLANK_DATE_STRING ? formState.startDate : undefined,
      endDate: formState.endDate !== Constants.BLANK_DATE_STRING ? formState.endDate : undefined,
    };
  };

  const today = getTodaysDate();
  const todayMinus39Months = get39MonthsInPastFromTodaysDate();

  const onClearFilterClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setFormData({
      ...formState,
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING,
    });
  };

  useEffect(() => {
    if ((personGeneralState?.isObjLoaded === true && personGeneralState?.objData?.personId)
      || (basePersonState?.isObjLoaded === true && basePersonState?.objData?.personId)) {
      if (otsOfficialMeetHistoryState?.isArrayLoading !== true && otsOfficialMeetHistoryState?.isArrayLoaded !== true) {
        let personId = '';
        if (otsOfficialHeaderState?.isLoggedInUser === true) {
          personId = basePersonState?.objData?.personId;
        }
        else {
          personId = personGeneralState?.objData?.personId;
        }
        const filterObject = createMeetHistoryFilterObj(formState);
        getOtsOfficialMeetHistoryByPersonId(personId, filterObject);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState?.isObjLoaded, basePersonState?.objData?.personId, otsOfficialHeaderState?.isLoggedInUser, otsOfficialMeetHistoryState]);

  useEffect(() => {
    if (formatDate(formState?.startDate) === Constants?.BLANK_DATE_STRING
      && formatDate(formState.endDate) === Constants?.BLANK_DATE_STRING) {

      setFormData({
        ...formState,
        startDate: formatDate(todayMinus39Months),
        endDate: formatDate(today),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleSubmit,
    formState,
    errorState,
    onValueTextPairChanged,
    onFormValueChanged,
    resetForm,
    otsOfficialMeetHistoryState,
    onClearFilterClicked
  };
};

export default useOtsOfficialMeetHistory;