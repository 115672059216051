import React, { createContext, useState } from 'react';

export const OtsMeetFiltersStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: '',
  backToSystem: 'OtsMeet'
};

const OtsMeetFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <OtsMeetFiltersStateContext.Provider value={stateHook}>
      {children}
    </OtsMeetFiltersStateContext.Provider>
  );
};

export default OtsMeetFiltersContextProvider;