import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

import searchForOfficialsForBulkRecertify from "./SearchForOfficialsForBulkRecertifyData";
import putOtsOfficialBulkRecertify from "./PutOtsOfficialBulkRecertifyData";

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  tryRedirect: false,
  showFilters: true
};

const OtsOfficialsBulkRecertifyData = {
  INITIAL_STATE: { ...INITIAL_STATE },
  searchForOfficialsForBulkRecertify,
  putOtsOfficialBulkRecertify
}

export default OtsOfficialsBulkRecertifyData;