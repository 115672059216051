import { useEffect, useState } from "react";

const useProfileGoalsGrid = (goalsData, gridFilters) => {
  const [gridDataState, setGridDataState] = useState([]);

  useEffect(() => {
    if (gridFilters.eventId > 0) {
      setGridDataState(goalsData.filter(x => x.eventId === gridFilters.eventId));
    } else {
      setGridDataState(goalsData);
    }
  }, [goalsData, gridFilters]);

  return {
    gridDataState
  };
};

export default useProfileGoalsGrid;