import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOfficialPositionData from './GetOfficialPositionData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  positionType: ''
};

const OfficialPositionData = {
  INITIAL_STATE, getOfficialPositionData
}

export default OfficialPositionData;