import useReview from './UseReview';

import CertificationHeader from '../CertificationHeader';

import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PageNotFound from '../../../../../common/components/PageNotFound';

const Review = ({ personPositionCertificationProgression, onCancelClicked }) => {
  const {
    positionCertificationRule } = useReview(personPositionCertificationProgression, onCancelClicked);
  return (
    <>
      {personPositionCertificationProgression && Object.keys(personPositionCertificationProgression)?.length > 0 && <CertificationHeader positionCertificationProgression={personPositionCertificationProgression} showApplicationStatus={false} />}

      {personPositionCertificationProgression && Object.keys(personPositionCertificationProgression)?.length > 0 && positionCertificationRule && (
        <div className="row  usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <div dangerouslySetInnerHTML={{ __html: positionCertificationRule }} />
          </div>
        </div>
      )
      }

      {personPositionCertificationProgression && Object.keys(personPositionCertificationProgression)?.length > 0 && <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
        </div>
      </div>}

      {personPositionCertificationProgression && Object.keys(personPositionCertificationProgression)?.length === 0 &&
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>}
    </>
  );
};

export default Review;