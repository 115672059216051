/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatPlaceString } from '../../../../common/utils/TimesUtils';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ careerStat, onUpdateIncludeOnLaneSheet }) => (
  <tr>
    <td>{careerStat.eventCode || ''}</td>
    <td>{careerStat.meetName || ''}</td>
    <td>{careerStat.meetStartDate ? formatDate(careerStat.meetStartDate) : ''} - {careerStat.meetEndDate ? formatDate(careerStat.meetEndDate) : ''}</td>
    <td>{careerStat.sessionTypeName || ''}</td>
    <td>{careerStat.finishPosition ? formatPlaceString(careerStat.finishPosition) : ''}</td>
    <td>
      <Checkbox
        label=''
        name={`includeOnLaneSheet${careerStat.personCareerStatsId}`}
        checked={careerStat.includeOnLaneSheet}
        onChange={(value) => { onUpdateIncludeOnLaneSheet(careerStat.personCareerStatsId) }} />
    </td>
  </tr>
);

const ProfileCareerStatsGridLarge = ({ data, isLoading, onUpdateIncludeOnLaneSheet }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event</th>
        <th>Meet</th>
        <th>Meet Dates</th>
        <th>Session</th>
        <th>Finish Position</th>
        <th>Include in Lane Sheets?</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="6">Loading...</td></tr>
        : Array.isArray(data) && data.length > 0
          ? data.map((careerStat, i) => <GridRow key={i} careerStat={careerStat} onUpdateIncludeOnLaneSheet={onUpdateIncludeOnLaneSheet} />)
          : <tr><td colSpan="6">No Career Stats</td></tr>
      }
    </tbody>
  </table>
);

export default ProfileCareerStatsGridLarge;