import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ProfileCoachDetailValidation';

import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import useForm from '../../../../common/utils/UseForm';

const useProfileCoachDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personProfileState, putPersonProfile } = usePersonProfileData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, onValueTextPairChanged, resetForm, setIsDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [uiState, setUiState] = useState(getInitialUiState());

  useEffect(() => {
    if (location.state && location.state.coachPersonId && personProfileState.isObjLoaded === true) {
      const coachPersonId = location.state.coachPersonId;

      if (coachPersonId) {
        const selectedCoach = personProfileState.objData.coaches.find(e => e.coachPersonId === coachPersonId);

        setFormData({
          ...formState,
          coachPersonId: coachPersonId,
          qualifiesForCoachIncentive: selectedCoach === undefined ? false : selectedCoach.qualifiesForCoachIncentive,
          personCoachComment: selectedCoach === undefined ? '' : selectedCoach.personCoachComment
        });

        setIsDirty(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personProfileState]);

  useEffect(() => {
    if (uiState.tryRedirect === true && personProfileState.isObjLoading === false && personProfileState.isSaving === false) {
      navigate(navRoutes.MEMBER_PROFILE_COACH?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState, uiState.tryRedirect]);

  // Creates a copy of the personProfileState.objData and makes changes to it based on formState
  function createPutPersonObject() {
    const personToPut = JSON.parse(JSON.stringify(personProfileState.objData));

    if (formState.coachPersonId) {
      let coachObj = personToPut.coaches.find(e => e.coachPersonId === location.state.coachPersonId);
      const coachIndex = personToPut.coaches.findIndex(e => e.coachPersonId === location.state.coachPersonId);

      //edit
      if (coachObj !== undefined) {
        coachObj = {
          ...coachObj,
          qualifiesForCoachIncentive: formState.qualifiesForCoachIncentive,
          personCoachComment: formState?.personCoachComment
        };

        personToPut.coaches[coachIndex] = coachObj;
      } else {
        //add
        let personCoachArray = personToPut.coaches;

        personCoachArray.push({
          personId: personToPut.personId,
          coachPersonId: location.state.coachPersonId,
          qualifiesForCoachIncentive: formState.qualifiesForCoachIncentive,
          personCoachComment: formState?.personCoachComment,
          personCoachType: null
        });

        personToPut.coaches = personCoachArray;
      }
    }

    return personToPut;
  };

  function submitFormCallback(formState) {
    if (personProfileState.isSaving === false) {
      const personToPut = createPutPersonObject();
      if (personToPut !== null) {
        putPersonProfile(personProfileState.objData.personId, personToPut);
      }
    }

    setUiState({
      ...uiState,
      tryRedirect: true
    });
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_PROFILE_COACH?.route);
  };

  function getInitialFormState() {
    return {
      coachPersonId: '',
      qualifiesForCoachIncentive: false,
      personCoachComment: ''
    };
  };

  function getInitialUiState() {
    return {
      tryRedirect: false
    };
  };

  return {
    personProfileState,
    formState,
    errorState,
    uiState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    resetForm,
    onCancelClicked
  };
};

export default useProfileCoachDetail;