import { createContext, useState } from 'react';

import PersonPositionCertificationNoteData from './PersonPositionCertificationNoteData';

export const PersonPositionCertificationNoteStateContext = createContext();

const PersonPositionCertificationNoteContextProvider = ({ children }) => {
  const stateHook = useState(PersonPositionCertificationNoteData.INITIAL_STATE);

  return (
    <PersonPositionCertificationNoteStateContext.Provider value={stateHook}>
      {children}
    </PersonPositionCertificationNoteStateContext.Provider>
  );
};

export default PersonPositionCertificationNoteContextProvider;