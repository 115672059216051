import { useContext } from 'react';

import { SearchOtsMeetStateContext } from './SearchOtsMeetContextProvider';

const useSearchOtsMeetData = () => {
  const [searchOtsMeetState, setSearchOtsMeetState] = useContext(SearchOtsMeetStateContext);

  return {
    searchOtsMeetState,
    setSearchOtsMeetState
  };
};

export default useSearchOtsMeetData;