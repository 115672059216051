import { Route, Routes } from 'react-router-dom';

import OtsCertificationApplicationContextStateContextProvider from '../../../../state/otsCertificationApplication/OtsCertificationApplicationContextStateContextProvider';
import OtsCertificationApplicationContextProvider from '../../../../state/otsCertificationApplication/OtsCertificationApplicationContextProvider';
import OfficialPositionCertificationTypeProgressionContextProvider from '../../../../state/officialPositionCertificationTypeProgression/OfficialPositionCertificationTypeProgressionContextProvider';
import PersonPositionCertificationNoteContextProvider from '../../../../state/personPositionCertificationNote/PersonPositionCertificationNoteContextProvider';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import BasePersonContextProvider from '../../../../../common/state/personBase/BasePersonContextProvider';
import BasePersonFiltersContextProvider from '../../../../../common/state/personBase/BasePersonFiltersContextProvider';

import SecuredRouteElement from '../../../../../common/components/security/SecuredRouteElement';

import CertificationReviewSearch from './CertificationReviewSearch';
import CertificationReviewOfficial from './CertificationReviewOfficial';
import CertificationReviewOfficialApplication from './CertificationReviewOfficialApplication';

const ContextRoutes = () => {
  const { navRoutes } = useNavRoutes();

  return (
    <Routes>
      <Route path={navRoutes['OTS_CERT_APP_WORKFLOW_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_CERT_APP_WORKFLOW_SEARCH']}
            editElement={<CertificationReviewSearch />} />
        }
      />
      <Route path={navRoutes['OTS_CERT_APP_WORKFLOW_OFFICIAL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_CERT_APP_WORKFLOW_OFFICIAL']}
            editElement={<CertificationReviewOfficial />} />
        }
      />
      <Route path={navRoutes['OTS_CERT_APP_WORKFLOW_OFFICIAL_CERTIFICATION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OTS_CERT_APP_WORKFLOW_OFFICIAL_CERTIFICATION']}
            editElement={<CertificationReviewOfficialApplication />} />
        }
      />
    </Routes>
  )
};

const CertificationReview = () => (
  <BasePersonContextProvider>
    <BasePersonFiltersContextProvider>
      <OtsCertificationApplicationContextProvider>
        <OtsCertificationApplicationContextStateContextProvider>
          <OfficialPositionCertificationTypeProgressionContextProvider>
            <PersonPositionCertificationNoteContextProvider>
              <ContextRoutes />
            </PersonPositionCertificationNoteContextProvider>
          </OfficialPositionCertificationTypeProgressionContextProvider>
        </OtsCertificationApplicationContextStateContextProvider>
      </OtsCertificationApplicationContextProvider>
    </BasePersonFiltersContextProvider>
  </BasePersonContextProvider>
);

export default CertificationReview;