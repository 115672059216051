import { useEffect, useState } from "react";
import { isRegistrationPeriodInFuture } from "../../../utils/OfferingManagementUtils";

const useOfferingInstanceOrgUnitGrid = (gridData, canAddAdditionalInstance) => {
  const [gridState, setGridState] = useState([]);

  useEffect(() => {
    if (Array.isArray(gridData)) {
      const newGridState = [];

      for (const offeringInstance of gridData) {
        // If offering instance only has one orgUnit instance, flatten data
        if (Array.isArray(offeringInstance.offeringInstanceOrgUnit)) {
          if (offeringInstance.offeringInstanceOrgUnit.length === 1) {
            const orgUnitOfferingInstance = offeringInstance.offeringInstanceOrgUnit[0];
            const canEditInstance = isRegistrationPeriodInFuture(orgUnitOfferingInstance.registrationStartDate);

            newGridState.push({
              ...offeringInstance,
              ...orgUnitOfferingInstance,
              canEditInstance,
              canAddAdditionalInstance,
              offeringInstanceOrgUnit: null
            });
          } else {
            const additionalInstances = [];
            let canEditInstance = true;

            for (const additionalInstance of offeringInstance.offeringInstanceOrgUnit) {
              const canEditAdditionalInstance = isRegistrationPeriodInFuture(additionalInstance.registrationStartDate);

              if (canEditAdditionalInstance === false) {
                canEditInstance = false;
              }

              additionalInstances.push({
                ...additionalInstance,
                canEditAdditionalInstance
              });
            }

            newGridState.push({
              ...offeringInstance,
              canEditInstance,
              canAddAdditionalInstance,
              offeringInstanceOrgUnit: additionalInstances
            });
          }
        } else {
          newGridState.push({ 
            ...offeringInstance,
            canEditInstance: false,
            canAddAdditionalInstance: false,
          });
        }
      }

      setGridState(newGridState);
    }
  }, [gridData, canAddAdditionalInstance]);

  return {
    formattedGridData: gridState
  };
};

export default useOfferingInstanceOrgUnitGrid;