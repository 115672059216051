import { useContext, useEffect } from 'react';

import { FrequentFlyerTypeStateContext } from './FrequentFlyerTypeContextProvider';

import FrequentFlyerTypeData, { defaultFrequentFlyerTypeState } from './FrequentFlyerTypeData';

const useFrequentFlyerTypeData = () => {
  const [frequentFlyerTypeState, setFrequentFlyerTypeState] = useContext(FrequentFlyerTypeStateContext);

  useEffect(() => {
    if (frequentFlyerTypeState.isLoading !== true && frequentFlyerTypeState.isLoaded !== true) {
      FrequentFlyerTypeData.getFrequentFlyerTypes(frequentFlyerTypeState, setFrequentFlyerTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    frequentFlyerTypeState,
    defaultFrequentFlyerTypeState
  };
};

export default useFrequentFlyerTypeData;