import { Fragment } from 'react';

import MemberRequirementsLargeWriteGrid from './MemberRequirementsLargeWriteGrid';
import MemberRequirementsSmallWriteGrid from './MemberRequirementsSmallWriteGrid';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MemberRequirementsGrid = ({ gridData, isLoading, onEditRequirement, formState, onOpenDeleteEnrollmentModal }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MemberRequirementsLargeWriteGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditRequirement={onEditRequirement}
        formState={formState}
        onOpenDeleteEnrollmentModal={onOpenDeleteEnrollmentModal}
        expandedId={expandedId}
        onClick={onClick} />
      <MemberRequirementsSmallWriteGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditRequirement={onEditRequirement}
        formState={formState}
        onOpenDeleteEnrollmentModal={onOpenDeleteEnrollmentModal}
        expandedId={expandedId}
        onClick={onClick} />
    </Fragment>
  );
};

export default MemberRequirementsGrid;