import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ officialPositionType }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{officialPositionType.positionTypeName} - {officialPositionType.positionAbbreviation}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Role
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {officialPositionType.positionTypeName} - {officialPositionType.positionAbbreviation}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Evaluator Title
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {officialPositionType.nationalEvaluatorTitle || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {officialPositionType.effectiveDate ? formatDate(officialPositionType.effectiveDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {officialPositionType.expirationDate ? formatDate(officialPositionType.expirationDate) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ officialPositionTypes }) => (
  <div className={global.SmallTableDetailTable}>
    {officialPositionTypes.map((officialPositionType, i) => <DetailGridRow key={i} officialPositionType={officialPositionType} />)}
  </div>
);


const SmallGridRow = ({ evaluator, onEdit, onDelete, expandedId, onExpandClicked }) => {
  const positionAbbreviations = Array.isArray(evaluator.officialPositionTypes)
    ? evaluator.officialPositionTypes.map(p => p.positionAbbreviation).join(', ')
    : '';
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{evaluator.evaluatorName}</span>&nbsp;
          <button
            className={global.IconButton}
            type="button"
            onClick={(e) => onExpandClicked(e, evaluator.nationalEvaluatorId)}>
            {expandedId === evaluator.nationalEvaluatorId ? <HideIcon /> : <ShowIcon />}
          </button>
          <button
            className={global.IconButtonMobileMargin}
            type="button"
            onClick={() => onDelete(evaluator.nationalEvaluatorId)}>
            <DeleteIcon />
          </button>
          <button
            className={global.IconButtonMobileMargin}
            type="button"
            onClick={() => onEdit(evaluator.nationalEvaluatorId)}>
            <EditIcon />
          </button>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
              Evaluator Name
            </div>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
              {evaluator.evaluatorName}
            </div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Club Name</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{evaluator.orgUnitName || <span>&nbsp;</span>} {evaluator.orgUnitName === 'Unattached' ? (evaluator.parentOrgUnitName ? '- ' + evaluator.parentOrgUnitName : '') : ''} {evaluator?.parentOrgUnitCode ? '(' + evaluator?.parentOrgUnitCode + ')' : ''}</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Roles
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {positionAbbreviations}
            </div>
          </div>
        </div>
      </div>
      {Array.isArray(evaluator.officialPositionTypes) && evaluator.officialPositionTypes.length > 0 && expandedId === evaluator.nationalEvaluatorId &&
        <DetailGrid officialPositionTypes={evaluator.officialPositionTypes} />
      }
    </Fragment>
  );
};

const NationalEvaluatorsGridSmall = ({ gridData, onEdit, onDelete, expandedId, onExpandClicked }) => {
  return (
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((evaluator, i) => <SmallGridRow key={i} evaluator={evaluator} onEdit={onEdit} onDelete={onDelete} expandedId={expandedId} onExpandClicked={onExpandClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
          </div>
        )
      }
    </div>
  );
};

export default NationalEvaluatorsGridSmall;