import React, { createContext, useState } from 'react';

import MeetEvaluationPersonOfficialData from './MeetEvaluationPersonOfficialData';

export const MeetEvaluationPersonOfficialStateContext = createContext();

const MeetEvaluationPersonOfficialContextProvider = ({ children }) => {
  const stateHook = useState(MeetEvaluationPersonOfficialData.INITIAL_STATE);

  return (
    <MeetEvaluationPersonOfficialStateContext.Provider value={stateHook}>
      {children}
    </MeetEvaluationPersonOfficialStateContext.Provider>
  );
};

export default MeetEvaluationPersonOfficialContextProvider;