import Constants from '../../../common/utils/Constants';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  dataAsOptionsWithNames: [],
  message: ''
};

export const defaultIneligibleReasonState = {
  ineligibleReasonId: Constants.DEFAULT_ID,
  reasonDescription: ''
};

const getIneligibleReasons = (state, setState) => {
  if (state.isLoading !== true && state.isLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      data: [],
      dataAsOptionsWithNames: [],
      message: 'Loading...'
    };
    setState(newState);

    const url = `/IneligibleReason`;
    PersonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const blankIneligibleReason = { ineligibleReasonId: Constants.DEFAULT_ID, reasonDescription: '--' };
          data.unshift(blankIneligibleReason);
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((ineligibleReason) => { return { id: ineligibleReason.ineligibleReasonId, name: ineligibleReason.reasonDescription } }),
            message: '',
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  };
};

const IneligibleReasonData = { INITIAL_STATE, getIneligibleReasons };

export default IneligibleReasonData;