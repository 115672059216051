import React, { Fragment } from 'react';

import useMemberAffiliations from './UseMemberAffiliations';

import MemberAffiliationsGrid from './MemberAffiliationsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../common/utils/Constants';

const MemberAffiliations = () => {
  const {
    isLoading,
    isSaving,
    affiliationsState,
    onAddClicked,
    onEditClicked,
    USAS_ORGANIZATION_ID
  } = useMemberAffiliations();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Affiliations</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddClicked}>Add Affiliation</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          {USAS_ORGANIZATION_ID > 0 &&
            <MemberAffiliationsGrid gridData={affiliationsState.arrayData} isLoading={affiliationsState.isArrayLoading} onEditClicked={onEditClicked} USAS_ORGANIZATION_ID={USAS_ORGANIZATION_ID} />
          }
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default MemberAffiliations;