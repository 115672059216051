const ToBoolIfBool = (value) => {
  try{
    if (value === null || value === undefined) return null;
    if (value === true) return true;
    if (value === false) return false;

    const trueRegex = /^\s*(true|1)\s*$/i;
    if (trueRegex.test(value)) return true;

    const falseRegex = /^\s*(false|0)\s*$/i;
    if (falseRegex.test(value)) return false;

    return null;
  } catch {
    return null;
  }
};

export default ToBoolIfBool;