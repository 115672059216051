import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import getUser from './GetUserData';
import postUserCanvas from './PostUserCanvasData';
import postUserCss from './PostUserCssData';

const INITIAL_STATE = {
    ...BASIC_INITIAL_STATE
};

const UserData = { INITIAL_STATE, getUser, postUserCanvas, postUserCss };
export default UserData;