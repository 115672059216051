import Constants from '../../../common/utils/Constants';
import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  dataAsOptionsWithNames: [],
  message: ''
};

export const defaultSupportStaffTypeState = {
  supportStaffTypeId: Constants.DEFAULT_ID,
  typeName: ''
};

const getSupportStaffTypes = (state, setState) => {
  if (state.isLoading !== true && state.isLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      data: [],
      dataAsOptionsWithNames: [],
      message: 'Loading...'
    };
    setState(newState);

    const url = `/SupportStaffType`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const blankSupportStaffType = { supportStaffTypeId: Constants.DEFAULT_ID, typeName: '--' };
          data.unshift(blankSupportStaffType);
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((supportStaffType) => { return { id: supportStaffType.supportStaffTypeId, name: supportStaffType.typeName } }),
            message: '',
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  };
};

const SupportStaffTypeData = { INITIAL_STATE, getSupportStaffTypes };

export default SupportStaffTypeData;