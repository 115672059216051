import useCertificationReview from './UseCertificationReview';

import Review from '../review/Review';

import LeftArrowIcon from '../../../../../common/components/icons/LeftArrowIcon';
import Headings from '../../../../../common/components/headings/Headings';

import global from '../../../../../common/components/GlobalStyle.module.css';

const CertificationReviewOfficialApplication = () => {
  const {
    officialName,
    memberId,
    personPositionCertificationProgression,
    buttonClickHandlers } = useCertificationReview();
  return (
    <>
      {officialName && memberId && <>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <button className={global.HeaderButton}>
              <Headings.H3
                className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
                excludeBar={true}>
                Review National Certification Applications
              </Headings.H3>
            </button>
          </div>
          <div className="col-xs-12">
            <hr className="usas-bar-turmeric" />
          </div>
        </div>
      </>}
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={buttonClickHandlers.onGoToOfficialsWithActiveApplicationsClicked}>
            <LeftArrowIcon />  Back to Officials with Active Applications
          </button>
        </div>
      </div>
      {officialName && memberId && (
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <p className={global.HeaderText}>
              <strong>Official Name:</strong>&nbsp;{officialName}
            </p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <p className={global.HeaderText}>
              <strong>Member ID:</strong>&nbsp;{memberId}
            </p>
          </div>
        </div>)}
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Review personPositionCertificationProgression={personPositionCertificationProgression}
            onCancelClicked={buttonClickHandlers.onCancelClicked} />
        </div>
      </div>
    </>
  );
}

export default CertificationReviewOfficialApplication;