import React, { Fragment } from 'react';

import useProfileGoalsDetail from './UseProfileGoalsDetail';

import EventCombobox from '../../../../common/components/comboboxes/eventCombobox/EventCombobox';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import TimeInput from '../../../../common/components/inputs/TimeInput';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import Constants from '../../../../common/utils/Constants';

const ProfileGoalsDetail = () => {
  const {
    isSaving,
    formState,
    errorState,
    dupModalState,
    onValueTextPairChanged,
    handleSubmit,
    onModalSaveClicked,
    onModalCancelClicked,
    onCancelClicked
  } = useProfileGoalsDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.personGoalId > 0 ? "Edit" : "Add"} Goal</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            {formState.personGoalId > 0 ?
              <ReadOnly
                label='Event'
                name='eventId'
                value={formState.eventName} />
              :
              <EventCombobox
                label='Event*'
                name='eventId'
                valueToMatch={formState.eventName}
                error={errorState.eventId}
                message={errorState.eventId}
                onChange={(newValue, newValueLabel) => onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName')} />
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <TimeInput
              label='Goal Time*'
              name='goalTime'
              value={formState.goalTime}
              error={errorState.goalTime}
              onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'goalTime', value, 'goalTimeValue'); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Update Goal Time?'}
        displayPopUp={dupModalState.displayPopUp}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{formState.eventName} already has a goal time.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Current Goal Time:</b> {dupModalState.currentGoalTime}</p>
            <p><b>New Goal Time:</b> {formState.goalTimeValue}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type='button' onClick={onModalSaveClicked}>Save</PrimaryButton>
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default ProfileGoalsDetail;