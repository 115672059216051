import useCountryData from "../../../state/country/UseCountryData";

const useCountryCombobox = () => {
  const { getCountryState } = useCountryData();
  const countryData = getCountryState();

  function getCountryNameById(countryId) {
    for (const country of countryData) {
      if (countryId === country.id) {
        return country.name;
      }
    }

    return '';
  }

  function getCountryIdByName(countryName) {
    for (const country of countryData) {
      if (countryName === country.name) {
        return country.id;
      }
    }

    return '';
  }

  return { countryData, getCountryNameById, getCountryIdByName };
};

export default useCountryCombobox;