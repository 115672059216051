import { useContext} from 'react';

import { OfficialLscCertificationStateContext } from './OfficialLscCertificationContextProvider';

import OfficialLscCertificationData from './OfficialLscCertificationData';

const useOfficialLscCertificationData = () => {
  const [officialLscCertificationState, setOfficialLscCertificationState] = useContext(OfficialLscCertificationStateContext);

  const getOfficialLscCertificationByOrgUnitId = (orgUnitId) => OfficialLscCertificationData.getOfficialLscCertificationByOrgUnitId(orgUnitId, officialLscCertificationState, setOfficialLscCertificationState);

  const postOfficialLscCertification = (lscCertificationObj) => OfficialLscCertificationData.postOfficialLscCertification(lscCertificationObj, officialLscCertificationState, setOfficialLscCertificationState)

  const putOfficialLscCertification = (personOfficialCertificationId, lscCertificationObj) => OfficialLscCertificationData.putOfficialLscCertification(personOfficialCertificationId, lscCertificationObj, officialLscCertificationState, setOfficialLscCertificationState);

  const deleteOfficialLscCertification = (personOfficialCertificationId)=> OfficialLscCertificationData.deleteOfficialLscCertification(personOfficialCertificationId, officialLscCertificationState, setOfficialLscCertificationState);

  const clearOfficialLscCertificationObjData = () => {
    setOfficialLscCertificationState({
      ...officialLscCertificationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOfficialLscCertificationArrayData = () => {
    setOfficialLscCertificationState({
      ...officialLscCertificationState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const resetOfficialLscCertificationState = () => {
    setOfficialLscCertificationState(OfficialLscCertificationData?.INITIAL_STATE);
  };

  const confirmOfficialLscCertificationSave = () => {
    setOfficialLscCertificationState({
      ...officialLscCertificationState,
      isSaved: false
    });
  };

  return {
    officialLscCertificationState,
    getOfficialLscCertificationByOrgUnitId,
    clearOfficialLscCertificationObjData,
    clearOfficialLscCertificationArrayData,
    confirmOfficialLscCertificationSave,
    postOfficialLscCertification,
    putOfficialLscCertification,
    resetOfficialLscCertificationState,
    deleteOfficialLscCertification
  };
};

export default useOfficialLscCertificationData;