import { useState } from "react";

import DecryptIdData from "./DecryptIdData";

const useDecryptIdData = () => {
  const [decryptedIdState, setDecryptedIdState] = useState(DecryptIdData.INITIAL_STATE);

  const getDecryptedId = (encryptedIdObj) => {
    return DecryptIdData.getDecryptedIdData(encryptedIdObj, decryptedIdState, setDecryptedIdState);
  };

  return {
    decryptedIdState,
    getDecryptedId
  };
};

export default useDecryptIdData;