import EditIcon from '../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ official, onSelectClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{official.firstName || ''} {official.lastName || ''} - {official.memberId}</span>&nbsp;
      <button className={global.IconButton} type="button" onClick={() => onSelectClicked(official)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {official.preferredName || official.firstName} {official.lastName}
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Member ID
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {official.memberId}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Email
        </div>
        <div className={['col-xs-6 col-sm-9', global.SmallTableRowData].join(' ')}>
          {official.email || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Submission Date
        </div>
        <div className={['col-xs-6 col-sm-9', global.SmallTableRowData].join(' ')}>
          {official.applicationDate ? formatDate(official.applicationDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Status
        </div>
        <div className={['col-xs-6 col-sm-9', global.SmallTableRowData].join(' ')}>
          {official.status || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);


const SmallGrid = ({ gridData, onSelectClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(gridData) && gridData.length > 0
      ? gridData.map((official, i) =>
        <SmallGridRow
          key={i}
          official={official}
          onSelectClicked={onSelectClicked} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
        </div>
      )
    }
  </div>
);

export default SmallGrid;