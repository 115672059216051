import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";
import searchForOfficial from "./SearchForOfficialData";

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  tryRedirect: false,
  showFilters: true
};

const SearchOtsOfficialData = {
  INITIAL_STATE: { ...INITIAL_STATE },
  searchForOfficial
}

export default SearchOtsOfficialData;