import { useEffect } from "react";

import validate from './OfferingTypeClubFormValidation';

import UseForm from "../../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  offeringName: '',
  offeringDescription: ''
};

const useOfferingTypeClubForm = (onSubmitFormCallback, offeringObj) => {
  const { formState, errorState, setFormState, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, formatBeforeSubmit, validate);

  useEffect(() => {
    if (offeringObj) {
      setFormState({
        ...formState,
        offeringName: offeringObj.offeringName ?? '',
        offeringDescription: offeringObj.offeringDescription ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringObj]);

  function formatBeforeSubmit(formState) {
    const submitObj = {
      offeringId: offeringObj.offeringId || undefined,
      // offeringInstance: offeringObj.offeringInstance || null,
      offeringTypeId: offeringObj.offeringTypeId,
      offeringTypeName: offeringObj.offeringTypeName,
      offeringOrgRole: [],
      offeringOptionalAttributes: [],

      offeringName: formState.offeringName.trim(),
      offeringDescription: formState.offeringDescription.trim(),
    };

    onSubmitFormCallback(submitObj);
  }

  return {
    formState,
    errorState,

    updateFormState,
    handleSubmit
  };
};

export default useOfferingTypeClubForm;