import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';
import { useLocation } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './MemberCoursesDetailValidation';

import usePersonCoursesData from '../../../../state/member/memberCourses/UsePersonCoursesData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_STATE = {
  view: '',
  viewService: '',
  course: {},
  personId: '',
  apiErrorMessage: ''
};

const INITIAL_FORM_STATE = {
  courseName: '',
  lmsCourseId: Constants.DEFAULT_ID,
  courseCompletionDate: Constants.BLANK_DATE_STRING,
  courseExpirationDate: Constants.BLANK_DATE_STRING,
  noExpirationDate: false,
};

const ADVANCED_WRITE_VIEW_CONSTANT = 'HQ';

const API_ERROR_MESSAGE = 'We are unable to complete your request at this time. Please try again later.';

const useMemberCoursesDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { memberPostCoursesAdminState, memberPutCoursesAdminState, postEnrollmentMemberCoursesAdmin,
    putMemberCourseEnrollmentAdmin
  } = usePersonCoursesData();
  const { formState, errorState, setFormData, onFormValueChanged, handleSubmit, onValueTextPairChanged } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onBackToMemberAllCourseWrite = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (state.view === ADVANCED_WRITE_VIEW_CONSTANT) {
      navigate(navRoutes?.MEMBER_ALLCOURSES_ADVANCED?.route);
    } else {
      navigate(navRoutes?.MEMBER_ALLCOURSES?.route);
    }
  };

  const getCourseExpirationDate = () => {
    return formState.noExpirationDate === false ? Constants.DEFAULT_MAX_DATE : formState.courseExpirationDate;
  };

  const getAddOrEdit = (course) => {
    return course === undefined ? 'Add' : 'Edit';
  };

  const onReloadClicked = () => {
    window.location.reload();
  }

  useEffect(() => {
    if (state.viewService === 'Edit') {
      const course = location.state?.course;

      setFormData({
        ...formState,
        courseName: course?.courseName,
        lmsCourseId: course?.lmsCourseId,
        courseCompletionDate: formatDate(course?.courseCompletionDate),
        courseExpirationDate: formatDate(course?.courseExpirationDate),
        noExpirationDate: formatDate(course?.courseExpirationDate) === Constants?.DEFAULT_MAX_DATE ? false : true,
        courseEnrollmentId: course?.courseEnrollmentId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewService]);

  useEffect(() => {
    if (location.state) {
      const course = location.state?.course;

      setState({
        ...state,
        view: location.state?.viewLocation,
        viewService: getAddOrEdit(course),
        personId: location.state?.personId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitFormCallback() {
    const personId = state.personId;
    const courseExpirationDate = getCourseExpirationDate();

    if (state.viewService === "Add") {
      const postObj = {
        personId: personId,
        lmsCourseId: formState.lmsCourseId,
        courseEnrollmentDate: formState.courseCompletionDate,
        courseExpirationDate: courseExpirationDate,
        coursePassed: true
      };

      const postEnrollmentPromise = postEnrollmentMemberCoursesAdmin(postObj);

      if (postEnrollmentPromise ?? false) {
        postEnrollmentPromise.then((newState) => {
          if (newState ?? false) {
            onBackToMemberAllCourseWrite();
          }
        }).catch(() => {
          setState({
            ...state,
            apiErrorMessage: API_ERROR_MESSAGE
          });
        });
      }
    } else {
      const courseId = formState.courseEnrollmentId;
      const putObject = {
        courseEnrollmentId: formState.courseEnrollmentId,
        courseCompletionDate: formState.courseCompletionDate,
        courseExpirationDate: courseExpirationDate,
        coursePassed: true
      };

      const putEnrollmentPromise = putMemberCourseEnrollmentAdmin(courseId, putObject);

      if (putEnrollmentPromise ?? false) {
        putEnrollmentPromise.then((newState) => {
          if (newState ?? false) {
            onBackToMemberAllCourseWrite();
          }
        }).catch(() => {
          setState({
            ...state,
            apiErrorMessage: API_ERROR_MESSAGE
          });
        });
      }
    }
  }

  return {
    isSaving: memberPostCoursesAdminState.isObjLoading || memberPutCoursesAdminState.isObjLoading,
    view: state.view,
    viewService: state.viewService,
    apiErrorMessage: state.apiErrorMessage,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onBackToMemberAllCourseWrite,
    onReloadClicked
  };
};

export default useMemberCoursesDetail;