import { useState } from 'react';

import PersonConvertToLifeMembershipData from './PersonConvertToLifeMembershipData';

const usePersonConvertToLifeMembershipData = () => {
  const [personConvertToLifeMembershipState, setPersonConvertToLifeMembershipState] = useState(PersonConvertToLifeMembershipData.INITIAL_STATE);

  const getPersonConvertToLifeMembership = (personId) => {
    PersonConvertToLifeMembershipData.getPersonConvertToLifeMembership(personId, personConvertToLifeMembershipState, setPersonConvertToLifeMembershipState);
  };

  const postPersonConvertToLifeMembership = (personId) => {
    PersonConvertToLifeMembershipData.postPersonConvertToLifeMembership(personId, personConvertToLifeMembershipState, setPersonConvertToLifeMembershipState);
  }

  return {
    personConvertToLifeMembershipState,
    setPersonConvertToLifeMembershipState,
    getPersonConvertToLifeMembership,
    postPersonConvertToLifeMembership

  };
};

export default usePersonConvertToLifeMembershipData;