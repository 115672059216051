import React, { Fragment } from 'react';

import useProfileGoalsHeaderForm from './UseProfileGoalsHeaderForm';

import EventCombobox from '../../../../common/components/comboboxes/eventCombobox/EventCombobox';

import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

const ProfileGoalsHeaderForm = ({ onAddGoal, onFilterGrid }) => {
  const {
    formState,
    errorState,
    onValueTextPairChanged
  } = useProfileGoalsHeaderForm(onFilterGrid);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-8 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddGoal}>Add Goal</ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-bottom-margin">
          <EventCombobox
            label='Event'
            name='eventId'
            valueToMatch={formState.eventName}
            error={errorState.eventId}
            message={errorState.eventId}
            onChange={(newValue, newValueLabel) => onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName')} />
        </div>
      </div>
    </Fragment >
  );
};

export default ProfileGoalsHeaderForm;