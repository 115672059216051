import { Fragment } from 'react';

import useProfileCoachDetail from './UseProfileCoachDetail';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import Textarea from '../../../../common/components/inputs/Textarea';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import Constants from '../../../../common/utils/Constants';

import style from '../Member.module.css';

const ProfileCoachDetail = () => {
  const {
    personProfileState,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onCancelClicked
  } = useProfileCoachDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>
            {formState.coachPersonId > 0 ? 'Edit Coach' : 'Add Coach'}
          </Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Qualifies for Coach Incentive?"
              name="qualifiesForCoachIncentive"
              checked={formState.qualifiesForCoachIncentive === true}
              error={errorState.qualifiesForCoachIncentive}
              message={errorState.qualifiesForCoachIncentive}
              onChange={(value) => { onFormValueChanged('qualifiesForCoachIncentive', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <Textarea
              label="Comments"
              className={style.CommentBoxMinHeight}
              name="personCoachComment"
              value={formState.personCoachComment}
              onChange={(value) => { onFormValueChanged('personCoachComment', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personProfileState.isSaving} />
    </Fragment>
  );
};

export default ProfileCoachDetail;