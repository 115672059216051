import { useContext, useEffect } from 'react';

import { CoachTypeStateContext } from './CoachTypeContextProvider';

import CoachTypeData, { defaultCoachTypeState } from './CoachTypeData';

const useCoachTypeData = () => {
  const [coachTypeState, setCoachTypeState] = useContext(CoachTypeStateContext);

  useEffect(() => {
    if (coachTypeState.isLoading !== true && coachTypeState.isLoaded !== true) {
      CoachTypeData.getCoachTypes(coachTypeState, setCoachTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    coachTypeState,
    defaultCoachTypeState
  };
};

export default useCoachTypeData;