import { isValidName, isValidEmail } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //First Name
  if (formState.firstName.trim() === '') {
    errors.firstName = 'First Name is required';
  } else if (!isValidName(formState.firstName.trim())) {
    errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  //Last Name
  if (formState.lastName.trim() === '') {
    errors.lastName = 'Last Name is required';
  } else if (!isValidName(formState.lastName.trim())) {
    errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  //Competition Category 
  if (formState.contactRelationshipTypeId < 0) {
    errors.contactRelationshipTypeId = 'Relationship is required';
  }

  //Email
  if (formState.email.trim() !== '') {
    if (!isValidEmail(formState.email.trim())) {
      errors.email = 'Email must be in a valid format';
    } else if (formState.email.trim().length > 512) {
      errors.email = 'Email cannot exceed 512 characters'
    }
  }

  return errors;
};

const NationalTeamContactDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default NationalTeamContactDetailValidation;