import React, { Fragment } from 'react';

import useProfileGoals from './UseProfileGoals';

import ProfileGoalsGrid from './ProfileGoalsGrid';
import ProfileGoalsHeaderForm from './ProfileGoalsHeaderForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const ProfileGoals = () => {
  const {
    isSaving,
    isLoading,
    goalsData,
    gridFilterState,
    onAddGoal,
    onGridFilter,
    onEditGoal,
    onDeleteGoal
  } = useProfileGoals();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Goals</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <ProfileGoalsHeaderForm
            onAddGoal={onAddGoal}
            onFilterGrid={onGridFilter} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <ProfileGoalsGrid
            goalsData={goalsData}
            isLoading={isLoading}
            gridFilters={gridFilterState}
            onEdit={onEditGoal}
            onDelete={onDeleteGoal} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment >
  );
};

export default ProfileGoals;