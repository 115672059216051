import { isValidMemberId } from '../../../../../common/utils/validation';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../../common/utils/HttpHelper';
import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.memberId.trim().length === 0) {
    errors.memberId = 'Member Id is required';
  }

  if (isValidMemberId(formState.memberId.trim()) === false) {
    errors.memberId = 'A valid Member Id is required';
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};
  const memberIdForUrl = formState.memberId ? encodeURIComponent(formState.memberId) : 'NaN';
  const url = `/Person/flat/memberId/${memberIdForUrl}`;

  await PersonHttpHelper(url, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        formState.personId = objData.personId;
        formState.person = objData;
      }
    })
    .catch((e) => {
      errors.apiError = 'Member Id is not valid';
    });

  if (!errors.apiError) {
    try {
      let found = false;
      formState.officialPositionTypes.forEach(position => {
        if (position.nationalEvaluatorTitleId > 0) {
          if (position.effectiveDate !== Constants.BLANK_DATE_STRING && position.effectiveDate !== '') {
            found = true;
          } else {
            errors.generalError = 'At least one of the selected positions does not have an effective date.';
          }
        }
      });

      if (!found) {
        errors.generalError = 'Please select a Title and Effective date for at least one of the positions.';
      }
    } catch {
      errors.generalError = 'Please select a Title and Effective date for at least one of the positions.';
    }
  }

  return errors;
};

const AddNationalEvaluatorValidation = async (formState) => {
  let errors = localValidate(formState);
  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default AddNationalEvaluatorValidation;