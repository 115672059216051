import React from 'react';

import useCertificationLevelDropdown from './UseCertificationLevelDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const CertificationLevelDropdown = ({ label, name, value, error, message, onChange, orgLevelId, officialPositionTypeId }) => {
  const { certificationLevelState } = useCertificationLevelDropdown(orgLevelId, officialPositionTypeId);

  return certificationLevelState.message
    ? <span className={global.LoadingMsg}>{certificationLevelState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={certificationLevelState.options}
        name={name}
        value={value}
        onChange={onChange}
        areValuesIntegers={true}
        isLoading={certificationLevelState.isArrayLoading} />
    );
};

export default CertificationLevelDropdown;