import { useEffect, useState } from 'react';

import usePersonClubTransferData from '../../../state/member/memberManageTransfers/UsePersonClubTransfersData';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import Constants from '../../../../common/utils/Constants';
import { formatDate, isValidDate } from '../../../../common/utils/DateFunctions';
import useForm from '../../../../common/utils/UseForm';

const TITLE = 'Member Transfers';
const INITIAL_FORM_STATE = {
  clubFromCode: '',
  clubFromName: '',
  clubToCode: '',
  clubToName: '',
  effectiveDate: Constants.BLANK_DATE_STRING,
  personOrgRoleUnattachedId: Constants.DEFAULT_ID,
  reAttachDate: Constants.BLANK_DATE_STRING
};

const INITIAL_MODAL_STATE = {
  modalTitle: 'Delete Unattached',
  displayPopUp: false,
}

const useMemberAdminManageTransfer = () => {
  const { basePersonState } = useBasePersonData();
  const { formState, errorState, onFormValueChanged, setFormData } = useForm(INITIAL_FORM_STATE);
  const { memberClubTransferState, getPersonClubTransfers, putPersonClubTransfer, deletePersonClubTransfer, deleteMemberClubTransferState } = usePersonClubTransferData();
  const [state, setState] = useState({ errorMessage: '', isTextView: false });
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const validate = () => {
    let validationReattachDate = new Date(formState.reAttachDate);
    let valiationExpirationDate = new Date(formState.effectiveDate);
    let effectiveDate = formatDate(formState.effectiveDate);

    if (!isValidDate(formState.reAttachDate)) {

      return 'Reattach Date must be a valid date';

    } else if (validationReattachDate <= valiationExpirationDate) {

      return `Reattach Date must after ${effectiveDate}`;
    }

    return '';
  };

  const onUpdateClubTransferReattachDate = () => {
    const errorMessage = validate();
    if (errorMessage) {
      setState({ ...state, errorMessage });
    } else {
      const personObj = {
        clubFromCode: formState?.clubFromCode,
        clubFromName: formState?.clubFromName,
        clubToCode: formState?.clubToCode,
        clubToName: formState?.clubToName,
        effectiveDate: formState?.effectiveDate,
        personOrgRoleUnattachedId: formState?.personOrgRoleUnattachedId,
        reAttachDate: formState?.reAttachDate
      }

      putPersonClubTransfer(basePersonState?.objData?.personId, personObj)
      setState({ ...state, errorMessage: '' });
    }
  };

  const onOpenModal = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      displayPopUp: true,
      formState: formState
    });
  };


  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      displayPopUp: false
    });
  };


  const removePersonClubTransfer = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const personOrgRoleUnattachedId = formState?.personOrgRoleUnattachedId;
    deletePersonClubTransfer(personOrgRoleUnattachedId);
    onModalCanceled();
  }

  useEffect(() => {
    if (deleteMemberClubTransferState.isSaved === true
      && Object?.keys(deleteMemberClubTransferState?.objData)?.length > 0 && state.isTextView === false) {

      setState({ ...state, isTextView: true })
    }
  }, [deleteMemberClubTransferState.isSaved, deleteMemberClubTransferState?.objData, state, state.isTextView]);

  useEffect(() => {
    if (memberClubTransferState.isObjLoaded === true && Object?.keys(memberClubTransferState?.objData)?.length > 0) {
      const memberInfo = memberClubTransferState?.objData;
      setFormData({
        ...formState,
        clubFromCode: memberInfo?.clubFromCode,
        clubFromName: memberInfo?.clubFromName,
        clubToCode: memberInfo?.clubToCode,
        clubToName: memberInfo?.clubToName,
        effectiveDate: formatDate(memberInfo?.effectiveDate),
        personOrgRoleUnattachedId: memberInfo?.personOrgRoleUnattachedId,
        reAttachDate: formatDate(memberInfo?.reAttachDate)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberClubTransferState?.isObjLoaded, memberClubTransferState?.objData]);


  useEffect(() => {
    if (basePersonState.isObjLoaded === true) {
      getPersonClubTransfers(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, memberClubTransferState?.isSaved]);

  return {
    title: TITLE,
    Constants,
    basePersonState,
    formState,
    errorState,
    onFormValueChanged,
    memberClubTransferState,
    onUpdateClubTransferReattachDate,
    removePersonClubTransfer,
    errorMessage: state?.errorMessage,
    isTextView: state?.isTextView,
    modalState,
    onModalCanceled,
    onOpenModal
  };
};

export default useMemberAdminManageTransfer;