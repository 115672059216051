import React, { Fragment } from 'react';

import NationalTeamSupportStaffLargeGrid from './NationalTeamSupportStaffLargeGrid';
import NationalTeamSupportStaffSmallGrid from './NationalTeamSupportStaffSmallGrid';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

const NationalTeamSupportStaffGrid = ({ gridState, onAddEdit, onDelete, onAddStaffType }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <NationalTeamSupportStaffLargeGrid
        gridState={gridState}
        expandedId={expandedId}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
        onAddStaffType={onAddStaffType}
        onExpandClicked={onClick}
      />
      <NationalTeamSupportStaffSmallGrid
        gridState={gridState}
        expandedId={expandedId}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
        onAddStaffType={onAddStaffType}
        onExpandClicked={onClick}
      />
    </Fragment>
  )
};

export default NationalTeamSupportStaffGrid;