import React, { Fragment } from 'react';

import useOtsOfficialMeetHistory from './UseOtsOfficialMeetHistory';
import OtsOfficialsMeetHistoryWriteGrid from './grids/OtsOfficialMeetHistoryWriteGrid';

import Headings from '../../../../../common/components/headings/Headings';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsOfficialMeetHistoryWrite = () => {
  const {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    otsOfficialMeetHistoryState,
    onClearFilterClicked
  } = useOtsOfficialMeetHistory();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>Meet History</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className='row usas-extra-bottom-margin'>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <DatePicker label="Meet History Start Date Range*"
              name="startDate"
              value={formState.startDate}
              error={errorState.startDate}
              message={errorState.startDate}
              onChange={(value) => { onFormValueChanged('startDate', value); }}
              countOfYears={150}
              startYearOffset={-149} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <DatePicker label="Meet History End Date Range*"
              name="endDate"
              value={formState.endDate}
              error={errorState.endDate}
              message={errorState.endDate}
              onChange={(value) => { onFormValueChanged('endDate', value); }}
              countOfYears={150}
              startYearOffset={-149} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <button type="submit" className={global.PlainButton}>Filter</button>&nbsp;
            <button type="button" className={global.PlainButton} onClick={onClearFilterClicked}>Clear Filter</button>
          </div>
        </div>
      </form>
      <div className='row'>
        <div className='col-xs-12'>
          <OtsOfficialsMeetHistoryWriteGrid state={otsOfficialMeetHistoryState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={otsOfficialMeetHistoryState.isArrayLoading} />
    </Fragment >
  );
};

export default OtsOfficialMeetHistoryWrite;