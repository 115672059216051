import { useContext } from 'react';

import { MeetOfficialRoleTypeStateContext } from './MeetOfficialRoleTypeContextProvider';

import MeetOfficialRoleTypeData from './MeetOfficialRoleTypeData';

const useMeetOfficialRoleTypeData = () => {
  const [meetOfficialRoleTypeState, setMeetOfficialRoleTypeState] = useContext(MeetOfficialRoleTypeStateContext);

  const getMeetOfficialRoleTypes = (orgLevelId, officialPositionTypeId) => MeetOfficialRoleTypeData.getMeetOfficialRoleTypeData(orgLevelId, officialPositionTypeId, meetOfficialRoleTypeState, setMeetOfficialRoleTypeState);

  const clearMeetOfficialRoleTypeObjData = () => {
    setMeetOfficialRoleTypeState({
      ...meetOfficialRoleTypeState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearMeetOfficialRoleTypeArrayData = () => {
    setMeetOfficialRoleTypeState({
      ...meetOfficialRoleTypeState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: '',
      options: [],
      orgLevelId: '',
      hasEvaluationForm: ''
    });
  };

  const confirmMeetOfficialRoleTypeSave = () => {
    setMeetOfficialRoleTypeState({
      ...meetOfficialRoleTypeState,
      isSaved: false
    });
  };

  return {
    meetOfficialRoleTypeState,
    getMeetOfficialRoleTypes,
    clearMeetOfficialRoleTypeObjData,
    clearMeetOfficialRoleTypeArrayData,
    confirmMeetOfficialRoleTypeSave
  };
};

export default useMeetOfficialRoleTypeData;