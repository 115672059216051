import React, { createContext, useState } from 'react';

import PersonProfileData from './PersonProfileData';

export const PersonProfileStateContext = createContext();

const PersonProfileContextProvider = ({ children }) => {
  const stateHook = useState(PersonProfileData.INITIAL_STATE);

  return (
    <PersonProfileStateContext.Provider value={stateHook}>
      {children}
    </PersonProfileStateContext.Provider>
  );
};

export default PersonProfileContextProvider;