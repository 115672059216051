import React, { createContext, useState } from 'react';

import OtsMeetOfficialData from './OtsMeetOfficialData';

export const OtsMeetOfficialStateContext = createContext();

const OtsMeetOfficialContextProvider = ({ children }) => {
  const stateHook = useState(OtsMeetOfficialData.INITIAL_STATE);

  return (
    <OtsMeetOfficialStateContext.Provider value={stateHook}>
      {children}
    </OtsMeetOfficialStateContext.Provider>
  );
};

export default OtsMeetOfficialContextProvider;