import { useEffect } from 'react';

import validate from './NationalTeamAdvancedInfoValidation';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useForm from '../../../../common/utils/UseForm';
import ToBoolIfBool from '../../../../common/utils/ToBoolIfBool';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

const TITLE = 'National Team Advanced Info';

const useNationalTeamAdvancedInfo = () => {
  const { basePersonState } = useBasePersonData();
  const { personNationalTeamAdvancedState, getPersonNationalTeam, getPersonNationalTeamAdvanced, putPersonNationalTeamAdvanced } = usePersonNationalTeamData();
  const { handleSubmit, updateFormState, formState, setFormData, resetForm, errorState }
    = useForm(getInitialFormState(), submitFormCallback, validate);

  function submitFormCallback() {
    if (personNationalTeamAdvancedState.isSaving === false) {
      let advancedInfoObj = {
        personId: personNationalTeamAdvancedState?.objData?.personId,
        comments: formState?.comments,
        passportEffectiveDate: formState?.passportEffectiveDate,
        passportExpirationDate: formState?.passportExpirationDate,
        passportVarified: formState?.passportVarified,
        passportNumber: formState?.passportNumber,
        professionalSwimmer: formState.professionalSwimmer === true ? true : false,
      };
      putPersonNationalTeamAdvanced(personNationalTeamAdvancedState.objData.personId, advancedInfoObj);
    }
  };

  useEffect(() => {
    if (personNationalTeamAdvancedState.isSaved === true && Object?.keys(personNationalTeamAdvancedState.objData)?.length > 0) {
      getPersonNationalTeam(basePersonState.objData.personId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personNationalTeamAdvancedState.isSaved]);

  useEffect(() => {
    if (personNationalTeamAdvancedState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personNationalTeamAdvancedState.objData)) {
      getPersonNationalTeamAdvanced(basePersonState.objData.personId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  useEffect(() => {
    if (personNationalTeamAdvancedState.isObjLoaded === true && Object?.keys(personNationalTeamAdvancedState?.objData)?.length > 0) {
      const advancedInfo = personNationalTeamAdvancedState?.objData;


      setFormData({
        ...formState,
        comments: advancedInfo?.comments,
        passportEffectiveDate: formatDate(advancedInfo?.passportEffectiveDate) || '',
        passportExpirationDate: formatDate(advancedInfo?.passportExpirationDate) || '',
        passportNumber: advancedInfo?.passportNumber || '',
        passportVarified: ToBoolIfBool(advancedInfo?.passportVarified) !== null ? ToBoolIfBool(advancedInfo?.passportVarified) : false,
        professionalSwimmer: ToBoolIfBool(advancedInfo?.professionalSwimmer) !== null ? ToBoolIfBool(advancedInfo?.professionalSwimmer) : false,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personNationalTeamAdvancedState]);

  function getInitialFormState() {
    return {
      comments: '',
      passportEffectiveDate: Constants.BLANK_DATE_STRING,
      passportExpirationDate: Constants.BLANK_DATE_STRING,
      passportVarified: false,
      passportNumber: '',
      professionalSwimmer: false,
    };
  };

  return {
    title: TITLE,
    Constants,
    personNationalTeamAdvancedState,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    resetForm,
  };
}

export default useNationalTeamAdvancedInfo;