import React, { Fragment } from 'react';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const DeleteOfficialLscCertificationModal = ({ modalState }) => {
  return (
    <Fragment>
      <p className='col-xs-12 col-sm-6'><strong>Position Name:</strong>&nbsp;{modalState?.positionTypeName} - {modalState?.positionAbbreviation}</p>
      <p className='col-xs-12 col-sm-6'><strong>Certification Level: </strong> &nbsp;{modalState?.levelName}</p>
      <p className='col-xs-12 col-sm-6'><strong>Effective Date: </strong> &nbsp;{formatDate(modalState?.effectiveDate)}</p>
      <p className='col-xs-12 col-sm-6'><strong>Expiration Date: </strong> &nbsp;{formatDate(modalState?.expirationDate)}</p>
      <p className='col-xs-12 col-sm-6'><strong>Sessions Worked: </strong> &nbsp;{modalState?.sessionsWorked}</p>
      <p className='col-xs-12'>Are you sure you want to delete this LSC Certification?</p>
    </Fragment>
  );
};

export default DeleteOfficialLscCertificationModal;