import { Fragment } from 'react';

import MemberCoursesLargeReadGrid from './MemberCoursesLargeReadGrid';
import MemberCoursesSmallReadGrid from './MemberCoursesSmallReadGrid';
import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MemberCoursesReadGrid = ({ gridData, isLoading, formState }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MemberCoursesLargeReadGrid
        gridData={gridData}
        isLoading={isLoading}
        formState={formState}
        expandedId={expandedId}
        onClick={onClick} />
      <MemberCoursesSmallReadGrid
        gridData={gridData}
        isLoading={isLoading}
        formState={formState}
        expandedId={expandedId}
        onClick={onClick} />
    </Fragment>
  );
};

export default MemberCoursesReadGrid;
