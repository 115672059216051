import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../UseMemberAdministration';

import useMemberAdministrationHqBoardData from '../../../../common/state/memberAdministration/hQBoard/UseMemberAdministrationHQBoardData';

import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

const useHQBoardMembers = () => {
  const navigate = useNavigate();
  const { memberAdministrationHQBoardState, getHQBoardDirectors, deleteHQBoardDirector, confirmSave
  } = useMemberAdministrationHqBoardData();
  const [viewState, setViewState] = useState(getInitialViewState);
  const [gridState, setGridState] = useState([]);

  const onAddBoardMember = () => {
    navigate(navLinks.HQ_BOARD_ADD);
  };

  const onEditBoardMember = (personId, personOrgRoleDurationId) => {
    navigate(navLinks.HQ_BOARD_EDIT, { state: { personId, personOrgRoleDurationId } });
  };

  const onDeleteBoardMember = (e, personOrgRoleDurationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteHQBoardDirector(personOrgRoleDurationId);
  };

  const onFilterCurrentMembers = () => {
    if (memberAdministrationHQBoardState.isArrayLoading === false) {
      setViewState({
        ...viewState,
        toggleCurrentStaffRoles: !viewState.toggleCurrentStaffRoles
      });
      setGridState(filterBoardMembersForGrid(memberAdministrationHQBoardState.arrayData, !viewState.toggleCurrentStaffRoles));
    }
  };

  useEffect(() => {
    if (memberAdministrationHQBoardState.isSaved === true) {
      confirmSave();
    } else {
      getHQBoardDirectors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationHQBoardState.isSaved]);

  useEffect(() => {
    if (memberAdministrationHQBoardState.isArrayLoading === false && memberAdministrationHQBoardState.isArrayLoaded === true) {
      setGridState(filterBoardMembersForGrid(memberAdministrationHQBoardState.arrayData, viewState.toggleCurrentStaffRoles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationHQBoardState]);

  function filterBoardMembersForGrid(arrayData, toggleCurrentStaffRoles = false) {
    const today = new Date();
    const gridData = [];

    for (const staff of arrayData) {
      const roleDurations = [];

      if (Array.isArray(staff.orgRoles) && staff.orgRoles.length > 0) {
        if (toggleCurrentStaffRoles === true) {
          for (const duration of staff.orgRoles) {
            if (duration.isCurrent === true) {
              roleDurations.push({
                personOrgRoleDurationId: duration.personOrgRoleDurationId || Constants.DEFAULT_ID,
                personOrgRoleId: duration.personOrgRoleId || Constants.DEFAULT_ID,
                effectiveDate: duration.effectiveDate ? formatDate(duration.effectiveDate) : '',
                expirationDate: duration.expirationDate ? formatDate(duration.expirationDate) : '',
                isCurrent: duration.isCurrent === true ? true : false,
                canDelete: new Date(duration.expirationDate) > today ? true : false,
                orgRoleId: duration.orgRoleId || '',
                orgRoleName: duration.orgRoleName || ''
              });
            }
          }
        } else {
          for (const duration of staff.orgRoles) {
            roleDurations.push({
              personOrgRoleDurationId: duration.personOrgRoleDurationId || Constants.DEFAULT_ID,
              personOrgRoleId: duration.personOrgRoleId || Constants.DEFAULT_ID,
              effectiveDate: duration.effectiveDate ? formatDate(duration.effectiveDate) : '',
              expirationDate: duration.expirationDate ? formatDate(duration.expirationDate) : '',
              isCurrent: duration.isCurrent === true ? true : false,
              canDelete: new Date(duration.expirationDate) > today ? true : false,
              orgRoleId: duration.orgRoleId || '',
              orgRoleName: duration.orgRoleName || ''
            });
          }
        }
      }

      if (roleDurations.length > 0) {
        roleDurations.sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate));

        gridData.push({
          personId: staff.personId || '',
          name: `${staff.firstName || ''} ${staff.lastName || ''}`,
          email: staff.email || '',
          roleDurations
        });
      }
    }

    return gridData;
  };

  function getInitialViewState() {
    return {
      toggleCurrentStaffRoles: true
    };
  };

  return {
    isSaving: memberAdministrationHQBoardState.isSaving,
    isGridLoading: memberAdministrationHQBoardState.isArrayLoading,
    viewState,
    gridState,
    onFilterCurrentMembers,
    onEditBoardMember,
    onAddBoardMember,
    onDeleteBoardMember
  };
};

export default useHQBoardMembers;