import React, { Fragment } from 'react';

import MeetOfficialsGridLarge from './MeetOfficialsGridLarge';
import MeetOfficialsGridSmall from './MeetOfficialsGridSmall';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MeetOfficialsGrid = ({ gridData, isLoading, onEditClicked, onDeleteClicked, passedInMeetEndDate }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MeetOfficialsGridLarge gridData={gridData} isLoading={isLoading} expandedId={expandedId} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} onExpandClicked={onClick} passedInMeetEndDate={passedInMeetEndDate} />
      <MeetOfficialsGridSmall gridData={gridData} isLoading={isLoading} expandedId={expandedId} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} onExpandClicked={onClick} passedInMeetEndDate={passedInMeetEndDate} />
    </Fragment>
  );
};

export default MeetOfficialsGrid;