import React, { Fragment } from 'react';

import UseOtsOfficialNationalCertificationHistory from './UseOtsOfficialNationalCertificationHistory';

import OtsOfficialsNationalCertificationHistoryReadGrid from './grids/OtsOfficialNationalCertificationHistoryReadGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import Constants from '../../../../../common/utils/Constants';

const OtsOfficialNationalCertificationHistoryRead = () => {
  const {
    basePersonState,
    otsOfficialNationalCertificationState,
    formState,
    errorState,
    onFormValueChanged
  } = UseOtsOfficialNationalCertificationHistory();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>National Certification History</Headings.H3>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <span className='pull-right'>
            <YesNoSwitch
              label="Show All National Certification History?"
              name="showAllNationalCertificationHistory"
              checked={formState.showAllNationalCertificationHistory === true}
              error={errorState.showAllNationalCertificationHistory}
              message={errorState.showAllNationalCertificationHistory}
              onChange={(value) => { onFormValueChanged('showAllNationalCertificationHistory', value); }}
              isHorizontal={true} />
          </span>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <OtsOfficialsNationalCertificationHistoryReadGrid
            formState={formState}
            state={otsOfficialNationalCertificationState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState?.isObjLoading || otsOfficialNationalCertificationState.isArrayLoading} />
    </Fragment >
  );
};

export default OtsOfficialNationalCertificationHistoryRead;