import React, { createContext, useState } from 'react';

import HispanicOrLatinoTypeData from './HispanicOrLatinoTypeData';

export const HispanicOrLatinoTypeStateContext = createContext();

const HispanicOrLatinoTypeContextProvider = ({ children }) => {
  const stateHook = useState(HispanicOrLatinoTypeData.INITIAL_STATE);

  return (
    <HispanicOrLatinoTypeStateContext.Provider value={stateHook}>
      {children}
    </HispanicOrLatinoTypeStateContext.Provider>
  );
};

export default HispanicOrLatinoTypeContextProvider;