import { useContext } from 'react';

import { SearchMemberStateContext } from './SearchMemberContextProvider';

const useSearchMemberData = () => {
  const [searchMemberState, setSearchMemberState] = useContext(SearchMemberStateContext);

  return {
    searchMemberState,
    setSearchMemberState
  };
};

export default useSearchMemberData;