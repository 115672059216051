import React, { createContext, useState } from 'react';

import MeetOfficialRoleTypeData from './MeetOfficialRoleTypeData';

export const MeetOfficialRoleTypeStateContext = createContext();

const MeetOfficialRoleTypeContextProvider = ({ children }) => {
  const stateHook = useState(MeetOfficialRoleTypeData.INITIAL_STATE);

  return (
    <MeetOfficialRoleTypeStateContext.Provider value={stateHook}>
      {children}
    </MeetOfficialRoleTypeStateContext.Provider>
  );
};

export default MeetOfficialRoleTypeContextProvider;