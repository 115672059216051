import { Fragment } from 'react';

import HqOtsOfficialLscBulkRecertifyLargeGrid from './HqOtsOfficialLscBulkRecertifyLargeGrid';
import HqOtsOfficialLscBulkRecertifySmallGrid from './HqOtsOfficialLscBulkRecertifySmallGrid';

const HqOtsOfficialsLscBulkRecertifyGrid = ({ state, selectedOfficials, onAddOrRemoveLscCertficationForBulkRecertify, onOfficialHistoryReportLoadClick }) => {
  return (
    <Fragment>
      {state.isArrayLoading === false
        ? <div className="row">
          <div className="col-xs-12 col-sm-10">
            <p>{state.arrayData.length === 1 ? `${state.arrayData.length} Search Result` : `${state.arrayData.length} Search Results`}</p>
          </div>
        </div>
        : <Fragment>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          </div>
        </Fragment >}
      <HqOtsOfficialLscBulkRecertifyLargeGrid
        state={state}
        selectedOfficials={selectedOfficials}
        onAddOrRemoveLscCertficationForBulkRecertify={onAddOrRemoveLscCertficationForBulkRecertify}
        onOfficialHistoryReportLoadClick={onOfficialHistoryReportLoadClick}
      />
      <HqOtsOfficialLscBulkRecertifySmallGrid
        state={state}
        selectedOfficials={selectedOfficials}
        onAddOrRemoveLscCertficationForBulkRecertify={onAddOrRemoveLscCertficationForBulkRecertify}
        onOfficialHistoryReportLoadClick={onOfficialHistoryReportLoadClick} />
    </Fragment>
  )
};

export default HqOtsOfficialsLscBulkRecertifyGrid;
