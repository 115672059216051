import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import style from './Spinner.module.css';
import {VisuallyHidden} from '@reach/visually-hidden';

const Spinner = () => {

  return (
    <div className={style.Spinner}>
      <VisuallyHidden>Loading</VisuallyHidden>
      <FontAwesomeIcon icon={faSpinner} className="fa-spin" title="Loading" />
    </div>
  );
};

export default Spinner;