import React, { createContext, useState } from 'react';

import SupportStaffTypeData from './SupportStaffTypeData';

export const SupportStaffTypeStateContext = createContext();

const SupportStaffTypeContextProvider = ({ children }) => {
  const stateHook = useState(SupportStaffTypeData.INITIAL_STATE);

  return (
    <SupportStaffTypeStateContext.Provider value={stateHook}>
      {children}
    </SupportStaffTypeStateContext.Provider>
  );
};

export default SupportStaffTypeContextProvider;