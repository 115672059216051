import { useState } from 'react';

import NationalTeamFilesData from './NationalTeamFilesData';

const useNationalTeamAttachmenFilesData = () => {
  const [nationalTeamAttachmentFilesState, setNationalTeamAttachmentFilesState] = useState(NationalTeamFilesData.INITIAL_STATE);

  const postNationalTeamAttachmentFiles = (file) => {
    NationalTeamFilesData.postNationalTeamAttachmentFilesData(file, nationalTeamAttachmentFilesState, setNationalTeamAttachmentFilesState);
  };

  const putNationalTeamAttachmentFiles = (file, oldFileUrl) => {
    NationalTeamFilesData.putNationalTeamAttachmenFilesData(file, oldFileUrl, nationalTeamAttachmentFilesState, setNationalTeamAttachmentFilesState);
  };

  const deleteNationalTeamAttachmentFiles = (personNationalAttachmentUrl) => {
    NationalTeamFilesData.deleteNationalTeamAttachmentFilesData(personNationalAttachmentUrl, nationalTeamAttachmentFilesState, setNationalTeamAttachmentFilesState);
  }

  const resetNationalTeamAttachmentFilesState = () => {
    setNationalTeamAttachmentFilesState(NationalTeamFilesData.INITIAL_STATE);
  }

  const getFileNameFromSavedUploadUrl = () => {
    if (nationalTeamAttachmentFilesState.isObjLoaded === true) {
      const fileNameWithDateTime = nationalTeamAttachmentFilesState.objData.uploadUrl.replace(NationalTeamFilesData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return nationalTeamAttachmentFilesState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(NationalTeamFilesData.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
    return decodeURI(fileName) || '';
  };

  return {
    nationalTeamAttachmentFilesState,
    postNationalTeamAttachmentFiles,
    putNationalTeamAttachmentFiles,
    deleteNationalTeamAttachmentFiles,
    resetNationalTeamAttachmentFilesState,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl,
    BASE_UPLOAD_URL: NationalTeamFilesData.BASE_UPLOAD_URL,
    GET_MEMBER_FILES_NATIONAL_TEAM_ATTACHMENT_PATH: NationalTeamFilesData.GET_MEMBER_FILES_NATIONAL_TEAM_ATTACHMENT_PATH
  };
};

export default useNationalTeamAttachmenFilesData;