import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';
import { isValidMemberId } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  // Member Id A
  if (formState.memberAId.trim() === '') {
    errors.memberAId = 'Member Id is required';
  } else if (!isValidMemberId(formState.memberAId.trim())) {
    errors.memberAId = 'Member Id must be 14 alphanumeric characters';
  }

  // Member Id B
  if (formState.memberBId.trim() === '') {
    errors.memberBId = 'Member Id is required';
  } else if (!isValidMemberId(formState.memberBId.trim())) {
    errors.memberBId = 'Member Id must be 14 alphanumeric characters';
  }

  if ((errors.memberAId ?? false) && (errors.memberBId ?? false)) {
    if (formState.memberAId.trim() === formState.memberBId.trim()) {
      errors.memberBId = 'Member Ids cannot be the same';
    }
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  //Check that the member Id is valid
  const memberAIdForUrl = formState.memberAId.trim() ? encodeURIComponent(formState.memberAId.trim()) : 'NaN';
  const memberBIdForUrl = formState.memberBId.trim() ? encodeURIComponent(formState.memberBId.trim()) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${memberAIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
    })
    .catch(() => {
      errors.memberAId = 'Member Id does not exist';
    });

  await PersonHttpHelper(`/Person/flat/memberId/${memberBIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
    })
    .catch(() => {
      errors.memberBId = 'Member Id does not exist';
    });

  return errors;
};

const MemberMergeSearchByMemberIdsValidation = async (formState) => {
  let errors = localValidate(formState);

  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default MemberMergeSearchByMemberIdsValidation;