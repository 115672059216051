import React from 'react';
import Report from '../../../common/components/embeddedReports/Report';

const MemberSummaryDashboard = () => {

  return (
    <Report embedSlotName="MEMBERSHIP_SUMMARY_DASHBOARD" />
  );
};

export default MemberSummaryDashboard;