import { useState, useEffect } from 'react';
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

import usePersonMembershipUpgradeData from '../../../state/member/memberUpgradeMembership/UsePersonMembershipUpgradeData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';

import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

const INITIAL_STATE = {
  tryGet: false,
  displayWaringModal: false,
  formattedSelectedUpgradeArray: []
};

const useMemberUpgrade = () => {
  const navigate = useNavigate();
  const { basePersonState } = useBasePersonData();
  const { personMembershipUpgradeState, postPersonMembershipUpgradeArray, getPersonUpgrade, } = usePersonMembershipUpgradeData();
  const { getGlobalRoute } = useGlobalRoutesData();
  const [state, setState] = useState(INITIAL_STATE);
  const [errors, setErrorState] = useState({});
  const [gridState, setGridState] = useState({ gridData: [] });
  const [selectionChangesState, setSelectionChangesState] = useState({ arrayData: [] });

  const onCheckboxChange = (e, memberRegistrationId, upgradeToOfferingInstanceId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.memberRegistrationId === memberRegistrationId && x.upgradeToOfferingInstanceId === upgradeToOfferingInstanceId);

    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedAthleteIndexGridData = gridState.gridData?.findIndex(x => x.memberRegistrationId === memberRegistrationId && x.upgradeToOfferingInstanceId === upgradeToOfferingInstanceId);

    if (selectedAthleteIndexGridData > -1) {
      updatedGridData[selectedAthleteIndexGridData].isSelected = !updatedGridData[selectedAthleteIndexGridData].isSelected;
      setGridState({
        ...gridState,
        gridData: updatedGridData
      });
    }

    if (selectedAthleteIndexArrayData > -1) {
      updatedArrayData[selectedAthleteIndexArrayData].isSelected = !updatedArrayData[selectedAthleteIndexArrayData].isSelected;
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
    else if (selectedAthleteIndexArrayData === -1) {
      const newAthlete = JSON.parse(JSON.stringify(updatedGridData[selectedAthleteIndexGridData]));
      updatedArrayData.push(newAthlete);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  const onSelectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      updatedGridData[i].isSelected = true;
      const selectedMembershipIndexArrayData = updatedArrayData.findIndex(x => x.memberRegistrationId === updatedGridData[i].memberRegistrationId && x.upgradeToOfferingInstanceId === updatedGridData[i].upgradeToOfferingInstanceId);
      //Edit
      if (selectedMembershipIndexArrayData > -1) {
        updatedArrayData[selectedMembershipIndexArrayData].isSelected = true;
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onUnselectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      updatedGridData[i].isSelected = false;
      const selectedMembershipIndexArrayData = updatedArrayData.findIndex(x => x.memberRegistrationId === updatedGridData[i].memberRegistrationId && x.upgradeToOfferingInstanceId === updatedGridData[i].upgradeToOfferingInstanceId);
      //Edit
      if (selectedMembershipIndexArrayData > -1) {
        updatedArrayData[selectedMembershipIndexArrayData].isSelected = false;
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onUpgradeMembership = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (basePersonState?.objData?.personId !== undefined) {
      if (selectionChangesState.arrayData?.filter(x => x.isSelected === true)?.length > 0) {
        const personId = basePersonState?.objData?.personId;
        const selectedUpgradeArray = selectionChangesState.arrayData?.filter(x => x.isSelected === true);
        const formattedSelectedUpgradeArray = selectedUpgradeArray.map((upgrade) => {
          return {
            memberRegistrationId: upgrade.memberRegistrationId,
            offeringInstanceId: upgrade.upgradeToOfferingInstanceId
          }
        });

        if (selectedUpgradeArray.findIndex(x => x.isSeasonalClub === true && x.isClubExpired === true && x.isAthleteRole === true && x.isSeasonalOffering === true) >= 0) {
          setState({
            ...state,
            displayWaringModal: true,
            formattedSelectedUpgradeArray
          });
        } else {
          postPersonMembershipUpgradeArray(personId, formattedSelectedUpgradeArray);
        }
      }
      else {
        setErrorState({ general: 'Please select at least one membership to upgrade' });
      }
    }
  };

  const onWarningModalConfirmClicked = (e) => {
    e?.preventDefault();

    const personId = basePersonState?.objData?.personId;

    postPersonMembershipUpgradeArray(personId, state.formattedSelectedUpgradeArray);

    setState({
      ...state,
      displayWaringModal: false,
      formattedSelectedUpgradeArray: []
    });
  };

  const onWarningModalCancelClicked = (e) => {
    e?.preventDefault();

    setState({
      ...state,
      displayWaringModal: false,
      formattedSelectedUpgradeArray: []
    });
  };

  useEffect(() => {
    if (basePersonState?.objData?.personId !== undefined || state.tryGet === true) {
      const personId = basePersonState.objData.personId;

      getPersonUpgrade(personId);
      setState({ ...state, tryGet: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, state.tryGet]);

  useEffect(() => {
    if (personMembershipUpgradeState.isArrayLoaded === true && personMembershipUpgradeState.isArrayLoading === false) {
      const arrayDataCopy = JSON.parse(JSON.stringify(personMembershipUpgradeState.arrayData));
      //keep unsaved selections / unselections from previous searches
      if (selectionChangesState.arrayData.length > 0) {
        for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
          const matchingIndex = arrayDataCopy.findIndex(x => x.memberRegistrationId === selectionChangesState.arrayData[i].memberRegistrationId && x.upgradeToOfferingInstanceId === selectionChangesState.arrayData[i].upgradeToOfferingInstanceId);
          if (matchingIndex > -1) {
            arrayDataCopy[matchingIndex].isSelected = selectionChangesState.arrayData[i].isSelected;
          }
        }
      }

      setGridState({ ...gridState, gridData: arrayDataCopy });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personMembershipUpgradeState.isArrayLoaded, personMembershipUpgradeState.isArrayLoading])

  useEffect(() => {
    if (personMembershipUpgradeState.isSaved === true) {
      const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');

      if (paymentsRoute && paymentsRoute.route) {
        navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
      } else {
        setState({ ...state, tryGet: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personMembershipUpgradeState.isSaved]);

  return {
    personMembershipUpgradeState,
    gridState,
    state,
    errors,
    onCheckboxChange,
    onSelectAll,
    onUnselectAll,
    onUpgradeMembership,
    onWarningModalConfirmClicked,
    onWarningModalCancelClicked
  };
};

export default useMemberUpgrade;