import React, { Fragment } from 'react';

import useMemberRequirementsDetail from './UseMemberRequirementsDetail';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

import style from '../../Member.module.css';
import global from '../../../../../common/components/GlobalStyle.module.css';

const MemberRequirementsDetail = () => {
  const {
    handleSubmit,
    formState,
    errorState,
    onFormValueChanged,
    onCancelClicked,
    enrollmentState,
    adminEnrollmentState
  } = useMemberRequirementsDetail();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Headings.H3>Edit Requirement Details</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 usas-extra-bottom-margin">
            <ReadOnly
              label='Name of Course Requirement'
              name='requirementName'
              value={formState?.requirementName}
            />
          </div>
          <div className="col-xs-12 col-sm-4">
            <DatePicker
              label="Completion Date*"
              name="courseCompletionDate"
              value={formState?.courseCompletionDate}
              error={errorState.courseCompletionDate}
              message={errorState.courseCompletionDate}
              onChange={(value) => { onFormValueChanged('courseCompletionDate', value); }}
              countOfYears={12}
              startYearOffset={-12} />
          </div>
          <div className='col-xs-12 col-sm-4 text-center'>
            <label className={style.CenteredUsasLabel}>Expiration Date*</label>
            <YesNoSwitch
              name="noExpirationDate"
              checked={formState.noExpirationDate}
              error={errorState.noExpirationDate}
              message={errorState.noExpirationDate}
              onChange={(value) => { onFormValueChanged('noExpirationDate', value); }} />
          </div>
          <span className={formState?.noExpirationDate === null || formState?.noExpirationDate === false ? global.HideComponent : global.DisplayComponent}>
            <div className="col-xs-12 col-sm-4">
              <DatePicker
                label="Expiration Date*"
                name="courseExpirationDate"
                value={formState?.courseExpirationDate}
                error={errorState.courseExpirationDate}
                message={errorState.courseExpirationDate}
                onChange={(value) => { onFormValueChanged('courseExpirationDate', value); }}
                countOfYears={3}
                startYearOffset={0} />
            </div></span>
          <div className='col-xs-12 col-sm-4 text-center'>
            <label className={style.CenteredUsasLabel}>Has Exemption*</label>
            <YesNoSwitch
              name="hasExemption"
              checked={formState.hasExemption}
              error={errorState.hasExemption}
              message={errorState.hasExemption}
              onChange={(value) => { onFormValueChanged('hasExemption', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={enrollmentState?.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={adminEnrollmentState?.isSaving === true} />
    </Fragment >
  )
}

export default MemberRequirementsDetail;