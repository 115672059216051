import React, { Fragment } from 'react';

import OtsOfficialLscCertificationLargeReadGrid from './OtsOfficialLscCertificationLargeReadGrid';
import OtsOfficialLscCertificationSmallReadGrid from './OtsOfficialLscCertificationSmallReadGrid';

const OtsOfficialsLscCertificationReadGrid = ({ state, formState }) => {

  return (
    <Fragment>
      <OtsOfficialLscCertificationLargeReadGrid
        state={state}
        formState={formState} />
      <OtsOfficialLscCertificationSmallReadGrid
        state={state}
        formState={formState} />
    </Fragment>
  )
};

export default OtsOfficialsLscCertificationReadGrid;
