import { Fragment } from 'react';

import useHqLscOfferingInstanceOrgUnit from './UseHqLscOfferingInstanceOrgUnit';

import HqOfferingManagementContextLoadingModal from '../HqOfferingManagementContextLoadingModal';

import OfferingInstanceOrgUnitGrid from '../../components/grids/offeringInstanceOrgUnit/OfferingInstanceOrgUnitGrid';

import Headings from '../../../../../common/components/headings/Headings';
import LscDropdown from '../../../../../common/components/dropdowns/lscDropdown/LscDropdown';
import ReportPeriodDropdown from '../../../../../common/components/dropdowns/reportPeriodDropdown/ReportPeriodDropdown';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

const HqLscOfferingInstanceOrgUnit = () => {
  const {
    organizationId,
    areFiltersValid,
    canAddAdditionalInstance,
    state,
    offeringManagementInstanceOrgUnitState,

    onLscChanged,
    onReportPeriodChanged,
    onAddOfferingInstanceOrgUnitClicked,
    onEditOfferingInstanceOrgUnitClicked,
    onAddAdditionalOfferingInstanceOrgUnitClicked,
    onEditAdditionalOfferingInstanceOrgUnitClicked
  } = useHqLscOfferingInstanceOrgUnit();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>LSC Offerings</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-4 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={(e) => onAddOfferingInstanceOrgUnitClicked(e)}>
            Add Offering
          </ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-bottom-margin">
          {state.reportPeriodId > 0 &&
            <LscDropdown
              label="LSC"
              name="orgUnitId"
              value={state.orgUnitId}
              error={undefined}
              message={undefined}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value && onLscChanged(newValue, newValueLabel);
              }} />
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-bottom-margin">
          {state.reportPeriodId > 0 &&
            <ReportPeriodDropdown
              label="Report Period"
              name="reportPeriodId"
              value={state.reportPeriodId}
              error={undefined}
              message={undefined}
              organizationId={organizationId}
              defaultOption={false}
              reverseDataOrder={true}
              currentAndFutureOptionsOnly={false}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value && onReportPeriodChanged(newValue, newValueLabel);
              }} />
          }
        </div>
        {state.filterErrorMessage &&
          <div className='col-xs-12'>
            <p className={global.ErrorMessage}>{state.filterErrorMessage}</p>
          </div>
        }
      </div>
      <div className="row">
        <div className="col-xs-12">
          <OfferingInstanceOrgUnitGrid
            gridData={offeringManagementInstanceOrgUnitState.arrayData}
            isLoading={offeringManagementInstanceOrgUnitState.isArrayLoading}
            canAddAdditionalInstance={canAddAdditionalInstance === true}
            noResultsMessage={areFiltersValid === false
              ? 'Please select an LSC and Report Period to filter Offerings'
              : 'No Results'
            }
            onEditInstance={onEditOfferingInstanceOrgUnitClicked}
            onAddAdditionalInstance={onAddAdditionalOfferingInstanceOrgUnitClicked}
            onEditAdditionalInstance={onEditAdditionalOfferingInstanceOrgUnitClicked} />
        </div>
      </div>
      <HqOfferingManagementContextLoadingModal
        isLoading={false}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default HqLscOfferingInstanceOrgUnit;