import { isValidDate } from '../../../../../common/utils/validation';
import Constants from "../../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  //Start Date 
  if (formState.startDate !== Constants.BLANK_DATE_STRING) {
    if (!isValidDate(formState.startDate)) {
      errors.startDate = 'Start Date must be a valid date';
    } else if (endDate < startDate) {
      errors.startDate = 'Start Date must be before the End Date';
    }
  }

  //End Date must be after the Start Date
  if (formState.endDate !== Constants.BLANK_DATE_STRING) {
    if (!isValidDate(formState.endDate)) {
      errors.endDate = 'End Date must be a valid date';
    } else if (endDate < startDate) {
      errors.endDate = 'End Date must be after the Start Date';
    }
  }

  return errors;
};

const MyOtsMeetsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MyOtsMeetsValidation;