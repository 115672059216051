import NavLinks from '../../MyLscOtsMeetNavLinks';

import useEnvironmentVariableData from '../../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from "../../../../../../common/state/security/UseSecurityData";

const TAXONOMIES = ['OTSMeetDetail'];
const SCOPE = 'OtsMeet';

const useMyLscOtsMeetSelection = () => {
    const { MY_LSC_OTS_MEET_SEARCH_ROLE_GROUP_ID } = useEnvironmentVariableData();;
    const { contextSecurityState } = useSecurityData();

    return {
        TAXONOMIES,
        SCOPE,
        MY_LSC_OTS_MEET_SEARCH_ROLE_GROUP_ID,
        NavLinks,
        contextSecurityState
    };
};

export default useMyLscOtsMeetSelection;