import EditIcon from '../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ course, onEditCourse, viewLocation }) => (
  <tr>
    <td>{course.courseName} ({course.courseCode})</td>
    <td>{course.courseCompletionDate !== null
      ? formatDate(course.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
        ? ' '
        : formatDate(course.courseCompletionDate)
      : ' '}</td>
    <td>
      {course.courseExpirationDate !== null
        ? formatDate(course.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
          ? `Doesn't Expire`
          : formatDate(course.courseExpirationDate)
        : ' '
      }
    </td>
    <td>
      <span className={course.canHqEdit === true ? global.DisplayComponent : global.HideComponent}>
        <button className={global.IconButtonMargin}
          type="button" onClick={(e) => onEditCourse(e, course, viewLocation)}><EditIcon />
        </button>
      </span>
    </td>
  </tr>
);

const MemberCoursesLargeAdvancedWriteGrid = ({ gridData, isLoading, onEditCourse, viewLocation }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Course Name</th>
        <th>Date Completed</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((course, i) =>
            <GridRow
              key={i}
              course={course}
              onEditCourse={onEditCourse}
              viewLocation={viewLocation} />
          )
          : <tr><td colSpan="4">No Courses</td></tr>
      }
    </tbody>
  </table>
);

export default MemberCoursesLargeAdvancedWriteGrid;