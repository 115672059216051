import PersonNationalTeamContextProvider from '../../state/personNationalTeam/PersonNationalTeamContextProvider';
import PersonProfileContextProvider from '../../state/personProfile/PersonProfileContextProvider';
import PersonCoursesForAdminRoleContextProvider from '../../state/member/memberAdminCourses/PersonCoursesForAdminRoleContextProvider';
import PersonNationalTeamFileTypeContextProvider from '../../state/personNationalTeamFileType/PersonNationalTeamFileTypeContextProvider';

import BasePersonContextProvider from '../../../common/state/personBase/BasePersonContextProvider';
import PersonGeneralContextProvider from '../../../common/state/personGeneral/PersonGeneralContextProvider';
import CoachEnrollmentInCertificationContextProvider from '../../../common/state/lmsEnrollment/CoachEnrollmentInCertificationContextProvider';
import EnrollmentContextProvider from '../../../common/state/lmsEnrollment/EnrollmentContextProvider';
import EnrollmentInCertificationContextProvider from '../../../common/state/lmsEnrollment/EnrollmentInCertificationContextProvider';
import UserContextProvider from '../../../common/state/lmsUser/UserContextProvider';
import GenderIdentityTypeContextProvider from '../../../common/state/genderIdentity/GenderIdentityTypeContextProvider';
import CommentContextProvider from '../../../common/state/comment/CommentContextProvider';
import PersonGeneralNationalTeamContextProvider from '../../../common/state/personGeneralNationalTeam/PersonGeneralNationalTeamContextProvider';
import ContactRelationshipTypesContextProvider from '../../../common/state/contactRelationshipType/ContactRelationshipTypesContextProvider';

const MemberContextRoutesProviders = ({ children }) => {
  return (
    <BasePersonContextProvider>
      <PersonGeneralContextProvider>
        <PersonGeneralNationalTeamContextProvider>
          <CommentContextProvider>
            <PersonProfileContextProvider>
              <PersonNationalTeamContextProvider>
                <PersonNationalTeamFileTypeContextProvider>
                  <ContactRelationshipTypesContextProvider>
                    <GenderIdentityTypeContextProvider>
                      <EnrollmentContextProvider>
                        <EnrollmentInCertificationContextProvider>
                          <CoachEnrollmentInCertificationContextProvider>
                            <PersonCoursesForAdminRoleContextProvider>
                              <UserContextProvider>
                                {children}
                              </UserContextProvider>
                            </PersonCoursesForAdminRoleContextProvider>
                          </CoachEnrollmentInCertificationContextProvider>
                        </EnrollmentInCertificationContextProvider>
                      </EnrollmentContextProvider>
                    </GenderIdentityTypeContextProvider>
                  </ContactRelationshipTypesContextProvider>
                </PersonNationalTeamFileTypeContextProvider>
              </PersonNationalTeamContextProvider>
            </PersonProfileContextProvider>
          </CommentContextProvider>
        </PersonGeneralNationalTeamContextProvider>
      </PersonGeneralContextProvider>
    </BasePersonContextProvider>
  );
};

export default MemberContextRoutesProviders;