import { useEffect, useState } from 'react';

import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useSearchOtsMeetData from '../../../../state/searchOfficialsTrackingSystemMeet/UseSearchOtsMeetData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../../../common/state/security/UseSecurityData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const TAXONOMIES = ['OTSMeetDetail'];
const SCOPE = 'OTSMeet';

const useMyLscOtsMeetSearch = () => {
  const navigate = useNavigate();
  const { otsMeetState, otsMeetHeaderState, setOtsMeetHeaderState, postOtsMeet, getOtsMeetMeetById } = useOtsMeetData();
  const { searchOtsMeetState } = useSearchOtsMeetData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { getContextSecurity, contextSecurityState } = useSecurityData();
  const { navRoutes, isReadyToRoute } = useNavRoutes();
  const [state, setState] = useState({ tryGet: false, tryRedirect: false });

  const onPostOtsMeet = (e, meet) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const otsMeetObj = {
      meetId: meet.meetId,
      isOfficialsQualifyingMeet: null,
      oqmNumber: null
    }
    postOtsMeet(otsMeetObj);
    setState({ ...state, tryGet: true });
  };

  const onEditOtsMeet = (e, meet) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (meet?.meetId) {
      getOtsMeetMeetById(meet?.meetId);
      setState({ ...state, tryRedirect: true });
    }
  };

  useEffect(() => {
    if (state.tryGet === true && otsMeetState.isSaved === true) {
      if (otsMeetState.objData?.meetId) {
        getOtsMeetMeetById(otsMeetState.objData?.meetId);
        setState({ ...state, tryGet: false, tryRedirect: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGet, otsMeetState]);

  useEffect(() => {
    if (isReadyToRoute === true && state.tryRedirect === true && otsMeetHeaderState.isObjLoaded === true) {
      setOtsMeetHeaderState({ ...otsMeetHeaderState, myOtsMeets: false, myLscOtsMeetSearch: true });
      if (contextSecurityState.isObjLoaded === true && (otsMeetHeaderState.objData?.otsMeetId === contextSecurityState.contextId)
        && contextSecurityState.scope === SCOPE) {
        const infoRoute = navRoutes.OTS_MEET_INFO;
        if (infoRoute) {
          navigate(infoRoute?.route);
        }
        else {
          const sessionsRoute = navRoutes.OTS_MEET_SESSIONS;
          if (sessionsRoute) {
            navigate(sessionsRoute?.route);
          }
          else {
            const officialsRoute = navRoutes.OTS_MEET_OFFICIALS;
            if (officialsRoute) {
              navigate(officialsRoute?.route);
            }
          }
        }
      } else if (contextSecurityState.isObjLoading !== true) {
        getContextSecurity(otsMeetHeaderState.objData?.otsMeetId, TAXONOMIES, SCOPE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, contextSecurityState, otsMeetHeaderState, isReadyToRoute, navRoutes]);

  return {
    selectOrgUnitState,
    searchOtsMeetState,
    onPostOtsMeet,
    onEditOtsMeet
  };
};

export default useMyLscOtsMeetSearch;