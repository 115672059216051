import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import PlusIcon from '../../../../../common/components/icons/PlusIcon';

import { formatPhoneNumber } from '../../../../../common/utils/validation';
import { DEFAULT_ID } from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRowAddress = ({ address, contactId, onAddEditAddress, onDeleteAddress }) => (
  <tr>
    <td>{address.address?.address1 || ''} {address.address?.address2 || ''}</td>
    <td>{address.address?.city || ''}</td>
    <td>{address.address?.stateCode || ''}</td>
    <td>{address.address?.postalCode || ''}</td>
    <td>{address.addressType?.typeName || ''}</td>
    <td>{address.isPrimary === true ? 'Yes' : 'No'}</td>
    <td className='pull-right'>
      {onAddEditAddress &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) =>
          onAddEditAddress(e, address.personNationalTeamContactAddressId, contactId)}>
          <EditIcon />
        </button>
      }
      {!address.isPrimary === true && onDeleteAddress &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) =>
          onDeleteAddress(e, address.personNationalTeamContactAddressId,
            `${address.address?.address1}${address.address?.address2 ? ' ' + address.address?.address2 : ''}, ${address.address?.city}, ${address.address?.stateCode} ${address.address?.postalCode} (${address.addressType?.typeName})`)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const DetailTableRowPhone = ({ phone, contactId, onAddEditPhone, onDeletePhone }) => {
  return (
    <tr>
      <td colSpan='2'>{phone.phoneNumber ? formatPhoneNumber(phone.phoneNumber) : ''}</td>
      <td colSpan='2'>{phone.phoneType?.typeName || ''}</td>
      <td colSpan='2'>{phone.isPrimary === true ? "Yes" : "No"}</td>
      <td className='pull-right'>
        {onAddEditPhone &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) =>
            onAddEditPhone(e, phone.personNationalTeamContactPhoneId, contactId)}>
            <EditIcon />
          </button>
        }
        {!phone.isPrimary === true && onDeletePhone &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) =>
            onDeletePhone(e, phone.personNationalTeamContactPhoneId,
              `${formatPhoneNumber(phone.phoneNumber)} (${phone.phoneType?.typeName})`)}>
            <DeleteIcon />
          </button>
        }
      </td>
    </tr >
  );
}

const GridRowDetailTableAddresses = ({ addresses, contactId, onAddEditAddress, onDeleteAddress }) => (
  <td colSpan="7">
    <table className={global.DetailTable}>
      <thead>
        <tr>
          <th>Address</th>
          <th>City</th>
          <th>State</th>
          <th>Zip Code</th>
          <th>Type</th>
          <th>Primary?</th>
          <th className='pull-right'>
            {onAddEditAddress &&
              <button className={global.IconButtonMargin} type="button" onClick={(e) =>
                onAddEditAddress(e, DEFAULT_ID, contactId)}>
                <PlusIcon />
              </button>
            }
          </th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(addresses) && addresses.length > 0
          ? addresses.map((address, i) => <DetailTableRowAddress key={i} address={address} contactId={contactId} onAddEditAddress={onAddEditAddress} onDeleteAddress={onDeleteAddress} />)
          : <tr><td colSpan='7'>No Addresses</td></tr>
        }
      </tbody>
    </table>
  </td >
);

const GridRowDetailTablePhones = ({ phones, contactId, onAddEditPhone, onDeletePhone }) => (
  <td colSpan="7">
    <table className={global.DetailTable}>
      <thead>
        <tr>
          <th colSpan='2'>Phone Number</th>
          <th colSpan='2'>Type</th>
          <th colSpan='2'>Primary?</th>
          <th className='pull-right'>
            {onAddEditPhone &&
              <button className={global.IconButtonMargin} type="button" onClick={(e) =>
                onAddEditPhone(e, DEFAULT_ID, contactId)}>
                <PlusIcon />
              </button>
            }
          </th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(phones) && phones.length > 0
          ? phones.map((phone, i) => <DetailTableRowPhone key={i} phone={phone} contactId={contactId} onAddEditPhone={onAddEditPhone} onDeletePhone={onDeletePhone} />)
          : <tr><td colSpan='7'>No Phones</td></tr>
        }
      </tbody>
    </table>
  </td>
);

const LargeGridRow = ({ contact, expandedId, onEditPerson, onDeletePerson,
  onAddEditPhone, onDeletePhone, onAddEditAddress, onDeleteAddress, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td>{contact.name || ''}</td>
      <td>{contact.relationship || ''}</td>
      <td>{contact.canEdit === true ? (contact.email || '') : 'Not collected for members under 16'}</td>
      <td>{contact.phoneNumber || ''}</td>
      <td>{contact.isPrimaryDisplay || ''}</td>
      <td colSpan='2'>
        {contact.canEdit && onEditPerson &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditPerson(e, contact.id)}>
            <EditIcon />
          </button>
        }
        {contact.canDelete && onDeletePerson &&
          <button className={global.IconButtonMargin} type="button" onClick={(e) =>
            onDeletePerson(e, contact.id, `${contact.name}, ${contact.email} (${contact.relationship})`)}>
            <DeleteIcon />
          </button>
        }
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, contact.id)}>
          {expandedId === contact.id ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {expandedId === contact.id &&
      <Fragment>
        <tr className={global.Expanded}>
          <GridRowDetailTablePhones phones={contact.contactPhones} contactId={contact.id} onAddEditPhone={onAddEditPhone} onDeletePhone={onDeletePhone} />
        </tr>
        <tr className={global.Expanded}>
          <GridRowDetailTableAddresses addresses={contact.contactAddresses} contactId={contact.id} onAddEditAddress={onAddEditAddress} onDeleteAddress={onDeleteAddress} />
        </tr>
      </Fragment >
    }
  </Fragment >
);

const NationalTeamContactsGridLarge = ({ contacts, isLoading, expandedId, onEditPerson, onDeletePerson,
  onAddEditPhone, onDeletePhone, onAddEditAddress, onDeleteAddress, onExpandClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Relationship</th>
        <th>Email</th>
        <th>Phone Number</th>
        <th>Primary Emergency Contact?</th>
        <th colSpan='2'></th>
      </tr>
    </thead>
    <tbody>
      {contacts.length > 0 ?
        contacts.map((contact, i) => <LargeGridRow key={i} contact={contact} expandedId={expandedId} onEditPerson={onEditPerson} onDeletePerson={onDeletePerson} onAddEditPhone={onAddEditPhone} onDeletePhone={onDeletePhone} onAddEditAddress={onAddEditAddress} onDeleteAddress={onDeleteAddress} onExpandClicked={onExpandClicked} />)
        : isLoading
          ? <tr><td colSpan="7">Loading...</td></tr>
          : <tr><td colSpan="7">No Contacts</td></tr>
      }
    </tbody>
  </table>
);

export default NationalTeamContactsGridLarge