import { createContext, useState } from 'react';

import OtsCertificationApplicationData from './OtsCertificationApplicationData';

export const OtsCertificationApplicationStateContext = createContext();

const OtsCertificationApplicationContextProvider = ({ children }) => {
  const stateHook = useState(OtsCertificationApplicationData.INITIAL_STATE);

  return (
    <OtsCertificationApplicationStateContext.Provider value={stateHook}>
      {children}
    </OtsCertificationApplicationStateContext.Provider>
  );
};

export default OtsCertificationApplicationContextProvider;