import { useState, useEffect } from 'react';

import useNationalEvaluatorData from '../../../../state/otsNationalEvaluator/UseNationalEvaluatorData';
import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useMeetEvaluatorData from '../../../../state/otsMeetEvaluator/UseMeetEvaluatorData';

import Constants from '../../../../../common/utils/Constants';

const INITIAL_VIEW_STATE = {
  gridData: [],
  nationalEvaulators: [],
  selectedNationalEvaluatorId: -1,
  isLoading: true,
  isSaving: false,
  showError: false
};

const useOtsMeetEvaluators = () => {
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const { otsMeetState } = useOtsMeetData();
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState());
  const { getNationalEvaluators } = useNationalEvaluatorData();
  const { getMeetEvaluatorsByOtsMeetId, postMeetEvaluator, deleteMeetEvaluator } = useMeetEvaluatorData();

  const getData = () => {
    getMeetEvaluatorsByOtsMeetId(otsMeetState.objData.otsMeetId)
      .then(data => {
        const newState = { ...state };
        if (Array.isArray(data?.arrayData)) {
          newState.gridData = data.arrayData;
          setState(newState);
        }
        return newState;
      })
      .then((newState) => {
        getNationalEvaluators(otsMeetState.objData.otsMeetId)
          .then(data => {
            const nationalEvaulators = [{ id: -1, name: 'Please select' }];
            data.arrayData.forEach(x => {
              if (!newState.gridData.find(f => f.nationalEvaluatorId === x.nationalEvaluatorId)) {
                nationalEvaulators.push({ id: x.nationalEvaluatorId, name: x.evaluatorName });
              }
            });
            setState({ ...newState, nationalEvaulators, isLoading: false, showError: false });
          })
          .catch(e => {
            console.log(e);
            //TODO: Handle error
            setState({ ...state, isLoading: false, showError: false });
          });
      })
      .catch(e => {
        console.log(e);
        //TODO: Handle error
        setState({ ...state, isLoading: false, showError: false });
      });
  };

  const onNationalEvaluatorChanged = (newValue) => {
    setState({ ...state, selectedNationalEvaluatorId: newValue });
  };

  const onAddButtonClicked = () => {
    if (state.selectedNationalEvaluatorId > 0) {
      postMeetEvaluator(otsMeetState.objData.otsMeetId, state.selectedNationalEvaluatorId)
        .then(data => {
          getData();
        })
        .catch(e => {
          console.log(e);
          //TODO: Handle error
          setState({ ...state, isLoading: false });
        });
    }
    else {
      setState({ ...state, showError: true });
    }
  };

  const onDeleteButtonClicked = (evaluator) => {
    if (evaluator) {
      setDeleteModalState({
        ...deleteModalState,
        meetEvaluatorId: evaluator.meetEvaluatorId,
        evaluatorName: evaluator.evaluatorName,
        nationalCertification: evaluator.nationalCertification,
        mentorStatus: evaluator.mentorStatus,
        hasEvaluationForm: evaluator.hasEvaluationForm,
        displayPopUp: true
      })
    }
  };

  const onDeleteEvaluator = (meetEvaluatorId) => {
    if (meetEvaluatorId > 0) {
      deleteMeetEvaluator(meetEvaluatorId)
        .then(data => {
          onDeleteModalCanceled();
          getData();
        })
        .catch(e => {
          console.log(e);
          //TODO: Handle error
          setState({ ...state, isLoading: false });
        });
    }
    //TODO: Handle error
  };

  const onDeleteModalCanceled = () => {
    setDeleteModalState(getInitialDeleteModalState());
  };

  useEffect(() => {
    if (otsMeetState.objData?.isOfficialsQualifyingMeet === true && otsMeetState.objData?.otsMeetId > 0) {
      getData();
    } else {
      setState({ ...state, isLoading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetState.objData]);

  function getInitialDeleteModalState() {
    return {
      meetEvaluatorId: Constants.DEFAULT_ID,
      evaluatorName: '',
      nationalCertification: '',
      mentorStatus: '',
      hasEvaluationForm: '',
      modalTitle: 'Delete Meet Evaluator?',
      displayPopUp: false
    };
  };

  return {
    ...state,
    isOfficialsQualifyingMeet: otsMeetState.objData?.isOfficialsQualifyingMeet,
    onNationalEvaluatorChanged,
    onAddButtonClicked,
    deleteModalState,
    onDeleteButtonClicked,
    onDeleteEvaluator,
    onDeleteModalCanceled
  };
};

export default useOtsMeetEvaluators;