import React, { Fragment } from 'react';

import HideIcon from '../../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../../common/components/icons/ShowIcon';
import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const DetailGridRowCourse = ({ moreInfo }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>Session: {`${moreInfo.sessionOrderId || <span>&nbsp;</span>} (${moreInfo.sessionTypeName || <span>&nbsp;</span>} ${moreInfo.sessionDate ? formatDate(moreInfo.sessionDate) : <span>&nbsp;</span>})`}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Session
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {`${moreInfo.sessionOrderId || <span>&nbsp;</span>} (${moreInfo.sessionTypeName || <span>&nbsp;</span>} ${moreInfo.sessionDate ? formatDate(moreInfo.sessionDate) : <span>&nbsp;</span>})`}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          OQM Session?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {moreInfo?.isOfficialsQualifyingSession === true ? 'Yes' : 'No'}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Role Worked
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {`${moreInfo.roleTypeName || <span>&nbsp;</span>} (${moreInfo.roleTypeAbbreviation || <span>&nbsp;</span>})`}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Apprentice?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {moreInfo?.isApprentice === true ? 'Apprentice' : 'No'}
        </div>
      </div>
    </div>
  </div>
);



const DetailGridCourses = ({ official }) => (
  <div className={global.SmallTableDetailTable}>
    <div className={global.SmallTableRow}>
    </div>
    {(!Array.isArray(official?.sessions) || official?.sessions.length <= 0) &&
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            No Additional Info
          </div>
        </div>
      </div>
    }
    {Array.isArray(official?.sessions) && official?.sessions.length > 0 &&
      official?.sessions?.map((moreInfo, i) =>
        <DetailGridRowCourse
          key={i}
          moreInfo={moreInfo}
        />)
    }
  </div>
);


const SmallGridRowIcons = ({ official, expandedId, onExpandClicked }) => (
  <Fragment>
    <button
      className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, official?.meetId)}>
      {expandedId === official?.meetId ? <HideIcon /> : <ShowIcon />}
    </button>
  </Fragment>
);

const SmallGridRow = ({ official, expandedId, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{official?.meetName}</span>&nbsp;
        <SmallGridRowIcons
          official={official}
          expandedId={expandedId}
          onExpandClicked={onExpandClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Meet Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {official?.meetName || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Classification
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {official?.meetClassificationTypeName || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            OQM Number
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {official?.oqmNumber || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Start-End Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {formatDate(official?.startDate)} -  {formatDate(official?.endDate)}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Sessions
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {official?.sessionsNumber >= 0 ? official?.sessionsNumber : <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Roles
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {official?.officialRoleTypeName || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    {expandedId === official?.meetId &&
      <Fragment>
        <DetailGridCourses official={official} meetId={official?.meetId} />
      </Fragment >
    }
  </Fragment>
);


const OtsOfficialMeetHistorySmallWriteGrid = ({ state, expandedId, onExpandClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(state?.arrayData) && state?.arrayData?.length > 0
      ? state?.arrayData?.map((official, i) =>
        <SmallGridRow
          key={i}
          official={official}
          expandedId={expandedId}
          onExpandClicked={onExpandClicked} />)
      : (
        <div>
          {state?.isArrayLoading
            ? (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
              </div>
            ) : (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
              </div>
            )
          }
        </div>
      )
    }
  </div>
);

export default OtsOfficialMeetHistorySmallWriteGrid;