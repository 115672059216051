import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ evaluator, onDeleteButtonClicked }) => {
  return (
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className="hidden-xs">{evaluator.evaluatorName}</span>&nbsp;
        <button
          className={global.IconButton}
          type="button"
          onClick={(e) => onDeleteButtonClicked(evaluator)}>
          <DeleteIcon />
        </button>
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Evaluator Name
          </div>
          <div className={['col-xs-6 col-sm-3  visible-xs', global.SmallTableRowData].join(' ')}>
            {evaluator.evaluatorName || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Natl Certs
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {evaluator.nationalCertification || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Mentor Status
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {evaluator.mentorStatus || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

const MeetEvaluatorsGridSmall = ({ gridData, onDeleteButtonClicked }) => {
  return (
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((evaluator, i) => <SmallGridRow key={i} evaluator={evaluator} onDeleteButtonClicked={onDeleteButtonClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
          </div>
        )
      }
    </div>
  );
};

export default MeetEvaluatorsGridSmall;