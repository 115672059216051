import React from 'react';

import Input from '../../../../../common/components/inputs/Input';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';

const EvaluationFormIntegerInput = ({ question, onItemClick, disabled }) => {
  return (
    <div className="row">
      <div className="col-xs-12" dangerouslySetInnerHTML={{ __html: question.acknowledgementFormText }}></div>
      <div className="col-xs-12">
        {disabled === true ?
          <ReadOnly
            name={`question${question.evaluationQuestionId}`}
            label={question.question || ''}
            value={question.field || ''}
          /> :
          <Input
            type='number'
            min={0}
            name={`question${question.evaluationQuestionId}`}
            label={question.question || ''}
            value={question.field || ''}
            onChange={value => onItemClick(question.evaluationQuestionId, value)} />
        }
      </div>
    </div>
  );
};

export default EvaluationFormIntegerInput;