import useOtsCertificationApplicationData from '../../../../state/otsCertificationApplication/UseOtsCertificationApplicationData';

const useCurrentCerts = () => {
  const { otsCertificationApplicationState } = useOtsCertificationApplicationData();

  return {
    isLoading: otsCertificationApplicationState.isArrayLoading === true,
    gridData: otsCertificationApplicationState.arrayData || [],
    error: otsCertificationApplicationState.message
  };
};

export default useCurrentCerts;