import { Fragment } from 'react';

import useLscOfferingManagementContextView from './UseLscOfferingManagementContextView';

import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import Headings from '../../../../common/components/headings/Headings';
import PageNotFound from '../../../../common/components/PageNotFound';
import LargeContextBasedNav from '../../../../common/components/contextBasedNav/LargeContextBasedNav';
import MobileContextBasedNav from '../../../../common/components/contextBasedNav/MobileContextBasedNav';

import global from '../../../../common/components/GlobalStyle.module.css';

const LscOfferingManagementContextView = ({ children }) => {
  const {
    homeRoute,
    selectOrgUnitState,
    onHomeClicked,
    onBackToSelectionClicked
  } = useLscOfferingManagementContextView();


  if (!selectOrgUnitState.orgUnitId) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToSelectionClicked}>
              <LeftArrowIcon />  Back to Selection
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>)
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              LSC Offering Management
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      {selectOrgUnitState.showBackToSelection &&
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToSelectionClicked}>
              <LeftArrowIcon />  Back to Selection
            </button>
          </div>
        </div>
      }
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <p className={global.HeaderText}><strong>Editing:</strong> {selectOrgUnitState.orgUnitName}</p>
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="LSCOfferingManagement" />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="LSCOfferingManagement" homeRoute={homeRoute} />
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default LscOfferingManagementContextView;