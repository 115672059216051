import { useEffect } from 'react';

import useContactRelationshipTypeData from '../../../state/contactRelationshipType/UseContactRelationshipTypesData';

import Constants from '../../../utils/Constants';

const SELF_LABEL = 'Self';

const useNoSelfContactRelationshipTypeDropdown = () => {
  const { contactRelationshipTypeState, getContactRelationshipTypes } = useContactRelationshipTypeData();

  useEffect(() => {
    if (contactRelationshipTypeState.isArrayLoading === false && contactRelationshipTypeState.isArrayLoaded === false) {
      getContactRelationshipTypes();
    }
    if (contactRelationshipTypeState.isArrayLoading === false && contactRelationshipTypeState.arrayData.length > 0) {
      const selfIndex = contactRelationshipTypeState.dataAsOptionsWithNames.findIndex(x => x.name === SELF_LABEL);
      if (selfIndex > Constants.DEFAULT_ID) {
        contactRelationshipTypeState.dataAsOptionsWithNames.splice(selfIndex, 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRelationshipTypeState]);

  return {
    contactRelationshipTypeState
  };
};

export default useNoSelfContactRelationshipTypeDropdown;