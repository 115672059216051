import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';
import Constants from '../../../../common/utils/Constants';

import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

const TITLE = 'Social Media';

const useProfileSocialMedia = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personProfileState, getPersonProfile, putPersonProfile } = usePersonProfileData();
  const [modalState, setModalState] = useState(getInitialModalState());

  const onAddEditSocialMedia = (e, personSocialMediaId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_PROFILE_SOCIAL_MEDIA_DETAIL?.route, { state: { personSocialMediaId } });
  };

  // Called by grid delete button, populates modal with target narrative info
  const onOpenModalClicked = (e, personSocialMediaId, socialHandle) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      personSocialMediaId: personSocialMediaId,
      socialHandle: socialHandle,
      displayPopUp: true
    });
  };

  const onDeleteSocialMedia = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const personObj = JSON.parse(JSON.stringify(personProfileState.objData));

    const index = personObj.socialMedia.findIndex(e => e.personSocialMediaId === modalState.personSocialMediaId);
    if (index > Constants.DEFAULT_ID) {
      personObj.socialMedia.splice(index, 1);
      putPersonProfile(personProfileState.objData.personId, personObj);
    }
    setModalState({
      ...getInitialModalState()
    });
  };

  // Clears modalState and closes modal
  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...getInitialModalState()
    });
  };

  useEffect(() => {
    if (personProfileState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personProfileState.objData)) {
      getPersonProfile(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  // Holds initial state for delete modal
  function getInitialModalState() {
    return {
      personSocialMediaId: Constants.DEFAULT_ID,
      socialHandle: '',
      displayPopUp: false,
      modalTitle: 'Delete this Social Media Account?'
    };
  };

  return {
    title: TITLE,
    DEFAULT_ID: Constants.DEFAULT_ID,
    personProfileState,
    modalState,
    onAddEditSocialMedia,
    onDeleteSocialMedia,
    onOpenModalClicked,
    onModalCanceled
  };
};

export default useProfileSocialMedia;