import { Fragment } from 'react';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

import NationalEvaluatorsGridLarge from './NationalEvaluatorsGridLarge';
import NationalEvaluatorsGridSmall from './NationalEvaluatorsGridSmall';

const NationalEvaluatorsGrid = ({ gridData, onEdit, onDelete }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <NationalEvaluatorsGridLarge
        gridData={gridData}
        onEdit={onEdit}
        onDelete={onDelete}
        expandedId={expandedId}
        onExpandClicked={onClick} />
      <NationalEvaluatorsGridSmall
        gridData={gridData}
        onEdit={onEdit}
        onDelete={onDelete}
        expandedId={expandedId}
        onExpandClicked={onClick} />
    </Fragment>
  );
};

export default NationalEvaluatorsGrid;