import React, { Fragment } from 'react';

import { FormatMoneyWithSymbol } from '../../../../common/utils/FormatMoney';
import { formatDate } from '../../../../common/utils/DateFunctions';

import style from '../Member.module.css';
import global from '../../../../common/components/GlobalStyle.module.css';

const GridRowIcons = ({ person, onOpenCancelModalClicked }) => (
	<Fragment>
		<button className={global.IconButton} type='button' onClick={(e) => onOpenCancelModalClicked(e, person)}>
			<span className={[style.SmallGridButtonText, 'pull-right'].join(' ')}>Cancel Membership</span>
		</button>
	</Fragment>
);


const GridRowContent = ({ person }) => (
	<div className={global.SmallTableRowInfo}>
		<div className='row'>
			<div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
				Team Name
			</div>
			<div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
				{person?.orgUnitName}/{person?.orgUnitCode}
			</div>
			<div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
				Member Role
			</div>
			<div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
				{person?.upgradeFromOfferingName || ''} &nbsp; / &nbsp; {FormatMoneyWithSymbol(person.upgradeFromOfferingTotalFeeAmount)}
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
				Effective Date
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
				{formatDate(person?.expirationDate)}
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
				Expiration Date
			</div>
			<div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
				{formatDate(person?.expirationDate)}
			</div>
		</div>
	</div>
);

const GridRow = ({ person, onOpenCancelModalClicked }) => {
	return (
		<Fragment>
			<div className={global.SmallTableRow}>
				<div className={global.SmallTableRowHead}>
					<span className='hidden-xs'>{person?.orgUnitName || ''} / ({person.orgUnitCode}) </span>&nbsp;
					<GridRowIcons person={person} onOpenCancelModalClicked={onOpenCancelModalClicked} />
				</div>
				<GridRowContent person={person} />
			</div>
		</Fragment>
	);
}
const SmallCancelMembershipGrid = ({ state, onOpenCancelModalClicked }) => (
	<div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
		{state.isArrayLoaded !== true
			? (<div className={global.SmallTableRow}>
				<div className={global.SmallTableRowHead}> &nbsp; </div>
				<div className={global.SmallTableRowInfo}>
					{state.isArrayLoading === true
						? <div className={global.SmallTableRowLabels}>Loading...</div>
						: <div className={global.SmallTableRowLabels}>No Results</div>}
				</div>
			</div>)
			: Array.isArray(state?.arrayData) === true && state?.arrayData?.length > 0
				? state?.arrayData?.map((person, i) =>
					<GridRow
						key={i}
						person={person}
						onOpenUpgradeModalClicked={onOpenCancelModalClicked} />)
				: (<div className={global.SmallTableRow}>
					<div className={global.SmallTableRowHead}>
						&nbsp;
					</div>
					<div className={global.SmallTableRowInfo}>
						<div className={global.SmallTableRowLabels}>No Results</div>
					</div>
				</div>)
		}
	</div>
);

export default SmallCancelMembershipGrid;