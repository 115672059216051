import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getPersonSubscriptionsData = (personId, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/person/${personIdForUrl}/Subscriptions`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const postPersonSubscriptionsData = (personId, subscriptionsObj, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/person/${personIdForUrl}/Subscriptions`;

    return api?.executeObject ? api.executeObject(url, 'POST', subscriptionsObj) : null;
  }
};

const PersonSubscriptionsData = {
  getPersonSubscriptionsData,
  postPersonSubscriptionsData
};

export default PersonSubscriptionsData;