import { useEffect, useState } from 'react';

import validate from './CompetitorListRegistrationFormValidation';

import { createGroupMemberObj, getGroupPropertyOptions, getNewGroupStatus } from '../../../utils/GroupsUtils';

import useGroupFieldNamesData from '../../../state/groupFieldNames/UseGroupFieldNamesData';
import useOrgGroupPropertiesData from '../../../state/orgGroupProperties/UseOrgGroupPropertiesData';
import useGroupPersonCoachesData from '../../../state/groupPersonCoaches/UseGroupPersonCoachesData';

import UseForm from '../../../utils/UseForm';

const INITIAL_FORM_STATE = {
  groupAttendeeTypeId: '',
  groupAttendeeTypeName: '--',
  groupRoleTypeId: '',
  groupRoleTypeName: '--',
  personalCoachId: '',
  personalCoachName: '--',
  potentialOrConfirmedId: '',
  potentialOrConfirmedName: '--'
};

const INITIAL_OPTIONS_STATE = {
  areOptionsLoaded: false,
  attendTypeOptions: [],
  roleOptions: [],
  potentialOrConfirmedOptions: []
};

const useCompetitorListRegistrationForm = (personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj, disableValidation, onFormCompletion) => {
  const { groupPersonCoachesState } = useGroupPersonCoachesData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { formState, errorState, setFormState, onValueTextPairChanged, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, disableValidation === true ? () => { } : validate);
  const [optionsState, setOptionsState] = useState(INITIAL_OPTIONS_STATE);

  useEffect(() => {
    if (groupObj.groupTypeId > 0 && optionsState.areOptionsLoaded === false) {
      if (orgGroupPropertiesState.isArrayLoaded === false || groupObj.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
        const getOrgGroupPropertiesPromise = getOrgGroupProperties(groupObj.groupTypeId);

        if (getOrgGroupPropertiesPromise !== null) {
          getOrgGroupPropertiesPromise.catch((e) => {
            //TODO
          });
        }
      } else {
        const attendTypeOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE, orgGroupPropertiesState.arrayData);
        const roleOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ROLE_TYPE, orgGroupPropertiesState.arrayData);
        const potentialOrConfirmedOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED, orgGroupPropertiesState.arrayData);

        potentialOrConfirmedOptions.unshift({ id: '', name: '--' })

        setOptionsState({
          ...optionsState,
          attendTypeOptions,
          roleOptions,
          potentialOrConfirmedOptions,
          areOptionsLoaded: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupObj, orgGroupPropertiesState.isArrayLoaded]);

  useEffect(() => {
    if (optionsState.areOptionsLoaded === true && memberGroupObj && groupPersonCoachesState.areOptionsLoaded === true
      && groupPersonCoachesState.personId === personId) {
      const personGroupTypeField = memberGroupObj.personGroupTypeField || [];
      const groupAttendeeTypeName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE)?.fieldValue || '';
      const groupRoleTypeName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ROLE_TYPE)?.fieldValue || '';
      const personalCoachId = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PERSONAL_COACH)?.fieldValue || '';
      const potentialOrConfirmedName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED)?.fieldValue || '';

      const groupAttendeeType = optionsState.attendTypeOptions.find(x => x.name === groupAttendeeTypeName);
      const groupRoleType = optionsState.roleOptions.find(x => x.name === groupRoleTypeName);
      const personalCoach = groupPersonCoachesState.options.find(x => x.id === personalCoachId);
      const potentialOrConfirmed = optionsState.potentialOrConfirmedOptions.find(x => x.name === potentialOrConfirmedName);

      setFormState({
        ...formState,
        groupAttendeeTypeId: groupAttendeeType?.id || optionsState.attendTypeOptions[0]?.id || '',
        groupAttendeeTypeName: groupAttendeeType?.name || optionsState.attendTypeOptions[0]?.name || '--',
        groupRoleTypeId: groupRoleType?.id || optionsState.roleOptions[0]?.id || '',
        groupRoleTypeName: groupRoleType?.name || optionsState.roleOptions[0]?.name || '--',
        personalCoachId: personalCoach?.id || groupPersonCoachesState.options[0]?.id || '',
        personalCoachName: personalCoach?.name || groupPersonCoachesState.options[0]?.name || '--',
        potentialOrConfirmedId: potentialOrConfirmed?.id || optionsState.potentialOrConfirmedOptions[0]?.id || '',
        potentialOrConfirmedName: potentialOrConfirmed?.name || optionsState.potentialOrConfirmedOptions[0]?.name || '--'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsState.areOptionsLoaded, groupPersonCoachesState.areOptionsLoaded, groupPersonCoachesState.personId, memberGroupObj]);

  function submitFormCallback(formState) {
    const orgGroupId = groupObj?.orgGroupId;
    const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
      ? memberGroupObj.personOrgGroupQualification[0]
      : undefined;

    if (personId && orgGroupId) {
      const groupStatus = getNewGroupStatus(personOrgGroupQualificationStatus, groupObj.requiresApproval);

      const groupMemberObj = createGroupMemberObj(personId, orgGroupId, [
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE, fieldValue: formState.groupAttendeeTypeId },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ROLE_TYPE, fieldValue: formState.groupRoleTypeId },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PERSONAL_COACH, fieldValue: formState.personalCoachId },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED, fieldValue: formState.potentialOrConfirmedId }
      ], orgGroupPropertiesState.arrayData, personOrgGroupId, personOrgGroupQualification, groupStatus);

      onFormCompletion(groupMemberObj);
    }
  };

  return {
    isLoading: groupPersonCoachesState.isArrayLoading,
    formState,
    errorState,
    optionsState,
    onValueTextPairChanged,
    handleSubmit
  };
};

export default useCompetitorListRegistrationForm;