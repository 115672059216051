import Constants from '../../../../common/utils/Constants';
import useNationalEvaluators from './UseNationalEvaluators';

import AddNationalEvaluator from './addNationalEvaluator/AddNationalEvaluator';
import NationalEvaluatorsGrid from './NationalEvaluatorsGrid';

import AtoZFilter from '../components/AtoZFilter';

import Headings from '../../../../common/components/headings/Headings';
import LoadingModal from '../../../../common/components/dialogs/LoadingModal';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import EditNationalEvaluator from './editNationalEvaluator/EditNationalEvaluator';

const NationalEvaluators = () => {
  const {
    isLoading,
    showGrid,
    showAddForm,
    showEditForm,
    isSaving,
    nationalEvaluatorId,
    gridData,
    nationalEvaluatorTitles,
    officialPositionTypes,
    lastNameFilter,
    onAddButtonClicked,
    onCallback,
    returnToGrid,
    onEdit,
    onDelete,
    alphabeticalFilterClicked } = useNationalEvaluators();

  return (
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <Headings.H3>{showAddForm === true ? 'Add National Evaluator' : showEditForm === true ? 'Edit National Evaluator' : 'National Evaluators'}</Headings.H3>
      </div>
      {showGrid && <div className="col-xs-12 usas-extra-top-margin">
        <ActionIntraPageButton onClick={onAddButtonClicked}>Add Evaluator</ActionIntraPageButton>
      </div>}
      <div className="col-xs-12">
        {isLoading && <LoadingModal />}
        <PopUpModal widthPct={50} maxWidth={250} title={Constants.SAVING_MSG} displayPopUp={isSaving === true} />
        {showGrid &&
          <div className='row'>
            <div className="col-xs-12 usas-extra-top-margin">
              <AtoZFilter lastNameFilter={lastNameFilter} alphabeticalFilterClicked={alphabeticalFilterClicked} />
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <NationalEvaluatorsGrid
                gridData={gridData}
                onEdit={onEdit}
                onDelete={onDelete} />
            </div>
          </div>}
        {showAddForm &&
          <AddNationalEvaluator
            gridData={gridData}
            nationalEvaluatorTitles={nationalEvaluatorTitles}
            officialPositionTypes={officialPositionTypes}
            onBackClicked={returnToGrid}
            onAddCallback={onCallback} />}
        {showEditForm && nationalEvaluatorId > 0 &&
          <EditNationalEvaluator
            nationalEvaluatorId={nationalEvaluatorId}
            nationalEvaluatorTitles={nationalEvaluatorTitles}
            officialPositionTypes={officialPositionTypes}
            onBackClicked={returnToGrid}
            onEditCallback={onCallback} />}
      </div>
    </div>
  );
};

export default NationalEvaluators;