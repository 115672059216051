import React, { Fragment } from 'react';

import useSearchMember from './UseSearchMember';

import SearchMemberGrid from './SearchMemberGrid';

import Dropdown from '../../dropdowns/Dropdown';
import Headings from '../../headings/Headings';
import Input from '../../inputs/Input';
import InternationalPhoneInput from '../../inputs/InternationalPhoneInput';
import DatePicker from '../../datepickers/DatePicker';
import OrgRoleTreeView from '../../tree/orgRoleTree/OrgRoleTreeView';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import StatesCombobox from '../../comboboxes/statesCombobox/StatesCombobox';
import OrgUnitTreeView from '../../tree/orgUnitTree/OrgUnitTreeView';
import PopUpModal from '../../dialogs/PopUpModal';
import YesNoSwitch from '../../yesNoSwitch/YesNoSwitch';

import Constants from '../../../utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const Form = ({ handleSubmit, searchMemberState, formState, errorState, onFormValueChanged,
  onValueTextPairChanged, clearForm, competitionGenderOptions, isCurrentOptions, sortByOptions,
  onShowHideAdvancedSearch, onCancelClicked }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <OrgUnitTreeView
          label="Organization*"
          name="orgUnit"
          value={formState.orgUnit}
          error={errorState.orgUnit}
          message={errorState.orgUnit}
          selectableLeavesOnly={false}
          singleSelect={true}
          onChange={(newValue) => { onFormValueChanged('orgUnit', newValue) }}
          organizationId={undefined} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker
          label="Birth Date"
          name="birthDate"
          value={formState.birthDate}
          error={errorState.birthDate}
          message={errorState.birthDate}
          onChange={(value) => { onFormValueChanged('birthDate', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Competition Category"
          name="competitionGenderTypeId"
          options={competitionGenderOptions}
          value={formState.competitionGenderTypeId}
          error={errorState.competitionGenderTypeId}
          message={errorState.competitionGenderTypeId}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'competitionGenderTypeId', newValueLabel, 'competitionGenderTypeName');
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Legal First Name or Preferred Name"
          name="firstName"
          value={formState.firstName}
          error={errorState.firstName}
          message={errorState.firstName}
          onChange={(value) => { onFormValueChanged('firstName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Middle Name or Middle Initial"
          name="middleName"
          value={formState.middleName}
          error={errorState.middleName}
          message={errorState.middleName}
          onChange={(value) => { onFormValueChanged('middleName', value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Legal Last Name or Maiden Name"
          name="lastName"
          value={formState.lastName}
          error={errorState.lastName}
          message={errorState.lastName}
          onChange={(value) => { onFormValueChanged('lastName', value) }} />
      </div>
      <div className={searchMemberState.showAdvanced === true ? global.DisplayComponent : global.HideComponent}>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="City"
            name="city"
            value={formState.city}
            error={errorState.city}
            message={errorState.city}
            onChange={(value) => { onFormValueChanged('city', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <StatesCombobox
            label="State"
            name="state"
            valueToMatch={formState.stateName}
            error={errorState.state}
            message={errorState.state}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'state', newValueLabel, 'stateName', e); }}
            isNorthAmerica={true} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Zip Code"
            name="zip"
            value={formState.zip}
            error={errorState.zip}
            message={errorState.zip}
            onChange={(value) => { onFormValueChanged('zip', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Member Id"
            name="memberId"
            value={formState.memberId}
            error={errorState.memberId}
            message={errorState.memberId}
            onChange={(value) => { onFormValueChanged('memberId', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Email"
            name="email"
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => { onFormValueChanged('email', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <InternationalPhoneInput
            label="Phone Number"
            name="phoneNumber"
            value={formState.phoneNumber}
            onChange={(value) => onFormValueChanged('phoneNumber', value)}
            error={errorState.phoneNumber}
            message={errorState.phoneNumber} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <OrgRoleTreeView
            label="Member Type or Member Role"
            name="orgRole"
            value={formState.orgRole}
            error={errorState.orgRole}
            message={errorState.orgRole}
            selectableLeavesOnly={false}
            singleSelect={true}
            onChange={(newValue) => { onFormValueChanged('orgRole', newValue) }}
            organizationId={formState.organizationId} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Current or Expired Membership?"
            name="isCurrent"
            options={isCurrentOptions}
            value={formState.isCurrentId}
            error={errorState.isCurrentId}
            message={errorState.isCurrentId}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'isCurrentId', newValueLabel, 'isCurrentName');
            }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <YesNoSwitch
            label="Include Only Non-Members?"
            name="nonOrganizationMember"
            checked={formState.nonOrganizationMember === true}
            error={errorState.nonOrganizationMember}
            message={errorState.nonOrganizationMember}
            onChange={(value) => { onFormValueChanged('nonOrganizationMember', value) }}
          />
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Sort Results By"
          name="sortById"
          options={sortByOptions}
          value={formState.sortById}
          error={errorState.sortById}
          message={errorState.sortById}
          isLoading={false}
          onChange={(newValue, newValueLabel, e) => {
            e && e.target && e.target.value &&
              onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
          }} />
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <button className={global.IconButton} type="button" onClick={() => onShowHideAdvancedSearch()}>
          {searchMemberState.showAdvanced === true ? "Hide Advanced Search" : "Show Advanced Search"}
        </button>
      </div>
    </div>
    {(errorState.filter || errorState.errorOnSubmitAction) &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>}
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Search for Member</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>&nbsp;
        {onCancelClicked !== undefined ? <Fragment><SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton>&nbsp;</Fragment> : <Fragment />}
      </div>
    </div>
  </form >
)

const SearchMember = ({ pageTitle, onEdit, searchMemberState, selectedPersonId, onCancelClicked }) => {
  const {
    formState,
    errorState,
    basePersonState,
    clearForm,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    competitionGenderOptions,
    isCurrentOptions,
    sortByOptions,
    onFiltersToggle,
    onShowHideAdvancedSearch,
    isLoading
  } = useSearchMember();
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      {searchMemberState !== undefined ?
        //toggles filters and results
        <Fragment>
          <div className={searchMemberState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
            <Form
              searchMemberState={searchMemberState}
              formState={formState}
              errorState={errorState}
              clearForm={clearForm}
              handleSubmit={handleSubmit}
              onFormValueChanged={onFormValueChanged}
              onValueTextPairChanged={onValueTextPairChanged}
              competitionGenderOptions={competitionGenderOptions}
              isCurrentOptions={isCurrentOptions}
              sortByOptions={sortByOptions}
              onShowHideAdvancedSearch={onShowHideAdvancedSearch}
              onCancelClicked={onCancelClicked}
            />
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchMemberGrid
                basePersonState={basePersonState}
                searchMemberState={searchMemberState}
                onEdit={onEdit}
                onFiltersToggle={onFiltersToggle}
                onCancelClicked={onCancelClicked}
                selectedPersonId={selectedPersonId}
                isLoading={isLoading}
              />
            </div>
          </div>
          {onCancelClicked ?
            <Fragment>
              <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                  <SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton>
                </div>
              </div>
            </Fragment> : <Fragment />}
        </Fragment>
        :
        //shows filters and results at the same time
        <Fragment>
          <Form
            searchMemberState={searchMemberState}
            formState={formState}
            errorState={errorState}
            clearForm={clearForm}
            handleSubmit={handleSubmit}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged}
            competitionGenderOptions={competitionGenderOptions}
            isCurrentOptions={isCurrentOptions}
            sortByOptions={sortByOptions}
            onShowHideAdvancedSearch={onShowHideAdvancedSearch}
            onCancelClicked={onCancelClicked}
          />
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchMemberGrid
                basePersonState={basePersonState}
                searchMemberState={searchMemberState}
                onEdit={onEdit}
                selectedPersonId={selectedPersonId}
                isLoading={isLoading}
              />
            </div>
          </div>
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  );
};

export default SearchMember;