import { isValidNumber } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Height
  if (formState.height !== '') {
    if (isValidNumber(formState.height) === false) {
      errors.height = 'Height must be a valid number';
    } else if (formState.height < 12 || formState.height > 96) {
      errors.height = 'Height is not in a valid range';
    }
  }

  //Weight
  const weight = parseInt(formState.weight);
  if (isValidNumber(weight) === false && isNaN(weight) === false) {
    errors.weight = 'Weight must be a valid number';
  }

  if (weight < 20 || weight > 500) {
    errors.weight = 'Weight is not in a valid range';
  }

  //High School Name
  if (formState.highSchoolName.trim().length > 0) {
    if (formState.highSchoolName.trim().length > 200) {
      errors.highSchoolName = 'A valid High School Name must be entered';
    }
  }

  //High School Graduation Year
  const graduationYear = parseInt(formState.highSchoolGraduationYear);
  if (isValidNumber(graduationYear) === false && isNaN(graduationYear) === false) {
    errors.highSchoolGraduationYear = 'A valid 4 digit year must be entered';
  }

  if (graduationYear < 1900 || graduationYear > 2500) {
    errors.highSchoolGraduationYear = 'A valid 4 digit year must be entered';
  }

  //High School City
  if (formState.highSchoolCity.trim().length > 0) {
    if (formState.highSchoolCity.trim().length > 200) {
      errors.highSchoolCity = 'A valid High School City must be entered';
    }
  }

  //College Name
  if (formState.college.trim().length > 0) {
    if (formState.college.trim().length > 200) {
      errors.college = 'A valid College Name must be entered';
    }
  }

  //College Graduation Year
  if (isValidNumber(formState.collegeGraduationYear) === false && formState.collegeGraduationYear.length > 0) {
    errors.collegeGraduationYear = 'A valid 4 digit year must be entered';
  }

  if ((formState.collegeGraduationYear < 1900 || formState.collegeGraduationYear > 2500) && formState.collegeGraduationYear.length > 0) {
    errors.collegeGraduationYear = 'A valid 4 digit year must be entered';
  }

  //hometown
  if (formState.hometown.trim().length > 0) {
    if (formState.hometown.trim().length > 200) {
      errors.hometown = 'A valid Hometown must be entered';
    }
  }

  //Birthplace
  if (formState.birthPlace.trim().length > 0) {
    if (formState.birthPlace.trim().length > 200) {
      errors.birthPlace = 'A valid Birthplace must be entered';
    }
  }

  return errors;
};

const ProfileBioInfoValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ProfileBioInfoValidation;