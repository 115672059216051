import React, { createContext, useState } from 'react';

import OfficialPositionData from './OfficialPositionData';

export const OfficialPositionStateContext = createContext();

const OfficialPositionContextProvider = ({ children }) => {
  const stateHook = useState(OfficialPositionData.INITIAL_STATE);

  return (
    <OfficialPositionStateContext.Provider value={stateHook}>
      {children}
    </OfficialPositionStateContext.Provider>
  );
};

export default OfficialPositionContextProvider;