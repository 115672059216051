import React, { Fragment } from 'react';

import useProfileCareerStats from './UseProfileCareerStats';

import ProfileCareerStatsGrid from './ProfileCareerStatsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

const ProfileCareerStats = () => {
  const {
    isLoading,
    isSaving,
    gridState,
    onCancelClicked,
    onUpdateClicked,
    onUpdateIncludeOnLaneSheet
  } = useProfileCareerStats();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Career Stats</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <ProfileCareerStatsGrid
            data={gridState}
            isLoading={isLoading}
            onUpdateIncludeOnLaneSheet={onUpdateIncludeOnLaneSheet} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onUpdateClicked}>Update</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default ProfileCareerStats;