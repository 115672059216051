import React, {useState, Fragment } from 'react';



import useQuickAddAthlete from './UseQuickAddAthlete';

import AthleteGrid from './AthleteGrid';
import AthleteConfirmationGrid from './AthleteConfirmationGrid';
import DupPersonGrid from './DupPersonGrid';

import QuickAddOrganizationDropdown from '../../../components/dropdowns/QuickAddOrganizationDropdown';

import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import CompetitionCategoryRadiobuttonList from '../../../../common/components/radiobuttonlists/competitionCategoryRadiobuttonList/CompetitionCategoryRadiobuttonList';
import OrgUnitCombobox from '../../../../common/components/comboboxes/orgUnitCombobox/OrgUnitCombobox';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';

import global from '../../../../common/components/GlobalStyle.module.css';

const DupeCheckContent = ({ formState, dupPersonState, onAddDupAthleteClicked, onCancelDupAthleteClicked, MALE_COMPETITION_GENDER_ID, FEMALE_COMPETITION_GENDER_ID }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <Headings.H3>Quick Add Person Duplicate Check</Headings.H3>
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <p>At least one person with the same First Name, Last Name, and Birth Date already exists in the database.</p>
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <DupPersonGrid state={dupPersonState} />
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <AthleteConfirmationGrid state={formState} MALE_COMPETITION_GENDER_ID={MALE_COMPETITION_GENDER_ID} FEMALE_COMPETITION_GENDER_ID={FEMALE_COMPETITION_GENDER_ID} />
      </div>
    </div>
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="button" onClick={onAddDupAthleteClicked}>Yes, Add Person</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={onCancelDupAthleteClicked}>Cancel</SecondaryButton>
      </div>
    </div>
  </Fragment>
);

const QuickAddAthlete = () => {
  const [page, setPage] = useState(1)
  const {
    Constants,
    organizationState,
    formState,
    errorState,
    setErrors,
    listState,
    dupPersonState,
    competitionGenderState,
    personGeneralState,
    onFormValueChanged,
    onValueTextPairChanged,
    onAddAthlete,
    resetFormInfo,
    onAddDupAthleteClicked,
    onCancelDupAthleteClicked,
    FINA_ORGANIZATION_ID,
    FINA_ORGANIZATION_NAME,
    NCAA_ORGANIZATION_ID,
    NCAA_ORGANIZATION_NAME,
    ISL_ORGANIZATION_NAME,
    MALE_COMPETITION_GENDER_ID,
    FEMALE_COMPETITION_GENDER_ID
  } = useQuickAddAthlete();

  return (
    <Fragment>
      {dupPersonState.arrayData.length > 0 ? (
        <DupeCheckContent
          formState={formState}
          dupPersonState={dupPersonState}
          onAddDupAthleteClicked={onAddDupAthleteClicked}
          onCancelDupAthleteClicked={onCancelDupAthleteClicked}
          MALE_COMPETITION_GENDER_ID={MALE_COMPETITION_GENDER_ID}
          FEMALE_COMPETITION_GENDER_ID={FEMALE_COMPETITION_GENDER_ID}
        />
      ) : (
        <Fragment>
          <form onSubmit={onAddAthlete} noValidate>
            <div className="row">
              <div className="col-xs-12">
                <Headings.H3>Quick Add Person</Headings.H3>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label="Legal First Name*"
                  name="firstName"
                  value={formState.firstName}
                  error={errorState.firstName}
                  message={errorState.firstName}
                  onChange={(value) => { onFormValueChanged('firstName', value) }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label="Legal Last Name*"
                  name="lastName"
                  value={formState.lastName}
                  error={errorState.lastName}
                  message={errorState.lastName}
                  onChange={(value) => { onFormValueChanged('lastName', value) }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <DatePicker
                  label="Birth Date*"
                  name="birthDate"
                  value={formState.birthDate}
                  error={errorState.birthDate}
                  message={errorState.birthDate}
                  onChange={(value) => { onFormValueChanged('birthDate', value); }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <YesNoSwitch
                  label="Is an Athlete?"
                  name="isAthlete"
                  checked={formState.isAthlete}
                  error={errorState.isAthlete}
                  message={errorState.isAthlete}
                  onChange={(value) => { onFormValueChanged('isAthlete', value); }} />
              </div>
              <div className={formState.isAthlete === true ? global.DisplayComponent : global.HideComponent}>
                <div className={"col-xs-12 col-sm-6 col-md-4"}>
                  <CompetitionCategoryRadiobuttonList
                    label="Competition Category*"
                    name="competitionGenderTypeId"
                    isVertical={false}
                    value={Number(formState.competitionGenderTypeId)}
                    error={errorState.competitionGenderTypeId}
                    message={errorState.competitionGenderTypeId}
                    onChange={(value) => { onFormValueChanged('competitionGenderTypeId', Number(value)); }}
                  />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <QuickAddOrganizationDropdown
                    label="Organization*"
                    name="organizationId"
                    value={formState.organizationId}
                    error={errorState.organizationId}
                    message={errorState.organizationId}
                    onChange={(newValue, newValueLabel, e) => {
                      onValueTextPairChanged(newValue, 'organizationId', newValueLabel, 'organizationName');
                      onValueTextPairChanged('', 'orgUnitId', '', 'orgUnitName', e);
                      setErrors({ ...errorState, orgUnitId: '' });
                    }} />
                </div>
                {formState.organizationId > 0 ?
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <OrgUnitCombobox
                      label={formState.orgUnitLabel}
                      name="orgUnitId"
                      valueToMatch={formState.orgUnitName}
                      error={errorState.orgUnitId}
                      message={errorState.orgUnitId}
                      onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'orgUnitId', newValueLabel, 'orgUnitName', e); }}
                      organizationId={formState.organizationId}
                      lscId={formState.lscId}
                    />
                  </div>
                  : <Fragment />
                }
                {formState.organizationName === NCAA_ORGANIZATION_NAME || formState.organizationName === ISL_ORGANIZATION_NAME ?
                  <Fragment>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                      <ReadOnly
                        label="Effective Date"
                        name="effectiveDate"
                        value={formState.effectiveDate}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                      <ReadOnly
                        label="Expiration Date"
                        name="expirationDate"
                        value={formState.expirationDate}
                      />
                    </div>
                  </Fragment>
                  : <Fragment />
                }
              </div>
            </div>
            {errorState.orgRoleStatus &&
              <div className="row">
                <div className="col-xs-12">
                  <p className={global.ErrorMessage}>{errorState.orgRoleStatus ? errorState.orgRoleStatus : ''}</p>
                </div>
              </div>
            }
            <div className="row usas-extra-top-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="submit">Add Person</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={resetFormInfo}>Clear Form</SecondaryButton>
              </div>
            </div>
          </form>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12">
              <AthleteGrid state={listState} FINA_ORGANIZATION_ID={FINA_ORGANIZATION_ID} FINA_ORGANIZATION_NAME={FINA_ORGANIZATION_NAME}
                NCAA_ORGANIZATION_ID={NCAA_ORGANIZATION_ID} NCAA_ORGANIZATION_NAME={NCAA_ORGANIZATION_NAME}
                ISL_ORGANIZATION_NAME={ISL_ORGANIZATION_NAME} />
            </div>
          </div>
        </Fragment>
      )
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={dupPersonState.isObjLoading || dupPersonState.isArrayLoading || organizationState.isLoading ||
          personGeneralState.isObjLoading || competitionGenderState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={dupPersonState.isSaving || personGeneralState.isSaving} />
    </Fragment >
  );
};

export default QuickAddAthlete;