import Constants from '../../../../../common/utils/Constants';
import { isValidDate } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  const validationStartDate = new Date(formState.startDate);
  const validationEndDate = new Date(formState.endDate);

  //Start Date
  if (formState.startDate === Constants.BLANK_DATE_STRING) {
    errors.startDate = 'Start Date Range is required';
  } else if (!isValidDate(formState.startDate)) {
    errors.startDate = 'Start Date Range must be a valid date';
  } else if (validationStartDate > validationEndDate) {
    errors.startDate = 'State Date must be before or equal to End Date'
  }

  //EndDate
  if (formState.endDate === Constants.BLANK_DATE_STRING) {
    errors.endDate = 'End Date Range is required';
  } else if (!isValidDate(formState.endDate)) {
    errors.endDate = 'End Date Range must be a valid date';
  } else if (validationStartDate > validationEndDate) {
    errors.endDate = 'End Date must be after or equal to Start Date'
  }

  return errors;
};

const OtsOfficialsMeetHistoryValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};

}

export default OtsOfficialsMeetHistoryValidation;