import { isValidDate } from '../../../../../../common/utils/validation';
import { getTodaysDate } from '../../../../../../common/utils/DateFunctions'
import { BLANK_DATE_STRING, DEFAULT_ID } from '../../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  const today = getTodaysDate();
  const todaysDate = new Date(today);
  const defaultEffectiveDate = new Date(formState.defaultEffectiveDate);
  const defaultExpirationDate = new Date(formState.defaultExpirationDate);
  const defaultRegStartDate = new Date(formState.defaultRegStartDate);
  const defaultRegEndDate = new Date(formState.defaultRegEndDate);

  let errors = {};

  // Offering Id
  if (formState.offeringId === DEFAULT_ID) {
    errors.offeringId = 'Offering is required';
  }

  // Fee Amount
  if (formState.feeAmount < 0) {
    errors.feeAmount = 'Fee Amount must be greater than or equal to 0'
  } else if (formState.feeAmount >= 10000) {
    errors.feeAmount = 'Fee Amount must be less than 10,000'
  }

  // Maximum LSC Fee Amount
  if (formState.maxLscFeeAmount !== null || formState.enableLscFeeAmount === true) {
    if (formState.maxLscFeeAmount < 0) {
      errors.maxLscFeeAmount = 'Maximum LSC Fee Amount must be greater than or equal to 0'
    } else if (formState.maxLscFeeAmount >= 10000) {
      errors.maxLscFeeAmount = 'Maximum LSC Fee Amount must be less than 10,000'
    }
  }

  // Registration Start Date
  if (formState.defaultRegStartDate === BLANK_DATE_STRING) {
    errors.defaultRegStartDate = 'Registration Start Date is required';
  } else if (!isValidDate(formState.defaultRegStartDate)) {
    errors.defaultRegStartDate = 'Registration Start Date must be a valid date'
  } else if (todaysDate > defaultRegStartDate) {
    errors.defaultRegStartDate = `Registration Start Date cannot be before today's date`
  } else if (formState.defaultRegStartDate === formState.defaultRegEndDate) {
    errors.defaultRegStartDate = 'Registration Start and Registration End Date cannot be the same date';
  } else if (defaultRegEndDate < defaultRegStartDate) {
    errors.defaultRegEndDate = 'Registration Start Date must be before the Registration End Date';
  }

  // Registration End Date
  if (formState.defaultRegEndDate === BLANK_DATE_STRING) {
    errors.defaultRegEndDate = 'Registration End Date is required';
  } else if (!isValidDate(formState.defaultRegEndDate)) {
    errors.defaultRegEndDate = 'Registration End Date must be a valid date'
  } else if (defaultRegEndDate < defaultRegStartDate) {
    errors.defaultRegEndDate = 'Registration End Date must be after the Registration Start Date';
  }

  // Effective Date
  if (formState.defaultEffectiveDate === BLANK_DATE_STRING) {
    errors.defaultEffectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.defaultEffectiveDate)) {
    errors.defaultEffectiveDate = 'Effective Date must be a valid date'
  } else if (formState.defaultExpirationDate === formState.defaultEffectiveDate) {
    errors.defaultEffectiveDate = 'Effective Date and Expiration Date cannot be the same date';
  } else if (defaultExpirationDate < defaultEffectiveDate) {
    errors.defaultEffectiveDate = 'Effective Date must be before the Expiration Date';
  }

  // Expiration Date
  if (formState.defaultExpirationDate === BLANK_DATE_STRING) {
    errors.defaultExpirationDate = 'Expiration Date is required';
  } else if (!isValidDate(formState.defaultExpirationDate)) {
    errors.defaultExpirationDate = 'Expiration Date must be a valid date'
  } else if (defaultExpirationDate < defaultEffectiveDate) {
    errors.defaultExpirationDate = 'Expiration Date must be after the Effective Date';
  }

  return errors;
};

const OfferingInstanceAddFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OfferingInstanceAddFormValidation;