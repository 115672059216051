import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../../common/wrappers/ReactRouterDom';

import useOtsCertificationRuleData from '../../../../../state/otsCertificationRule/UseOtsCertificationRuleData';

import useNavRoutes from '../../../../../../common/state/security/UseNavRoutes';

const useStatus = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const { navRoutes } = useNavRoutes();
  const [ruleData, setRuleData] = useState([]);
  const [positionCertificationProgression, setPositionCertificationProgression] = useState({});
  const { getOtsCertificationRulesForPersonProgression } = useOtsCertificationRuleData();

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(navRoutes.OTS_OFFICIAL_NATIONALCERTIFICATIONAPPLICATIONHISTORY?.route)
  };

  useEffect(() => {
    getOtsCertificationRulesForPersonProgression(location.state?.personPositionCertificationProgressionId)
      .then(data => {
        setRuleData({ ...ruleData, positionCertificationRule: data.arrayData?.positionCertificationRule || '' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.personPositionCertificationProgressionId]);

  useEffect(() => {
    if (location.state?.position) {
      setPositionCertificationProgression({ ...location.state.position });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.position]);

  return {
    personPositionCertificationProgressionId: location.state?.personPositionCertificationProgressionId,
    certificationPublicNote: location.state?.certificationPublicNote,
    publicNotes: location.state?.publicNotes,
    position: location.state?.publicNotes,
    onCancelClicked,
    ruleData,
    positionCertificationProgression
  };
};

export default useStatus;