import { useEffect } from 'react';

import useOtsPositionWorkedEvaluationLevelEvaluatorNameData from '../../../state/otsPositionWorkedEvaluationLevelEvaluatorName/UseOtsPositionWorkedEvaluationLevelEvaluatorNameData';

const useOtsPositionWorkedCombobox = (otsMeetId) => {
  const { otsPositionWorkedEvaluationLevelEvaluatorNameState, getOtsPositionWorkedEvaluationLevelEvaluatorNamesByOtsMeetId } = useOtsPositionWorkedEvaluationLevelEvaluatorNameData();

  useEffect(() => {
    if (otsPositionWorkedEvaluationLevelEvaluatorNameState.isArrayLoading !== true && (otsPositionWorkedEvaluationLevelEvaluatorNameState.isArrayLoaded !== true ||
      (otsMeetId !== otsPositionWorkedEvaluationLevelEvaluatorNameState.otsMeetId))) {
      getOtsPositionWorkedEvaluationLevelEvaluatorNamesByOtsMeetId(otsMeetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsPositionWorkedEvaluationLevelEvaluatorNameState, otsMeetId]);

  return { otsPositionWorkedEvaluationLevelEvaluatorNameState, getOtsPositionWorkedEvaluationLevelEvaluatorNamesByOtsMeetId };
};

export default useOtsPositionWorkedCombobox;