import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import LSCReportingSelection from './lscReportingSelection/LSCReportingSelection';
import LSCReportingReports from './lscReportingReports/LSCReportingReports';
import LSCReportingHQSelection from './lscReportingHqSelection/LSCReportingHQSelection';
import LSCReportingHQReports from './lscReportingHqReports/LSCReportingHQReports';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';

const LSCReportingRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['LSC_REPORTING_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['LSC_REPORTING_SELECTION']}
            editElement={<LSCReportingSelection />} />
        }
      />
      <Route path={navRoutes['LSC_REPORTING_REPORTS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['LSC_REPORTING_REPORTS']}
            editElement={<LSCReportingReports />} />
        }
      />
      <Route path={navRoutes['LSC_REPORTING_HQ_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['LSC_REPORTING_HQ_SELECTION']}
            editElement={<LSCReportingHQSelection />} />
        }
      />
      <Route path={navRoutes['LSC_REPORTING_HQ_REPORTS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['LSC_REPORTING_HQ_REPORTS']}
            editElement={<LSCReportingHQReports />} />
        }
      />

      <Route path={'/*'} element={navRoutes['LSC_REPORTING_HQ_REPORTS']?.route ? <Navigate to={navRoutes['LSC_REPORTING_HQ_REPORTS']?.route} replace /> :
        <Navigate to={navRoutes['LSC_REPORTING_REPORTS']?.route} replace />} />
      <Route path={'/'} element={navRoutes['LSC_REPORTING_HQ_SELECTION']?.route ? <Navigate to={navRoutes['LSC_REPORTING_HQ_SELECTION']?.route} replace /> :
        <Navigate to={navRoutes['LSC_REPORTING_SELECTION']?.route} replace />} />
    </Routes>
  );
}

const LSCReporting = () => (
  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <LSCReportingRoutes />
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>
);

export default LSCReporting;