import { useEffect, useState } from 'react';
import useOtsCertificationApplicationData from '../../../../state/otsCertificationApplication/UseOtsCertificationApplicationData';
import useSecurityData from '../../../../../common/state/security/UseSecurityData';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

const useCertificationReviewSearch = () => {
  const { navRoutes } = useNavRoutes();
  const navigate = useNavigate();
  const [state, setState] = useState({ tryRedirectToOfficial: false });
  const { securityState } = useSecurityData();
  const { getOfficialsWithActiveNationalApplication,
    otsCertificationApplicationContextState, setOtsCertificationApplicationContextState }
    = useOtsCertificationApplicationData();

  const initializeView = () => {
    getOfficialsWithActiveNationalApplication()
      .then(data => {
        if (Array.isArray(data.arrayData)) {
          const filteredActiveOfficials = data.arrayData.filter(x => x.memberId !== securityState.userInfo.memberId);
          setOtsCertificationApplicationContextState({
            ...otsCertificationApplicationContextState,
            personId: '',
            personPositionCertificationRequestId: '',
            memberId: '',
            officialName: '',
            personPositionCertificationProgressionId: -1,
            personPositionCertificationProgression: {},
            activeOfficialsWithApplications: filteredActiveOfficials,
            isLoading: false
          });
        }
      });
  };

  const onSelectClicked = (official) => {
    setOtsCertificationApplicationContextState({
      ...otsCertificationApplicationContextState,
      personId: official.personId,
      personPositionCertificationRequestId: official.personPositionCertificationRequestId,
      memberId: official.memberId,
      officialName: `${official.preferredName || official.firstName} ${official.lastName}`,
    });
    setState({ ...state, tryRedirectToOfficial: true });
  };

  useEffect(() => {
    initializeView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.tryRedirectToOfficial === true) {
      if (otsCertificationApplicationContextState.personId &&
        otsCertificationApplicationContextState.personPositionCertificationRequestId &&
        otsCertificationApplicationContextState.memberId &&
        otsCertificationApplicationContextState.officialName) {
        navigate(navRoutes.OTS_CERT_APP_WORKFLOW_OFFICIAL?.route);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, otsCertificationApplicationContextState]);

  return {
    ...otsCertificationApplicationContextState,
    buttonClickHandlers: {
      onSelectClicked,
    }
  };
};

export default useCertificationReviewSearch;