import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './NationalTeamAttachmentDetailValidation';

import useNationalTeamAttachmentFilesData from '../../../state/nationalTeamAttachmentFiles/UseNationalTeamAttachmentFilesData';
import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';
const SAVING_ERROR_MSG = 'The last action is still saving. Please try again.';

const INITIAL_FORM_STATE = {
  fileTypeId: Constants?.DEFAULT_ID,
  fileTypeName: '',
  attachmentDescription: '',
  fileName: '',
  attachmentUrl: '',
  personNationalAttachmentId: Constants?.DEFAULT_ID,
};

const INITIAL_REMOVE_FILE_MODAL_STATE = {
  displayPopUp: false,
  fileName: '',
  fileType: '',
  modalTitle: 'Remove Uploaded File?'
};

const INITIAL_VIEW_STATE = {
  tryRedirect: false,
  tryRemove: false,
  error: '',
  errorAttachmentMessage: '',
  attachmentId: Constants.DEFAULT_ID,
  localNTAttachmentState: {}
};

const useNationalTeamAttachmentDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { formState, errorState, handleSubmit, onValueTextPairChanged, onFormValueChanged, setFormData, setFormState, setIsDirty, isDirty} = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [fileState, setFileState] = useState({ templateFileUrl: '', templateFileName: '', fileType: '' });
  const [removeFileModalState, setRemoveFileModalState] = useState(INITIAL_REMOVE_FILE_MODAL_STATE);
  const { nationalTeamAttachmentFilesState, postNationalTeamAttachmentFiles, getFileNameFromSavedUploadUrl, GET_MEMBER_FILES_NATIONAL_TEAM_ATTACHMENT_PATH } = useNationalTeamAttachmentFilesData();
  const { personNationalTeamAttachmentState, putPersonNationalTeamAttachmentById, postPersonNationalTeamAttachment } = usePersonNationalTeamData();

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_ATTACHMENTS?.route);
  };


  function createPostObject() {
    //Is Add
    const personId = basePersonState?.objData?.personId;
    let postObj = {};
    postObj = {
      attachmentDescription: formState?.attachmentDescription,
      attachmentName: formState?.fileName,
      attachmentUrl: fileState?.templateFileUrl,
      personNationalAttachmentFileTypeId: formState?.fileTypeId,
      personNationalAttachmentFileTypeName: formState?.fileTypeName,
      // templateFileName: fileState.templateFileName,
      personId: personId
    };

    return postObj;
  }

  function submitFormCallback() {
    if (personNationalTeamAttachmentState.isSaving === false) {
      const personToPut = createPutPersonNationalTeamAttachmentObject();
      if (personToPut !== null && state?.attachmentId > 0) {
        const person = personToPut?.arrayData?.find(x => x.personNationalAttachmentId === state?.attachmentId);
        const id = state?.attachmentId;

        putPersonNationalTeamAttachmentById(id, person);
        setState({ ...state, tryRedirect: true });

      }
      else {

        postPersonNationalTeamAttachment(createPostObject());
        setState({ ...state, tryRedirect: true });
      }
    }
  }

  const setRemoveAttachmentFileType = (e, fileType) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...removeFileModalState, fileType: fileType });
  };

  const onUploadTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (personNationalTeamAttachmentState.isSaving === true || nationalTeamAttachmentFilesState.isSaving === true) {
      setState({ ...state, tryRedirect: false, error: SAVING_ERROR_MSG });
    } else {

      setState({ ...state, tryRedirect: false, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;

        setRemoveAttachmentFileType(e, fileType);
        if (fileType === Constants?.FILE_TYPE_JPG || fileType === Constants.FILE_TYPE_PDF
          || fileType === Constants.FILE_TYPE_DOC || fileType === Constants.FILE_TYPE_DOCX) {

          postNationalTeamAttachmentFiles(file);
          setState({ ...state, tryRedirect: false, error: '' });
        }
        else {

          setState({ ...state, tryRedirect: false, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  };

  const onRemoveTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...INITIAL_REMOVE_FILE_MODAL_STATE });

    if (personNationalTeamAttachmentState.isSaving === true || nationalTeamAttachmentFilesState.isSaving === true) {

      setState({ ...state, tryRedirect: false, error: SAVING_ERROR_MSG });
    } else {

      setFileState({ ...fileState, templateFileUrl: '', templateFileName: '' });
      setState({ ...state, tryRedirect: false, tryRemove: true, error: '' });
      setAttachmentUrlFormState(fileState);
      setIsDirty(true);
      
      navigate(navRoutes.MEMBER_NT_ATTACHMENTS_DETAIL?.route);
    }
  };


  function createPutPersonNationalTeamAttachmentObject() {
    const personNationalTeamAttachmentToPut = JSON.parse(JSON.stringify(location?.state?.personNationalTeamAttachmentState));
    const personId = basePersonState?.objData?.personId;

    // Is Edit
    if (state?.attachmentId > 0) {
      const index = personNationalTeamAttachmentToPut.arrayData.findIndex(x => x.personNationalAttachmentId === state?.attachmentId);
      personNationalTeamAttachmentToPut.arrayData[index] = {
        ...personNationalTeamAttachmentToPut.arrayData[index],
        attachmentDescription: formState?.attachmentDescription,
        attachmentName: formState?.fileName,
        attachmentUrl: formState?.attachmentUrl,
        personNationalAttachmentFileTypeId: formState?.fileTypeId,
        personNationalAttachmentFileTypeName: formState?.fileTypeName,
        personNationalAttachmentId: formState?.personNationalAttachmentId,
        personId: personId
      };
    }

    return personNationalTeamAttachmentToPut;
  }


  const getFileType = () => {
    let fileType = ''
    if (removeFileModalState?.fileType === '') {
      const attachmentObj = state?.localNTAttachmentState?.arrayData?.find(x => x.personNationalAttachmentId === state?.attachmentId);
      fileType = attachmentObj?.personNationalAttachmentFileTypeName
    } else {
      fileType = removeFileModalState?.fileType
    }
    return fileType
  }


  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({
      ...removeFileModalState,
      fileName: fileState.templateFileName,
      fileType: getFileType(),
      displayPopUp: true
    });
  };

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...INITIAL_REMOVE_FILE_MODAL_STATE });
  };

  const onDownloadUploadedFile = (event) => {
    event.preventDefault();
    const fileUrl = GET_MEMBER_FILES_NATIONAL_TEAM_ATTACHMENT_PATH + fileState.templateFileUrl;
    PersonHttpHelper(fileUrl, 'GET', null, false, true)
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...state,
          message: message
        });
      });

    //window.location.href = import.meta.env.VITE_PERSONAPI_URL + GET_MEMBER_FILES_NATIONAL_TEAM_ATTACHMENT_PATH + fileUrl;
  };

  function getPhotoUrlFromPersonNationalTeamAttachmentState(personNTAttachmentId) {
    const personNationalTeamState = location?.state?.personNationalTeamAttachmentState;
    const personNationalTeamArray = personNationalTeamState.arrayData;
    const selectedAttachmentItem = personNationalTeamArray?.find(e => e.personNationalAttachmentId === personNTAttachmentId);
    const photoUrl = selectedAttachmentItem !== undefined ? selectedAttachmentItem.attachmentUrl : '';
    setFileState({
      ...fileState,
      templateFileUrl: photoUrl,
      templateFileName: selectedAttachmentItem?.attachmentName
    });
  }


  const setAttachmentUrlFormState = (fileState) => {

    const getFileAttachment = (fileState) => {
      let fileAttachment;

      if (fileState === undefined) {
        fileAttachment = ''
      } else {
        fileAttachment = fileState?.uploadUrl
      }
      return fileAttachment
    }

    setFormState({ ...formState, attachmentUrl: getFileAttachment(fileState) });
  };

  useEffect(() => {
    if (nationalTeamAttachmentFilesState?.isSaved === true && Object?.keys(nationalTeamAttachmentFilesState?.objData)?.length > 0) {
      setFileState({ ...fileState, templateFileUrl: nationalTeamAttachmentFilesState.objData.uploadUrl, templateFileName: getFileNameFromSavedUploadUrl() });
      setAttachmentUrlFormState(nationalTeamAttachmentFilesState.objData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamAttachmentFilesState]);


  useEffect(() => {
    if (location?.state?.attachmentId > 0) {
      const personNTAttachmentId = location?.state?.attachmentId;
      const personNationalTeamState = location?.state?.personNationalTeamAttachmentState;
      const selectedAttachment = personNationalTeamState?.arrayData?.find(x => x.personNationalAttachmentId === personNTAttachmentId);
      getPhotoUrlFromPersonNationalTeamAttachmentState(personNTAttachmentId);
      setFormData({
        ...formState,
        attachmentDescription: selectedAttachment?.attachmentDescription,
        fileName: selectedAttachment?.attachmentName,
        attachmentUrl: selectedAttachment?.attachmentUrl,
        fileTypeId: selectedAttachment?.personNationalAttachmentFileTypeId,
        fileTypeName: selectedAttachment?.personNationalAttachmentFileTypeName,
        personNationalAttachmentId: selectedAttachment?.personNationalAttachmentId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.state && location?.state?.attachmentId > Constants?.DEFAULT_ID) {
      const attachmentId = location?.state?.attachmentId;
      const personNationalTeamAttachmentState = location?.state?.personNationalTeamAttachmentState
      setState({
        ...state,
        attachmentId: attachmentId,
        localNTAttachmentState: personNationalTeamAttachmentState
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (personNationalTeamAttachmentState.isSaving === false && state.tryRedirect === true) {
      navigate(navRoutes.MEMBER_NT_ATTACHMENTS?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personNationalTeamAttachmentState.isSaving, state.tryRedirect]);

  return {
    formState,
    errorState,
    fileState,
    state,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    onDownloadUploadedFile,
    onUploadTemplateClicked,
    onOpenRemoveFileModalClicked,
    removeFileModalState,
    onRemoveFileModalCanceled,
    onRemoveTemplateClicked,
    isSaving: personNationalTeamAttachmentState.isSaving || nationalTeamAttachmentFilesState.isSaving,
    personNationalTeamAttachmentState,
    nationalTeamAttachmentFilesState,
    isDirty
  };
}

export default useNationalTeamAttachmentDetail;
