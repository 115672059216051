import React, { Fragment } from "react";

import HQCommitteeMembersGridLarge from "./HQCommitteeMembersGridLarge";
import HQCommitteeMembersGridSmall from "./HQCommitteeMembersGridSmall";

import useExpandedRow from "../../../../common/components/grids/UseExpandedRow";

const HQCommitteeMembersGrid = ({ data, isLoading, onEdit, onDelete, formState }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <HQCommitteeMembersGridLarge
        data={data}
        isLoading={isLoading}
        onEdit={onEdit}
        onDelete={onDelete}
        formState={formState}
        expandedId={expandedId}
        onExpandClicked={onClick} />
      <HQCommitteeMembersGridSmall
        data={data}
        isLoading={isLoading}
        onEdit={onEdit}
        onDelete={onDelete}
        formState={formState}
        expandedId={expandedId}
        onExpandClicked={onClick} />
    </Fragment>
  );
};
export default HQCommitteeMembersGrid;