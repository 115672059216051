import Constants from '../../../utils/Constants';

export const CampRegistrationFormValidation = (formState) => {
  let errors = {};

  if (formState.groupAttendeeTypeId === Constants.DEFAULT_ID || formState.groupAttendeeTypeId === '') {
    errors.groupAttendeeTypeId = 'Attendee Type is required';
  }

  if (formState.coachOfRecordId === Constants.DEFAULT_ID || formState.coachOfRecordId === '') {
    errors.coachOfRecordId = 'Coach of Record is required';
  }

  return errors;
};

export default CampRegistrationFormValidation;