import { useContext, useEffect } from 'react';
import { DisabilityTypeStateContext } from './DisabilityTypeContextProvider';

import DisabilityTypeData from './DisabilityTypeData';

const useDisabilityTypeData = () => {
  const [disabilityTypeState, setDisabilityTypeState] = useContext(DisabilityTypeStateContext);

  const getDisabilityTypes = () => {
    DisabilityTypeData.getDisabilityTypes(disabilityTypeState, setDisabilityTypeState);
  };
  
  const createHierarchicalDataAsOptions = () => {
    //define compare for object sort
    const compareDisplayOrder = (a, b) => {
      if (a.displayOrder < b.displayOrder) return -1;
      if (a.displayOrder > b.displayOrder) return 1;
      return 0;  //equal
    };
    
    if (Array.isArray(disabilityTypeState.arrayData)) {
      const parentTypes = disabilityTypeState.arrayData
      .filter(f => f.parentId === null)
      .map(x => {
        return {
          id: x.disabilityTypeId,
          name: x.typeName,
          displayOrder: x.displayOrder,
          isAthleteDisabilityType: x.isAthleteDisabilityType,
          isNonAthleteDisabilityType: x.isNonAthleteDisabilityType
        };
      })
      .sort(compareDisplayOrder);
      
      for (let i = 0; i < parentTypes.length; i++) {
        const parentType = parentTypes[i];
        if (parentType) {
          const childObjects = disabilityTypeState.arrayData
          .filter(f => f.parentId === parentType.id)
          .map(x => {
            return {
              id: x.disabilityTypeId,
              name: x.typeName,
              displayOrder: x.displayOrder,
              isAthleteDisabilityType: x.isAthleteDisabilityType,
              isNonAthleteDisabilityType: x.isNonAthleteDisabilityType
            };
          })
          .sort(compareDisplayOrder);
          parentType.childObjects = childObjects;
        }
      }
      return parentTypes;
    }
    return [];
  }

  useEffect(() => {
    if (disabilityTypeState.isArrayLoaded === true
      && disabilityTypeState.isArrayLoading !== true
      && Array.isArray(disabilityTypeState.arrayData)
      && disabilityTypeState.arrayData.length > 0
      && (!Array.isArray(disabilityTypeState.hierarchicalOptions)
        || disabilityTypeState.hierarchicalOptions.length === 0)) {
      const hierarchicalOptions = createHierarchicalDataAsOptions();
      setDisabilityTypeState({
        ...disabilityTypeState,
        hierarchicalOptions
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabilityTypeState]);

  return {
    disabilityTypeState,
    getDisabilityTypes,
    setDisabilityTypeState
  };
};

export default useDisabilityTypeData;