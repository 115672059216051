import { Fragment } from 'react';

import useCampRegistrationForm from './UseCampRegistrationForm';

import GroupPersonCoachesDropdown from '../../dropdowns/groupPersonCoachesDropdown/GroupPersonCoachesDropdown';
import Dropdown from '../../dropdowns/Dropdown';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import PopUpModal from '../../dialogs/PopUpModal';

import Constants from '../../../utils/Constants';

import global from '../../GlobalStyle.module.css';

const CampRegistrationForm = ({ personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj,
  primaryButtonText = 'Save', secondaryButtonText = 'Back', disableValidation = false, onSecondaryButtonClicked,
  onFormCompletion }) => {
  const {
    isLoading,
    formState,
    errorState,
    optionsState,
    handleSubmit,
    onValueTextPairChanged
  } = useCampRegistrationForm(personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj, disableValidation, onFormCompletion);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label={disableValidation === true ? 'Attendee Type' : 'Attendee Type*'}
              name="groupAttendeeTypeId"
              value={formState.groupAttendeeTypeId}
              error={errorState.groupAttendeeTypeId}
              message={errorState.groupAttendeeTypeId}
              areValuesIntegers={false}
              isLoading={optionsState.areOptionsLoaded !== true}
              options={optionsState.attendTypeOptions}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'groupAttendeeTypeId', newValueLabel, 'groupAttendeeTypeName'); }} />
          </div>
          {personId &&
            <div className="col-xs-12 col-sm-6 col-md-4">
              <GroupPersonCoachesDropdown
                label={disableValidation === true ? 'Coach of Record' : 'Coach of Record*'}
                name="coachOfRecordId"
                value={formState.coachOfRecordId}
                error={errorState.coachOfRecordId}
                message={errorState.coachOfRecordId}
                personId={personId}
                onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'coachOfRecordId', newValueLabel, 'coachOfRecordName'); }} />
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
            {errorState && errorState.errorOnSubmitAction
              && <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction}</p>
            }
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default CampRegistrationForm;