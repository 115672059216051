import React, { Fragment } from 'react';

import useMemberAdministrationSelection from './UseMemberAdministrationSelection';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

const MemberAdministrationSelection = () => {
  const {
    isLoading
  } = useMemberAdministrationSelection();

  return (
    <Fragment>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default MemberAdministrationSelection;