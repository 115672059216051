import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from '../../Member.module.css';

const DetailGridRowIcons = ({ role, orgUnitId, onEdit }) => (
  <Fragment>
    {role.personClubTransferId > 0
      ? (
        <button className={global.IconButton} type='button' onClick={(e) => onEdit(e, role.personOrgRoleDurationId, orgUnitId)}>
          <span className={style.SmallGridButtonText}>Review Transfer</span>
        </button>
      )
      : role.isTransferEligible === true
        ? (
          <button className={global.IconButton} type='button' onClick={(e) => onEdit(e, role.personOrgRoleDurationId, orgUnitId)}>
            <span className={style.SmallGridButtonText}>Initiate Transfer</span>
          </button>
        )
        : <Fragment />
    }
  </Fragment>
);

const DetailGridRow = ({ role, orgUnitId, onEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{role.orgRoleName || ''}</span>&nbsp;
      <DetailGridRowIcons role={role} orgUnitId={orgUnitId} onEdit={onEdit} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Member Role
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {role.orgRoleName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.effectiveDate ? formatDate(role.effectiveDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.expirationDate ? formatDate(role.expirationDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Status
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {role.personClubTransferId > 0
            ? 'Transfer Pending'
            : role.isTransferEligible === true
              ? 'Transfer Eligible'
              : 'Transfer Ineligible'
          }
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ personRoles, orgUnitId, onEdit }) => (
  <div className={global.SmallTableDetailTable}>
    {personRoles.map((role, i) => <DetailGridRow key={i} role={role} orgUnitId={orgUnitId} onEdit={onEdit} />)}
  </div>
);

const GridRowIcons = ({ club, expandedId, onExpandClicked }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, club.orgUnitId)}>
      {expandedId === club.orgUnitId ? <HideIcon /> : <ShowIcon />}
    </button>
  </Fragment>
);

const GridRow = ({ club, expandedId, onEdit, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{club.orgUnitName || ''}</span>&nbsp;
        <GridRowIcons club={club} expandedId={expandedId} onExpandClicked={onExpandClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Club
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {club.orgUnitName || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    {Array.isArray(club.personRoles) && club.personRoles.length > 0 && expandedId === club.orgUnitId
      && <DetailGrid personRoles={club.personRoles} orgUnitId={club.orgUnitId} onEdit={onEdit} />
    }
  </Fragment>
);

const MemberClubTransferMemberGridSmall = ({ gridData, expandedId, isLoading, onEdit, onExpandClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
            &nbsp;&nbsp;Loading...
          </div>
        </div>
      )
      : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((club, i) => <GridRow key={i} club={club} expandedId={expandedId} onEdit={onEdit} onExpandClicked={onExpandClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
              &nbsp;&nbsp;No Results
            </div>
          </div>
        )
    }
  </div>
);

export default MemberClubTransferMemberGridSmall;