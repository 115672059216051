import React, { Fragment } from 'react';

import useMyOtsMeets from './UseMyOtsMeets';

import SearchOtsMeetGrid from '../../../../components/searchOfficialsTrackingSystemMeet/SearchOtsMeetGrid';

import Headings from '../../../../../common/components/headings/Headings';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const MyOtsMeets = () => {
  const { otsMeetState, searchOtsMeetState, otsMeetHeaderState, contextSecurityState,
    onPostOtsMeet, onEditOtsMeet, formState, errorState, onFormValueChanged, onValueTextPairChanged,
    onFilterClicked, onClearFilterClicked, sortByOptions } = useMyOtsMeets();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>My OTS Meets</Headings.H3>
        </div>
      </div>
      <form>
        <div className='row'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker label="Meet Start Date Range"
              name="startDate"
              value={formState.startDate}
              error={errorState.startDate}
              message={errorState.startDate}
              onChange={(value) => { onFormValueChanged('startDate', value); }}
              countOfYears={150}
              startYearOffset={-149} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker label="Meet End Date Range"
              name="endDate"
              value={formState.endDate}
              error={errorState.endDate}
              message={errorState.endDate}
              onChange={(value) => { onFormValueChanged('endDate', value); }}
              countOfYears={150}
              startYearOffset={-149} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Sort Results By"
              name="sortById"
              options={sortByOptions}
              value={formState.sortById}
              error={errorState.sortById}
              message={errorState.sortById}
              isLoading={false}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
              }} />
          </div>
          {(errorState.filter || errorState.errorOnSubmitAction) &&
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>}
          <div className='col-xs-6 col-sm-2 col-md-1'>
            <button className={global.PlainButton} onClick={(e) => onFilterClicked(e)}>Filter</button>
          </div>
          <div className='col-xs-6 col-sm-4 col-md-2'>
            <button className={global.PlainButton} onClick={(e) => onClearFilterClicked(e)}>Clear Filter</button>
          </div>
        </div>
      </form>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <SearchOtsMeetGrid
            searchOtsMeetState={searchOtsMeetState}
            otsMeetState={otsMeetState}
            onPost={onPostOtsMeet}
            onEdit={onEditOtsMeet}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={otsMeetState.isArrayLoading || otsMeetState.isObjLoading ||
          otsMeetHeaderState.isObjLoading || contextSecurityState.isObjLoading} />
    </Fragment >
  );
};

export default MyOtsMeets;