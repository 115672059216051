import { useContext } from "react";

import { OfferingManagementInstanceOrgUnitStateContext } from "./OfferingManagementInstanceOrgUnitContextProvider";

import OfferingManagementInstanceOrgUnitData from "./OfferingManagementInstanceOrgUnitData";

const useOfferingManagementInstanceOrgUnitData = () => {
  const [offeringManagementInstanceOrgUnitState, setOfferingManagementInstanceOrgUnitState] = useContext(OfferingManagementInstanceOrgUnitStateContext);

  const postOfferingInstanceOrgUnit = (offeringInstanceOrgUnitObj) => {
    return OfferingManagementInstanceOrgUnitData.postOfferingInstanceOrgUnitData(offeringInstanceOrgUnitObj, offeringManagementInstanceOrgUnitState, setOfferingManagementInstanceOrgUnitState);
  };

  const getOfferingInstanceOrgUnits = (orgUnitId, reportPeriodId) => {
    // Note: objData is used exclusively for POST/PUT/DELETE,
    // Should be cleared when getting new arrayData context
    const newOfferingManagementInstanceOrgUnitState = {
      ...offeringManagementInstanceOrgUnitState,
      isObjLoaded: false,
      isObjLoading: false,
      objData: {}
    };

    return OfferingManagementInstanceOrgUnitData.getOfferingInstancesOrgUnitData(orgUnitId, reportPeriodId, newOfferingManagementInstanceOrgUnitState, setOfferingManagementInstanceOrgUnitState);
  };

  const putOfferingInstanceOrgUnit = (offeringInstanceOrgUnitId, offeringInstanceOrgUnitObj) => {
    return OfferingManagementInstanceOrgUnitData.putOfferingInstanceOrgUnitData(offeringInstanceOrgUnitId, offeringInstanceOrgUnitObj, offeringManagementInstanceOrgUnitState, setOfferingManagementInstanceOrgUnitState);
  };

  const deleteOfferingInstanceOrgUnit = (offeringInstanceOrgUnitId) => {
    return OfferingManagementInstanceOrgUnitData.deleteOfferingInstanceOrgUnitData(offeringInstanceOrgUnitId, offeringManagementInstanceOrgUnitState, setOfferingManagementInstanceOrgUnitState);
  };

  const resetOfferingManagementInstanceOrgUnitState = () => {
    setOfferingManagementInstanceOrgUnitState({
      ...OfferingManagementInstanceOrgUnitData.INITIAL_OFFERING_MANAGEMENT_INSTANCE_ORG_UNIT_STATE
    });
  };

  const clearArrayData = () => {
    setOfferingManagementInstanceOrgUnitState({
      ...offeringManagementInstanceOrgUnitState,
      orgUnitId: '',
      isArrayLoading: false,
      isArrayLoaded: false,
      arrayData: []
    });
  };

  return {
    offeringManagementInstanceOrgUnitState,

    postOfferingInstanceOrgUnit,
    getOfferingInstanceOrgUnits,
    putOfferingInstanceOrgUnit,
    deleteOfferingInstanceOrgUnit,

    resetOfferingManagementInstanceOrgUnitState,
    clearArrayData
  };
};

export default useOfferingManagementInstanceOrgUnitData;