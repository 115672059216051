import { useState } from 'react';

import PersonFilesProfilePhotoData from './PersonFilesProfilePhotoData';

const usePersonFilesProfilePhotoData = () => {
  const [personProfilePhotoUploadState, setPersonProfilePhotoUploadState] = useState(PersonFilesProfilePhotoData.PERSON_PROFILE_PHOTO_UPLOAD_INITIAL_STATE);
  const [personProfilePhotoDownloadState, setPersonProfilePhotoDownloadState] = useState(PersonFilesProfilePhotoData.PERSON_PROFILE_PHOTO_DOWNLOAD_INITIAL_STATE)

  const postMemberFilesProfilePhoto = (file) => {
    PersonFilesProfilePhotoData.postPersonFilesProfilePhotoData(file, personProfilePhotoUploadState, setPersonProfilePhotoUploadState);
  };

  const getPersonProfilePhoto = (fileUrl) => {
    return PersonFilesProfilePhotoData.getPersonProfilePhotoData(fileUrl, personProfilePhotoDownloadState, setPersonProfilePhotoDownloadState);
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(PersonFilesProfilePhotoData.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);

    return decodeURI(fileName) || '';
  };

  return {
    personProfilePhotoUploadState,
    personProfilePhotoDownloadState,
    postMemberFilesProfilePhoto,
    getPersonProfilePhoto,
    getFileNameFromUrl,
  };
};

export default usePersonFilesProfilePhotoData;