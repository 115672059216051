import React, { createContext, useState } from 'react';

import IneligibleReasonData from './IneligibleReasonData';

export const IneligibleReasonStateContext = createContext();

const IneligibleReasonContextProvider = ({ children }) => {
  const stateHook = useState(IneligibleReasonData.INITIAL_STATE);

  return (
    <IneligibleReasonStateContext.Provider value={stateHook}>
      {children}
    </IneligibleReasonStateContext.Provider>
  );
};

export default IneligibleReasonContextProvider;