import React, { Fragment } from 'react';

import useNationalTeamAudit from './UseNationalTeamAudit';

import NationalTeamAuditGrid from './NationalTeamAuditGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const NationalTeamAudit = () => {
  const { title, Constants, personNationalTeamAuditState, basePersonState } = useNationalTeamAudit();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{title}</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <NationalTeamAuditGrid state={personNationalTeamAuditState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || personNationalTeamAuditState.isLoading} />
    </Fragment>
  );
};

export default NationalTeamAudit;