import { PersonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const GetPersonSwimTimesFilteredData = (personId, organizationId, eventId, sessionId, reportPeriodId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'NaN';
    const organizationIdForUrl = organizationId ? encodeURIComponent(organizationId) : 0;
    const eventIdForUrl = eventId ? encodeURIComponent(eventId) : 0;
    const sessionIdForUrl = sessionId ? encodeURIComponent(sessionId) : 0;
    const reportPeriodIdForUrl = reportPeriodId ? encodeURIComponent(reportPeriodId) : 0;
    
    const url = `/Person/swimTime/filtered?id=${personIdForUrl}&organizationId=${organizationIdForUrl}&eventId=${eventIdForUrl}&sessionId=${sessionIdForUrl}&reportPeriodId=${reportPeriodIdForUrl}`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const PersonSwimTimesFilteredData = {
  INITIAL_STATE: BASIC_INITIAL_STATE,
  GetPersonSwimTimesFilteredData
};

export default PersonSwimTimesFilteredData;