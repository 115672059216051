import useOtsSessionsDetail from "./UseOtsSessionsDetail";

import useOtsMeetSessionsData from "../../../../../common/state/officialsTrackingSystemMeetSessions/UseOtsMeetSessionsData";

const useOtsMeetSessionsDetail = () => {
  const { otsMeetSessionsState } = useOtsMeetSessionsData();
  const { meetSessionsState, onSaveButtonClicked,
    onBackButtonClicked } = useOtsSessionsDetail();

  return {
    otsMeetSessionsState,
    meetSessionsState,
    onSaveButtonClicked,
    onBackButtonClicked
  };
};

export default useOtsMeetSessionsDetail;