import { isValidDate, isValidBirthDate } from '../../../../common/utils/validation';
import Constants from "../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.supportStaffTypeId < 0) {
    errors.supportStaffTypeId = 'Support Staff Role is required';
  }

  //Effective date
  if (formState.effectiveDate === Constants.BLANK_DATE_STRING) {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  } else if (!isValidBirthDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date cannot be in the future';
  }

  //Expiration date
  if (formState.expirationDate === Constants.BLANK_DATE_STRING) {
    errors.expirationDate = 'Expiration Date is required';
  } else if (!isValidDate(formState.expirationDate)) {
    errors.expirationDate = 'Expiration Date must be a valid date';
  }

  const startDate = new Date(formState.effectiveDate);
  const endDate = new Date(formState.expirationDate);

  //Effective Date
  if (formState.effectiveDate !== Constants.BLANK_DATE_STRING) {
    if (endDate < startDate && formState.effectiveDate !== formState.expirationDate) {
      errors.effectiveDate = 'Effective Date must be before the Expiration Date';
    }
  }

  //Expiration Date
  if (formState.expirationDate !== Constants.BLANK_DATE_STRING) {
    if (endDate < startDate && formState.effectiveDate !== formState.expirationDate) {
      errors.expirationDate = 'Expiration Date must be after the Effective Date';
    }
  }


  return errors;
};

const NationalTeamSupportStaffTypeDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default NationalTeamSupportStaffTypeDetailValidation;