import { /*useContext,*/ useState } from 'react';

// import { OtsOfficialMeetHistoryStateContext } from './OtsOfficialMeetHistoryContextProvider';
import OtsOfficialMeetHistoryData from './OtsOfficialMeetHistoryData';

const useOtsOfficialMeetHistoryData = () => {
  const [otsOfficialMeetHistoryState, setOtsOfficialMeetHistoryState] = useState(OtsOfficialMeetHistoryData.INITIAL_STATE);

  const getOtsOfficialMeetHistoryByPersonId = (personId, filterObject) => OtsOfficialMeetHistoryData?.getOtsOfficialMeetHistoryByPersonId(personId, JSON.stringify(filterObject), otsOfficialMeetHistoryState, setOtsOfficialMeetHistoryState);

  const clearOtsOfficialMeetHistoryState = () => {

    setOtsOfficialMeetHistoryState({ ...OtsOfficialMeetHistoryData?.INITIAL_STATE });
  };

  return {
    otsOfficialMeetHistoryState,
    setOtsOfficialMeetHistoryState,
    getOtsOfficialMeetHistoryByPersonId,
    clearOtsOfficialMeetHistoryState
  };
};

export default useOtsOfficialMeetHistoryData;