import { useEffect } from 'react';

import useApprovedClubsData from '../../../state/approvedClubs/UseApprovedClubsData';

const useApprovedClubsCombobox = (lscId) => {
  const { approvedClubsState, getApprovedClubs } = useApprovedClubsData();

  useEffect(() => {
    if ((lscId !== approvedClubsState.lscId || approvedClubsState.isArrayLoaded === false)
      && approvedClubsState.isArrayLoading === false) {
      getApprovedClubs(lscId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lscId, approvedClubsState]);

  return {
    approvedClubsState
  };
};

export default useApprovedClubsCombobox;