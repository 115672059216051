import { useEffect } from 'react';

import validate from './MemberAdvancedInfoValidation';

import { createUsasPersonIfUndefinedOrNull } from '../utils/MemberUtils';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useCompetitionGenderTypeData from '../../../../common/state/competitionGender/UseCompetitionGenderTypeData';

import useForm from '../../../../common/utils/UseForm';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { evaluateBirthDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';
import ToBoolIfBool from '../../../../common/utils/ToBoolIfBool';

import useMemberInGoodStandingRefreshData from '../../../state/memberInGoodStanding/UseMemberInGoodStandingRefreshData';

const useMemberAdvancedInfo = () => {
  const { basePersonState, getBasePerson, putBasePerson } = useBasePersonData();
  const { competitionGenderState } = useCompetitionGenderTypeData();
  const { refreshState, putMemberInGoodStandingRefresh } = useMemberInGoodStandingRefreshData();
  const { handleSubmit, updateFormState, formState, setFormData, resetForm, errorState
  } = useForm(getInitialFormState(), submitFormCallback, validate);

  useEffect(() => {
    //Populate formState with data from the getPerson API call
    if (basePersonState.isObjLoaded === true && competitionGenderState.isArrayLoaded === true) {
      setFormData({
        ...formState,
        birthDate: formatDate(basePersonState.objData.birthDate),
        birthDateConfirmed: basePersonState.objData.usasPerson ? ToBoolIfBool(basePersonState.objData.usasPerson.birthDateConfirmed) : false,
        maidenNameUsedAsLastName: basePersonState.objData.usasPerson ? ToBoolIfBool(basePersonState.objData.usasPerson.maidenNameUsedAsLastName) : false,
        competitionGenderTypeId: basePersonState.objData.competitionGenderTypeId.toString()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, competitionGenderState.isArrayLoaded]);

  useEffect(() => {
    if (refreshState.isSaved === true && refreshState.objData.numDatesUpdated > 0) {
      //refresh in good standing icons
      getBasePerson(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshState]);

  const onRefreshClicked = (e) => {
    putMemberInGoodStandingRefresh(basePersonState.objData.personId);
  }

  function submitFormCallback(formState) {
    if (basePersonState.isSaving === false) {
      putBasePerson(basePersonState.objData.personId, createPersonObject());
    }
  };

  function createPersonObject() {
    const birthDateObj = evaluateBirthDate(formState.birthDate);
    const showEmail = birthDateObj.isJunior === true || birthDateObj.isAdult === true;

    return {
      ...basePersonState.objData,
      email: showEmail === false ? '' : basePersonState.objData.email,
      competitionGenderTypeId: formState.competitionGenderTypeId,
      competitionGenderType: undefined,
      birthDate: formState.birthDate,
      usasPerson: basePersonState.objData.usasPerson ? {
        ...basePersonState.objData.usasPerson,
        maidenNameUsedAsLastName: formState.maidenNameUsedAsLastName,
        birthDateConfirmed: formState.birthDateConfirmed,
        birthCertificateUrl: formState.birthDateConfirmed === true ? null : basePersonState.objData.usasPerson.birthCertificateUrl
      } :
        {
          ...createUsasPersonIfUndefinedOrNull(basePersonState.objData.personId),
          maidenNameUsedAsLastName: formState.maidenNameUsedAsLastName,
          birthDateConfirmed: formState.birthDateConfirmed
        }
    };
  };

  function getInitialFormState() {
    return {
      maidenNameUsedAsLastName: false,
      birthDate: Constants.BLANK_DATE_STRING,
      birthDateConfirmed: false,
      competitionGenderTypeId: '',
    };
  };

  return {
    Constants,
    basePersonState,
    formState,
    errorState,
    competitionGenderState,
    refreshState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    resetForm,
    onRefreshClicked
  };
};

export default useMemberAdvancedInfo;