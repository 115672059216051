import Constants from '../../../utils/Constants';

export const CompetitorListRegistrationFormValidation = (formState) => {
  let errors = {};

  if (formState.groupAttendeeTypeId === Constants.DEFAULT_ID || formState.groupAttendeeTypeId === '') {
    errors.groupAttendeeTypeId = 'Attendee Type is required';
  }

  if (formState.groupRoleTypeId === Constants.DEFAULT_ID || formState.groupRoleTypeId === '') {
    errors.groupRoleTypeId = 'Role is required';
  }
  
  if (formState.personalCoachId === Constants.DEFAULT_ID || formState.personalCoachId === '') {
    errors.personalCoachId = 'Personal Coach is required';
  }

  return errors;
};

export default CompetitorListRegistrationFormValidation;