import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';
import Constants from '../../../../common/utils/Constants';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const useProfileGoals = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personProfileState, getPersonProfile, putPersonProfile, confirmSave } = usePersonProfileData();
  const [gridFilterState, setGridFilterState] = useState({ eventId: Constants.DEFAULT_ID });

  const onAddGoal = () => {
    navigate(navRoutes.MEMBER_PROFILE_GOALS_DETAIL?.route);
  };

  const onGridFilter = (eventId) => {
    if (gridFilterState.eventId !== eventId) {
      setGridFilterState({
        ...gridFilterState,
        eventId: eventId
      });
    }
  };

  const onEditGoal = (personGoalId) => {
    navigate(navRoutes.MEMBER_PROFILE_GOALS_DETAIL?.route, { state: { personGoalId } });
  };

  const onDeleteGoal = (personGoalId) => {
    putPersonProfile(personProfileState.objData.personId, removePersonGoal(personProfileState.objData, personGoalId));
  };

  useEffect(() => {
    if (personProfileState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personProfileState.objData)) {
      getPersonProfile(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  useEffect(() => {
    if (personProfileState.isSaved === true) {
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState.isSaved]);

  function removePersonGoal(profileObj, personGoalId) {
    const newPersonObj = JSON.parse(JSON.stringify(profileObj));

    if (Array.isArray(newPersonObj.goals)) {
      const newGoals = newPersonObj.goals.filter(x => x.personGoalId !== personGoalId);

      newPersonObj.goals = newGoals;
    }

    return newPersonObj;
  };

  return {
    isSaving: personProfileState.isSaving,
    isLoading: personProfileState.isObjLoading,
    goalsData: personProfileState.objData?.goals || [],
    gridFilterState,
    onAddGoal,
    onGridFilter,
    onEditGoal,
    onDeleteGoal
  };
};

export default useProfileGoals;