import React from 'react';

import useMemberRequirementsAdvancedFilterDropdown from './UseMemberRequirementsAdvancedFilterDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberRequriementsAdvancedFilterDropdown = ({ label, name, value, error, message, onChange }) => {
  const { memberRequriementState } = useMemberRequirementsAdvancedFilterDropdown();

  return memberRequriementState?.message
    ? <span className={global.LoadingMsg}>{memberRequriementState?.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={memberRequriementState?.filteredRoleList}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={memberRequriementState?.isArrayLoading}
      />
    );
};

export default MemberRequriementsAdvancedFilterDropdown;