import { CommonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import HierarchicalDataUtils from '../../utils/HierarchicalDataUtils';
import Constants from '../../utils/Constants';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  roleGroupId: Constants.DEFAULT_ID,
  treeData: []
};

const getOrgRolesStaffRolesHierarchy = (roleGroupId, state, setState) => {
  if (state.isArrayLoading !== true && roleGroupId > 0 && !state.message) {
    let newState = {
      ...state,
      roleGroupId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const roleGroupIdForUrl = roleGroupId ? encodeURIComponent(roleGroupId) : 'NaN';
    const url = `/OrgRole/OrgRoleGroup/${roleGroupIdForUrl}/Hierarchy`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: arrayData,
            treeData: HierarchicalDataUtils.MapArrayToTreeData(arrayData, 'id', 'name'),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const OrgRoleStaffRoleHierarchyData = { INITIAL_STATE, getOrgRolesStaffRolesHierarchy };

export default OrgRoleStaffRoleHierarchyData;