import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getEvaluationLevelData from './GetEvaluationLevelData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  officialPositionTypeId: ''
};

const EvaluationLevelData = {
  INITIAL_STATE, getEvaluationLevelData
}

export default EvaluationLevelData;