import { createContext, useState } from 'react';

import OfferingManagementData from './OfferingManagementData';

export const OfferingManagementStateContext = createContext();

const OfferingManagementContextProvider = ({ children }) => {
  const stateHook = useState(OfferingManagementData.INITIAL_OFFERING_MANAGEMENT_STATE);

  return (
    <OfferingManagementStateContext.Provider value={stateHook}>
      {children}
    </OfferingManagementStateContext.Provider>
  );
};

export default OfferingManagementContextProvider;