import { Fragment } from 'react';

import useHqLscOfferingInstanceOrgUnitEdit from './UseHqLscOfferingInstanceOrgUnitEdit';

import HqOfferingManagementContextLoadingModal from '../../HqOfferingManagementContextLoadingModal';

import OfferingInstanceOrgUnitEditForm from '../../../components/forms/offeringInstanceOrgUnit/OfferingInstanceOrgUnitEditForm';

import { EDIT_VIEW_TYPE_ADDITIONAL_OFFERING, EDIT_VIEW_TYPE_MAIN_OFFERING, EDIT_VIEW_TYPE_SINGLE_OFFERING } from '../../../utils/OfferingManagementConstants';

import Headings from '../../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';

import { SAVING_MSG } from '../../../../../../common/utils/Constants';
import { convertNumberToCurrencyString } from '../../../../../../common/utils/CurrencyUtils';

const HqLscOfferingInstanceOrgUnitEdit = () => {
  const {
    isSaving,
    state,
    onSubmitOfferingInstanceOrgUnitForm,
    onBackClicked,
    onDeleteClicked,
    onDeletePopupConfirmClicked,
    onDeletePopupCancelClicked
  } = useHqLscOfferingInstanceOrgUnitEdit();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{state.viewType === EDIT_VIEW_TYPE_ADDITIONAL_OFFERING ? 'Edit Offering Period' : 'Edit LSC Offering'}</Headings.H3>
        </div>
      </div>
      {state.viewType === EDIT_VIEW_TYPE_SINGLE_OFFERING
        ? ( // Can edit fee and dates
          <OfferingInstanceOrgUnitEditForm
            reportPeriodObj={state.reportPeriodObj}
            orgUnitObj={state.orgUnitObj}
            offeringInstanceObj={state.offeringInstanceObj}
            offeringInstanceOrgUnitId={state.offeringInstanceOrgUnitId}
            isFeeReadOnly={false}
            hideDateFields={false}
            hideOrgUnitName={false}
            onSubmitFormCallback={onSubmitOfferingInstanceOrgUnitForm}
            onBackClicked={onBackClicked}
            onDeleteClicked={onDeleteClicked} />
        ) : state.viewType === EDIT_VIEW_TYPE_MAIN_OFFERING
          ? ( // Can only edit fee, no dates or delete button
            <OfferingInstanceOrgUnitEditForm
              reportPeriodObj={state.reportPeriodObj}
              orgUnitObj={state.orgUnitObj}
              offeringInstanceObj={state.offeringInstanceObj}
              offeringInstanceOrgUnitId={state.offeringInstanceOrgUnitId}
              hideOrgUnitName={false}
              isFeeReadOnly={false}
              hideDateFields={true}
              onSubmitFormCallback={onSubmitOfferingInstanceOrgUnitForm}
              onBackClicked={onBackClicked} />
          ) : state.viewType === EDIT_VIEW_TYPE_ADDITIONAL_OFFERING
            ? ( // Can edit dates
              <OfferingInstanceOrgUnitEditForm
                reportPeriodObj={state.reportPeriodObj}
                orgUnitObj={state.orgUnitObj}
                offeringInstanceObj={state.offeringInstanceObj}
                offeringInstanceOrgUnitId={state.offeringInstanceOrgUnitId}
                hideOrgUnitName={false}
                isFeeReadOnly={true}
                hideDateFields={false}
                onSubmitFormCallback={onSubmitOfferingInstanceOrgUnitForm}
                onBackClicked={onBackClicked}
                onDeleteClicked={onDeleteClicked} />
            ) : <Fragment />
      }
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={state.viewType === EDIT_VIEW_TYPE_ADDITIONAL_OFFERING ? 'Delete Offering Period?' : 'Delete LSC Offering?'}
        displayPopUp={state.displayDeletePopup === true}
        onModalCanceled={onDeletePopupCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>LSC</b>: &nbsp;{state.orgUnitObj?.orgUnitName}</p>
            <p><b>Offering Name</b>: &nbsp;{state.deletePopupOfferingObj?.offeringName}</p>
            <p><b>Price</b>: &nbsp;${convertNumberToCurrencyString(state.deletePopupOfferingObj?.feeAmount)}</p>
            <p><b>Registration Start/End Date</b>: &nbsp;{state.deletePopupOfferingObj?.registrationStartDate} - {state.deletePopupOfferingObj?.registrationEndDate}</p>
            <p><b>Effective/Expiration Date</b>: &nbsp;{state.deletePopupOfferingObj?.effectiveDate} - {state.deletePopupOfferingObj?.expirationDate}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeletePopupConfirmClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeletePopupCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <HqOfferingManagementContextLoadingModal
        isLoading={isSaving}
        loadingMessage={SAVING_MSG}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default HqLscOfferingInstanceOrgUnitEdit;