import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../common/utils/HttpHelper';

const GetPersonUpgradeData = (personId, state, setState) => {
  if (state.isArrayLoading !== true && personId && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'NaN';
    const url = `/Person/${personIdForUrl}/MembershipUpgrade`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: ''
          };

          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetPersonUpgradeData;