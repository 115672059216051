import { useState } from 'react';

import PersonAuditData from './PersonAuditData';

const usePersonAuditData = () => {
  const [personAuditState, setPersonAuditState] = useState(PersonAuditData.INITIAL_STATE);
  const getPersonAudit = (memberId) => PersonAuditData.getPersonAudit(memberId, personAuditState, setPersonAuditState);

  return {
    personAuditState,
    getPersonAudit
  };
};

export default usePersonAuditData;