import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

import validate from './NationalTeamSupportStaffDetailValidation';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';
import useSupportStaffTypeData from '../../../state/supportStaffType/UseSupportStaffTypeData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const TITLE = 'Support Staff';

const useNationalTeamSupportStaffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personNationalTeamState, putPersonNationalTeam } = usePersonNationalTeamData();
  const { supportStaffTypeState } = useSupportStaffTypeData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, onValueTextPairChanged,
    isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [uiState, setUiState] = useState(getInitialUiState());

  const onSaveClicked = (e) => {
    if (isDirty === false && formState.nationalTeamSupportStaffId > 0) {
      onCancelClicked(e);
    }
    else {
      handleSubmit(e);
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
  }

  // Setup useEffect, determines if the form is going to be used for add or edit, then populates formState accordingly
  useEffect(() => {
    if (supportStaffTypeState.isLoaded === true) {
      if (location.state && location.state.nationalTeamSupportStaffId && personNationalTeamState.isObjLoaded === true) {
        const nationalTeamSupportStaffId = location.state.nationalTeamSupportStaffId;
        const nationalTeamSupportStaffTypeId = location.state.nationalTeamSupportStaffTypeId;

        // Edit
        if (nationalTeamSupportStaffId > 0) {
          const selectedSupportStaff = personNationalTeamState.objData.supportStaffMembers.find(e => e.nationalTeamSupportStaffId === nationalTeamSupportStaffId);
          const selectedStaffType = selectedSupportStaff.nationalTeamSupportStaffType.find(e => e.nationalTeamSupportStaffTypeId === nationalTeamSupportStaffTypeId);

          setFormData({
            ...formState,
            nationalTeamSupportStaffId: nationalTeamSupportStaffId || Constants.DEFAULT_ID,
            nationalTeamSupportStaffTypeId: nationalTeamSupportStaffTypeId || Constants.DEFAULT_ID,
            firstName: selectedSupportStaff.firstName || '',
            lastName: selectedSupportStaff.lastName || '',
            email: selectedSupportStaff.email || '',
            phoneNumber: selectedSupportStaff.phoneNumber || Constants.DEFAULT_PHONE_NUMBER_AREA_CODE,
            effectiveDate: selectedStaffType.effectiveDate && formatDate(selectedStaffType.effectiveDate) !== Constants.DEFAULT_MIN_DATE ? formatDate(selectedStaffType.effectiveDate) : Constants.BLANK_DATE_STRING,
            expirationDate: selectedStaffType.expirationDate && formatDate(selectedStaffType.expirationDate) !== Constants.DEFAULT_MAX_DATE ? formatDate(selectedStaffType.expirationDate) : Constants.BLANK_DATE_STRING,
            supportStaffTypeId: selectedStaffType.supportStaffTypeId || Constants.DEFAULT_ID
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personNationalTeamState, supportStaffTypeState]);

  // Redirects the user to the previous page once all API calls have finished
  useEffect(() => {
    if (uiState.tryRedirect === true && personNationalTeamState.isObjLoading === false && personNationalTeamState.isSaving === false) {
      navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personNationalTeamState, uiState.tryRedirect]);

  // Creates a copy of the personNationalTeamState.objData and makes changes to it based on formState
  function createPutPersonNationalTeamObject() {
    const personNationalTeamObjToPut = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    // Is Edit
    if (formState.nationalTeamSupportStaffId > 0) {
      const staffIndex = personNationalTeamObjToPut.supportStaffMembers.findIndex(e => e.nationalTeamSupportStaffId === formState.nationalTeamSupportStaffId);
      const staffTypeIndex = personNationalTeamObjToPut.supportStaffMembers[staffIndex].nationalTeamSupportStaffType.findIndex(e => e.nationalTeamSupportStaffTypeId === formState.nationalTeamSupportStaffTypeId)
      let nationalTeamSupportStaffTypeArray = personNationalTeamObjToPut.supportStaffMembers[staffIndex].nationalTeamSupportStaffType;

      const nationalTeamSupportStaffTypeObj = {
        ...personNationalTeamObjToPut.supportStaffMembers[staffIndex].nationalTeamSupportStaffType[staffTypeIndex],
        effectiveDate: formState.effectiveDate !== Constants.BLANK_DATE_STRING ? formState.effectiveDate : Constants.DEFAULT_MIN_DATE,
        expirationDate: formState.expirationDate !== Constants.BLANK_DATE_STRING ? formState.expirationDate : Constants.DEFAULT_MAX_DATE,
        supportStaffTypeId: formState.supportStaffTypeId
      }

      nationalTeamSupportStaffTypeArray[staffTypeIndex] = nationalTeamSupportStaffTypeObj;

      personNationalTeamObjToPut.supportStaffMembers[staffIndex] =
      {
        ...personNationalTeamObjToPut.supportStaffMembers[staffIndex],
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        email: formState.email.trim(),
        phoneNumber: formState.phoneNumber.trim() !== Constants.DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== Constants.DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO ? formState.phoneNumber.trim() : '',
        nationalTeamSupportStaffType: nationalTeamSupportStaffTypeArray
      }
    }
    // Is Add
    else {
      let supportStaffMembers = personNationalTeamObjToPut.supportStaffMembers;

      let nationalTeamSupportStaffTypeArray = [];

      const nationalTeamSupportStaffTypeObj = {
        effectiveDate: formState.effectiveDate !== Constants.BLANK_DATE_STRING ? formState.effectiveDate : Constants.DEFAULT_MIN_DATE,
        expirationDate: formState.expirationDate !== Constants.BLANK_DATE_STRING ? formState.expirationDate : Constants.DEFAULT_MAX_DATE,
        supportStaffTypeId: formState.supportStaffTypeId,
        supportStaffType: null
      }

      nationalTeamSupportStaffTypeArray.push(nationalTeamSupportStaffTypeObj);

      supportStaffMembers.push({
        personId: personNationalTeamObjToPut.personId,
        firstName: formState.firstName.trim(),
        lastName: formState.lastName.trim(),
        email: formState.email.trim(),
        phoneNumber: formState.phoneNumber.trim() !== Constants.DEFAULT_PHONE_NUMBER_AREA_CODE && formState.phoneNumber.trim() !== Constants.DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO ? formState.phoneNumber.trim() : '',
        nationalTeamSupportStaffType: nationalTeamSupportStaffTypeArray
      });

      personNationalTeamObjToPut.supportStaffMembers = supportStaffMembers;
    }

    return personNationalTeamObjToPut;
  }

  function submitFormCallback(formState) {
    if (personNationalTeamState.isSaving === false) {
      const personNationalTeamObjToPut = createPutPersonNationalTeamObject();
      if (personNationalTeamObjToPut !== null) {
        putPersonNationalTeam(personNationalTeamState.objData.personId, personNationalTeamObjToPut);
        setUiState({ ...uiState, tryRedirect: true });
      }
    }
  };

  // Holds initial formState
  function getInitialFormState() {
    return {
      nationalTeamSupportStaffId: Constants.DEFAULT_ID,
      nationalTeamSupportStaffTypeId: Constants.DEFAULT_ID,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: Constants.DEFAULT_PHONE_NUMBER_AREA_CODE,
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      supportStaffTypeId: Constants.DEFAULT_ID,
    };
  };

  // Holds initial view state
  function getInitialUiState() {
    return {
      tryRedirect: false
    };
  };

  return {
    title: TITLE,
    Constants,
    personNationalTeamState,
    formState,
    errorState,
    uiState,
    supportStaffTypeState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  }
}

export default useNationalTeamSupportStaffDetail;