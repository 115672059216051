import { useState } from 'react';

import PersonSwimTimeData from './PersonSwimTimeData';
import PersonSwimTimesFilteredData from './GetPersonSwimTimeFilteredData';

const usePersonSwimTimeData = () => {
  const [personSwimTimeState, setPersonSwimTimeState] = useState(PersonSwimTimeData.INITIAL_STATE);

  const getPersonSwimTimes = (personId) => PersonSwimTimeData.GetPersonSwimTimesData(personId, personSwimTimeState, setPersonSwimTimeState);
  const getPersonSwimTimesFiltered = (personId, organizationId, eventId, sessionId, reportPeriodId) =>
    PersonSwimTimesFilteredData.GetPersonSwimTimesFilteredData(personId, organizationId, eventId, sessionId, reportPeriodId, personSwimTimeState, setPersonSwimTimeState);

  const clearArrayData = () => {
    setPersonSwimTimeState({
      ...personSwimTimeState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  return {
    personSwimTimeState,
    clearArrayData,
    getPersonSwimTimes,
    getPersonSwimTimesFiltered
  };
};

export default usePersonSwimTimeData;