import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ role, session, onDeleteSessionRole }) => (
  <tr>
    <td>{`${session.sessionOrderId || <span>&nbsp;</span>} (${session.sessionType || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})`}</td>
    <td>{session.isOfficialsQualifyingSession === true ? 'Yes' : session.isOfficialsQualifyingSession === false ? 'No' : <span>&nbsp;</span>}</td>
    <td><button
      className={global.IconButtonMargin}
      type="button"
      onClick={(e) => onDeleteSessionRole(e, session, role)}>
      <DeleteIcon />
    </button></td>
  </tr>
);

const GridRowDetailTable = ({ sessions, role, onDeleteSessionRole }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Session</th>
        <th>OQM Session?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(sessions) && sessions.map((session, i) =>
        <DetailTableRow key={i} session={session} role={role} onDeleteSessionRole={onDeleteSessionRole} />)}
    </tbody>
  </table>
);

const GridRow = ({ role, onEditSessionRole, onDeleteSessionRole, expandedId, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td>{`${role.roleTypeName || ''} (${role.roleTypeAbbreviation || ''})`}</td>
      <td><b>{role?.isApprentice === true ? 'Apprentice' : ''}</b></td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onEditSessionRole(e, role)}>
          <EditIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, `${role.meetOfficialRoleTypeId}+${role.isApprentice}`)}>
          {expandedId === `${role.meetOfficialRoleTypeId}+${role.isApprentice}` ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {expandedId === `${role.meetOfficialRoleTypeId}+${role.isApprentice}` &&
      <tr className={global.Expanded}>
        {Array.isArray(role.session) && role.session.length > 0
          ? (
            <td colSpan="3">
              <GridRowDetailTable sessions={role.session} role={role} onDeleteSessionRole={onDeleteSessionRole} />
            </td>
          )
          : (
            <td colSpan="3">
              <table className={global.DetailTable}>
                <thead>
                  <tr>
                    <th>No Session Roles</th>
                  </tr>
                </thead>
              </table>
            </td>
          )
        }
      </tr>
    }
  </Fragment>
);

const MeetOfficialsOfficialGridLarge = ({ state, onEditSessionRole, onDeleteSessionRole, expandedId, onExpandClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Role Worked</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state.isArrayLoaded !== true ?
        <tr><td colSpan="3">{state.isArrayLoading === true ? 'Loading...' : 'No Session Roles'}</td></tr>
        : Array.isArray(state.arrayData) && state.arrayData.length > 0 ?
          state.arrayData.map((role, i) =>
            <GridRow key={i} role={role} onEditSessionRole={onEditSessionRole}
              onDeleteSessionRole={onDeleteSessionRole} expandedId={expandedId}
              onExpandClicked={onExpandClicked} />)
          :
          <tr><td colSpan="3">No Session Roles</td></tr>
      }
    </tbody>
  </table >
);

export default MeetOfficialsOfficialGridLarge;