import { createContext, useState } from 'react';

import OfferingTypesData from './OfferingTypesData';

export const OfferingTypesStateContext = createContext();

const OfferingTypesContextProvider = ({ children }) => {
  const stateHook = useState(OfferingTypesData.INITIAL_OFFERING_TYPES_STATE);

  return (
    <OfferingTypesStateContext.Provider value={stateHook}>
      {children}
    </OfferingTypesStateContext.Provider>
  );
};

export default OfferingTypesContextProvider;