import React, { Fragment } from 'react';
import NationalTeamAttachmentsLargeGrid from './NationalTeamAttachmentsLargeGrid';
import NationalTeamAttachmentsSmallGrid from './NationalTeamAttachmentsSmallGrid';
import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

const NationalTeamAttachmentsGrid = ({ state, onEditAttachment, onOpenDeleteAttachmentModal }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <NationalTeamAttachmentsLargeGrid
        state={state}
        onEditAttachment={onEditAttachment}
        onOpenDeleteAttachmentModal={onOpenDeleteAttachmentModal}
        expandedId={expandedId}
        onExpandClicked={onClick} />
      <NationalTeamAttachmentsSmallGrid
        state={state}
        onEditAttachment={onEditAttachment}
        onOpenDeleteAttachmentModal={onOpenDeleteAttachmentModal}
        expandedId={expandedId}
        onExpandClicked={onClick} />
    </Fragment>
  );
}

export default NationalTeamAttachmentsGrid;