import { Fragment } from 'react';

import UseMiniMemberMergeSelection from './UseMiniMemberMergeSelection';

import MemberMergeTool from '../../../components/memberMergeTool/MemberMergeTool';

import Headings from '../../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const MiniMemberMergeSelection = () => {
  const {
    memberAId,
    memberBId,
    onSuccessfulMerge,
    onCancelMergeClicked
  } = UseMiniMemberMergeSelection();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Merge - Select Member Information</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
        </div>
        <div className="col-xs-12">
          <button className={global.BackToSearchButton} type="button" onClick={() => onCancelMergeClicked()}>
            <LeftArrowIcon /> Back to Member Merge Search
          </button>
        </div>
        <div className="col-xs-12">
          <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
        </div>
      </div>
      <MemberMergeTool
        memberAId={memberAId}
        memberBId={memberBId}
        onSuccessfulMerge={onSuccessfulMerge}
        onCancelClicked={onCancelMergeClicked} />
    </Fragment>
  );
};

export default MiniMemberMergeSelection;