export const localValidate = (formState) => {
  let errors = {};

  if (formState.socialMediaTypeId < 0) {
    errors.socialMediaTypeId = 'Social Media Platform is required';
  }

  if (!formState.socialHandle.trim()) {
    errors.socialHandle = 'Handle / URL is required';
  } else if (formState.socialHandle.trim().length > 255) {
    errors.socialHandle = 'Handle / URL cannot exceed 255 characters';
  }

  return errors;
};

const ProfileSocialMediaDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ProfileSocialMediaDetailValidation;