import useActiveClubCombobox from './UseActiveClubCombobox';

import Combobox from '../Combobox';

import Constants from '../../../utils/Constants';

import global from '../../GlobalStyle.module.css';

const ActiveClubCombobox = ({ label, name, valueToMatch, error, message, onChange, lscId }) => {
  const { activeClubsState } = useActiveClubCombobox(lscId !== Constants.DEFAULT_ID && lscId !== '' && lscId !== undefined ? lscId : '');

  return activeClubsState.message
    ? <span className={global.LoadingMsg}>{activeClubsState.message}</span>
    : (
      <Combobox
        label={label}
        name={name}
        isLoading={activeClubsState.isArrayLoading}
        items={activeClubsState.items}
        valueToMatch={valueToMatch}
        error={error}
        message={message}
        onChange={onChange} />
    );
};

export default ActiveClubCombobox;