import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useMemberCoachesData from '../../../state/memberCoaches/UseMemberCoachesData';
import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useCommentData from '../../../../common/state/comment/UseCommentData';

import Constants from '../../../../common/utils/Constants';
import { isPersonObjectDataCurrent } from '../utils/MemberUtils';
import useForm from '../../../../common/utils/UseForm';

const TITLE = 'Coach';
const INITIAL_FORM_STATE = {
  coachComments: ''
};

const useProfileCoach = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personProfileState, getPersonProfile, putPersonProfile } = usePersonProfileData();
  const { memberCoachesState, getMemberCoaches } = useMemberCoachesData();
  const [modalState, setModalState] = useState(getInitialModalState());
  const { formState, onFormValueChanged, resetForm, handleSubmit, setFormData } = useForm(INITIAL_FORM_STATE);
  const { commentState, putComment, postComment } = useCommentData();

  useEffect(() => {
    if (personProfileState.isObjLoaded === true) {
      getMemberCoaches(personProfileState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState]);

  useEffect(() => {
    if (personProfileState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personProfileState.objData)) {
      getPersonProfile(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  const onAddEditCoach = (e, coachPersonId, coachName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (coachPersonId === Constants.DEFAULT_ID) {
      //add
      navigate(navRoutes.MEMBER_PROFILE_COACH_SEARCH?.route);
    } else {
      //edit
      navigate(navRoutes.MEMBER_PROFILE_COACH_DETAIL?.route, { state: { coachPersonId, coachName } });
    }
  };

  const onAddEditCoachType = (e, coachPersonId, personCoachTypeId, coachName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_PROFILE_COACH_TYPE_DETAIL?.route, { state: { coachPersonId, personCoachTypeId, coachName } });
  };

  const onOpenModalCoachTypeClicked = (e, coachPersonId, personCoachTypeId, typeName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      modalTitle: 'Delete this Coach Type?',
      itemId: coachPersonId,
      itemId2: personCoachTypeId,
      itemName: typeName,
      displayPopUp: true
    });
  };

  const onOpenModalClicked = (e, coachPersonId, coachName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...modalState,
      modalTitle: 'Delete this Coach?',
      itemId: coachPersonId,
      itemName: coachName,
      displayPopUp: true
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState({
      ...getInitialModalState()
    });
  };

  const onSaveCoachComment = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const commentId = memberCoachesState?.arrayData?.coachComments?.personCommentId;

    if (memberCoachesState?.arrayData?.coachComments?.personCommentId !== null) {
      const putCommentObj = {
        personCommentId: commentId,
        personId: personProfileState?.objData?.personId,
        commentType: 'Coach',
        comment: formState?.coachComments
      }
      putComment(commentId, putCommentObj);

    } else {
      const postCommentObj = {
        personId: personProfileState?.objData?.personId,
        commentType: 'Coach',
        comment: formState?.coachComments
      }

      postComment(postCommentObj);
    }

  };

  const onDeleteCoach = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const personObj = JSON.parse(JSON.stringify(personProfileState.objData));
    const index = personObj.coaches.findIndex(e => e.coachPersonId === modalState.itemId);

    if (index > Constants.DEFAULT_ID) {
      personObj.coaches.splice(index, 1);
      putPersonProfile(personProfileState.objData.personId, personObj);
    }
    setModalState({
      ...getInitialModalState()
    });
  };

  const onDeleteCoachType = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const personObj = JSON.parse(JSON.stringify(personProfileState.objData));
    const coachIndex = personObj.coaches.findIndex(e => e.coachPersonId === modalState.itemId);
    const coachTypeindex = personObj.coaches[coachIndex].personCoachType.findIndex(e => e.personCoachTypeId === modalState.itemId2);

    if (coachIndex > Constants.DEFAULT_ID && coachTypeindex > Constants.DEFAULT_ID) {
      personObj.coaches[coachIndex].personCoachType.splice(coachTypeindex, 1);
      putPersonProfile(personProfileState.objData.personId, personObj);
    }

    setModalState({
      ...getInitialModalState()
    });
  };

  useEffect(() => {
    if (memberCoachesState && memberCoachesState?.arrayData?.coachComments?.personCommentId !== null) {

      setFormData({
        ...formState,
        coachComments: memberCoachesState?.arrayData?.coachComments?.comment
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCoachesState]);

  function getInitialModalState() {
    return {
      itemId: Constants.DEFAULT_ID,
      itemId2: Constants.DEFAULT_ID,
      itemName: '',
      displayPopUp: false,
      modalTitle: 'Delete this Coach?'
    };
  };

  return {
    title: TITLE,
    DEFAULT_ID: Constants.DEFAULT_ID,
    memberCoachesState,
    modalState,
    onAddEditCoach,
    onAddEditCoachType,
    onDeleteCoach,
    onDeleteCoachType,
    onOpenModalClicked,
    onOpenModalCoachTypeClicked,
    onModalCanceled,
    formState,
    onFormValueChanged,
    resetForm,
    handleSubmit,
    onSaveCoachComment,
    commentState
  };
};

export default useProfileCoach;