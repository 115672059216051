import React, { Fragment } from 'react';

import OtsOfficialNationalCertificationApplicationLargeWriteGrid from './OtsOfficialNationalCertificationApplicationLargeWriteGrid';
import OtsOfficialNationalCertificationApplicationSmallWriteGrid from './OtsOfficialNationalCertificationApplicationSmallWriteGrid';

const OtsOfficialsNationalCertificationApplicationWriteGrid = ({ state, gridState, formState, onRecertifyCheckboxChange, onAdvanceCheckboxChange }) => {
  return (
    <Fragment>
      <OtsOfficialNationalCertificationApplicationLargeWriteGrid
        state={state}
        gridState={gridState}
        formState={formState}
        onRecertifyCheckboxChange={onRecertifyCheckboxChange}
        onAdvanceCheckboxChange={onAdvanceCheckboxChange} />
      <OtsOfficialNationalCertificationApplicationSmallWriteGrid
        state={state}
        gridState={gridState}
        formState={formState}
        onRecertifyCheckboxChange={onRecertifyCheckboxChange}
        onAdvanceCheckboxChange={onAdvanceCheckboxChange} />
    </Fragment>
  )
};

export default OtsOfficialsNationalCertificationApplicationWriteGrid;
