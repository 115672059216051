import { isValidDate, isValidBirthDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.testingBodyId < 0) {
    errors.testingBodyId = 'Testing Body is required';
  }

  if (formState.drugTestStatusId < 0) {
    errors.drugTestStatusId = 'Status is required';
  }

  //missing drug test date
  if (formState.missingDrugTestDate === Constants.BLANK_DATE_STRING) {
    errors.missingDrugTestDate = 'Date of Missed Drug Test is required';
  } else if (!isValidDate(formState.missingDrugTestDate)) {
    errors.missingDrugTestDate = 'Date of Missed Drug Test must be a valid date';
  } else if (!isValidBirthDate(formState.missingDrugTestDate)) {
    errors.missingDrugTestDate = 'Date of Missed Drug Test cannot be in the future';
  }

  //notificationFromTestBodyDate
  if (formState.notificationFromTestBodyDate !== Constants.BLANK_DATE_STRING && !isValidDate(formState.notificationFromTestBodyDate)) {
    errors.notificationFromTestBodyDate = 'Notification from Testing Body Date must be a valid date';
  } else if (!isValidBirthDate(formState.notificationFromTestBodyDate)) {
    errors.notificationFromTestBodyDate = 'Notification from Testing Body Date cannot be in the future';
  }

  let firstDate = new Date(formState.missingDrugTestDate);
  let secondDate = new Date(formState.notificationFromTestBodyDate);

  if (formState.notificationFromTestBodyDate !== Constants.BLANK_DATE_STRING) {
    if (secondDate < firstDate && formState.missingDrugTestDate !== formState.notificationFromTestBodyDate) {
      errors.notificationFromTestBodyDate = 'Notification from Testing Body Date must be after the Date of Missed Drug Test';
    }
  }

  //athleteNotifiedDate
  if (formState.athleteNotifiedDate !== Constants.BLANK_DATE_STRING && !isValidDate(formState.athleteNotifiedDate)) {
    errors.athleteNotifiedDate = 'Athlete Notified Date must be a valid date';
  } else if (!isValidBirthDate(formState.athleteNotifiedDate)) {
    errors.athleteNotifiedDate = 'Athlete Notified Date cannot be in the future';
  }

  firstDate = new Date(formState.notificationFromTestBodyDate);
  secondDate = new Date(formState.athleteNotifiedDate);

  if (formState.athleteNotifiedDate !== Constants.BLANK_DATE_STRING) {
    if (formState.notificationFromTestBodyDate === Constants.BLANK_DATE_STRING) {
      errors.notificationFromTestBodyDate = 'Notification from Testing Body Date must be provided if Athlete Notified Date is provided'
    } else if (secondDate < firstDate && formState.notificationFromTestBodyDate !== formState.athleteNotifiedDate) {
      errors.athleteNotifiedDate = 'Athlete Notified Date must be after the Notification from Testing Body Date';
    }
  }

  //testAppealedDate
  if (formState.testAppealedDate !== Constants.BLANK_DATE_STRING && !isValidDate(formState.testAppealedDate)) {
    errors.testAppealedDate = 'Test Appealed Date must be a valid date';
  } else if (!isValidBirthDate(formState.testAppealedDate)) {
    errors.testAppealedDate = 'Test Appealed Date cannot be in the future';
  }

  firstDate = new Date(formState.athleteNotifiedDate);
  secondDate = new Date(formState.testAppealedDate);

  if (formState.testAppealedDate !== Constants.BLANK_DATE_STRING) {
    if (formState.athleteNotifiedDate === Constants.BLANK_DATE_STRING) {
      errors.athleteNotifiedDate = 'Athlete Notified Date must be provided if Test Appealed Date is provided'
    } else if (secondDate < firstDate && formState.athleteNotifiedDate !== formState.testAppealedDate) {
      errors.testAppealedDate = 'Test Appealed Date must be after the Athlete Notified Date';
    }
  }

  //testBodyAppealResponseDate
  if (formState.testBodyAppealResponseDate !== Constants.BLANK_DATE_STRING && !isValidDate(formState.testBodyAppealResponseDate)) {
    errors.testBodyAppealResponseDate = 'Test Body Appeal Response Date must be a valid date';
  } else if (!isValidBirthDate(formState.testBodyAppealResponseDate)) {
    errors.testBodyAppealResponseDate = 'Test Body Appeal Response Date cannot be in the future';
  }

  firstDate = new Date(formState.testAppealedDate);
  secondDate = new Date(formState.testBodyAppealResponseDate);

  if (formState.testBodyAppealResponseDate !== Constants.BLANK_DATE_STRING) {
    if (formState.testAppealedDate === Constants.BLANK_DATE_STRING) {
      errors.testAppealedDate = 'Test Appealed Date must be provided if the Test Body Appeal Response Date is provided'
    } else if (secondDate < firstDate && formState.testAppealedDate !== formState.testBodyAppealResponseDate) {
      errors.testBodyAppealResponseDate = 'Test Body Appeal Response Date must be after the Test Appealed Date';
    }
  }

  if (formState.comments.trim().length > 2000) {
    errors.comments = 'Comments cannot exceed 2000 characters';
  }

  return errors;
};

const NationalTeamMissedDrugTestsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default NationalTeamMissedDrugTestsDetailValidation;