import React, { Fragment } from 'react';

import useOtsMeetOfficialsOfficialDetail from './UseOtsMeetOfficialsOfficialDetail';

import MeetOfficialRoleTypeDropdown from '../../../../components/dropdowns/meetOfficialRoleTypeDropdown/MeetOfficialRoleTypeDropdown';
import SessionMultiSelect from '../../../../components/multiSelect/SessionMultiSelect';

import Headings from '../../../../../common/components/headings/Headings';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsMeetOfficialsOfficialDetail = () => {
  const {
    otsMeetHeaderState,
    isLoading,
    isSaving,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    Constants,
    onSaveClicked,
    onBackClicked
  } = useOtsMeetOfficialsOfficialDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.isEdit === true ? 'Edit' : 'Add'} Meet Official Session Role</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <p><b>Official Name:</b>&nbsp;{formState.firstName || ''} {formState.lastName || ''}</p>
          <p><b>Member ID:</b>&nbsp;{formState.meetOfficialMemberId}</p>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-bottom-margin">
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <MeetOfficialRoleTypeDropdown
              label="Role Worked*"
              name="meetOfficialRoleTypeId"
              value={formState.meetOfficialRoleTypeId}
              error={errorState.meetOfficialRoleTypeId}
              message={errorState.meetOfficialRoleTypeId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'meetOfficialRoleTypeId', newValueLabel, 'officialRoleTypeName', e) }}
              orgLevelId={undefined}
            />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <YesNoSwitch
              label="Apprentice?*"
              name="isApprentice"
              checked={formState.isApprentice}
              error={errorState.isApprentice}
              message={errorState.isApprentice}
              onChange={(value) => { onFormValueChanged('isApprentice', value); }} />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-8'>
            <SessionMultiSelect
              meetId={otsMeetHeaderState?.objData?.meetId}
              label="Session(s)*"
              name="meetSessionIds"
              value={formState.meetSessionIds}
              error={errorState.meetSessionIds}
              message={errorState.meetSessionIds}
              onChange={(value) => { onFormValueChanged('meetSessionIds', value) }} />
          </div>
        </div>
        {errorState.general &&
          <div className="row">
            <div className="col-xs-12">
              <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className='col-xs-12 usas-extra-top-margin'>
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  )
}

export default OtsMeetOfficialsOfficialDetail;