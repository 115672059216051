import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MemberContactPhoneDetailValidation';

import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../common/utils/Constants';
import useForm from '../../../../common/utils/UseForm';
import { formatPhoneNumber } from '../../../../common/utils/validation';

const useMemberContactPhoneDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personGeneralState, putPersonGeneralContact } = usePersonGeneralData();
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setFormState, setIsDirty, onValueTextPairChanged
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState(getInitialState());
  const [modalState, setModalState] = useState(getInitialModalState());

  const onSavePhone = (e) => {
    if (isDirty === false) {
      onCancelClicked(e);
    } else {
      handleSubmit(e);
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_CONTACTS?.route, { state: { personContactId: location.state.personContactId } });
  };

  const onModalCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    setModalState(getInitialModalState());
  };

  const putPersonGeneralContactPhones = (doPrimaryCheck) => {
    if (personGeneralState.isSaving === false) {
      putPersonGeneralContact(personGeneralState.objData.personId, createPersonGeneralObject(doPrimaryCheck));
    }

    setState({
      ...state,
      tryRedirect: true
    });

    setModalState(getInitialModalState);
  };

  useEffect(() => {
    if (state.tryRedirect === true && personGeneralState.isObjLoading === false && personGeneralState.isSaving === false) {
      navigate(navRoutes.MEMBER_CONTACTS?.route, { state: { personContactId: location.state.personContactId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState, state.tryRedirect]);

  useEffect(() => {
    if (personGeneralState.isObjLoaded === true && location.state?.personContactId > 0) {
      const targetContact = personGeneralState.objData.personContact.find(x => x.personContactId === location.state.personContactId);

      if (targetContact) {
        if (location.state?.personContactPhoneId > 0) { // edit
          const targetPhone = targetContact.personContactPhone.find(x => x.personContactPhoneId === location.state.personContactPhoneId);

          if (targetPhone) {
            setFormState({
              ...formState,
              personContactPhoneId: targetPhone.personContactPhoneId,
              isPrimary: targetPhone.isPrimary === true,
              phoneTypeId: targetPhone.phoneTypeId || Constants.DEFAULT_ID,
              phoneTypeName: targetPhone.phoneType?.typeName || '',
              phoneNumber: targetPhone.phoneNumber
            });

            setState({
              ...state,
              personContactId: targetContact.personContactId,
              personContactName: `${targetContact.firstName || ''} ${targetContact.lastName || ''}`,
              disablePrimaryCheckbox: targetPhone.isPrimary === true
            });
          }
        } else if (targetContact.personContactPhone.length === 0) { // add first phone, force isPrimary true
          setFormState({ ...formState, isPrimary: true });
          setState({
            ...state,
            personContactId: targetContact.personContactId,
            personContactName: `${targetContact.firstName || ''} ${targetContact.lastName || ''}`,
            disablePrimaryCheckbox: true
          });
        } else { // add
          setState({
            ...state,
            personContactId: targetContact.personContactId,
            personContactName: `${targetContact.firstName || ''} ${targetContact.lastName || ''}`
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personGeneralState]);

  function submitFormCallback(formState) {
    if (state.disablePrimaryCheckbox === false && formState.isPrimary === true) {
      const targetContact = personGeneralState.objData.personContact.find(x => x.personContactId === state.personContactId);

      if (targetContact) {
        const currentPrimary = targetContact.personContactPhone.find(x => x.isPrimary === true);

        if (currentPrimary) {
          setModalState({
            ...modalState,
            displayPopUp: true,
            currentPrimaryInfo: `${formatPhoneNumber(currentPrimary.phoneNumber)} (${currentPrimary.phoneType?.typeName || 'Unknown Phone Type'})`
          });
        } else {
          putPersonGeneralContactPhones(true);
        }
      }
    } else {
      putPersonGeneralContactPhones(false);
    }
  };

  function createPersonGeneralObject(doPrimaryCheck) {
    const newPersonGeneralObj = JSON.parse(JSON.stringify(personGeneralState.objData));
    const personContactIndex = newPersonGeneralObj.personContact.findIndex(x => x.personContactId === state.personContactId);

    if (personContactIndex >= 0) {
      if (doPrimaryCheck === true) {
        for (let i = 0; i < newPersonGeneralObj.personContact[personContactIndex].personContactPhone.length; i++) {
          newPersonGeneralObj.personContact[personContactIndex].personContactPhone[i].isPrimary = false;
        }
      }

      const newPersonContactPhone = {
        phoneTypeId: formState.phoneTypeId,
        isPrimary: formState.isPrimary,
        phoneNumber: formState.phoneNumber,
        phoneType: undefined
      };

      if (formState.personContactPhoneId > 0) {
        const personContactPhoneIndex = newPersonGeneralObj.personContact[personContactIndex].personContactPhone.findIndex(x => x.personContactPhoneId === formState.personContactPhoneId);

        if (personContactPhoneIndex >= 0) {
          newPersonGeneralObj.personContact[personContactIndex].personContactPhone[personContactPhoneIndex] = {
            ...newPersonGeneralObj.personContact[personContactIndex].personContactPhone[personContactPhoneIndex],
            ...newPersonContactPhone
          };
        }
      } else {
        newPersonGeneralObj.personContact[personContactIndex].personContactPhone.push({
          personContactId: state.personContactId,
          ...newPersonContactPhone
        });
      }
    }

    return newPersonGeneralObj;
  };

  function getInitialFormState() {
    return {
      personContactPhoneId: Constants.DEFAULT_ID,
      isPrimary: false,
      phoneTypeId: Constants.DEFAULT_ID,
      phoneTypeName: '',
      phoneNumber: ''
    };
  };

  function getInitialState() {
    return {
      personContactId: Constants.DEFAULT_ID,
      personContactName: '',
      disablePrimaryCheckbox: false,
      tryRedirect: false
    };
  };

  function getInitialModalState() {
    return {
      displayPopUp: false,
      currentPrimaryInfo: '',
      modalTitle: 'Change the primary phone number?'
    };
  };

  return {
    personGeneralState,
    formState,
    errorState,
    state,
    modalState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onSavePhone,
    onCancelClicked,
    onModalCancelClicked,
    putPersonGeneralContactPhones,
    phoneTypeOptions: [ //TODO probably get from database instead
      { id: Constants.DEFAULT_ID, name: "--" },
      { id: 1, name: "Mobile" },
      { id: 2, name: "Work"},
      { id: 3, name: "Home" },
      { id: 4, name: "Other" }
    ]
  };
};

export default useMemberContactPhoneDetail;