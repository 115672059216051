import { useEffect, useState } from 'react';

import validate from './OfferingInstanceAddFormValidation';

import useOfferingsData from '../../../../../state/offerings/UseOfferingsData';

import useEnvironmentVariableData from '../../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../../../common/utils/UseForm';
import { BLANK_DATE_STRING, DEFAULT_ID } from '../../../../../../common/utils/Constants';

const FEE_TYPE_NAME = 'USAS';

const INITIAL_STATE = {
  areOfferingOptionsLoaded: false,
  offeringOptions: [],
  apiErrorMessage: ''
};

const INITIAL_FORM_STATE = {
  offeringId: DEFAULT_ID,
  offeringName: '',
  defaultRegStartDate: BLANK_DATE_STRING,
  defaultRegEndDate: BLANK_DATE_STRING,
  defaultEffectiveDate: BLANK_DATE_STRING,
  defaultExpirationDate: BLANK_DATE_STRING,
  feeAmount: 0,
  enableMaxLscFeeAmount: false,
  maxLscFeeAmount: null
};

const useOfferingInstanceAddForm = (onSubmitFormCallback, reportPeriodObj) => {
  const { USAS_ORG_UNIT_ID, USAS_MEMBERSHIP_FEE_TYPE_ID } = useEnvironmentVariableData();
  const { getAvailableOfferings } = useOfferingsData();
  const { formState, errorState, setFormState, handleSubmit, updateFormState,
    onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, formatBeforeSubmit, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onEnableMaxLscFeeAmountChanged = (value) => {
    setFormState({
      ...formState,
      enableMaxLscFeeAmount: value,
      maxLscFeeAmount: value === true ? 0 : null
    });
  };

  useEffect(() => {
    if (state.areOfferingOptionsLoaded === true) {
      if (state.offeringOptions.length > 0) {
        setFormState({
          ...formState,
          offeringId: state.offeringOptions[0]?.id,
          offeringName: state.offeringOptions[0]?.name
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.areOfferingOptionsLoaded]);

  useEffect(() => {
    if (reportPeriodObj?.reportPeriodId > 0) {
      const getAvailableOfferingsPromise = getAvailableOfferings(reportPeriodObj.reportPeriodId);

      if (getAvailableOfferingsPromise ?? false) {
        getAvailableOfferingsPromise.then((newState) => {
          if (newState ?? false) {
            const options = newState.arrayData.map((x) => { return { id: x.offeringId, name: x.offeringName } });

            setState({
              ...state,
              areOfferingOptionsLoaded: true,
              offeringOptions: options
            });
          }
        }).catch((e) => {
          setState({
            ...state,
            apiErrorMessage: e?.message || 'Failed to retrieve available offerings',
          });
        });
      }
    } else {
      setState({
        ...state,
        apiErrorMessage: 'Failed to load available offerings'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodObj]);

  function formatBeforeSubmit(formState) {
    if (USAS_ORG_UNIT_ID && USAS_MEMBERSHIP_FEE_TYPE_ID) {
      const submitObj = {
        reportPeriodId: reportPeriodObj.reportPeriodId,
        feeTypeId: USAS_MEMBERSHIP_FEE_TYPE_ID,
        orgUnitId: USAS_ORG_UNIT_ID,

        offeringId: formState.offeringId,
        defaultRegStartDate: formState.defaultRegStartDate,
        defaultRegEndDate: formState.defaultRegEndDate,
        defaultEffectiveDate: formState.defaultEffectiveDate,
        defaultExpirationDate: formState.defaultExpirationDate,
        feeAmount: formState.feeAmount,
        maxLscFeeAmount: formState.enableMaxLscFeeAmount === true ? formState.maxLscFeeAmount : null
      };

      onSubmitFormCallback(submitObj);
    }
  }

  return {
    feeTypeName: FEE_TYPE_NAME,
    formState,
    errorState,
    state,

    updateFormState,
    onValueTextPairChanged,
    onEnableMaxLscFeeAmountChanged,
    handleSubmit
  };
};

export default useOfferingInstanceAddForm;