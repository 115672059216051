import React, { Fragment } from 'react';

import MemberTimesLargeGrid from './MemberTimesLargeGrid';
import MemberTimesSmallGrid from './MemberTimesSmallGrid';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

const MemberTimesGrid = ({ gridData, isLoading, onEdit }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      {gridData.length > 0 ?
        <div className="row">
          <div className="col-xs-12">
            <p>{gridData.length === 1 ? `${gridData.length} Search Result` : `${gridData.length} Search Results`}</p>
          </div>
        </div>
        : <Fragment>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          </div>
        </Fragment>}
      <MemberTimesLargeGrid
        gridData={gridData}
        expandedId={expandedId}
        isLoading={isLoading}
        onEdit={onEdit}
        onExpandClicked={onClick} />
      <MemberTimesSmallGrid
        gridData={gridData}
        expandedId={expandedId}
        isLoading={isLoading}
        onEdit={onEdit}
        onExpandClicked={onClick} />
    </Fragment>
  );
};

export default MemberTimesGrid;

