import { useEffect } from 'react';

import usePersonAuditData from '../../../state/member/memberAudit/UsePersonAuditData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import Constants from '../../../../common/utils/Constants';

const TITLE = 'Member Audit';

const useMemberAudit = () => {
  const { basePersonState } = useBasePersonData();
  const { personAuditState, getPersonAudit } = usePersonAuditData();

  useEffect(() => {
    if (basePersonState.isObjLoaded === true) {
      getPersonAudit(basePersonState.objData.memberId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  return { title: TITLE, Constants, personAuditState, basePersonState };
};

export default useMemberAudit;