import useNationalTeamRegistrationForm from './UseNationalTeamRegistrationForm';

import YearDropdown from '../../../../app/components/dropdowns/YearDropdown';

import Dropdown from '../../dropdowns/Dropdown';
import Input from '../../inputs/Input';
import StatesCombobox from '../../comboboxes/statesCombobox/StatesCombobox';
import DatePicker from '../../datepickers/DatePicker';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

import global from '../../GlobalStyle.module.css';

const NationalTeamRegistrationForm = ({ personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj,
  primaryButtonText = 'Save', secondaryButtonText = 'Back', disableValidation = false, onSecondaryButtonClicked,
  onFormCompletion }) => {
  const {
    formState,
    errorState,
    optionsState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged
  } = useNationalTeamRegistrationForm(personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj, disableValidation, onFormCompletion);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label={disableValidation === true ? 'Attendee Type' : 'Attendee Type*'}
            name="groupAttendeeTypeId"
            value={formState.groupAttendeeTypeId}
            error={errorState.groupAttendeeTypeId}
            message={errorState.groupAttendeeTypeId}
            areValuesIntegers={false}
            isLoading={optionsState.areOptionsLoaded !== true}
            options={optionsState.attendTypeOptions}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'groupAttendeeTypeId', newValueLabel, 'groupAttendeeTypeName'); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label={disableValidation === true ? 'Role' : 'Role*'}
            name="groupRoleTypeId"
            value={formState.groupRoleTypeId}
            error={errorState.groupRoleTypeId}
            message={errorState.groupRoleTypeId}
            areValuesIntegers={false}
            isLoading={optionsState.areOptionsLoaded !== true}
            options={optionsState.roleOptions}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'groupRoleTypeId', newValueLabel, 'groupRoleTypeName'); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'Professional Swimmer' : 'Professional Swimmer*'}
            name="professionalSwimmer"
            value={formState.professionalSwimmer}
            error={errorState.professionalSwimmer}
            message={errorState.professionalSwimmer}
            onChange={(value) => { onFormValueChanged('professionalSwimmer', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'City of Birth' : 'City of Birth*'}
            name="cityOfBirth"
            value={formState.cityOfBirth}
            error={errorState.cityOfBirth}
            message={errorState.cityOfBirth}
            onChange={(value) => { onFormValueChanged('cityOfBirth', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'Country of Birth' : 'Country of Birth*'}
            name="countryOfBirth"
            value={formState.countryOfBirth}
            error={errorState.countryOfBirth}
            message={errorState.countryOfBirth}
            onChange={(value) => { onFormValueChanged('countryOfBirth', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'Fan Mail Address' : 'Fan Mail Address*'}
            name="fanMailAddress"
            value={formState.fanMailAddress}
            error={errorState.fanMailAddress}
            message={errorState.fanMailAddress}
            onChange={(value) => { onFormValueChanged('fanMailAddress', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'Fan Mail City' : 'Fan Mail City*'}
            name="fanMailCity"
            value={formState.fanMailCity}
            error={errorState.fanMailCity}
            message={errorState.fanMailCity}
            onChange={(value) => { onFormValueChanged('fanMailCity', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <StatesCombobox
            label={disableValidation === true ? 'Fan Mail State' : 'Fan Mail State*'}
            name="fanMailStateCode"
            valueToMatch={formState.fanMailStateName}
            error={errorState.fanMailStateCode}
            message={errorState.fanMailStateCode}
            isUSA={true}
            onChange={(newValue, newValueLabel, e) => {
              onValueTextPairChanged(newValue, 'fanMailStateCode', newValueLabel, 'fanMailStateName', e);
            }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'Fan Mail Zip Code' : 'Fan Mail Zip Code*'}
            name="fanMailZip"
            value={formState.fanMailZip}
            error={errorState.fanMailZip}
            message={errorState.fanMailZip}
            onChange={(value) => { onFormValueChanged('fanMailZip', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'Passport Number' : 'Passport Number*'}
            name="passportNumber"
            value={formState.passportNumber}
            error={errorState.passportNumber}
            message={errorState.passportNumber}
            onChange={(value) => { onFormValueChanged('passportNumber', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label={disableValidation === true ? 'Passport Effective Date' : 'Passport Effective Date*'}
            name="passportEffectiveDate"
            value={formState.passportEffectiveDate}
            error={errorState.passportEffectiveDate}
            message={errorState.passportEffectiveDate}
            onChange={(value) => { onFormValueChanged('passportEffectiveDate', value); }}
            countOfYears={30}
            startYearOffset={-15} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label={disableValidation === true ? 'Passport Expiration Date' : 'Passport Expiration Date*'}
            name="passportExpirationDate"
            value={formState.passportExpirationDate}
            error={errorState.passportExpirationDate}
            message={errorState.passportExpirationDate}
            onChange={(value) => { onFormValueChanged('passportExpirationDate', value); }}
            countOfYears={30}
            startYearOffset={-15} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'Known Traveler Number' : 'Known Traveler Number*'}
            name="knownTravelerNumber"
            value={formState.knownTravelerNumber}
            error={errorState.knownTravelerNumber}
            message={errorState.knownTravelerNumber}
            onChange={(value) => { onFormValueChanged('knownTravelerNumber', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label={disableValidation === true ? 'Preferred Airline' : 'Preferred Airline*'}
            name="preferredAirlineId"
            value={formState.preferredAirlineId}
            error={errorState.preferredAirlineId}
            message={errorState.preferredAirlineId}
            areValuesIntegers={false}
            isLoading={optionsState.areOptionsLoaded !== true}
            options={optionsState.airlineOptions}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'preferredAirlineId', newValueLabel, 'preferredAirlineName'); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label={disableValidation === true ? 'Aisle/Window Preference' : 'Aisle/Window Preference*'}
            name="aisleWindowPreferenceId"
            value={formState.aisleWindowPreferenceId}
            error={errorState.aisleWindowPreferenceId}
            message={errorState.aisleWindowPreferenceId}
            areValuesIntegers={false}
            isLoading={optionsState.areOptionsLoaded !== true}
            options={optionsState.aisleWindowOptions}
            onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'aisleWindowPreferenceId', newValueLabel, 'aisleWindowPreferenceName'); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'Airline Status' : 'Airline Status*'}
            name="airlineStatus"
            value={formState.airlineStatus}
            error={errorState.airlineStatus}
            message={errorState.airlineStatus}
            onChange={(value) => { onFormValueChanged('airlineStatus', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'Primary Health Provider' : 'Primary Health Provider*'}
            name="primaryHealthProvider"
            value={formState.primaryHealthProvider}
            error={errorState.primaryHealthProvider}
            message={errorState.primaryHealthProvider}
            onChange={(value) => { onFormValueChanged('primaryHealthProvider', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'Agent' : 'Agent*'}
            name="agent"
            value={formState.agent}
            error={errorState.agent}
            message={errorState.agent}
            onChange={(value) => { onFormValueChanged('agent', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'High School Name' : 'High School Name*'}
            name="highSchoolName"
            value={formState.highSchoolName}
            error={errorState.highSchoolName}
            message={errorState.highSchoolName}
            onChange={(value) => { onFormValueChanged('highSchoolName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <YearDropdown
            label={disableValidation === true ? 'High School Graduation Year' : 'High School Graduation Year*'}
            name="highSchoolGraduationYear"
            value={formState.highSchoolGraduationYear}
            error={errorState.highSchoolGraduationYear}
            message={errorState.highSchoolGraduationYear}
            countOfYears={36}
            startYearOffset={-27}
            onChange={(newValue) => { onFormValueChanged('highSchoolGraduationYear', newValue) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={disableValidation === true ? 'College Name' : 'College Name*'}
            name="collegeName"
            value={formState.collegeName}
            error={errorState.collegeName}
            message={errorState.collegeName}
            onChange={(value) => { onFormValueChanged('collegeName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <YearDropdown
            label={disableValidation === true ? 'College Graduation Year' : 'College Graduation Year*'}
            name="collegeGraduationYear"
            value={formState.collegeGraduationYear}
            error={errorState.collegeGraduationYear}
            message={errorState.collegeGraduationYear}
            countOfYears={36}
            startYearOffset={-27}
            onChange={(newValue) => { onFormValueChanged('collegeGraduationYear', newValue) }} />
        </div>
        {disableValidation === true &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label={'Potential/Confirmed'}
              name="potentialOrConfirmedId"
              value={formState.potentialOrConfirmedId}
              error={errorState.potentialOrConfirmedId}
              message={errorState.potentialOrConfirmedId}
              areValuesIntegers={false}
              isLoading={optionsState.areOptionsLoaded !== true}
              options={optionsState.potentialOrConfirmedOptions}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'potentialOrConfirmedId', newValueLabel, 'potentialOrConfirmedName'); }} />
          </div>
        }
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
          {errorState && errorState.errorOnSubmitAction
            && <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction}</p>
          }
        </div>
      </div>
    </form>
  );
};

export default NationalTeamRegistrationForm;