import { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

import SGColumnHeader from '../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../common/utils/SGConstants';

const LargeGridRow = ({ evaluation, formState, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked, onRunViewHistoryReport }) => {
  if ((formState?.showAllEvaluations === false && evaluation.isActiveEvaluation === true) || formState?.showAllEvaluations === true) {
    return (
      <tr>
        {evaluation.hasEvaluation === false ? <td> {evaluation.officialFirstName || ''} {evaluation.officialLastName || ''}</td> :
          evaluation.isActiveEvaluation === true ?
            <td><a onClick={(e) => onGoToEvaluationFormClicked(e, evaluation)}>{evaluation.officialFirstName || ''} {evaluation.officialLastName || ''}</a></td> :
            <td><a onClick={(e) => onReadOnlyEvaluationClicked(e, evaluation)}>{evaluation.officialFirstName || ''} {evaluation.officialLastName || ''}</a></td>}
        <td>{evaluation.meetName || ''}</td>
        <td>{evaluation.oqmNumber || ''}</td>
        <td>{evaluation.positionTypeName || ''}</td>
        <td>{evaluation.evaluationLevelName || ''}</td>
        <td>{evaluation.evaluatorFirstName || ''} {evaluation.evaluatorLastName || ''}</td>
        <td>{evaluation.evaluationDate ? formatDate(evaluation.evaluationDate) : ''}</td>
        <td>{evaluation.hasEvaluation === true ? 'Yes' : 'No'}</td>
        <td>
          {evaluation.hasEvaluation === false &&
            <Fragment>
              <button className={global.IconButtonMargin} type="button" onClick={(e) => onGoToEvaluationFormClicked(e, evaluation)}>
                <EditIcon />
              </button>
              {onDeleteEvaluationClicked && evaluation.isActiveEvaluation === true &&
                <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteEvaluationClicked(e, evaluation)}>
                  <DeleteIcon />
                </button>}
            </Fragment>}
          {evaluation.isActiveEvaluation === true &&
            <ActionIntraPageButton onClick={(e) => onRunViewHistoryReport(e, evaluation)}>View History</ActionIntraPageButton>
          }
        </td>
      </tr>
    );
  }
  else {
    return (
      <Fragment />
    )
  }
}

const LargeGrid = ({ state, formState, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked, onRunViewHistoryReport }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader style={{ minWidth: '115px' }} sortableGridState={sortableGridState} columnLabel={'Official Name'} columnField={'officialLastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '115px' }} sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '65px' }} sortableGridState={sortableGridState} columnLabel={'OQM #'} columnField={'oqmNumber'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th style={{ minWidth: '125px' }}>Position Worked</th>
          <th style={{ minWidth: '120px' }}>Evaluation Level</th>
          <th style={{ minWidth: '115px' }}>Evaluator Name</th>
          <SGColumnHeader style={{ minWidth: '130px' }} sortableGridState={sortableGridState} columnLabel={'Evaluation Date'} columnField={'evaluationDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <th>Completed?</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr><td colSpan="9">{state.isArrayLoading === true ? 'Loading...' : 'No Meet Evaluations'}</td></tr>
          ) : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((evaluation, i) => <LargeGridRow key={i} evaluation={evaluation} formState={formState}
              onGoToEvaluationFormClicked={onGoToEvaluationFormClicked} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked}
              onDeleteEvaluationClicked={onDeleteEvaluationClicked} onRunViewHistoryReport={onRunViewHistoryReport} />
            ) : (
              <tr>
                <td colSpan="9">No Meet Evaluations</td>
              </tr>
            )
        }
      </tbody>
    </table >
  );
};

const SmallGridRowIcons = ({ evaluation, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked, onRunViewHistoryReport }) => (
  <Fragment>
    {evaluation.isActiveEvaluation &&
      <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onRunViewHistoryReport(e, evaluation)}>View History</ActionIntraPageButton></div>
    }
    {evaluation.hasEvaluation === false ?
      <Fragment>
        &nbsp;
        {onDeleteEvaluationClicked && evaluation.isActiveEvaluation === true &&
          <button className={global.IconButton} type="button" onClick={(e) => onDeleteEvaluationClicked(e, evaluation)}>
            <DeleteIcon />
          </button>}
        <button className={onDeleteEvaluationClicked ? global.IconButtonMobileMargin : global.IconButton} type="button" onClick={(e) => onGoToEvaluationFormClicked(e, evaluation)}>
          <EditIcon />
        </button>
      </Fragment>
      :
      <Fragment>
        {evaluation.isActiveEvaluation === true ?
          <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onGoToEvaluationFormClicked(e, evaluation)}>Edit Evaluation</ActionIntraPageButton></div> :
          <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onReadOnlyEvaluationClicked(e, evaluation)}>View Evaluation</ActionIntraPageButton></div>}
      </Fragment>}
  </Fragment >
);

const SmallGridRow = ({ evaluation, formState, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked, onRunViewHistoryReport }) => {
  if ((formState?.showAllEvaluations === false && evaluation.isActiveEvaluation === true) || formState?.showAllEvaluations === true) {
    return (
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          {evaluation.hasEvaluation === true || evaluation.isActiveEvaluation === true ? <span /> : <span className='hidden-xs'>{evaluation.officialFirstName || ''} {evaluation.officialLastName || ''}</span>}
          &nbsp;
          <SmallGridRowIcons evaluation={evaluation} onGoToEvaluationFormClicked={onGoToEvaluationFormClicked} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} onDeleteEvaluationClicked={onDeleteEvaluationClicked} onRunViewHistoryReport={onRunViewHistoryReport} />
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            {evaluation.hasEvaluation === true || evaluation.isActiveEvaluation === true ?
              <Fragment>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                  Official Name
                </div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                  {evaluation.officialFirstName || <span>&nbsp;</span>}  {evaluation.officialLastName || <span>&nbsp;</span>}
                </div>
              </Fragment> :
              <Fragment>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                  Official Name
                </div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                  {evaluation.officialFirstName || <span>&nbsp;</span>}  {evaluation.officialLastName || <span>&nbsp;</span>}
                </div>
              </Fragment>}
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Meet Name
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.meetName || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              OQM Number
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.oqmNumber || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Position Worked
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.positionTypeName || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Evaluation Level
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.evaluationLevelName || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Evaluator Name
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.evaluatorFirstName || <span>&nbsp;</span>}  {evaluation.evaluatorLastName || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Completed?
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.hasEvaluation === true ? 'Yes' : 'No'}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Evaluation Date
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.evaluationDate ? formatDate(evaluation.evaluationDate) : <span>&nbsp;</span>}
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (<Fragment />)
  }
}

const SmallGrid = ({ state, formState, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked, onRunViewHistoryReport }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div> :
              <div className={global.SmallTableRowLabels}>No Meet Evaluations</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((evaluation, i) => <SmallGridRow key={i} evaluation={evaluation} formState={formState}
          onGoToEvaluationFormClicked={onGoToEvaluationFormClicked} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked}
          onDeleteEvaluationClicked={onDeleteEvaluationClicked} onRunViewHistoryReport={onRunViewHistoryReport} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Meet Evaluations</div>
            </div>
          </div>
        )
    }
  </div >
);

const MyOtsEvaluationsGrid = ({ state, formState, onGoToEvaluationFormClicked, onReadOnlyEvaluationClicked, onDeleteEvaluationClicked, onRunViewHistoryReport }) => (
  <Fragment>
    <LargeGrid state={state} formState={formState} onGoToEvaluationFormClicked={onGoToEvaluationFormClicked}
      onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} onDeleteEvaluationClicked={onDeleteEvaluationClicked} onRunViewHistoryReport={onRunViewHistoryReport} />
    <SmallGrid state={state} formState={formState} onGoToEvaluationFormClicked={onGoToEvaluationFormClicked}
      onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} onDeleteEvaluationClicked={onDeleteEvaluationClicked} onRunViewHistoryReport={onRunViewHistoryReport} />
  </Fragment>
);

export default MyOtsEvaluationsGrid;