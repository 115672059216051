import React, { createContext, useState } from 'react';

import SearchOtsOfficialsForBulkRecertifyData from './OtsOfficialsBulkRecertifyData';

export const SearchOtsOfficialsForBulkRecertifyStateContext = createContext();

const SearchOtsOfficialsForBulkRecertifyContextProvider = ({ children }) => {
  const stateHook = useState({ ...SearchOtsOfficialsForBulkRecertifyData.INITIAL_STATE });

  return (
    <SearchOtsOfficialsForBulkRecertifyStateContext.Provider value={stateHook}>
      {children}
    </SearchOtsOfficialsForBulkRecertifyStateContext.Provider>
  );
};

export default SearchOtsOfficialsForBulkRecertifyContextProvider;