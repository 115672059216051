export const localValidate = (formState) => {
  let errors = {};

  //Officials Qualifying Meet
  if (formState.isOfficialsQualifyingMeet !== true && formState.isOfficialsQualifyingMeet !== false) {
    errors.isOfficialsQualifyingMeet = 'Must select yes or no for this field';
  }

  //OQM Number
  if (formState.isOfficialsQualifyingMeet === true) {
    if (formState.oqmNumber.trim().length > 30) {
      errors.oqmNumber = 'OQM Number cannot exceed 30 characters';
    }
    else if (formState.oqmNumber.trim().length === 0) {
      errors.oqmNumber = 'OQM Number is required';
    }
  }

  return errors;
};

const OtsMeetInfoValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OtsMeetInfoValidation;