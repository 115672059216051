import { createContext, useState } from 'react';

export const OtsCertificationApplicationContextStateStateContext = createContext();

const OtsCertificationApplicationContextStateContextProvider = ({ children }) => {
  const stateHook = useState({
    personId: '',
    personPositionCertificationRequestId: '',
    memberId: '',
    officialName: '',
    lastNameFilter: '',
    personPositionCertificationProgressionId: -1,
    personPositionCertificationProgression: {},
    activeOfficialsWithApplications: [],
    isLoading: true
  });

  return (
    <OtsCertificationApplicationContextStateStateContext.Provider value={stateHook}>
      {children}
    </OtsCertificationApplicationContextStateStateContext.Provider>
  );
};

export default OtsCertificationApplicationContextStateContextProvider;