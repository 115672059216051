import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: {},
  message: '',
};

const GetOrgRoleStatusData = (orgRoleId, state, setState) => {
  if (state.isLoading !== true && orgRoleId && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgRoleIdForUrl = orgRoleId ? encodeURIComponent(orgRoleId) : 'NaN';
    const url = `/OrgRole/TopParentForChild/${orgRoleIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const OrgRoleStatusData = { INITIAL_STATE, GetOrgRoleStatusData };

export default OrgRoleStatusData;