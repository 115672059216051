
import { useContext } from 'react';

import { PersonCoursesForAdminRoleStateContext } from './PersonCoursesForAdminRoleContextProvider';

import PersonCoursesForAdminRoleData from './PersonCoursesForAdminRoleData';

const usePersonCoursesForAdminRole = () => {
  const [memberCoursesAdminRoleState, setMemberCoursesAdminRoleState] = useContext(PersonCoursesForAdminRoleStateContext);


  const getCoursesForAdminRole = (roleName) => {
    PersonCoursesForAdminRoleData.getCoursesForAdminRole(roleName, memberCoursesAdminRoleState, setMemberCoursesAdminRoleState);
  };

  return {
    memberCoursesAdminRoleState,
    setMemberCoursesAdminRoleState,
    getCoursesForAdminRole
  };
};

export default usePersonCoursesForAdminRole;