import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../../common/utils/HttpHelper';
import { isValidMemberId } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Member Id
  if (formState.memberId.trim() === '') {
    errors.memberId = 'Member Id is required';
  } else if (!isValidMemberId(formState.memberId.trim())) {
    errors.memberId = 'Member Id must be 14 alphanumeric characters';
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  //Member Id
  const memberIdForUrl = formState.memberId ? encodeURIComponent(formState.memberId) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${memberIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (objData.isOrgRoleDurationActive === false || !(objData.personId)) {
          errors.memberId = "Member Id does not have an active member registration";
        } else {
          formState.personId = objData.personId;
        }
      }
    })
    .catch(() => {
      errors.memberId = 'Member Id is not valid';
    });

  if (formState.isMeetReferee === true) {
    const personIdForUrl = formState.personId ? encodeURIComponent(formState.personId) : 'NaN';
    const roleGroupIdForUrl = formState.OFFICIALS_ROLES_ROLE_GROUP_ID ? encodeURI(formState.OFFICIALS_ROLES_ROLE_GROUP_ID) : 'NaN';
    await PersonHttpHelper(`/Person/${personIdForUrl}/hasActiveRegistration?roleGroupId=${roleGroupIdForUrl}`, 'GET')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          if (objData.hasActiveRegistration === false) {
            errors.memberId = "Member Id does not have an active official registration";
          }
        }
      })
      .catch(() => {
        errors.memberId = "Member Id does not have an active official registration";
      });
  }

  return errors;
};

const MeetStaffDetailValidation = async (formState) => {
  let errors = localValidate(formState);
  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default MeetStaffDetailValidation;