import React, { Fragment } from 'react';

import useHQCommitteeMembers from './UseHQCommitteeMembers';
import HQCommitteeMembersGrid from './HQCommitteeMembersGrid';

import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Combobox from '../../../../common/components/comboboxes/Combobox';

import Constants from '../../../../common/utils/Constants';

const HQCommitteeMembers = () => {
  const {
    isSaving,
    isGridLoading,
    viewState,
    gridState,
    onFilterCurrentMembers,
    onEditCommitteeMember,
    onAddCommitteeMember,
    onDeleteCommitteeMember,
    formState,
    errorState,
    committeeOptions,
    onValueTextPairChanged,
    HQ_COMMITTEE_ROLE_GROUP_ID,
    memberNameOptionsState,
  } = useHQCommitteeMembers();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3 excludeBar={true}>HQ Committee Members</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={onAddCommitteeMember}>Add Committee Member</ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-md-3">
          {HQ_COMMITTEE_ROLE_GROUP_ID && (
            <Dropdown
              label={"Committee Filter"}
              name={"committeeId"}
              value={formState.committeeId}
              options={committeeOptions}
              error={errorState.committeeId}
              message={errorState.committeeId}
              isLoading={false}
              areValuesIntegers={true}
              onChange={(newValue, newName, e) => e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'committeeId', newName, 'committeeName')} />
          )}</div>
        <div className="col-xs-12 col-md-3">
          <Combobox
            label="Member Name"
            name="memberNameId"
            isLoading={memberNameOptionsState?.length < 0}
            items={memberNameOptionsState}
            valueToMatch={formState.memberName}
            error={errorState.memberNameId}
            message={errorState.memberNameId}
            onChange={(newValue, newValueLabel, e) => {
              onValueTextPairChanged(newValue, 'memberNameId', newValueLabel, 'memberName', e);
            }} />
        </div>
        <div className="col-xs-12 col-md-4 pull-right">
          <span className='pull-right'>
            <YesNoSwitch
              label="Show Only Current Committee Members?"
              name="showCurrentCommittee"
              checked={viewState.toggleCurrentStaffRoles}
              onChange={onFilterCurrentMembers} />
          </span>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin ">
        <div className="col-xs-12">
          <HQCommitteeMembersGrid
            data={gridState}
            isLoading={isGridLoading}
            onEdit={onEditCommitteeMember}
            onDelete={onDeleteCommitteeMember}
            formState={formState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isGridLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default HQCommitteeMembers;