import { Fragment } from 'react';

import useHqOfferingInstance from './UseHqOfferingInstance';

import HqOfferingManagementContextLoadingModal from '../HqOfferingManagementContextLoadingModal';

import OfferingInstanceGrid from '../../components/grids/offeringInstance/OfferingInstanceGrid';

import Headings from '../../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import ReportPeriodDropdown from '../../../../../common/components/dropdowns/reportPeriodDropdown/ReportPeriodDropdown';

const HqOfferingInstance = () => {
  const {
    organizationId,
    state,
    offeringManagementInstanceState,
    onReportPeriodChanged,
    onAddOfferingInstanceClicked,
    onEditOfferingInstanceClicked,
  } = useHqOfferingInstance();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>HQ Offering Instances</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-8 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={(e) => onAddOfferingInstanceClicked(e)}>
            Add Offering Instance
          </ActionIntraPageButton>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-bottom-margin">
          {state.reportPeriodId > 0 &&
            <ReportPeriodDropdown
              label="Report Period"
              name="reportPeriodId"
              value={state.reportPeriodId}
              error={undefined}
              message={undefined}
              organizationId={organizationId}
              defaultOption={false}
              reverseDataOrder={true}
              currentAndFutureOptionsOnly={false}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value && onReportPeriodChanged(newValue, newValueLabel);
              }} />
          }
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <OfferingInstanceGrid
            gridData={offeringManagementInstanceState.arrayData}
            isLoading={offeringManagementInstanceState.isArrayLoading}
            onEditClicked={onEditOfferingInstanceClicked} />
        </div>
      </div>
      <HqOfferingManagementContextLoadingModal
        isLoading={false}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default HqOfferingInstance;