import { useState, useEffect } from 'react';
import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';
import useOtsCertificationApplicationData from '../../../../state/otsCertificationApplication/UseOtsCertificationApplicationData';
import Constants from '../../../../../common/utils/Constants';

const VIEW_NAMES = {
  CURRENT_CERTS: 'CURRENT_CERTS',
  APPLY: 'APPLY',
  STATUS: 'STATUS'
};

const INITIAL_VIEW_STATE = {
  viewName: VIEW_NAMES.CURRENT_CERTS,
  officialName: '',
  memberId: '',
  personPositionCertificationProgressionId: Constants.DEFAULT_ID,
  certificationPublicNote: '',
  publicNotes: '',
  position: {},
  availableCerts: []
};

const useCertificationApplication = () => {
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const { basePersonState, getBasePerson, clearBasePersonObjData } = useBasePersonData();
  const { getCurrentCertificationsForUser, getAvailableCertificationsForUser } = useOtsCertificationApplicationData();

  const onAddClicked = () => {
    setState({
      ...state,
      viewName: VIEW_NAMES.APPLY
    });
  };

  const onStatusClicked = (position) => {
    setState({
      ...state,
      viewName: VIEW_NAMES.STATUS,
      personPositionCertificationProgressionId: position.personPositionCertificationProgressionId,
      certificationPublicNote: position.certificationPublicNote,
      publicNotes: position.publicNotes,
      position: position
    });
  };

  const onCancelClicked = () => {
    getCurrentCertificationsForUser(basePersonState.objData.personId, true)
      .finally(() => {
        setState({
          ...state,
          viewName: VIEW_NAMES.CURRENT_CERTS,
          personPositionCertificationProgressionId: Constants.DEFAULT_ID,
          certificationPublicNote: '',
          publicNotes: '',
          position: {},
        });
      });
  };

  const onAddRecertPromoteSuccessful = () => {
    setState({...INITIAL_VIEW_STATE});
    clearBasePersonObjData(0);
  };

  useEffect(() => {
    if (basePersonState.isObjLoaded === true 
        && basePersonState?.objData?.personId 
        && state.memberId !== basePersonState?.objData?.memberId) {
      const name1 = basePersonState?.objData?.preferredName || basePersonState?.objData?.firstName;
      const newState = {
        ...state,
        officialName: `${name1} ${basePersonState?.objData?.lastName}`,
        memberId: basePersonState?.objData?.memberId
      };

      setState({...newState});

      getCurrentCertificationsForUser(basePersonState.objData.personId);
      getAvailableCertificationsForUser(basePersonState.objData.personId)
        .then((data) => {
          if (Array.isArray(data?.arrayData)){
            setState({
              ...newState,
              availableCerts: data.arrayData
            });
          }
        });
    } else {
      getBasePerson(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  return {
    ...state,
    VIEW_NAMES,
    buttonClickHandlers: {
      onAddClicked,
      onStatusClicked,
      onCancelClicked,
      onAddRecertPromoteSuccessful
    }
  };
};

export default useCertificationApplication;