import useZoneDropdown from './UseZoneDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css'

const ZoneDropdown = ({ label, name, value, error, message, onChange }) => {
  const { zoneState } = useZoneDropdown();
  return zoneState.message
    ? <span className={global.LoadingMsg}>{zoneState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={zoneState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={zoneState.isLoading}
      />
    );
};

export default ZoneDropdown;