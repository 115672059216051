import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import getPersonNationalTeamAttachmentFileTypes from './GetPersonNationalTeamAttachmentFileTypesData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};



const PersonNationalTeamFileTypeData = { INITIAL_STATE, getPersonNationalTeamAttachmentFileTypes };

export default PersonNationalTeamFileTypeData;
