import Constants from '../../../common/utils/Constants';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  dataAsOptionsWithNames: [],
  message: ''
};

export const defaultNarrativeTypeState = {
  narrativeTypeId: Constants.DEFAULT_ID,
  narrativeTypeName: ''
};

const getNarrativeTypes = (state, setState) => {
  if (state.isLoading !== true && state.isLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      data: [],
      dataAsOptionsWithNames: [],
      message: 'Loading...'
    };
    setState(newState);

    const url = `/NarrativeType`;
    PersonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const blankNarrativeType = { narrativeTypeId: Constants.DEFAULT_ID, typeName: '--' };
          data.unshift(blankNarrativeType);
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((narrativeType) => { return { id: narrativeType.narrativeTypeId, name: narrativeType.typeName } }),
            message: '',
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  };
};

const NarrativeTypeData = { INITIAL_STATE, getNarrativeTypes };

export default NarrativeTypeData;