import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOfficialsForEvaluationSelectionData from './GetOfficialsForEvaluationSelectionData';
import postOfficialSelectionForEvaluationData from './PostOfficialSelectionForEvaluationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  otsMeetId: Constants.DEFAULT_ID,
  hasEvaluator: ''
};

const OfficialSelectionForEvaluationData = {
  INITIAL_STATE, 
  getOfficialsForEvaluationSelectionData,
  postOfficialSelectionForEvaluationData
};

export default OfficialSelectionForEvaluationData;