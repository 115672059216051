import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './ProfileFunFactsDetailValidation';

import useFunFactsTypeData from '../../../state/funFactsType/UseFunFactsTypeData';
import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useForm from '../../../../common/utils/UseForm';

import Constants from '../../../../common/utils/Constants';

const useProfileFunFactsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personProfileState, putPersonProfile } = usePersonProfileData();
  const { funFactsTypeState } = useFunFactsTypeData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, onValueTextPairChanged,
    isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [uiState, setUiState] = useState(getInitialUiState());

  const onSaveClicked = (e) => {
    if (isDirty === false && formState.personFunFactsId > 0) {
      onCancelClicked(e);
    }
    else {
      handleSubmit(e);
    }
  };

  // Redirects user to previous page
  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_PROFILE_FUN_FACTS?.route);
  };

  // Setup useEffect, determines if the form is going to be used for add or edit, then populates formState accordingly
  useEffect(() => {
    if (funFactsTypeState.isLoaded === true && personProfileState.isObjLoaded === true) {
      if (location.state && location.state.personFunFactsId && personProfileState.isObjLoaded === true) {
        const personFunFactsId = location.state.personFunFactsId;

        // Edit
        if (personFunFactsId > 0) {
          const selectedFunFact = personProfileState.objData.funFacts.find(e => e.personFunFactsId === personFunFactsId);
          setFormData({
            ...formState,
            personFunFactsId: selectedFunFact.personFunFactsId,
            funFactsId: selectedFunFact.funFactsId,
            textValue: selectedFunFact.textValue
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personProfileState, funFactsTypeState]);

  // Redirects the user to the previous page once all API calls have finished
  useEffect(() => {
    if (uiState.tryRedirect === true && personProfileState.isObjLoading === false && personProfileState.isSaving === false) {
      navigate(navRoutes.MEMBER_PROFILE_FUN_FACTS?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState, uiState.tryRedirect]);


  // Creates a copy of the personProfileState.objData and makes changes to it based on formState
  function createPutPersonObject() {
    const personToPut = JSON.parse(JSON.stringify(personProfileState.objData));

    // Is Edit
    if (formState.personFunFactsId > 0) {
      const index = personToPut.funFacts.findIndex(e => e.personFunFactsId === formState.personFunFactsId);

      if (personToPut.funFacts[index].funFactsId === formState.funFactsId &&
        personToPut.funFacts[index].textValue === formState.textValue) {
        return null;
      }

      personToPut.funFacts[index] = {
        ...personToPut.funFacts[index],
        funFactsId: formState.funFactsId,
        textValue: formState.textValue.trim(),
        funFacts: null
      };
    }
    // Is Add
    else {
      let personFunFacts = personToPut.funFacts;

      personFunFacts.push({
        personId: personToPut.personId,
        funFactsId: formState.funFactsId,
        textValue: formState.textValue.trim(),
        funFacts: null
      });

      personToPut.funFacts = personFunFacts;
    }

    return personToPut;
  }

  function submitFormCallback(formState) {
    if (personProfileState.isSaving === false) {
      const personToPut = createPutPersonObject();
      if (personToPut !== null) {
        putPersonProfile(personProfileState.objData.personId, personToPut);
      }
    }

    setUiState({
      ...uiState,
      tryRedirect: true
    });
  };

  // Holds initial formState
  function getInitialFormState() {
    return {
      personFunFactsId: Constants.DEFAULT_ID,
      funFactsId: Constants.DEFAULT_ID,
      textValue: ''
    };
  };

  // Holds initial view state
  function getInitialUiState() {
    return {
      tryRedirect: false
    };
  };

  return {
    formState,
    errorState,
    uiState,
    funFactsTypeState,
    personProfileState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  };
};

export default useProfileFunFactsDetail;