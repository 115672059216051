import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import putComment from './PutCommentData';
import postComment from './PostCommentData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const CommentData = {
  INITIAL_STATE, putComment, postComment
};

export default CommentData;