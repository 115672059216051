import { useContext } from 'react';

import { MeetOfficialSessionStateContext } from './MeetOfficialSessionContextProvider';

import MeetOfficialSessionData from './MeetOfficialSessionData';

const useMeetOfficialSessionData = () => {
  const [meetOfficialSessionState, setMeetOfficialSessionState] = useContext(MeetOfficialSessionStateContext);

  const getMeetOfficialSessionsByOtsMeetId = (otsMeetId) => MeetOfficialSessionData.getMeetOfficialSessionsByOtsMeetIdData(otsMeetId, meetOfficialSessionState, setMeetOfficialSessionState);
  const getMeetOfficialSessionsByOtsMeetIdAndPersonId = (otsMeetId, personId) => MeetOfficialSessionData.getMeetOfficialSessionsByOtsMeetIdAndPersonIdData(otsMeetId, personId, meetOfficialSessionState, setMeetOfficialSessionState);
  const postMeetOfficialSession = (meetOfficialSessionObj) => MeetOfficialSessionData.postMeetOfficialSessionData(meetOfficialSessionObj, meetOfficialSessionState, setMeetOfficialSessionState);
  const postMeetOfficialSessionByOtsMeetIdAndPersonId = (otsMeetId, personId, meetOfficialSessionObj) => MeetOfficialSessionData.postMeetOfficialSessionByOtsMeetIdAndPersonIdData(otsMeetId, personId, meetOfficialSessionObj, meetOfficialSessionState, setMeetOfficialSessionState);
  const putMeetOfficialSession = (meetOfficialSessionId, meetOfficialSessionObj) => MeetOfficialSessionData.putMeetOfficialSessionData(meetOfficialSessionId, meetOfficialSessionObj, meetOfficialSessionState, setMeetOfficialSessionState);
  const putMeetOfficialSessionByOtsMeetIdAndPersonId = (otsMeetId, personId, meetOfficialSessionObj) => MeetOfficialSessionData.putMeetOfficialSessionByOtsMeetIdAndPersonIdData(otsMeetId, personId, meetOfficialSessionObj, meetOfficialSessionState, setMeetOfficialSessionState);
  const deleteMeetOfficialSession = (meetOfficialSessionId) => MeetOfficialSessionData.deleteMeetOfficialSessionData(meetOfficialSessionId, meetOfficialSessionState, setMeetOfficialSessionState);

  const clearMeetOfficialSessionObjData = () => {
    setMeetOfficialSessionState({
      ...meetOfficialSessionState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearMeetOfficialSessionArrayData = () => {
    setMeetOfficialSessionState({
      ...meetOfficialSessionState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmMeetOfficialSessionSave = () => {
    setMeetOfficialSessionState({
      ...meetOfficialSessionState,
      isSaved: false
    });
  };

  return {
    meetOfficialSessionState,
    getMeetOfficialSessionsByOtsMeetId,
    getMeetOfficialSessionsByOtsMeetIdAndPersonId,
    postMeetOfficialSession,
    postMeetOfficialSessionByOtsMeetIdAndPersonId,
    putMeetOfficialSession,
    putMeetOfficialSessionByOtsMeetIdAndPersonId,
    deleteMeetOfficialSession,
    clearMeetOfficialSessionObjData,
    clearMeetOfficialSessionArrayData,
    confirmMeetOfficialSessionSave
  };
};

export default useMeetOfficialSessionData;