import React, { createContext, useState } from 'react';

import SocialMediaTypeData from './SocialMediaTypeData';

export const SocialMediaTypeStateContext = createContext();

const SocialMediaTypeContextProvider = ({ children }) => {
  const stateHook = useState(SocialMediaTypeData.INITIAL_STATE);

  return (
    <SocialMediaTypeStateContext.Provider value={stateHook}>
      {children}
    </SocialMediaTypeStateContext.Provider>
  );
};

export default SocialMediaTypeContextProvider;