import { isValidDate, isValidBirthDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.coachTypeId < 0) {
    errors.coachTypeId = 'Support Staff Type is required'
  }

  if (formState.isPrimary === '') {
    errors.isPrimary = 'Must select yes or no'
  }

  if (formState.confirmedDates === '') {
    errors.confirmedDates = 'Must select yes or no'
  }

  if (formState.isCoachOfRecord === '') {
    errors.isCoachOfRecord = 'Must select yes or no'
  }


  if (formState?.noExpirationDate === '') {
    errors.noExpirationDate = 'Must select yes or no'
  }

  let effectiveDate = new Date(formState.effectiveDate);
  let expirationDate = new Date(formState.expirationDate);

  //Effective Date
  if (formState.effectiveDate === Constants.BLANK_DATE_STRING) {
    errors.effectiveDate = 'Effective Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date must be a valid date';
  } else if (!isValidBirthDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Effective Date cannot be in the future';
  } else if (expirationDate < effectiveDate || formState.effectiveDate === formState.expirationDate) {
    errors.effectiveDate = 'Effective Date must be before the Expiration Date';
  }

  //Expiration Date
  if (formState?.noExpirationDate === false) {
    if (formState.expirationDate === Constants.BLANK_DATE_STRING) {
      errors.expirationDate = 'Expiration Date is required';
    } else if (!isValidDate(formState.expirationDate)) {
      errors.expirationDate = 'Expiration Date must be a valid date';
    } else if (expirationDate < effectiveDate || formState.effectiveDate === formState.expirationDate) {
      errors.expirationDate = 'Expiration Date must be after the Expiration Date';
    }
  }

  return errors;
};

const ProfileCoachTypeDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ProfileCoachTypeDetailValidation;