import { Fragment } from 'react';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const SmallGridRowIcons = ({ ineligibleReason, onEdit, onDelete }) => (
  <Fragment>
    {onDelete &&
      <button
        className={global.IconButtonMobileMargin}
        type="button"
        onClick={() => onDelete(ineligibleReason.personIneligibleReasonId, `${ineligibleReason.ineligibleReason?.reasonDescription || ''} (Start Date: ${formatDate(ineligibleReason.effectiveDate)}, End Date: ${formatDate(ineligibleReason.expirationDate)})`)}>
        <DeleteIcon />
      </button>}
    <button
      className={onDelete ? global.IconButtonMobileMargin : global.IconButton}
      type="button"
      onClick={() => onEdit(ineligibleReason.personIneligibleReasonId)}>
      <EditIcon />
    </button>
  </Fragment>
);

const SmallGridRow = ({ ineligibleReason, onEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{ineligibleReason.ineligibleReason?.reasonDescription || ''}</span>&nbsp;
      <SmallGridRowIcons ineligibleReason={ineligibleReason} onEdit={onEdit} onDelete={onDelete} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Ineligibility Reason
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {ineligibleReason.ineligibleReason?.reasonDescription || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Start Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {ineligibleReason.effectiveDate ? formatDate(ineligibleReason.effectiveDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          End Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {ineligibleReason.expirationDate ? formatDate(ineligibleReason.expirationDate) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const MemberIneligibleToCompeteSmallGrid = ({ gridData, isLoading, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div> :
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((ineligibleReason, i) => <SmallGridRow key={i} ineligibleReason={ineligibleReason} onEdit={onEdit} onDelete={onDelete} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Ineligible to Compete Reasons</div>
            </div>
          </div>
        )
    }
  </div>
)

export default MemberIneligibleToCompeteSmallGrid;