/* eslint-disable no-throw-literal */
const useMultiSelectCheckboxes = (options, onChange, value) => {
  if (!Array.isArray(value) || (value.length > 0 && (value[0].id <= 0 || !value[0].name))) {
    throw 'MultiSelectCheckboxes expects an array of objects shaped like {id, name} for the value parameter';
  }
  if (!Array.isArray(options) || (options.length > 0 && (options[0].id <= 0 || !options[0].name))) {
    throw 'MultiSelectCheckboxes expects an array of objects shaped like {id, name} for the options parameter';
  }

  const isOptionSelected = (option, value) => {
    const foundValue = value.find(x => x.id === option.id);
    return !foundValue ? false : true;
  };
  const optionsList = options.map(x => {return {id: x.id, name: x.name, isSelected: isOptionSelected(x, value)}});

  const onCheckChanged = (isChecked, option) => {
    const foundIndex = value.findIndex(x => x.id === option.id);
    if (isChecked === true && foundIndex < 0) {
        //add new value
        const foundOption = options.find(x => x.id === option.id);
        onChange([...value, foundOption]);
    } else if (isChecked !== true && foundIndex >= 0) {
        //remove value
        const newValue = [...value];
        newValue.splice(foundIndex, 1);
        onChange([...newValue]);
    }
  };

  return {optionsList, onCheckChanged};
};

export default useMultiSelectCheckboxes;