import { useState } from 'react';

import PersonSubscriptionsData from './PersonSubscriptionsData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_PERSON_SUBSCRIPTIONS_STATE = {
  ...BASIC_INITIAL_STATE
};

const usePersonSubscriptionsData = () => {
  const [personSubscriptionsState, setPersonSubscriptionsState] = useState(INITIAL_PERSON_SUBSCRIPTIONS_STATE);

  const getPersonSubscriptions = (personId) => {
    return PersonSubscriptionsData.getPersonSubscriptionsData(personId, personSubscriptionsState, setPersonSubscriptionsState);
  };

  const postPersonSubscriptions = (personId, subscriptionsObj) => {
    return PersonSubscriptionsData.postPersonSubscriptionsData(personId, subscriptionsObj, personSubscriptionsState, setPersonSubscriptionsState);
  };

  const resetPersonSubscriptionsState = () => {
    setPersonSubscriptionsState({
      ...INITIAL_PERSON_SUBSCRIPTIONS_STATE
    });
  };

  return {
    personSubscriptionsState,
    getPersonSubscriptions,
    postPersonSubscriptions,
    resetPersonSubscriptionsState
  };
};

export default usePersonSubscriptionsData;