import { useContext, useState } from 'react';

import { OtsCertificationApplicationStateContext } from './OtsCertificationApplicationContextProvider';
import { OtsCertificationApplicationContextStateStateContext } from './OtsCertificationApplicationContextStateContextProvider';

import OtsCertificationApplicationData from './OtsCertificationApplicationData';

const useOtsCertificationApplicationData = () => {
  const [otsCertificationApplicationState, setOtsCertificationApplicationState]
    = useContext(OtsCertificationApplicationStateContext);
  const [otsCertificationApplicationContextState, setOtsCertificationApplicationContextState]
    = useContext(OtsCertificationApplicationContextStateStateContext);
  const [state, setState] = useState({ ...OtsCertificationApplicationData.INITIAL_STATE });

  const getCurrentCertificationsForUser = (personId, clearContext = false) => {
    const newContextState = clearContext === true ? { ...OtsCertificationApplicationData.INITIAL_STATE } : otsCertificationApplicationState;
    return OtsCertificationApplicationData
      .getCurrentCertificationsForUser(personId, newContextState, setOtsCertificationApplicationState);
  };

  const getAvailableCertificationsForUser = (personId) => {
    return OtsCertificationApplicationData
      .getAvailableCertificationsForUser(personId, state, setState);
  };

  const getOpenCertificationApplicationsForPerson = (personId) => {
    return OtsCertificationApplicationData
      .getOpenCertificationApplicationsForPerson(personId, state, setState);
  };

  const getCertificationApplicationsHistoryForPerson = (personId) => {
    return OtsCertificationApplicationData
      .getCertificationApplicationsHistoryForPerson(personId, state, setState);
  };

  const getCertificationApplicationsForPersonByPersonPositionCertificationRequestId = (personId, personPositionCertificationRequestId) => {
    return OtsCertificationApplicationData
      .getCertificationApplicationsForPersonByPersonPositionCertificationRequestId(personId, personPositionCertificationRequestId, state, setState);
  };

  const getPersonPositionCertificationProgression = (personPositionCertificationProgressionId) => {
    return OtsCertificationApplicationData.getPersonPositionCertificationProgression(
      personPositionCertificationProgressionId, state, setState);
  };

  const getOfficialsWithActiveNationalApplication = () => {
    return OtsCertificationApplicationData.getOfficialsWithActiveNationalApplication(state, setState);
  };

  const saveProgressionStatus = (personId, personPositionCertificationRequestId, progressionStatusObj) => {
    return OtsCertificationApplicationData
      .putProgressionStatus(personId, personPositionCertificationRequestId, progressionStatusObj, state, setState);
  };

  const addNewApplications = (personId, positionCertificationProgressionIds) => {
    if (personId && positionCertificationProgressionIds.length > 0) {
      return OtsCertificationApplicationData
        .postNewApplications(personId, positionCertificationProgressionIds, state, setState)
        .finally(() => {
          setState({ ...OtsCertificationApplicationData.INITIAL_STATE });
        });
    }
  };

  return {
    localOtsCertificationApplicationState: state,
    otsCertificationApplicationState,
    otsCertificationApplicationContextState,
    setOtsCertificationApplicationContextState,
    getCurrentCertificationsForUser,
    getAvailableCertificationsForUser,
    getOpenCertificationApplicationsForPerson,
    getCertificationApplicationsHistoryForPerson,
    getCertificationApplicationsForPersonByPersonPositionCertificationRequestId,
    getPersonPositionCertificationProgression,
    getOfficialsWithActiveNationalApplication,
    saveProgressionStatus,
    addNewApplications
  };
};

export default useOtsCertificationApplicationData;