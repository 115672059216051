import React, { Fragment } from 'react';

import useNationalTeamSupportStaff from './UseNationalTeamSupportStaff';

import NationalTeamSupportStaffGrid from './NationalTeamSupportStaffGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';

const NationalTeamSupportStaff = () => {
  const {
    title,
    DEFAULT_ID,
    Constants,
    personNationalTeamState,
    modalState,
    onAddEditSupportStaff,
    onDeleteSupportStaff,
    onOpenModalClicked,
    onModalCanceled,
    onAddStaffType
  } = useNationalTeamSupportStaff();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{title}</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={(e) => onAddEditSupportStaff(e, DEFAULT_ID)}>Add {title}</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <NationalTeamSupportStaffGrid
            gridState={personNationalTeamState}
            onAddEdit={onAddEditSupportStaff}
            onDelete={onOpenModalClicked}
            onAddStaffType={onAddStaffType} />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.name}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteSupportStaff}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personNationalTeamState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personNationalTeamState.isSaving} />
    </Fragment>
  );
}
export default NationalTeamSupportStaff;