import { Fragment } from "react";

import ProfileCareerStatsGridLarge from "./ProfileCareerStatsGridLarge";
import ProfileCareerStatsGridSmall from "./ProfileCareerStatsGridSmall";

const ProfileCareerStatsGrid = ({ data, isLoading, onUpdateIncludeOnLaneSheet }) => (
  <Fragment>
    <ProfileCareerStatsGridLarge data={data} isLoading={isLoading} onUpdateIncludeOnLaneSheet={onUpdateIncludeOnLaneSheet} />
    <ProfileCareerStatsGridSmall data={data} isLoading={isLoading} onUpdateIncludeOnLaneSheet={onUpdateIncludeOnLaneSheet} />
  </Fragment>
);

export default ProfileCareerStatsGrid;