import React, { createContext, useState } from 'react';

import MemberAdministrationHQBoardData from './MemberAdministrationHQBoardData';

export const MemberAdministrationHQBoardStateContext = createContext();

const MemberAdministrationHQBoardContextProvider = ({ children }) => {
  const stateHook = useState(MemberAdministrationHQBoardData.INITIAL_STATE);

  return (
    <MemberAdministrationHQBoardStateContext.Provider value={stateHook}>
      {children}
    </MemberAdministrationHQBoardStateContext.Provider>
  );
};

export default MemberAdministrationHQBoardContextProvider;