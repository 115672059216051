import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const PutOtsOfficialNationalCertificationByPersonIdData = (personId, otsOfficialCertArray, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'NaN';
    const url = `/PersonPositionCertification/Person/${personIdForUrl}/NationalCertification`;
    PersonHttpHelper(url, 'PUT', otsOfficialCertArray)
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoaded: true,
            isSaving: false,
            isSaved: true,
            arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoaded: false,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

export default PutOtsOfficialNationalCertificationByPersonIdData;