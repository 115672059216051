import React, { createContext, useState } from 'react';

import DisabilityTypeData from './DisabilityTypeData';

export const DisabilityTypeStateContext = createContext();

const DisabilityTypeContextProvider = ({ children }) => {
  const stateHook = useState(DisabilityTypeData.INITIAL_STATE);

  return (
    <DisabilityTypeStateContext.Provider value={stateHook}>
      {children}
    </DisabilityTypeStateContext.Provider>
  );
};

export default DisabilityTypeContextProvider;