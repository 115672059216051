import { Fragment } from 'react';

import OtsOfficialClinicsGridLarge from './OtsOfficialClinicsGridLarge';
import OtsOfficialClinicsGridSmall from './OtsOfficialClinicsGridSmall';

const OtsOfficialClinicsGrid = ({ gridData }) => {
  return (
    <Fragment>
      <OtsOfficialClinicsGridLarge gridData={gridData} />
      <OtsOfficialClinicsGridSmall gridData={gridData} />
    </Fragment>
  );
};

export default OtsOfficialClinicsGrid;