import React, { Fragment } from 'react';

import useOtsOfficialBulkRecertifySelection from './UseOtsOfficialLscBulkRecertifySelection';

import SelectOrgUnit from '../../../../../common/components/selectOrgUnit/SelectOrgUnit';

const OtsOfficialLscBulkRecertifySelection = () => {
    const { TAXONOMIES, SCOPE, OTS_LSC_BULK_RECERTIFICATION_ROLE_GROUP_ID, NavLinks } = useOtsOfficialBulkRecertifySelection();

    return (
        <Fragment>
            <SelectOrgUnit
                pageTitle={'Select LSC'}
                navLink={NavLinks.OTS_LSC_BULK_RECERTIFICATION}
                getFunction={() => { }}
                roleGroupId={OTS_LSC_BULK_RECERTIFICATION_ROLE_GROUP_ID}
                TAXONOMIES={TAXONOMIES}
                SCOPE={SCOPE} />
        </Fragment>
    );
};

export default OtsOfficialLscBulkRecertifySelection;