import { useEffect, useState } from "react";

import validate from './OfferingInstanceOrgUnitAddFormValidation';

import useOfferingInstancesData from "../../../../../state/offeringInstances/UseOfferingInstancesData";

import UseForm from "../../../../../../common/utils/UseForm";
import { BLANK_DATE_STRING, DEFAULT_ID } from "../../../../../../common/utils/Constants";
import { formatDate } from "../../../../../../common/utils/DateFunctions";

const INITIAL_STATE = {
  areOfferingOptionsLoaded: false,
  offeringOptions: [],
  apiErrorMessage: ''
};

const INITIAL_FORM_STATE = {
  offeringInstanceId: DEFAULT_ID,
  offeringId: DEFAULT_ID,
  offeringName: '',
  registrationStartDate: BLANK_DATE_STRING,
  registrationEndDate: BLANK_DATE_STRING,
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING,
  feeAmount: 0,
  maxLscFeeAmount: null,
  reportPeriodStartDate: null,
  reportPeriodEndDate: null,
};

const useOfferingInstanceOrgUnitAddForm = (onSubmitFormCallback, reportPeriodObj, orgUnitObj) => {
  const { offeringInstancesState, getAvailableOfferingInstances } = useOfferingInstancesData();
  const { formState, errorState, setFormState, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, formatBeforeSubmit, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onOfferingInstanceChanged = (offeringInstanceId) => {
    const offeringInstance = offeringInstancesState.arrayData.find((X) => X.offeringInstanceId === offeringInstanceId);

    if (offeringInstance) {
      setFormState({
        ...formState,
        offeringInstanceId,
        offeringId: offeringInstance.offeringId,
        offeringName: offeringInstance.offeringName,
        registrationStartDate: offeringInstance.defaultRegStartDate ? formatDate(offeringInstance.defaultRegStartDate) : BLANK_DATE_STRING,
        registrationEndDate: offeringInstance.defaultRegEndDate ? formatDate(offeringInstance.defaultRegEndDate) : BLANK_DATE_STRING,
        effectiveDate: offeringInstance.defaultEffectiveDate ? formatDate(offeringInstance.defaultEffectiveDate) : BLANK_DATE_STRING,
        expirationDate: offeringInstance.defaultExpirationDate ? formatDate(offeringInstance.defaultExpirationDate) : BLANK_DATE_STRING,
        maxLscFeeAmount: offeringInstance.maxLscFeeAmount,
        reportPeriodStartDate: formatDate(reportPeriodObj.startDate),
        reportPeriodEndDate: formatDate(reportPeriodObj.endDate),
      });
    }
  };

  useEffect(() => {
    if (state.areOfferingOptionsLoaded === true && offeringInstancesState.isArrayLoaded === true) {
      if (state.offeringOptions.length > 0) {
        onOfferingInstanceChanged(state.offeringOptions[0]?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.areOfferingOptionsLoaded, offeringInstancesState.isArrayLoaded]);

  useEffect(() => {
    if (reportPeriodObj?.reportPeriodId > 0 && orgUnitObj?.orgUnitId) {
      const getAvailableOfferingInstancesPromise = getAvailableOfferingInstances(orgUnitObj.orgUnitId, reportPeriodObj.reportPeriodId);

      if (getAvailableOfferingInstancesPromise ?? false) {
        getAvailableOfferingInstancesPromise.then((newState) => {
          if (newState ?? false) {
            const options = newState.arrayData.map((x) => { return { id: x.offeringInstanceId, name: x.offeringName } });

            setState({
              ...state,
              areOfferingOptionsLoaded: true,
              offeringOptions: options
            });
          }
        }).catch((e) => {
          setState({
            ...state,
            apiErrorMessage: e?.message || 'Failed to retrieve available offerings',
          });
        });
      }
    } else {
      setState({
        ...state,
        apiErrorMessage: 'Failed to load available offerings'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodObj, orgUnitObj]);

  function formatBeforeSubmit(formState) {
    const submitObj = {
      reportPeriodId: reportPeriodObj.reportPeriodId,
      orgUnitId: orgUnitObj.orgUnitId,

      offeringInstanceId: formState.offeringInstanceId,
      offeringId: formState.offeringId,
      registrationStartDate: formState.registrationStartDate,
      registrationEndDate: formState.registrationEndDate,
      effectiveDate: formState.effectiveDate,
      expirationDate: formState.expirationDate,
      feeAmount: formState.feeAmount
    };

    onSubmitFormCallback(submitObj);
  }

  return {
    state,
    formState,
    errorState,

    updateFormState,
    onOfferingInstanceChanged,
    handleSubmit
  };
};

export default useOfferingInstanceOrgUnitAddForm;