import React, { Fragment } from 'react';

import OtsMeetStaffLargeGrid from './OtsMeetStaffLargeGrid';
import OtsMeetStaffSmallGrid from './OtsMeetStaffSmallGrid';

const OtsMeetStaffGrid = ({ gridData, isLoading, onEditStaff }) => {
  return (
    <Fragment>
      <OtsMeetStaffLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditStaff={onEditStaff}
      />
      <OtsMeetStaffSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditStaff={onEditStaff}
      />
    </Fragment>
  )
};

export default OtsMeetStaffGrid;