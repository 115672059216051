import { useContext } from 'react';

import { PersonGeneralNationalTeamStateContext } from './PersonGeneralNationalTeamContextProvider';
import PersonGeneralNationalTeamData from './PersonGeneralNationalTeamData';

const usePersonGeneralNationalTeamData = () => {
  const [personGeneralNationalTeamState, setPersonGeneralNationalTeamState] = useContext(PersonGeneralNationalTeamStateContext)

  const getPersonGeneralNationalTeam = (personId) => PersonGeneralNationalTeamData.getPersonGeneralNationalTeam(personId, personGeneralNationalTeamState, setPersonGeneralNationalTeamState);
  const putPersonGeneralNationalTeam = (personId, personObj) => PersonGeneralNationalTeamData.putPersonGeneralNationalTeam(personId, personObj, personGeneralNationalTeamState, setPersonGeneralNationalTeamState);
  const deletePersonNationalTeamContact = (personNationalTeamContactId) => PersonGeneralNationalTeamData.deletePersonNationalTeamContact(personNationalTeamContactId, personGeneralNationalTeamState, setPersonGeneralNationalTeamState);
  const deletePersonNationalTeamContactAddress = (personNationalTeamContactAddressId) => PersonGeneralNationalTeamData.deletePersonNationalTeamContactAddress(personNationalTeamContactAddressId, personGeneralNationalTeamState, setPersonGeneralNationalTeamState);
  const deletePersonNationalTeamContactPhone = (personNationalTeamContactPhoneId) => PersonGeneralNationalTeamData.deletePersonNataionalTeamContactPhone(personNationalTeamContactPhoneId, personGeneralNationalTeamState, setPersonGeneralNationalTeamState);

  const clearObjData = () => {
    setPersonGeneralNationalTeamState({
      ...personGeneralNationalTeamState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearArrayData = () => {
    setPersonGeneralNationalTeamState({
      ...personGeneralNationalTeamState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSave = () => {
    setPersonGeneralNationalTeamState({
      ...personGeneralNationalTeamState,
      isSaved: false
    });
  };

  return {
    personGeneralNationalTeamState,
    setPersonGeneralNationalTeamState,
    clearObjData,
    clearArrayData,
    confirmSave,
    getPersonGeneralNationalTeam,
    putPersonGeneralNationalTeam,
    deletePersonNationalTeamContact,
    deletePersonNationalTeamContactAddress,
    deletePersonNationalTeamContactPhone
  };
};

export default usePersonGeneralNationalTeamData;