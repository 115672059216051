import { useState } from "react";

import OfferingInstancesData from "./OfferingInstancesData";

const useOfferingInstancesData = () => {
  const [offeringInstancesState, setOfferingInstancesState] = useState(OfferingInstancesData.INITIAL_OFFERING_INSTANCES_STATE);

  const getAvailableOfferingInstances = (orgUnitId, reportPeriodId) => {
    return OfferingInstancesData.getAvailableOfferingInstancesData(orgUnitId, reportPeriodId, offeringInstancesState, setOfferingInstancesState);
  };

  return {
    offeringInstancesState,

    getAvailableOfferingInstances
  };
};

export default useOfferingInstancesData;