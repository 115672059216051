import React, { createContext, useState } from 'react';

import OfficialNationalCertificationData from './OfficialNationalCertificationData';

export const OfficialNationalCertificationStateContext = createContext();

const OfficialNationalCertificationContextProvider = ({ children }) => {
  const stateHook = useState(OfficialNationalCertificationData.INITIAL_STATE);

  return (
    <OfficialNationalCertificationStateContext.Provider value={stateHook}>
      {children}
    </OfficialNationalCertificationStateContext.Provider>
  );
};

export default OfficialNationalCertificationContextProvider;