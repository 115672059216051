import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../UseMemberAdministration';

import useMemberAdministrationHqCommitteeData from '../../../../common/state/memberAdministration/hQCommittee/UseMemberAdministrationHQCommitteeData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';
import useForm from '../../../../common/utils/UseForm';
import useOrgRoleStaffRoleHierarchyData from '../../../../common/state/orgRoleStaffRoleHierarchy/UseOrgRoleStaffRoleHierarchyData';

const INITIAL_FORM_STATE = {
  committeeId: '',
  committeeName: '',
  memberNameId: '',
  memberName: ''
}

const INITIAL_COMMITTEE_FILTER_STATE = {
  isArrayLoaded: false,
  arrayData: []
}

const useHQCommitteeMembers = () => {
  const navigate = useNavigate();
  const { ORG_ROLE_FIELD_TERM_LENGTH_FIELD_NAME, ORG_ROLE_FIELD_REPRESENTATIVE_TYPE_FIELD_NAME, HQ_COMMITTEE_ROLE_GROUP_ID,
    environmentVariableState, } = useEnvironmentVariableData();
  const [viewState, setViewState] = useState(getInitialViewState);
  const [gridState, setGridState] = useState([]);
  const { formState, errorState, onFormValueChanged, onValueTextPairChanged, setFormState, setIsDirty } = useForm(INITIAL_FORM_STATE);
  const { orgRoleStaffRoleHierarchyState, getOrgRolesStaffRolesHierarchy } = useOrgRoleStaffRoleHierarchyData();
  const [committeeFilterState, setCommitteeState] = useState(INITIAL_COMMITTEE_FILTER_STATE);
  const [memberNameOptionsState, setMemberNameOptionsState] = useState([]);
  const {
    memberAdministrationHQCommitteeState,
    getHQCommitteeMembers,
    deleteHQCommitteeMember,
    confirmSave,
    memberAdminHqCommitteeFilterState,
    setMemberAdminHqCommitteeFilterState } = useMemberAdministrationHqCommitteeData();


  const onAddCommitteeMember = () => {

    setMemberAdminHqCommitteeFilterState({ ...memberAdminHqCommitteeFilterState, filterObject: createFilterObject(formState) });

    navigate(navLinks.HQ_COMMITTEE_ADD);
  };

  const createFilterObject = (formState) => {
    return {
      committeeId: formState.committeeId.trim() || undefined,
      committeeName: formState.committeeName.trim() || undefined,
      memberNameId: formState.memberNameId === Constants?.DEFAULT_ID ? undefined : formState?.memberNameId.trim() || undefined,
      memberName: formState.memberName.trim() || undefined,
    }
  }

  const onEditCommitteeMember = (personId, personOrgRoleDurationId) => {

    setMemberAdminHqCommitteeFilterState({ ...memberAdminHqCommitteeFilterState, filterObject: createFilterObject(formState) });

    navigate(navLinks.HQ_COMMITTEE_EDIT, { state: { personId, personOrgRoleDurationId } });
  };


  const onDeleteCommitteeMember = (e, personOrgRoleDurationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deleteHQCommitteeMember(personOrgRoleDurationId);
  };

  const onFilterCurrentMembers = () => {
    if (memberAdministrationHQCommitteeState.isArrayLoading === false) {
      setViewState({
        ...viewState,
        toggleCurrentStaffRoles: !viewState.toggleCurrentStaffRoles
      });
      setGridState(filterCommitteeMembersForGrid(memberAdministrationHQCommitteeState.arrayData, !viewState.toggleCurrentStaffRoles));
    }
  };

  useEffect(() => {
    if (memberAdministrationHQCommitteeState.isSaved === true) {
      confirmSave();
    } else {
      getHQCommitteeMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationHQCommitteeState.isSaved]);

  useEffect(() => {
    if (memberAdministrationHQCommitteeState.isArrayLoading === false && memberAdministrationHQCommitteeState.isArrayLoaded === true
      && environmentVariableState.isLoaded === true) {
      setGridState(filterCommitteeMembersForGrid(memberAdministrationHQCommitteeState.arrayData, viewState.toggleCurrentStaffRoles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationHQCommitteeState, environmentVariableState]);


  useEffect(() => {
    if (orgRoleStaffRoleHierarchyState?.isArrayLoading !== true && orgRoleStaffRoleHierarchyState?.isArrayLoaded !== true && HQ_COMMITTEE_ROLE_GROUP_ID) {

      getOrgRolesStaffRolesHierarchy(HQ_COMMITTEE_ROLE_GROUP_ID);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleStaffRoleHierarchyState, HQ_COMMITTEE_ROLE_GROUP_ID]);

  useEffect(() => {
    if (memberAdministrationHQCommitteeState.isArrayLoading === false && memberAdministrationHQCommitteeState.isArrayLoaded === true
      && Array.isArray(memberAdministrationHQCommitteeState?.arrayData) === true && memberAdministrationHQCommitteeState?.arrayData?.length > 0) {
      let tempMemberArray = [];
      memberAdministrationHQCommitteeState?.arrayData?.forEach(member => {
        if (member.personId !== '') {
          tempMemberArray.push({ id: member?.personId, name: `${member.firstName || ''} ${member.lastName || ''}` });
        }
      })

      setMemberNameOptionsState(tempMemberArray);
    }
  }, [memberAdministrationHQCommitteeState]);

  useEffect(() => {
    if (Array.isArray(committeeFilterState?.arrayData) === true && committeeFilterState?.arrayData?.length === 0
      && committeeFilterState?.isArrayLoaded === false && orgRoleStaffRoleHierarchyState?.isArrayLoaded === true
      && orgRoleStaffRoleHierarchyState?.arrayData.length > 0) {
      let tempCommiteeArray = [];
      if (Array.isArray(orgRoleStaffRoleHierarchyState?.arrayData) === true && orgRoleStaffRoleHierarchyState?.arrayData.length > 0) {
        orgRoleStaffRoleHierarchyState?.arrayData?.forEach(committee => {
          if (Array.isArray(committee?.children) === true && committee?.children?.length > 0) {
            tempCommiteeArray.push(...committee?.children);
          }
        })
      }

      setCommitteeState({
        ...committeeFilterState,
        arrayData: tempCommiteeArray,
        isArrayLoaded: true
      });
    }
  }, [committeeFilterState, orgRoleStaffRoleHierarchyState?.arrayData, orgRoleStaffRoleHierarchyState?.isArrayLoaded]);

  useEffect(() => {
    if (memberAdminHqCommitteeFilterState.filterObject && Object.keys(memberAdminHqCommitteeFilterState?.filterObject)?.length > 0) {
      const oldSearchValues = JSON.parse(JSON.stringify(memberAdminHqCommitteeFilterState.filterObject))

      setFormState({
        ...formState,
        ...oldSearchValues,

      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function filterCommitteeMembersForGrid(arrayData, toggleCurrentStaffRoles = false) {
    const today = new Date();
    const gridData = [];

    for (const staff of arrayData) {
      const roleDurations = [];

      if (Array.isArray(staff.orgRoles) && staff.orgRoles.length > 0) {
        if (toggleCurrentStaffRoles === true) {
          for (const duration of staff.orgRoles) {
            if (duration.isCurrent === true) {
              roleDurations.push({
                personOrgRoleDurationId: duration.personOrgRoleDurationId || Constants.DEFAULT_ID,
                personOrgRoleId: duration.personOrgRoleId || Constants.DEFAULT_ID,
                effectiveDate: duration.effectiveDate ? formatDate(duration.effectiveDate) : '',
                expirationDate: duration.expirationDate ? formatDate(duration.expirationDate) : '',
                isCurrent: duration.isCurrent === true ? true : false,
                canDelete: new Date(duration.expirationDate) > today ? true : false,
                orgRoleId: duration.orgRoleId || '',
                orgRoleName: duration.orgRoleName || '',
                representativeTypeValue: getOrgRoleFieldValue(duration.orgRoleFields, ORG_ROLE_FIELD_REPRESENTATIVE_TYPE_FIELD_NAME) || '',
                termLengthValue: getOrgRoleFieldValue(duration.orgRoleFields, ORG_ROLE_FIELD_TERM_LENGTH_FIELD_NAME) || '',
                isInGoodStandingExpirationDate: formatDate(duration?.isInGoodStandingExpirationDate)
              });
            }
          }
        } else {
          for (const duration of staff.orgRoles) {
            roleDurations.push({
              personOrgRoleDurationId: duration.personOrgRoleDurationId || Constants.DEFAULT_ID,
              personOrgRoleId: duration.personOrgRoleId || Constants.DEFAULT_ID,
              effectiveDate: duration.effectiveDate ? formatDate(duration.effectiveDate) : '',
              expirationDate: duration.expirationDate ? formatDate(duration.expirationDate) : '',
              isCurrent: duration.isCurrent === true ? true : false,
              canDelete: new Date(duration.expirationDate) > today ? true : false,
              orgRoleId: duration.orgRoleId || '',
              orgRoleName: duration.orgRoleName || '',
              representativeTypeValue: getOrgRoleFieldValue(duration.orgRoleFields, ORG_ROLE_FIELD_REPRESENTATIVE_TYPE_FIELD_NAME) || '',
              termLengthValue: getOrgRoleFieldValue(duration.orgRoleFields, ORG_ROLE_FIELD_TERM_LENGTH_FIELD_NAME) || '',
              isInGoodStandingExpirationDate: formatDate(duration?.isInGoodStandingExpirationDate)
            });
          }
        }
      }

      if (roleDurations.length > 0) {
        roleDurations.sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate));

        gridData.push({
          personId: staff.personId || '',
          name: `${staff.firstName || ''} ${staff.lastName || ''}`,
          firstName: staff.firstName || '',
          lastName: staff.lastName || '',
          email: staff.email || '',
          roleDurations
        });
      }
    }

    return gridData;
  };

  function getOrgRoleFieldValue(orgRoleFieldsArray, orgRoleFieldName) {
    if (Array.isArray(orgRoleFieldsArray) && orgRoleFieldsArray.length > 0 && orgRoleFieldName) {
      const targetOrgRoleField = orgRoleFieldsArray.find(x => x.fieldName === orgRoleFieldName);

      if (targetOrgRoleField && targetOrgRoleField.orgRoleFieldId) {
        return targetOrgRoleField.fieldValue;
      }
    }

    return null;
  };

  function getInitialViewState() {
    return {
      toggleCurrentStaffRoles: true
    };
  };

  return {
    isSaving: memberAdministrationHQCommitteeState.isSaving,
    isGridLoading: memberAdministrationHQCommitteeState.isArrayLoading,
    viewState,
    gridState,
    onFilterCurrentMembers,
    onEditCommitteeMember,
    onAddCommitteeMember,
    onDeleteCommitteeMember,
    formState,
    errorState,
    committeeOptions: committeeFilterState?.arrayData,
    onFormValueChanged,
    onValueTextPairChanged,
    HQ_COMMITTEE_ROLE_GROUP_ID,
    memberNameOptionsState,
    memberAdminHqCommitteeFilterState
  };
};

export default useHQCommitteeMembers;