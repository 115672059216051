import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getPersonNationalTeamData from './GetPersonNationalTeamData';
import putPersonNationalTeamData from './PutPersonNationalTeamData';
import getPersonNationalTeamAdvanced from './GetPersonNationalTeamAdvancedData';
import putPersonNationalTeamAdvanced from './PutPersonNationalTeamDataAdvanced';
import getPersonNationalTeamAttachment from './GetPersonNationalTeamAttachmentData';
import getPersonNationalTeamAttachmentsByPersonId from './GetPersonNationalTeamAttachmentsByPersonIdData';
import deletePersonNationalTeamAttachmentById from './DeletePersonNationalTeamAttachmentByIdData';
import putPersonNationalTeamAttachmentById from './PutPersonNationalTeamAttachmentByIdData';
import postPersonNationalTeamAttachment from './PostPersonNationalTeamAttachmentData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonNationalTeamData = {
  INITIAL_STATE: { ...INITIAL_STATE },
  getPersonNationalTeamData,
  putPersonNationalTeamData,
  getPersonNationalTeamAdvanced,
  putPersonNationalTeamAdvanced,
  getPersonNationalTeamAttachment,
  getPersonNationalTeamAttachmentsByPersonId,
  deletePersonNationalTeamAttachmentById,
  putPersonNationalTeamAttachmentById,
  postPersonNationalTeamAttachment
};

export default PersonNationalTeamData;