import useOfferingTypeClubForm from './UseOfferingTypeClubForm';

import Input from '../../../../../../common/components/inputs/Input';
import Textarea from '../../../../../../common/components/inputs/Textarea';
import ReadOnly from '../../../../../../common/components/readOnly/ReadOnly';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const OfferingTypeClubForm = ({ offeringObj, submitButtonText = 'Save', onSubmitFormCallback,
  onBackClicked, onDeleteClicked = undefined }) => {
  const {
    formState,
    errorState,

    updateFormState,
    handleSubmit
  } = useOfferingTypeClubForm(onSubmitFormCallback, offeringObj);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Offering Type"
            name="offeringTypeName"
            value={offeringObj.offeringTypeName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Offering Name*"
            name="offeringName"
            value={formState.offeringName}
            error={errorState.offeringName}
            message={errorState.offeringName}
            onChange={(value) => { updateFormState('offeringName', value); }} />
        </div>
        <div className='col-xs-12'>
          <Textarea
            className={global.Textarea}
            label="Offering Description*"
            name="offeringDescription"
            value={formState.offeringDescription}
            error={errorState.offeringDescription}
            message={errorState.offeringDescription}
            onChange={(value) => { updateFormState('offeringDescription', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
          {offeringObj.offeringId > 0 && onDeleteClicked &&
            <SecondaryButton type="button" onClick={(e) => onDeleteClicked(e, formState)}>Delete</SecondaryButton>
          }
        </div>
      </div>
    </form>
  );
};

export default OfferingTypeClubForm;