import React from 'react';

import useMultiSelectCheckboxes from './UseMultiSelectCheckboxes';

import global from '../../../common/components/GlobalStyle.module.css';
import style from './MultiSelectCheckboxes.module.css';
import useCheckbox from './UseCheckbox';

const Checkbox = ({ name, option, onCheckChanged, isDisabled }) => {
  return (
    <div className="row">
      <div className="col-xs-12">
      <input
        style={{ width: "20px" }}
        type="checkbox"
        checked={option.isSelected}
        id={`${name}${option.id}`}
        name={name}
        value={option.id}
        onChange={(e) => { onCheckChanged(e.target.checked, option) }}
        disabled={isDisabled === true}
      />
      <span> {option.name}</span>
      </div>
    </div>
  );
}

const Checkboxes = ({ optionsList, name, onCheckChanged, isDisabled }) => {
  return (
    <div>
      {optionsList.map((option, i) => <Checkbox key={i} name={name} option={option} onCheckChanged={onCheckChanged} isDisabled={isDisabled} />)}
    </div>
  );
};

const MultiSelectCheckboxes = ({ label, name, value, error, message, options, onChange, isLoading }) => {
  const { optionsList, onCheckChanged } = useMultiSelectCheckboxes(options, onChange, value);
  const { isDisabled } = useCheckbox('', error, style, global, false);

  return (
    <div className={global.ComponentFlex}>
      {label && <label className={global.UsasLabel}>{label}</label>}
      <div className={style.Checkboxes}>
        {
          isLoading === true
            ? <div className={global.InputLoading}>Loading...</div>
            : <Checkboxes optionsList={optionsList} name={name} onCheckChanged={onCheckChanged} isDisabled={isDisabled} />
        }
        {error && <p className={global.ErrorMessage}>{message}</p>}
      </div>
    </div>
  );
};

export default MultiSelectCheckboxes;