
export const localValidate = (formState) => {
  let errors = {};

  // Offering Name
  if (formState.offeringName.trim() === '') {
    errors.offeringName = 'Offering Name is required';
  } else if (formState.offeringName.trim().length > 50) {
    errors.offeringName = 'Offering Name cannot be greater than 50 characters';
  }

  // Age Start 
  if (parseInt(formState.ageStart) === 0 || parseInt(formState.ageStart) < 0) {
    errors.ageStart = 'Age Start must be greater than 0';
  } else if (parseInt(formState.ageStart) >= 150) {
    errors.ageStart = 'Age Start must be less than 150';
  }

  // Age End
  if (parseInt(formState.ageEnd) === 0 || parseInt(formState.ageEnd) < 0) {
    errors.ageEnd = 'Age End must be greater than 0';
  } else if (parseInt(formState.ageEnd) >= 150) {
    errors.ageStart = 'Age End must be less than 150';
  } else if (parseInt(formState.ageStart) === parseInt(formState.ageEnd)) {
    errors.ageStart = "Age Start cannot be the same number as Age End"
    errors.ageEnd = "Age Start cannot be the same number as Age End"
  } else if (parseInt(formState.ageStart) > parseInt(formState.ageEnd)) {
    errors.ageStart = "Age Start must be greater than Age End"
    errors.ageEnd = "Age End must be less than Age Start"
  }

  // Offering Description
  if (formState.offeringDescription.trim() === '') {
    errors.offeringDescription = 'Offering Description is required';
  } else if (formState.offeringDescription.trim().length > 2000) {
    errors.offeringDescription = 'Offering Description cannot be greater than 2000 characters';
  }

  // Org Role
  if (formState.offeringOrgRole.length === 0) {
    errors.offeringOrgRole = 'Org Role(s) Associated with Offering are required';
  }

  return errors;
};

const OfferingTypeUSASFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OfferingTypeUSASFormValidation;