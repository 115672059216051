import { useEffect, useState } from 'react';

import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsOfficialLscData from '../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';
import useMeetEvaluationPersonOfficialData from '../../../../state/otsMeetEvaluationsPersonOfficial/UseMeetEvaluationPersonOfficialData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import useForm from '../../../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  showAllEvaluations: true,
};

const useOtsOfficialEvaluations = () => {
  const navigate = useNavigate();
  const { otsOfficialHeaderState } = useOtsOfficialLscData();
  const { meetEvaluationPersonOfficialState, getMeetEvaluationsOfficialByPersonId } = useMeetEvaluationPersonOfficialData();
  const { navRoutes } = useNavRoutes();
  const {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged
  } = useForm(INITIAL_FORM_STATE);

  const onReadOnlyEvaluationClicked = (e, evaluation) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(navRoutes.OTS_OFFICIAL_EVALUATIONS_FORM?.route, {
      state: {
        evaluation: evaluation
      }
    });
  }

  useEffect(() => {
    //handle initial page load when navigating to page for the first time
    if (otsOfficialHeaderState.objData?.personId) {
      getMeetEvaluationsOfficialByPersonId(otsOfficialHeaderState.objData?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsOfficialHeaderState]);

  return {
    handleSubmit,
    formState,
    errorState,
    onFormValueChanged,
    meetEvaluationPersonOfficialState,
    onReadOnlyEvaluationClicked
  };
};

export default useOtsOfficialEvaluations;