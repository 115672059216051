import { Fragment } from 'react';

import useOtsOfficialLscCertification from './UseOtsOfficialLscCertification';
import OtsOfficialsLscCertificationWriteGrid from './grids/OtsOfficialLscCertificationWriteGrid';
import DeleteOfficialLscCertificationModal from './DeleteOfficialLscCertificationModal';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsOfficialLscCertificationWrite = () => {
  const {
    onEditLscCert,
    onAddLscCert,
    onOpenDeleteLscCertificationModal,
    onDeleteLscCertificationModalCanceled,
    deleteLscCertificationModalState,
    otsOfficialLscState,
    personPositionCertificationState,
    formState,
    errorState,
    onFormValueChanged,
    onNavigateToLscRecertifyView,
    onDeleteOfficialLscCertification,
    onAddOrRemoveLscCertficationForRecertify,
    recertifyState
  } = useOtsOfficialLscCertification();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>LSC Certifications</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12">
          <ActionIntraPageButton type="button" onClick={onAddLscCert}>Add/Upgrade Certification</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12 col-md-7'>
          <p>Total Sessions Worked in Sanctioned Meets Regardless of Certification: {otsOfficialLscState?.arrayData?.sessionsWorked >= 0 ? otsOfficialLscState?.arrayData?.sessionsWorked : ''}</p>
        </div>
        <div className='col-xs-12 col-md-5'>
          <span className='pull-right'>
            <YesNoSwitch
              label="Show All LSC Certification History?"
              name="showAllLscCertificationHistory"
              checked={formState.showAllLscCertificationHistory === true}
              error={errorState.showAllLscCertificationHistory}
              message={errorState.showAllLscCertificationHistory}
              onChange={(value) => { onFormValueChanged('showAllLscCertificationHistory', value); }}
              isHorizontal={true} />
          </span>
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <OtsOfficialsLscCertificationWriteGrid
            state={otsOfficialLscState}
            formState={formState}
            onEditLscCert={onEditLscCert}
            onOpenDeleteLscCertificationModal={onOpenDeleteLscCertificationModal}
            selectedLscCertifications={recertifyState?.recertifyPersonPositionCertificationIds}
            onAddOrRemoveLscCertficationForRecertify={onAddOrRemoveLscCertficationForRecertify} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <span className={recertifyState?.recertifyPersonPositionCertificationIds?.length === 0 ? global.HideComponent : global.DisplayComponent}>
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton onClick={(e) => onNavigateToLscRecertifyView(e, formState)}>Re-Certify</PrimaryButton>
          </div>
        </span>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={deleteLscCertificationModalState.modalTitle}
        displayPopUp={deleteLscCertificationModalState.displayPopUp}
        onModalCanceled={onDeleteLscCertificationModalCanceled}>
        <DeleteOfficialLscCertificationModal modalState={deleteLscCertificationModalState} />
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onDeleteOfficialLscCertification(e, deleteLscCertificationModalState)}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteLscCertificationModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={otsOfficialLscState?.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personPositionCertificationState?.isSaving} />
    </Fragment>
  );
};

export default OtsOfficialLscCertificationWrite;