import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import usePersonOrgRoleAffiliationsData from '../../../state/member/memberAffiliations/UsePersonOrgRoleAffiliationsData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const useMemberAffiliations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { environmentVariableState, USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { personGeneralState, getPersonGeneral } = usePersonGeneralData();
  const { basePersonState } = useBasePersonData();
  const { affiliationsState, getAffiliations } = usePersonOrgRoleAffiliationsData();

  const onAddClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_AFFILIATIONS_DETAIL?.route);
  };

  const onEditClicked = (e, personOrgRoleDurationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let personOrgRoleAffiliation = null;
    for (const organization of affiliationsState.arrayData) {
      if (!personOrgRoleAffiliation) {
        if (organization.personAffiliationDurations) {
          for (const affiliation of organization.personAffiliationDurations) {
            if (affiliation.personOrgRoleDurationId === personOrgRoleDurationId) {
              personOrgRoleAffiliation = {
                ...affiliation,
                organizationId: organization.organizationId,
                organizationName: organization.organizationName
              };
              break;
            }
          }
        } 
      }
    }

    if (personOrgRoleAffiliation) {
      navigate(navRoutes.MEMBER_AFFILIATIONS_DETAIL?.route, { state: { personOrgRoleAffiliation } })
    }
  };

  useEffect(() => {
    if (personGeneralState.isObjLoaded === false || location.state?.refreshGeneral === true
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personGeneralState.objData)) {
      getPersonGeneral(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  useEffect(() => {
    if (personGeneralState.isSaving === false && personGeneralState.isObjLoaded === true
      && isPersonObjectDataCurrent(basePersonState.objData.personId, personGeneralState.objData)) {
      getAffiliations(personGeneralState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState]);

  return {
    isLoading: environmentVariableState.isLoading || personGeneralState.isObjLoading || affiliationsState.isArrayLoading,
    isSaving: personGeneralState.isSaving,
    affiliationsState,
    onAddClicked,
    onEditClicked,
    USAS_ORGANIZATION_ID
  };
};

export default useMemberAffiliations;