import { Fragment } from 'react';

import UseMemberMergeSelection from './UseMemberMergeSelection';

import MemberMergeTool from '../../../components/memberMergeTool/MemberMergeTool';

import Headings from '../../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberMergeSelection = () => {
  const {
    personAId,
    personBId,
    onSuccessfulMerge,
    onCancelMergeClicked
  } = UseMemberMergeSelection();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Merge - Select Member Information</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
        </div>
        <div className="col-xs-12">
          <button className={global.BackToSearchButton} type="button" onClick={() => onCancelMergeClicked()}>
            <LeftArrowIcon /> Back to Member B Search
          </button>
        </div>
        <div className="col-xs-12">
          <hr className={[global.ThinBar, 'usas-bar-gray'].join(' ')} />
        </div>
      </div>
      <MemberMergeTool
        personAId={personAId}
        personBId={personBId}
        onSuccessfulMerge={onSuccessfulMerge}
        onCancelClicked={onCancelMergeClicked} />
    </Fragment>
  );
};

export default MemberMergeSelection;