import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ test }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{test.courseName}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Clinic Name
        </div>
        <div className={['visible-xs col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {test.courseName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Date Completed
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {test.courseCompletionDate || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {test.courseExpirationDate || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Status
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {test.completionStatus || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const OtsOfficialClinicsGridSmall = ({ gridData }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(gridData) && gridData.length > 0
      ? gridData.map((test, i) => <SmallGridRow key={i} test={test} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
        </div>
      )
    }
  </div>
);

export default OtsOfficialClinicsGridSmall;