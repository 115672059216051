import { PersonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const getPersonByMemberId = (memberId, state, setState) => {
  if (state.isObjLoading !== true && !state.message) {
    let newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const memberIdForUrl = memberId ? encodeURIComponent(memberId) : 'NaN';
    const url = `/Person/flat/memberId/${memberIdForUrl} `;
    PersonHttpHelper(url, 'GET')
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          message: message
        });
      });
  }
};

const getPotentialPersonDupes = (firstName, lastName, birthDate, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const birthDateEncodeForUrl = encodeURIComponent(birthDate);
    const url = `/Person/query/dupcheck?firstName=${firstName}&lastName=${lastName}&birthDate=${birthDateEncodeForUrl}`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const postBasePerson = (basePersonObj, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    let newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      objData: {},
      message: 'Saving...'
    };
    setState(newState);

    const url = `/Person/baseperson`;
    PersonHttpHelper(url, 'POST', basePersonObj)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isSaving: false,
            isSaved: true,
            objData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonData = {
  INITIAL_STATE,
  getPersonByMemberId,
  getPotentialPersonDupes,
  postBasePerson
};

export default PersonData;