import { useContext } from 'react';

import { CommentStateContext } from './CommentContextProvider';
import CommentData from './CommentData';

const useCommentData = () => {
  const [commentState, setCommentState] = useContext(CommentStateContext);

  const putComment = (personId, personObj) => CommentData.putComment(personId, personObj, commentState, setCommentState);
  const postComment = (personObj) => CommentData.postComment(personObj, commentState, setCommentState);

  return {
    commentState,
    setCommentState,
    putComment,
    postComment
  };
};

export default useCommentData;