import React, { createContext, useState } from 'react';

import CommentData from './CommentData';

export const CommentStateContext = createContext();

const CommentContextProvider = ({ children }) => {
  const stateHook = useState(CommentData.INITIAL_STATE);

  return (
    <CommentStateContext.Provider value={stateHook}>
      {children}
    </CommentStateContext.Provider>
  );
};

export default CommentContextProvider;