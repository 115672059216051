
// MERGE FIELDS
// valueLabel is what label is displayed to the user
// valueKeys are what fields are selected from personA or personB during the merge
// displayValueKey is what value is used to display the value to the user
// isReadOnly if true, field is only displayed in the merge confirmation grid, not the form grid
export const MERGE_FIELD_MEMBER_ID = { valueLabel: 'Member Id', valueKeys: ['memberId'], displayValueKey: 'memberId', isReadOnly: true };
export const MERGE_FIELD_FIRST_NAME = { valueLabel: 'First Name', valueKeys: ['firstName'], displayValueKey: 'firstName', isReadOnly: false };
export const MERGE_FIELD_LAST_NAME = { valueLabel: 'Last Name', valueKeys: ['lastName'], displayValueKey: 'lastName', isReadOnly: false };
export const MERGE_FIELD_MIDDLE_NAME = { valueLabel: 'Middle Name', valueKeys: ['middleName'], displayValueKey: 'middleName', isReadOnly: false };
export const MERGE_FIELD_PREFERRED_NAME = { valueLabel: 'Preferred Name', valueKeys: ['preferredName'], displayValueKey: 'preferredName', isReadOnly: false };
export const MERGE_FIELD_BIRTH_DATE = { valueLabel: 'Birth Date', valueKeys: ['birthDate'], displayValueKey: 'birthDate', isReadOnly: false };
export const MERGE_FIELD_COMPETITION_CATEGORY = { valueLabel: 'Competition Category', valueKeys: ['competitionGenderTypeId', 'competitionGenderTypeName'], displayValueKey: 'competitionGenderTypeName', isReadOnly: false };
export const MERGE_FIELD_EMAIL = { valueLabel: 'Email', valueKeys: ['email'], displayValueKey: 'email', isReadOnly: false };
export const MERGE_FIELD_CONTACT_INFO = { valueLabel: 'Contact Info', valueKeys: ['personContact'], displayValueKey: 'personContact', isReadOnly: false };
export const MERGE_FIELD_DISABILITY = { valueLabel: 'Disability', valueKeys: ['usasPerson.personDisability'], displayValueKey: 'usasPerson.personDisability', isReadOnly: false };
export const MERGE_FIELD_HISPANIC_OR_LATINO = { valueLabel: 'Hispanic/Latino Ethnicity', valueKeys: ['usasPerson.hispanicOrLatinoTypeId', 'usasPerson.hispanicOrLatinoType'], displayValueKey: 'usasPerson.hispanicOrLatinoType', isReadOnly: false };
export const MERGE_FIELD_PERSON_ETHNICITY = { valueLabel: 'Race', valueKeys: ['usasPerson.personEthnicity'], displayValueKey: 'usasPerson.personEthnicity', isReadOnly: false };
export const MERGE_FIELD_IS_US_CITIZEN = { valueLabel: 'US Citizen', valueKeys: ['usasPerson.isUsCitizen'], displayValueKey: 'usasPerson.isUsCitizen', isReadOnly: false };
export const MERGE_FIELD_USER_ACCOUNT = { valueLabel: 'User Account', valueKeys: ['userName', 'lmsInfo'], displayValueKey: 'userName', isReadOnly: false };
export const MERGE_FIELDS = [
  MERGE_FIELD_MEMBER_ID,
  MERGE_FIELD_FIRST_NAME,
  MERGE_FIELD_LAST_NAME,
  MERGE_FIELD_MIDDLE_NAME,
  MERGE_FIELD_PREFERRED_NAME,
  MERGE_FIELD_BIRTH_DATE,
  MERGE_FIELD_COMPETITION_CATEGORY,
  MERGE_FIELD_EMAIL,
  MERGE_FIELD_CONTACT_INFO,
  MERGE_FIELD_DISABILITY,
  MERGE_FIELD_HISPANIC_OR_LATINO,
  MERGE_FIELD_PERSON_ETHNICITY,
  MERGE_FIELD_IS_US_CITIZEN,
  MERGE_FIELD_USER_ACCOUNT
];

export const DISPLAY_VALUE_NO_INFO = 'No Info';
export const DISPLAY_VALUE_UNKNOWN_GENDER = 'Unknown';

export const DEFAULT_MERGE_ERROR_MESSAGE = 'This is most likely the result of conflicting data';
export const DEFAULT_MERGE_VALIDATION_GET_ERROR_MESSAGE = 'Unable to validate merge info';
export const DEFAULT_MERGE_INFO_GET_ERROR_MESSAGE = 'Unable to retrieve merge info';
export const DEFAULT_MERGE_CONFIRMATION_PUT_ERROR_MESSAGE = 'Unable to complete member merge';

//TODO hardcoded values, doesn't include all disabilities?
export const DISABILITY_TYPES = [
  { id: 5, name: 'Able Bodied' },
  { id: 6, name: 'P1' },
  { id: 7, name: 'P2' },
  { id: 8, name: 'P3' }
];

const MemberMergeConstants = {
  MERGE_FIELD_MEMBER_ID,
  MERGE_FIELD_FIRST_NAME,
  MERGE_FIELD_LAST_NAME,
  MERGE_FIELD_MIDDLE_NAME,
  MERGE_FIELD_PREFERRED_NAME,
  MERGE_FIELD_BIRTH_DATE,
  MERGE_FIELD_COMPETITION_CATEGORY,
  MERGE_FIELD_EMAIL,
  MERGE_FIELD_CONTACT_INFO,
  MERGE_FIELD_DISABILITY,
  MERGE_FIELD_HISPANIC_OR_LATINO,
  MERGE_FIELD_PERSON_ETHNICITY,
  MERGE_FIELD_IS_US_CITIZEN,
  MERGE_FIELD_USER_ACCOUNT,
  MERGE_FIELDS,

  DISPLAY_VALUE_NO_INFO,
  DISPLAY_VALUE_UNKNOWN_GENDER,

  DEFAULT_MERGE_ERROR_MESSAGE,
  DEFAULT_MERGE_VALIDATION_GET_ERROR_MESSAGE,
  DEFAULT_MERGE_INFO_GET_ERROR_MESSAGE,
  DEFAULT_MERGE_CONFIRMATION_PUT_ERROR_MESSAGE,

  DISABILITY_TYPES
};

export default MemberMergeConstants;