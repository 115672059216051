import { useState } from 'react';

import MemberCareerStatsData from './MemberCareerStatsData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_MEMBER_CAREER_STATS_STATE = {
  ...BASIC_INITIAL_STATE
};

const useMemberCareerStatsData = () => {
  const [memberCareerStatsState, setMemberCareerStatsState] = useState(INITIAL_MEMBER_CAREER_STATS_STATE);

  const getMemberCareerStats = (personId) => {
    return MemberCareerStatsData.getMemberCareerStatsData(personId, memberCareerStatsState, setMemberCareerStatsState);
  };

  const putMemberCareerStats = (personId, memberCareerStatsArray) => {
    return MemberCareerStatsData.putMemberCareerStatsData(personId, memberCareerStatsArray, memberCareerStatsState, setMemberCareerStatsState);
  };

  return {
    memberCareerStatsState,
    getMemberCareerStats,
    putMemberCareerStats
  };
};

export default useMemberCareerStatsData;