import { Fragment } from 'react';

import useOtsOfficialLscCertificationDetail from './UseOtsOfficialLscCertificationDetail';

import OfficialPositionTypeDropdown from '../../../../components/dropdowns/officialPositionTypeDropdown/OfficialPositionTypeDropdown';
import CertificationLevelDropdown from '../../../../components/dropdowns/certificationLevelDropdown/CertificationLevelDropdown';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const OtsOfficialLscCertificationDetail = () => {
  const {
    onSubmit,
    formState,
    errorState,
    onValueTextPairChanged,
    isLoading,
    state,
    onBackToLscCert,
    onFormValueChanged,
    LSC_ORG_LEVEL_ID,
    isSaving,
    countOfYears,
    startYearOffset
  } = useOtsOfficialLscCertificationDetail();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>{state?.personPositionCertificationId > 0 ? "Change" : "Add"} LSC Certification</Headings.H3>
        </div>
      </div>
      <form>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            {state?.personPositionCertificationId > 0
              ? <ReadOnly label="Position Worked*"
                name="officialPositionTypeId"
                value={formState.positionTypeName} />
              : <OfficialPositionTypeDropdown
                label="Position Worked*"
                name="officialPositionTypeId"
                value={formState.officialPositionTypeId}
                error={errorState.officialPositionTypeId}
                message={errorState.officialPositionTypeId}
                onChange={(newValue, newValueLabel, e) => {
                  onValueTextPairChanged(newValue, 'officialPositionTypeId', newValueLabel, 'positionTypeName', e)
                  onValueTextPairChanged(Constants.DEFAULT_ID, 'certificationLevelId', '', 'levelName', e)
                }}
                orgLevelId={LSC_ORG_LEVEL_ID} />
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {state?.personPositionCertificationId > 0
              ? <ReadOnly
                label="Certification Level*"
                name="certificationLevelId"
                value={formState.levelName} />
              : <CertificationLevelDropdown
                label="Certification Level*"
                name="certificationLevelId"
                value={formState.certificationLevelId}
                error={errorState.certificationLevelId}
                message={errorState.certificationLevelId}
                onChange={(newValue, newValueLabel, e) => {
                  e && e.target && e.target.value &&
                    onValueTextPairChanged(newValue, 'certificationLevelId', newValueLabel, 'levelName');
                }}
                orgLevelId={LSC_ORG_LEVEL_ID}
                officialPositionTypeId={formState.officialPositionTypeId !== Constants.DEFAULT_ID ? formState.officialPositionTypeId : undefined}
              />
            }
          </div>
        </div>
        <div className='row usas-extra-bottom-margin'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {state?.personPositionCertificationId > 0
              ? <ReadOnly
                label="Effective Date*"
                name="effectiveDate"
                value={formState.effectiveDate} />
              :
              <DatePicker
                label="Effective Date*"
                name="effectiveDate"
                value={formState.effectiveDate}
                error={errorState.effectiveDate}
                message={errorState.effectiveDate}
                onChange={(value) => { onFormValueChanged('effectiveDate', value); }}
                countOfYears={countOfYears}
                startYearOffset={startYearOffset} />
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Expiration Date*"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              onChange={(value) => { onFormValueChanged('expirationDate', value); }}
              countOfYears={countOfYears}
              startYearOffset={startYearOffset} />
          </div>
        </div>
        <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
          <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSubmit}>Save</PrimaryButton>&nbsp;
            <SecondaryButton onClick={onBackToLscCert}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default OtsOfficialLscCertificationDetail;