import { useEffect, useState } from 'react';

import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';
import useOtsOfficialNationalCertificationData from '../../../../state/officialsTrackingSystemOfficialNationalCertification/UseOtsOfficialNationalCertificationData';
import useOtsOfficialLscData from '../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';

import useForm from '../../../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  showAllNationalCertificationApplication: false
}

const useOtsOfficialNationalCertificationApplication = () => {
  const { basePersonState } = useBasePersonData();
  const { otsOfficialHeaderState } = useOtsOfficialLscData();
  const { formState, errorState, onFormValueChanged } = useForm(INITIAL_FORM_STATE);
  const { otsOfficialNationalCertificationState, getOtsOfficialNationalCertificationByPersonId } = useOtsOfficialNationalCertificationData();
  const [gridState, setGridState] = useState({ gridData: [] });
  const [selectionChangesState, setSelectionChangesState] = useState({ arrayData: [] });

  const onRecertifyCheckboxChange = (e, personOfficialCertificationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedCertIndexArrayData = updatedArrayData.findIndex(x => x.personOfficialCertificationId === personOfficialCertificationId);

    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedCertIndexGridData = gridState.gridData?.findIndex(x => x.personOfficialCertificationId === personOfficialCertificationId);

    if (selectedCertIndexGridData > -1) {
      updatedGridData[selectedCertIndexGridData].isRecertifySelected = !updatedGridData[selectedCertIndexGridData].isRecertifySelected;
      if (updatedGridData[selectedCertIndexGridData].isRecertifySelected === true &&
        updatedGridData[selectedCertIndexGridData].isAdvanceSelected === true) {
        updatedGridData[selectedCertIndexGridData].isAdvanceSelected = false;
      }
      setGridState({
        ...gridState,
        gridData: updatedGridData
      });
    }

    if (selectedCertIndexArrayData > -1) {
      updatedArrayData[selectedCertIndexArrayData].isRecertifySelected = !updatedArrayData[selectedCertIndexArrayData].isRecertifySelected;
      if (updatedGridData[selectedCertIndexGridData].isRecertifySelected === true &&
        updatedGridData[selectedCertIndexGridData].isAdvanceSelected === true) {
        updatedGridData[selectedCertIndexGridData].isAdvanceSelected = false;
      }
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
    else if (selectedCertIndexArrayData === -1) {
      const newAthlete = JSON.parse(JSON.stringify(updatedGridData[selectedCertIndexGridData]));
      updatedArrayData.push(newAthlete);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  const onAdvanceCheckboxChange = (e, personOfficialCertificationId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedCertIndexArrayData = updatedArrayData.findIndex(x => x.personOfficialCertificationId === personOfficialCertificationId);

    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedCertIndexGridData = gridState.gridData?.findIndex(x => x.personOfficialCertificationId === personOfficialCertificationId);

    if (selectedCertIndexGridData > -1) {
      updatedGridData[selectedCertIndexGridData].isAdvanceSelected = !updatedGridData[selectedCertIndexGridData].isAdvanceSelected;
      if (updatedGridData[selectedCertIndexGridData].isAdvanceSelected === true &&
        updatedGridData[selectedCertIndexGridData].isRecertifySelected === true) {
        updatedGridData[selectedCertIndexGridData].isRecertifySelected = false;
      }
      setGridState({
        ...gridState,
        gridData: updatedGridData
      });
    }

    if (selectedCertIndexArrayData > -1) {
      updatedArrayData[selectedCertIndexArrayData].isAdvanceSelected = !updatedArrayData[selectedCertIndexArrayData].isAdvanceSelected;
      if (updatedGridData[selectedCertIndexGridData].isAdvanceSelected === true &&
        updatedGridData[selectedCertIndexGridData].isRecertifySelected === true) {
        updatedGridData[selectedCertIndexGridData].isRecertifySelected = false;
      }
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
    else if (selectedCertIndexArrayData === -1) {
      const newAthlete = JSON.parse(JSON.stringify(updatedGridData[selectedCertIndexGridData]));
      updatedArrayData.push(newAthlete);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  const onApply = (e) => {
    //TODO
  };

  useEffect(() => {
    if (otsOfficialNationalCertificationState.isArrayLoading === false && otsOfficialNationalCertificationState.isArrayLoaded === false) {
      const personId = otsOfficialHeaderState?.objData?.personId
      getOtsOfficialNationalCertificationByPersonId(personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsOfficialNationalCertificationState])

  useEffect(() => {
    if (otsOfficialNationalCertificationState.isArrayLoaded === true) {
      const arrayDataCopy = JSON.parse(JSON.stringify(otsOfficialNationalCertificationState.arrayData));
      //keep unsaved selections / unselections from previous searches
      if (selectionChangesState.arrayData.length > 0) {
        for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
          const matchingIndex = arrayDataCopy.findIndex(x => x.personOfficialCertificationId === selectionChangesState.arrayData[i].personOfficialCertificationId);
          if (matchingIndex > -1) {
            arrayDataCopy[matchingIndex].isRecertifySelected = selectionChangesState.arrayData[i].isRecertifySelected;
            arrayDataCopy[matchingIndex].isAdvanceSelected = selectionChangesState.arrayData[i].isAdvanceSelected;
          }
        }
      }
      setGridState({ ...gridState, gridData: arrayDataCopy });
    }
    else if (otsOfficialNationalCertificationState.isArrayLoaded === false && otsOfficialNationalCertificationState.isArrayLoading === false) {
      setGridState({ ...gridState, gridData: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsOfficialNationalCertificationState.isArrayLoaded])

  return {
    otsOfficialNationalCertificationState,
    basePersonState,
    gridState,
    formState,
    errorState,
    onFormValueChanged,
    onRecertifyCheckboxChange,
    onAdvanceCheckboxChange,
    onApply
  };
};

export default useOtsOfficialNationalCertificationApplication;