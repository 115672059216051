import { isValidDate, isValidBirthDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.firstName.trim().length > 100) {
    errors.firstName = 'Provided Legal First Name or Preferred Name exceeds the character limit'
  }

  if (!formState.lastName.trim()) {
    errors.lastName = 'Legal Last Name or Maiden Name is a required search parameter';
  } else if (formState.lastName.trim().length > 100) {
    errors.lastName = 'Provided Legal Last Name or Maiden Name exceeds the character limit'
  }

  //Birth Date
  if (formState.birthDate !== Constants.BLANK_DATE_STRING) {
    if (!isValidDate(formState.birthDate)) {
      errors.birthDate = 'Birth Date must be a valid date';
    } else if (!isValidBirthDate(formState.birthDate)) {
      errors.birthDate = 'Birth Date cannot be in the future';
    }
  }

  return errors;
};

const ProfileCoachSearchValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ProfileCoachSearchValidation;