import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import MemberAdministrationContextView from './MemberAdministrationContextView';

import MemberAdministrationSelection from './memberAdministrationSelection/MemberAdministrationSelection';
import HQBoardMembers from './hqBoardMembers/HQBoardMembers';
import AddHQBoardMember from './hqBoardMembers/boardAdd/AddHQBoardMember';
import EditHQBoardMember from './hqBoardMembers/boardEdit/EditHQBoardMember';
import FoundationBoardMembers from './foundationBoardMembers/FoundationBoardMembers';
import AddFoundationBoardMember from './foundationBoardMembers/foundationAdd/AddFoundationBoardMember';
import EditFoundationBoardMember from './foundationBoardMembers/foundationEdit/EditFoundationBoardMember';
import HQCommitteeMembers from './hqCommittees/HQCommitteeMembers';
import AddHQCommitteeMember from './hqCommittees/committeeAdd/AddHQCommitteeMember';
import EditHQCommitteeMember from './hqCommittees/committeeEdit/EditHQCommitteeMember';

import BasePersonContextProvider from '../../../common/state/personBase/BasePersonContextProvider';
import BasePersonFiltersContextProvider from '../../../common/state/personBase/BasePersonFiltersContextProvider';
import MemberAdministrationHQBoardContextProvider from '../../../common/state/memberAdministration/hQBoard/MemberAdministrationHQBoardContextProvider';
import MemberAdministrationFoundationBoardContextProvider from '../../../common/state/memberAdministration/foundationBoard/MemberAdministrationFoundationBoardContextProvider';
import MemberAdministrationHQCommitteeContextProvider from '../../../common/state/memberAdministration/hQCommittee/MemberAdministrationHQCommitteeContextProvider';
import OrgRoleStaffRoleHierarchyContextProvider from '../../../common/state/orgRoleStaffRoleHierarchy/OrgRoleStaffRoleHierarchyContextProvider';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import MemberAdministrationHQCommitteeFilterContextProvider from '../../../common/state/memberAdministration/hQCommittee/MemberAdministrationHQCommitteeFilterContextProvider';

const ContextRoutes = ({ navRoutes }) => (
  <MemberAdministrationContextView>
    <Routes>
      <Route path={navRoutes['MEMBER_ADMIN_HQ_BOARD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_ADMIN_HQ_BOARD']}
            editElement={<HQBoardMembers />} />
        }
      />
      <Route path={navRoutes['MEMBER_ADMIN_HQ_BOARD_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_ADMIN_HQ_BOARD_ADD']}
            editElement={<AddHQBoardMember />} />
        }
      />
      <Route path={navRoutes['MEMBER_ADMIN_HQ_BOARD_EDIT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_ADMIN_HQ_BOARD_EDIT']}
            editElement={<EditHQBoardMember />} />
        }
      />

      <Route path={navRoutes['MEMBER_ADMIN_FOUNDATION_BOARD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_ADMIN_FOUNDATION_BOARD']}
            editElement={<FoundationBoardMembers />} />
        }
      />
      <Route path={navRoutes['MEMBER_ADMIN_FOUNDATION_BOARD_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_ADMIN_FOUNDATION_BOARD_ADD']}
            editElement={<AddFoundationBoardMember />} />
        }
      />
      <Route path={navRoutes['MEMBER_ADMIN_FOUNDATION_BOARD_EDIT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_ADMIN_FOUNDATION_BOARD_EDIT']}
            editElement={<EditFoundationBoardMember />} />
        }
      />

      <Route path={navRoutes['MEMBER_ADMIN_HQ_COMMITTEE']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_ADMIN_HQ_COMMITTEE']}
            editElement={<HQCommitteeMembers />} />
        }
      />
      <Route path={navRoutes['MEMBER_ADMIN_HQ_COMMITTEE_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_ADMIN_HQ_COMMITTEE_ADD']}
            editElement={<AddHQCommitteeMember />} />
        }
      />
      <Route path={navRoutes['MEMBER_ADMIN_HQ_COMMITTEE_EDIT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_ADMIN_HQ_COMMITTEE_EDIT']}
            editElement={<EditHQCommitteeMember />} />
        }
      />
    </Routes>
  </MemberAdministrationContextView>
);

const MemberAdministrationRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['MEMBER_ADMIN_HQ_BOARD_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MEMBER_ADMIN_HQ_BOARD_SELECTION']}
            editElement={<MemberAdministrationSelection />} />
        }
      />

      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['MEMBER_ADMIN_HQ_BOARD_SELECTION']?.route} replace />} />
    </Routes>
  );
}

const MemberAdministration = () => (
    <BasePersonContextProvider>
      <BasePersonFiltersContextProvider>
        <MemberAdministrationHQBoardContextProvider>
          <MemberAdministrationHQCommitteeContextProvider>
            <MemberAdministrationHQCommitteeFilterContextProvider>
              <MemberAdministrationFoundationBoardContextProvider>
                <OrgRoleStaffRoleHierarchyContextProvider>
                  <MemberAdministrationRoutes />
                </OrgRoleStaffRoleHierarchyContextProvider>
              </MemberAdministrationFoundationBoardContextProvider>
            </MemberAdministrationHQCommitteeFilterContextProvider>
          </MemberAdministrationHQCommitteeContextProvider>
        </MemberAdministrationHQBoardContextProvider>
      </BasePersonFiltersContextProvider>
    </BasePersonContextProvider>
);

export default MemberAdministration;