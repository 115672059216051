import React, { Fragment } from 'react';

import global from '../../../../../../common/components/GlobalStyle.module.css';

import { formatDate, getTodaysDate } from '../../../../../../common/utils/DateFunctions';

const LargeGridRow = ({ official, formState }) => {
  const todayDate = getTodaysDate();
  const today = new Date(todayDate);
  const expirationDateLocal = new Date(official?.expirationDate);

  if (formState?.showAllNationalCertificationHistory === false && (today <= expirationDateLocal)) {
    return (
      <Fragment>
        <tr>
          <td>{official?.positionTypeName} - {official?.positionAbbreviation}</td>
          <td>{official?.levelName}</td>
          <td>{formatDate(official?.effectiveDate)}</td>
          <td>{formatDate(official?.expirationDate)}</td>
        </tr>
      </Fragment >
    );
  } else if (formState?.showAllNationalCertificationHistory === true) {
    return (
      <Fragment>
        <tr>
          <td>{official?.positionTypeName} - {official?.positionAbbreviation}</td>
          <td>{official?.levelName}</td>
          <td>{formatDate(official?.effectiveDate)}</td>
          <td>{formatDate(official?.expirationDate)}</td>
        </tr>
      </Fragment>
    )
  } else {
    return (
      <Fragment />
    )
  }
}

const OtsOfficialNationalCertificationHistoryLargeReadGrid = ({ state, formState }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Position</th>
          <th>National Certification</th>
          <th>Effective Date</th>
          <th>Expiration Date</th>
        </tr>
      </thead>
      <tbody>
        {state?.arrayData?.length > 0 ?
          state?.arrayData?.map((official, i) =>
            <LargeGridRow
              key={i}
              official={official}
              formState={formState}
            />)
          : state?.isArrayLoading
            ? <tr><td colSpan="4">Loading...</td></tr>
            : <tr><td colSpan="4">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default OtsOfficialNationalCertificationHistoryLargeReadGrid;