import { useContext } from 'react';

import { OfficialsTrackingSystemOfficialSelectionStateContext } from './OfficialsTrackingSystemOfficialSelectionContextProvider';

import OfficialsTrackingSystemOfficialSelectionData from './OfficialsTrackingSystemOfficialSelectionData';

const useOfficialsTrackingSystemOfficialSelectionData = () => {
  const [officialsTrackingSystemOfficialSelectionState, setOfficialsTrackingSystemOfficialSelectionState] = useContext(OfficialsTrackingSystemOfficialSelectionStateContext);

  const getOtsOfficialSelection = (otsMeetId, filterObj) =>
    OfficialsTrackingSystemOfficialSelectionData.getOtsOfficialSelectionData(otsMeetId, JSON.stringify(filterObj), officialsTrackingSystemOfficialSelectionState, setOfficialsTrackingSystemOfficialSelectionState);

  const postOtsOfficialSelection = (otsMeetId, officialArray) =>
    OfficialsTrackingSystemOfficialSelectionData.postOtsOfficialSelectionData(otsMeetId, officialArray, officialsTrackingSystemOfficialSelectionState, setOfficialsTrackingSystemOfficialSelectionState);

  const deleteMeetOfficial = (meetOfficialId) =>
    OfficialsTrackingSystemOfficialSelectionData.deleteMeetOfficialData(meetOfficialId, officialsTrackingSystemOfficialSelectionState, setOfficialsTrackingSystemOfficialSelectionState);

  const clearOfficialsTrackingSystemOfficialSelectionArrayData = () => {
    setOfficialsTrackingSystemOfficialSelectionState({
      ...officialsTrackingSystemOfficialSelectionState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const clearOfficialsTrackingSystemOfficialSelectionObjData = () => {
    setOfficialsTrackingSystemOfficialSelectionState({
      ...officialsTrackingSystemOfficialSelectionState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const confirmSave = () => {
    setOfficialsTrackingSystemOfficialSelectionState({
      ...officialsTrackingSystemOfficialSelectionState,
      isSaved: false
    });
  };

  return {
    officialsTrackingSystemOfficialSelectionState,
    setOfficialsTrackingSystemOfficialSelectionState,
    getOtsOfficialSelection,
    postOtsOfficialSelection,
    deleteMeetOfficial,
    clearOfficialsTrackingSystemOfficialSelectionObjData,
    clearOfficialsTrackingSystemOfficialSelectionArrayData,
    confirmSave
  };
};

export default useOfficialsTrackingSystemOfficialSelectionData;