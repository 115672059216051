import { useEffect, useState } from 'react';
import useOtsCertificationRuleData from '../../../../state/otsCertificationRule/UseOtsCertificationRuleData';

const INITIAL_VIEW_STATE = {
  isSaveDisabled: true,
  positionCertificationRule: '',
  certificationStatus: ''
};

const useReview = (personPositionCertificationProgression) => {
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const { getOtsCertificationRulesForPersonProgression } = useOtsCertificationRuleData();

  useEffect(() => {
    getOtsCertificationRulesForPersonProgression(personPositionCertificationProgression.personPositionCertificationProgressionId)
      .then(rulesData => {
        setState({
          ...state,
          positionCertificationRule: rulesData.arrayData?.positionCertificationRule || '',
          certificationStatus: personPositionCertificationProgression.certificationStatus
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPositionCertificationProgression?.personPositionCertificationProgressionId]);

  return {
    ...state
  };
};

export default useReview;