import React, { Fragment } from "react";

import FoundationBoardMemberGridLarge from "./FoundationBoardMemberGridLarge";
import FoundationBoardMemberGridSmall from "./FoundationBoardMemberGridSmall";

import useExpandedRow from "../../../../common/components/grids/UseExpandedRow";

const FoundationBoardMemberGrid = ({ data, isLoading, onEdit, onDelete }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <FoundationBoardMemberGridLarge data={data} expandedId={expandedId} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} onExpandClicked={onClick} />
      <FoundationBoardMemberGridSmall data={data} expandedId={expandedId} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} onExpandClicked={onClick} />
    </Fragment>
  );
};
export default FoundationBoardMemberGrid;