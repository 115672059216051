import Constants from '../../../common/utils/Constants';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOfficialEvaluationsByMeetEvaluationIdData from './GetOfficialEvaluationsByMeetEvaluationIdData';
import postOfficialEvaluationData from './PostOfficialEvaluationData';
import putOfficialEvaluationData from './PutOfficialEvaluationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  meetEvaluationId: Constants.DEFAULT_ID
};

const OfficialEvaluationData = {
  INITIAL_STATE, 
  getOfficialEvaluationsByMeetEvaluationIdData,
  postOfficialEvaluationData,
  putOfficialEvaluationData
};

export default OfficialEvaluationData;