import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getPersonMembershipUpgrade from './GetPersonMembershipUpgradeData';
import getPersonUpgrade from './GetPersonUpgradeData';
import postPersonMembershipUpgrade from './PostPersonMembershipUpgradeData';
import postPersonMembershipUpgradeArrayData from './PostPersonMembershipUpgradeArrayData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonMembershipUpgradeData = {
  INITIAL_STATE,
  getPersonMembershipUpgrade,
  getPersonUpgrade,
  postPersonMembershipUpgrade,
  postPersonMembershipUpgradeArrayData
};

export default PersonMembershipUpgradeData;