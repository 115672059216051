import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getPersonConvertToLifeMembership from './GetPersonConvertToLifeMembershipData';
import postPersonConvertToLifeMembership from './PostPersonCovertToLifeMembershipData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonConvertToLifeMembershipData = {
  INITIAL_STATE,
  getPersonConvertToLifeMembership,
  postPersonConvertToLifeMembership

};

export default PersonConvertToLifeMembershipData;