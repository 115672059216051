import React from 'react';

import useHqReporting from './UseHqReporting';

import ReportList from '../../../common/components/embeddedReports/ReportList';

const HqReporting = () => {
  const { reportParameters, routeName } = useHqReporting();

  return (
    <ReportList listTitle="HQ Reports"
      routeName={routeName}
      reportParameters={reportParameters} />
  );
};

export default HqReporting;