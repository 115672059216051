import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import FileExclamationIcon from '../../../../../common/components/icons/FileExclamationIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ session }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{`${session.sessionOrderId || ''} (${session.sessionType || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})`}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Session
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {`${session.sessionOrderId || ''} (${session.sessionType || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})`}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          OQM Session?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {session.isOfficialsQualifyingSession === true ? 'Yes' : session.isOfficialsQualifyingSession === false ? 'No' : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Role Worked
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {session.roleTypeName || ''} ({session.roleTypeAbbreviation || ''})
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Apprentice?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {session.isApprentice === true ? 'Apprentice' : 'No'}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ sessions }) => (
  <div className={global.SmallTableDetailTable}>
    {sessions.map((session, i) => <DetailGridRow key={i} session={session} />)}
  </div>
);

const SmallGridRowIcons = ({ official, expandedId, onEditClicked, onDeleteClicked, onExpandClicked }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, official.personId)}>
      {expandedId === official.personId ? <HideIcon /> : <ShowIcon />}
    </button>
    <button className={global.IconButtonMobileMargin}
      type="button"
      onClick={(e) => onDeleteClicked(e, official)}>
      <DeleteIcon />
    </button>
    <button className={global.IconButtonMobileMargin}
      type="button"
      onClick={(e) => onEditClicked(e, official)}>
      <EditIcon />
    </button>
  </Fragment>
);

const GridRow = ({ official, expandedId, onEditClicked, onDeleteClicked, onExpandClicked, passedInMeetEndDate }) => {
  const meetEndDate = new Date(passedInMeetEndDate);
  const expirationDateForRegistration = new Date(official?.registrationExpirationDate);
  const expirationDateForApt = new Date(official?.aptExpirationDate);
  const expirationDateForBgc = new Date(official?.bgcExpirationDate);
  const expirationDateForCpt = new Date(official?.cptExpirationDate);
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{official.firstName || <span>&nbsp;</span>} {official.lastName || <span>&nbsp;</span>}</span>&nbsp;
          <SmallGridRowIcons official={official} expandedId={expandedId} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} onExpandClicked={onExpandClicked} />
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
              Name
            </div>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
              {official.firstName || <span>&nbsp;</span>} {official.lastName || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              LSC / Club
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {official.lscCode || <span>&nbsp;</span>} {official.clubCode ? '/' : ''} {official.clubCode || <span>&nbsp;</span>}
            </div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 hidden-xs'].join(' ')}>Registration Expires</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Reg. Expires</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{expirationDateForRegistration < meetEndDate
              ? <span style={{ color: 'red' }}>{official.registrationExpirationDate ? formatDate(official.registrationExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? 'No Expiration' : formatDate(official.registrationExpirationDate) : <span>No Registration</span>}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.registrationExpirationDate ? 'Registration Expired' : 'No Registration'} /></span> :
              <span>{official.registrationExpirationDate ? formatDate(official.registrationExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? 'No Expiration' : formatDate(official.registrationExpirationDate) : <span>&nbsp;</span>}</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>APT Expires</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{expirationDateForApt < meetEndDate
              ? <span style={{ color: 'red' }}>{official.aptExpirationDate ? formatDate(official.aptExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? 'No Expiration' : formatDate(official.aptExpirationDate) : <span>Not Completed</span>}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.aptExpirationDate ? 'Course Expired' : 'Not Completed'} /></span> :
              <span>{official.aptExpirationDate ? formatDate(official.aptExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? 'No Expiration' : formatDate(official.aptExpirationDate) : <span>&nbsp;</span>}</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>BGC Expires</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{expirationDateForBgc < meetEndDate
              ? <span style={{ color: 'red' }}>{official.bgcExpirationDate ? formatDate(official.bgcExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? 'No Expiration' : formatDate(official.bgcExpirationDate) : <span>Not Completed</span>}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.bgcExpirationDate ? 'BGC Expired' : 'Not Completed'} /></span> :
              <span>{official.bgcExpirationDate ? formatDate(official.bgcExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? 'No Expiration' : formatDate(official.bgcExpirationDate) : <span>&nbsp;</span>}</span>}</div>
            <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>CPT Expires</div>
            <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{expirationDateForCpt < meetEndDate
              ? <span style={{ color: 'red' }}>{official.cptExpirationDate ? formatDate(official.cptExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? 'No Expiration' : formatDate(official.cptExpirationDate) : <span>Not Completed</span>}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.cptExpirationDate ? 'Course Expired' : 'Not Completed'} /></span> :
              <span>{official.cptExpirationDate ? formatDate(official.cptExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? 'No Expiration' : formatDate(official.cptExpirationDate) : <span>&nbsp;</span>}</span>}</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Sessions
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {official.sessionsNumber >= 0 ? official.sessionsNumber : <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Roles
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {official.officialRoleTypeName || <span>&nbsp;</span>}
            </div>
          </div>
        </div>
      </div>
      {Array.isArray(official.session) && official.session.length > 0 && expandedId === official.personId
        && <DetailGrid sessions={official.session} />
      }
    </Fragment>
  );
}

const MeetOfficialsGridSmall = ({ gridData, isLoading, expandedId, onEditClicked, onDeleteClicked, onExpandClicked, passedInMeetEndDate }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true ? (
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          <div className={global.SmallTableRowLabels}>Loading...</div>
        </div>
      </div>
    ) : Array.isArray(gridData) && gridData.length > 0 ?
      gridData.map((official, i) =>
        <GridRow key={i} official={official} expandedId={expandedId} onEditClicked={onEditClicked}
          onDeleteClicked={onDeleteClicked} onExpandClicked={onExpandClicked} passedInMeetEndDate={passedInMeetEndDate} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Meet Officials</div>
          </div>
        </div>
      )
    }
  </div>
);

export default MeetOfficialsGridSmall;

