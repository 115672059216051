import { Fragment } from "react";

import useProfileBioInfo from './UseProfileBioInfo';

import Headings from "../../../../common/components/headings/Headings";
import Input from "../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";
import CountryCombobox from "../../../../common/components/comboboxes/countryCombobox/CountryCombobox";

const ProfileBioInfo = () => {
  const {
    personProfileState,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    resetForm,
    onValueTextPairChanged
  } = useProfileBioInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Biographical Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Height (inches)"
              name="height"
              type="number"
              min="0"
              value={formState.height}
              error={errorState.height}
              message={errorState.height}
              onChange={(value) => { onFormValueChanged('height', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Weight (lbs)"
              name="weight"
              type="number"
              min="0"
              value={formState.weight}
              error={errorState.weight}
              message={errorState.weight}
              onChange={(value) => { onFormValueChanged('weight', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="High School Name"
              name="highSchoolName"
              value={formState.highSchoolName}
              error={errorState.highSchoolName}
              message={errorState.highSchoolName}
              onChange={(value) => { onFormValueChanged('highSchoolName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="High School City"
              name="highSchoolCity"
              value={formState.highSchoolCity}
              error={errorState.highSchoolCity}
              message={errorState.highSchoolCity}
              onChange={(value) => { onFormValueChanged('highSchoolCity', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="High School Graduation (Year)"
              name="highSchoolGraduationYear"
              value={formState.highSchoolGraduationYear}
              error={errorState.highSchoolGraduationYear}
              message={errorState.highSchoolGraduationYear}
              onChange={(value) => { onFormValueChanged('highSchoolGraduationYear', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="College Name"
              name="college"
              value={formState.college}
              error={errorState.college}
              message={errorState.college}
              onChange={(value) => { onFormValueChanged('college', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="College Graduation (Year)"
              name="collegeGraduationYear"
              value={formState.collegeGraduationYear}
              error={errorState.collegeGraduationYear}
              message={errorState.collegeGraduationYear}
              onChange={(value) => { onFormValueChanged('collegeGraduationYear', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Hometown"
              name="hometown"
              value={formState.hometown}
              error={errorState.hometown}
              message={errorState.hometown}
              onChange={(value) => { onFormValueChanged('hometown', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Birth Place"
              name="birthPlace"
              value={formState.birthPlace}
              error={errorState.birthPlace}
              message={errorState.birthPlace}
              onChange={(value) => { onFormValueChanged('birthPlace', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <CountryCombobox
              label="Birth Country"
              name="birthCountry"
              valueToMatch={formState.birthCountryName}
              error={errorState.birthCountry}
              message={errorState.birthCountry}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'birthCountry', newValueLabel, 'birthCountryName', e) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personProfileState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personProfileState.isSaving} />
    </Fragment>
  );
};

export default ProfileBioInfo;