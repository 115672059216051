import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';
import getCoursesForAdminRole from './GetCoursesForAdminRoleData';


const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: []
};

const PersonCoursesForAdminRoleData = {
  INITIAL_STATE: { ...INITIAL_STATE },
  getCoursesForAdminRole

};

export default PersonCoursesForAdminRoleData;