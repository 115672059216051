import { useEffect, useState } from 'react';

import usePersonCoursesData from '../../../../state/member/memberCourses/UsePersonCoursesData';

import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';

const useMemberCoursesRead = () => {
  const { basePersonState } = useBasePersonData();
  const { memberCoursesState, getPersonCourses } = usePersonCoursesData();
  const [gridDataState, setGridDataState] = useState([]);

  useEffect(() => {
    if (memberCoursesState?.isArrayLoaded === true) {

      setGridDataState(memberCoursesState?.arrayData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCoursesState]);

  useEffect(() => {
    if (memberCoursesState?.isArrayLoaded === false && memberCoursesState?.isArrayLoading === false) {
      if (basePersonState.isObjLoaded === true) {

        getPersonCourses(basePersonState.objData.personId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState?.isObjLoaded, memberCoursesState?.isArrayLoaded]);

  return {
    basePersonState,
    gridDataState,
    isGridLoading: memberCoursesState?.isArrayLoading,

  };
};

export default useMemberCoursesRead;