import useCertificationApplication from './UseCertificationApplication';

import OtsCertificationApplicationContextProvider from '../../../../state/otsCertificationApplication/OtsCertificationApplicationContextProvider';
import OtsCertificationApplicationContextStateContextProvider from '../../../../state/otsCertificationApplication/OtsCertificationApplicationContextStateContextProvider';

import BasePersonContextProvider from '../../../../../common/state/personBase/BasePersonContextProvider';
import BasePersonFiltersContextProvider from '../../../../../common/state/personBase/BasePersonFiltersContextProvider';

import CurrentCerts from '../currentCerts/CurrentCerts';
import Apply from '../apply/Apply';
import Status from '../status/Status';

import Headings from '../../../../../common/components/headings/Headings';

import global from '../../../../../common/components/GlobalStyle.module.css';

const ApplicationTemplate = () => {
  const {
    VIEW_NAMES,
    viewName,
    officialName,
    memberId,
    personPositionCertificationProgressionId,
    certificationPublicNote,
    publicNotes,
    position,
    availableCerts,
    buttonClickHandlers } = useCertificationApplication();

  return (
    <>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <button className={global.HeaderButton}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              Apply for Certification as an Official
            </Headings.H3>
          </button>
        </div>
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <p className={global.HeaderText}>
            <strong>Official Name:</strong>&nbsp;{officialName}
          </p>
        </div>
        <div className="col-xs-12 col-sm-6">
          <p className={[global.HeaderText, viewName === VIEW_NAMES.CURRENT_CERTS ? 'pull-right' : ''].join(' ')}>
            <strong>Member ID:</strong>&nbsp;{memberId}
          </p>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          {viewName === VIEW_NAMES.CURRENT_CERTS && <CurrentCerts canApply={availableCerts?.length > 0} buttonClickHandlers={buttonClickHandlers} />}
          {viewName === VIEW_NAMES.APPLY && <Apply availableCerts={availableCerts} buttonClickHandlers={buttonClickHandlers} officialName={officialName} memberId={memberId}/>}
          {viewName === VIEW_NAMES.STATUS && <Status personPositionCertificationProgressionId={personPositionCertificationProgressionId} certificationPublicNote={certificationPublicNote} publicNotes={publicNotes} position={position} onCancelClicked={buttonClickHandlers.onCancelClicked} />}
        </div>
      </div>
    </>
  );
};

const CertificationApplication = () => (
  <BasePersonContextProvider>
    <BasePersonFiltersContextProvider>
      <OtsCertificationApplicationContextProvider>
        <OtsCertificationApplicationContextStateContextProvider>
          <ApplicationTemplate />
        </OtsCertificationApplicationContextStateContextProvider>
      </OtsCertificationApplicationContextProvider>
    </BasePersonFiltersContextProvider>
  </BasePersonContextProvider>
);

export default CertificationApplication;
