import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../wrappers/ReactRouterDom';

import validate from './MemberContactDetailValidation';

import useNavRoutes from '../../state/security/UseNavRoutes';
import useBasePersonData from '../../state/personBase/UseBasePersonData';
import usePersonGeneralData from '../../state/personGeneral/UsePersonGeneralData';
import useContactRelationshipTypeData from '../../state/contactRelationshipType/UseContactRelationshipTypesData';

import useForm from '../../utils/UseForm';
import Constants from '../../utils/Constants';
import { formatDate } from '../../utils/DateFunctions';
import { evaluateBirthDate } from '../../utils/validation';

const useMemberContactDetail = (acknowledgementsView, personAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personGeneralState, putPersonGeneralContact } = usePersonGeneralData();
  const { contactRelationshipTypeState } = useContactRelationshipTypeData();
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setFormData, onValueTextPairChanged, setIsDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState(getInitialState());
  const [isPrimaryModalState, setIsPrimaryModalState] = useState(getInitialIsPrimaryModalState());

  const onSaveContact = (e) => {
    if (isDirty === false) {
      onCancelClicked(e);
    } else {
      handleSubmit(e);
    }
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (acknowledgementsView === true) {
      setPersonAcknowledgementInfoPageViewState({
        ...personAcknowledgementInfoPageViewState,
        showChangeContactInfoView: true,
        showChangeContactInfoContactDetailView: false,
        showChangeContactInfoContactDetailAddressView: false,
        showChangeContactInfoContactDetailPhoneView: false,
        personContactId: Constants.DEFAULT_ID
      });
    }
    else {
      navigate(navRoutes.MEMBER_CONTACTS?.route);
    }
  };

  const onIsPrimaryModalCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    setIsPrimaryModalState(getInitialIsPrimaryModalState());
  };

  const putPersonGeneralContacts = (doPrimaryCheck) => {
    if (personGeneralState.isSaving === false) {
      putPersonGeneralContact(personGeneralState.objData.personId, createPersonGeneralObject(doPrimaryCheck));
    }

    setState({
      ...state,
      tryRedirect: true
    });

    setIsPrimaryModalState(getInitialIsPrimaryModalState);
  };

  useEffect(() => {
    if (state.tryRedirect === true && personGeneralState.isObjLoading === false && personGeneralState.isSaving === false) {
      if (acknowledgementsView === true) {
        setPersonAcknowledgementInfoPageViewState({
          ...personAcknowledgementInfoPageViewState,
          showChangeContactInfoView: true,
          showChangeContactInfoContactDetailView: false,
          showChangeContactInfoContactDetailAddressView: false,
          showChangeContactInfoContactDetailPhoneView: false,
          personContactId: Constants.DEFAULT_ID
        });
      }
      else {
        navigate(navRoutes.MEMBER_CONTACTS?.route);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState, state.tryRedirect]);

  useEffect(() => {
    if (contactRelationshipTypeState.isArrayLoaded === true && personGeneralState.isObjLoaded === true) {
      if (location.state?.personContactId > 0 || personAcknowledgementInfoPageViewState?.personContactId > 0) { // Edit Contact
        const targetContact = location.state?.personContactId > 0 ? personGeneralState.objData?.personContact?.find(x => x.personContactId === location.state?.personContactId) :
          personAcknowledgementInfoPageViewState?.personContactId > 0 ?
            personGeneralState.objData?.personContact?.find(x => x.personContactId === personAcknowledgementInfoPageViewState?.personContactId) : {};
        if (targetContact) {
          setFormData({
            ...formState,
            firstName: targetContact.firstName || '',
            lastName: targetContact.lastName || '',
            contactRelationshipTypeId: targetContact.contactRelationshipTypeId || Constants.DEFAULT_ID,
            contactRelationshipTypeName: targetContact.contactRelationshipType?.typeName || '',
            email: targetContact.emailAddress || '',
            isPrimary: targetContact.isPrimary === true,
            personContactId: targetContact.personContactId || Constants.DEFAULT_ID
          }, true);

          setState({
            ...state,
            disablePrimaryCheckbox: targetContact.isPrimary === true
          });
        }
      } else if (personGeneralState.objData.personContact.length === 0 ||
        personGeneralState.objData.personContact.filter(x => x.isPrimary === true).length === 0) { // If this is the first add, force isPrimary to true
        setState({ ...state, disablePrimaryCheckbox: true });
        setFormData({ ...formState, isPrimary: true }, true);
      }
      else {
        setIsDirty(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personGeneralState, contactRelationshipTypeState]);

  function submitFormCallback(formState) {
    if (state.disablePrimaryCheckbox === false && formState.isPrimary === true) {
      const currentPrimary = personGeneralState.objData.personContact.find(x => x.isPrimary === true);

      if (currentPrimary) {
        setIsPrimaryModalState({
          ...isPrimaryModalState,
          displayPopUp: true,
          currentPrimaryInfo: `${currentPrimary.firstName} ${currentPrimary.lastName}, ${currentPrimary.emailAddress} (${currentPrimary.contactRelationshipType?.typeName || 'Unknown Contact Relationship'})`
        });
      } else {
        putPersonGeneralContacts(true);
      }
    } else {
      putPersonGeneralContacts(false);
    }
  }

  function createPersonGeneralObject(doPrimaryCheck) {
    const newPersonGeneralObj = JSON.parse(JSON.stringify(personGeneralState.objData));

    if (doPrimaryCheck === true) {
      for (let i = 0; i < newPersonGeneralObj.personContact.length; i++) {
        newPersonGeneralObj.personContact[i].isPrimary = false;
      }
    }

    const newPersonContact = {
      firstName: formState.firstName.trim(),
      lastName: formState.lastName.trim(),
      contactRelationshipTypeId: formState.contactRelationshipTypeId,
      emailAddress: formState.email.trim(),
      isPrimary: formState.isPrimary,
      isDeleted: false,
      contactRelationshipType: undefined
    };

    if (formState.personContactId > 0) {
      const index = newPersonGeneralObj.personContact.findIndex(e => e.personContactId === formState.personContactId);

      if (index >= 0) {
        newPersonGeneralObj.personContact[index] = {
          ...newPersonGeneralObj.personContact[index],
          ...newPersonContact
        };
      }
    } else {
      newPersonGeneralObj.personContact.push({
        personId: newPersonGeneralObj.personId,
        ...newPersonContact
      });
    }

    return newPersonGeneralObj;
  }

  function getInitialState() {
    return {
      disablePrimaryCheckbox: false,
      tryRedirect: false
    };
  }

  function getInitialFormState() {
    return {
      firstName: '',
      lastName: '',
      contactRelationshipTypeId: Constants.DEFAULT_ID,
      contactRelationshipTypeName: '',
      email: '',
      isPrimary: false,
      personContactId: Constants.DEFAULT_ID
    };
  }

  function getInitialIsPrimaryModalState() {
    return {
      displayPopUp: false,
      currentPrimaryInfo: '',
      modalTitle: 'Change the primary emergency contact?'
    };
  }

  function getSelfAlreadyExistsOrMemberIsTooYoung() {
    const memberBirthDate = formatDate(basePersonState.objData?.birthDate);
    const birthDateObj = evaluateBirthDate(memberBirthDate);

    if (personGeneralState.objData?.personContact?.findIndex(x => x.contactRelationshipType?.typeName === 'Self') >= 0 ||
      (birthDateObj.isJunior === false && birthDateObj.isAdult === false)) {
      return true;
    }
    return false;
  }

  return {
    state,
    formState,
    errorState,
    personGeneralState,
    contactRelationshipTypeState,
    isPrimaryModalState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onSaveContact,
    onCancelClicked,
    onIsPrimaryModalCancelClicked,
    putPersonGeneralContacts,
    DEFAULT_ID: Constants.DEFAULT_ID,
    selfAlreadyExistsOrMemberIsTooYoung: getSelfAlreadyExistsOrMemberIsTooYoung()
  };
};

export default useMemberContactDetail;