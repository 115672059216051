import { Fragment } from 'react';

import useHqOfferingType from './UseHqOfferingType';

import HqOfferingManagementContextLoadingModal from '../../HqOfferingManagementContextLoadingModal';

import Headings from '../../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';
import RadiobuttonList from '../../../../../../common/components/radiobuttonlists/RadiobuttonList';

const HqOfferingType = () => {
  const {
    isLoading,
    state,
    onBackClicked,
    onContinueClicked,
    onUpdateOfferingType
  } = useHqOfferingType();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Select Offering Type</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          {state.offeringTypeItems.length > 0 &&
            <RadiobuttonList
              name="offeringTypeName"
              value={state.offeringTypeName}
              error={undefined}
              message={undefined}
              isVertical={true}
              items={state.offeringTypeItems}
              onChange={(value) => { onUpdateOfferingType(value); }} />
          }
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" isDisabled={state.offeringTypeName === ''} onClick={onContinueClicked}>Continue</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <HqOfferingManagementContextLoadingModal
        isLoading={isLoading}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default HqOfferingType;