import { useContext } from 'react';

import { OtsMeetOfficialStateContext } from './OtsMeetOfficialContextProvider';
import OtsMeetOfficialData from './OtsMeetOfficialData';

const useOtsMeetOfficialData = () => {
  const [otsMeetOfficialState, setOtsMeetOfficialState] = useContext(OtsMeetOfficialStateContext);

  const getOtsMeetOfficialsByOtsMeetId = (otsMeetId) => {
    OtsMeetOfficialData.getOtsMeetOfficialsByOtsMeetId(otsMeetId, otsMeetOfficialState, setOtsMeetOfficialState);
  };

  return {
    otsMeetOfficialState,
    setOtsMeetOfficialState,
    getOtsMeetOfficialsByOtsMeetId
   };
};

export default useOtsMeetOfficialData;