import { Fragment } from 'react';

import OfferingInstanceGridLarge from './OfferingInstanceGridLarge';
import OfferingInstanceGridSmall from './OfferingInstanceGridSmall';

const OfferingInstanceGrid = ({ gridData, isLoading, onEditClicked }) => (
  <Fragment>
    <OfferingInstanceGridLarge gridData={gridData} isLoading={isLoading} onEditClicked={onEditClicked} />
    <OfferingInstanceGridSmall gridData={gridData} isLoading={isLoading} onEditClicked={onEditClicked} />
  </Fragment>
);

export default OfferingInstanceGrid;