import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

export const INITIAL_STATE = {
  isArrayLoading: false,
  isArrayLoaded: false,
  arrayData: [],
  hierarchicalOptions: [],
  message: ''
};

const getDisabilityTypes = (state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);
    const url = `/DisabilityType`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const putDisabilityTypes = (state, setState) => {
  if (state.isLoading !== true) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false
    };
    setState(newState);

    const url = `/DisabilityType`;
    PersonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isLoading: false,
            isLoaded: true,
            data
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...state,
          message: message
        });
      });
  }
};

const DisabilityTypeData = { INITIAL_STATE, getDisabilityTypes, putDisabilityTypes };
export default DisabilityTypeData;