import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useGroupFieldNamesData from '../../../../common/state/groupFieldNames/UseGroupFieldNamesData';
import useMemberGroupsData from '../../../../common/state/memberGroups/UseMemberGroupsData';

const useProfileGroups = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { basePersonState } = useBasePersonData();
  const { memberGroupsState, getMemberGroups } = useMemberGroupsData();

  const onApplyClicked = (group) => {
    navigate(navRoutes.MEMBER_PROFILE_GROUPS_REGISTRATION?.route, { state: { group } });
  };

  useEffect(() => {
    if (basePersonState.objData.personId && memberGroupsState.isArrayLoaded === false
      && memberGroupsState.isArrayLoading === false) {
      getMemberGroups(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberGroupsState, basePersonState]);

  return {
    isLoading: basePersonState.isObjLoading,
    gridData: memberGroupsState.arrayData || [],
    isGridLoading: memberGroupsState.isArrayLoading,
    onApplyClicked,
    INVITE_STATUS: GROUP_CONSTANTS.PERSON_GROUP_QUALIFICATION_STATUS_INVITED
  };
};

export default useProfileGroups;