export const localValidate = (formState) => {
  let errors = {};

  try {
    let found = false;
    formState.officialPositionTypes.forEach(position => {
      if (position.nationalEvaluatorTitleId > 0) {
        if (position.effectiveDate) {
          found = true;
        } else {
          errors.generalError = 'At least one of the selected positions does not have an effective date.';
        }
      }
    });

    if (!found) {
      errors.generalError = 'Please select a Title and Effective date for at least one of the positions.';
    }
  } catch {
    errors.generalError = 'Please select a Title and Effective date for at least one of the positions.';
  }

  return errors;
};

const EditNationalEvaluatorValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default EditNationalEvaluatorValidation;