import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../../common/wrappers/ReactRouterDom';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import OfficialPositionTypeContextProvider from '../../../state/officialPositionType/OfficialPositionTypeContextProvider';
import CertificationLevelContextProvider from '../../../state/certificationLevel/CertificationLevelContextProvider';
import SearchOtsOfficialsForBulkRecertifyContextProvider from '../../../state/officialsTrackingSystemBulkRecertification/SearchOtsOfficialsForBulkRecertifyContextProvider';
import SearchOtsOfficialsForBulkRecertifyFilterContextProvider from '../../../state/officialsTrackingSystemBulkRecertification/SearchOtsOfficialsForBulkRecertifyFilterContextProvider';

import BasePersonContextProvider from '../../../../common/state/personBase/BasePersonContextProvider';
import BasePersonFiltersContextProvider from '../../../../common/state/personBase/BasePersonFiltersContextProvider';

import HqOtsOfficialLscBulkRecertify from './HqOtsOfficialLscBulkRecertify';
import HqOtsOfficialLscBulkRecertifyDetail from './HqOtsOfficialLscBulkRecertifyDetail';

import LoadingModal from '../../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../../common/components/security/SecuredRouteElement';

const HqOfficialsTrackingSystemOfficialRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
        <Route path={navRoutes['HQ_OTS_LSC_BULK_RECERTIFICATION']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['HQ_OTS_LSC_BULK_RECERTIFICATION']}
              editElement={<HqOtsOfficialLscBulkRecertify />} />
          }
        />
        <Route path={navRoutes['HQ_OTS_LSC_BULK_RECERTIFICATION_DETAIL']?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes['HQ_OTS_LSC_BULK_RECERTIFICATION_DETAIL']}
              editElement={<HqOtsOfficialLscBulkRecertifyDetail />} />
          }
        />
      <Route path={'/'} element={<Navigate to={navRoutes['HQ_OTS_LSC_BULK_RECERTIFICATION']?.route} replace />} />
    </Routes>
  );
}

const OfficialsTrackingSystemLscBulkRecertify = () => (
  <SearchOtsOfficialsForBulkRecertifyContextProvider>
    <SearchOtsOfficialsForBulkRecertifyFilterContextProvider>
      <BasePersonContextProvider>
        <BasePersonFiltersContextProvider>
          <OfficialPositionTypeContextProvider>
            <CertificationLevelContextProvider>
              <HqOfficialsTrackingSystemOfficialRoutes />
            </CertificationLevelContextProvider>
          </OfficialPositionTypeContextProvider>
        </BasePersonFiltersContextProvider>
      </BasePersonContextProvider>
    </SearchOtsOfficialsForBulkRecertifyFilterContextProvider>
  </SearchOtsOfficialsForBulkRecertifyContextProvider>
);

export default OfficialsTrackingSystemLscBulkRecertify;