import { useState } from 'react';
import MeetEvaluatorData from './MeetEvaluatorData';

const useMeetEvaluatorData = () => {
  const [ state, setState ] = useState({ ...MeetEvaluatorData.INITIAL_STATE });

  const getMeetEvaluatorsByOtsMeetId = (otsMeetId) => {
    return MeetEvaluatorData.getMeetEvaluatorsByOtsMeetId(otsMeetId, state, setState);
  };

  const postMeetEvaluator = (otsMeetId, nationalEvaluatorId) => {
    const payload = { otsMeetId, nationalEvaluatorId };
    return MeetEvaluatorData.postMeetEvaluator(payload, state, setState);
  };

  const deleteMeetEvaluator = (id) => {
    return MeetEvaluatorData.deleteMeetEvaluator(id, state, setState);
  };

  return {
    MeetEvaluatorState: {...state },
    SetMeetEvaluatorState: setState,
    getMeetEvaluatorsByOtsMeetId,
    postMeetEvaluator, 
    deleteMeetEvaluator
  };
};

export default useMeetEvaluatorData;
