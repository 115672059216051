import React, { Fragment } from 'react';

import useMemberClubTransferMemberDetail from './UseMemberClubTransferMemberDetail';

import MemberClubTransferMemberStatus from './components/MemberClubTransferMemberStatus';
import MemberClubTransferMemberForm from './components/MemberClubTransferMemberForm';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';

import Constants from '../../../../common/utils/Constants';

const MemberClubTransferMemberDetail = () => {
  const {
    isSaving,
    isLoading,
    formState,
    errorState,
    transferState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onBackClicked,
    clubTransferNotice
  } = useMemberClubTransferMemberDetail();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Headings.H3>Member Club Transfer</Headings.H3>
        </div>
      </div>
      {transferState.personClubTransferId > 0
        ? <MemberClubTransferMemberStatus transferState={transferState} onBackClicked={onBackClicked} />
        : transferState.isTransferEligible === true
          ? (
            <MemberClubTransferMemberForm
              transferState={transferState}
              formState={formState}
              errorState={errorState}
              onFormValueChanged={onFormValueChanged}
              onValueTextPairChanged={onValueTextPairChanged}
              handleSubmit={handleSubmit}
              onBackClicked={onBackClicked}
              clubTransferNotice={clubTransferNotice} />
          )
          : (
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
              </div>
            </div>
          )
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SUBMITTING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default MemberClubTransferMemberDetail;