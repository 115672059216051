import { useState } from 'react';

import PersonMembershipUpgradeData from './PersonMembershipUpgradeData';

const usePersonMembershipUpgradeData = () => {
  const [personMembershipUpgradeState, setPersonMembershipUpgradeState] = useState(PersonMembershipUpgradeData.INITIAL_STATE);

  const getPersonMembershipUpgrade = (personId, personOrgRoleDurationId) => {
    PersonMembershipUpgradeData.getPersonMembershipUpgrade(personId, personOrgRoleDurationId, personMembershipUpgradeState, setPersonMembershipUpgradeState);
  };

  const getPersonUpgrade = (personId) => {
    PersonMembershipUpgradeData.getPersonUpgrade(personId, personMembershipUpgradeState, setPersonMembershipUpgradeState);
  };

  const postPersonMembershipUpgrade = (personId, memberRegistrationId) => {
    PersonMembershipUpgradeData.postPersonMembershipUpgrade(personId, memberRegistrationId, personMembershipUpgradeState, setPersonMembershipUpgradeState);
  };

  const postPersonMembershipUpgradeArray = (personId, upgradeArray) => {
    PersonMembershipUpgradeData.postPersonMembershipUpgradeArrayData(personId, upgradeArray, personMembershipUpgradeState, setPersonMembershipUpgradeState);
  };

  const resetPersonMembershipUpgradeState = () => {
    setPersonMembershipUpgradeState({
      ...personMembershipUpgradeState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      objData: {},
      message: ''
    })
  }

  return {
    personMembershipUpgradeState,
    setPersonMembershipUpgradeState,
    getPersonMembershipUpgrade,
    getPersonUpgrade,
    postPersonMembershipUpgrade,
    postPersonMembershipUpgradeArray,
    resetPersonMembershipUpgradeState
  };
};

export default usePersonMembershipUpgradeData;