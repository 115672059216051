import { ClubHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const getActiveClubs = (lscId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      lscId,
      message: 'Loading...'
    };
    setState(newState);

    const lscIdForUrl = lscId ? encodeURIComponent(lscId) : undefined;
    const url = `/UsasClub/ActiveRegistration${lscIdForUrl ? `?lscOrgUnitId=${lscIdForUrl}` : ''}`;
    ClubHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          let items = [];

          if (newState.lscId) {
            items = data.map((x) => { return { id: x.orgUnitId, name: `${x.orgUnitName} (${x.orgUnitCode})` }; })
          } else {
            items = data.map((x) => { return { id: x.orgUnitId, name: `${x.orgUnitName} (${x.parentOrgUnitCode}/${x.orgUnitCode})` }; })
          }

          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            data,
            items,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  lscId: ''
};

const ActiveClubsData = {
  INITIAL_STATE,
  getActiveClubs
};

export default ActiveClubsData;