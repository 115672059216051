import { useState} from 'react';

import ActiveClubsData from './ActiveClubsData';

const useActiveClubsData = () => {
  const [activeClubsState, setActiveClubsState] = useState(ActiveClubsData.INITIAL_STATE);

  const getActiveClubs = (lscId) => ActiveClubsData.getActiveClubs(lscId, activeClubsState, setActiveClubsState);

  return {
    activeClubsState,
    getActiveClubs
  };
};

export default useActiveClubsData;