import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import usePersonConvertToLifeMembershipData from '../../../state/member/memberConvertToLifeMembership/UsePersonConvertToLifeMembershipData';

import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import Constants from '../../../../common/utils/Constants';
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

const INITIAL_STATE = {
  modalState: {
    modalTitle: 'Convert To Lifetime Membership',
    displayPopUp: false,
    teamName: '',
    teamCode: '',
    offeringName: '',
    expirationDate: Constants.BLANK_DATE_STRING,
    effectiveDate: Constants.BLANK_DATE_STRING,
  },
  tryGet: false
};

const useMemberConvertToLifeMembership = () => {
  const navigate = useNavigate();
  const { basePersonState } = useBasePersonData();
  const { personConvertToLifeMembershipState, getPersonConvertToLifeMembership, postPersonConvertToLifeMembership
  } = usePersonConvertToLifeMembershipData();
  const { getGlobalRoute } = useGlobalRoutesData();
  const [state, setState] = useState(INITIAL_STATE);

  const onOpenConvertToLifeModalClicked = (e, person) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      modalState: {
        ...state.modalState,
        displayPopUp: true,
        teamName: person.orgUnitName,
        teamCode: person.orgUnitCode,
        offeringName: person.offeringName,
        expirationDate: person.expirationDate,
        effectiveDate: person.effectiveDate,
      }
    });
  };

  const onModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({
      ...state,
      modalState: {
        ...state.modalState,
        displayPopUp: false,
      }
    });
  };

  const onConvertToLifeMembership = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (basePersonState?.objData?.personId !== undefined) {
      const personId = basePersonState?.objData?.personId;

      postPersonConvertToLifeMembership(personId);
    };
  };

  useEffect(() => {
    if (personConvertToLifeMembershipState.isSaved === true) {
      const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');

      if (paymentsRoute && paymentsRoute.route) {
        navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
      } else {
        setState({
          ...state,
          tryGet: true,
          modalState: {
            ...state.modalState,
            displayPopUp: false,
          }
        });
      }

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personConvertToLifeMembershipState.isSaved]);


  useEffect(() => {
    if (basePersonState?.objData?.personId !== undefined || state.tryGet === true) {
      const personId = basePersonState.objData.personId;

      getPersonConvertToLifeMembership(personId);
      setState({ ...state, tryGet: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, state.tryGet]);

  return {
    modalState: state?.modalState,
    onModalCanceled,
    onOpenConvertToLifeModalClicked,
    onConvertToLifeMembership,
    personConvertToLifeMembershipState,
  }
};

export default useMemberConvertToLifeMembership;