import { Fragment } from "react";

import useMemberMergeTool from "./UseMemberMergeTool";

import MemberMergeFormGrid from "./grids/MemberMergeFormGrid";
import MemberMergeConfirmationPopup from "./dialogs/MemberMergeConfirmationPopup";

import Spinner from "../../../common/components/spinners/Spinner";
import PopUpModal from "../../../common/components/dialogs/PopUpModal";

import { SAVING_MSG } from "../../../common/utils/Constants";

import global from '../../../common/components/GlobalStyle.module.css';
import style from './MemberMergeTool.module.css';

const MergeErrorMessage = ({ errorMessages }) => (
  <div className={style.MergeErrorMessage}>
    <h3>An issue occurred when trying to merge members</h3>
    {errorMessages.map((error, i) => (
      <p key={i} className={global.ErrorMessage}>{error.message}</p>
    ))}
    <p>Please go to <a href='https://swimssupport.usaswimming.org'>https://swimssupport.usaswimming.org</a> for support</p>
  </div>
);

const MemberMergeTool = ({ personAId, personBId, memberAId, memberBId, onSuccessfulMerge, onCancelClicked }) => {
  const {
    isLoading,
    isSaving,
    isMergeInfoLoaded,
    personAData,
    personBData,
    state,

    onMergeClicked,
    onConfirmMergeClicked,
    onCloseConfirmationPopup
  } = useMemberMergeTool(personAId, personBId, memberAId, memberBId, onSuccessfulMerge);

  if (state.isError === true) {
    return <MergeErrorMessage errorMessages={state.errorMessages} />;
  } else if (isLoading === false && isMergeInfoLoaded === true) {
    return (
      <Fragment>
        <MemberMergeFormGrid
          personAData={personAData}
          personBData={personBData}
          onMergeClicked={onMergeClicked}
          onCancelClicked={onCancelClicked} />
        {state.displayConfirmationPopup === true &&
          <MemberMergeConfirmationPopup
            personMergeObj={state.personMergeObj}
            onConfirmMergeClicked={onConfirmMergeClicked}
            onClosePopupClicked={onCloseConfirmationPopup} />
        }
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={SAVING_MSG}
          displayPopUp={isSaving === true} />
      </Fragment>
    );
  } else {
    return <Spinner />;
  }
};

export default MemberMergeTool;