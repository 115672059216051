import { Fragment } from 'react';

import useOtsOfficialNationalApplicationApplication from './UseOtsOfficialNationalCertificationApplication';

import OtsOfficialsNationalCertificationApplicationWriteGrid from './grids/OtsOfficialNationalCertificationApplicationWriteGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

const OtsOfficialNationalCertificationApplication = () => {
  const {
    otsOfficialNationalCertificationState,
    basePersonState,
    gridState,
    formState,
    errorState,
    onFormValueChanged,
    onRecertifyCheckboxChange,
    onAdvanceCheckboxChange,
    onApply
  } = useOtsOfficialNationalApplicationApplication();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>National Certification Application</Headings.H3>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <span className='pull-right'>
            <YesNoSwitch
              label="Show All National Certification Applications?"
              name="showAllNationalCertificationApplication"
              checked={formState.showAllNationalCertificationApplication === true}
              error={errorState.showAllNationalCertificationApplication}
              message={errorState.showAllNationalCertificationApplication}
              onChange={(value) => { onFormValueChanged('showAllNationalCertificationApplication', value); }}
              isHorizontal={true} />
          </span>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <OtsOfficialsNationalCertificationApplicationWriteGrid
            formState={formState}
            state={otsOfficialNationalCertificationState}
            gridState={gridState}
            onRecertifyCheckboxChange={onRecertifyCheckboxChange}
            onAdvanceCheckboxChange={onAdvanceCheckboxChange} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton onClick={(e) => onApply(e)}>Apply</PrimaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || otsOfficialNationalCertificationState.isArrayLoading} />
    </Fragment >
  );
};

export default OtsOfficialNationalCertificationApplication;