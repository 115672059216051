import { useState } from 'react';

import PersonData from './PersonData';

const usePersonData = () => {
  const [personState, setPersonState] = useState(PersonData.INITIAL_STATE);

  const getPersonByMemberId = (memberId) => {
    PersonData.getPersonByMemberId(memberId, personState, setPersonState)
  };

  const getPotentialPersonDupes = (firstName, lastName, birthDate) => {
    PersonData.getPotentialPersonDupes(firstName, lastName, birthDate, personState, setPersonState);
  };

  const postBasePerson = (basePersonObj) => {
    PersonData.postBasePerson(basePersonObj, personState, setPersonState);
  };

  const resetPersonState = () => {
    setPersonState(PersonData.INITIAL_STATE);
  }

  return {
    personState,
    setPersonState,
    getPersonByMemberId,
    getPotentialPersonDupes,
    postBasePerson,
    resetPersonState
  };
};

export default usePersonData;