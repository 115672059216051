import React, { Fragment } from 'react';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';

import useOtsOfficialSelection from './UseOtsOfficialSelection';

const OtsOfficialSelection = () => {
    const { isLoading } = useOtsOfficialSelection();
    return (
        <Fragment>
            <Fragment>
                <PopUpModal
                    widthPct={50}
                    maxWidth={250}
                    title={Constants.LOADING_MSG}
                    displayPopUp={isLoading} />
            </Fragment>
        </Fragment>
    );
};

export default OtsOfficialSelection;