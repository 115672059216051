import { useEffect } from "react";

import validate from './OfferingTypeUSASFormValidation';

import useOrgRoleHierarchyData from "../../../../../../common/state/orgRoleHierarchy/UseOrgRoleHierarchyData";
import useEnvironmentVariableData from "../../../../../../common/state/environmentVariable/UseEnvironmentVariableData";

import UseForm from "../../../../../../common/utils/UseForm";
import HierarchicalDataUtils from "../../../../../../common/utils/HierarchicalDataUtils";

const INITIAL_FORM_STATE = {
  offeringName: '',
  ageStart: 0,
  ageEnd: 1,
  isEligibleForBogo: false,
  isEligibleForLscBogo: false,
  offeringDescription: '',
  offeringOrgRole: []
};

const useOfferingTypeUSASForm = (onSubmitFormCallback, offeringObj) => {
  const { USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { orgRoleHierarchyState } = useOrgRoleHierarchyData();
  const { formState, errorState, setFormState, updateFormState, handleSubmit, onValueTextPairChanged
  } = UseForm(INITIAL_FORM_STATE, formatBeforeSubmit, validate);

  useEffect(() => {
    if (orgRoleHierarchyState.isArrayLoading !== true && orgRoleHierarchyState.isArrayLoaded === true
      && orgRoleHierarchyState.organizationId === USAS_ORGANIZATION_ID && offeringObj) {
      const optionalAttributes = Array.isArray(offeringObj.offeringOptionalAttributes) && offeringObj.offeringOptionalAttributes.length > 0
        ? offeringObj.offeringOptionalAttributes[0]
        : {};
      const orgRoleIds = Array.isArray(offeringObj.offeringOrgRole)
        ? offeringObj.offeringOrgRole.map((x) => x.orgRoleId)
        : [];

      setFormState({
        ...formState,
        offeringName: offeringObj.offeringName ?? '',
        ageStart: optionalAttributes.ageStart ?? 0,
        ageEnd: optionalAttributes.ageEnd ?? 1,
        isEligibleForBogo: optionalAttributes.isEligibleForBogo ?? false,
        isEligibleForLscBogo: optionalAttributes.isEligibleForLscBogo ?? false,
        offeringDescription: offeringObj.offeringDescription ?? '',
        offeringOrgRole: HierarchicalDataUtils.GetNameIdPairs(orgRoleHierarchyState.treeData, orgRoleIds)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringObj, orgRoleHierarchyState]);

  function formatBeforeSubmit(formState) {
    const offeringId = offeringObj.offeringId || undefined;
    const offeringOrgRole = formState.offeringOrgRole.map(x => {
      return { orgRoleId: x.id, offeringId }
    });
    const optionalAttributes = Array.isArray(offeringObj.offeringOptionalAttributes) && offeringObj.offeringOptionalAttributes.length > 0
      ? offeringObj.offeringOptionalAttributes[0]
      : {};
    const offeringOptionalAttributes = [{
      offeringId,
      offeringOptionalAttributesId: optionalAttributes.offeringOptionalAttributesId || undefined,
      ageStart: formState.ageStart,
      ageEnd: formState.ageEnd,
      isEligibleForBogo: formState.isEligibleForBogo,
      isEligibleForLscBogo: formState.isEligibleForLscBogo
    }];

    const submitObj = {
      offeringId: offeringObj.offeringId || undefined,
      // offeringInstance: offeringObj.offeringInstance || null,
      offeringTypeId: offeringObj.offeringTypeId,
      offeringTypeName: offeringObj.offeringTypeName,

      offeringOrgRole,
      offeringOptionalAttributes,
      offeringName: formState.offeringName.trim(),
      offeringDescription: formState.offeringDescription.trim()
    };

    onSubmitFormCallback(submitObj);
  }

  return {
    organizationId: USAS_ORGANIZATION_ID,
    formState,
    errorState,

    updateFormState,
    onValueTextPairChanged,
    handleSubmit
  };
};

export default useOfferingTypeUSASForm;