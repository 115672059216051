import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getMeetEvaluationsByOtsMeetIdData from './GetMeetEvaluationsByOtsMeetIdData';
import postMeetEvaluationData from './PostMeetEvaluationData';
import putMeetEvaluationData from './PutMeetEvaluationData';
import deleteMeetEvaluationData from './DeleteMeetEvaluationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetEvaluationData = {
  INITIAL_STATE, getMeetEvaluationsByOtsMeetIdData,
  postMeetEvaluationData,
  putMeetEvaluationData,
  deleteMeetEvaluationData
};

export default MeetEvaluationData;