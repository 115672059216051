import { useEffect, useState } from 'react';

import useOfferingInstanceCouponDiscountsData from '../../../../state/offeringInstanceCouponDiscounts/UseOfferingInstanceCouponDiscountsData';

import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const INITIAL_STATE = {
  pageErrorMessage: '',
};

const useLscOfferingInstanceCoupon = () => {
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { offeringInstanceCouponDiscountsState, getOfferingInstanceCouponDiscounts, putOfferingInstanceCouponDiscount
  } = useOfferingInstanceCouponDiscountsData();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmitCouponDiscount = (offeringInstanceCouponDiscountObj) => {
    const putOfferingInstanceCouponDiscountPromise = putOfferingInstanceCouponDiscount(offeringInstanceCouponDiscountObj.offeringInstanceCouponDiscountId, offeringInstanceCouponDiscountObj);

    if (putOfferingInstanceCouponDiscountPromise ?? false) {
      //TODO should probably refresh arrayData while keeping user inputs
      putOfferingInstanceCouponDiscountPromise.catch((e) => {
        setState({
          ...state,
          pageErrorMessage: e?.message || 'Failed to update coupon'
        })
      });
    }
  };

  useEffect(() => {
    const orgUnitId = selectOrgUnitState.orgUnitId;

    if (offeringInstanceCouponDiscountsState.isArrayLoaded === false) {
      const getOfferingInstanceCouponDiscountsPromise = getOfferingInstanceCouponDiscounts(orgUnitId);

      if (getOfferingInstanceCouponDiscountsPromise ?? false) {
        getOfferingInstanceCouponDiscountsPromise.catch((e) => {
          setState({
            ...state,
            pageErrorMessage: e?.message || 'Failed to retrieve coupons'
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSaving: offeringInstanceCouponDiscountsState.isObjLoading,
    state,
    offeringInstanceCouponDiscountsState,
    onSubmitCouponDiscount
  };
};

export default useLscOfferingInstanceCoupon;