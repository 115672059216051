import { useEffect, useState } from 'react';

import useEnvironmentVariableData from '../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useOrgUnitData from '../../../common/state/orgUnit/UseOrgUnitData';

const useAffiliationTreeView = () => {
  const { environmentVariableState, USAS_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { orgUnitState, getOrgUnits } = useOrgUnitData();
  const [affiliationsState, setAffiliationsState] = useState([]);

  useEffect(() => {
    getOrgUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orgUnitState.isArrayLoaded === true && orgUnitState.isArrayLoading === false
      && environmentVariableState.isLoaded === true) {
      const treeData = JSON.parse(JSON.stringify(orgUnitState.treeData));

      setAffiliationsState(treeData.filter(x => x.id !== USAS_ORG_UNIT_ID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState, environmentVariableState]);

  return {
    orgUnitState,
    affiliationsState
  };
};

export default useAffiliationTreeView;