import React from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

import global from '../../../../common/components/GlobalStyle.module.css';
import style from './NationalEvaluators.module.css';

const DetailGridRow = ({ officialPositionType }) => (
  <tr>
    <td>{officialPositionType.positionTypeName} - {officialPositionType.positionAbbreviation}</td>
    <td>{officialPositionType.nationalEvaluatorTitle}</td>
    <td>{formatDate(officialPositionType.effectiveDate)}</td>
    <td>{formatDate(officialPositionType.expirationDate)}</td>
  </tr>
);

const DetailGrid = ({ officialPositionTypes, onEdit, onDelete }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Position</th>
        <th>Evaluator Title</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
      </tr>
    </thead>
    <tbody>
      {officialPositionTypes.map((officialPositionType, i) => 
        <DetailGridRow key={i} 
          officialPositionType={officialPositionType} />)}
    </tbody>
  </table>
);

const LargeGridRow = ({ evaluator, onEdit, onDelete, expandedId, onExpandClicked }) => {
  const positionAbbreviations = Array.isArray(evaluator.officialPositionTypes) 
    ? evaluator.officialPositionTypes.map(p => p.positionAbbreviation).join(', ')
    : '';

  return (
    <>
      <tr>
        <td>{evaluator.evaluatorName}</td>
        <td>{evaluator.orgUnitName || ''} {evaluator.orgUnitName === 'Unattached' ? (evaluator.parentOrgUnitName ? '- ' + evaluator.parentOrgUnitName : '') : ''} {evaluator?.parentOrgUnitCode ? '(' + evaluator?.parentOrgUnitCode + ')' : ''}</td>
        <td>{positionAbbreviations}</td>
        <td className="pull-right">
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onEdit(evaluator.nationalEvaluatorId)}>
            <EditIcon />
          </button>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={() => onDelete(evaluator.nationalEvaluatorId)}>
            <DeleteIcon />
          </button>&nbsp;
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onExpandClicked(e, evaluator.nationalEvaluatorId)}>
            {expandedId === evaluator.nationalEvaluatorId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {expandedId === evaluator.nationalEvaluatorId &&
        <tr className={global.Expanded}>
          <td colSpan="6">
            <DetailGrid 
              officialPositionTypes={evaluator.officialPositionTypes} 
              onEdit={onEdit}
              onDelete={onDelete} />
          </td>
        </tr>}
    </>
  );
};

const NationalEvaluatorsGridLarge = ({ gridData, onEdit, onDelete, expandedId, onExpandClicked }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th className={style.NameColumn}>Evaluator Name</th>
          <th>Club Name</th>
          <th>Positions</th>
          <th className={style.ButtonColumn}></th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(gridData) && gridData.length > 0
          ? gridData?.map((evaluator, i) => 
            <LargeGridRow 
              key={i}
              evaluator={evaluator}
              onEdit={onEdit}
              onDelete={onDelete}
              expandedId={expandedId}
              onExpandClicked={onExpandClicked} />)
          : <tr><td colSpan="8">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default NationalEvaluatorsGridLarge;