import React, { Fragment } from 'react';

import ProfileCoachLargeGrid from './ProfileCoachLargeGrid';
import ProfileCoachSmallGrid from './ProfileCoachSmallGrid';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

const ProfileCoachGrid = ({ gridState, onDelete, onDeleteCoachType, onAddEditCoach, onAddEditCoachType }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <ProfileCoachLargeGrid
        gridState={gridState}
        onDelete={onDelete}
        onDeleteCoachType={onDeleteCoachType}
        onAddEditCoach={onAddEditCoach}
        onAddEditCoachType={onAddEditCoachType}
        expandedId={expandedId}
        onExpandClicked={onClick} />
      <ProfileCoachSmallGrid
        gridState={gridState}
        onDelete={onDelete}
        onDeleteCoachType={onDeleteCoachType}
        onAddEditCoach={onAddEditCoach}
        onAddEditCoachType={onAddEditCoachType}
        expandedId={expandedId}
        onExpandClicked={onClick} />

    </Fragment>
  );
};

export default ProfileCoachGrid;