import EditIcon from '../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ course, onEditCourse, viewLocation }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{course.courseName} ({course.courseCode})</span>&nbsp;
      <span className={course.canHqEdit === true ? global.DisplayComponent : global.HideComponent}>
        <button className={global.IconButtonMobileMargin}
          type="button" onClick={(e) => onEditCourse(e, course, viewLocation)}><EditIcon />
        </button>
      </span>
    </div>
    <div className={[global.SmallTableRowInfo, 'visible-xs'].join('')}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 hidden-sm'].join(' ')}>Course Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 hidden-sm'].join(' ')}>{course.courseName} ({course.courseCode})</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Date Completed</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>
          {course.courseCompletionDate !== null
            ? formatDate(course.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
              ? ' '
              : formatDate(course.courseCompletionDate)
            : ' '}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Expiration Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>
          {course.courseExpirationDate !== null
            ? formatDate(course.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
              ? `Doesn't Expire`
              : formatDate(course.courseExpirationDate)
            : ' '
          }
        </div>
      </div>
    </div>
  </div>
);

const MemberCoursesSmallAdvancedWriteGrid = ({ gridData, isLoading, onEditCourse, viewLocation }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (<div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          <div className={global.SmallTableRowLabels}>
            Loading...
          </div>
        </div>
      </div>
      )
      : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((course, i) =>
          <GridRow key={i} course={course} onEditCourse={onEditCourse} viewLocation={viewLocation} />)
        : (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              No Courses
            </div>
          </div>
        </div>
        )
    }
  </div>
);

export default MemberCoursesSmallAdvancedWriteGrid;