import React, { Fragment } from 'react';

const RemoveAttachmentModal = ({ modalState }) => {
  return (
    <Fragment>
      <p className='col-xs-12'><strong>File Type Name: </strong> &nbsp;{modalState.attachment?.fileTypeName})</p>
      <p className='col-xs-12 col-sm-6'><strong>File Name:</strong>&nbsp;{modalState.attachment?.fileName}</p>
      <p className='col-xs-12'>Are you sure you want to remove this attachment?</p>
    </Fragment>
  );
};

export default RemoveAttachmentModal;