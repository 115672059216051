import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';
import getPersonClubTransfers from './GetPersonClubTransfersData';
import putPersonClubTransfer from './PutPersonClubTransferData';
import deletePersonClubTransfer from './DeletePersonClubTransferData';


const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonClubTransfersData = {
  INITIAL_STATE: { ...INITIAL_STATE },
  getPersonClubTransfers,
  putPersonClubTransfer,
  deletePersonClubTransfer

};

export default PersonClubTransfersData;