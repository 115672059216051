import React, { Fragment } from 'react';

import MemberAffiliationsGridLarge from './MemberAffiliationsGridLarge';
import MemberAffiliationsGridSmall from './MemberAffiliationsGridSmall';

import useExpandedRow from '../../../../common/components/grids/UseExpandedRow';

const MemberAffiliationsGrid = ({ gridData, isLoading, onEditClicked, USAS_ORGANIZATION_ID }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MemberAffiliationsGridLarge gridData={gridData} isLoading={isLoading} expandedId={expandedId} onEditClicked={onEditClicked} onExpandClicked={onClick} USAS_ORGANIZATION_ID={USAS_ORGANIZATION_ID} />
      <MemberAffiliationsGridSmall gridData={gridData} isLoading={isLoading} expandedId={expandedId} onEditClicked={onEditClicked} onExpandClicked={onClick} USAS_ORGANIZATION_ID={USAS_ORGANIZATION_ID} />
    </Fragment>
  );
};

export default MemberAffiliationsGrid;