import React, { createContext, useState } from 'react';

export const OtsMeetHeaderStateContext = createContext();

const INITIAL_STATE = {
  isObjLoading: false,
  isObjLoaded: false,
  isSaving: false,
  isSaved: false,
  objData: {},
  message: '',
  myOtsMeets: false,
  myLscOtsMeetSearch: false
};

const OtsMeetHeaderContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <OtsMeetHeaderStateContext.Provider value={stateHook}>
      {children}
    </OtsMeetHeaderStateContext.Provider>
  );
};

export default OtsMeetHeaderContextProvider;