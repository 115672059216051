export const localValidate = (formState) => {
  let errors = {};

  if (formState.frequentFlyerTypeId < 0) {
    errors.frequentFlyerTypeId = 'Carrier Name is required';
  }

  if (!formState.frequentFlyerNumber.trim()) {
    errors.frequentFlyerNumber = 'Frequent Flyer Number is required'
  } else if (formState.frequentFlyerNumber.trim().length > 255) {
    errors.frequentFlyerNumber = 'Frequent Flyer Number cannot exceed 255 characters'
  }

  return errors;
};

const NationalTeamFrequentFlyerDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default NationalTeamFrequentFlyerDetailValidation;