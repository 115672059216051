import { useContext } from 'react';

import MemberAdministrationHQBoardData from './MemberAdministrationHQBoardData';
import { MemberAdministrationHQBoardStateContext } from './MemberAdministrationHQBoardContextProvider';

const useMemberAdministrationHQBoardData = () => {
  const [memberAdministrationHQBoardState, setMemberAdministrationHQBoardState] = useContext(MemberAdministrationHQBoardStateContext);

  const getHQBoardDirectors = () => MemberAdministrationHQBoardData.getHQBoardDirectors(memberAdministrationHQBoardState, setMemberAdministrationHQBoardState);
  const postHQBoardDirector = (orgUnitId, roleGroupId, staff) => MemberAdministrationHQBoardData.postHQBoardDirector(orgUnitId, roleGroupId, staff, memberAdministrationHQBoardState, setMemberAdministrationHQBoardState);
  const putHQBoardDirector = (orgUnitId, personId, roleGroupId, staff) => MemberAdministrationHQBoardData.putHQBoardDirector(orgUnitId, personId, roleGroupId, staff, memberAdministrationHQBoardState, setMemberAdministrationHQBoardState);
  const deleteHQBoardDirector = (personOrgRoleDurationId) => MemberAdministrationHQBoardData.deleteHQBoardDirector(personOrgRoleDurationId, memberAdministrationHQBoardState, setMemberAdministrationHQBoardState);

  const confirmSave = () => {
    setMemberAdministrationHQBoardState({
      ...memberAdministrationHQBoardState,
      isSaved: false
    });
  };

  return {
    memberAdministrationHQBoardState,
    getHQBoardDirectors,
    postHQBoardDirector,
    putHQBoardDirector,
    deleteHQBoardDirector,
    confirmSave
  };
};

export default useMemberAdministrationHQBoardData;