import { useEffect } from 'react';

import validate from './ProfileBioInfoValidation';

import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import useForm from '../../../../common/utils/UseForm';
import { isPersonObjectDataCurrent } from '../utils/MemberUtils';
// import Constants from '../../../../common/utils/Constants';
// import useCountryCombobox from '../../../../common/components/comboboxes/countryCombobox/UseCountryCombobox';

const useProfileBioInfo = () => {
  const { basePersonState } = useBasePersonData();
  // const { getCountryNameById } = useCountryCombobox();
  const { personProfileState, getPersonProfile, putPersonProfile } = usePersonProfileData();
  const {
    handleSubmit,
    updateFormState,
    formState,
    setFormData,
    resetForm,
    errorState,
    onValueTextPairChanged
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const {
    environmentVariableState,
    WEIGHT_BIO_ENTRY_ID,
    HEIGHT_BIO_ENTRY_ID,
    HIGH_SCHOOL_BIO_ENTRY_ID,
    HIGH_SCHOOL_GRADUATION_BIO_ENTRY_ID,
    HIGH_SCHOOL_CITY_BIO_ENTRY_ID,
    COLLEGE_BIO_ENTRY_ID,
    COLLEGE_GRADUATION_BIO_ENTRY_ID,
    HOMETOWN_BIO_ENTRY_ID,
    BIRTHPLACE_BIO_ENTRY_ID,
    BIRTH_COUNTRY_BIO_ENTRY_ID
  } = useEnvironmentVariableData();

  function submitFormCallback() {
    if (personProfileState.isSaving === false) {
      putPersonProfile(personProfileState.objData.personId, createPersonObject());
    }
  };

  function addNewBioEntry(bios, bioEntryId, value) {

    let result = bios.find(obj => {
      return obj.bioEntryId === bioEntryId;
    });

    if (result === undefined) {
      bios.push({
        personId: personProfileState.objData.personId,
        bioEntryId: bioEntryId,
        textValue: value,
        bioEntry: null
      });
    };
  }

  function createPersonObject() {
    let bios = personProfileState.objData.bios;

    //update objects already in bios array
    bios.forEach((item) => {
      if (item.bioEntryId === HEIGHT_BIO_ENTRY_ID) {
        item.textValue = formState.height;
      } else if (item.bioEntryId === WEIGHT_BIO_ENTRY_ID) {
        item.textValue = formState.weight;
      } else if (item.bioEntryId === HIGH_SCHOOL_BIO_ENTRY_ID) {
        item.textValue = formState.highSchoolName.trim();
      } else if (item.bioEntryId === HIGH_SCHOOL_GRADUATION_BIO_ENTRY_ID) {
        item.textValue = formState.highSchoolGraduationYear;
      } else if (item.bioEntryId === HIGH_SCHOOL_CITY_BIO_ENTRY_ID) {
        item.textValue = formState.highSchoolCity.trim();
      } else if (item.bioEntryId === COLLEGE_BIO_ENTRY_ID) {
        item.textValue = formState.college.trim();
      } else if (item.bioEntryId === COLLEGE_GRADUATION_BIO_ENTRY_ID) {
        item.textValue = formState.collegeGraduationYear;
      } else if (item.bioEntryId === HOMETOWN_BIO_ENTRY_ID) {
        item.textValue = formState.hometown.trim();
      } else if (item.bioEntryId === BIRTHPLACE_BIO_ENTRY_ID) {
        item.textValue = formState.birthPlace.trim();
      } else if (item.bioEntryId === BIRTH_COUNTRY_BIO_ENTRY_ID) {
        item.textValue = formState.birthCountryName.trim();
      }

    });

    //add new objects to bios array
    // remove birthCountryCode before mapping key value pairs
    delete formState?.birthCountry;
    const keys = Object.keys(formState);

    const bioTypes = {
      height: HEIGHT_BIO_ENTRY_ID,
      weight: WEIGHT_BIO_ENTRY_ID,
      highSchoolName: HIGH_SCHOOL_BIO_ENTRY_ID,
      highSchoolGraduationYear: HIGH_SCHOOL_GRADUATION_BIO_ENTRY_ID,
      highSchoolCity: HIGH_SCHOOL_CITY_BIO_ENTRY_ID,
      college: COLLEGE_BIO_ENTRY_ID,
      collegeGraduationYear: COLLEGE_GRADUATION_BIO_ENTRY_ID,
      hometown: HOMETOWN_BIO_ENTRY_ID,
      birthPlace: BIRTHPLACE_BIO_ENTRY_ID,
      birthCountryName: BIRTH_COUNTRY_BIO_ENTRY_ID,
    };

    keys.forEach((key) => {
      addNewBioEntry(bios, bioTypes[key], formState[key]);
    });

    //remove objects with empty textValue
    let filteredPersonBio = bios.filter((item) => item.textValue.length > 0);

    return {
      ...personProfileState.objData,
      bios: filteredPersonBio
    };
  };

  useEffect(() => {
    if (personProfileState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personProfileState.objData)) {
      getPersonProfile(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  useEffect(() => {
    if (personProfileState.isObjLoaded === true && isPersonObjectDataCurrent(basePersonState.objData.personId, personProfileState.objData)
      && environmentVariableState.isLoaded) {
      const bios = personProfileState.objData.bios;

      const heightObj = bios.find(obj => {
        return obj.bioEntryId === HEIGHT_BIO_ENTRY_ID;
      });

      const weightObj = bios.find(obj => {
        return obj.bioEntryId === WEIGHT_BIO_ENTRY_ID;
      });

      const highSchoolNameObj = bios.find(obj => {
        return obj.bioEntryId === HIGH_SCHOOL_BIO_ENTRY_ID;
      });

      const highSchoolGraduationYearObj = bios.find(obj => {
        return obj.bioEntryId === HIGH_SCHOOL_GRADUATION_BIO_ENTRY_ID;
      });

      const highSchoolCityObj = bios.find(obj => {
        return obj.bioEntryId === HIGH_SCHOOL_CITY_BIO_ENTRY_ID;
      });

      const collegeObj = bios.find(obj => {
        return obj.bioEntryId === COLLEGE_BIO_ENTRY_ID;
      });

      const collegeGraduationYearObj = bios.find(obj => {
        return obj.bioEntryId === COLLEGE_GRADUATION_BIO_ENTRY_ID;
      });

      const hometownObj = bios.find(obj => {
        return obj.bioEntryId === HOMETOWN_BIO_ENTRY_ID;
      });

      const birthPlaceObj = bios.find(obj => {
        return obj.bioEntryId === BIRTHPLACE_BIO_ENTRY_ID;
      });


      const birthCountryObj = bios.find(obj => {
        return obj.bioEntryId === BIRTH_COUNTRY_BIO_ENTRY_ID;
      });


      setFormData({
        ...formState,
        height: heightObj !== undefined ? heightObj.textValue : '',
        weight: weightObj !== undefined ? weightObj.textValue : '',
        highSchoolName: highSchoolNameObj !== undefined ? highSchoolNameObj.textValue : '',
        highSchoolGraduationYear: highSchoolGraduationYearObj !== undefined ? highSchoolGraduationYearObj.textValue : '',
        highSchoolCity: highSchoolCityObj !== undefined ? highSchoolCityObj.textValue : '',
        college: collegeObj !== undefined ? collegeObj.textValue : '',
        collegeGraduationYear: collegeGraduationYearObj !== undefined ? collegeGraduationYearObj.textValue : '',
        hometown: hometownObj !== undefined ? hometownObj.textValue : '',
        birthPlace: birthPlaceObj !== undefined ? birthPlaceObj.textValue : '',
        birthCountryName: birthCountryObj !== undefined ? birthCountryObj.textValue : '',

      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState, environmentVariableState]);

  function getInitialFormState() {
    return {
      height: '',
      weight: '',
      highSchoolName: '',
      highSchoolCity: '',
      highSchoolGraduationYear: '',
      college: '',
      collegeGraduationYear: '',
      hometown: '',
      birthPlace: '',
      birthCountryName: ''
    };
  };

  return {
    personProfileState,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    resetForm,
    onValueTextPairChanged
  };
};

export default useProfileBioInfo;
