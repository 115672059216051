import React from 'react';
import MemberContextProviders from './MemberContextProviders';
import BasicMemberRoutes from './BasicMemberRoutes';

const Member = () => {
  return (
    <MemberContextProviders>
      <BasicMemberRoutes />
    </MemberContextProviders>
  );
};

export default Member;