import { useContext } from 'react';

import { SearchOtsOfficialsForBulkRecertifyStateContext } from './SearchOtsOfficialsForBulkRecertifyContextProvider';
import { SearchOtsOfficialsForBulkRecertifyFilterStateContext } from './SearchOtsOfficialsForBulkRecertifyFilterContextProvider'

import OtsOfficialsBulkRecertifyData from './OtsOfficialsBulkRecertifyData';

const useOtsOfficialsBulkRecertifyData = () => {
  const [searchOtsOfficialsForBulkRecertifyState, setSearchOtsOfficialsForBulkRecertifyState] = useContext(SearchOtsOfficialsForBulkRecertifyStateContext);
  const [searchOtsOfficialsForBulkRecertifyFilterState, setSearchOtsOfficialsForBulkRecertifyFilterState] = useContext(SearchOtsOfficialsForBulkRecertifyFilterStateContext);

  const searchForOfficialsForBulkRecertify = (filterObject, sortBy) => {
    setSearchOtsOfficialsForBulkRecertifyFilterState({ ...searchOtsOfficialsForBulkRecertifyFilterState, filterObject, sortBy });
    OtsOfficialsBulkRecertifyData.searchForOfficialsForBulkRecertify(JSON.stringify(filterObject), sortBy, searchOtsOfficialsForBulkRecertifyState, setSearchOtsOfficialsForBulkRecertifyState);
  };

  const putOtsOfficialBulkRecertify = (otsBulkRecertifyObj) => {
    OtsOfficialsBulkRecertifyData.putOtsOfficialBulkRecertify(otsBulkRecertifyObj, searchOtsOfficialsForBulkRecertifyState, setSearchOtsOfficialsForBulkRecertifyState);
  };

  const clearSearchOtsOfficialsForBulkRecertifyObjData = () => {
    setSearchOtsOfficialsForBulkRecertifyState({
      ...setSearchOtsOfficialsForBulkRecertifyState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearSearchOtsOfficialsForBulkRecertifyArrayData = () => {
    setSearchOtsOfficialsForBulkRecertifyState({
      ...setSearchOtsOfficialsForBulkRecertifyState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const resetOtsOfficialBulkRecertifyState = () => {
    setSearchOtsOfficialsForBulkRecertifyState(OtsOfficialsBulkRecertifyData.INITIAL_STATE);
  };

  const resetSearchOtsOfficialsForBulkRecertifyFilterState = () => {
    setSearchOtsOfficialsForBulkRecertifyFilterState({
      filterObject: {},
      sortBy: '',
      backToSystem: 'Ots Official'
    });
  };

  return {
    searchOtsOfficialsForBulkRecertifyState,
    setSearchOtsOfficialsForBulkRecertifyState,
    searchForOfficialsForBulkRecertify,
    searchOtsOfficialsForBulkRecertifyFilterState,
    setSearchOtsOfficialsForBulkRecertifyFilterState,
    clearSearchOtsOfficialsForBulkRecertifyObjData,
    clearSearchOtsOfficialsForBulkRecertifyArrayData,
    resetSearchOtsOfficialsForBulkRecertifyFilterState,
    putOtsOfficialBulkRecertify,
    resetOtsOfficialBulkRecertifyState
  };
};

export default useOtsOfficialsBulkRecertifyData;