import { Fragment } from 'react';
import MeetEvaluatorsGridLarge from './MeetEvaluatorsGridLarge';
import MeetEvaluatorsGridSmall from './MeetEvaluatorsGridSmall';

const MeetEvaluatorsGrid = ({ gridData, onDeleteButtonClicked }) => {

  return (
    <Fragment>
      <MeetEvaluatorsGridLarge gridData={gridData} onDeleteButtonClicked={onDeleteButtonClicked} />
      <MeetEvaluatorsGridSmall gridData={gridData} onDeleteButtonClicked={onDeleteButtonClicked} />
    </Fragment>
  );
};

export default MeetEvaluatorsGrid;