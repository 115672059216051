import React, { Fragment } from 'react';

import { formatDate, getTodaysDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ officialLscCert, formState }) => {
  const todayDate = getTodaysDate();
  const today = new Date(todayDate);
  const expirationDateLocal = new Date(officialLscCert?.expirationDate)
  if (formState?.showAllLscCertificationHistory === false && (officialLscCert?.isActive === true || today <= expirationDateLocal)) {
    return (
      <Fragment>
        <tr>
          <td>{officialLscCert?.positionTypeName} - {officialLscCert?.positionAbbreviation}</td>
          <td>{officialLscCert?.sessionsWorked}</td>
          <td>{officialLscCert?.levelName}</td>
          <td>{formatDate(officialLscCert?.effectiveDate)}</td>
          <td>{formatDate(officialLscCert?.expirationDate)}</td>
        </tr>
      </Fragment>
    )
  } else if (formState?.showAllLscCertificationHistory === true) {
    return (
      <Fragment>
        <tr>
          <td>{officialLscCert?.positionTypeName} - {officialLscCert?.positionAbbreviation}</td>
          <td>{officialLscCert?.sessionsWorked}</td>
          <td>{officialLscCert?.levelName}</td>
          <td>{formatDate(officialLscCert?.effectiveDate)}</td>
          <td>{formatDate(officialLscCert?.expirationDate)}</td>
        </tr>
      </Fragment>
    )
  } else {
    return (
      <Fragment />
    )
  }
}

const OtsOfficialLscCertificationLargeReadGrid = ({ state, formState }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Position</th>
        <th>Sessions Worked</th>
        <th>Certification Level</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state?.arrayData?.certifications?.length > 0 ?
        state?.arrayData?.certifications?.map((officialLscCert, i) =>
          <LargeGridRow
            key={i}
            officialLscCert={officialLscCert}
            formState={formState}
          />)
        : state?.isArrayLoading
          ? <tr><td colSpan="6">Loading...</td></tr>
          : <tr><td colSpan="6">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default OtsOfficialLscCertificationLargeReadGrid