import { useState, } from 'react';

import PersonRequirementsData from './PersonRequirementsData';

const usePersonRequirements = () => {
  const [memberRequriementState, setMemberRequriementState] = useState(PersonRequirementsData.INITIAL_STATE);
  const [adminEnrollmentState, setAdminEnrollmentState] = useState(PersonRequirementsData.INITIAL_STATE);
  const [deleteEnrollmentState, setDeleteEnrollmentState] = useState(PersonRequirementsData.INITIAL_STATE);

  const getPersonRequirements = (personId) => {
    PersonRequirementsData.getPersonRequirements(personId, memberRequriementState, setMemberRequriementState);
  };

  
  const getPersonRequirementsAdvanced = (personId) => {
    PersonRequirementsData.getPersonRequirementsAdvanced(personId, memberRequriementState, setMemberRequriementState);
  };


  const postEnrollmentMemberRequirmentAdmin = (courseId, personId, dateObj) => {
    PersonRequirementsData.postEnrollmentMemberRequirmentAdmin(courseId, personId, dateObj, adminEnrollmentState, setAdminEnrollmentState);
  };


  const deletePersonRequirementEnrollment = (courseEnrollmentId) => {
    PersonRequirementsData.deletePersonRequirementEnrollment(courseEnrollmentId, deleteEnrollmentState, setDeleteEnrollmentState);
  };



  return {
    memberRequriementState,
    setMemberRequriementState,
    getPersonRequirements,
    postEnrollmentMemberRequirmentAdmin,
    adminEnrollmentState,
    setAdminEnrollmentState,
    getPersonRequirementsAdvanced,
    deletePersonRequirementEnrollment,
    deleteEnrollmentState,
    setDeleteEnrollmentState
  };
};

export default usePersonRequirements;