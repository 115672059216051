import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import PersonIcon from '../../../../../common/components/icons/PersonIcon';
import FileExclamationIcon from '../../../../../common/components/icons/FileExclamationIcon';

import { formatDate, getTodaysDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailGridRowCourse = ({ officialCourse }) => {
  const todaysDate = getTodaysDate();
  const dateOfToday = new Date(todaysDate);
  const expirationDateForCourse = new Date(officialCourse?.courseExpirationDate);
  const formattedCourseExpirationDate = formatDate(officialCourse?.courseExpirationDate);

  return (
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableDetailRowHead}>
        <span className='hidden-xs'>{officialCourse.courseName} ({officialCourse?.courseCode})</span>&nbsp;
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Course Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {officialCourse.courseName} ({officialCourse?.courseCode})
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Completion Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {officialCourse?.courseCompletionDate !== null && officialCourse?.coursePassed === true
              ? formatDate(officialCourse?.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
                ? <span>&nbsp;</span>
                : formatDate(officialCourse?.courseCompletionDate)
              : <span>&nbsp;</span>
            }
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Expiration Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {officialCourse?.courseExpirationDate !== null && officialCourse?.coursePassed === true
              ? formatDate(officialCourse?.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? 'No Expiration'
                : expirationDateForCourse < dateOfToday
                  ? <span style={{ color: 'red' }}>{formattedCourseExpirationDate}&nbsp;&nbsp;<FileExclamationIcon /></span>
                  : formatDate(officialCourse?.courseExpirationDate)
              : <span>&nbsp;</span>
            }
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Status
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {officialCourse.status}
          </div>
        </div>
      </div>
    </div>
  )
};

const DetailGridCourses = ({ official }) => (
  <div className={global.SmallTableDetailTable}>
    {(!Array.isArray(official?.courses) || official?.courses.length <= 0) &&
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableDetailRowHead}>
            <span>Course Name</span>&nbsp;
          </div>
        </div>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
                No Courses
              </div>
            </div>
          </div>
        </div>
      </Fragment>}
    {Array.isArray(official?.courses) && official?.courses.length > 0 &&
      official?.courses?.map((officialCourse, i) =>
        <DetailGridRowCourse
          key={i}
          officialCourse={officialCourse}
        />)}
  </div>
);

const SmallGridRowIcons = ({ official, onSelectMember, expandedId, onExpandClicked }) => (
  <Fragment>
    <button
      className={global.IconButton}
      type="button"
      onClick={(e) => { let id = official?.personId + official?.lscName + official?.clubName; onExpandClicked(e, id) }}>
      {expandedId === official?.personId ? <HideIcon /> : <ShowIcon />}
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onSelectMember(e, official)}>
      <PersonIcon />
    </button>
  </Fragment>
);

const SmallGridRow = ({ official, onSelectMember, expandedId, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{official.firstName || ''} {official.lastName || ''} - {official?.memberId}</span>&nbsp;
        <SmallGridRowIcons
          official={official}
          onSelectMember={onSelectMember}
          expandedId={expandedId}
          onExpandClicked={onExpandClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {official.firstName || ''} {official.lastName || ''}
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Member ID
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {official?.memberId}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            LSC
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {official?.lscName}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Club Name
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {official?.clubName}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Role
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {official?.roleName}
          </div>
          <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>
            Registration Expires
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Reg. Expires
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {formatDate(official?.registrationExpirationDate)}
          </div>
        </div>
      </div>
    </div>
    {expandedId === official?.personId + official?.lscName + official?.clubName &&
      <Fragment>
        <DetailGridCourses official={official} personId={official?.personId} />
      </Fragment >
    }
  </Fragment>
);


const OtsOfficialsSmallGrid = ({ state, onSelectMember, expandedId, onExpandClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(state?.arrayData) && state?.arrayData?.length > 0
      ? state?.arrayData?.map((official, i) =>
        <SmallGridRow
          key={i}
          official={official}
          onSelectMember={onSelectMember}
          expandedId={expandedId}
          onExpandClicked={onExpandClicked} />)
      : (
        <div>
          {state?.isArrayLoading
            ? (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
              </div>
            ) : (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
              </div>
            )
          }
        </div>
      )
    }
  </div>
);

export default OtsOfficialsSmallGrid;