import React, { Fragment } from 'react';

import useMyLscOtsMeetSearch from './UseMyLscOtsMeetSearch';

import SearchOtsMeet from '../../../../components/searchOfficialsTrackingSystemMeet/SearchOtsMeet';

const MyLscOtsMeetSearch = () => {
  const { selectOrgUnitState, searchOtsMeetState, onPostOtsMeet, onEditOtsMeet } = useMyLscOtsMeetSearch();

  return (
    <Fragment>
      <SearchOtsMeet
        pageTitle={""}
        searchButtonText={'Search for OTS Meet'}
        onPost={onPostOtsMeet}
        onEdit={onEditOtsMeet}
        selectOrgUnitState={selectOrgUnitState}
        searchOtsMeetState={searchOtsMeetState}
      />
    </Fragment>
  );
};

export default MyLscOtsMeetSearch;