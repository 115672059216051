import React, { Fragment } from 'react';

import useOfficialsTrackingSystemOfficialContextView from './UseOfficialsTrackingSystemOfficialContextView';

import Headings from '../../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import MobileContextBasedNav from '../../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../../common/components/contextBasedNav/LargeContextBasedNav';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import PageNotFound from '../../../../common/components/PageNotFound';

import Constants from '../../../../common/utils/Constants';

import style from './OfficialStyle.module.css';
import global from '../../../../common/components/GlobalStyle.module.css';

const OfficialsTrackingSystemOfficialContextView = ({ children }) => {
  const {
    otsOfficialHeaderState,
    emailAddress,
    backToLabel,
    onBackClicked,
    isLoading
  } = useOfficialsTrackingSystemOfficialContextView();

  if (!otsOfficialHeaderState || Object.keys(otsOfficialHeaderState.objData)?.length === 0) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              {otsOfficialHeaderState.isLoggedInUser === true ? 'My Official History' : 'View/Edit Official'}
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      {otsOfficialHeaderState?.isLoggedInUser === false ?
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        : <Fragment />}
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6">
          <p className={global.HeaderText}>
            <strong>Official Name:</strong>&nbsp;
            {otsOfficialHeaderState.objData?.firstName || ''} {otsOfficialHeaderState.objData?.lastName || ''}
          </p>
          <p className={global.HeaderText}>
            <strong>Member ID:</strong>&nbsp;
            {otsOfficialHeaderState.objData?.memberId || ''}
          </p>
        </div>
        <div className="col-xs-12 col-sm-6 ">
          <p className={[global.HeaderText, style.RemoveTopMarginMobile].join(' ')}>
            <strong>Email Address:</strong>&nbsp;
            {emailAddress || ''}
          </p>
          <p className={global.HeaderText}>
            <strong>Role:</strong>&nbsp;
            {otsOfficialHeaderState.objData?.roleName || ''}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="OTSOfficialDetail" renderPillsOnly={true} />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="OTSOfficialDetail" renderPillsOnly={true} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      {children}
    </Fragment >
  )
};

export default OfficialsTrackingSystemOfficialContextView;