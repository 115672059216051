import React, { Fragment } from 'react';

import useOtsOfficialLscCertificationRecertify from './UseOtsOfficialLscCertificationRecertify';

import Headings from '../../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const ErrorHandle = ({ error, message }) => (
  <Fragment>
    {error && <p className={global.ErrorMessage}>{message}</p>}
  </Fragment>
);

const OtsOfficialLscCertificationRecertify = () => {
  const {
    onSubmit,
    formState,
    errorState,
    onBackToLscCert,
    onFormValueChanged,
    state,
    otsLscRecertifyState,
    countOfYears,
    startYearOffset
  } = useOtsOfficialLscCertificationRecertify();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Headings.H3>Re-Certify LSC Certification(s)</Headings.H3>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <table className={global.DetailTable}>
            <thead>
              <tr>
                <th style={{ width: '25%', textAlign: 'left', paddingTop: '5px', paddingBottom: '5px' }}>&nbsp;&nbsp;Certification</th>
                <th style={{ width: '25%', textAlign: 'left', paddingTop: '5px', paddingBottom: '5px' }}>Position Name</th>
                <th style={{ width: '25%', textAlign: 'left', paddingTop: '5px', paddingBottom: '5px' }}>Effective - Expiration Date&nbsp;&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {state?.selectedLscCertifications?.map((selectedCert, i) => {
                return (
                  <tr key={i}>
                    <td style={{ width: '25%', paddingTop: '5px', paddingBottom: '5px' }}>&nbsp;&nbsp;{selectedCert?.levelName}</td>
                    <td style={{ width: '25%', paddingTop: '5px', paddingBottom: '5px' }}>{selectedCert?.positionTypeName} ({selectedCert?.positionAbbreviation})</td>
                    <td style={{ width: '25%', paddingTop: '5px', paddingBottom: '5px' }}>{formatDate(selectedCert?.effectiveDate)} - {formatDate(selectedCert?.expirationDate)}&nbsp;&nbsp;</td>
                  </tr>
                )
              })
              }
            </tbody>
          </table>
        </div>
      </div>
      <form>
        <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
          <div className="col-xs-12 col-sm-6 col-md-4 usas-extra-bottom-margin">
            <DatePicker
              label="Extend Expiration Date To*"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              onChange={(value) => { onFormValueChanged('expirationDate', value); }}
              countOfYears={countOfYears}
              startYearOffset={startYearOffset} />
          </div>
        </div>
        <span className='col-xs-12'>
          <ErrorHandle error={state.errorMessage} message={state.errorMessage} />
        </span>
        <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <PrimaryButton type="button" onClick={onSubmit}>Save</PrimaryButton>&nbsp;
            <SecondaryButton onClick={onBackToLscCert}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={otsLscRecertifyState?.isSaving} />
    </Fragment>
  );
};

export default OtsOfficialLscCertificationRecertify;