import { isValidUSOrCanadianPhoneNumber, isValidMexicanPhoneNumber } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  //Phone Type
  if (formState.phoneTypeId < 0) {
    errors.phoneTypeId = 'Phone Type is required';
  }

  //Phone Number
  if (!formState.phoneNumber) {
    errors.phoneNumber = 'Phone Number is required';
  } else if (!isValidUSOrCanadianPhoneNumber(formState.phoneNumber) && !isValidMexicanPhoneNumber(formState.phoneNumber)) {
    errors.phoneNumber = 'Must enter a valid US, Canadian, or Mexican phone number in +1/+52 (XXX) XXX-XXXX format';
  }

  return errors;
};

const NationalTeamContactPhoneDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default NationalTeamContactPhoneDetailValidation;