import React from 'react';

import useAffiliationsOrgRoleTreeView from './UseAffiliationsOrgRoleTreeView';

import TreeView from '../../../common/components/tree/TreeView';

import Constants from '../../../common/utils/Constants';

import global from '../../../common/components/GlobalStyle.module.css';

//TODO replace with common component
const AffiliationsOrgRoleTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange, organizationId }) => {
  const { affiliationsOrgRoleState } = useAffiliationsOrgRoleTreeView(organizationId || Constants.DEFAULT_ID);

  return affiliationsOrgRoleState.message
    ? <span className={global.LoadingMsg}>{affiliationsOrgRoleState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={affiliationsOrgRoleState.isArrayLoading}
      options={affiliationsOrgRoleState.treeData}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default AffiliationsOrgRoleTreeView;