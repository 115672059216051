import React from 'react';

import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';

const EvaluationFormYesNoSwitch = ({ question, onItemClick, disabled }) => {
  return (
    <div className="row">
      <div className="col-xs-12" dangerouslySetInnerHTML={{ __html: question.acknowledgementFormText }}></div>
      <div className="col-xs-12">
        <YesNoSwitch
          isHorizontal={false}
          name={`question${question.evaluationQuestionId}`}
          label={question.question || ''}
          checked={(question.field === 'true' || question.field === 'false') ? JSON.parse(question.field?.toLowerCase()) : (question.field ?? '')}
          onChange={value => onItemClick(question.evaluationQuestionId, value)}
          disabled={disabled} />
      </div>
    </div>
  );
};

export default EvaluationFormYesNoSwitch;