import React, { createContext, useState } from 'react';

import SearchMemberData from './SearchMemberData';

export const SearchMemberStateContext = createContext();

const SearchMemberContextProvider = ({ children }) => {
  const stateHook = useState(SearchMemberData.INITIAL_STATE);

  return (
    <SearchMemberStateContext.Provider value={stateHook}>
      {children}
    </SearchMemberStateContext.Provider>
  );
};

export default SearchMemberContextProvider;