import React from 'react';

import useSessionMultiSelect from './UseSessionMultiSelect';

import MultiSelect from '../../../common/components/multiSelect/MultiSelect';

import global from '../../../common/components/GlobalStyle.module.css';

const SessionMultiSelect = ({ meetId, label, name, value, error, message, onChange }) => {
  const { otsMeetSessionsState } = useSessionMultiSelect(meetId);

  return otsMeetSessionsState.message
    ? <span className={global.LoadingMsg}>{otsMeetSessionsState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={otsMeetSessionsState.options}
        error={error}
        message={message}
        isLoading={otsMeetSessionsState.isArrayLoading}
        onChange={onChange} />
    );
};

export default SessionMultiSelect;