import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import Constants from '../../../../common/utils/Constants';

import usePersonRequirementsData from '../../../state/member/memberRequriements/UsePersonRequriementsData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useUserData from '../../../../common/state/lmsUser/UseUserData';
import useEnrollmentData from '../../../../common/state/lmsEnrollment/UseEnrollmentData';
import useForm from '../../../../common/utils/UseForm';

const INITIAL_FORM_STATE = {
  goodStandingId: Constants.DEFAULT_ID,
  goodStandingName: ''
};

const INITIAL_DELETE_ENROLLMENT_MODAL_STATE = {
  modalTitle: 'Delete Enrollment',
  displayPopUp: false,
  courseEnrollmentId: Constants.DEFAULT_ID,
  course: {},
  courseName: '',
};

const INITIAL_STATE = {
  viewInfoSet: false
}


const useMemberRequirements = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const [gridDataState, setGridDataState] = useState([]);
  const [state, setState] = useState(INITIAL_STATE)
  const [deleteEnrollmentModalState, setDeleteEnrollmentModalState] = useState(INITIAL_DELETE_ENROLLMENT_MODAL_STATE);
  const { basePersonState } = useBasePersonData();
  const { memberRequriementState, getPersonRequirements, deleteEnrollmentState, deletePersonRequirementEnrollment } = usePersonRequirementsData();
  const { formState, errorState, onValueTextPairChanged } = useForm(INITIAL_FORM_STATE);
  const { enrollmentState, getUserEnrollment } = useEnrollmentData();
  const { userState, getUser } = useUserData();

  const onEditRequirement = (e, course) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_REQUIREMENTS_DETAIL?.route, { state: { course: course } });
  };


  const onDeleteRequirement = (e, course) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const courseEnrollmentId = course?.courseEnrollmentId;
    deletePersonRequirementEnrollment(courseEnrollmentId);
    setState({ ...INITIAL_STATE });
    setTimeout(() => {
      onDeleteEnrollmentModalCanceled();
    }, 250);
  }


  const onOpenDeleteEnrollmentModal = (e, course) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setDeleteEnrollmentModalState({
      ...deleteEnrollmentModalState,
      displayPopUp: true,
      course: course
    });
  };

  const onDeleteEnrollmentModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setDeleteEnrollmentModalState({
      ...deleteEnrollmentModalState,
      displayPopUp: false
    });
  };



  useEffect(() => {
    if (memberRequriementState?.isArrayLoaded === true) {

      setGridDataState(memberRequriementState?.arrayData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberRequriementState]);


  useEffect(() => {
    if (userState?.isObjLoaded !== true
      && userState?.isObjLoading !== true && userState?.message === '') {

      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);


  useEffect(() => {
    if (enrollmentState?.isArrayLoaded === false && enrollmentState?.isArrayLoading === false) {

      getUserEnrollment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentState]);


  useEffect(() => {
    if ((memberRequriementState?.isArrayLoaded === false && memberRequriementState?.isArrayLoading === false)
      || (deleteEnrollmentState?.isSaved === true && deleteEnrollmentState?.objData?.message !== '')) {
      if (basePersonState?.isObjLoaded === true && state?.viewInfoSet === false) {
        const personId = basePersonState?.objData?.personId;

        getPersonRequirements(personId);
        setState({ ...state, viewInfoSet: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState?.isObjLoaded, memberRequriementState?.isArrayLoaded, deleteEnrollmentState?.isSaved]);

  return {
    basePersonState,
    isGridLoading: memberRequriementState?.isArrayLoading,
    gridDataState,
    onEditRequirement,
    onDeleteRequirement,
    formState,
    errorState,
    onValueTextPairChanged,
    goodStandingData: memberRequriementState?.arrayData,
    enrollmentState,
    onOpenDeleteEnrollmentModal,
    onDeleteEnrollmentModalCanceled,
    deleteEnrollmentState,
    deleteEnrollmentModalState
  };
};

export default useMemberRequirements;