import useOfferingTypeUSASForm from './UseOfferingTypeUSASForm';

import Input from '../../../../../../common/components/inputs/Input';
import Textarea from '../../../../../../common/components/inputs/Textarea';
import ReadOnly from '../../../../../../common/components/readOnly/ReadOnly';
import YesNoSwitch from '../../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../../common/components/buttons/SecondaryButton';
import OrgRoleTreeView from '../../../../../../common/components/tree/orgRoleTree/OrgRoleTreeView';

import global from '../../../../../../common/components/GlobalStyle.module.css';
import style from '../../../OfferingManagement.module.css';

const OfferingTypeUSASForm = ({ offeringObj, submitButtonText = 'Save', onSubmitFormCallback,
  onBackClicked, onDeleteClicked = undefined }) => {
  const {
    organizationId,
    formState,
    errorState,

    updateFormState,
    onValueTextPairChanged,
    handleSubmit
  } = useOfferingTypeUSASForm(onSubmitFormCallback, offeringObj);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Offering Type"
            name="offeringTypeName"
            value={offeringObj.offeringTypeName} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Offering Name*"
            name="offeringName"
            value={formState.offeringName}
            error={errorState.offeringName}
            message={errorState.offeringName}
            onChange={(value) => { updateFormState('offeringName', value); }} />
        </div>
        <div className="col-xs-12 col-md-4">
          <label className={style.OfferingFormAgeRangeHeading}>Age Range</label>
          <div className="col-xs-6">
            <Input
              label="Start*"
              type="number"
              min="0"
              name="ageStart"
              value={formState.ageStart}
              error={errorState.ageStart}
              message={errorState.ageStart}
              onChange={(value) => { updateFormState('ageStart', value); }} />
          </div>
          <div className="col-xs-6">
            <Input
              label="End*"
              type="number"
              min="1"
              name="ageEnd"
              value={formState.ageEnd}
              error={errorState.ageEnd}
              message={errorState.ageEnd}
              onChange={(value) => { updateFormState('ageEnd', value); }} />
          </div>
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          {organizationId &&
            <OrgRoleTreeView
              label="Org Role Associated with Offering*"
              name="offeringOrgRole"
              value={formState.offeringOrgRole}
              error={errorState.offeringOrgRole}
              message={errorState.offeringOrgRole}
              selectableLeavesOnly={true}
              singleSelect={true}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'offeringOrgRole', newValueLabel, 'orgRoleId') }}
              organizationId={organizationId} />
          }
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <YesNoSwitch
            label="Is Eligible For Bogo"
            name="isEligibleForBogo"
            checked={formState.isEligibleForBogo === true}
            error={errorState.isEligibleForBogo}
            message={errorState.isEligibleForBogo}
            onChange={(value) => { updateFormState('isEligibleForBogo', value); }} />
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <YesNoSwitch
            label="Is Eligible For LSC Bogo"
            name="isEligibleForLscBogo"
            checked={formState.isEligibleForLscBogo === true}
            error={errorState.isEligibleForLscBogo}
            message={errorState.isEligibleForLscBogo}
            onChange={(value) => { updateFormState('isEligibleForLscBogo', value); }} />
        </div>
        <div className='col-xs-12'>
          <Textarea
            className={global.Textarea}
            label="Offering Description*"
            name="offeringDescription"
            value={formState.offeringDescription}
            error={errorState.offeringDescription}
            message={errorState.offeringDescription}
            onChange={(value) => { updateFormState('offeringDescription', value) }} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>&nbsp;
          {offeringObj.offeringId > 0 && onDeleteClicked &&
            <SecondaryButton type="button" onClick={(e) => onDeleteClicked(e, formState)}>Delete</SecondaryButton>
          }
        </div>
      </div>
    </form>
  );
};

export default OfferingTypeUSASForm;