import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getHQBoardDirectors from './GetHQBoardDirectorsData';
import postHQBoardDirector from '../../orgUnitStaff/PostOrgUnitStaffData';
import putHQBoardDirector from '../../orgUnitStaff/PutOrgUnitStaffData';
import deleteHQBoardDirector from '../../orgUnitStaff/DeletePersonOrgRoleDurationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberAdministrationHQBoardData = {
  INITIAL_STATE,
  getHQBoardDirectors,
  postHQBoardDirector,
  putHQBoardDirector,
  deleteHQBoardDirector
};

export default MemberAdministrationHQBoardData;