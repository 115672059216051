import { useState } from 'react';
import OfficialsTrackingSystemOnlineTestsData from './OfficialsTrackingSystemOnlineTestsData';

const useOfficialsTrackingSystemOnlineTestsData = () => {
  const [ OfficialsTrackingSystemOnlineTestsState, setOfficialsTrackingSystemOnlineTestsState ] = useState({ ...OfficialsTrackingSystemOnlineTestsData.INITIAL_STATE });

  const getOfficialsTrackingSystemOnlineTests = (personId, isClinic) => {
    return OfficialsTrackingSystemOnlineTestsData.getOfficialsTrackingSystemOnlineTests(personId, isClinic, OfficialsTrackingSystemOnlineTestsState, setOfficialsTrackingSystemOnlineTestsState);
  }

  return {
    getOfficialsTrackingSystemOnlineTests
  };
};

export default useOfficialsTrackingSystemOnlineTestsData;