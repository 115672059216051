import CurrencyInput from '../../../../../../common/components/inputs/CurrencyInput';
import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';
import ActionIntraPageButton from '../../../../../../common/components/buttons/ActionIntraPageButton';

import { convertNumberToCurrencyString } from '../../../../../../common/utils/CurrencyUtils';
import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';

import global from '../../../../../../common/components/GlobalStyle.module.css';
import style from '../../../OfferingManagement.module.css';

const GridRow = ({ coupon, onCouponValueChanged, onSaveCouponValue }) => (
  <tr>
    <td>{coupon.couponName}</td>
    <td className={style.CouponFormGridInputCell}>
      {coupon.isActive === true
        ? (
          <CurrencyInput
            label=''
            name={"couponValue" + coupon.offeringInstanceCouponDiscountId}
            value={coupon.couponValue}
            onChange={(value) => { onCouponValueChanged(coupon.offeringInstanceCouponDiscountId, value); }} />
        ) : <span>${convertNumberToCurrencyString(coupon.couponValue)}</span>
      }
    </td>
    <td className={style.CouponFormGridButtonCell}>
      {coupon.isActive === true
        ? <ActionIntraPageButton onClick={(e) => onSaveCouponValue(e, coupon)}>Save</ActionIntraPageButton>
        : <span>&nbsp;</span>
      }
    </td>
  </tr>
)

const OfferingInstanceCouponDiscountFormGridLarge = ({ gridData, isLoading, onCouponValueChanged, onSaveCouponValue }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Coupon Name'} columnField={'couponName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>LSC Fee</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="3">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) === true && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((coupon, i) => <GridRow key={i} coupon={coupon} onCouponValueChanged={onCouponValueChanged} onSaveCouponValue={onSaveCouponValue} />)
            : <tr><td colSpan="3">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default OfferingInstanceCouponDiscountFormGridLarge;