import { Fragment } from "react";

import OfferingGridLarge from "./OfferingGridLarge";
import OfferingGridSmall from "./OfferingGridSmall";

import useExpandedRow from '../../../../../../common/components/grids/UseExpandedRow';

const OfferingGrid = ({ gridData, isLoading, onEditClicked }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <OfferingGridLarge gridData={gridData} isLoading={isLoading} expandedId={expandedId} onEditClicked={onEditClicked} onExpandClicked={onClick} />
      <OfferingGridSmall gridData={gridData} isLoading={isLoading} expandedId={expandedId} onEditClicked={onEditClicked} onExpandClicked={onClick} />
    </Fragment>
  );
};

export default OfferingGrid;