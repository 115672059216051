import { useContext } from 'react';

import { BaseMemberStateContext } from './BaseMemberContextProvider';
import BaseMemberData from './BaseMemberData';

const useBaseMemberData = () => {
  const [baseMemberState, setBaseMemberState] = useContext(BaseMemberStateContext);

  const getBaseMember = (personId) => BaseMemberData.getBaseMemberData(personId, baseMemberState, setBaseMemberState);

  const clearObjData = () => {
    setBaseMemberState({
      ...baseMemberState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    baseMemberState,
    clearObjData,
    getBaseMember
  };
};

export default useBaseMemberData;