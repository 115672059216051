import { useEffect } from 'react';

import useOtsMeetSessionsData from '../../../common/state/officialsTrackingSystemMeetSessions/UseOtsMeetSessionsData';

const useSessionMultiselect = (meetId) => {
  const { otsMeetSessionsState, getMeetSessions } = useOtsMeetSessionsData();

  useEffect(() => {
    if (meetId > 0) {
      if (otsMeetSessionsState.isArrayLoading !== true && otsMeetSessionsState.isArrayLoaded !== true) {
        getMeetSessions(meetId);
      } 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetSessionsState, meetId]);

  return {
    otsMeetSessionsState
  };
};

export default useSessionMultiselect;