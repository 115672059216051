import { useEffect } from 'react';

import useMemberRequirementData from '../../../state/member/memberRequriements/UsePersonRequriementsData';
import useMemberRequirements from '../../../views/member/memberRequirements/UseMemberRequriements';

const useMemberRequriementsFilterDropdown = () => {
  const { goodStandingData } = useMemberRequirements();
  const { memberRequriementState, setMemberRequriementState } = useMemberRequirementData();

  useEffect(() => {
    if (Array?.isArray(goodStandingData) === true && goodStandingData?.length > 0) {
      let newArray = [];
      goodStandingData?.forEach(type => {
        type?.goodStandingType?.forEach(goodStandingType => {
          const obj = newArray.find(x => x.name === goodStandingType?.goodStandingName);
          if (obj === undefined) {
            const goodStandingObj = {
              id: goodStandingType?.goodStandingId,
              name: goodStandingType?.goodStandingName
            }
            newArray.push(goodStandingObj)
          }
        })
      });

      setMemberRequriementState({ ...memberRequriementState, filteredRoleList: newArray })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goodStandingData]);

  return {
    memberRequriementState
  }
};

export default useMemberRequriementsFilterDropdown;