import LookupIcon from '../../../../../common/components/icons/LookupIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';
const GridRow = ({ position, onStatusClicked }) => {
  return (
    <tr>
      <td>{position.positionTypeName} ({position.positionAbbreviation})</td>
      <td>{position.levelName}</td>
      <td>{position.status === 'Approved' && formatDate(position.effectiveDate)}</td>
      <td>{position.status === 'Approved' && position.expirationDate !== '3000-01-01T00:00:00' && formatDate(position.expirationDate)}</td>
      <td>{position.status}</td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onStatusClicked(position)}>
          <LookupIcon toolTipText='Review (Re)certification' />
        </button>
      </td>
    </tr>
  );
};

const GridLarge = ({ gridData, onStatusClicked }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Position</th>
          <th>Level</th>
          <th>Effective Date</th>
          <th>Expiration Date</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(gridData) && gridData.length > 0
          && gridData.map((position, i) => <GridRow key={i} position={position} onStatusClicked={onStatusClicked} />)}
        {(!Array.isArray(gridData) || gridData.length === 0)
          && (
            <tr>
              <td colSpan={6}>
                You are not currently certified at any position as an official, and you do not
                have any certification applications in process. If you believe this is an error,
                please contact your officials chair.
              </td>
            </tr>
          )}
      </tbody>
    </table >
  );
};

export default GridLarge;