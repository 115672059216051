/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import PlusIcon from '../../icons/PlusIcon';
import EditIcon from '../../icons/EditIcon';
import MergeIcon from '../../icons/MergeIcon';
import ExclamationIcon from '../../icons/ExclamationIcon';

import SGColumnHeader from '../../grids/SGColumnHeader';

import Constants from '../../../utils/Constants';
import { formatDate } from '../../../utils/DateFunctions';
import useSortableGrid from '../../../utils/UseSortableGrid';
import SGConstants from '../../../utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';

const COMP_GENDER_OPTIONS = [
  { name: Constants.COMP_GENDER_OPTIONS_UNKNOWN_NAME, value: Constants.COMP_GENDER_OPTIONS_UNKNOWN_VALUE },
  { name: Constants.COMP_GENDER_OPTIONS_MALE_NAME, value: Constants.COMP_GENDER_OPTIONS_MALE_VALUE },
  { name: Constants.COMP_GENDER_OPTIONS_FEMALE_NAME, value: Constants.COMP_GENDER_OPTIONS_FEMALE_VALUE }
];

const LargeGridRow = ({ person, selectedPersonId, onEdit }) => (
  <tr>
    {selectedPersonId
      ? <td>{person.firstName || ''} {person.middleName || ''} {person.lastName || ''}</td>
      : <td>{person.isIneligible === true && <span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText={person.ineligibleReasonDescription} />&nbsp;&nbsp;</span>}<a onClick={(e) => onEdit(e, person.personId)}>{person.firstName || ''} {person.middleName || ''} {person.lastName || ''}</a></td>
    }
    <td>{person.gender ? COMP_GENDER_OPTIONS.find(x => x.value === person.gender).name : ''}</td>
    <td>{person.birthDate ? formatDate(person.birthDate) : ''}</td>
    <td>{person.rootRoleName || ''} - {person.orgRoleName || ''}</td>
    {person.parentOrgUnitCode === null
      ? <td>{person.orgUnitCode || ''}</td>
      : <td>{person.parentOrgUnitCode} | {person.orgUnitCode || ''}</td>
    }
    <td>{person.expirationDate ? formatDate(person.expirationDate) : ''}</td>
    {selectedPersonId
      ? (
        <td>
          {selectedPersonId !== person.personId ?
            <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, person.personId)}>
              <MergeIcon />
            </button> : <Fragment />
          }
        </td>
      ) : <Fragment />
    }
  </tr>
);

const LargeGrid = ({ isLoading, state, onEdit, selectedPersonId }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Competition Category'} columnField={'gender'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Birth Date'} columnField={'birthDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Member Role'} columnField={'orgRoleName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Organization'} columnField={'orgUnitCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Expiration Date'} columnField={'expirationDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          {selectedPersonId ?
            <th></th> : <Fragment />}
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true || isLoading === true
          ? (<tr>
            {selectedPersonId ?
              <Fragment>
                {state.isArrayLoading === true || isLoading === true ?
                  <td colSpan="7">Loading...</td>
                  : <td colSpan="7">No Results</td>
                }
              </Fragment>
              :
              <Fragment>
                {state.isArrayLoading === true || isLoading === true ?
                  <td colSpan="6">Loading...</td>
                  : <td colSpan="6">No Results</td>
                }
              </Fragment>}
          </tr>
          ) : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((person, i) => <LargeGridRow key={i} person={person} selectedPersonId={selectedPersonId} onEdit={onEdit} />
            ) : (
              <Fragment>
                {selectedPersonId ?
                  <tr>
                    <td colSpan="7">No Results</td>
                  </tr>
                  :
                  <tr>
                    <td colSpan="6">No Results</td>
                  </tr>
                }
              </Fragment>
            )
        }
      </tbody>
    </table>
  );
};

const SmallGridRowIcons = ({ person, selectedPersonId, onEdit, onCancelClicked }) => (
  <Fragment>
    {onCancelClicked
      ? (
        <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, person.personId)}>
          <PlusIcon />
        </button>
      ) : selectedPersonId ? (selectedPersonId !== person.personId ?
        <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, person.personId)}>
          <MergeIcon />
        </button> : <Fragment />
      ) : (
        <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, person.personId)}>
          <EditIcon />
        </button>
      )
    }
  </Fragment>
);

const SmallGridRow = ({ person, selectedPersonId, onEdit, onCancelClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{person.isIneligible === true && <span style={{ color: 'white' }}><ExclamationIcon toolTipText={person.ineligibleReasonDescription} />&nbsp;&nbsp;</span>}{person.firstName || ''} {person.middleName || ''} {person.lastName || ''}</span>&nbsp;
      <SmallGridRowIcons person={person} selectedPersonId={selectedPersonId} onEdit={onEdit} onCancelClicked={onCancelClicked} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {person.isIneligible === true && <span style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText={person.ineligibleReasonDescription} />&nbsp;&nbsp;</span>}{(person.firstName || person.middleName || person.lastName) ? `${person.firstName || ''} ${person.middleName || ''} ${person.lastName || ''}` : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>
          Competition Category
        </div>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Comp. Category
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {person.gender ? COMP_GENDER_OPTIONS.find(x => x.value === person.gender).name : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Birth Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {person.birthDate ? formatDate(person.birthDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Member Role
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {person.rootRoleName || ''} - {person.orgRoleName || ''}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Organization
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {person.parentOrgUnitCode === null
            ? <span>{person.orgUnitCode}</span>
            : <span>{person.parentOrgUnitCode} | {person.orgUnitCode}</span>
          }
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {person.expirationDate ? formatDate(person.expirationDate) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ isLoading, state, onEdit, selectedPersonId, onCancelClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true || isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true || isLoading === true
              ? <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
              : <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
            }
          </div>
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((person, i) => <SmallGridRow key={i} person={person} selectedPersonId={selectedPersonId} onEdit={onEdit} onCancelClicked={onCancelClicked} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div>
);

const SearchMemberGrid = ({ isLoading, basePersonState, searchMemberState, onEdit, onFiltersToggle, selectedPersonId, onCancelClicked }) => (
  <Fragment>
    {isLoading === false
      ? (
        <div className="row">
          <div className="col-xs-12 col-sm-10">
            {basePersonState.arrayData.length > 499 ?
              <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
              : <p>{basePersonState.arrayData.length === 1 ? `${basePersonState.arrayData.length} Search Result` : `${basePersonState.arrayData.length} Search Results`}</p>}
          </div>
          {onFiltersToggle ?
            <div className="col-xs-12 col-sm-2">
              <button className={global.ToggleButton} type="button" onClick={() => onFiltersToggle()}>{searchMemberState.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
            </div> : <Fragment />
          }
        </div>
      )
      : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>
    }
    <LargeGrid isLoading={isLoading} state={basePersonState} onEdit={onEdit} selectedPersonId={selectedPersonId} />
    <SmallGrid isLoading={isLoading} state={basePersonState} onEdit={onEdit} selectedPersonId={selectedPersonId} onCancelClicked={onCancelClicked} />
  </Fragment>
);

export default SearchMemberGrid;