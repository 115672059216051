import React, { createContext, useState } from 'react';

import OfficialSelectionForEvaluationData from './OfficialSelectionForEvaluationData';

export const OfficialSelectionForEvaluationStateContext = createContext();

const OfficialSelectionForEvaluationContextProvider = ({ children }) => {
  const stateHook = useState(OfficialSelectionForEvaluationData.INITIAL_STATE);

  return (
    <OfficialSelectionForEvaluationStateContext.Provider value={stateHook}>
      {children}
    </OfficialSelectionForEvaluationStateContext.Provider>
  );
};

export default OfficialSelectionForEvaluationContextProvider;