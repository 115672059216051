import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getMemberCareerStatsData = (personId, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/PersonCareerStats/person/${personIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putMemberCareerStatsData = (personId, memberCareerStatsArray, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/PersonCareerStats/person/${personIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'PUT', memberCareerStatsArray) : null;
  }
};


const MemberCareerStatsData = {
  getMemberCareerStatsData,
  putMemberCareerStatsData
};

export default MemberCareerStatsData;