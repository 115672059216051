import React from 'react';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ ineligibleReason, onEdit, onDelete }) => (
  <tr>
    <td>{ineligibleReason.ineligibleReason?.reasonDescription || ''}</td>
    <td>{ineligibleReason.effectiveDate ? formatDate(ineligibleReason.effectiveDate) : ''}</td>
    <td>{ineligibleReason.expirationDate ? formatDate(ineligibleReason.expirationDate) : ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => onEdit(ineligibleReason.personIneligibleReasonId)}>
        <EditIcon />
      </button>
      {onDelete &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDelete(ineligibleReason.personIneligibleReasonId, `${ineligibleReason.ineligibleReason?.reasonDescription || ''} (Start Date: ${formatDate(ineligibleReason.effectiveDate)}, End Date: ${formatDate(ineligibleReason.expirationDate)})`)}>
          <DeleteIcon />
        </button>}
    </td>
  </tr>
);

const MemberIneligibleToCompeteLargeGrid = ({ gridData, isLoading, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Ineligibility Reason</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="4">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((ineligibleReason, i) => <LargeGridRow key={i} ineligibleReason={ineligibleReason} onEdit={onEdit} onDelete={onDelete} />)
          : <tr><td colSpan="4">No Ineligible to Compete Reasons</td></tr>
      }
    </tbody>
  </table>
);

export default MemberIneligibleToCompeteLargeGrid;