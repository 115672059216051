import React, { Fragment } from 'react';

import PlusIcon from '../../../../common/components/icons/PlusIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ person, onAdd }) => (
  <tr>
    <td>{person.lastName}</td>
    <td>{person.firstName} {person.middleName}</td>
    <td>{formatDate(person.birthDate)}</td>
    <td>{person.rootRoleName} - {person.orgRoleName}</td>
    {person.parentOrgUnitCode === null
      ? <td>{person.orgUnitCode}</td>
      : <td>{person.parentOrgUnitCode} | {person.orgUnitCode}</td>
    }
    {onAdd !== undefined &&
      <td>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onAdd(e, person.personId)}>
          <PlusIcon />
        </button>
      </td>
    }
  </tr>
);

const SmallGridRow = ({ person, onAdd }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{person.lastName}, {person.firstName} {person.middleName}</span>&nbsp;
      {onAdd !== undefined &&
        <button className={global.IconButton} type="button" onClick={(e) => onAdd(e, person.personId)}>
          <PlusIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{person.lastName}, {person.firstName} {person.middleName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{formatDate(person.birthDate)}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Type - Role</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.rootRoleName} - {person.orgRoleName}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Organization</div>
        {person.parentOrgUnitCode === null
          ? <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.orgUnitCode}</div>
          : <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.parentOrgUnitCode} | {person.orgUnitCode}</div>
        }
      </div>
    </div>
  </div>
);

const LargeGrid = ({ data, isLoading, onAdd }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Last Name</th>
        <th>First &amp; Middle Name</th>
        <th>Birth Date</th>
        <th>Member Type - Member Role</th>
        <th>Organization</th>
        {onAdd !== undefined &&
          <th></th>
        }
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            {onAdd !== undefined ?
              <td colSpan="8">Loading...</td>
              :
              <td colSpan="7">Loading...</td>
            }
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((person, i) => <LargeGridRow key={i} person={person} onAdd={onAdd} />)
          : (
            <tr>
              {onAdd !== undefined ?
                <td colSpan="8">No Results</td>
                :
                <td colSpan="7">No Results</td>
              }
            </tr>
          )
      }
    </tbody >
  </table >
);

const SmallGrid = ({ data, isLoading, onAdd }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((person, i) => <SmallGridRow key={i} person={person} onAdd={onAdd} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div>
);

/**
 * @param {Array} data array on persons
    * @param {Boolean} isLoading boolean
    * @param {Function} onAdd function or undefined
    * @returns jsx element
    */
const ProfileCoachSearchGrid = ({ data, isLoading, onAdd }) => {
  return (
    <Fragment>
      {isLoading === false
        ? (
          <div className="row">
            <div className="col-xs-12">
              {data.length > 499 ?
                <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
                : <p>{data.length === 1 ? `${data.length} Search Result` : `${data.length} Search Results`}</p>}
            </div>
          </div>
        )
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment >
      }
      <LargeGrid data={data} isLoading={isLoading} onAdd={onAdd} />
      <SmallGrid data={data} isLoading={isLoading} onAdd={onAdd} />
    </Fragment>
  );
};

export default ProfileCoachSearchGrid;