import useCommitteeReporting from './UseCommitteeReporting';

import ReportList from '../../../common/components/embeddedReports/ReportList';

const CommitteeReporting = () => {
  const { reportParameters, routeName } = useCommitteeReporting();

  return (
    <ReportList listTitle="Committee Reports"
      routeName={routeName}
      reportParameters={reportParameters} />
  );
};

export default CommitteeReporting;