import React, { Fragment } from 'react';

import OtsOfficialMeetHistoryLargeWriteGrid from './OtsOfficialMeetHistoryLargeWriteGrid';
import OtsOfficialMeetHistorySmallWriteGrid from './OtsOfficialMeetHistorySmallWriteGrid';

import useExpandedRow from '../../../../../../common/components/grids/UseExpandedRow';

const OtsOfficialsMeetHistoryWriteGrid = ({ state }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <OtsOfficialMeetHistoryLargeWriteGrid
        state={state}
        expandedId={expandedId}
        onExpandClicked={onClick} />
      <OtsOfficialMeetHistorySmallWriteGrid
        state={state}
        expandedId={expandedId}
        onExpandClicked={onClick} />
    </Fragment>
  )
};

export default OtsOfficialsMeetHistoryWriteGrid;
