import React, { useState, useEffect, Fragment } from 'react';

import validate from './MemberEthnicitiesValidation';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import useEthnicityTypeData from '../../../../common/state/ethnicityType/UseEthnicityTypeData'
import useHispanicOrLatinoTypeData from '../../../../common/state/hispanicOrLatinoType/UseHispanicOrLatinoTypeData'
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const useMemberEthnicities = () => {
  const { ethnicityTypeState, getEthnicityTypes } = useEthnicityTypeData();
  const { hispanicOrLatinoTypeState, getHispanicOrLatinoTypes } = useHispanicOrLatinoTypeData();
  const { basePersonState, putBasePerson } = useBasePersonData();
  const [raceEthnicityState, setRaceEthnicityState] = useState(getInitialRaceEthnicityState);
  const [state, setState] = useState({
    showRaceEthnicity: false,
    selectedMoreThan4Error: false,
    radiobuttonlist: [],
    hispanicOrLatinoType: '',
    additionalRaces: ''
  });

  const {
    handleSubmit,
    updateFormState,
    formState,
    setFormState,
    errorState,
    setIsDirty
  } = useForm(getInitialRaceEthnicityState, submitFormCallback, validate);

  const HISPANIC_OR_LATINO_TYPE_IDS = {
    hispanicOrLatino: 1,
    nonHispanicOrLatino: 2,
    iPreferNotToSay: 3
  }

  const ETHNICITY_TYPE_IDS = {
    americanIndianAlaskanNative: 1,
    asian: 2,
    blackAfricanAmerican: 3,
    nativeHawaiianPacificIslander: 4,
    white: 5,
    someOtherRace: 6,
    iPreferNotToSay: 7
  }

  function getRadioButtonList() {
    const radiobuttonlist = hispanicOrLatinoTypeState.arrayData.map((hispanicOrLatinoType) => {
      return {
        label: hispanicOrLatinoType.typeName,
        value: hispanicOrLatinoType.hispanicOrLatinoTypeId,
        id: hispanicOrLatinoType.hispanicOrLatinoTypeId
      }
    })
    return radiobuttonlist;
  };

  function getCheckboxesBasedOnDisabled(isDisabled) {
    const checkboxesArray = ethnicityTypeState.arrayData.map((ethnicityType) => {
      if (ethnicityType.ethnicityTypeId === ETHNICITY_TYPE_IDS.iPreferNotToSay) {
        isDisabled = false;
      }
      return {
        label: ethnicityType.typeName,
        name: ethnicityType.typeName,
        id: ethnicityType.ethnicityTypeId,
        disabled: isDisabled
      };
    })
    return { checkboxes: checkboxesArray };
  };

  function getInitialRaceEthnicityState() {
    return { checkboxes: [] };
  };

  // This function is for taking the initial basePersonState to assist the useEffect in populating hispanicOrLatinoTypeId and ethnicityTypeIdArray
  const getInitialEthncities = () => {
    const ethnicityTypeArray = [];
    if (basePersonState.objData.usasPerson && basePersonState.objData.usasPerson.personEthnicity?.length > 0) {
      // eslint-disable-next-line array-callback-return
      basePersonState.objData.usasPerson.personEthnicity.map((ethnicity) => {
        ethnicityTypeArray.push(ethnicity.ethnicityTypeId)
      })
    }
    return ethnicityTypeArray;
  }

  const getInitialEthncityNames = () => {
    const ethnicityTypeArray = [];
    if (basePersonState.objData.usasPerson && basePersonState.objData.usasPerson.personEthnicity?.length > 0) {
      // eslint-disable-next-line array-callback-return
      basePersonState.objData.usasPerson.personEthnicity.map((ethnicity) => {
        ethnicityTypeArray.push(ethnicity.ethnicityType.typeName)
      })
    }
    return ethnicityTypeArray.join(', ');
  }

  const createUsasPersonIfUndefinedOrNull = {
    personId: basePersonState.objData.personId,
    maidenName: null,
    suffix: null,
    maidenNameUsedAsLastName: false,
    birthDateConfirmed: false,
    isUsCitizen: false,
    hispanicOrLatinoTypeId: null,
    hispanicOrLatinoType: undefined,
    personDisability: [],
    personEthnicity: [],
    personNarrative: []
  }

  const createPutBasePersonEthnicityArray = (formState) => {
    const personCopy = JSON.parse(JSON.stringify(basePersonState.objData));

    if (personCopy.usasPerson === undefined || personCopy.usasPerson === null) {
      personCopy.usasPerson = createUsasPersonIfUndefinedOrNull;
    }

    if (formState.hispanicOrLatinoTypeId === HISPANIC_OR_LATINO_TYPE_IDS.iPreferNotToSay) {
      personCopy.usasPerson.hispanicOrLatinoTypeId = formState.hispanicOrLatinoTypeId;
      personCopy.usasPerson.personEthnicity = []
    } else {
      personCopy.usasPerson.hispanicOrLatinoTypeId = formState.hispanicOrLatinoTypeId;
      personCopy.usasPerson.personEthnicity = [...formState.ethnicityTypeIdArray].map(ethnicity => {
        return {
          personId: personCopy.personId,
          ethnicityTypeId: ethnicity
        }
      });
    }
    return personCopy;
  }

  const removeByIndex = (array, index) => array.filter((_, i) => i !== index);

  const onCheckBoxChange = (e) => {
    const id = Number(e.target.id)
    let ethnicityTypeIdArray = [...formState.ethnicityTypeIdArray];
    let selectedMoreThan4Error = state.selectedMoreThan4Error;
    if (id === ETHNICITY_TYPE_IDS.iPreferNotToSay) {
      ethnicityTypeIdArray = [7]
    }
    if (formState.ethnicityTypeIdArray?.includes(id)) {
      // eslint-disable-next-line array-callback-return
      formState.ethnicityTypeIdArray.map((ethnicity, index) => {
        if (ethnicity === id) {
          ethnicityTypeIdArray = removeByIndex(ethnicityTypeIdArray, index);
        }
      })
      selectedMoreThan4Error = false
    } else {
      if (ethnicityTypeIdArray.length < 4) {
        ethnicityTypeIdArray.push(id);
      } else {
        selectedMoreThan4Error = true
      }
    }
    setFormState({
      ...formState,
      ethnicityTypeIdArray,
    })
    setIsDirty(true);
    setState({
      ...state,
      selectedMoreThan4Error
    })
  }

  function submitFormCallback() {
    putBasePerson(basePersonState.objData.personId, createPutBasePersonEthnicityArray(formState));
  };

  const onCancelClicked = () => {
    setFormState({
      ...formState,
      hispanicOrLatinoTypeId: basePersonState.objData.usasPerson?.hispanicOrLatinoTypeId,
      ethnicityTypeIdArray: getInitialEthncities(),
    });
  };

  const ErrorHandle = ({ error, message }) => (
    <Fragment>
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </Fragment>
  )

  useEffect(() => {
    if (state.selectedMoreThan4Error === true) {
      setTimeout(() => {
        setState({
          ...state,
          selectedMoreThan4Error: !state.selectedMoreThan4Error
        })
      }, 3500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedMoreThan4Error])

  useEffect(() => {
    if (ethnicityTypeState.isArrayLoaded === true
      && hispanicOrLatinoTypeState.isArrayLoaded === true
      && basePersonState.isObjLoaded === true
      && basePersonState.isObjLoading === false) {
      const radiobuttonlist = getRadioButtonList();
      const memberHispanicOrLatinoTypeId = basePersonState.objData.usasPerson ?
        basePersonState.objData.usasPerson.hispanicOrLatinoTypeId : Constants.DEFAULT_ID;
      let hispanicOrLatinoTypeId = Constants.DEFAULT_ID;
      let ethnicityTypeIdArray = [];
      let hispanicOrLatinoType = 'Not Specified';
      let additionalRaces = 'Not Specified';
      if (memberHispanicOrLatinoTypeId === HISPANIC_OR_LATINO_TYPE_IDS.hispanicOrLatino ||
        memberHispanicOrLatinoTypeId === HISPANIC_OR_LATINO_TYPE_IDS.nonHispanicOrLatino) {
        hispanicOrLatinoTypeId = memberHispanicOrLatinoTypeId;
        ethnicityTypeIdArray = getInitialEthncities();
        additionalRaces = getInitialEthncityNames();
        hispanicOrLatinoType = (memberHispanicOrLatinoTypeId === HISPANIC_OR_LATINO_TYPE_IDS.hispanicOrLatino) ? "Yes" : "No";
      } else if (memberHispanicOrLatinoTypeId === HISPANIC_OR_LATINO_TYPE_IDS.iPreferNotToSay) {
        hispanicOrLatinoTypeId = memberHispanicOrLatinoTypeId;
        ethnicityTypeIdArray = [];
        additionalRaces = 'I Prefer Not To Say';
        hispanicOrLatinoType = 'I Prefer Not To Say';
      }
      setFormState({
        ...formState,
        hispanicOrLatinoTypeId,
        ethnicityTypeIdArray
      });
      setState({
        ...state,
        radiobuttonlist,
        hispanicOrLatinoType,
        additionalRaces
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, hispanicOrLatinoTypeState.isArrayLoaded, ethnicityTypeState.isArrayLoaded]);

  useEffect(() => {
    if (hispanicOrLatinoTypeState.isArrayLoaded === false
      && hispanicOrLatinoTypeState.isArrayLoading === false) {
      getHispanicOrLatinoTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hispanicOrLatinoTypeState])

  useEffect(() => {
    if (ethnicityTypeState.isArrayLoaded === false
      && ethnicityTypeState.isArrayLoading === false) {
      getEthnicityTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethnicityTypeState])

  useEffect(() => {
    if (formState.ethnicityTypeIdArray?.length >= 0) {
      let checkboxes = null;
      if ([...formState.ethnicityTypeIdArray].includes(ETHNICITY_TYPE_IDS.iPreferNotToSay) === true) {
        checkboxes = getCheckboxesBasedOnDisabled(true);
      } else {
        checkboxes = getCheckboxesBasedOnDisabled(false);
      }
      setFormState({
        ...formState,
        checkboxes: checkboxes.checkboxes
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.ethnicityTypeIdArray])

  useEffect(() => {
    if (raceEthnicityState.hispanicOrLatinoTypeId === HISPANIC_OR_LATINO_TYPE_IDS.hispanicOrLatino ||
      raceEthnicityState.hispanicOrLatinoTypeId === HISPANIC_OR_LATINO_TYPE_IDS.nonHispanicOrLatino) {
      setState({
        ...state,
        showRaceEthnicity: true
      })
      setRaceEthnicityState({
        ...raceEthnicityState,
        InitialRaceEthnicityState: getInitialEthncities()
      })
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [raceEthnicityState.hispanicOrLatinoTypeId]);

  return {
    basePersonState,
    ethnicityTypeState,
    hispanicOrLatinoTypeState,
    state,
    formState,
    errorState,
    ErrorHandle,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onCancelClicked,
    onCheckBoxChange,
    HISPANIC_OR_LATINO_TYPE_IDS
  };
};

export default useMemberEthnicities;

