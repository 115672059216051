import { Fragment } from "react";
import Headings from "../../../../common/components/headings/Headings";

import useMemberRaceStats from './UseMemberRaceStats';
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import ViewReport from "../../../../common/components/embeddedReports/ViewReport";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

const MemberRaceStats = () => {
  const { viewState, setViewState, reportListState, onRunRaceStatsDashboard, INITIAL_VIEW_STATE } = useMemberRaceStats();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Race Stats</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 col-sm-6">
          <PrimaryButton type="button" onClick={() => onRunRaceStatsDashboard()}>View Dashboard</PrimaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={reportListState?.isArrayLoading} />
      {viewState.showReport === true &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <ViewReport
              reportInfoId={viewState.reportInfoId}
              reportParameters={viewState.reportParameters}
              onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />
          </div>
        </div>
      }
    </Fragment>);
}

export default MemberRaceStats;