export const localValidate = (formState) => {
  let errors = {};

  if (formState.memberId.length < 1) {
    errors.memberId = 'Please enter a Member ID';
  }

  return errors;
};

const MemberCardLookupValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default MemberCardLookupValidation;