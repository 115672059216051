import React from 'react';

import useOfficialPositionTypeDropdown from './UseOfficialPositionTypeDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const OfficialPositionTypeDropdown = ({ label, name, value, error, message, onChange, orgLevelId, hasEvaluationForm  }) => {
  const { officialPositionTypeState } = useOfficialPositionTypeDropdown(orgLevelId, hasEvaluationForm);

  return officialPositionTypeState.message
    ? <span className={global.LoadingMsg}>{officialPositionTypeState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={officialPositionTypeState.options}
        name={name}
        value={value}
        onChange={onChange}
        areValuesIntegers={true}
        isLoading={officialPositionTypeState.isArrayLoading} />
    );
};

export default OfficialPositionTypeDropdown;