import React, { createContext, useState } from 'react';

import OfficialEvaluationData from './OfficialEvaluationData';

export const OfficialEvaluationStateContext = createContext();

const OfficialEvaluationContextProvider = ({ children }) => {
  const stateHook = useState(OfficialEvaluationData.INITIAL_STATE);

  return (
    <OfficialEvaluationStateContext.Provider value={stateHook}>
      {children}
    </OfficialEvaluationStateContext.Provider>
  );
};

export default OfficialEvaluationContextProvider;