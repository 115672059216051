import { Fragment } from 'react';

import useOtsMeetOfficialsOfficialSelection from './UseOtsMeetOfficialsOfficialSelection';

import MeetOfficialsOfficialSelectionGrid from './MeetOfficialsOfficialSelectionGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Input from '../../../../../common/components/inputs/Input';
import SearchMemberPopup from '../../../../../common/components/searches/searchMember/SearchMemberPopup';
import LscDropdown from '../../../../../common/components/dropdowns/lscDropdown/LscDropdown';
import ApprovedClubCombobox from '../../../../../common/components/comboboxes/approvedClubCombobox/ApprovedClubCombobox';

import global from '../../../../../common/components/GlobalStyle.module.css';

import Constants from '../../../../../common/utils/Constants';

const OtsMeetOfficialsOfficialSelection = () => {
  const {
    gridState, officialsTrackingSystemOfficialSelectionState, onSelectAll, onUnselectAll, onSaveClicked, onBackClicked, onCheckboxChange, onFilterClicked,
    onClearFilterClicked, onFormValueChanged, onValueTextPairChanged, setFormData, formState, errorState, passedInMeetEndDate
  } = useOtsMeetOfficialsOfficialSelection();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Official Selection</Headings.H3>
        </div>
      </div>
      <form>
        <div className='row'>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <LscDropdown
              label="LSC"
              name="lscOrgUnitId"
              value={formState.lscOrgUnitId}
              error={errorState.lscOrgUnitId}
              message={errorState.lscOrgUnitId}
              onChange={(newValue, newValueLabel, e) => {
                onValueTextPairChanged(newValue, 'lscOrgUnitId', newValueLabel, 'lscName', e);
                onValueTextPairChanged('', 'clubOrgUnitId', '', 'clubOrgUnitName', e);
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ApprovedClubCombobox
              label='Club Name'
              name="clubOrgUnitName"
              valueToMatch={formState.clubOrgUnitName}
              error={errorState.clubOrgUnitId}
              message={errorState.clubOrgUnitId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'clubOrgUnitId', newValueLabel, 'clubOrgUnitName', e); }}
              lscId={formState.lscOrgUnitId} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="First or Preferred Name"
              name="firstOrPreferredName"
              value={formState.firstOrPreferredName}
              error={errorState.firstOrPreferredName}
              message={errorState.firstOrPreferredName}
              onChange={(value) => { onFormValueChanged('firstOrPreferredName', value) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Last Name"
              name="lastName"
              value={formState.lastName}
              error={errorState.lastName}
              message={errorState.lastName}
              onChange={(value) => { onFormValueChanged('lastName', value) }} />
          </div>
          <div className='col-xs-12 col-sm-6 col-md-4'>
            <SearchMemberPopup
              label={'Member ID'}
              memberIdName={'memberId'}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged}
            />
          </div>
          <div className='col-xs-6 col-sm-2 col-md-1'>
            <button className={global.PlainButton} onClick={(e) => onFilterClicked(e)}>Filter</button>
          </div>
          <div className='col-xs-6 col-sm-4 col-md-2'>
            <button className={global.PlainButton} onClick={(e) => onClearFilterClicked(e)}>Clear Filter</button>
          </div>
          {errorState.filter &&
            <div className="col-xs-12">
              <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.filter}</p>
            </div>}
        </div>
      </form>
      {gridState.gridData.length > 5 && officialsTrackingSystemOfficialSelectionState.isArrayLoading === false &&
        < div className='visible-md visible-lg' >
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
            </div>
          </div>
          {errorState.general &&
            <div className="row">
              <div className="col-xs-12">
                <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
            </div>
          </div>
        </div >}
      {gridState.gridData.length > 0 && officialsTrackingSystemOfficialSelectionState.isArrayLoading === false &&
        < div className='hidden-md hidden-lg' >
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
            </div>
          </div>
          {errorState.general &&
            <div className="row">
              <div className="col-xs-12">
                <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
            </div>
          </div>
        </div >}
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12">
          <MeetOfficialsOfficialSelectionGrid
            gridData={gridState.gridData}
            isLoading={officialsTrackingSystemOfficialSelectionState.isArrayLoading}
            onCheckboxChange={onCheckboxChange}
            passedInMeetEndDate={passedInMeetEndDate}
          />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
        </div>
      </div>
      {errorState.general &&
        <div className="row">
          <div className="col-xs-12">
            <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errorState.general}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={officialsTrackingSystemOfficialSelectionState.isArrayLoading} />
      <PopUpModal
        widthPct={30}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={officialsTrackingSystemOfficialSelectionState.isSaving} />
    </Fragment >
  );
};

export default OtsMeetOfficialsOfficialSelection;