import { Fragment } from 'react';

import useHqOfferingInstanceAdd from './UseHqOfferingInstanceAdd';

import HqOfferingManagementContextLoadingModal from '../../HqOfferingManagementContextLoadingModal';

import OfferingInstanceAddForm from '../../../components/forms/offeringInstance/OfferingInstanceAddForm';

import Headings from '../../../../../../common/components/headings/Headings';

import { SAVING_MSG } from '../../../../../../common/utils/Constants';

const HqOfferingInstanceAdd = () => {
  const {
    isSaving,
    state,
    onSubmitOfferingInstanceForm,
    onBackClicked
  } = useHqOfferingInstanceAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Offering Instance</Headings.H3>
        </div>
      </div>
      {state.reportPeriodObj?.reportPeriodId > 0 &&
        <OfferingInstanceAddForm
          reportPeriodObj={state.reportPeriodObj}
          submitButtonText='Add Offering Instance'
          onSubmitFormCallback={onSubmitOfferingInstanceForm}
          onBackClicked={onBackClicked} />
      }
      <HqOfferingManagementContextLoadingModal
        isLoading={isSaving}
        loadingMessage={SAVING_MSG}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default HqOfferingInstanceAdd;