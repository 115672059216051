import Constants from "./Constants";

const BLANK_VALUES = [
  '',
  Constants.DEFAULT_ID,
  Constants.BLANK_DATE_STRING,
  'Year',
  undefined,
  null
];

const createGroupMemberObj = (personId, orgGroupId, personGroupFields, orgGroupProperties,
  personOrgGroupId = undefined, personOrgGroupQualification = undefined, status = undefined) => {
  const groupMember = {
    personId,
    orgGroupId,
    personOrgGroupId: personOrgGroupId > 0 ? personOrgGroupId : undefined,
    personGroupTypeField: [],
    personOrgGroupQualification: []
  };

  for (const groupField of personGroupFields) {
    const groupTypeField = orgGroupProperties.find(x => x.fieldName === groupField.fieldName);

    if (groupTypeField) {
      const isBlankValue = BLANK_VALUES.findIndex(x => groupField.fieldValue === x) >= 0;

      if (isBlankValue !== true) {
        groupMember.personGroupTypeField.push({
          groupTypeFieldId: groupTypeField.groupTypeFieldId,
          fieldValue: groupField.fieldValue,
          personOrgGroupId: personOrgGroupId > 0 ? personOrgGroupId : undefined
        });
      }
    }
  }

  if (personOrgGroupId > 0 && personOrgGroupQualification && status) {
    groupMember.personOrgGroupQualification.push({
      ...personOrgGroupQualification,
      personOrgGroupId,
      status
    });
  }

  return groupMember;
};

const getNewGroupStatus = (currentStatus, requiresApproval) => {
  if (currentStatus) {
    if (requiresApproval === true) {
      return 'Pending'; //TODO make constant?
    } else {
      return 'Approved'; //TODO make constant?
    }
  } else {
    return undefined;
  }
};

const getGroupPropertyOptions = (propertyFieldName, orgGroupProperties) => {
  const groupProperty = orgGroupProperties.find(x => x.fieldName === propertyFieldName);

  if (groupProperty && Array.isArray(groupProperty.groupTypeFieldLookup)) {
    return groupProperty.groupTypeFieldLookup.map(x => { return { id: x.typeValue, name: x.typeValue } });
  } else {
    return [];
  }
};

export { getGroupPropertyOptions, getNewGroupStatus, createGroupMemberObj };