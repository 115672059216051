import { Fragment } from 'react';

import useSearchOtsMeet from './UseSearchOtsMeet';

import SearchOtsMeetGrid from './SearchOtsMeetGrid';

import MeetClassificationTreeView from '../../../common/components/tree/meetClassificationTree/MeetClassificationTreeView';
import Headings from '../../../common/components/headings/Headings';
import Input from '../../../common/components/inputs/Input';
import ZoneDropdown from '../../../common/components/dropdowns/zoneDropdown/ZoneDropdown';
import LscDropdown from '../../../common/components/dropdowns/lscDropdown/LscDropdown';
import ApprovedClubCombobox from '../../../common/components/comboboxes/approvedClubCombobox/ApprovedClubCombobox';
import Dropdown from '../../../common/components/dropdowns/Dropdown';
import DatePicker from '../../../common/components/datepickers/DatePicker';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import Constants from '../../../common/utils/Constants';

import global from '../../../common/components/GlobalStyle.module.css';

const Form = ({ searchButtonText, handleSubmit, formState, errorState, onFormValueChanged, onValueTextPairChanged, onZoneOrgUnitChanged,
  onLscOrgUnitChanged, onOrgUnitChanged, USAS_ORG_UNIT_ID, clearForm, sortByOptions, onCancelClicked, onAdd, selectOrgUnitState, zoneState }) => {
  const options = [{ id: Constants.DEFAULT_ID, name: '--' }];
  zoneState?.dataAsOptionsWithNames?.find(x => x.id === formState.zoneOrgUnitId)?.lscOrgUnit?.forEach(x => { options.push({ id: x.lscOrgUnitId, name: x.lscOrgUnitName }) })
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className='row'>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Meet Name"
            name="meetName"
            value={formState.meetName}
            error={errorState.meetName}
            message={errorState.meetName}
            onChange={(value) => { onFormValueChanged('meetName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Sanction Number"
            name="sanctionNumber"
            value={formState.sanctionNumber}
            error={errorState.sanctionNumber}
            message={errorState.sanctionNumber}
            onChange={(value) => { onFormValueChanged('sanctionNumber', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="OQM Number"
            name="oqmNumber"
            value={formState.oqmNumber}
            error={errorState.oqmNumber}
            message={errorState.oqmNumber}
            onChange={(value) => { onFormValueChanged('oqmNumber', value) }} />
        </div>
        {!selectOrgUnitState &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ZoneDropdown
              label="Host Zone"
              name="zoneOrgUnitId"
              value={formState.zoneOrgUnitId}
              error={errorState.zoneOrgUnitId}
              message={errorState.zoneOrgUnitId}
              onChange={(newValue, newValueLabel) => { onZoneOrgUnitChanged(newValue, newValueLabel) }} />
          </div>}
        {!selectOrgUnitState && formState.zoneOrgUnitId === Constants.DEFAULT_ID &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <LscDropdown
              label="Host LSC"
              name="lscOrgUnitId"
              value={formState.lscOrgUnitId}
              error={errorState.lscOrgUnitId}
              message={errorState.lscOrgUnitId}
              onChange={(newValue, newValueLabel, e) => { onLscOrgUnitChanged(newValue, newValueLabel, e) }} />
          </div>}
        {!selectOrgUnitState && formState.zoneOrgUnitId !== Constants.DEFAULT_ID &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              options={options}
              label="Host LSC"
              name="lscOrgUnitId"
              value={formState.lscOrgUnitId}
              error={errorState.lscOrgUnitId}
              message={errorState.lscOrgUnitId}
              onChange={(newValue, newValueLabel, e) => { onLscOrgUnitChanged(newValue, newValueLabel, e) }} />
          </div>}
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ApprovedClubCombobox
            label={'Host ' + formState.orgUnitLabel}
            name="orgUnitName"
            valueToMatch={formState.orgUnitName}
            error={errorState.orgUnitId}
            message={errorState.orgUnitId}
            onChange={(newValue, newValueLabel) => { onOrgUnitChanged(newValue, newValueLabel); }}
            lscId={selectOrgUnitState ? selectOrgUnitState.orgUnitId : formState.lscOrgUnitId} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <MeetClassificationTreeView
            label="Meet Classification"
            name="meetClassification"
            value={formState.meetClassification}
            error={errorState.meetClassification}
            message={errorState.meetClassification}
            selectableLeavesOnly={true}
            singleSelect={true}
            onChange={(newValue) => { onFormValueChanged('meetClassification', newValue) }}
            orgUnitId={selectOrgUnitState ? selectOrgUnitState.orgUnitId : formState.orgUnitId !== '' ? formState.orgUnitId : (formState.lscOrgUnitId !== Constants.DEFAULT_ID ? formState.lscOrgUnitId : USAS_ORG_UNIT_ID)}
            filterId={-1} 
            filterMode={"exclude_filter_id"}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker label="Meet Start Date Range"
            name="startDate"
            value={formState.startDate}
            error={errorState.startDate}
            message={errorState.startDate}
            onChange={(value) => { onFormValueChanged('startDate', value); }}
            countOfYears={150}
            startYearOffset={-149} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker label="Meet End Date Range"
            name="endDate"
            value={formState.endDate}
            error={errorState.endDate}
            message={errorState.endDate}
            onChange={(value) => { onFormValueChanged('endDate', value); }}
            countOfYears={150}
            startYearOffset={-149} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Sort Results By"
            name="sortById"
            options={sortByOptions}
            value={formState.sortById}
            error={errorState.sortById}
            message={errorState.sortById}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
            }} />
        </div>
      </div>
      {(errorState.filter || errorState.errorOnSubmitAction) &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{searchButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={() => clearForm()}>Clear Form</SecondaryButton>&nbsp;
          {onCancelClicked !== undefined ? <Fragment><SecondaryButton type="button" onClick={() => onCancelClicked()}>Back</SecondaryButton>&nbsp;</Fragment> : <Fragment />}
          {onAdd !== undefined ? <Fragment><ActionIntraPageButton type="button" onClick={() => onAdd()}>Add Meet</ActionIntraPageButton>&nbsp;</Fragment> : <Fragment />}
        </div>
      </div>
    </form >
  )
}

const SearchOtsMeet = ({ pageTitle, searchButtonText, onEdit, onPost, selectOrgUnitState, searchOtsMeetState, onAdd, onCancelClicked }) => {
  const {
    formState,
    errorState,
    otsMeetState,
    otsMeetHeaderState,
    zoneState,
    clearForm,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onZoneOrgUnitChanged,
    onLscOrgUnitChanged,
    onOrgUnitChanged,
    sortByOptions,
    onFiltersToggle,
    USAS_ORG_UNIT_ID,
    contextSecurityState
  } = useSearchOtsMeet(selectOrgUnitState);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      {searchOtsMeetState !== undefined ?
        //toggles filters and results
        <Fragment>
          <div className={searchOtsMeetState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
            <Form
              searchButtonText={searchButtonText}
              formState={formState}
              errorState={errorState}
              clearForm={clearForm}
              handleSubmit={handleSubmit}
              onFormValueChanged={onFormValueChanged}
              onValueTextPairChanged={onValueTextPairChanged}
              onZoneOrgUnitChanged={onZoneOrgUnitChanged}
              onLscOrgUnitChanged={onLscOrgUnitChanged}
              onOrgUnitChanged={onOrgUnitChanged}
              sortByOptions={sortByOptions}
              onAdd={onAdd}
              USAS_ORG_UNIT_ID={USAS_ORG_UNIT_ID}
              selectOrgUnitState={selectOrgUnitState}
              zoneState={zoneState}
            />
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchOtsMeetGrid
                searchOtsMeetState={searchOtsMeetState}
                otsMeetState={otsMeetState}
                onPost={onPost}
                onEdit={onEdit}
                onFiltersToggle={onFiltersToggle}
                onCancelClicked={onCancelClicked}
              />
            </div>
          </div>
          {onCancelClicked && searchOtsMeetState.showFilters === false ?
            <Fragment>
              <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-top-margin">
                  <SecondaryButton type="button" onClick={() => onCancelClicked()}>Cancel</SecondaryButton>
                </div>
              </div>
            </Fragment> : <Fragment />}
        </Fragment>
        :
        //shows filters and results at the same time
        <Fragment>
          <Form
            searchButtonText={searchButtonText}
            formState={formState}
            errorState={errorState}
            clearForm={clearForm}
            handleSubmit={handleSubmit}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged}
            onZoneOrgUnitChanged={onZoneOrgUnitChanged}
            onLscOrgUnitChanged={onLscOrgUnitChanged}
            onOrgUnitChanged={onOrgUnitChanged}
            sortByOptions={sortByOptions}
            onAdd={onAdd}
            USAS_ORG_UNIT_ID={USAS_ORG_UNIT_ID}
            selectOrgUnitState={selectOrgUnitState}
            zoneState={zoneState}
          />
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <SearchOtsMeetGrid
                otsMeetState={otsMeetState}
                onPost={onPost}
                onEdit={onEdit}
              />
            </div>
          </div>
        </Fragment>
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={otsMeetState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={otsMeetState.isArrayLoading || otsMeetState.isObjLoading ||
          otsMeetHeaderState.isObjLoading || contextSecurityState.isObjLoading} />
    </Fragment >
  );
};

export default SearchOtsMeet;