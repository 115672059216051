import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import LSCReportingNavLinks from '../LSCReportingNavLinks';

import validate from './LSCReportingHQSelectionValidation';

import UseForm from '../../../../common/utils/UseForm';

import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import Constants from '../../../../common/utils/Constants';

const useLSCReportingHQSelection = () => {
    const navigate = useNavigate();
    const { selectOrgUnitState, setSelectOrgUnitState } = useSelectOrgUnitData();
    const { handleSubmit, formState, errorState, onValueTextPairChanged
    } = UseForm(getInitialFormState, submitFormCallback, validate);

    function submitFormCallback() {
        setSelectOrgUnitState({
            ...selectOrgUnitState,
            orgUnitId: formState.orgUnitId,
            orgUnitName: formState.orgUnitName,
            showBackToSelection: true
        });

        navigate(LSCReportingNavLinks.LSC_REPORTING_HQ_REPORTS);
    }

    function getInitialFormState() {
        return {
            orgUnitId: Constants.DEFAULT_ID,
            orgUnitName: '--'
        };
    }

    return {
        handleSubmit,
        formState,
        errorState,
        onValueTextPairChanged
    };
};

export default useLSCReportingHQSelection;