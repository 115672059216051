import React, { Fragment } from 'react';

import useOtsMeetOfficials from './UseOtsMeetOfficials';

import MeetOfficialsGrid from './MeetOfficialsGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsMeetOfficials = () => {
  const {
    meetOfficialSessionState,
    isLoading,
    isSaving,
    Constants,
    onAddOfficial,
    onEditOfficial,
    deleteModalState,
    onDeleteOfficialClicked,
    onDeleteOfficial,
    onDeleteModalCanceled,
    passedInMeetEndDate
  } = useOtsMeetOfficials();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Officials</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className='col-xs-12'>
          <ActionIntraPageButton onClick={onAddOfficial}>Add Official</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12'>
          <MeetOfficialsGrid
            gridData={meetOfficialSessionState.arrayData}
            isLoading={meetOfficialSessionState.isArrayLoading}
            onEditClicked={onEditOfficial}
            onDeleteClicked={onDeleteOfficialClicked}
            passedInMeetEndDate={passedInMeetEndDate}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={deleteModalState.modalTitle}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Official:</b> {deleteModalState.officialName}</p>
            <p><b>Member ID:</b> {deleteModalState.memberId}</p>
            <p><b>LSC / Club:</b> {deleteModalState.organization}</p>
            <p><b>Reg. Expires:</b> {deleteModalState.registrationExpirationDate}</p>
            <p><b>APT Expires:</b> {deleteModalState.aptExpirationDate}</p>
            <p><b>BGC Expires:</b> {deleteModalState.bgcExpirationDate}</p>
            <p><b>CPT Expires:</b> {deleteModalState.cptExpirationDate}</p>
            <p><b>Sessions:</b> {deleteModalState.sessionsNumber}</p>
            <p><b>Roles:</b> {deleteModalState.officialRoleTypeName}</p>
            <p><b>Has an Evaluation?</b> {deleteModalState.hasEvaluationForm === true ? 'Yes' : 'No'}</p>
          </div>
          {deleteModalState.hasEvaluationForm === true &&
            <div className="col-xs-12">
               <p className={global.ErrorMessage}>Unable to Delete Meet Official - Officials with an evaluation cannot be deleted.</p>
               <p className={global.ErrorMessage}>To Delete this Meet Official, their evaluation(s) must be deleted first.</p>
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            {deleteModalState.hasEvaluationForm === false &&
              <Fragment>
                <PrimaryButton type="button" onClick={onDeleteOfficial}>Delete</PrimaryButton>&nbsp;
              </Fragment>}
            <SecondaryButton type="button" onClick={onDeleteModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  )
}

export default OtsMeetOfficials;