import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OtsOfficialsSearchValidation';

import useSearchOtsOfficialData from '../../../../state/searchOfficialsTrackingSystemOfficial/UseSearchOtsOfficialData';
import useOtsOfficialLscData from '../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';
import useOtsOfficialMeetHistoryData from '../../../../state/officialsTrackingSystemOfficialMeetHistory/UseOtsOfficialMeetHistoryData';
import useOfficialPositionTypeData from '../../../../state/officialPositionType/UseOfficialPositionTypeData';
import useOtsOfficialNationalCertificationData from '../../../../state/officialsTrackingSystemOfficialNationalCertification/UseOtsOfficialNationalCertificationData';
import useCertificationLevelData from '../../../../state/certificationLevel/UseCertificationLevelData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../../../common/state/security/UseSecurityData';
import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';
import usePersonGeneralData from '../../../../../common/state/personGeneral/UsePersonGeneralData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const TAXONOMIES = ['OTSOfficialDetail'];
const SCOPE = 'Official';

const INITIAL_STATE = {
  showFilters: true,
  showResults: false,
  isItemSelected: false,
  official: {},
  isNavigating: false
};

const isCurrentOptions = [
  { id: Constants.DEFAULT_ID, name: "--", value: '' },
  { id: 1, name: "Current", value: true },
  { id: 2, name: "Expired", value: false }];

const sortByOptions = [
  { id: 1, name: "LSC", value: 'lscName' },
  { id: 2, name: "Club Name", value: 'clubName' },
  { id: 3, name: "First Name", value: "firstName" },
  { id: 4, name: "Last Name", value: "lastName" }];

const INITIAL_FORM_STATE = {
  lscName: '',
  lscOrgUnitId: Constants?.DEFAULT_ID,
  clubOrgUnitId: Constants?.DEFAULT_ID,
  clubOrgUnitName: '',
  memberId: '',
  email: '',
  firstName: '',
  lastName: '',
  officialPositionTypeId: Constants?.DEFAULT_ID,
  officialPositionTypeName: '',
  certificationLevelId: Constants?.DEFAULT_ID,
  levelName: '',
  sortById: sortByOptions[0].id,
  sortByName: sortByOptions[0].name,
  isCurrentId: isCurrentOptions[0].id,
  isCurrentName: isCurrentOptions[0].name,
};

const useOtsOfficialsSearch = () => {
  const navigate = useNavigate();
  const { navRoutes, isReadyToRoute } = useNavRoutes();
  const { contextSecurityState, getContextSecurity, resetContextSecurityState } = useSecurityData();
  const { personGeneralState, getPersonGeneral } = usePersonGeneralData();
  const { basePersonState, setBasePersonState } = useBasePersonData();
  const { formState, errorState, onFormValueChanged, onValueTextPairChanged, handleSubmit, setFormState, setErrors, setIsDirty, isDirty } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const { searchOtsOfficialState, searchOtsOfficialFilterState, searchForOfficial } = useSearchOtsOfficialData();
  const { otsOfficialHeaderState, setOtsOfficialHeaderState, getOtsOfficialLscCertificationsByPersonId, clearOtsOfficialState } = useOtsOfficialLscData();
  const { officialPositionTypeState } = useOfficialPositionTypeData();
  const { certificationLevelState } = useCertificationLevelData();
  const { clearOtsOfficialMeetHistoryState } = useOtsOfficialMeetHistoryData();
  const { clearOtsOfficialNationalCertificationState } = useOtsOfficialNationalCertificationData();
  const [state, setState] = useState(INITIAL_STATE);
  const [tryPreviousSearch, setTryPreviousSearch] = useState(true);

  function submitFormCallback(formState) {
    let sortBy = undefined;
    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    if (searchOtsOfficialState?.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchForOfficial(filterObject, sortBy);
    }
  };

  function createSearchFilterObj(formState) {
    return {
      memberId: formState?.memberId.trim() || undefined,
      email: formState?.email.trim() || undefined,
      firstName: formState?.firstName.trim() || undefined,
      lastName: formState?.lastName.trim() || undefined,
      lscOrgUnitId: formState.lscOrgUnitId !== Constants?.DEFAULT_ID ? formState.lscOrgUnitId : undefined,
      clubOrgUnitId: formState.clubOrgUnitId !== Constants?.DEFAULT_ID && formState.clubOrgUnitId !== '' ? formState.clubOrgUnitId : undefined,
      clubOrgUnitName: formState.clubOrgUnitName.trim() || undefined,
      officialPositionTypeId: formState.officialPositionTypeId !== Constants?.DEFAULT_ID ? formState.officialPositionTypeId : undefined,
      certificationLevelId: formState.certificationLevelId !== Constants?.DEFAULT_ID ? formState.certificationLevelId : undefined,
      isCurrent: formState.isCurrentId > 0 ? isCurrentOptions.find(x => x.id === formState.isCurrentId).value : undefined
    };
  };

  const clearForm = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setFormState({ ...INITIAL_FORM_STATE });
    setErrors({});
    setTryPreviousSearch(false);
  };

  const onFiltersToggle = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, showFilters: state.showFilters === false });
  };

  const onSelectMember = (e, person) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    clearOtsOfficialMeetHistoryState();
    clearOtsOfficialState();
    clearOtsOfficialNationalCertificationState();
    const personId = person?.personId;
    getContextSecurity(personId, TAXONOMIES, SCOPE);
    getPersonGeneral(personId);
    setState({ ...state, isItemSelected: true, official: person });
  };

  const setNavigatingToTrue = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setBasePersonState({
      ...basePersonState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: '',
    });
    setState({ ...state, isNavigating: true });
  };

  useEffect(() => {
    //Clear out any prior context security state on page load
    resetContextSecurityState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state?.isItemSelected === true && Object?.keys(state?.official)?.length > 0) {
      if (personGeneralState?.isObjLoaded === true && contextSecurityState?.isObjLoaded === true
        && personGeneralState?.objData?.personId === contextSecurityState?.contextId
        && contextSecurityState?.scope === SCOPE) {
        if (personGeneralState?.objData?.personId && isReadyToRoute === true) {
          setOtsOfficialHeaderState({ ...otsOfficialHeaderState, objData: state?.official, isLoggedInUser: false, orgUnitId: state?.official?.lscOrgUnitId });
          getOtsOfficialLscCertificationsByPersonId(personGeneralState?.objData?.personId);
          setNavigatingToTrue();
          navigate('/ots/official/lsccertifications');
        } else {
          setState(INITIAL_STATE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, personGeneralState, contextSecurityState, navRoutes, isReadyToRoute]);

  useEffect(() => {
    if (searchOtsOfficialFilterState.filterObject && Object.keys(searchOtsOfficialFilterState?.filterObject)?.length > 0
      && tryPreviousSearch === true) {
      const oldSearchValues = JSON.parse(JSON.stringify(searchOtsOfficialFilterState.filterObject))

      let isCurrentIndex = undefined;
      for (let i = 0; i < isCurrentOptions.length; i++) {
        if (searchOtsOfficialFilterState.filterObject?.isCurrent === isCurrentOptions[i].value) {
          isCurrentIndex = i;
          break;
        }
      }

      let sortByIndex = undefined;
      for (let i = 0; i < sortByOptions.length; i++) {
        if (searchOtsOfficialFilterState.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }

      if (((formState.officialPositionTypeId === searchOtsOfficialFilterState.filterObject?.officialPositionTypeId && officialPositionTypeState.isArrayLoaded === true) ||
        searchOtsOfficialFilterState.filterObject?.officialPositionTypeId === undefined) &&
        ((formState.certificationLevelId === searchOtsOfficialFilterState.filterObject?.certificationLevelId && certificationLevelState.isArrayLoaded === true) ||
          searchOtsOfficialFilterState.filterObject?.certificationLevelId === undefined)
      ) {
        setIsDirty(true);
        setTryPreviousSearch(false);
        setFormState({
          ...formState,
          ...oldSearchValues,
          isCurrentId: isCurrentIndex !== undefined ? isCurrentOptions[isCurrentIndex].id : isCurrentOptions[0].id,
          isCurrentName: isCurrentIndex !== undefined ? isCurrentOptions[isCurrentIndex].name : isCurrentOptions[0].name,
          sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
          sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
        });

        if (searchOtsOfficialState?.isArrayLoading === false) {
          searchForOfficial(searchOtsOfficialFilterState.filterObject, searchOtsOfficialFilterState.sortBy);
        }
      } else {
        setFormState({
          ...formState,
          officialPositionTypeId: oldSearchValues.officialPositionTypeId,
          certificationLevelId: oldSearchValues.certificationLevelId
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officialPositionTypeState.isArrayLoaded, certificationLevelState.isArrayLoaded, formState.officialPositionTypeId, formState.certificationLevelId]);

  useEffect(() => {
    if (searchOtsOfficialState.isArrayLoaded === true) {
      setState({ ...state, showResults: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOtsOfficialState]);

  useEffect(() => {
    if (searchOtsOfficialState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOtsOfficialState.showFilters, isDirty]);

  return {
    searchOtsOfficialState,
    searchOtsOfficialFilterState,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    sortByOptions,
    clearForm,
    showFilters: state?.showFilters,
    onFiltersToggle,
    showResults: state?.showResults,
    onSelectMember,
    isLoading: state?.isNavigating || personGeneralState?.isArrayLoading || contextSecurityState.isObjLoading,
    isCurrentOptions
  }
};

export default useOtsOfficialsSearch;