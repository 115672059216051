import { useEffect } from 'react';
import useZoneData from '../../../state/zone/UseZoneData';

const useZoneDropdown = () => {
  const { zoneState, getZones } = useZoneData();

  useEffect(() => {
    if (zoneState.isLoading !== true && zoneState.isLoaded !== true) {
      getZones();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    zoneState
  };
};

export default useZoneDropdown;