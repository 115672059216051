import { formatDate } from '../../../../../common/utils/DateFunctions';

import LookupIcon from '../../../../../common/components/icons/LookupIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ position, onStatusClicked }) => {
  return (
    <>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{position.positionTypeName} ({position.positionAbbreviation})</span>&nbsp;
          <button
            className={global.IconButton}
            type="button"
            onClick={() => onStatusClicked(position)}>
            <LookupIcon toolTipText='Review (Re)certification' />
          </button>
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
              Position
            </div>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
              {position.positionTypeName || <span>&nbsp;</span>} ({position.positionAbbreviation || <span>&nbsp;</span>})
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Level
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {position.levelName || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Effective Date
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {position.status === 'Approved' && formatDate(position.effectiveDate) ?
                formatDate(position.effectiveDate) : <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Expiration Date
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {position.status === 'Approved' && position.expirationDate !== '3000-01-01T00:00:00' && formatDate(position.expirationDate) ?
                formatDate(position.expirationDate) :
                <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Status
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {position.status || <span>&nbsp;</span>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const GridSmall = ({ gridData, onStatusClicked }) => {
  return (
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {Array.isArray(gridData) && gridData.length > 0
        && gridData.map((position, i) => <SmallGridRow key={i} position={position} onStatusClicked={onStatusClicked} />)}
      {(!Array.isArray(gridData) || !gridData?.length) && (<span>No data for grid</span>)}
    </div>
  );
};

export default GridSmall;