
import { Fragment } from 'react';

import useOrgUnitSelection from './UseOrgUnitSelection';

import SelectOrgUnit from '../../../../../common/components/selectOrgUnit/SelectOrgUnit';
import LoadingModal from '../../../../../common/components/dialogs/LoadingModal';

const OrgUnitSelection = () => {
  const { NAV_LINK, LSC_OFFERING_MGMT_ROLE_GROUP_ID } = useOrgUnitSelection();

  return (
    <Fragment>
      {NAV_LINK && LSC_OFFERING_MGMT_ROLE_GROUP_ID
        ? (
          <SelectOrgUnit
            pageTitle={'LSC Offering Management'}
            navLink={NAV_LINK}
            roleGroupId={LSC_OFFERING_MGMT_ROLE_GROUP_ID} />
        ) : <LoadingModal />
      }

    </Fragment>
  );
};

export default OrgUnitSelection;