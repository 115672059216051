import { useContext } from 'react';
import { EthnicityTypeStateContext } from './EthnicityTypeContextProvider';

import EthnicityTypeData from './EthnicityTypeData';

const useEthnicityTypeData = () => {
  const [ethnicityTypeState, setEthnicityTypeState] = useContext(EthnicityTypeStateContext);

  const getEthnicityTypes = () => {
    EthnicityTypeData.getEthnicityTypes(ethnicityTypeState, setEthnicityTypeState);
  };

  return {
    ethnicityTypeState,
    getEthnicityTypes,
    setEthnicityTypeState
  };
};

export default useEthnicityTypeData;