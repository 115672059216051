import React, { Fragment } from 'react';

import useProfileCoach from './UseProfileCoach';
import ProfileCoachGrid from './ProfileCoachGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import Textarea from '../../../../common/components/inputs/Textarea';

import Constants from '../../../../common/utils/Constants';

import style from '../Member.module.css';

const ProfileCoach = () => {
  const {
    title,
    DEFAULT_ID,
    memberCoachesState,
    modalState,
    onAddEditCoach,
    onAddEditCoachType,
    onDeleteCoach,
    onDeleteCoachType,
    onOpenModalClicked,
    onOpenModalCoachTypeClicked,
    onModalCanceled,
    formState,
    onFormValueChanged,
    resetForm,
    onSaveCoachComment,
    commentState
  } = useProfileCoach();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{title}</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={(e) => onAddEditCoach(e, DEFAULT_ID)}>Add Coach</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <ProfileCoachGrid
            gridState={memberCoachesState}
            onDelete={onOpenModalClicked}
            onDeleteCoachType={onOpenModalCoachTypeClicked}
            onAddEditCoach={onAddEditCoach}
            onAddEditCoachType={onAddEditCoachType} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Textarea
            label="Comments"
            className={style.CommentBoxMinHeight}
            name="coachComments"
            value={formState.coachComments}
            onChange={(value) => { onFormValueChanged('coachComments', value); }}
          />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveCoachComment}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p>{modalState.itemName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            {modalState.itemId2 === Constants.DEFAULT_ID ?
              <PrimaryButton type="button" onClick={onDeleteCoach}>Yes, Delete</PrimaryButton>
              :
              <PrimaryButton type="button" onClick={onDeleteCoachType}>Yes, Delete Coach Type</PrimaryButton>
            }&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={memberCoachesState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={commentState.isSaving} />
    </Fragment >
  );
};

export default ProfileCoach;