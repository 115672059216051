import { useContext } from 'react';

import { PersonProfileStateContext } from './PersonProfileContextProvider';
import PersonProfileData from './PersonProfileData';

const usePersonProfileData = () => {
  const [personProfileState, setPersonProfileState] = useContext(PersonProfileStateContext);

  const getPersonProfile = (personId) => PersonProfileData.getPersonProfileData(personId, personProfileState, setPersonProfileState);
  const putPersonProfile = (personId, personObj) => PersonProfileData.putPersonProfileData(personId, personObj, personProfileState, setPersonProfileState);
  const postPersonProfile = (personId, personObj) => PersonProfileData.postPersonProfile(personId, personObj, personProfileState, setPersonProfileState);

  const clearObjData = () => {
    setPersonProfileState({
      ...personProfileState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearArrayData = () => {
    setPersonProfileState({
      ...personProfileState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSave = () => {
    setPersonProfileState({
      ...personProfileState,
      isSaved: false
    });
  };

  return {
    personProfileState,
    clearObjData,
    clearArrayData,
    confirmSave,
    getPersonProfile,
    putPersonProfile,
    postPersonProfile
  };
};

export default usePersonProfileData;