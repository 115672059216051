import React from 'react';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import useBackToSystemLink from './UseBackToSystemLink';

import global from '../../../common/components/GlobalStyle.module.css';

const BackToSystemLink = () => {
  const { label, onBackToSelectionClicked } = useBackToSystemLink();

  return (
    <button className={global.BackToSearchButton}
      type="button"
      onClick={onBackToSelectionClicked}>
      <LeftArrowIcon />  {label}
    </button>
  );
};

export default BackToSystemLink;