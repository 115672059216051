import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OtsMeetOfficialsOfficialSelectionValidation';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useOfficialsTrackingSystemOfficialSelectionData from '../../../../state/officialsTrackingSystemOfficialSelection/UseOfficialsTrackingSystemOfficialSelectionData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import UseForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  lscName: '',
  lscOrgUnitId: Constants?.DEFAULT_ID,
  clubOrgUnitId: Constants?.DEFAULT_ID,
  clubOrgUnitName: '',
  firstOrPreferredName: '',
  lastName: '',
  memberId: ''
};

const useOtsMeetOfficialsOfficialSelection = () => {
  const navigate = useNavigate();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { officialsTrackingSystemOfficialSelectionState,
    getOtsOfficialSelection, postOtsOfficialSelection, clearOfficialsTrackingSystemOfficialSelectionArrayData } = useOfficialsTrackingSystemOfficialSelectionData();
  const { navRoutes } = useNavRoutes();
  const [state, setState] = useState({ tryRedirect: false });
  const [gridState, setGridState] = useState({ gridData: [] });
  const [selectionChangesState, setSelectionChangesState] = useState({ arrayData: [] });
  const { handleSubmit, formState, errorState, setErrors, setFormState, onFormValueChanged, onValueTextPairChanged, setFormData
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  };

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormState(INITIAL_FORM_STATE);
    setErrors({});
  };

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (selectionChangesState.arrayData.length > 0) {
      postOtsOfficialSelection(otsMeetHeaderState.objData?.otsMeetId, selectionChangesState.arrayData);
    }
    setState({ ...state, tryRedirect: true });
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_OFFICIALS?.route);
  };

  const onSelectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      updatedGridData[i].isSelected = true;

      const selectedOfficialIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId);
      //Edit
      if (selectedOfficialIndexArrayData > -1) {
        updatedArrayData[selectedOfficialIndexArrayData].isSelected = true;
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onUnselectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      updatedGridData[i].isSelected = false;

      const selectedOfficialIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId);
      //Edit
      if (selectedOfficialIndexArrayData > -1) {
        updatedArrayData[selectedOfficialIndexArrayData].isSelected = false;
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onCheckboxChange = (e, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedOfficialIndexArrayData = updatedArrayData.findIndex(x => x.personId === personId);

    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedAthleteIndexGridData = gridState.gridData?.findIndex(x => x.personId === personId);

    if (selectedAthleteIndexGridData > -1) {
      updatedGridData[selectedAthleteIndexGridData].isSelected = !updatedGridData[selectedAthleteIndexGridData].isSelected;
      setGridState({
        ...gridState,
        gridData: updatedGridData
      });
    }

    if (selectedOfficialIndexArrayData > -1) {
      updatedArrayData[selectedOfficialIndexArrayData].isSelected = !updatedArrayData[selectedOfficialIndexArrayData].isSelected;
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
    else if (selectedOfficialIndexArrayData === -1) {
      const newAthlete = JSON.parse(JSON.stringify(updatedGridData[selectedAthleteIndexGridData]));
      updatedArrayData.push(newAthlete);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  function submitFormCallback(formState) {
    getOtsOfficialSelection(otsMeetHeaderState.objData?.otsMeetId, createFilterObj(formState));
  };

  function createFilterObj(formState) {
    return {
      lscOrgUnitId: formState.lscOrgUnitId !== Constants?.DEFAULT_ID ? formState.lscOrgUnitId : undefined,
      clubOrgUnitId: formState.clubOrgUnitId !== Constants?.DEFAULT_ID && formState.clubOrgUnitId !== '' ? formState.clubOrgUnitId : undefined,
      clubOrgUnitName: formState.clubOrgUnitName.trim() || undefined,
      firstName: formState.firstOrPreferredName.trim() !== '' ? formState.firstOrPreferredName.trim() : undefined,
      lastName: formState.lastName.trim() !== '' ? formState.lastName.trim() : undefined,
      memberId: formState.memberId.trim() !== '' ? formState.memberId.trim() : undefined
    }
  };

  useEffect(() => {
    clearOfficialsTrackingSystemOfficialSelectionArrayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (officialsTrackingSystemOfficialSelectionState.isArrayLoaded === true && officialsTrackingSystemOfficialSelectionState.isArrayLoading === false) {
      const arrayDataCopy = JSON.parse(JSON.stringify(officialsTrackingSystemOfficialSelectionState.arrayData));
      //keep unsaved selections / unselections from previous searches
      if (selectionChangesState.arrayData.length > 0) {
        for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
          const matchingIndex = arrayDataCopy.findIndex(x => x.personId === selectionChangesState.arrayData[i].personId);
          if (matchingIndex > -1) {
            arrayDataCopy[matchingIndex].isSelected = selectionChangesState.arrayData[i].isSelected;
          }
        }
      }
      setGridState({ ...gridState, gridData: arrayDataCopy });
    }
    else if (officialsTrackingSystemOfficialSelectionState.isArrayLoaded === false && officialsTrackingSystemOfficialSelectionState.isArrayLoading === false) {
      setGridState({ ...gridState, gridData: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officialsTrackingSystemOfficialSelectionState.isArrayLoaded, officialsTrackingSystemOfficialSelectionState.isArrayLoading])

  useEffect(() => {
    if (state.tryRedirect === true && officialsTrackingSystemOfficialSelectionState.isSaving === false) {
      navigate(navRoutes.OTS_MEET_OFFICIALS?.route, { state: { tryGet: selectionChangesState.arrayData.length > 0 ? true : false } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, officialsTrackingSystemOfficialSelectionState])

  return {
    gridState,
    officialsTrackingSystemOfficialSelectionState,
    onSelectAll,
    onUnselectAll,
    onSaveClicked,
    onBackClicked,
    onCheckboxChange,
    onFilterClicked,
    onClearFilterClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    formState,
    errorState,
    passedInMeetEndDate: otsMeetHeaderState.objData?.meetEndDate
  };
}

export default useOtsMeetOfficialsOfficialSelection;