import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ coachType, onAddEditCoachType, onDeleteCoachType, coachPersonId, coachName }) => (
  <tr>
    <td>{coachType.coachType || ''}</td>
    <td>{coachType.effectiveDate ? formatDate(coachType.effectiveDate) : ''}</td>
    <td>{coachType.expirationDate ? formatDate(coachType.expirationDate) : ''}</td>
    <td>{coachType.isPrimary === true ? "Yes" : "No"}</td>
    <td>{coachType.confirmedDates === true ? "Yes" : "No"}</td>
    <td>
      {coachType.confirmedDates === false
        ? <button className={global.IconButtonMargin} type="button" onClick={(e) =>
          onAddEditCoachType(e, coachPersonId, coachType.personCoachTypeId, coachName)}>
          <EditIcon />
        </button>
        : <Fragment />
      }
      <button className={global.IconButtonMargin} type="button" onClick={(e) =>
        onDeleteCoachType(e, coachPersonId, coachType.personCoachTypeId, `${coachType.coachType} (Effective Date: ${formatDate(coachType.effectiveDate)}, Expiration Date: ${formatDate(coachType.expirationDate)})`)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const DetailGrid = ({ coach, onAddEditCoachType, onDeleteCoachType }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Coach Type</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th>Primary?</th>
        <th>Confirmed Dates?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {coach.coachType && coach.coachType.map((coachType, i) =>
        <DetailGridRow coachType={coachType} key={i} onAddEditCoachType={onAddEditCoachType} onDeleteCoachType={onDeleteCoachType} coachPersonId={coach.coachPersonId} coachName={coach.coachFirstName + ' ' + coach.coachLastName} />)}
    </tbody>
  </table>
);

const GridRow = ({ coach, expandedId, onDelete, onDeleteCoachType, onAddEditCoach, onAddEditCoachType, coachName, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td>{coach.coachFirstName || ''} {coach.coachLastName || ''}</td>
      <td colSpan='2'>{coach.coachEmail || ''}</td>
      <td>{coach.isActiveCoach === 1 ? "Yes" : "No"}</td>
      <td>{coach.qualifiesForCoachIncentive === true ? "Yes" : "No"}</td>
      <td>
        <button className={global.IconButtonMargin} type="button" onClick={(e) =>
          onAddEditCoach(e, coach.coachPersonId, coachName)}>
          <EditIcon />
        </button>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddEditCoachType(e, coach.coachPersonId, -1, coach.coachFirstName + ' ' + coach.coachLastName)}>
          <PlusIcon toolTipText="Add Coach Type" />
        </button>
        <button className={global.IconButtonMargin} type="button" onClick={(e) =>
          onDelete(e, coach.coachPersonId, `${coachName} (Last Modified Date: ${formatDate(coach.modifiedDatetime)})`)}>
          <DeleteIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, coach.coachPersonId)}>
          {expandedId === coach.coachPersonId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {Array.isArray(coach.coachType) && coach.coachType.length > 0 && expandedId === coach.coachPersonId &&
      <tr className={global.Expanded}>
        <td colSpan="6">
          <DetailGrid coach={coach} onAddEditCoachType={onAddEditCoachType} onDeleteCoachType={onDeleteCoachType} />
        </td>
      </tr>
    }
  </Fragment>
);

const ProfileCoachLargeGrid = ({ gridState, expandedId, onDelete, onDeleteCoachType, onAddEditCoach, onAddEditCoachType, onExpandClicked }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Coach Name</th>
          <th colSpan="2">Email</th>
          <th>Is Active?</th>
          <th>Coach Incentive?</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {gridState.isArrayLoaded !== true
          ? (
            <tr><td colSpan="6">{gridState.isArrayLoading === true ? 'Loading...' : 'No Coaches'}</td></tr>
          ) : Array.isArray(gridState?.arrayData?.personCoach) && gridState?.arrayData?.personCoach?.length > 0
            ? gridState?.arrayData?.personCoach?.map((coach, i) => (
              <GridRow
                key={i}
                expandedId={expandedId}
                coach={coach}
                onDelete={onDelete}
                onDeleteCoachType={onDeleteCoachType}
                onAddEditCoach={onAddEditCoach}
                onAddEditCoachType={onAddEditCoachType}
                coachName={coach.coachFirstName + ' ' + coach.coachLastName}
                onExpandClicked={onExpandClicked}
              />))
            :
            <tr><td colSpan="6">No Coaches</td></tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default ProfileCoachLargeGrid;