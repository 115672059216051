import React, { Fragment } from 'react';

import OtsOfficialNationalCertificationHistoryLargeReadGrid from './OtsOfficialNationalCertificationHistoryLargeReadGrid';
import OtsOfficialNationalCertificationHistorySmallReadGrid from './OtsOfficialNationalCertificationHistorySmallReadGrid';

const OtsOfficialsNationalCertificationHistoryReadGrid = ({ state, formState }) => {
  return (
    <Fragment>
      <OtsOfficialNationalCertificationHistoryLargeReadGrid
        state={state}
        formState={formState}
      />
      <OtsOfficialNationalCertificationHistorySmallReadGrid
        state={state}
        formState={formState}
      />
    </Fragment>
  )
};

export default OtsOfficialsNationalCertificationHistoryReadGrid;
