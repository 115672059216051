import { useEffect, useState } from 'react';

import usePersonGeneralData from '../../../../../common/state/personGeneral/UsePersonGeneralData';

const INITIAL_VIEW_STATE = {
  reportParameters: { personId: '' },
  routeName: ''
};

const useOtsOfficialReporting = () => {
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });
  const { personGeneralState } = usePersonGeneralData();

  useEffect(() => {
    if (personGeneralState.objData?.personId) {
      setViewState({
        ...viewState,
        reportParameters: { personId: personGeneralState.objData.personId },
        routeName: 'OTS_OFFICIAL_REPORTING'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState]);

  return { ...viewState };
};

export default useOtsOfficialReporting;