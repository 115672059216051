import { useEffect, useState } from 'react';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import usePersonProfileData from '../../../state/personProfile/UsePersonProfileData';
import useMemberCareerStatsData from '../../../state/memberCareerStats/UseMemberCareerStatsData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

const useProfileCareerStats = () => {
  const { basePersonState } = useBasePersonData();
  const { personProfileState, getPersonProfile } = usePersonProfileData();
  const { memberCareerStatsState, getMemberCareerStats, putMemberCareerStats } = useMemberCareerStatsData();
  const [gridState, setGridState] = useState([]);

  const onCancelClicked = () => {
    setGridState(JSON.parse(JSON.stringify(memberCareerStatsState.arrayData)));
  };

  const onUpdateClicked = () => {
    const putMemberCareerStatsPromise = putMemberCareerStats(personProfileState.objData?.personId, gridState);

    if (putMemberCareerStatsPromise !== null) {
      putMemberCareerStatsPromise.then((newState) => {
        if (newState !== null) {
          setGridState(JSON.parse(JSON.stringify(newState.arrayData)));
        }
      }).catch((e) => {
        //TODO state error
      });
    }
  };

  const onUpdateIncludeOnLaneSheet = (personCareerStatsId) => {
    const newGridData = JSON.parse(JSON.stringify(gridState));

    for (const careerStat of newGridData) {
      if (personCareerStatsId === careerStat.personCareerStatsId) {
        careerStat.includeOnLaneSheet = !careerStat.includeOnLaneSheet;
        break;
      }
    }

    setGridState(newGridData);
  };

  useEffect(() => {
    if (personProfileState.isObjLoaded === true) {
      const getMemberCareerStatsPromise = getMemberCareerStats(personProfileState.objData?.personId);

      if (getMemberCareerStatsPromise !== null) {
        getMemberCareerStatsPromise.then((newState) => {
          if (newState !== null) {
            setGridState(JSON.parse(JSON.stringify(newState.arrayData)));
          }
        }).catch((e) => {
          //TODO state error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileState]);

  useEffect(() => {
    if (personProfileState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData?.personId, personProfileState.objData)) {
      getPersonProfile(basePersonState.objData?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  return {
    isLoading: memberCareerStatsState.isArrayLoading || personProfileState.isObjLoading || basePersonState.isObjLoading,
    isSaving: memberCareerStatsState.isSaving,
    gridState,
    onCancelClicked,
    onUpdateClicked,
    onUpdateIncludeOnLaneSheet
  };
};

export default useProfileCareerStats;