import React, { Fragment } from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ audit }) => (
  <tr>
    <td>{audit.actionDatetime ? formatDate(audit.actionDatetime) : ''}</td>
    <td>{audit.columnName || ''}</td>
    <td>{audit.oldValue || ''}</td>
    <td>{audit.newValue || ''}</td>
    <td>{audit.modifiedBy ? audit.modifiedBy : 'Anonymous'}</td>
  </tr>
);

const LargeGrid = ({ state }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Date</th>
        <th>Changed Field</th>
        <th>Value Before Change</th>
        <th>Value After Change</th>
        <th>Modified By</th>
      </tr>
    </thead>
    <tbody>
      {state.isLoaded !== true
        ? (
          <tr><td colSpan="5">{state.isLoading === true ? 'Loading...' : 'No Audits'}</td></tr>
        ) : Array.isArray(state.data) && state.data.length > 0
          ? state.data.slice(0).reverse().map((audit, i) => <LargeGridRow key={i} audit={audit} />
          ) : (
            <tr>
              <td colSpan="5">No Audits</td>
            </tr>
          )
      }
    </tbody>
  </table >
);

const SmallGridRow = ({ audit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{audit.columnName}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Changed Field
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {audit.columnName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {audit.actionDatetime ? formatDate(audit.actionDatetime) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Value Before
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {audit.oldValue || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Value After
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {audit.newValue || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Modified By
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {audit.modifiedBy || 'Anonymous'}
        </div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ state }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div> :
              <div className={global.SmallTableRowLabels}>No Audits</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.data) && state.data.length > 0
        ? state.data.slice(0).reverse().map((audit, i) => <SmallGridRow key={i} audit={audit} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Audits</div>
            </div>
          </div>
        )
    }
  </div >
);

const MemberAuditGrid = ({ state }) => (
  <Fragment>
    <LargeGrid state={state} />
    <SmallGrid state={state} />
  </Fragment>
);

export default MemberAuditGrid;