import React, { Fragment } from 'react';

import OtsOfficialMeetHistoryLargeReadGrid from './OtsOfficialMeetHistoryLargeReadGrid';
import OtsOfficialMeetHistorySmallReadGrid from './OtsOfficialMeetHistorySmallReadGrid';

import useExpandedRow from '../../../../../../common/components/grids/UseExpandedRow';

const OtsOfficialsMeetHistoryReadGrid = ({ state }) => {
  const { expandedId, onClick } = useExpandedRow();
  return (
    <Fragment>
      <OtsOfficialMeetHistoryLargeReadGrid
        state={state}
        expandedId={expandedId}
        onExpandClicked={onClick} />
      <OtsOfficialMeetHistorySmallReadGrid
        state={state}
        expandedId={expandedId}
        onExpandClicked={onClick} />
    </Fragment>
  )
};

export default OtsOfficialsMeetHistoryReadGrid;
