import { useState } from 'react';

import PersonFilesBirthCertificateData from './PersonFilesBirthCertificateData';

const usePersonFilesBirthCertificateData = () => {
  const [memberFilesBirthCertificateState, setMemberFilesBirthCertificateState] = useState(PersonFilesBirthCertificateData.INITIAL_STATE);

  const postMemberFilesBirthCertificate = (file) => {
    PersonFilesBirthCertificateData.postPersonFilesBirthCertificateData(file, memberFilesBirthCertificateState, setMemberFilesBirthCertificateState);
  };

  const resetMemberFilesBirthCertificateState = () => {
    setMemberFilesBirthCertificateState(PersonFilesBirthCertificateData.INITIAL_STATE);
  }

  const getFileNameFromSavedUploadUrl = () => {
    if (PersonFilesBirthCertificateData.isObjLoaded === true) {
      const fileNameWithDateTime = memberFilesBirthCertificateState.objData.uploadUrl.replace(PersonFilesBirthCertificateData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return memberFilesBirthCertificateState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(PersonFilesBirthCertificateData.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
    return decodeURI(fileName) || '';
  };

  return {
    memberFilesBirthCertificateState,
    postMemberFilesBirthCertificate,
    resetMemberFilesBirthCertificateState,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl,
    BASE_UPLOAD_URL: PersonFilesBirthCertificateData.BASE_UPLOAD_URL,
    GET_MEMBER_FILES_BIRTH_CERTIFICATE_PATH: PersonFilesBirthCertificateData.GET_MEMBER_FILES_BIRTH_CERTIFICATE_PATH
  };
};

export default usePersonFilesBirthCertificateData;