import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OtsMeetStaffDetailValidation'

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useOtsMeetStaffData from '../../../../state/otsMeetStaff/UseOtsMeetStaffData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import UseForm from "../../../../../common/utils/UseForm";
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useOtsMeetStaffDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { otsMeetStaffState, putOtsMeetStaffData, confirmSaveOtsMeetStaff } = useOtsMeetStaffData();
  const { formState, errorState, setFormData, handleSubmit, onFormValueChanged, setIsDirty
  } = UseForm(getInitialFormState(), submitFormCallback, validate);
  const { environmentVariableState, MEET_DIRECTOR_STAFF_TYPE_ID,
    MEET_REFEREE_STAFF_TYPE_ID, OFFICIALS_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const [state, setState] = useState({ tryRedirect: false });

  const onSaveButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    handleSubmit();
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_STAFF?.route);
  };

  useEffect(() => {
    if (location.state && environmentVariableState.isLoaded === true) {
      let meetDirectorMemberId = '';
      let meetRefereeMemberId = ''
      for (let i = 0; i < otsMeetStaffState.arrayData.length; i++) {
        if (otsMeetStaffState.arrayData[i].meetStaffTypeId === MEET_DIRECTOR_STAFF_TYPE_ID) {
          meetDirectorMemberId = otsMeetStaffState.arrayData[i].memberId || '';
        }
        else if (otsMeetStaffState.arrayData[i].meetStaffTypeId === MEET_REFEREE_STAFF_TYPE_ID) {
          meetRefereeMemberId = otsMeetStaffState.arrayData[i].memberId || '';
        }
      }
      setFormData({
        ...formState,
        meetStaffTypeId: location.state?.meetStaffTypeId || '',
        meetStaffTypeName: location.state?.meetStaffTypeName || '',
        isMeetDirector: location.state?.meetStaffTypeId === MEET_DIRECTOR_STAFF_TYPE_ID ? true : false,
        isMeetReferee: location.state?.meetStaffTypeId === MEET_REFEREE_STAFF_TYPE_ID ? true : false,
        meetDirectorMemberId: meetDirectorMemberId,
        meetRefereeMemberId: meetRefereeMemberId,
        personId: location.state?.personId || '',
        memberId: location.state?.memberId || '',
        OFFICIALS_ROLES_ROLE_GROUP_ID: OFFICIALS_ROLES_ROLE_GROUP_ID || '',
        meetStartDate: otsMeetHeaderState.objData.startDate ? formatDate(otsMeetHeaderState.objData.startDate) : Constants.BLANK_DATETIME_STRING,
        meetEndDate: otsMeetHeaderState.objData.endDate ? formatDate(otsMeetHeaderState.objData.endDate) : Constants.BLANK_DATETIME_STRING
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState])

  useEffect(() => {
    if (state.tryRedirect === true && otsMeetStaffState.isSaving === false &&
      otsMeetStaffState.isSaved === true) {
      confirmSaveOtsMeetStaff();
      navigate(navRoutes.OTS_MEET_STAFF?.route, { state: { tryGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, otsMeetStaffState])

  function submitFormCallback(formState) {
    const meetStaffObj = {
      personId: formState.personId,
      meetStaffTypeId: formState.meetStaffTypeId
    };
    putOtsMeetStaffData(otsMeetHeaderState.objData?.meetId, meetStaffObj);
    setState({ ...state, tryRedirect: true });
  };

  function getInitialFormState() {
    return ({
      meetStaffTypeId: '',
      meetStaffTypeName: '',
      isMeetDirector: false,
      isMeetReferee: false,
      meetDirectorMemberId: '',
      meetRefereeMemberId: '',
      personId: '',
      memberId: '',
      OFFICIALS_ROLES_ROLE_GROUP_ID: '',
      meetStartDate: Constants.BLANK_DATE_STRING,
      meetEndDate: Constants.BLANK_DATE_STRING
    })
  };

  return {
    otsMeetStaffState,
    otsMeetHeaderState,
    formState,
    errorState,
    handleSubmit,
    setFormData,
    onFormValueChanged,
    onSaveButtonClicked,
    onBackButtonClicked
  };
};

export default useOtsMeetStaffDetail;