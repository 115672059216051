import { Fragment } from 'react';

import useSAARegistrationForm from './UseSAARegistrationForm';

import YearDropdown from '../../../../app/components/dropdowns/YearDropdown';

import Dropdown from '../../dropdowns/Dropdown';
import Input from '../../inputs/Input';
import SecondaryButton from '../../buttons/SecondaryButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import PopUpModal from '../../dialogs/PopUpModal';
import Textarea from '../../inputs/Textarea';

import Constants from '../../../utils/Constants';

import global from '../../GlobalStyle.module.css';

const SAARegistrationForm = ({ personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj,
  primaryButtonText = 'Save', secondaryButtonText = 'Back', disableValidation = false, isAdminEdit = false, onSecondaryButtonClicked,
  onFormCompletion }) => {
  const {
    isLoading,
    isSaving,
    formState,
    errorState,
    optionsState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onFileDownload,
    onFileLoad,
    onFileUnload,
    getFileNameFromUrl
  } = useSAARegistrationForm(personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj, disableValidation, onFormCompletion);

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            {formState.gpaFile?.size > 0 || formState.gpaUrl !== ''
              ? (
                <Fragment>
                  <SecondaryButton onClick={(e) => onFileUnload(e)}>Remove Uploaded File</SecondaryButton>&nbsp;
                  {formState.gpaUrl !== '' && <PrimaryButton type="button" onClick={(e) => onFileDownload(e)}>Download Uploaded File</PrimaryButton>}
                  <p><b>File:</b> {formState.gpaFile.name || getFileNameFromUrl(formState.gpaUrl)}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor="gpa-file-upload" className={global.FileUpload}>Upload Complete Transcript</label>
                  <input hidden id="gpa-file-upload" type="file" onChange={(e) => onFileLoad(e)} />&nbsp;(*.pdf), (*.png), (*.jpg) or (*.jpeg)
                  {errorState.gpaFile && <p className={global.ErrorMessage}>{errorState.gpaFile}</p>}
                </Fragment>
              )
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label={disableValidation === true ? 'High School Name' : 'High School Name*'}
              name="highSchoolName"
              value={formState.highSchoolName}
              error={errorState.highSchoolName}
              message={errorState.highSchoolName}
              onChange={(value) => { onFormValueChanged('highSchoolName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YearDropdown
              label={disableValidation === true ? 'High School Graduation Year' : 'High School Graduation Year*'}
              name="highSchoolGraduationYear"
              value={formState.highSchoolGraduationYear}
              error={errorState.highSchoolGraduationYear}
              message={errorState.highSchoolGraduationYear}
              countOfYears={8}
              startYearOffset={-4}
              onChange={(newValue) => { onFormValueChanged('highSchoolGraduationYear', newValue) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label={disableValidation === true ? 'Class Year' : 'Class Year*'}
              name="classYearId"
              value={formState.classYearId}
              error={errorState.classYearId}
              message={errorState.classYearId}
              areValuesIntegers={false}
              isLoading={optionsState.areOptionsLoaded !== true}
              options={optionsState.classYearOptions}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'classYearId', newValueLabel, 'classYearName'); }} />
          </div>
          {isAdminEdit === true &&
            <Fragment>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label={disableValidation === true ? 'Weighted GPA' : 'Weighted GPA*'}
                  name="gpa"
                  value={formState.gpa}
                  error={errorState.gpa}
                  message={errorState.gpa}
                  onChange={(value) => { onFormValueChanged('gpa', value); }} />
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4">
                <Input
                  label={disableValidation === true ? 'Unweighted GPA' : 'Unweighted GPA*'}
                  name="unweightedGPA"
                  value={formState.unweightedGPA}
                  error={errorState.unweightedGPA}
                  message={errorState.unweightedGPA}
                  onChange={(value) => { onFormValueChanged('unweightedGPA', value); }} />
              </div>
            </Fragment>
          }
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label={disableValidation === true ? 'Para Status' : 'Para Status*'}
              name="paraStatusId"
              value={formState.paraStatusId}
              error={errorState.paraStatusId}
              message={errorState.paraStatusId}
              areValuesIntegers={false}
              isLoading={optionsState.areOptionsLoaded !== true}
              options={optionsState.paraOptions}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'paraStatusId', newValueLabel, 'paraStatusName'); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label={disableValidation === true ? 'Open Water Status' : 'Open Water Status*'}
              name="openWaterStatusId"
              value={formState.openWaterStatusId}
              error={errorState.openWaterStatusId}
              message={errorState.openWaterStatusId}
              areValuesIntegers={false}
              isLoading={optionsState.areOptionsLoaded !== true}
              options={optionsState.openWaterOptions}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'openWaterStatusId', newValueLabel, 'openWaterStatusName'); }} />
          </div>
          <div className="col-xs-12">
            <Textarea
              className={global.Textarea}
              label={disableValidation === true ? "Explain your High School grading system" : "Explain your High School grading system*"}
              name="highSchoolGradingSystem"
              value={formState.highSchoolGradingSystem}
              error={errorState.highSchoolGradingSystem}
              message={errorState.highSchoolGradingSystem}
              onChange={(value) => { onFormValueChanged('highSchoolGradingSystem', value) }} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">{primaryButtonText}</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
            {errorState && errorState.errorOnSubmitAction
              && <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction}</p>
            }
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default SAARegistrationForm;