import { Fragment } from 'react';

import useLscOfferingInstanceCoupon from './UseLscOfferingInstanceCoupon';

import LscOfferingManagementContextLoadingModal from '../LscOfferingManagementContextLoadingModal';

import OfferingInstanceCouponDiscountFormGrid from '../../components/grids/offeringInstance/OfferingInstanceCouponDiscountFormGrid';

import { SAVING_MSG } from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LscOfferingInstanceCoupon = () => {
  const {
    isSaving,
    state,
    offeringInstanceCouponDiscountsState,
    onSubmitCouponDiscount
  } = useLscOfferingInstanceCoupon();

  return (
    <Fragment>
      <p className={global.UsasLabel}>Note*:&nbsp;LSC Fee must be between $0.00 and $2.00</p>
      <OfferingInstanceCouponDiscountFormGrid
        gridData={offeringInstanceCouponDiscountsState.arrayData}
        isLoading={offeringInstanceCouponDiscountsState.isArrayLoading}
        onSubmitCoupon={onSubmitCouponDiscount} />
      <LscOfferingManagementContextLoadingModal
        isLoading={isSaving}
        loadingMessage={SAVING_MSG}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default LscOfferingInstanceCoupon;