import { DEFAULT_ID } from "../../../common/utils/Constants";
import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const postOfferingData = (offeringObj, state, setState) => {
  if (offeringObj) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const url = `/Offering`;

    return api?.executeObject ? api.executeObject(url, 'POST', offeringObj) : null;
  }
};

const postOfferingInstanceData = (offeringInstanceObj, state, setState) => {
  if (offeringInstanceObj) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const url = `/OfferingInstance`;

    return api?.executeObject ? api.executeObject(url, 'POST', offeringInstanceObj) : null;
  }
};

const getOfferingsData = (state, setState) => {
  const api = initApi(API_NAMES.OMR, state, setState);
  const url = `/Offering`;

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const getOfferingInstancesData = (reportPeriodId, state, setState) => {
  if (reportPeriodId > 0) {
    const newState = {
      ...state,
      reportPeriodId
    };

    const api = initApi(API_NAMES.OMR, newState, setState);
    const reportPeriodIdForUrl = reportPeriodId ? encodeURIComponent(reportPeriodId) : 'NaN';
    const url = `/OfferingInstance?reportPeriodId=${reportPeriodIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putOfferingData = (offeringId, offeringObj, state, setState) => {
  if (offeringId > 0 && offeringObj) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const offeringIdForUrl = offeringId ? encodeURIComponent(offeringId) : 'NaN';
    const url = `/Offering/${offeringIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'PUT', offeringObj) : null;
  }
};

const putOfferingInstanceData = (offeringInstanceId, offeringInstanceObj, state, setState) => {
  if (offeringInstanceId > 0 && offeringInstanceObj) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const offeringInstanceIdForUrl = offeringInstanceId ? encodeURIComponent(offeringInstanceId) : 'NaN';
    const url = `/OfferingInstance/${offeringInstanceIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'PUT', offeringInstanceObj) : null;
  }
};

const deleteOfferingData = (offeringId, state, setState) => {
  if (offeringId > 0) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const offeringIdForUrl = offeringId ? encodeURIComponent(offeringId) : 'NaN';
    const url = `/Offering/${offeringIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const deleteOfferingInstanceData = (offeringInstanceId, state, setState) => {
  if (offeringInstanceId) {
    const api = initApi(API_NAMES.OMR, state, setState);
    const offeringInstanceIdForUrl = offeringInstanceId ? encodeURIComponent(offeringInstanceId) : 'NaN';
    const url = `/OfferingInstance/${offeringInstanceIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const INITIAL_OFFERING_MANAGEMENT_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_OFFERING_MANAGEMENT_INSTANCE_STATE = {
  ...BASIC_INITIAL_STATE,
  reportPeriodId: DEFAULT_ID
};

const OfferingManagementData = {
  postOfferingData,
  getOfferingsData,
  putOfferingData,
  deleteOfferingData,

  postOfferingInstanceData,
  getOfferingInstancesData,
  putOfferingInstanceData,
  deleteOfferingInstanceData,

  INITIAL_OFFERING_MANAGEMENT_STATE,
  INITIAL_OFFERING_MANAGEMENT_INSTANCE_STATE
};

export default OfferingManagementData;