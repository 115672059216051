import { Fragment } from 'react';

import UseOtsOfficialNationalCertificationApplicationHistory from './UseOtsOfficialNationalCertificationApplicationHistory';

import OtsOfficialsNationalCertificationApplicationHistoryGrid from './grids/OtsOfficialNationalCertificationApplicationHistoryGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const OtsOfficialNationalCertificationApplicationHistory = () => {
  const {
    basePersonState,
    localOtsCertificationApplicationState,
    onStatusClicked
  } = UseOtsOfficialNationalCertificationApplicationHistory();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>National Certification Application History</Headings.H3>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <OtsOfficialsNationalCertificationApplicationHistoryGrid
            state={localOtsCertificationApplicationState}
            onStatusClicked={onStatusClicked} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState?.isObjLoading || localOtsCertificationApplicationState.isArrayLoading} />
    </Fragment >
  );
};

export default OtsOfficialNationalCertificationApplicationHistory;