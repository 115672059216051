import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getPersonCancelMembership from './GetPersonCancelMembershipData';
import deletePersonMembership from './DeletePersonMembershipData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonCancelMembershipData = {
  INITIAL_STATE,
  getPersonCancelMembership,
  deletePersonMembership

};

export default PersonCancelMembershipData;