import { isValidName, isValidMemberId, isValidEmail } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;
  let hasCurrentOrExpiredSelection = false;

  //First Name
  if (formState.firstName.trim() !== '') {
    if (!isValidName(formState.firstName.trim())) {
      errors.firstName = 'Legal First Name or Preferred Name cannot contain numbers, special characters, or exceed 100 characters';
    } else {
      filterCount += 1;
    }
  }

  //Last Name
  if (formState.lastName.trim() !== '') {
    if (!isValidName(formState.lastName.trim())) {
      errors.lastName = 'Legal Last Name or Maiden Name cannot contain numbers, special characters, or exceed 100 characters';
    } else {
      filterCount += 1;
    }
  }

  //Member ID
  if (formState.memberId.trim() !== '') {
    if (!isValidMemberId(formState.memberId.trim())) {
      errors.memberId = 'Member ID must be 14 alphanumeric characters';
    }
    else {
      filterCount += 1;
    }
  }

  //Email
  if (formState.email.trim() !== '') {
    if (!isValidEmail(formState.email.trim())) {
      errors.email = 'Email must be in a valid format';
    } else if (formState.email.trim().length > 512) {
      errors.email = 'Email cannot exceed 512 characters'
    } else {
      filterCount += 1;
    }
  }

  //  LSC
  if (formState.lscOrgUnitId !== Constants.DEFAULT_ID) {
    filterCount += 1;
  }

  // Club Name
  if (formState.clubOrgUnitName !== '') {
    if (formState.clubOrgUnitId === Constants.DEFAULT_ID || formState.clubOrgUnitId === '') {
      errors.clubOrgUnitId = 'Must be a valid Club Name';
    }
    else {
      filterCount += 1;
    }
  }

  // Official Position Type
  if (formState.officialPositionTypeId !== Constants.DEFAULT_ID) {
    filterCount += 1;
  }

  // Certification Level
  if (formState.certificationLevelId !== Constants.DEFAULT_ID) {
    filterCount += 1;
  }

  //  Current or Expired Membership
  if (formState.isCurrentId !== Constants.DEFAULT_ID) {
    filterCount += 1;
    hasCurrentOrExpiredSelection = true;
  }

  if (filterCount === 1 && hasCurrentOrExpiredSelection === true) {
    errors.filter = 'Please provide at least one more filter to search';
  }

  if (filterCount < 1) {
    errors.filter = 'Please provide at least one filter to search';
  }

  return errors;
};

const OtsOfficialsSearchValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};

}

export default OtsOfficialsSearchValidation;