import React, { Fragment } from 'react';

import useMemberDisabilities from './UseMemberDisabilities';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import RadioListWithMultiCheckboxChildren from '../../../../common/components/hierarchicalLists/RadioListWithMultiCheckboxChildren';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const MemberDisabilitiesWrite = () => {
  const {
    disabilityTypeState,
    basePersonState,
    personGeneralState,
    hierarchicalOptions,
    formState,
    state,
    errorState,
    onFormValueChanged,
    handleSubmit,
    resetForm
  } = useMemberDisabilities();

  if (hierarchicalOptions.length <= 0) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>Member Disabilities (Optional)</Headings.H3>
          </div>
        </div>
        <PopUpModal
          widthPct={50}
          maxWidth={250}
          title={Constants.LOADING_MSG}
          displayPopUp={hierarchicalOptions.length <= 0} />
      </Fragment>
    );
  }
  const disabilitiesHeadingLabel = <Headings.H6>
    {(state.isAthlete === true) ? "Athlete Abilities" : "Non-Athlete Abilities"}
  </Headings.H6>;
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Disabilities (Optional)</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12">
            <RadioListWithMultiCheckboxChildren
              label={disabilitiesHeadingLabel}
              name="disabilityTypes"
              childOptionsName="impairments"
              currentValues={formState.disabilityTypes}
              error={errorState.disabilityTypes}
              message={errorState.disabilityTypes}
              hierarchicalOptions={hierarchicalOptions}
              onChange={(value) => { onFormValueChanged('disabilityTypes', value); }}
            />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || disabilityTypeState.isArrayLoading || personGeneralState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={basePersonState.isSaving} />
    </Fragment>
  );
};

export default MemberDisabilitiesWrite;

