import { useEffect } from 'react';

import useOtsMeetOfficialData from '../../../state/otsMeetOfficial/UseOtsMeetOfficialData';

const useOtsMeetOfficialCombobox = (otsMeetId) => {
  const { otsMeetOfficialState, getOtsMeetOfficialsByOtsMeetId } = useOtsMeetOfficialData();

  useEffect(() => {
    if (otsMeetOfficialState.isArrayLoading !== true && (otsMeetOfficialState.isArrayLoaded !== true ||
      (otsMeetId !== otsMeetOfficialState.otsMeetId))) {
      getOtsMeetOfficialsByOtsMeetId(otsMeetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetOfficialState, otsMeetId]);

  return { otsMeetOfficialState, getOtsMeetOfficialsByOtsMeetId };
};

export default useOtsMeetOfficialCombobox;