import { useState} from 'react';

import OrgUnitLowestChildrenData from './OrgUnitLowestChildrenData';

const useOrgUnitLowestChildrenData = () => {
  const [orgUnitLowestChildrenState, setOrgUnitLowestChildrenState] = useState(OrgUnitLowestChildrenData.INITIAL_STATE);
  const getOrgUnitsByOrganization = (organizationId) => OrgUnitLowestChildrenData.getOrgUnitsByOrganization(organizationId, orgUnitLowestChildrenState, setOrgUnitLowestChildrenState);
  const getOrgUnitsByLsc = (organizationId, lscId) => OrgUnitLowestChildrenData.getOrgUnitsByLsc(organizationId, lscId, orgUnitLowestChildrenState, setOrgUnitLowestChildrenState);

  return {
    orgUnitLowestChildrenState,
    getOrgUnitsByLsc,
    getOrgUnitsByOrganization
  };
};

export default useOrgUnitLowestChildrenData;