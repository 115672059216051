import UseOtsSessionsDetailNoOQM from "./UseOtsSessionsDetailNoOQM";

import useOtsMeetSessionsData from "../../../../../common/state/officialsTrackingSystemMeetSessions/UseOtsMeetSessionsData";

const useOtsMeetSessionsDetailNoOQM = () => {
  const { otsMeetSessionsState } = useOtsMeetSessionsData();
  const { meetSessionsState, onSaveButtonClicked,
    onBackButtonClicked } = UseOtsSessionsDetailNoOQM();

  return {
    otsMeetSessionsState,
    meetSessionsState,
    onSaveButtonClicked,
    onBackButtonClicked
  };
};

export default useOtsMeetSessionsDetailNoOQM;