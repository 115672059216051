import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './HqOtsOfficialLscBulkCertificationValidation';

import useOtsOfficialsBulkRecertifyData from '../../../state/officialsTrackingSystemBulkRecertification/UseOtsOfficialsBulkRecertifyData';
import useOfficialPositionTypeData from '../../../state/officialPositionType/UseOfficialPositionTypeData';
import useCertificationLevelData from '../../../state/certificationLevel/UseCertificationLevelData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useLscData from '../../../../common/state/lsc/UseLscData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useEmbeddedReportData from '../../../../common/state/embeddedReport/UseEmbeddedReportData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const INITIAL_RECERTIFY_STATE = {
  recertifyBulkOfficialPersonIds: [],
  isUnselectAllClicked: false,
};

const INITIAL_VIEW_STATE = {
  showLoading: false,
  errorMessage: ''
};

const sortByOptions = [
  { id: 1, name: "First Name", value: "firstName desc" },
  { id: 2, name: "Last Name", value: "lastName desc" },
  { id: 3, name: "Expiration Date", value: "expirationDate desc" }
];

const INITIAL_FORM_STATE = {
  lscId: Constants.DEFAULT_ID,
  lscName: '',
  officialPositionTypeId: Constants.DEFAULT_ID,
  officialPositionName: '',
  certificationLevelId: Constants.DEFAULT_ID,
  levelName: '',
  fromExpirationDate: Constants.BLANK_DATE_STRING,
  toExpirationDate: Constants.BLANK_DATE_STRING,
  sortById: sortByOptions[0].id,
  sortByName: sortByOptions[0].name
};

const useHqOtsOfficialLscBulkRecertify = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const [recertifyBulkState, setRecertifyBulkState] = useState(INITIAL_RECERTIFY_STATE);
  const { officialPositionTypeState } = useOfficialPositionTypeData();
  const { certificationLevelState } = useCertificationLevelData();
  const { searchForOfficialsForBulkRecertify, searchOtsOfficialsForBulkRecertifyState, searchOtsOfficialsForBulkRecertifyFilterState } = useOtsOfficialsBulkRecertifyData();
  const { lscState } = useLscData();
  const { environmentVariableState, LSC_ORG_LEVEL_ID } = useEnvironmentVariableData();
  const { formState, errorState, onFormValueChanged, onValueTextPairChanged, setFormState, setIsDirty, handleSubmit, setErrors
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const { getEmbeddedReportListForRoute, getEmbeddedReport } = useEmbeddedReportData();

  function createSearchFilterObj(formState) {
    return {
      orgUnitId: formState?.lscId,
      officialPositionTypeId: formState.officialPositionTypeId > 0 ? formState.officialPositionTypeId : undefined,
      certificationLevelId: formState.certificationLevelId > 0 ? formState.certificationLevelId : undefined,
      fromExpirationDate: formState.fromExpirationDate !== Constants.BLANK_DATE_STRING ? formState.fromExpirationDate : undefined,
      toExpirationDate: formState.toExpirationDate !== Constants.BLANK_DATE_STRING ? formState.toExpirationDate : undefined,
    };
  }

  const onSendFilterInfoToGetGridData = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  };

  const onClearFilterInfo = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setFormState(INITIAL_FORM_STATE);
    setErrors({});
  };

  const onAddOrRemoveLscCertficationForBulkRecertify = (checked, lscCertifcation, personId) => {
    const newArray = [...recertifyBulkState?.recertifyBulkOfficialPersonIds];
    const object = newArray?.find(x => x.personId === personId);
    let index = newArray.findIndex(x => x.personId === personId);

    if (checked === true) {
      //add to state array
      if (object === undefined) {
        newArray.push(lscCertifcation);
      }
    }
    else {
      //remove from state array
      if (Object?.keys(object)?.length > 0) {
        newArray.splice(index, 1);
      }
    }

    setRecertifyBulkState({
      ...recertifyBulkState,
      recertifyBulkOfficialPersonIds: newArray
    });
  };

  const onNavigateToHqLscBulkRecertifyDetailView = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const recertifyBulkStateArray = recertifyBulkState.recertifyBulkOfficialPersonIds;

    let lscName = '';
    if (searchOtsOfficialsForBulkRecertifyFilterState?.filterObject) {
      const oldSearchValues = JSON.parse(JSON.stringify(searchOtsOfficialsForBulkRecertifyFilterState.filterObject))
      let selectedLsc = lscState.dataAsOptionsWithNames.find(x => x.id === oldSearchValues.orgUnitId);
      lscName = selectedLsc ? selectedLsc.name : '';
    }

    navigate(navRoutes?.HQ_OTS_LSC_BULK_RECERTIFICATION_DETAIL?.route, { state: { recertifyBulkStateArray, lscName } });
  };

  const onSelectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const allSearchedListArrayData = JSON.parse(JSON.stringify(searchOtsOfficialsForBulkRecertifyState.arrayData));

    setRecertifyBulkState({
      ...recertifyBulkState,
      recertifyBulkOfficialPersonIds: allSearchedListArrayData,
      isUnselectAllClicked: true,
    });
  };

  const onUnSelectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRecertifyBulkState({ ...INITIAL_RECERTIFY_STATE });
  };

  const calculateCountOfYears = () => {
    let countOfYears = 0;
    const today = new Date();
    const year = today.getFullYear();
    countOfYears = year - 2000 + 9;
    return countOfYears;
  };

  const calculateStartYearOffset = () => {
    let startYearOffset = 0;
    const today = new Date();
    const year = today.getFullYear();
    startYearOffset = 2000 - year;
    return startYearOffset;
  };

  const showErrorMessage = (errorMessage) => {
    setState({ ...state, errorMessage, showLoading: false });
  };

  const onErrorCanceled = () => {
    setState({ ...state, errorMessage: '', showLoading: false });
  };

  const onOfficialHistoryReportLoadClick = (official) => {
    const newState = {
      ...state,
      showLoading: true
    };
    setState({ ...newState });
    getEmbeddedReportListForRoute('HQ_OTS_LSC_BULK_RECERTIFICATION')
      .then(data => {
        if (data?.arrayData?.[0].reportInfoId > 0) {
          getEmbeddedReport(data?.arrayData?.[0].reportInfoId, { personId: official.personId })
            .then(rptData => {
              if (rptData?.objData?.reportUrl) {
                setState({ ...newState, showLoading: false });
                window.open(rptData.objData.reportUrl, '_blank').focus();
              } else {
                showErrorMessage('There was an error building the report.');
              }
            })
            .catch(() => {
              showErrorMessage('There was an error building the report.');
            });
        } else {
          showErrorMessage('There was an error building the report.');
        }
      })
      .catch(() => {
        showErrorMessage('There was an error building the report.');
      });
  };

  useEffect(() => {
    if (searchOtsOfficialsForBulkRecertifyFilterState?.filterObject && Object.keys(searchOtsOfficialsForBulkRecertifyFilterState?.filterObject)?.length > 0 &&
      environmentVariableState.isLoaded === true && lscState.isLoaded === true && officialPositionTypeState.isArrayLoaded === true &&
      certificationLevelState.isArrayLoaded === true) {
      const oldSearchValues = JSON.parse(JSON.stringify(searchOtsOfficialsForBulkRecertifyFilterState.filterObject))
      let selectedLsc = lscState.dataAsOptionsWithNames.find(x => x.id === oldSearchValues.orgUnitId);
      let lscId = selectedLsc ? selectedLsc.id : Constants.DEFAULT_ID;
      let lscName = selectedLsc ? selectedLsc.name : '';
      setFormState({
        ...formState,
        ...oldSearchValues,
        lscId,
        lscName
      });
      setIsDirty(true);

      if (searchOtsOfficialsForBulkRecertifyState?.isArrayLoading === false) {
        searchForOfficialsForBulkRecertify(searchOtsOfficialsForBulkRecertifyFilterState.filterObject, '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState.isLoaded, lscState.isLoaded, officialPositionTypeState.isArrayLoaded, certificationLevelState.isArrayLoaded]);

  function submitFormCallback() {
    let sortBy = '';
    if (searchOtsOfficialsForBulkRecertifyState?.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchForOfficialsForBulkRecertify(filterObject, sortBy || '');
    }
  }

  return {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    onSendFilterInfoToGetGridData,
    onClearFilterInfo,
    searchOtsOfficialsForBulkRecertifyState,
    isLoading: certificationLevelState?.isArrayLoading || officialPositionTypeState?.isArrayLoading || searchOtsOfficialsForBulkRecertifyState?.isArrayLoading === true,
    notLoaded: certificationLevelState?.arrayData?.length === 0 || officialPositionTypeState?.arrayData?.length === 0,
    onAddOrRemoveLscCertficationForBulkRecertify,
    recertifyBulkState,
    onNavigateToHqLscBulkRecertifyDetailView,
    onSelectAll,
    onUnSelectAll,
    countOfYears: calculateCountOfYears(),
    startYearOffset: calculateStartYearOffset(),
    LSC_ORG_LEVEL_ID,
    state,
    onErrorCanceled,
    onOfficialHistoryReportLoadClick
  };
};

export default useHqOtsOfficialLscBulkRecertify;