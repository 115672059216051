import React, { Fragment } from 'react';

import useOtsOfficialLscCertification from './UseOtsOfficialLscCertification';
import OtsOfficialsLscCertificationReadGrid from './grids/OtsOfficialLscCertificationReadGrid';

import Headings from '../../../../../common/components/headings/Headings';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const OtsOfficialLscCertificationRead = () => {
  const {
    otsOfficialLscState,
    formState,
    errorState,
    onFormValueChanged
  } = useOtsOfficialLscCertification();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>LSC Certifications</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12 col-md-7'>
          <p>Total Sessions Worked in Sanctioned Meet Regardless of Certification: {otsOfficialLscState?.arrayData?.sessionsWorked >= 0 ? otsOfficialLscState?.arrayData?.sessionsWorked : ''}</p>
        </div>
        <div className='col-xs-12 col-md-5'>
          <span className='pull-right'>
            <YesNoSwitch
              label="Show All LSC Certification History?"
              name="showAllLscCertificationHistory"
              checked={formState.showAllLscCertificationHistory === true}
              error={errorState.showAllLscCertificationHistory}
              message={errorState.showAllLscCertificationHistory}
              onChange={(value) => { onFormValueChanged('showAllLscCertificationHistory', value); }}
              isHorizontal={true} />
          </span>
        </div>
      </div>
      <div className='row usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          <OtsOfficialsLscCertificationReadGrid
            state={otsOfficialLscState}
            formState={formState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={otsOfficialLscState?.isArrayLoading} />
    </Fragment>
  );
};

export default OtsOfficialLscCertificationRead;