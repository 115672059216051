import { DEFAULT_ID } from "../../../common/utils/Constants";
import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getAvailableOfferingInstancesData = (orgUnitId, reportPeriodId, state, setState) => {
  if (orgUnitId && reportPeriodId > 0) {
    const newState = {
      ...state,
      orgUnitId,
      reportPeriodId
    };

    const api = initApi(API_NAMES.OMR, newState, setState);
    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const reportPeriodIdForUrl = reportPeriodId ? encodeURIComponent(reportPeriodId) : 'NaN';
    const url = `/Offering/OrgUnit/${orgUnitIdForUrl}/ReportPeriod/${reportPeriodIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_OFFERING_INSTANCES_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  reportPeriodId: DEFAULT_ID
};

const OfferingInstancesData = {
  INITIAL_OFFERING_INSTANCES_STATE,

  getAvailableOfferingInstancesData
};

export default OfferingInstancesData;