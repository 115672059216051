import { API_NAMES, initApi } from "../../utils/HttpApiHelper";

const postGroupMemberGPAFileData = (file, state, setState) => {
  if (file) {
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);

    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/gpa`;
    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const getGroupMemberGPAFileData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/PersonFiles/gpa?fileUrl=${fileUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const MemberGroupsFileData = {
  postGroupMemberGPAFileData,
  getGroupMemberGPAFileData
};

export default MemberGroupsFileData;