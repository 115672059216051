import { useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsCertificationApplicationData from '../../../../state/otsCertificationApplication/UseOtsCertificationApplicationData';
import useOtsOfficialData from '../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';

import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

const useOtsOfficialNationalCertificationApplicationHistory = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { otsOfficialHeaderState } = useOtsOfficialData();
  const { localOtsCertificationApplicationState, getCertificationApplicationsHistoryForPerson } = useOtsCertificationApplicationData();

  const onStatusClicked = (position) => {
    navigate(navRoutes.OTS_OFFICIAL_NATIONALCERTIFICATIONAPPLICATIONHISTORY_STATUS?.route, {
      state: {
        personPositionCertificationProgressionId: position.personPositionCertificationProgressionId,
        certificationPublicNote: position.certificationPublicNote,
        publicNotes: position.publicNotes,
        position: position
      }
    });
  };

  useEffect(() => {
    if (localOtsCertificationApplicationState.isArrayLoading === false && localOtsCertificationApplicationState.isArrayLoaded === false) {
      const personId = otsOfficialHeaderState?.objData?.personId
      getCertificationApplicationsHistoryForPerson(personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localOtsCertificationApplicationState])

  return {
    localOtsCertificationApplicationState,
    basePersonState,
    onStatusClicked
  };
};

export default useOtsOfficialNationalCertificationApplicationHistory;