import React from 'react';

import useMemberReporting from './UseMemberReporting';

import ReportList from '../../../../common/components/embeddedReports/ReportList';

const MemberReporting = () => {
  const { reportParameters, contextId, routeName } = useMemberReporting();

  return (
    <ReportList listTitle="Member Reports"
      routeName={routeName}
      contextId={contextId}
      reportParameters={reportParameters} />
  );
};

export default MemberReporting;