import React from 'react';

import BaseMemberContextProvider from '../../state/member/baseMember/BaseMemberContextProvider';

import BasePersonContextProvider from '../../../common/state/personBase/BasePersonContextProvider';
import ReportPeriodSeasonContextProvider from '../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider';
import SearchMemberContextProvider from '../../../common/state/searchMember/SearchMemberContextProvider';
import GroupPersonCoachesContextProvider from '../../../common/state/groupPersonCoaches/GroupPersonCoachesContextProvider';

const MemberContextProviders = ({ children }) => {
  return (
    <SearchMemberContextProvider>
      <BasePersonContextProvider>
        <BaseMemberContextProvider>
          <ReportPeriodSeasonContextProvider>
            <GroupPersonCoachesContextProvider>
              {children}
            </GroupPersonCoachesContextProvider>
          </ReportPeriodSeasonContextProvider>
        </BaseMemberContextProvider>
      </BasePersonContextProvider>
    </SearchMemberContextProvider>
  );
};

export default MemberContextProviders;