import { Fragment } from 'react';

import MemberCoursesLargeAdvancedWriteGrid from './MemberCoursesLargeAdvancedWriteGrid';
import MemberCoursesSmallAdvancedWriteGrid from './MemberCoursesSmallAdvancedWriteGrid';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MemberCoursesAdvancedWriteGrid = ({ gridData, isLoading, onEditCourse , viewLocation}) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MemberCoursesLargeAdvancedWriteGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditCourse={onEditCourse}
        viewLocation={viewLocation}
        expandedId={expandedId}
        onClick={onClick} />
      <MemberCoursesSmallAdvancedWriteGrid
        gridData={gridData}
        isLoading={isLoading}
        onEditCourse={onEditCourse}
        viewLocation={viewLocation}
        expandedId={expandedId}
        onClick={onClick} />
    </Fragment>
  );
};

export default MemberCoursesAdvancedWriteGrid;
