import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MemberClubTransferMemberDetailValidation';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import usePersonClubTransfer from '../../../state/member/memberClubTransfer/UsePersonClubTransferData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useMemberClubTransferMemberDetail = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const location = useLocation();
  const { memberTransferState, postPersonClubTransfer
  } = usePersonClubTransfer();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, onValueTextPairChanged
  } = useForm(getInitialFormState, submitFormCallback, validate);
  const [transferState, setTransferState] = useState(getInitialTransferState);

  const onBackClicked = () => {
    navigate(navRoutes.MEMBER_CLUB_TRANSFER?.route);
  };

  useEffect(() => {
    if (location.state?.club && location.state?.personRole) {
      const club = location.state.club;
      const personRole = location.state.personRole;

      setFormData({
        ...formState,
        transferSignatureDate: new Date(),
        currentOrgUnitId: club.orgUnitId || ''
      });

      setTransferState({
        ...transferState,
        personOrgRoleDurationId: personRole.personOrgRoleDurationId || Constants.DEFAULT_ID,
        currentOrgUnitId: club.orgUnitId || '',
        currentOrgUnitName: (club.orgUnitName || club.orgUnitCode || club.parentOrgUnitCode)
          ? `${club.orgUnitName || ''} (${club.parentOrgUnitCode || ''}/${club.orgUnitCode || ''})`
          : '',
        orgRoleId: personRole.orgRoleId || '',
        orgRoleName: personRole.orgRoleName || '',
        isTransferEligible: personRole.isTransferEligible === true ? true : false,
        personClubTransferId: personRole.personClubTransferId || Constants.DEFAULT_ID,
        lastMeet: {
          meetName: personRole.meetName || '',
          city: personRole.city || '',
          stateCode: personRole.stateCode || '',
          competitionDate: personRole.competitionDate || null
        },
        personClubTransfer: {
          transferOrgUnitId: personRole.transferOrgUnitId || Constants.DEFAULT_ID,
          transferOrgUnitName: (personRole.transferOrgUnitName || personRole.transferOrgUnitCode || personRole.transferParentOrgUnitCode)
            ? `${personRole.transferOrgUnitName || ''} (${personRole.transferParentOrgUnitCode || ''}/${personRole.transferOrgUnitCode || ''})`
            : '',
          transferSignature: personRole.transferSignature || '',
          transferSignatureDate: personRole.transferSignatureDate || Constants.BLANK_DATE_STRING
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (memberTransferState.isSaved === true) {
      setTransferState({
        ...transferState,
        personClubTransferId: memberTransferState.objData.personClubTransferId || Constants.DEFAULT_ID,
        personClubTransfer: {
          transferOrgUnitId: formState.transferOrgUnitId || Constants.DEFAULT_ID,
          transferOrgUnitName: formState.transferOrgUnitName || '',
          transferSignature: formState.transferSignature || '',
          transferSignatureDate: formState.transferSignatureDate || Constants.BLANK_DATE_STRING
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberTransferState.isSaved]);

  function submitFormCallback(formState) {
    if (memberTransferState.isSaving === false) {
      postPersonClubTransfer({
        personOrgRoleDurationId: transferState.personOrgRoleDurationId,
        transferOrgUnitId: formState.transferOrgUnitId,
        transferSignature: formState.transferSignature.trim(),
        transferSignatureDate: formState.transferSignatureDate.toISOString(),
        lastCompetitionDate: transferState.lastMeet.competitionDate
      });
    }
  };

  function getInitialFormState() {
    return {
      currentOrgUnitId: Constants.DEFAULT_ID,
      transferOrgUnitId: Constants.DEFAULT_ID,
      transferOrgUnitName: '',
      transferSignature: '',
      transferSignatureDate: Constants.BLANK_DATE_STRING,
    };
  };

  function getInitialTransferState() {
    return {
      personOrgRoleDurationId: Constants.DEFAULT_ID,
      currentOrgUnitId: Constants.DEFAULT_ID,
      currentOrgUnitName: '',
      orgRoleId: '',
      orgRoleName: '',
      isTransferEligible: false,
      personClubTransferId: Constants.DEFAULT_ID,
      lastMeet: {
        meetName: '',
        city: '',
        stateCode: '',
        competitionDate: null
      },
      personClubTransfer: {
        transferOrgUnitId: Constants.DEFAULT_ID,
        transferOrgUnitName: '',
        transferSignature: '',
        transferSignatureDate: Constants.BLANK_DATE_STRING
      }
    };
  };

  return {
    isSaving: memberTransferState.isSaving,
    isLoading: memberTransferState.isObjLoading,
    formState,
    errorState,
    transferState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onBackClicked,
    clubTransferNotice: {
      part1: 'This will confirm that the above information is correct. I understand that the above athlete will remain Unattached for 60 consecutive days from the date of last open sanctioned competition in accordance with USA Swimming Rules and Regulations 203.3.',
      part2: 'The club from which the athlete is transferring will be notified that this transfer has occurred. The old club has the responsibility to notify the LSC Registrar of the new club within sixty (60) days if the club has obtained a court judgment in accordance with USA Swimming Rules and Regulation 203.6. If there is a court judgment, the swimmer will be Unattached until such time as the judgment has been satisfied.'
    }
  };
};

export default useMemberClubTransferMemberDetail;