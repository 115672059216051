import { isRegistrationPeriodInFuture } from '../../../utils/OfferingManagementUtils';

import EditIcon from '../../../../../../common/components/icons/EditIcon';

import { convertNumberToCurrencyString } from '../../../../../../common/utils/CurrencyUtils';
import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ offeringInstance, onEditClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{offeringInstance.offeringName || ''}</span>&nbsp;
      {isRegistrationPeriodInFuture(offeringInstance.registrationStartDate) === true &&
        <button className={global.IconButton} type="button" onClick={(e) => onEditClicked(e, offeringInstance)}><EditIcon /></button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-7 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Offering Name
        </div>
        <div className={['col-xs-5 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {offeringInstance.offeringName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-7 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Reg Start Date - Reg End Date
        </div>
        <div className={['col-xs-5 col-sm-3', global.SmallTableRowData].join(' ')}>
          {offeringInstance.registrationStartDate ? formatDate(offeringInstance.registrationStartDate) : ''} &nbsp; - &nbsp; {offeringInstance.registrationEndDate ? formatDate(offeringInstance.registrationEndDate) : ''}
        </div>
        <div className={['col-xs-7 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date - Expiration Date
        </div>
        <div className={['col-xs-5 col-sm-3', global.SmallTableRowData].join(' ')}>
          {offeringInstance.effectiveDate ? formatDate(offeringInstance.effectiveDate) : ''} &nbsp; - &nbsp; {offeringInstance.expirationDate ? formatDate(offeringInstance.expirationDate) : ''}
        </div>
      </div>
      <div className='row'>
        <div className={['col-xs-7 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Price
        </div>
        <div className={['col-xs-5 col-sm-3', global.SmallTableRowData].join(' ')}>
          ${convertNumberToCurrencyString(offeringInstance.feeAmount)}
        </div>
        <div className={['col-xs-7 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Max LSC Price
        </div>
        <div className={['col-xs-5 col-sm-3', global.SmallTableRowData].join(' ')}>
          {offeringInstance.maxLscFeeAmount === null ? 'N/A' : `$${convertNumberToCurrencyString(offeringInstance.maxLscFeeAmount)}`}
        </div>
      </div>
    </div>
  </div>
);

const OfferingInstanceGridSmall = ({ gridData, isLoading, onEditClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) === true && gridData.length > 0
        ? gridData.map((offeringInstance, i) => (
          <GridRow
            key={i}
            offeringInstance={offeringInstance}
            onEditClicked={onEditClicked} />))
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )
    }
  </div>
);

export default OfferingInstanceGridSmall;