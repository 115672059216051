import { Fragment } from 'react';

import useMemberRequriements from '../UseMemberRequriements';

import MemberRequirementsReadGrid from './MemberRequirementsReadGrid';

import MemberRequriementsFilterDropdown from '../../../../components/dropdowns/memberRequirementsFilterDropdown/MemberRequirementsFilterDropdown';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const MemberRequirementsRead = () => {
  const {
    basePersonState,
    isGridLoading,
    gridDataState,
    formState,
    errorState,
    onValueTextPairChanged,
  } = useMemberRequriements();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Requirements</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className='col-xs-12 col-sm-4 pull-right'>
          <MemberRequriementsFilterDropdown
            label="Roles"
            name="goodStandingId"
            value={formState.goodStandingId}
            error={errorState.goodStandingId}
            message={errorState.goodStandingId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'goodStandingId', newValueLabel, 'goodStandingName', e); }} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <MemberRequirementsReadGrid gridData={gridDataState} isLoading={isGridLoading} formState={formState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || isGridLoading} />
    </Fragment>
  );
};

export default MemberRequirementsRead;