import { useEffect } from 'react';

import useContactRelationshipTypeData from '../../../state/contactRelationshipType/UseContactRelationshipTypesData';

const SELF_LABEL = 'Self';

const useContactRelationshipTypeDropdown = () => {
  const { contactRelationshipTypeState, getContactRelationshipTypes } = useContactRelationshipTypeData();

  useEffect(() => {
    if (contactRelationshipTypeState.isArrayLoading === false && (contactRelationshipTypeState.isArrayLoaded === false ||
      contactRelationshipTypeState.dataAsOptionsWithNames.findIndex(x => x.name === SELF_LABEL) < 0)
    ) {
      getContactRelationshipTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRelationshipTypeState]);

  return {
    contactRelationshipTypeState
  };
};

export default useContactRelationshipTypeDropdown;