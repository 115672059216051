import { useState } from "react";

import MemberMergeToolData from "./MemberMergeToolData";

const useMemberMergeToolData = () => {
  const [memberMergeInfoState, setMemberMergeInfoState] = useState(MemberMergeToolData.INITIAL_MEMBER_MERGE_INFO_STATE);
  const [memberMergeValidationState, setMemberMergeValidationState] = useState(MemberMergeToolData.INITIAL_MEMBER_MERGE_VALIDATION_STATE);
  const [memberMergeConfirmationState, setMemberMergeConfirmationState] = useState(MemberMergeToolData.INITIAL_MEMBER_MERGE_CONFIRMATION_STATE);

  const getIsValidMergeByPersonIds = (personAId, personBId) => {
    return MemberMergeToolData.getIsValidMergeByPersonIdsData(personAId, personBId, memberMergeValidationState, setMemberMergeValidationState);
  };

  const getIsValidMergeByMemberIds = (memberAId, memberBId) => {
    return MemberMergeToolData.getIsValidMergeByMemberIdsData(memberAId, memberBId, memberMergeValidationState, setMemberMergeValidationState);
  };

  const getPersonMergeInfoByPersonIds = (personAId, personBId) => {
    return MemberMergeToolData.getPersonMergeInfoByPersonIdsData(personAId, personBId, memberMergeInfoState, setMemberMergeInfoState);
  };

  const getPersonMergeInfoByMemberIds = (memberAId, memberBId) => {
    return MemberMergeToolData.getPersonMergeInfoByMemberIdsData(memberAId, memberBId, memberMergeInfoState, setMemberMergeInfoState);
  };

  const putPersonMerge = (personAId, personBId, personMergeObj) => {
    return MemberMergeToolData.putPersonMergeData(personAId, personBId, personMergeObj, memberMergeConfirmationState, setMemberMergeConfirmationState);
  };

  return {
    memberMergeInfoState,
    memberMergeValidationState,
    memberMergeConfirmationState,

    getIsValidMergeByPersonIds,
    getIsValidMergeByMemberIds,
    getPersonMergeInfoByPersonIds,
    getPersonMergeInfoByMemberIds,
    putPersonMerge
  };
};

export default useMemberMergeToolData;