import React, { createContext, useState } from 'react';
import Constants from '../../utils/Constants';

export const PersonAcknowledgementInfoPageViewStateContext = createContext();

const INITIAL_STATE = {
  showConfirmContactInfoView: true,
  showChangeContactInfoView: false,
  showChangeContactInfoContactDetailView: false,
  showChangeContactInfoContactDetailAddressView: false,
  showChangeContactInfoContactDetailPhoneView: false,
  showSubscriptionOptInView: false,
  showAcknowledgeSwitchesView: false,
  person: {},
  personContactId: Constants.DEFAULT_ID,
  personContactAddressId: Constants.DEFAULT_ID,
  personContactPhoneId: Constants.DEFAULT_ID
};

const PersonAcknowledgementInfoPageViewContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <PersonAcknowledgementInfoPageViewStateContext.Provider value={stateHook}>
      {children}
    </PersonAcknowledgementInfoPageViewStateContext.Provider>
  );
};

export default PersonAcknowledgementInfoPageViewContextProvider;