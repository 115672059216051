import { useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MemberAffiliationsDetailValidation';

import useOrgRoleFieldData from '../../../state/orgRoleField/UseOrgRoleFieldData';
import usePersonOrgRoleAffiliationsData from '../../../state/member/memberAffiliations/UsePersonOrgRoleAffiliationsData';

import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';
import useOrgUnitData from '../../../../common/state/orgUnit/UseOrgUnitData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

import Constants from '../../../../common/utils/Constants';
import useForm from '../../../../common/utils/UseForm';
import { formatDate } from '../../../../common/utils/DateFunctions';

const useMemberAffiliationsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { USAS_ORGANIZATION_ID, FINA_ORGANIZATION_ID, environmentVariableState
  } = useEnvironmentVariableData();
  const { basePersonState } = useBasePersonData();
  const { personGeneralState } = usePersonGeneralData();
  const { orgUnitState } = useOrgUnitData();
  const { orgRoleFieldState, getOrgRoleFields, clearArrayData } = useOrgRoleFieldData();
  const { affiliationsState, postAffiliation, putAffiliation } = usePersonOrgRoleAffiliationsData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData
  } = useForm(getInitialFormState(), submitFormCallback, validate);

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_AFFILIATIONS?.route);
  };

  const onOrgUnitChanged = (orgUnit) => {
    if (orgUnit.length > 0) {
      const newOrganization = getOrganizationOfOrgUnit(orgUnit[0].id);

      if (formState.organizationId === newOrganization.organizationId) {
        setFormData({
          ...formState,
          orgUnit
        }, true);
      } else {
        // Use ...getInitialFormState instead of ...formState because orgRoleFields are added to formState based on orgRole,
        // when organizationId is updated, orgRole is cleared meaning orgRoleFields must be removed from formState
        setFormData({
          ...getInitialFormState(),
          orgUnit,
          organizationId: newOrganization.organizationId,
          organizationName: newOrganization.organizationName,
          effectiveDate: newOrganization.organizationId === FINA_ORGANIZATION_ID ? getPersonBirthDate() : Constants.BLANK_DATE_STRING
        }, true);
        clearArrayData();
      }
    } else {
      setFormData({
        ...formState,
        orgUnit,
        orgRole: [],
        organizationId: Constants.DEFAULT_ID,
        organizationName: ''
      }, true);
      clearArrayData();
    }
  };

  const onOrgRoleChanged = (orgRole) => {
    if (orgRole.length > 0) {
      getOrgRoleFields(orgRole[0].id);
    } else {
      clearArrayData();
    }

    // Use ...getInitialFormState instead of ...formState because orgRoleFields are added to formState based on orgRole,
    // when orgRole changes they must be removed from formState
    setFormData({
      ...getInitialFormState(),
      orgRole,
      orgUnit: formState.orgUnit,
      organizationId: formState.organizationId,
      organizationName: formState.organizationName,
      effectiveDate: formState.effectiveDate
    }, true);
  };

  // Sets initial formState for edit
  useEffect(() => {
    if (location.state?.personOrgRoleAffiliation && personGeneralState.isObjLoaded === true
      && environmentVariableState.isLoaded === true) {
      const personOrgRoleAffiliation = location.state.personOrgRoleAffiliation;

      const orgRoleFieldValues = {};
      if (Array.isArray(personOrgRoleAffiliation.personOrgRoleFields)
        && personOrgRoleAffiliation.personOrgRoleFields.length > 0) {
        for (const orgRoleField of personOrgRoleAffiliation.personOrgRoleFields) {
          orgRoleFieldValues[orgRoleField.orgRoleFieldName] = orgRoleField.fieldValue;
        }
      }

      if (personOrgRoleAffiliation.orgRoleId) {
        getOrgRoleFields(personOrgRoleAffiliation.orgRoleId);
      }

      setFormData({
        ...formState,
        ...orgRoleFieldValues,
        orgUnit: [{ id: personOrgRoleAffiliation.orgUnitId, name: personOrgRoleAffiliation.orgUnitName }],
        orgRole: [{ id: personOrgRoleAffiliation.orgRoleId, name: personOrgRoleAffiliation.orgRoleName }],
        organizationId: personOrgRoleAffiliation.organizationId,
        organizationName: personOrgRoleAffiliation.organizationName,
        effectiveDate: formatDate(personOrgRoleAffiliation.effectiveDate),
        expirationDate: formatDate(personOrgRoleAffiliation.expirationDate)
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personGeneralState.isObjLoaded, environmentVariableState.isLoaded]);

  // Redirects on successful save
  useEffect(() => {
    if (affiliationsState.isSaving === false && affiliationsState.isSaved === true) {
      navigate(navRoutes.MEMBER_AFFILIATIONS.route, { state: { refreshGeneral: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliationsState]);

  // Add orgRoleFields to formState with defaultValue, only on Add
  useEffect(() => {
    if (orgRoleFieldState.isArrayLoaded === true && orgRoleFieldState.isArrayLoading === false
      && !location.state?.personOrgRoleAffiliation) {
      if (orgRoleFieldState.arrayData.length > 0) {
        const orgRoleFieldValues = {};

        for (const orgRoleField of orgRoleFieldState.arrayData) {
          orgRoleFieldValues[orgRoleField.fieldName] = orgRoleField.defaultValue;
        }

        setFormData({
          ...formState,
          ...orgRoleFieldValues
        }, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgRoleFieldState, location.state]);

  function submitFormCallback(formState) {
    const personOrgRoleDurationId = location.state?.personOrgRoleAffiliation?.personOrgRoleDurationId;
    const personOrgRoleId = location.state?.personOrgRoleAffiliation?.personOrgRoleId;
    const personOrgRoleFields = location.state?.personOrgRoleAffiliation?.personOrgRoleFields;

    const affiliationObj = createAffiliationObj(formState, orgRoleFieldState.arrayData, personOrgRoleId, personOrgRoleDurationId, personOrgRoleFields);

    if (personOrgRoleDurationId) {
      putAffiliation(affiliationObj);
    } else {
      postAffiliation(affiliationObj);
    }
  };

  function createAffiliationObj(formState, orgRoleFieldStateData, personOrgRoleId = undefined, personOrgRoleDurationId = undefined, personOrgRoleFields = undefined) {
    const previousPersonOrgRoleFields = Array.isArray(personOrgRoleFields) ? personOrgRoleFields : [];
    const newPersonOrgRoleFields = [];

    for (const orgRoleField of orgRoleFieldStateData) {
      const personOrgRoleFieldId = previousPersonOrgRoleFields.find(x => x.orgRoleFieldId === orgRoleField.orgRoleFieldId)?.personOrgRoleFieldId;

      newPersonOrgRoleFields.push({
        personOrgRoleFieldId,
        orgRoleFieldId: orgRoleField.orgRoleFieldId,
        fieldValue: formState[orgRoleField.fieldName]
      });
    }

    return {
      personId: personGeneralState.objData?.personId,
      personOrgRoleId,
      personOrgRoleDurationId,
      orgUnitId: formState.orgUnit[0].id,
      orgRoleId: formState.orgRole[0].id,
      effectiveDate: formState.effectiveDate,
      personOrgRoleFields: newPersonOrgRoleFields
    };
  };

  function getOrganizationOfOrgUnit(orgUnitId) {
    // Only looks for single match in leaves 
    const getSelectedOrgUnitRecurse = (orgUnits) => {
      let result;

      for (const orgUnit of orgUnits) {
        if (!result) {
          if (Array.isArray(orgUnit.children) && orgUnit.children.length > 0) {
            result = getSelectedOrgUnitRecurse(orgUnit.children);
          } else if (orgUnit.id === orgUnitId) {
            return orgUnit;
          }
        } else {
          return result;
        }
      }

      return null;
    };

    for (const organization of orgUnitState.arrayData) {
      if (Array.isArray(organization.children) && organization.children.length > 0
        && organization.organizationId !== USAS_ORGANIZATION_ID) { //skip usas orgUnits to save time
        const targetOrgUnit = getSelectedOrgUnitRecurse(organization.children);

        if (targetOrgUnit) {
          return {
            organizationId: organization.organizationId,
            organizationName: organization.name
          };
        }
      }
    }

    return {
      organizationId: Constants.DEFAULT_ID,
      organizationName: ''
    };
  };

  function getPersonBirthDate() {
    const birthDate = basePersonState.objData.birthDate;

    if (birthDate) {
      return formatDate(birthDate);
    } else {
      return Constants.BLANK_DATE_STRING;
    }
  };

  function getInitialFormState() {
    return {
      orgUnit: [],
      orgRole: [],
      organizationId: Constants.DEFAULT_ID,
      organizationName: '',
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING
    };
  };

  return {
    isSaving: affiliationsState.isSaving,
    isLoading: personGeneralState.isObjLoading || orgUnitState.isArrayLoading || environmentVariableState.isLoading || orgRoleFieldState.isArrayLoading,
    isEdit: location.state?.personOrgRoleAffiliation ? true : false,
    apiError: affiliationsState.isSaving === false && affiliationsState.message ? true : false,
    Constants,
    formState,
    errorState,
    personGeneralState,
    orgUnitState,
    orgRoleFieldState,
    onFormValueChanged: updateFormState,
    handleSubmit,
    onCancelClicked,
    onOrgUnitChanged,
    onOrgRoleChanged
  };
};

export default useMemberAffiliationsDetail;