import { useContext } from "react";

import { OfferingManagementStateContext } from "./OfferingManagementContextProvider";
import { OfferingManagementInstanceStateContext } from "./OfferingManagementInstanceContextProvider";

import OfferingManagementData from "./OfferingManagementData";

const useOfferingManagementData = () => {
  const [offeringManagementState, setOfferingManagementState] = useContext(OfferingManagementStateContext);
  const [offeringManagementInstanceState, setOfferingManagementInstanceState] = useContext(OfferingManagementInstanceStateContext);

  const postOffering = (offeringObj) => {
    return OfferingManagementData.postOfferingData(offeringObj, offeringManagementState, setOfferingManagementState);
  };

  const postOfferingInstance = (offeringInstanceObj) => {
    return OfferingManagementData.postOfferingInstanceData(offeringInstanceObj, offeringManagementInstanceState, setOfferingManagementInstanceState);
  };

  const getOfferings = () => {
    // Note: objData is used exclusively for POST/PUT/DELETE,
    // Should be cleared when getting new arrayData context
    const newOfferingManagementState = {
      ...offeringManagementState,
      isObjLoaded: false,
      isObjLoading: false,
      objData: {}
    };

    return OfferingManagementData.getOfferingsData(newOfferingManagementState, setOfferingManagementState);
  };

  const getOfferingInstances = (reportPeriodId) => {
    // Note: objData is used exclusively for POST/PUT/DELETE
    // Should be cleared when getting new arrayData context
    const newOfferingManagementInstanceState = {
      ...offeringManagementInstanceState,
      isObjLoaded: false,
      isObjLoading: false,
      objData: {}
    };

    return OfferingManagementData.getOfferingInstancesData(reportPeriodId, newOfferingManagementInstanceState, setOfferingManagementInstanceState);
  };

  const putOffering = (offeringId, offeringObj) => {
    return OfferingManagementData.putOfferingData(offeringId, offeringObj, offeringManagementState, setOfferingManagementState);
  };

  const putOfferingInstance = (offeringInstanceId, offeringInstanceObj) => {
    return OfferingManagementData.putOfferingInstanceData(offeringInstanceId, offeringInstanceObj, offeringManagementInstanceState, setOfferingManagementInstanceState);
  };

  const deleteOffering = (offeringId) => {
    return OfferingManagementData.deleteOfferingData(offeringId, offeringManagementState, setOfferingManagementState);
  };

  const deleteOfferingInstance = (offeringInstanceId) => {
    return OfferingManagementData.deleteOfferingInstanceData(offeringInstanceId, offeringManagementInstanceState, setOfferingManagementInstanceState);
  };

  const resetOfferingManagementState = () => {
    setOfferingManagementState({
      ...OfferingManagementData.INITIAL_OFFERING_MANAGEMENT_STATE
    });
  };

  const resetOfferingManagementInstanceState = () => {
    setOfferingManagementInstanceState({
      ...OfferingManagementData.INITIAL_OFFERING_MANAGEMENT_INSTANCE_STATE
    });
  };

  return {
    offeringManagementState,
    offeringManagementInstanceState,

    postOffering,
    getOfferings,
    putOffering,
    deleteOffering,

    postOfferingInstance,
    getOfferingInstances,
    putOfferingInstance,
    deleteOfferingInstance,

    resetOfferingManagementState,
    resetOfferingManagementInstanceState
  };
};

export default useOfferingManagementData;