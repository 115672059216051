import style from '../MemberMergeTool.module.css';

const GridRow = ({ mergeField }) => (
  <tr>
    <th>{mergeField.valueLabel}</th>
    <td>{mergeField.displayValue}</td>
  </tr>
);

const MemberMergeConfirmationGrid = ({ gridData, isLoading }) => (
  <table className={style.MergeConfirmationTable}>
    <tbody>
      {isLoading
        ? <tr><td colSpan={2}>Loading...</td></tr>
        : gridData.map((mergeField, i) => <GridRow key={i} mergeField={mergeField} />)
      }
    </tbody>
  </table>
);

export default MemberMergeConfirmationGrid;