import { useContext } from 'react';

import { OfficialPositionTypeStateContext } from './OfficialPositionTypeContextProvider';

import OfficialPositionTypeData from './OfficialPositionTypeData';

const useOfficialPositionTypeData = () => {
  const [officialPositionTypeState, setOfficialPositionTypeState] = useContext(OfficialPositionTypeStateContext);

  const getOfficialPositionTypes = (orgLevelId, hasEvaluationForm) => OfficialPositionTypeData.getOfficialPositionTypeData(orgLevelId, hasEvaluationForm, officialPositionTypeState, setOfficialPositionTypeState);

  const clearOfficialPositionTypeObjData = () => {
    setOfficialPositionTypeState({
      ...officialPositionTypeState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOfficialPositionTypeArrayData = () => {
    setOfficialPositionTypeState({
      ...officialPositionTypeState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: '',
      options: [],
      orgLevelId: '',
      hasEvaluationForm: ''
    });
  };

  const confirmOfficialPositionTypeSave = () => {
    setOfficialPositionTypeState({
      ...officialPositionTypeState,
      isSaved: false
    });
  };

  return {
    officialPositionTypeState,
    getOfficialPositionTypes,
    clearOfficialPositionTypeObjData,
    clearOfficialPositionTypeArrayData,
    confirmOfficialPositionTypeSave
  };
};

export default useOfficialPositionTypeData;