import React, { Fragment } from 'react';

import MemberClubTransferMemberGridLarge from './MemberClubTransferMemberGridLarge';
import MemberClubTransferMemberGridSmall from './MemberClubTransferMemberGridSmall';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MemberClubTransferMemberGrid = ({ gridData, isLoading, onEdit }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MemberClubTransferMemberGridLarge
        gridData={gridData}
        expandedId={expandedId}
        isLoading={isLoading}
        onEdit={onEdit}
        onExpandClicked={onClick} />
      <MemberClubTransferMemberGridSmall
        gridData={gridData}
        expandedId={expandedId}
        isLoading={isLoading}
        onEdit={onEdit}
        onExpandClicked={onClick} />
    </Fragment>
  );
};

export default MemberClubTransferMemberGrid;

