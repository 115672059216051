import { useContext } from 'react';

import { OtsMeetOfficialsStateContext } from './OtsMeetOfficialsContextProvider';

import OtsMeetOfficialsData from './OtsMeetOfficialsData';

import Constants from '../../../common/utils/Constants';

const useOtsMeetOfficialsData = () => {
  const [otsMeetOfficialsState, setOtsMeetOfficialsState] = useContext(OtsMeetOfficialsStateContext);

  const getMeetOfficials = (otsMeetId, officialPositionTypeId, hasEvaluator) => OtsMeetOfficialsData.getMeetOfficialData(otsMeetId, officialPositionTypeId, hasEvaluator, otsMeetOfficialsState, setOtsMeetOfficialsState);
  const putMeetOfficial = (otsMeetId, meetOfficialObj) => OtsMeetOfficialsData.putMeetOfficialData(otsMeetId, meetOfficialObj, otsMeetOfficialsState, setOtsMeetOfficialsState);

  const clearOtsMeetOfficialsObjData = () => {
    setOtsMeetOfficialsState({
      ...otsMeetOfficialsState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOtsMeetOfficialsArrayData = () => {
    setOtsMeetOfficialsState({
      ...otsMeetOfficialsState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: '',
      otsMeetId: Constants.DEFAULT_ID,
      officialPositionTypeId: Constants.DEFAULT_ID,
      hasEvaluator: ''
    });
  };

  const confirmOtsMeetOfficialSave = () => {
    setOtsMeetOfficialsState({
      ...otsMeetOfficialsState,
      isSaved: false
    });
  };

  return {
    otsMeetOfficialsState,
    getMeetOfficials,
    putMeetOfficial,
    clearOtsMeetOfficialsObjData,
    clearOtsMeetOfficialsArrayData,
    confirmOtsMeetOfficialSave
  };
};

export default useOtsMeetOfficialsData;