import { useState } from 'react';

import AffiliationsOrgRoleData from './AffiliationsOrgRoleData';

const useAffiliationsOrgRoleData = () => {
  const [affiliationsOrgRoleState, setAffiliationsOrgRoleState] = useState(AffiliationsOrgRoleData.INITIAL_STATE);

  const getAffiliationsOrgRolesByOrganizationId = (organizationId) => AffiliationsOrgRoleData.getAffiliationsOrgRolesByOrganizationId(organizationId, affiliationsOrgRoleState, setAffiliationsOrgRoleState);

  return {
    affiliationsOrgRoleState,
    getAffiliationsOrgRolesByOrganizationId
  };
};

export default useAffiliationsOrgRoleData;