import React, { createContext, useState } from 'react';

import OfficialMentorLevelData from './OfficialMentorLevelData';

export const OfficialMentorLevelStateContext = createContext();

const OfficialMentorLevelContextProvider = ({ children }) => {
  const stateHook = useState(OfficialMentorLevelData.INITIAL_STATE);

  return (
    <OfficialMentorLevelStateContext.Provider value={stateHook}>
      {children}
    </OfficialMentorLevelStateContext.Provider>
  );
};

export default OfficialMentorLevelContextProvider;