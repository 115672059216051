import { useState } from 'react';

import PersonCancelMembershipData from './PersonCancelMembershipData';

const usePersonCancelMembershipData = () => {
  const [personCancelMembershipState, setPersonCancelMembershipState] = useState(PersonCancelMembershipData.INITIAL_STATE);

  const getPersonCancelMembership = (personId) => {
    PersonCancelMembershipData.getPersonCancelMembership(personId, personCancelMembershipState, setPersonCancelMembershipState);
  };

  const deletePersonMembership = (personId) => {
    PersonCancelMembershipData.deletePersonMembership(personId, personCancelMembershipState, setPersonCancelMembershipState);
  };

  return {
    personCancelMembershipState,
    setPersonCancelMembershipState,
    getPersonCancelMembership,
    deletePersonMembership
  };
};

export default usePersonCancelMembershipData;