import useOrganizationData from '../../../common/state/organization/UseOrganizationData';

const useQuickAddOrganizationDropdown = () => {
  const { organizationState } = useOrganizationData();

  return {
    organizationState
  };
};

export default useQuickAddOrganizationDropdown;