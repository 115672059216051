import React, { Fragment } from 'react';

import ProfileSocialMediaLargeGrid from './ProfileSocialMediaLargeGrid';
import ProfileSocialMediaSmallGrid from './ProfileSocialMediaSmallGrid';

const ProfileSocialMediaGrid = ({ gridState, onAddEdit, onDelete }) => {

  return (
    <Fragment>
      <ProfileSocialMediaLargeGrid
        gridState={gridState}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
      />
      <ProfileSocialMediaSmallGrid
        gridState={gridState}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
      />
    </Fragment>
  );
};

export default ProfileSocialMediaGrid;