import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getPersonGeneralNationalTeam from './GetPersonGeneralNationalTeamData';
import putPersonGeneralNationalTeam from './PutPersonGeneralNationalTeamData';
import deletePersonNataionalTeamContactPhone from './DeletePersonNationalTeamContactPhoneData';
import deletePersonNationalTeamContactAddress from './DeletePersonNationalTeamContactAddressData';
import deletePersonNationalTeamContact from './DeletePersonNationalTeamContactData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonGeneralNationalTeamData = {
  INITIAL_STATE: { ...INITIAL_STATE }, 
  getPersonGeneralNationalTeam, putPersonGeneralNationalTeam, deletePersonNataionalTeamContactPhone, deletePersonNationalTeamContactAddress, deletePersonNationalTeamContact
};

export default PersonGeneralNationalTeamData;