import { useEffect, useState } from 'react';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

const INITIAL_VIEW_STATE = {
  reportParameters: { personId: '' },
  routeName: ''
};

const useMemberReporting = () => {
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });
  const { basePersonState } = useBasePersonData();

  useEffect(() => {
    if (basePersonState.objData?.personId) {
      setViewState({
        ...viewState,
        reportParameters: { personId: basePersonState.objData?.personId },
        contextId: basePersonState.objData?.personId,
        routeName: 'MEMBER_REPORTING'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState.objData]);

  return { ...viewState };
};

export default useMemberReporting;