import useOrgRoleStaffRoleTreeView from './UseOrgRoleStaffRoleTreeView';

import TreeView from '../TreeView';

import global from '../../GlobalStyle.module.css';

const OrgRoleStaffRoleTreeView = ({ label, name, value, error, message, onChange, singleSelect, roleGroupId }) => {
  const { orgRoleStaffRoleHierarchyState } = useOrgRoleStaffRoleTreeView(roleGroupId);

  return orgRoleStaffRoleHierarchyState.message
    ? <span className={global.LoadingMsg}>{orgRoleStaffRoleHierarchyState.message}</span>
    : (
      <TreeView
        label={label}
        name={name}
        value={value}
        options={orgRoleStaffRoleHierarchyState.treeData}
        error={error}
        message={message}
        isLoading={orgRoleStaffRoleHierarchyState.isArrayLoading}
        singleSelect={singleSelect}
        selectableLeavesOnly={true}
        onChange={onChange} />
    );
};

export default OrgRoleStaffRoleTreeView;