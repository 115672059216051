import { useContext, useState } from 'react';

import { PersonNationalTeamStateContext } from './PersonNationalTeamContextProvider';
import PersonNationalTeamData from './PersonNationalTeamData';

const usePersonNationalTeamData = () => {
  const [personNationalTeamState, setPersonNationalTeamState] = useContext(PersonNationalTeamStateContext);
  const [personNationalTeamAdvancedState, setPersonNationalTeamAdvancedState] = useState(PersonNationalTeamData?.INITIAL_STATE);
  const [personNationalTeamAttachmentState, setPersonNationalTeamAttachmentState] = useState(PersonNationalTeamData?.INITIAL_STATE);
  const [deletedPersonNationalTeamAttachmentState, setDeletedPersonNationalTeamAttachmentState] = useState(PersonNationalTeamData?.INITIAL_STATE);

  const getPersonNationalTeam = (personId) => PersonNationalTeamData.getPersonNationalTeamData(personId, personNationalTeamState, setPersonNationalTeamState);
  const putPersonNationalTeam = (personId, personObj) => PersonNationalTeamData.putPersonNationalTeamData(personId, personObj, personNationalTeamState, setPersonNationalTeamState);
  const getPersonNationalTeamAdvanced = (personId) => PersonNationalTeamData.getPersonNationalTeamAdvanced(personId, personNationalTeamAdvancedState, setPersonNationalTeamAdvancedState);
  const putPersonNationalTeamAdvanced = (personId, personObj) => PersonNationalTeamData.putPersonNationalTeamAdvanced(personId, personObj, personNationalTeamAdvancedState, setPersonNationalTeamAdvancedState);

  const getPersonNationalTeamAttachment = () => PersonNationalTeamData.getPersonNationalTeamAttachment(personNationalTeamAttachmentState, setPersonNationalTeamAttachmentState);
  const getPersonNationalTeamAttachmentsByPersonId = (personId) => PersonNationalTeamData.getPersonNationalTeamAttachmentsByPersonId(personId, personNationalTeamAttachmentState, setPersonNationalTeamAttachmentState);
  const putPersonNationalTeamAttachmentById = (attachmentId, personObj) => PersonNationalTeamData.putPersonNationalTeamAttachmentById(attachmentId, personObj, personNationalTeamAttachmentState, setPersonNationalTeamAttachmentState);
  const deletePersonNationalTeamAttachmentById = (attatchmentId) => PersonNationalTeamData.deletePersonNationalTeamAttachmentById(attatchmentId, deletedPersonNationalTeamAttachmentState, setDeletedPersonNationalTeamAttachmentState);
  const postPersonNationalTeamAttachment = (attachmentObj) => PersonNationalTeamData.postPersonNationalTeamAttachment(attachmentObj, personNationalTeamAttachmentState, setPersonNationalTeamAttachmentState);

  const clearObjData = () => {
    setPersonNationalTeamState({
      ...personNationalTeamState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearArrayData = () => {
    setPersonNationalTeamState({
      ...personNationalTeamState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSave = () => {
    setPersonNationalTeamState({
      ...personNationalTeamState,
      isSaved: false
    });
  };

  return {
    personNationalTeamState,
    clearObjData,
    clearArrayData,
    confirmSave,
    getPersonNationalTeam,
    putPersonNationalTeam,
    getPersonNationalTeamAdvanced,
    putPersonNationalTeamAdvanced,
    personNationalTeamAdvancedState,
    setPersonNationalTeamAdvancedState,
    getPersonNationalTeamAttachment,
    getPersonNationalTeamAttachmentsByPersonId,
    putPersonNationalTeamAttachmentById,
    deletePersonNationalTeamAttachmentById,
    personNationalTeamAttachmentState,
    setPersonNationalTeamAttachmentState,
    deletedPersonNationalTeamAttachmentState,
    setDeletedPersonNationalTeamAttachmentState,
    postPersonNationalTeamAttachment
  };
};

export default usePersonNationalTeamData;