import React, { Fragment } from 'react';

import useMemberAudit from './UseMemberAudit';

import MemberAuditGrid from './MemberAuditGrid';

import Headings from '../../../../common/components/headings/Headings';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const MemberAudit = () => {
  const { title, Constants, personAuditState, basePersonState } = useMemberAudit();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{title}</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <MemberAuditGrid state={personAuditState} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || personAuditState.isLoading} />
    </Fragment>
  );
};

export default MemberAudit;