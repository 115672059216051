import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

const GetCertificationLevelData = (orgLevelId, officialPositionTypeId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...',
      orgLevelId: orgLevelId,
      officialPositionTypeId: officialPositionTypeId
    };
    setState(newState);

    const orgLevelIdForUrl = orgLevelId ? encodeURIComponent(orgLevelId) : '';
    const officialPositionTypeIdForUrl = officialPositionTypeId ? encodeURIComponent(officialPositionTypeId) : '';
    const url = orgLevelIdForUrl || officialPositionTypeIdForUrl ? `/CertificationLevel?orgLevelId=${orgLevelIdForUrl}&officialPositionTypeId=${officialPositionTypeIdForUrl}` : `/CertificationLevel`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const data = JSON.parse(JSON.stringify(arrayData));
          const blankPosition = { certificationLevelId: Constants.DEFAULT_ID };
          data.unshift(blankPosition);
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options: data.map((certificationLevel) => { return { id: certificationLevel.certificationLevelId, name: certificationLevel.certificationLevelId !== Constants.DEFAULT_ID ? `${certificationLevel.levelName || ''}` : '--' } }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetCertificationLevelData;