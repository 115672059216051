import { Fragment } from 'react';

import useHqOffering from './UseHqOffering';

import HqOfferingManagementContextLoadingModal from '../HqOfferingManagementContextLoadingModal';

import OfferingGrid from '../../components/grids/offering/OfferingGrid';

import Headings from '../../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

const HqOffering = () => {
  const {
    isLoading,
    state,
    offeringManagementState,
    onCreateOfferingClicked,
    onEditOfferingClicked
  } = useHqOffering();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <Headings.H3>HQ Offerings</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={onCreateOfferingClicked}>Create Offering Type</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <OfferingGrid
            gridData={offeringManagementState.arrayData}
            isLoading={offeringManagementState.isArrayLoading}
            onEditClicked={onEditOfferingClicked} />
        </div>
      </div>
      <HqOfferingManagementContextLoadingModal
        isLoading={isLoading}
        isError={state.pageErrorMessage !== ''}
        errorMessage={state.pageErrorMessage} />
    </Fragment>
  );
};

export default HqOffering;