import useSearch from './UseSearch';

import Grid from './Grid';
import AtoZFilter from '../../components/AtoZFilter';

import Headings from '../../../../../common/components/headings/Headings';
import LoadingModal from '../../../../../common/components/dialogs/LoadingModal';

const Search = ({ activeOfficialsWithApplications, isLoading, buttonClickHandlers }) => {
  const { gridData, lastNameFilter, alphabeticalFilterClicked, localOtsCertificationApplicationState } = useSearch(activeOfficialsWithApplications);

  return (
    <>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>Officials with Active Applications</Headings.H3>
        </div>
        <div className="col-xs-12">
          <p>
            These are the officials who are in the application process for national certification.
            You may review their application by clicking on the edit icon.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {(isLoading === true || localOtsCertificationApplicationState.isArrayLoading === true) && <LoadingModal />}
          <AtoZFilter lastNameFilter={lastNameFilter} alphabeticalFilterClicked={alphabeticalFilterClicked}/>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
          <Grid gridData={gridData} onSelectClicked={buttonClickHandlers.onSelectClicked} />
        </div>
      </div>
    </>
  );
};

export default Search;