import React, { Fragment } from 'react';

import NationalTeamSponsorsLargeGrid from './NationalTeamSponsorsLargeGrid';
import NationalTeamSponsorsSmallGrid from './NationalTeamSponsorsSmallGrid';

const NationalTeamSponsorsGrid = ({ gridState, onAddEdit, onDelete }) => {

  return (
    <Fragment>
      <NationalTeamSponsorsLargeGrid
        gridState={gridState}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
      />
      <NationalTeamSponsorsSmallGrid
        gridState={gridState}
        onAddEdit={onAddEdit}
        onDelete={onDelete}
      />
    </Fragment>
  )
};

export default NationalTeamSponsorsGrid;