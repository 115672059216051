import React, { Fragment } from 'react';

import HideIcon from '../../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../../common/components/icons/ShowIcon';
import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';
import { formatDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const DetailTableRowCourse = ({ moreInfo }) => {
  return (
    <tr>
      <td>{`${moreInfo.sessionOrderId || ''} (${moreInfo.sessionTypeName || ''} ${moreInfo.sessionDate ? formatDate(moreInfo.sessionDate) : ''})`}</td>
      <td>{moreInfo?.isOfficialsQualifyingSession === true ? 'Yes' : 'No'}</td>
      <td>{`${moreInfo.roleTypeName || ''} (${moreInfo.roleTypeAbbreviation || ''})`}</td>
      <td>{moreInfo?.isApprentice === true ? 'Apprentice' : ''}</td>
    </tr >
  );
}

const GridRowDetailTableCourses = ({ official }) => (
  <td colSpan="7">
    <table className={global.DetailTable}>
      <thead>
        <tr>
          <th>Session</th>
          <th>OQM Session?</th>
          <th>Role Worked</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(official?.sessions) && official?.sessions?.length > 0
          ? official?.sessions?.map((moreInfo, i) =>
            <DetailTableRowCourse
              key={i}
              moreInfo={moreInfo} />)
          : <tr><td colSpan="7">No Additional Info</td></tr>
        }
      </tbody>
    </table>
  </td>
);

const LargeGridRow = ({ official, expandedId, onExpandClicked }) => {
  return (
    <Fragment>
      <tr>
        <td>{official?.meetName}</td>
        <td>{official?.meetClassificationTypeName}</td>
        <td>{official?.oqmNumber}</td>
        <td>{formatDate(official?.startDate)} - {formatDate(official?.endDate)}</td>
        <td>{official?.sessionsNumber >= 0 ? official?.sessionsNumber : ''}</td>
        <td>{official?.officialRoleTypeName}</td>
        <td>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onExpandClicked(e, official?.meetId)}>
            {expandedId === official?.meetId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {expandedId === official?.meetId &&
        <Fragment>
          <tr className={global.Expanded}>
            <GridRowDetailTableCourses official={official} />
          </tr>
        </Fragment >
      }
    </Fragment >
  );
}

const OtsOfficialMeetHistoryLargeWriteGrid = ({ state, expandedId, onExpandClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Classification'} columnField={'meetClassificationTypeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '110px' }} sortableGridState={sortableGridState} columnLabel={'OQM Number'} columnField={'oqmNumber'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '180px' }} sortableGridState={sortableGridState} columnLabel={'Start - End Date'} columnField={'startDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '100px' }} sortableGridState={sortableGridState} columnLabel={'Sessions'} columnField={'sessionsNumber'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '100px' }} sortableGridState={sortableGridState} columnLabel={'Roles'} columnField={'officialRoleTypeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sortableGridState.sortedGridData?.length > 0 ?
          sortableGridState.sortedGridData?.map((official, i) =>
            <LargeGridRow
              key={i}
              official={official}
              expandedId={expandedId}
              onExpandClicked={onExpandClicked} />)
          : state?.isArrayLoading
            ? <tr><td colSpan="7">Loading...</td></tr>
            : <tr><td colSpan="7">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default OtsOfficialMeetHistoryLargeWriteGrid