import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getOrgUnitAthleteData from './GetOrgUnitAthleteData';
import getOrgUnitAthletePiiSafeData from './GetOrgUnitAthletePiiSafeData';
import postOrgUnitAthleteData from './PostOrgUnitAthleteData';
import postOrgUnitAthleteRosterRolloverData from './PostOrgUnitAthleteRosterRolloverData';
import putNcaaAthleteData from './PutNcaaAthleteData';
import deleteOrgUnitAthleteData from './DeleteOrgUnitAthleteData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OrgUnitAthleteData = { 
  INITIAL_STATE, 
  getOrgUnitAthleteData, 
  getOrgUnitAthletePiiSafeData,
  postOrgUnitAthleteData, 
  postOrgUnitAthleteRosterRolloverData, 
  putNcaaAthleteData, 
  deleteOrgUnitAthleteData };

export default OrgUnitAthleteData;