import { useEffect, useState } from 'react';
import { useLocation } from '../../../common/wrappers/ReactRouterDom';

import validate from './SearchOtsMeetValidation';

import useOtsMeetData from '../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useSearchOtsMeetData from '../../state/searchOfficialsTrackingSystemMeet/UseSearchOtsMeetData';

import useMeetClassificationData from '../../../common/state/meet/meetClassification/UseMeetClassificationData';
import useEnvironmentVariableData from '../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from '../../../common/state/security/UseSecurityData';
import useZoneData from '../../../common/state/zone/UseZoneData';

import useForm from '../../../common/utils/UseForm';
import Constants from '../../../common/utils/Constants';
import HierarchicalDataUtils from '../../../common/utils/HierarchicalDataUtils';

const sortByOptions = [
  { id: 1, name: "Meet Date (Most Recent to Oldest)", value: "MeetStartDate desc" },
  { id: 2, name: "Meet Name (A-Z)", value: 'MeetName' }];

const useSearchOtsMeet = (selectOrgUnitState) => {
  const location = useLocation();
  const { otsMeetState, otsMeetHeaderState, searchOtsMeets, otsMeetFiltersState,
    clearOtsMeetObjData, clearOtsMeetHeaderObjData, clearOtsMeetArrayData } = useOtsMeetData();
  const { searchOtsMeetState, setSearchOtsMeetState } = useSearchOtsMeetData();
  const { meetClassificationState } = useMeetClassificationData();
  const { zoneState } = useZoneData();
  const { USAS_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { contextSecurityState, resetContextSecurityState } = useSecurityData();
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors, onValueTextPairChanged,
    setIsDirty, isDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [tryPreviousSearch, setTryPreviousSearch] = useState(true);

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onFiltersToggle = () => {
    setSearchOtsMeetState({ ...searchOtsMeetState, showFilters: !searchOtsMeetState.showFilters });
    setIsDirty(true);
  };

  const onZoneOrgUnitChanged = (newValue, newValueLabel) => {
    setFormState({
      ...formState,
      zoneOrgUnitId: newValue,
      zoneName: newValueLabel,
      lscOrgUnitId: Constants.DEFAULT_ID,
      lscName: '',
      orgUnitId: '',
      orgUnitName: '',
      meetClassification: []
    });

    setIsDirty(true);
  };

  const onLscOrgUnitChanged = (newValue, newValueLabel, e) => {
    if (e) {
      setFormState({
        ...formState,
        lscOrgUnitId: newValue,
        lscName: newValueLabel,
        orgUnitId: '',
        orgUnitName: '',
        meetClassification: []
      });

      setIsDirty(true);
    }
  };

  const onOrgUnitChanged = (newValue, newValueLabel) => {
    setFormState({
      ...formState,
      orgUnitId: newValue !== Constants.DEFAULT_ID ? newValue : '',
      orgUnitName: newValueLabel,
      meetClassification: []
    });

    setIsDirty(true);
  };

  useEffect(() => {
    //Clear out any prior data
    clearOtsMeetObjData();
    clearOtsMeetHeaderObjData();
    clearOtsMeetArrayData();
    resetContextSecurityState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formState.lscOrgUnitId === Constants.DEFAULT_ID && formState.orgUnitLabel !== Constants.USAS_ORG_UNIT_LABEL_LSC) {
      setFormState({
        ...formState,
        orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL_LSC
      });
    }
    else if ((selectOrgUnitState || formState.lscOrgUnitId !== Constants.DEFAULT_ID) && formState.orgUnitLabel !== Constants.USAS_ORG_UNIT_LABEL) {
      setFormState({
        ...formState,
        orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.lscOrgUnitId, selectOrgUnitState]);

  useEffect(() => {
    if (Object.keys(otsMeetFiltersState.filterObject).length > 0 && tryPreviousSearch === true
      && meetClassificationState.isArrayLoaded === true && location.state && location.state?.keepSearchData === true) {
      const oldSearchValues = JSON.parse(JSON.stringify(otsMeetFiltersState.filterObject));

      let sortByIndex = undefined;
      for (let i = 0; i < sortByOptions.length; i++) {
        if (otsMeetFiltersState.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }

      setIsDirty(true);
      setTryPreviousSearch(false);
      setFormState({
        ...formState,
        ...oldSearchValues,
        meetClassification: HierarchicalDataUtils.GetNameIdPairs(meetClassificationState.treeData, [oldSearchValues.meetClassificationId]) || [],
        sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
        sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
      });

      if (otsMeetState.isArrayLoading === false) {
        searchOtsMeets(otsMeetFiltersState.filterObject, otsMeetFiltersState.sortBy || '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetClassificationState.isArrayLoaded]);

  useEffect(() => {
    if (searchOtsMeetState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOtsMeetState.showFilters, isDirty]);

  function submitFormCallback(formState) {
    setSearchOtsMeetState({ ...searchOtsMeetState, tryRedirect: false });

    let sortBy = undefined;
    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    if (otsMeetState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchOtsMeets(filterObject, sortBy || '');
    }
  };

  function createSearchFilterObj(formState) {
    return {
      meetName: formState.meetName.trim() || undefined,
      sanctionNumber: formState.sanctionNumber.trim() || undefined,
      oqmNumber: formState.oqmNumber.trim() || undefined,
      meetClassificationId: (formState.meetClassification !== undefined && formState.meetClassification.length > 0) ? formState.meetClassification[0].id : undefined,
      zoneOrgUnitId: formState.zoneOrgUnitId !== Constants.DEFAULT_ID ? formState.zoneOrgUnitId : undefined,
      lscOrgUnitId: selectOrgUnitState ? selectOrgUnitState.orgUnitId : formState.lscOrgUnitId !== Constants.DEFAULT_ID ? formState.lscOrgUnitId : undefined,
      orgUnitId: formState.orgUnitId ? formState.orgUnitId : undefined,
      orgUnitName: formState.orgUnitName.trim() || undefined,
      startDate: formState.startDate !== Constants.BLANK_DATE_STRING ? formState.startDate : undefined,
      endDate: formState.endDate !== Constants.BLANK_DATE_STRING ? formState.endDate : undefined
    };
  };

  function getInitialFormState() {
    return {
      meetName: '',
      sanctionNumber: '',
      oqmNumber: '',
      meetClassification: [],
      zoneOrgUnitId: Constants.DEFAULT_ID,
      zoneName: '',
      lscOrgUnitId: Constants.DEFAULT_ID,
      lscName: '',
      orgUnitId: '',
      orgUnitName: '',
      orgUnitLabel: Constants.USAS_ORG_UNIT_LABEL_LSC,
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING,
      sortById: sortByOptions[0].id,
      sortByName: sortByOptions[0].name
    };
  };

  return {
    formState,
    errorState,
    otsMeetState,
    otsMeetHeaderState,
    searchOtsMeetState,
    setSearchOtsMeetState,
    zoneState,
    clearForm,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onZoneOrgUnitChanged,
    onLscOrgUnitChanged,
    onOrgUnitChanged,
    handleSubmit,
    setIsDirty,
    onFiltersToggle,
    sortByOptions,
    USAS_ORG_UNIT_ID,
    contextSecurityState
  }
}

export default useSearchOtsMeet;