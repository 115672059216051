import { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';

import useMemberContextRoutes from './UseMemberContextRoutes';

import MemberHeader from '../../components/memberHeader/MemberHeader';
import MemberContextRoutesProviders from './MemberContextRoutesProviders';

import MemberInfoRead from './memberInfo/MemberInfoRead';
import MemberInfoWrite from './memberInfo/MemberInfoWrite';
import MemberAdvancedInfo from './memberAdvancedInfo/MemberAdvancedInfo';
import MemberContactsWrite from './memberContacts/MemberContactsWrite';
import MemberContactsRead from './memberContacts/MemberContactsRead';
import MemberContactDetail from './memberContacts/MemberContactDetail';
import MemberContactAddressDetail from './memberContacts/MemberContactAddressDetail';
import MemberContactPhoneDetail from './memberContacts/MemberContactPhoneDetail';
import MemberClubTransferMember from './memberClubTransfer/MemberClubTransferMember';
import MemberClubTransferMemberDetail from './memberClubTransfer/MemberClubTransferMemberDetail';
import MemberEthnicitiesWrite from './memberEthnicities/MemberEthnicitiesWrite';
import MemberEthnicitiesRead from './memberEthnicities/MemberEthnicitiesRead';
import MemberDisabilitiesWrite from './memberDisabilities/MemberDisabilitiesWrite';
import MemberDisabilitiesRead from './memberDisabilities/MemberDisabilitiesRead';
import MemberAffiliationsWrite from './memberAffiliations/MemberAffiliationsWrite';
import MemberAffiliationsRead from './memberAffiliations/MemberAffiliationsRead';
import MemberAffiliationsDetail from './memberAffiliations/MemberAffiliationsDetail';
import MemberUpgrade from './memberUpgrade/MemberUpgrade';
import ProfileBioInfo from './profileBioInfo/ProfileBioInfo';
import ProfileSocialMedia from './profileSocialMedia/ProfileSocialMedia';
import ProfileSocialMediaDetail from './profileSocialMedia/ProfileSocialMediaDetail';
import ProfileCoach from './profileCoach/ProfileCoach';
import ProfileCoachSearch from './profileCoach/ProfileCoachSearch';
import ProfileCoachDetail from './profileCoach/ProfileCoachDetail';
import ProfileCoachTypeDetail from './profileCoach/ProfileCoachTypeDetail';
import ProfileFunFacts from './profileFunFacts/ProfileFunFacts';
import ProfileFunFactsDetail from './profileFunFacts/ProfileFunFactsDetail';
import MemberNarratives from './memberNarratives/MemberNarratives';
import MemberNarrativeDetail from './memberNarratives/MemberNarrativeDetail';
import MemberTimes from './memberTimes/MemberTimes';
import ProfilePhotoManagement from './profilePhotoManagement/ProfilePhotoManagement';
import ProfilePhotoManagementDetail from './profilePhotoManagement/ProfilePhotoManagementDetail';
import ProfileGoals from './profileGoals/ProfileGoals';
import ProfileGoalsDetail from './profileGoals/ProfileGoalsDetail';
import ProfileGroups from './profileGroups/ProfileGroups';
import MemberGroupsRegistration from './profileGroups/memberGroupsRegistration/MemberGroupsRegistration';
import NationalTeamAdvancedInfo from './nationalTeamAdvancedInfo/NationalTeamAdvancedInfo';
import NationalTeamSupportStaff from './nationalTeamSupportStaff/NationalTeamSupportStaff';
import NationalTeamSupportStaffDetail from './nationalTeamSupportStaff/NationalTeamSupportStaffDetail';
import NationalTeamSupportStaffTypeDetail from './nationalTeamSupportStaff/NationalTeamSupportStaffTypeDetail';
import NationalTeamFrequentFlyer from './nationalTeamFrequentFlyer/NationalTeamFrequentFlyer';
import NationalTeamFrequentFlyerDetail from './nationalTeamFrequentFlyer/NationalTeamFrequentFlyerDetail';
import NationalTeamMissedDrugTests from './nationalTeamMissedDrugTests/NationalTeamMissedDrugTests';
import NationalTeamMissedDrugTestsDetail from './nationalTeamMissedDrugTests/NationalTeamMissedDrugTestsDetail';
import NationalTeamSponsors from './nationalTeamSponsors/NationalTeamSponsors';
import NationalTeamSponsorsDetail from './nationalTeamSponsors/NationalTeamSponsorsDetail';
import NationalTeamContactsWrite from './nationalTeamContacts/NationalTeamContactsWrite';
import NationalTeamContactsRead from './nationalTeamContacts/NationalTeamContactsRead';
import NationalTeamContactDetail from './nationalTeamContacts/NationalTeamContactDetail';
import NationalTeamContactAddressDetail from './nationalTeamContacts/NationalTeamContactAddressDetail';
import NationalTeamContactPhoneDetail from './nationalTeamContacts/NationalTeamContactPhoneDetail';
import NationalTeamAudit from './nationalTeamAudit/NationalTeamAudit';
import MemberAdminManageTransfer from './memberAdminManageTransfers/MemberAdminManageTransfer';
import MemberCoursesRead from './memberCourses/MemberCoursesRead/MemberCoursesRead';
import NationalTeamAttachments from './nationalTeamAttachments/NationalTeamAttachments';
import NationalTeamAttachmentDetail from './nationalTeamAttachments/NationalTeamAttachmentDetail';
import MemberCoursesWrite from './memberCourses/MemberCoursesWrite/MemberCoursesWrite';
import MemberCoursesAdvancedWrite from './memberCourses/MemberCoursesAdvancedWrite/MemberCoursesAdvancedWrite';
import MemberCoursesDetail from './memberCourses/MemberCoursesDetail/MemberCoursesDetail';
import MemberReporting from './memberReporting/MemberReporting';
import MemberRaceStats from './memberRaceStats/MemberRaceStats';
import MemberAudit from './memberAudit/MemberAudit';
import MemberIneligibleToCompete from './memberIneligibleToCompete/MemberIneligibleToCompete';
import MemberIneligibleToCompeteDetail from './memberIneligibleToCompete/MemberIneligibleToCompeteDetail';
import MemberConvertToLifeMembership from './memberConvertToLifeMembership/MemberConvertToLifeMembership';
import MemberCancelMembership from './memberCancelMembership/MemberCancelMembership';
import MemberRequirementsWrite from './memberRequirements/MemberRequirementsWrite/MemberRequirementsWrite';
import MemberRequirementsRead from './memberRequirements/MemberRequirementsRead/MemberRequirementsRead';
import MemberRequirementsAdvancedWrite from './memberRequirements/MemberRequirementsAdvancedWrite/MemberRequirementsAdvancedWrite';
import MemberRequirementsDetail from './memberRequirements/MemberRequirementsWrite/MemberRequriementsDetail';
import MemberMergeSearch from './memberMergeSearch/MemberMergeSearch';
import MemberMergeSelection from './memberMergeSelection/MemberMergeSelection';
import ProfileCareerStats from './profileCareerStats/ProfileCareerStats';
import MemberPassportUpload from './memberPassportUpload/MemberPassportUpload';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import Headings from '../../../common/components/headings/Headings';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import global from '../../../common/components/GlobalStyle.module.css';

const ContextRoutes = () => {
  const { isLoading, navRoutes } = useMemberContextRoutes();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              View/Edit Member
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      {isLoading === true ?
        <LoadingModal /> : (
          <Fragment>
            <MemberHeader />
            <Routes>
              <Route path={navRoutes.MEMBER_INFO?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_INFO}
                    editElement={<MemberInfoWrite />}
                    readOnlyElement={<MemberInfoRead />} />
                }
              />
              <Route path={navRoutes.MEMBER_CONTACTS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_CONTACTS}
                    editElement={<MemberContactsWrite />}
                    readOnlyElement={<MemberContactsRead />} />
                }
              />
              <Route path={navRoutes.MEMBER_CONTACTS_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_CONTACTS_DETAIL}
                    editElement={<MemberContactDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_CONTACTS_DETAIL_ADDRESS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_CONTACTS_DETAIL_ADDRESS}
                    editElement={<MemberContactAddressDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_CONTACTS_DETAIL_PHONE?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_CONTACTS_DETAIL_PHONE}
                    editElement={<MemberContactPhoneDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_CLUB_TRANSFER?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_CLUB_TRANSFER}
                    editElement={<MemberClubTransferMember />} />
                }
              />
              <Route path={navRoutes.MEMBER_CLUB_TRANSFER_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_CLUB_TRANSFER_DETAIL}
                    editElement={<MemberClubTransferMemberDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_ETHNICITIES?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_ETHNICITIES}
                    editElement={<MemberEthnicitiesWrite />}
                    readOnlyElement={<MemberEthnicitiesRead />} />
                }
              />
              <Route path={navRoutes.MEMBER_DISABILITIES?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_DISABILITIES}
                    editElement={<MemberDisabilitiesWrite />}
                    readOnlyElement={<MemberDisabilitiesRead />} />
                }
              />
              <Route path={navRoutes.MEMBER_REQUIREMENTS_ADVANCED?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_REQUIREMENTS_ADVANCED}
                    editElement={<MemberRequirementsAdvancedWrite />}
                  />
                }
              />
              <Route path={navRoutes.MEMBER_REQUIREMENTS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_REQUIREMENTS}
                    editElement={<MemberRequirementsWrite />}
                    readOnlyElement={<MemberRequirementsRead />} />
                }
              />
              <Route path={navRoutes.MEMBER_REQUIREMENTS_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_REQUIREMENTS_DETAIL}
                    editElement={<MemberRequirementsDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_AFFILIATIONS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_AFFILIATIONS}
                    editElement={<MemberAffiliationsWrite />}
                    readOnlyElement={<MemberAffiliationsRead />} />
                }
              />
              <Route path={navRoutes.MEMBER_AFFILIATIONS_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_AFFILIATIONS_DETAIL}
                    editElement={<MemberAffiliationsDetail />} />
                }
              />


              <Route path={navRoutes.MEMBER_UPGRADE?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_UPGRADE}
                    editElement={<MemberUpgrade />} />
                }
              />
              <Route path={navRoutes.MEMBER_ALLCOURSES_ADVANCED?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_ALLCOURSES_ADVANCED}
                    editElement={<MemberCoursesAdvancedWrite />} />
                }
              />
              <Route path={navRoutes.MEMBER_ALLCOURSES?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_ALLCOURSES}
                    editElement={<MemberCoursesWrite />}
                    readOnlyElement={<MemberCoursesRead />} />
                }
              />
              <Route path={navRoutes.MEMBER_ALLCOURSES_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_ALLCOURSES_DETAIL}
                    editElement={<MemberCoursesDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_BIO_INFO_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_BIO_INFO_DETAIL}
                    editElement={<ProfileBioInfo />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_SOCIAL_MEDIA?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_SOCIAL_MEDIA}
                    editElement={<ProfileSocialMedia />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_SOCIAL_MEDIA_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_SOCIAL_MEDIA_DETAIL}
                    editElement={<ProfileSocialMediaDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_COACH?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_COACH}
                    editElement={<ProfileCoach />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_COACH_SEARCH?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_COACH_SEARCH}
                    editElement={<ProfileCoachSearch />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_COACH_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_COACH_DETAIL}
                    editElement={<ProfileCoachDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_COACH_TYPE_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_COACH_TYPE_DETAIL}
                    editElement={<ProfileCoachTypeDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_FUN_FACTS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_FUN_FACTS}
                    editElement={<ProfileFunFacts />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_FUN_FACTS_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_FUN_FACTS_DETAIL}
                    editElement={<ProfileFunFactsDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_NARRATIVES?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NARRATIVES}
                    editElement={<MemberNarratives />} />
                }
              />
              <Route path={navRoutes.MEMBER_NARRATIVES_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NARRATIVES_DETAIL}
                    editElement={<MemberNarrativeDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_TIMES?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_TIMES}
                    editElement={<MemberTimes />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_PHOTO?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_PHOTO}
                    editElement={<ProfilePhotoManagement />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_PHOTO_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_PHOTO_DETAIL}
                    editElement={<ProfilePhotoManagementDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_GOALS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_GOALS}
                    editElement={<ProfileGoals />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_GOALS_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_GOALS_DETAIL}
                    editElement={<ProfileGoalsDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_GROUPS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_GROUPS}
                    editElement={<ProfileGroups />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_GROUPS_REGISTRATION?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_GROUPS_REGISTRATION}
                    editElement={<MemberGroupsRegistration />} />
                }
              />
              <Route path={navRoutes.MEMBER_PROFILE_CAREER_STATS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PROFILE_CAREER_STATS}
                    editElement={<ProfileCareerStats />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_ADVANCED_INFO?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_ADVANCED_INFO}
                    editElement={<NationalTeamAdvancedInfo />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_CONTACTS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_CONTACTS}
                    editElement={<NationalTeamContactsWrite />}
                    readOnlyElement={<NationalTeamContactsRead />} />
                }
              />
              <Route path={navRoutes.MEMBER_NTCONTACTS_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NTCONTACTS_DETAIL}
                    editElement={<NationalTeamContactDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_CONTACTS_DETAIL_ADDRESS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_CONTACTS_DETAIL_ADDRESS}
                    editElement={<NationalTeamContactAddressDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_CONTACTS_DETAIL_PHONE?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_CONTACTS_DETAIL_PHONE}
                    editElement={<NationalTeamContactPhoneDetail />} />
                }
              />

              <Route path={navRoutes.MEMBER_NT_SUPPORT_STAFF?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_SUPPORT_STAFF}
                    editElement={<NationalTeamSupportStaff />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_SUPPORT_STAFF_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_SUPPORT_STAFF_DETAIL}
                    editElement={<NationalTeamSupportStaffDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_SUPPORT_STAFF_TYPE_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_SUPPORT_STAFF_TYPE_DETAIL}
                    editElement={<NationalTeamSupportStaffTypeDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_FREQUENT_FLYER?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_FREQUENT_FLYER}
                    editElement={<NationalTeamFrequentFlyer />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_FREQUENT_FLYER_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_FREQUENT_FLYER_DETAIL}
                    editElement={<NationalTeamFrequentFlyerDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_MISSING_DRUG_TESTS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_MISSING_DRUG_TESTS}
                    editElement={<NationalTeamMissedDrugTests />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_MISSING_DRUG_TESTS_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_MISSING_DRUG_TESTS_DETAIL}
                    editElement={<NationalTeamMissedDrugTestsDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_SPONSORS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_SPONSORS}
                    editElement={<NationalTeamSponsors />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_SPONSORS_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_SPONSORS_DETAIL}
                    editElement={<NationalTeamSponsorsDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_ATTACHMENTS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_ATTACHMENTS}
                    editElement={<NationalTeamAttachments />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_ATTACHMENTS_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_ATTACHMENTS_DETAIL}
                    editElement={<NationalTeamAttachmentDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_NT_AUDIT?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_NT_AUDIT}
                    editElement={<NationalTeamAudit />} />
                }
              />
              <Route path={navRoutes.MEMBER_ADVANCED_INFO?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_ADVANCED_INFO}
                    editElement={<MemberAdvancedInfo />} />
                }
              />
              <Route path={navRoutes.MEMBER_AUDIT?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_AUDIT}
                    editElement={<MemberAudit />} />
                }
              />
              <Route path={navRoutes.MEMBER_INELIGIBLE_TO_COMPETE?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_INELIGIBLE_TO_COMPETE}
                    editElement={<MemberIneligibleToCompete />} />
                }
              />
              <Route path={navRoutes.MEMBER_INELIGIBLE_TO_COMPETE_DETAIL?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_INELIGIBLE_TO_COMPETE_DETAIL}
                    editElement={<MemberIneligibleToCompeteDetail />} />
                }
              />
              <Route path={navRoutes.MEMBER_CONVERT_TO_LIFE?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_CONVERT_TO_LIFE}
                    editElement={<MemberConvertToLifeMembership />} />
                }
              />
              <Route path={navRoutes.MEMBER_CANCEL_MEMBERSHIP?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_CANCEL_MEMBERSHIP}
                    editElement={<MemberCancelMembership />} />
                }
              />
              <Route path={navRoutes.MEMBER_ADMIN_MANAGETRANSFERS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_ADMIN_MANAGETRANSFERS}
                    editElement={<MemberAdminManageTransfer />} />
                }
              />
              <Route path={navRoutes.MEMBER_MERGE_SEARCH?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_MERGE_SEARCH}
                    editElement={<MemberMergeSearch />} />
                }
              />
              <Route path={navRoutes.MEMBER_MERGE_SELECTION?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_MERGE_SELECTION}
                    editElement={<MemberMergeSelection />} />
                }
              />
              <Route path={navRoutes.MEMBER_REPORTING?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_REPORTING}
                    editElement={<MemberReporting />} />
                }
              />
              <Route path={navRoutes.MEMBER_RACE_STATS?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_RACE_STATS}
                    editElement={<MemberRaceStats />} />
                }
              />
              <Route path={navRoutes.MEMBER_PASSPORTUPLOAD?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes.MEMBER_PASSPORTUPLOAD}
                    editElement={<MemberPassportUpload />} />
                }
              />
            </Routes>
          </Fragment>
        )}
    </Fragment>
  );
};

const MemberContextRoutes = () => {
  return (
    <MemberContextRoutesProviders>
      <ContextRoutes />
    </MemberContextRoutesProviders>
  );
};

export default MemberContextRoutes;