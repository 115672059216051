import { useContext, useEffect } from 'react';

import { SponsorStateContext } from './SponsorContextProvider';

import SponsorData, { defaultSponsorState } from './SponsorData';

const useSponsorData = () => {
  const [sponsorState, setSponsorState] = useContext(SponsorStateContext);

  useEffect(() => {
    if (sponsorState.isLoading !== true && sponsorState.isLoaded !== true) {
      SponsorData.getSponsors(sponsorState, setSponsorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    sponsorState,
    defaultSponsorState
  };
};

export default useSponsorData;