import React from 'react';

import useMemberCoursesForAdminRoleDropdown from './UseMemberCoursesForAdminRoleDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberCoursesForAdminRoleDropdown = ({ label, name, value, error, message, onChange, roleName }) => {
  const { memberCoursesAdminRoleState } = useMemberCoursesForAdminRoleDropdown(roleName);

  return memberCoursesAdminRoleState?.message
    ? <span className={global.LoadingMsg}>{memberCoursesAdminRoleState?.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={memberCoursesAdminRoleState?.options}//.filter(x => x.courseSourceId !== 1 && x.id !== 2)}
        name={name}
        value={value}
        onChange={onChange}
        areValuesIntegers={true}
        isLoading={memberCoursesAdminRoleState.isArrayLoading} />
    );
};

export default MemberCoursesForAdminRoleDropdown;