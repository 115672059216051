import React, { Fragment } from 'react';

import useMemberNarrativeDetail from './UseMemberNarrativeDetail';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import Textarea from '../../../../common/components/inputs/Textarea';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberNarrativeDetail = () => {
  const {
    personProfileState,
    formState,
    errorState,
    narrativeTypeState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked
  } = useMemberNarrativeDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.personNarrativeId > 0 ? "Edit" : "Add"} Narrative</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Narrative Name*"
              name="narrativeTypeId"
              value={formState.narrativeTypeId}
              error={errorState.narrativeTypeId}
              message={errorState.narrativeTypeId}
              isLoading={narrativeTypeState.isLoading}
              options={narrativeTypeState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'narrativeTypeId', newValueLabel, 'narrativeTypeName'); }}
            />
          </div>
          <div className="col-xs-12">
            <Textarea
              className={global.Textarea}
              label="Narrative*"
              name="narrativeDescription"
              value={formState.narrativeDescription}
              error={errorState.narrativeDescription}
              message={errorState.narrativeDescription}
              onChange={(value) => { onFormValueChanged('narrativeDescription', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personProfileState.isObjLoading || narrativeTypeState.isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personProfileState.isSaving} />
    </Fragment >
  );
};

export default MemberNarrativeDetail;