import { useContext } from 'react';
import { HispanicOrLatinoTypeStateContext } from './HispanicOrLatinoTypeContextProvider';

import HispanicOrLatinoTypeData from './HispanicOrLatinoTypeData';

const useHispanicOrLatinoTypeData = () => {
  const [hispanicOrLatinoTypeState, setHispanicOrLatinoTypeState] = useContext(HispanicOrLatinoTypeStateContext);

  const getHispanicOrLatinoTypes = () => {
    HispanicOrLatinoTypeData.getHispanicOrLatinoTypes(hispanicOrLatinoTypeState, setHispanicOrLatinoTypeState);
  };

  return {
    hispanicOrLatinoTypeState,
    getHispanicOrLatinoTypes,
    setHispanicOrLatinoTypeState
  };
};

export default useHispanicOrLatinoTypeData;