import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: []
};

const getMeetEvaluatorsByOtsMeetId = (otsMeetId, state, setState) => {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/otsmeetevaluator/meet/${otsMeetId}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const postMeetEvaluator = (payload, state, setState) => {
  if (payload) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/otsmeetevaluator`;

    return api?.executeObject ? api.executeObject(url, 'POST', payload) : null;
  }
};

const deleteMeetEvaluator = (id, state, setState) => {
  if (id) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const idForUrl = encodeURIComponent(id);
    const url = `/otsmeetevaluator/${idForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const MeetEvaluatorData = {
  getMeetEvaluatorsByOtsMeetId,
  postMeetEvaluator,
  deleteMeetEvaluator,
  INITIAL_STATE
};

export default MeetEvaluatorData;