import React, { Fragment } from 'react';

import MeetOfficialsOfficialSelectionLargeGrid from './MeetOfficialsOfficialSelectionLargeGrid';
import MeetOfficialsOfficialSelectionSmallGrid from './MeetOfficialsOfficialSelectionSmallGrid';

import global from '../../../../../common/components/GlobalStyle.module.css';

const MeetOfficialsOfficialSelectionGrid = ({ passedInMeetEndDate, gridData, isLoading, onCheckboxChange }) => {
  return (
    <Fragment>
      {isLoading === false ?
        <div className="row">
          <div className="col-xs-12">
            {gridData.length > 499
              ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p> :
              <p>{gridData.length === 1 ? `${gridData.length} Search Result` : `${gridData.length} Search Results`}</p>}
          </div>
        </div>
        : <Fragment><div className="row usas-extra-top-margin usas-extra-bottom-margin"></div></Fragment>}
      <MeetOfficialsOfficialSelectionLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onCheckboxChange={onCheckboxChange}
        passedInMeetEndDate={passedInMeetEndDate}
      />
      <MeetOfficialsOfficialSelectionSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onCheckboxChange={onCheckboxChange}
        passedInMeetEndDate={passedInMeetEndDate}
      />
    </Fragment>
  )
};

export default MeetOfficialsOfficialSelectionGrid;