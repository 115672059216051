import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOtsMeetStaffData from './GetOtsMeetStaffData';
import putOtsMeetStaffData from './PutOtsMeetStaffData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OtsMeetStaffData = {
  INITIAL_STATE, getOtsMeetStaffData, putOtsMeetStaffData
};

export default OtsMeetStaffData;