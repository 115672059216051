import React, { createContext, useState } from 'react';

import EvaluationLevelData from './EvaluationLevelData';

export const EvaluationLevelStateContext = createContext();

const EvaluationLevelContextProvider = ({ children }) => {
  const stateHook = useState(EvaluationLevelData.INITIAL_STATE);

  return (
    <EvaluationLevelStateContext.Provider value={stateHook}>
      {children}
    </EvaluationLevelStateContext.Provider>
  );
};

export default EvaluationLevelContextProvider;