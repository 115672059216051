import { Fragment } from 'react';

import OfficialSelectionForEvaluationGridLarge from './OfficialSelectionForEvaluationGridLarge';
import OfficialSelectionForEvaluationGridSmall from './OfficialSelectionForEvaluationGridSmall';

const OfficialSelectionForEvaluationGrid = ({ state, otsMeetHeaderState, gridState, showError, evaluators,
  otsPositionWorkedEvaluationLevelEvaluatorNameState, onUpdateOfficialNameValueChange, onUpdateOfficialPositionWorkedValueChange,
  onUpdateEvaluatorNameValueChange, onUpdateOverrideEvaluatorNameValueChange, onUpdateEvaluationLevelValueChange,
  onUpdateOverrideEvaluationLevelValueChange, isLarge }) => {
  return (
    <Fragment>
      {isLarge === true ?
        <OfficialSelectionForEvaluationGridLarge
          state={state}
          otsMeetHeaderState={otsMeetHeaderState}
          gridState={gridState}
          showError={showError}
          evaluators={evaluators}
          otsPositionWorkedEvaluationLevelEvaluatorNameState={otsPositionWorkedEvaluationLevelEvaluatorNameState}
          onUpdateOfficialNameValueChange={onUpdateOfficialNameValueChange}
          onUpdateOfficialPositionWorkedValueChange={onUpdateOfficialPositionWorkedValueChange}
          onUpdateEvaluatorNameValueChange={onUpdateEvaluatorNameValueChange}
          onUpdateOverrideEvaluatorNameValueChange={onUpdateOverrideEvaluatorNameValueChange}
          onUpdateEvaluationLevelValueChange={onUpdateEvaluationLevelValueChange}
          onUpdateOverrideEvaluationLevelValueChange={onUpdateOverrideEvaluationLevelValueChange}
        /> :
        <OfficialSelectionForEvaluationGridSmall
          state={state}
          otsMeetHeaderState={otsMeetHeaderState}
          gridState={gridState}
          showError={showError}
          evaluators={evaluators}
          otsPositionWorkedEvaluationLevelEvaluatorNameState={otsPositionWorkedEvaluationLevelEvaluatorNameState}
          onUpdateOfficialNameValueChange={onUpdateOfficialNameValueChange}
          onUpdateOfficialPositionWorkedValueChange={onUpdateOfficialPositionWorkedValueChange}
          onUpdateEvaluatorNameValueChange={onUpdateEvaluatorNameValueChange}
          onUpdateOverrideEvaluatorNameValueChange={onUpdateOverrideEvaluatorNameValueChange}
          onUpdateEvaluationLevelValueChange={onUpdateEvaluationLevelValueChange}
          onUpdateOverrideEvaluationLevelValueChange={onUpdateOverrideEvaluationLevelValueChange} />
      }
    </Fragment>
  );
};
export default OfficialSelectionForEvaluationGrid;