import { useState } from 'react';

import MemberCardData from './MemberCardByPersonaData';

const useMemberCardData = () => {
  const [memberCardState, setMemberCardState] = useState(MemberCardData.INITIAL_STATE);

  const getMemberCard = (personId, personaId) => {
    MemberCardData.getMemberCard(personId, personaId, memberCardState, setMemberCardState);
  };

  return { memberCardState, getMemberCard };
};

export default useMemberCardData;