import React, { Fragment } from 'react';

import useRadioListWithMultiCheckboxChildren from './UseRadioListWithMultiCheckboxChildren';

import MultiSelectCheckboxes from '../checkboxes/MultiSelectCheckboxes';

import global from '../../components/GlobalStyle.module.css';
import style from './RadioListWithMultiCheckboxChildren.module.css';

const RadioListWithMultiCheckboxChildren = ({ hierarchicalOptions, label, name, childOptionsName, currentValues, error, onChange, message }) => {
  const { formatChildLabel, options, onRadioCheckedChanged, onCheckboxCheckedChanged }
    = useRadioListWithMultiCheckboxChildren(hierarchicalOptions, currentValues, onChange);

  return (
    <div className={global.ComponentFlex}>
      <label className={global.UsasLabel} htmlFor={name}>{label}</label>
      <span style={{ marginTop: "5px" }}></span>
      {options.map((item) => (
        <div key={item.id}>
          <div className={style.RadioButton}>
            <input
              type="radio"
              id={name + "-" + item.id}
              name={name}
              value={item.id}
              checked={item.isChecked}
              onChange={(e) => { onRadioCheckedChanged(e) }}
            />
          </div>
          <div className={style.LabelAndCheckboxes}>
            <label htmlFor={name + "-" + item.value}>
              {item.name}
            </label>
            {
              Array.isArray(item.childObjects) && item.childObjects.length > 0
                ? <MultiSelectCheckboxes
                  label={formatChildLabel(item.name, childOptionsName)}
                  name={`${name}-${item.id}-child`}
                  value={item.currentChildValues}
                  error={error}
                  options={item.childObjects}
                  onChange={(newValue) => { onCheckboxCheckedChanged(newValue, item.id) }}
                />
                : <Fragment />
            }
          </div>
        </div>
      ))}
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </div>
  );
};

export default RadioListWithMultiCheckboxChildren;