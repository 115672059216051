import { Fragment } from 'react';

import useNationalTeamFrequentFlyerDetail from './UseNationalTeamFrequentFlyerDetail';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';

const NationalTeamFrequentFlyerDetail = () => {
  const {
    Constants,
    personNationalTeamState,
    formState,
    errorState,
    frequentFlyerTypeState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  } = useNationalTeamFrequentFlyerDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.personFrequentFlyerId > 0 ? 'Edit' : 'Add'} Frequent Flyer Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Carrier Name*"
              name="frequentFlyerTypeId"
              value={formState.frequentFlyerTypeId}
              error={errorState.frequentFlyerTypeId}
              message={errorState.frequentFlyerTypeId}
              isLoading={frequentFlyerTypeState.isLoading}
              options={frequentFlyerTypeState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'frequentFlyerTypeId', newValueLabel, 'typeName') }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Frequent Flyer Number*"
              name="frequentFlyerNumber"
              value={formState.frequentFlyerNumber}
              error={errorState.frequentFlyerNumber}
              message={errorState.frequentFlyerNumber}
              onChange={(value) => { onFormValueChanged('frequentFlyerNumber', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onSaveClicked(e)}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={(e) => onCancelClicked(e)}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personNationalTeamState.isObjLoading || frequentFlyerTypeState.isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personNationalTeamState.isSaving} />
    </Fragment>);
}

export default NationalTeamFrequentFlyerDetail;