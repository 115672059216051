import React, { Fragment } from 'react';

import useOtsOfficialEvaluations from './UseOtsOfficialEvaluations';

import OtsOfficialEvaluationsGrid from './OtsOfficialEvaluationsGrid';

import Headings from '../../../../../common/components/headings/Headings';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

const OtsOfficialEvaluations = () => {
  const { meetEvaluationPersonOfficialState,
    onReadOnlyEvaluationClicked,
    formState,
    errorState,
    onFormValueChanged } = useOtsOfficialEvaluations();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Evaluations</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <span className='pull-right'>
            <YesNoSwitch
              label="Show All Evaluations?"
              name="showAllEvaluations"
              checked={formState.showAllEvaluations === true}
              error={errorState.showAllEvaluations}
              message={errorState.showAllEvaluations}
              onChange={(value) => { onFormValueChanged('showAllEvaluations', value); }}
              isHorizontal={true} />
          </span>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <OtsOfficialEvaluationsGrid
            state={meetEvaluationPersonOfficialState}
            formState={formState}
            onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={meetEvaluationPersonOfficialState.isArrayLoading || meetEvaluationPersonOfficialState.isObjLoading} />
    </Fragment >
  );
};

export default OtsOfficialEvaluations;