import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOfficialEvaluationData from '../../../../state/otsOfficialEvaluations/UseOfficialEvaluationData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useEmbeddedReportData from '../../../../../common/state/embeddedReport/UseEmbeddedReportData';

import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';

const INITIAL_STATE = {
  evaluation: {},
  isLoading: true,
  isSaving: false,
  arrayData: [],
  message: '',
  tryRedirect: false
};

const INITIAL_VIEW_STATE = {
  reportParameters: {},
  contextId: '',
  routeName: '',
  isLoading: false,
  showReport: false,
  reportViewerType: '',
  reportName: ''
};

const useOtsOfficialMeetEvaluationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { officialEvaluationState, getOfficialEvaluationsByMeetEvaluationId } = useOfficialEvaluationData();
  const { getEmbeddedReportListForRoute } = useEmbeddedReportData();
  const [state, setState] = useState({ ...INITIAL_STATE });
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);

  const onItemClick = (id, value) => {
    let changed = false;

    const newArray = state.arrayData.map(x => {
      const field = x.evaluationQuestionId === ToIntIfInt(id) ? value : x.field;
      changed = changed === true || field !== x.field;

      return {
        ...x,
        field: x.evaluationQuestionId === ToIntIfInt(id) ? value : x.field
      };
    });


    if (changed === true) {
      setState({ ...state, arrayData: newArray });
    }
  };

  const onItemClickDropdown = (id, value, e) => {
    let changed = false;

    const newArray = state.arrayData.map(x => {
      const field = x.evaluationQuestionId === ToIntIfInt(id) ? value : x.field;
      changed = changed === true || field !== x.field;

      return {
        ...x,
        field: x.evaluationQuestionId === ToIntIfInt(id) ? value : x.field
      };
    });


    if (e && changed === true) {
      setState({ ...state, arrayData: newArray });
    }
  };

  const onRunMeetEvaluationReport = (meetEvaluationId) => {
    setViewState({
      ...viewState,
      reportParameters: { meetEvaluationId: meetEvaluationId },
      routeName: 'OTS_MEET_MY_OTS_EVALUATIONS',
      reportViewerType: 'ActiveReports',
      reportName: 'Meet Evaluation Report',
      isLoading: true
    });
  };

  const onBackClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OTS_OFFICIAL_EVALUATIONS?.route);
  };

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const reportIdx = stateObj.arrayData.findIndex(x => ((x.reportViewerType === viewState.reportViewerType) && (x.reportName === viewState.reportName)));

            setViewState({
              ...viewState,
              isLoading: false,
              showReport: stateObj.arrayData.length > 0,
              reportInfoId: stateObj.arrayData[reportIdx].reportInfoId || -1,
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName, viewState.reportViewerType, viewState.routeName]);

  useEffect(() => {
    if (officialEvaluationState.isSaving !== true) {
      if (officialEvaluationState.isSaved === true) {
        setState({ ...state, isSaving: false });
      } else if (location.state && location.state?.evaluation && (
        officialEvaluationState.isArrayLoaded !== true ||
        (officialEvaluationState.isArrayLoaded === true && officialEvaluationState.meetEvaluationId !== location.state?.evaluation?.meetEvaluationId)
      )) {
        getOfficialEvaluationsByMeetEvaluationId(location.state?.evaluation?.meetEvaluationId);
        setState({ ...state, isSaving: false });
      } else if ((state.isLoading === true && officialEvaluationState.isArrayLoading !== true)) {
        const arrayDataFormatted = (officialEvaluationState.arrayData || []).map(x => { return { ...x }; })
        setState({
          ...state,
          evaluation: location.state.evaluation,
          isLoading: false,
          isSaving: false,
          arrayData: arrayDataFormatted
        });
      }
    } else {
      setState({ ...state, isSaving: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officialEvaluationState]);

  return {
    ...state,
    viewState,
    setViewState,
    INITIAL_VIEW_STATE,
    onItemClick,
    onItemClickDropdown,
    onBackClicked,
    onRunMeetEvaluationReport
  };
};

export default useOtsOfficialMeetEvaluationForm;