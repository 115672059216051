import React from 'react';

import useQuickAddOrganizationDropdown from './UseQuickAddOrganizationDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const QuickAddOrganizationDropdown = ({ label, name, value, error, message, onChange }) => {
  const { organizationState } = useQuickAddOrganizationDropdown();

  return organizationState.message
    ? <span className={global.LoadingMsg}>{organizationState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={organizationState.quickAddOrganizationsAsOptions}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={organizationState.isLoading}
      />
    );
};

export default QuickAddOrganizationDropdown;