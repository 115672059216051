import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useGlobalRoutesData from '../../../../common/state/security/UseGlobalRoutesData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useMemberPersistentStorage from '../../../../common/state/memberPersistentStorage/UseMemberPersistentStorage';
import useSearchMemberData from '../../../../common/state/searchMember/UseSearchMemberData';

const useMemberSearch = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { setPersistentStorage, resetPersistentStorage } = useMemberPersistentStorage();
  const { searchMemberState } = useSearchMemberData();

  const onEditPerson = (e, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setPersistentStorage(personId, 'member-id', navRoutes.MEMBER_SEARCH.route, 'Back to Member Search');
    const route = getGlobalRoute('MEMBER_INFO');
    if (route) {
      navigate(route?.route);
    }
  };

  useEffect(() => {
    resetPersistentStorage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { searchMemberState, onEditPerson };
};

export default useMemberSearch;