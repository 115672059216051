import { useEffect } from 'react';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import { navLinks } from '../UseMemberAdministration';

const TAXONOMIES = ['HQMemberAdministrationDetail'];
const SCOPE = 'MemberAdmin';

const useMemberAdministrationSelection = () => {
  const navigate = useNavigate();
  const { environmentVariableState, USAS_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { getContextSecurity, contextSecurityState } = useSecurityData();

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      if (contextSecurityState.isObjLoaded === true && USAS_ORG_UNIT_ID === contextSecurityState.contextId
        && contextSecurityState.scope === SCOPE) {
        navigate(navLinks.HQ_BOARD);
      } else if (contextSecurityState.isObjLoading !== true) {
        getContextSecurity(USAS_ORG_UNIT_ID, TAXONOMIES, SCOPE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, contextSecurityState]);

  return {
    isLoading: environmentVariableState.isLoading || contextSecurityState.isObjLoading
  }
};

export default useMemberAdministrationSelection;