import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import FileExclamationIcon from '../../../../../common/components/icons/FileExclamationIcon';
import SGColumnHeader from '../../../../../common/components/grids/SGColumnHeader';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../common/utils/SGConstants';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ session }) => (
  <tr>
    <td>{`${session.sessionOrderId || ''} (${session.sessionType || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})`}</td>
    <td>{session.isOfficialsQualifyingSession === true ? 'Yes' : session.isOfficialsQualifyingSession === false ? 'No' : ''}</td>
    <td>{`${session.roleTypeName || ''} (${session.roleTypeAbbreviation || ''})`}</td>
    <td>{session?.isApprentice === true ? 'Apprentice' : ''}</td>
  </tr>
);

const GridRowDetailTable = ({ sessions }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Session</th>
        <th>OQM Session?</th>
        <th>Role Worked</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(sessions) && sessions.map((session, i) =>
        <DetailTableRow key={i} session={session} />)}
    </tbody>
  </table>
);

const GridRow = ({ official, expandedId, onEditClicked, onDeleteClicked, onExpandClicked, passedInMeetEndDate }) => {
  const meetEndDate = new Date(passedInMeetEndDate);
  const expirationDateForRegistration = new Date(official?.registrationExpirationDate);
  const expirationDateForApt = new Date(official?.aptExpirationDate);
  const expirationDateForBgc = new Date(official?.bgcExpirationDate);
  const expirationDateForCpt = new Date(official?.cptExpirationDate);
  return (
    <Fragment>
      <tr>
        <td>{official.firstName || ''} {official.lastName || ''}</td>
        <td>{official.lscCode || ''} {official.clubCode ? '/' : ''} {official.clubCode || ''}</td>
        <td>{expirationDateForRegistration < meetEndDate
          ? <span style={{ color: 'red' }}>{official.registrationExpirationDate ?
            formatDate(official.registrationExpirationDate) === Constants.DEFAULT_MAX_DATE
              ? 'No Expiration' :
              formatDate(official.registrationExpirationDate) : 'No Registration'}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.registrationExpirationDate ? 'Registration Expired' : 'No Registration'} /></span> :
          <span>{official.registrationExpirationDate ? formatDate(official.registrationExpirationDate) === Constants.DEFAULT_MAX_DATE
            ? 'No Expiration' :
            formatDate(official.registrationExpirationDate) : ''}</span>}</td>
        <td>{expirationDateForApt < meetEndDate
          ? <span style={{ color: 'red' }}>{official.aptExpirationDate ?
            formatDate(official.aptExpirationDate) === Constants.DEFAULT_MAX_DATE
              ? 'No Expiration' :
              formatDate(official.aptExpirationDate) : 'Not Completed'}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.aptExpirationDate ? 'Course Expired' : 'Not Completed'} /></span> :
          <span>{official.aptExpirationDate ? formatDate(official.aptExpirationDate) === Constants.DEFAULT_MAX_DATE
            ? 'No Expiration' : formatDate(official.aptExpirationDate) : ''}</span>}</td>
        <td>{expirationDateForBgc < meetEndDate
          ? <span style={{ color: 'red' }}>{official.bgcExpirationDate ?
            formatDate(official.bgcExpirationDate) === Constants.DEFAULT_MAX_DATE
              ? 'No Expiration' :
              formatDate(official.bgcExpirationDate) : 'Not Completed'}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.bgcExpirationDate ? 'BGC Expired' : 'Not Completed'} /></span> :
          <span>{official.bgcExpirationDate ? formatDate(official.bgcExpirationDate) === Constants.DEFAULT_MAX_DATE
            ? 'No Expiration' : formatDate(official.bgcExpirationDate) : ''}</span>}</td>
        <td>{expirationDateForCpt < meetEndDate
          ? <span style={{ color: 'red' }}>{official.cptExpirationDate ?
            formatDate(official.cptExpirationDate) === Constants.DEFAULT_MAX_DATE
              ? 'No Expiration'
              : formatDate(official.cptExpirationDate)
            : 'Not Completed'}&nbsp;&nbsp;<FileExclamationIcon toolTipText={official.cptExpirationDate ? 'Course Expired' : 'Not Completed'} /></span> :
          <span>{official.cptExpirationDate ? formatDate(official.cptExpirationDate) === Constants.DEFAULT_MAX_DATE
            ? 'No Expiration' : formatDate(official.cptExpirationDate) : ''}</span>}</td>
        <td>{official.sessionsNumber >= 0 ? official.sessionsNumber : ''}</td>
        <td>{official.officialRoleTypeName || ''}</td>
        <td>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onEditClicked(e, official)}>
            <EditIcon />
          </button>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onDeleteClicked(e, official)}>
            <DeleteIcon />
          </button>
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onExpandClicked(e, official.personId)}>
            {expandedId === official.personId ? <HideIcon /> : <ShowIcon />}
          </button>
        </td>
      </tr>
      {expandedId === official.personId &&
        <tr className={global.Expanded}>
          {Array.isArray(official.session) && official.session.length > 0
            ? (
              <td colSpan="9">
                <GridRowDetailTable sessions={official.session} />
              </td>
            )
            : (
              <td colSpan="9">
                <table className={global.DetailTable}>
                  <thead>
                    <tr>
                      <th>No Session Roles</th>
                    </tr>
                  </thead>
                </table>
              </td>
            )
          }
        </tr>
      }
    </Fragment>
  );
}

const MeetOfficialsGridLarge = ({ gridData, isLoading, expandedId, onEditClicked, onDeleteClicked, onExpandClicked, passedInMeetEndDate }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'firstName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '100px' }} sortableGridState={sortableGridState} columnLabel={'LSC / Club'} columnField={'clubCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '140px' }} sortableGridState={sortableGridState} columnLabel={'Reg. Expires'} columnField={'registrationExpirationDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '140px' }} sortableGridState={sortableGridState} columnLabel={'APT Expires'} columnField={'aptExpirationDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '140px' }} sortableGridState={sortableGridState} columnLabel={'BGC Expires'} columnField={'bgcExpirationDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '140px' }} sortableGridState={sortableGridState} columnLabel={'CPT Expires'} columnField={'cptExpirationDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '85px' }} sortableGridState={sortableGridState} columnLabel={'Sessions'} columnField={'sessionsNumber'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '90px' }} sortableGridState={sortableGridState} columnLabel={'Roles'} columnField={'officialRoleTypeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th style={{ minWidth: '130px' }}></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true ?
          <tr><td colSpan="9">Loading...</td></tr>
          : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0 ?
            sortableGridState.sortedGridData.map((official, i) =>
              <GridRow key={i} official={official} expandedId={expandedId} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked}
                onExpandClicked={onExpandClicked} passedInMeetEndDate={passedInMeetEndDate} />)
            :
            <tr><td colSpan="9">No Meet Officials</td></tr>
        }
      </tbody>
    </table >
  );
}

export default MeetOfficialsGridLarge;