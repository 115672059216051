import React, { createContext, useState } from 'react';

import FunFactsTypeData from './FunFactsTypeData';

export const FunFactsTypeStateContext = createContext();

const FunFactsTypeContextProvider = ({ children }) => {
  const stateHook = useState(FunFactsTypeData.INITIAL_STATE);

  return (
    <FunFactsTypeStateContext.Provider value={stateHook}>
      {children}
    </FunFactsTypeStateContext.Provider>
  );
};

export default FunFactsTypeContextProvider;