import { useContext } from 'react';

import { EnrollmentStateContext } from './EnrollmentContextProvider';
import { EnrollmentInCertificationStateContext } from './EnrollmentInCertificationContextProvider';
import { CoachEnrollmentInCertificationStateContext } from './CoachEnrollmentInCertificationContextProvider';

import EnrollmentData from './EnrollmentData';

const useEnrollmentData = () => {
  const [enrollmentState, setEnrollmentState] = useContext(EnrollmentStateContext);
  const [enrollmentCertificationState, setEnrollmentCertificationState] = useContext(EnrollmentInCertificationStateContext);
  const [coachEnrollmentInCertificationState, setCoachEnrollmentInCertificationState] = useContext(CoachEnrollmentInCertificationStateContext);

  const getUserEnrollment = () => {
    EnrollmentData.getUserEnrollment(enrollmentState, setEnrollmentState);
  };

  const postEnrollmentByExternalLmsCourseId = (externalLmsCourseId, lmsCourseId, cssCourseId) => {
    EnrollmentData.postEnrollmentByExternalLmsCourseId(externalLmsCourseId, lmsCourseId, cssCourseId, enrollmentState, setEnrollmentState);
  };

  const postEnrollmentByCertificationId = (certificationId) => {
    EnrollmentData.postEnrollmentByCertificationId(certificationId, enrollmentCertificationState, setEnrollmentCertificationState);
  };


  const postCoachEnrollmentByCertification = (coachArrayObj) => {
    EnrollmentData.postCoachEnrollmentByCertification(coachArrayObj, coachEnrollmentInCertificationState, setCoachEnrollmentInCertificationState);
  };

  const postCoachEnrollmentByEligibleCoaches = (coachArrayObj) => {
    EnrollmentData.postCoachEnrollmentByEligibleCoaches(coachArrayObj, coachEnrollmentInCertificationState, setCoachEnrollmentInCertificationState);
  };

  const putEnrollment = (obj) => {
    EnrollmentData.putEnrollment(obj, enrollmentState, setEnrollmentState)
  }

  const resetEnrollmentState = () => {

    setEnrollmentState({ ...EnrollmentData.INITIAL_STATE });
  };

  const resetEnrollmentCertificationState = () => {

    setEnrollmentCertificationState({ ...EnrollmentData.INITIAL_STATE });
  };

  const resetCoachEnrollmentCertificationState = () => {

    setCoachEnrollmentInCertificationState({ ...EnrollmentData.INITIAL_STATE });
  };

  return {
    enrollmentState,
    setEnrollmentState,
    getUserEnrollment,
    postEnrollmentByExternalLmsCourseId,
    resetEnrollmentState,
    postEnrollmentByCertificationId,
    putEnrollment,
    enrollmentCertificationState,
    setEnrollmentCertificationState,
    resetEnrollmentCertificationState,
    postCoachEnrollmentByCertification,
    postCoachEnrollmentByEligibleCoaches,
    coachEnrollmentInCertificationState,
    setCoachEnrollmentInCertificationState,
    resetCoachEnrollmentCertificationState
  };
};

export default useEnrollmentData;