import { Fragment } from 'react';

import DatePicker from '../../../../../../common/components/datepickers/DatePicker';

import { formatDate, getTodaysDate } from '../../../../../../common/utils/DateFunctions';
import { isValidDate } from '../../../../../../common/utils/validation';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ position, formState, showError, onUpdateEffectiveDateValueChange, onUpdateExpirationDateValueChange }) => {
  const todayDate = getTodaysDate();
  const today = new Date(todayDate);
  const expirationDateLocal = new Date(position?.expirationDate);
  const effectiveDate = position?.selectedEffectiveDate ? position?.selectedEffectiveDate : formatDate(position?.effectiveDate);
  const expirationDate = position?.selectedExpirationDate ? position?.selectedExpirationDate : formatDate(position?.expirationDate);
  const effectiveDateDateFormat = new Date(effectiveDate);
  const expirationDateDateFormat = new Date(expirationDate);

  if (formState?.showAllNationalCertificationHistory === false && (today <= expirationDateLocal)) {
    return (
      <Fragment>
        <tr>
          <td>{position?.positionTypeName} - {position?.positionAbbreviation}</td>
          <td>{position?.levelName}</td>
          <td>
            <DatePicker
              label=""
              name={"effectiveDate" + position?.personPositionCertificationId + "personPositionCertificationId"}
              value={position?.selectedEffectiveDate}
              onChange={(value) => { onUpdateEffectiveDateValueChange(position, value); }}
              countOfYears={24}
              startYearOffset={-24}
              error={showError === true && ((effectiveDateDateFormat > expirationDateDateFormat) || !isValidDate(effectiveDate))}
              message={!isValidDate(effectiveDate) ? 'Effective Date must be a valid date' : (effectiveDateDateFormat > expirationDateDateFormat) ? 'Effective Date must be on or before the Expiration Date' : ''}
            />
          </td>
          <td>
            <DatePicker
              label=""
              name={"expirationDate" + position?.personPositionCertificationId + "personPositionCertificationId"}
              value={position?.selectedExpirationDate}
              onChange={(value) => { onUpdateExpirationDateValueChange(position, value); }}
              countOfYears={27}
              startYearOffset={-24}
              error={showError === true && ((effectiveDateDateFormat > expirationDateDateFormat) || !isValidDate(expirationDate))}
              message={!isValidDate(expirationDate) ? 'Expiration Date must be a valid date' : (effectiveDateDateFormat > expirationDateDateFormat) ? 'Expiration Date must be on or after the Effective Date' : ''}
            />
          </td>
        </tr>
      </Fragment >
    );
  } else if (formState?.showAllNationalCertificationHistory === true) {
    return (
      <Fragment>
        <tr>
          <td>{position?.positionTypeName} - {position?.positionAbbreviation}</td>
          <td>{position?.levelName}</td>
          <td>
            <DatePicker
              label=""
              name={"effectiveDate" + position?.personPositionCertificationId + "personPositionCertificationId"}
              value={position?.selectedEffectiveDate}
              onChange={(value) => { onUpdateEffectiveDateValueChange(position, value); }}
              countOfYears={24}
              startYearOffset={-24}
              error={showError === true && ((effectiveDateDateFormat > expirationDateDateFormat) || !isValidDate(effectiveDate))}
              message={!isValidDate(effectiveDate) ? 'Effective Date must be a valid date' : (effectiveDateDateFormat > expirationDateDateFormat) ? 'Effective Date must be on or before the Expiration Date' : ''}
            />
          </td>
          <td>
            <DatePicker
              label=""
              name={"expirationDate" + position?.personPositionCertificationId + "personPositionCertificationId"}
              value={position?.selectedExpirationDate}
              onChange={(value) => { onUpdateExpirationDateValueChange(position, value); }}
              countOfYears={27}
              startYearOffset={-24}
              error={showError === true && ((effectiveDateDateFormat > expirationDateDateFormat) || !isValidDate(expirationDate))}
              message={!isValidDate(expirationDate) ? 'Expiration Date must be a valid date' : (effectiveDateDateFormat > expirationDateDateFormat) ? 'Expiration Date must be on or after the Effective Date' : ''}
            />
          </td>
        </tr>
      </Fragment>
    )
  } else {
    return (
      <Fragment />
    )
  }
}

const OtsOfficialNationalCertificationHistoryLargeWriteGrid = ({ gridState, state, formState, showError, onUpdateEffectiveDateValueChange, onUpdateExpirationDateValueChange }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Position</th>
          <th>National Certification</th>
          <th>Effective Date</th>
          <th>Expiration Date</th>
        </tr>
      </thead>
      <tbody>
        {gridState?.arrayData?.length > 0 ?
          gridState?.arrayData?.map((position, i) =>
            <LargeGridRow
              key={i}
              position={position}
              formState={formState}
              showError={showError}
              onUpdateEffectiveDateValueChange={onUpdateEffectiveDateValueChange}
              onUpdateExpirationDateValueChange={onUpdateExpirationDateValueChange}
            />)
          : state?.isArrayLoading
            ? <tr><td colSpan="4">Loading...</td></tr>
            : <tr><td colSpan="4">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default OtsOfficialNationalCertificationHistoryLargeWriteGrid;