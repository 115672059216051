import { Fragment } from 'react';

import useOtsMeetInfo from './UseOtsMeetInfo';

import Headings from '../../../../../common/components/headings/Headings';
import Input from '../../../../../common/components/inputs/Input';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsMeetInfo = () => {
  const { otsMeetState, otsMeetSessionsState, handleSubmit, formState, errorState,
    onFormValueChanged, resetForm, modalState, onIsOfficialsQualifyingMeetIsChangingClicked,
    onIsOfficialsQualifyingMeetIsNotChangingClicked } = useOtsMeetInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Officials Qualifying Meet (OQM)?*"
              name="isOfficialsQualifyingMeet"
              checked={formState.isOfficialsQualifyingMeet}
              error={errorState.isOfficialsQualifyingMeet}
              message={errorState.isOfficialsQualifyingMeet}
              onChange={(value) => { onFormValueChanged('isOfficialsQualifyingMeet', value); }} />
          </div>
          <div className={formState.isOfficialsQualifyingMeet === true ? global.DisplayComponent : global.HideComponent}>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <Input
                label="OQM Number*"
                name="oqmNumber"
                value={formState.oqmNumber}
                error={errorState.oqmNumber}
                message={errorState.oqmNumber}
                onChange={(value) => { onFormValueChanged('oqmNumber', value); }} />
            </div>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={otsMeetState.isObjLoading || otsMeetSessionsState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={otsMeetState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}>
        <div className="row">
          <div className="col-xs-12">
            <p>Are you sure that the Officials Qualifying Meet (OQM) selection is changing from &apos;Yes&apos; to &apos;No&apos;?</p>
            <p><b>This meet currently has at least one meet session where the OQM session selection is &apos;Yes&apos;.
              Changing the Officials Qualifying Meet (OQM) selection to &apos;No&apos; will also change the OQM session selection from &apos;Yes&apos; to &apos;No&apos; on the meet session(s).
              Are you sure you would like to make this change to the meet and its meet sessions?</b></p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onIsOfficialsQualifyingMeetIsChangingClicked}>Yes, Accept Change</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onIsOfficialsQualifyingMeetIsNotChangingClicked}>No, Undo Change</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default OtsMeetInfo;