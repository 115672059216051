import React, { Fragment } from 'react';

import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import SGColumnHeader from '../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../common/utils/SGConstants';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ evaluation, formState, onReadOnlyEvaluationClicked }) => {
  if ((formState?.showAllEvaluations === false && evaluation.isActiveEvaluation === true) || formState?.showAllEvaluations === true) {
    return (
      <tr>
        {evaluation.hasEvaluation === false ? <td> {evaluation.meetName || ''}</td> :
          <td><a onClick={(e) => onReadOnlyEvaluationClicked(e, evaluation)}>{evaluation.meetName || ''}</a></td>}
        <td>{evaluation.meetStartDate ? formatDate(evaluation.meetStartDate) : ''} - {evaluation.meetEndDate ? formatDate(evaluation.meetEndDate) : ''}</td>
        <td>{evaluation.positionTypeName || ''}</td>
        <td>{evaluation.evaluationLevelName || ''}</td>
        <td>{evaluation.evaluatorFirstName || ''} {evaluation.evaluatorLastName || ''}</td>
        <td>{evaluation.hasEvaluation === true ? 'Yes' : 'No'}</td>
      </tr>
    );
  }
  else {
    return (
      <Fragment />
    )
  }
}

const LargeGrid = ({ state, formState, onReadOnlyEvaluationClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{minWidth: '175px'}} sortableGridState={sortableGridState} columnLabel={'Meet Dates'} columnField={'meetStartDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Position Worked'} columnField={'positionTypeName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{minWidth: '135px'}}  sortableGridState={sortableGridState} columnLabel={'Evaluation Level'} columnField={'evaluationLevelName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{minWidth: '105px'}} sortableGridState={sortableGridState} columnLabel={'Evaluator Name'} columnField={'evaluatorLastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{minWidth: '105px'}}  sortableGridState={sortableGridState} columnLabel={'Completed?'} columnField={'hasEvaluation'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr><td colSpan="6">{state.isArrayLoading === true ? 'Loading...' : 'No Meet Evaluations'}</td></tr>
          ) : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((evaluation, i) => <LargeGridRow key={i} evaluation={evaluation} formState={formState} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} />
            ) : (
              <tr>
                <td colSpan="6">No Meet Evaluations</td>
              </tr>
            )
        }
      </tbody>
    </table >
  );
}

const SmallGridRowIcons = ({ evaluation, onReadOnlyEvaluationClicked }) => (
  <Fragment>
    {evaluation.hasEvaluation === false ?
      <Fragment>
      </Fragment>
      :
      <Fragment>
        <div style={{ maxWidth: '180px' }}><ActionIntraPageButton onClick={(e) => onReadOnlyEvaluationClicked(e, evaluation)}>View</ActionIntraPageButton></div>
      </Fragment>}
  </Fragment >
);

const SmallGridRow = ({ evaluation, formState, onReadOnlyEvaluationClicked }) => {
  if ((formState?.showAllEvaluations === false && evaluation.isActiveEvaluation === true) || formState?.showAllEvaluations === true) {
    return (
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          {evaluation.hasEvaluation === true ? <span /> : <span className='hidden-xs'>{evaluation.meetName || ''}</span>}
          &nbsp;
          <SmallGridRowIcons evaluation={evaluation} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} />
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            {evaluation.hasEvaluation === true ?
              <Fragment>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                  Meet Name
                </div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                  {evaluation.meetName || <span>&nbsp;</span>}
                </div>
              </Fragment> :
              <Fragment>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                  Meet Name
                </div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                  {evaluation.meetName || <span>&nbsp;</span>}
                </div>
              </Fragment>}
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Meet Dates
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.meetStartDate ? formatDate(evaluation.meetStartDate) : <span>&nbsp;</span>} - {evaluation.meetEndDate ? formatDate(evaluation.meetEndDate) : <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Position Worked
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.positionTypeName || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Evaluation Level
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.evaluationLevelName || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Evaluator Name
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.evaluatorFirstName || <span>&nbsp;</span>}  {evaluation.evaluatorLastName || <span>&nbsp;</span>}
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
              Completed?
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              {evaluation.hasEvaluation === true ? 'Yes' : 'No'}
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (<Fragment />)
  }
}

const SmallGrid = ({ state, formState, onReadOnlyEvaluationClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            {state.isLoading === true ?
              <div className={global.SmallTableRowLabels}>Loading...</div> :
              <div className={global.SmallTableRowLabels}>No Meet Evaluations</div>
            }
          </div>
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((evaluation, i) => <SmallGridRow key={i} evaluation={evaluation} formState={formState} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Meet Evaluations</div>
            </div>
          </div>
        )
    }
  </div >
);

const OtsOfficialEvaluationsGrid = ({ state, formState, onReadOnlyEvaluationClicked }) => (
  <Fragment>
    <LargeGrid state={state} formState={formState} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} />
    <SmallGrid state={state} formState={formState} onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked} />
  </Fragment>
);

export default OtsOfficialEvaluationsGrid;