import React, { Fragment } from 'react';

import { navLinks } from './UseMemberAdministration';
import useMemberAdministrationContextView from './UseMemberAdministrationContextView';

import Headings from '../../../common/components/headings/Headings';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';

import global from '../../../common/components/GlobalStyle.module.css';

const MemberAdministrationContextView = ({ children }) => {
  const {
    onBackClicked,
  } = useMemberAdministrationContextView();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onBackClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              Member Administration
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="HQMemberAdministrationDetail" renderPillsOnly={true} />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="HQMemberAdministrationDetail" homeRoute={navLinks.HQ_BOARD} renderPillsOnly={true} />
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default MemberAdministrationContextView;