import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useOfferingManagementData from '../../../state/offeringManagement/UseOfferingManagementData';
import useOfferingManagementInstanceOrgUnitData from '../../../state/offeringManagementInstanceOrgUnit/UseOfferingManagementInstanceOrgUnitData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../../common/state/security/UseSecurityData';

import { DEFAULT_POSITIVE_ID } from '../../../../common/utils/Constants';

const TAXONOMIES = ['OfferingManagement'];
const SCOPE = 'Project';

const useOfferingManagementContextView = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { securityState, getContextSecurity } = useSecurityData();
  const { resetOfferingManagementState, resetOfferingManagementInstanceState } = useOfferingManagementData();
  const { resetOfferingManagementInstanceOrgUnitState } = useOfferingManagementInstanceOrgUnitData();

  const onReloadClicked = () => {
    resetOfferingManagementState();
    resetOfferingManagementInstanceState();
    resetOfferingManagementInstanceOrgUnitState();

    const homeRoute = navRoutes.OFFERING_MANAGMENT_HQ_OFFERINGS?.route;
    if (homeRoute && homeRoute !== window.location.pathname) {
      navigate(homeRoute);
    } else {
      window.location.reload();
    }
  };

  const onHomeClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERINGS?.route);
  };

  useEffect(() => {
    getContextSecurity(DEFAULT_POSITIVE_ID, TAXONOMIES, SCOPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState]);

  return {
    homeRoute: navRoutes.OFFERING_MANAGMENT_HQ_OFFERINGS?.route,
    onHomeClicked,
    onReloadClicked
  };
};

export default useOfferingManagementContextView;
