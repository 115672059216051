export const localValidate = (formState) => {
  let errors = {};

  return errors;
};

const MemberTimesFilterValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MemberTimesFilterValidation;