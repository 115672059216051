import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

const useMemberMergeSelection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { basePersonState, setBasePersonState } = useBasePersonData();
  const { navRoutes } = useNavRoutes();

  const onSuccessfulMerge = (basePersonObj) => {
    setBasePersonState({
      ...basePersonState,
      objData: basePersonObj,
      isSaved: true
    });

    navigate(navRoutes.MEMBER_INFO?.route);
  };

  const onCancelMergeClicked = () => {
    navigate(navRoutes.MEMBER_MERGE_SEARCH?.route);
  };

  return {
    personAId: location.state?.personAId,
    personBId: location.state?.personBId,
    onSuccessfulMerge,
    onCancelMergeClicked
  };
};

export default useMemberMergeSelection;