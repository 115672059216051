import React from 'react';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';

import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatPlaceString } from '../../../../common/utils/TimesUtils';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ careerStat, onUpdateIncludeOnLaneSheet }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{careerStat.eventCode || ''}</span>&nbsp;
      <span className='pull-right'>
        <Checkbox
          label=''
          name={`includeOnLaneSheet${careerStat.personCareerStatsId}`}
          checked={careerStat.includeOnLaneSheet}
          onChange={(value) => { onUpdateIncludeOnLaneSheet(careerStat.personCareerStatsId) }} />
      </span>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Event
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {careerStat.eventCode || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Meet
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {careerStat.meetName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Meet Dates
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {careerStat.meetStartDate ? formatDate(careerStat.meetStartDate) : ''} - {careerStat.meetEndDate ? formatDate(careerStat.meetEndDate) : ''}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Session
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {careerStat.sessionTypeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Finish Position
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {careerStat.finishPosition ? formatPlaceString(careerStat.finishPosition) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const ProfileCareerStatsGridSmall = ({ data, isLoading, onUpdateIncludeOnLaneSheet }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
            &nbsp;&nbsp;Loading...
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((careerStat, i) => <GridRow key={i} careerStat={careerStat} onUpdateIncludeOnLaneSheet={onUpdateIncludeOnLaneSheet} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
              &nbsp;&nbsp;No Career Stats
            </div>
          </div>
        )
    }
  </div>
);

export default ProfileCareerStatsGridSmall;