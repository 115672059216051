import { Fragment } from 'react';

import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ roles }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowData, 'col-xs-12'].join(' ')}>{roles.goodStandingName}</div>
      </div>
    </div>
  </div>
);

const GridRowDetailTable = ({ course }) => (
  <div className={global.SmallTableDetailTable}>
    <div className={[global.SmallTableDetailRowHead, 'col-xs-12'].join(' ')}>
      Good Standing Types
    </div>
    {Array.isArray(course.goodStandingType) && course.goodStandingType.map((roles, i) => <DetailTableRow key={i} roles={roles} />)}
  </div>
);

const GridRow = ({ course, onEditRequirement, formState, onOpenDeleteEnrollmentModal, expandedId, onClick }) => {
  const courseGoodStandingRole = Array.isArray(course.goodStandingType)
    ? course.goodStandingType.find(x => x.goodStandingName === formState.goodStandingName)
    : null;

  return (
    formState.goodStandingName === courseGoodStandingRole?.goodStandingName
      ? <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}><span className='hidden-xs'>{course.courseName} {course.courseCode ? `(${course.courseCode})` : ''}</span>&nbsp;
            <button className={global.IconButton}
              type="button"
              onClick={(e) => onClick(e, course.lmsCourseId)}>
              {expandedId === course.lmsCourseId ? <HideIcon /> : <ShowIcon />}
            </button>
            <span className={course.canAdminEdit === true && !course.certificationId && course.status !== 'Not Completed' ? global.DisplayComponent : global.HideComponent}>
              <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onOpenDeleteEnrollmentModal(e, course)}><DeleteIcon /></button>
            </span>
            <span className={course.canAdminEdit === true && !course.certificationId ? global.DisplayComponent : global.HideComponent}>
              <button className={global.IconButtonMobileMargin} onClick={(e) => onEditRequirement(e, course)}><EditIcon /></button>
            </span>
          </div>
          <div className={[global.SmallTableRowInfo, 'visible-xs'].join('')}>
            <div className='row'>
              <div className={[global.SmallTableRowLabels, 'col-xs-6 hidden-sm'].join(' ')}>Requirement</div>
              <div className={[global.SmallTableRowData, 'col-xs-6 hidden-sm'].join(' ')}>{course.courseName} {course.courseCode ? `(${course.courseCode})` : ''}</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Date Completed</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>
                {course.courseCompletionDate !== null && course.coursePassed === true
                  ? formatDate(course.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
                    ? <span>&nbsp;</span>
                    : formatDate(course.courseCompletionDate)
                  : <span>&nbsp;</span>
                }</div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Expiration Date</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>
                {course.courseExpirationDate !== null && course.coursePassed === true
                  ? formatDate(course.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
                    ? `Doesn't Expire`
                    : formatDate(course.courseExpirationDate)
                  : <span>&nbsp;</span>
                }
              </div>
              <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Status</div>
              <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>
                {course.status}
              </div>
            </div>
          </div>
        </div>
        {Array.isArray(course.goodStandingType) && course.goodStandingType.length > 0
          && expandedId === course.lmsCourseId &&
          <GridRowDetailTable course={course} />
        }
      </Fragment>
      : <Fragment />
  );
};

const MemberRequirementsSmallWriteGrid = ({ gridData, isLoading, onEditRequirement, formState, onOpenDeleteEnrollmentModal, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (<div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          <div className={global.SmallTableRowLabels}>
            Loading...
          </div>
        </div>
      </div>
      )
      : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((course, i) =>
          <GridRow key={i}
            course={course}
            onEditRequirement={onEditRequirement}
            formState={formState}
            onOpenDeleteEnrollmentModal={onOpenDeleteEnrollmentModal}
            expandedId={expandedId}
            onClick={onClick} />)
        : (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              No Requirements
            </div>
          </div>
        </div>)
    }
  </div>
);

export default MemberRequirementsSmallWriteGrid;