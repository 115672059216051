import { createContext, useState } from 'react';

import OfferingManagementData from './OfferingManagementData';

export const OfferingManagementInstanceStateContext = createContext();

const OfferingManagementInstanceContextProvider = ({ children }) => {
  const stateHook = useState(OfferingManagementData.INITIAL_OFFERING_MANAGEMENT_INSTANCE_STATE);

  return (
    <OfferingManagementInstanceStateContext.Provider value={stateHook}>
      {children}
    </OfferingManagementInstanceStateContext.Provider>
  );
};

export default OfferingManagementInstanceContextProvider;