import React, { createContext, useState } from 'react';
import Constants from '../../../common/utils/Constants';


const INITIAL_STATE = {
  isObjLoading: false,
  isObjLoaded: false,
  isSaving: false,
  isSaved: false,
  objData: {},
  message: '',
  orgUnitId: Constants?.DEFAULT_ID,
  isLoggedInUser: false
};


export const OtsOfficialHeaderStateContext = createContext();

const OtsOfficialHeaderContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <OtsOfficialHeaderStateContext.Provider value={stateHook}>
      {children}
    </OtsOfficialHeaderStateContext.Provider>
  );
};

export default OtsOfficialHeaderContextProvider;