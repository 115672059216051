import { useContext, useEffect } from 'react';

import { DrugTestStatusStateContext } from './DrugTestStatusContextProvider';

import DrugTestStatusData, { defaultDrugTestStatusState } from './DrugTestStatusData';

const useDrugTestStatusData = () => {
  const [drugTestStatusState, setDrugTestStatusState] = useContext(DrugTestStatusStateContext);

  useEffect(() => {
    if (drugTestStatusState.isLoading !== true && drugTestStatusState.isLoaded !== true) {
      DrugTestStatusData.getDrugTestStatus(drugTestStatusState, setDrugTestStatusState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    drugTestStatusState,
    defaultDrugTestStatusState
  };
};

export default useDrugTestStatusData;