import { useContext, useEffect } from 'react';

import { SupportStaffTypeStateContext } from './SupportStaffTypeContextProvider';

import SupportStaffTypeData, { defaultSupportStaffTypeState } from './SupportStaffTypeData';

const useSupportStaffTypeData = () => {
  const [supportStaffTypeState, setSupportStaffTypeState] = useContext(SupportStaffTypeStateContext);

  useEffect(() => {
    if (supportStaffTypeState.isLoading !== true && supportStaffTypeState.isLoaded !== true) {
      SupportStaffTypeData.getSupportStaffTypes(supportStaffTypeState, setSupportStaffTypeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    supportStaffTypeState,
    defaultSupportStaffTypeState
  };
};

export default useSupportStaffTypeData;