import { Fragment } from 'react';

import useMyOtsEvaluations from './UseMyOtsEvaluations';

import MyOtsEvaluationsGrid from './MyOtsEvaluationsGrid';

import Headings from '../../../../../common/components/headings/Headings';
import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import ViewReport from '../../../../../common/components/embeddedReports/ViewReport';

import Constants from '../../../../../common/utils/Constants';

const MyOtsEvaluations = () => {
  const {
    viewState,
    setViewState,
    INITIAL_VIEW_STATE,
    meetEvaluationPersonState,
    onGoToEvaluationFormClicked,
    onReadOnlyEvaluationClicked,
    formState,
    errorState,
    onFormValueChanged,
    onRunViewHistoryReport } = useMyOtsEvaluations();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>My OTS Evaluations</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <span className='pull-right'>
            <YesNoSwitch
              label="Show All Evaluations?"
              name="showAllEvaluations"
              checked={formState.showAllEvaluations === true}
              error={errorState.showAllEvaluations}
              message={errorState.showAllEvaluations}
              onChange={(value) => { onFormValueChanged('showAllEvaluations', value); }}
              isHorizontal={true} />
          </span>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <MyOtsEvaluationsGrid
            state={meetEvaluationPersonState}
            formState={formState}
            onGoToEvaluationFormClicked={onGoToEvaluationFormClicked}
            onReadOnlyEvaluationClicked={onReadOnlyEvaluationClicked}
            onRunViewHistoryReport={onRunViewHistoryReport}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={meetEvaluationPersonState.isArrayLoading || meetEvaluationPersonState.isObjLoading} />
      {viewState.showReport === true &&
        <ViewReport reportInfoId={viewState.reportInfoId} reportParameters={viewState.reportParameters} onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />}
    </Fragment >
  );
};

export default MyOtsEvaluations;