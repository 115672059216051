import React, { createContext, useState } from 'react';

import MemberAdministrationHQCommitteeData from './MemberAdministrationHQCommitteeData';

export const MemberAdministrationHQCommitteeStateContext = createContext();

const MemberAdministrationHQCommitteeContextProvider = ({ children }) => {
  const stateHook = useState(MemberAdministrationHQCommitteeData.INITIAL_STATE);

  return (
    <MemberAdministrationHQCommitteeStateContext.Provider value={stateHook}>
      {children}
    </MemberAdministrationHQCommitteeStateContext.Provider>
  );
};

export default MemberAdministrationHQCommitteeContextProvider;