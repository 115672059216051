import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import usePersonCoursesData from '../../../state/member/memberCourses/UsePersonCoursesData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const useMemberCourses = (viewLocation) => {
  const { basePersonState } = useBasePersonData();
  const { memberCoursesState, getPersonCourses } = usePersonCoursesData();
  const [gridDataState, setGridDataState] = useState([]);
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();

  const onGoToMemberCoursesDetailView = (e, viewLocation) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes?.MEMBER_ALLCOURSES_DETAIL?.route, { state: { personId: basePersonState?.objData?.personId, viewLocation: viewLocation } });
  };

  const onEditCourse = (e, course, viewLocation) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes?.MEMBER_ALLCOURSES_DETAIL?.route, { state: { course: course, personId: basePersonState?.objData?.personId, viewLocation: viewLocation} });
  };

  useEffect(() => {
    if (memberCoursesState?.isArrayLoaded === true) {

      setGridDataState(memberCoursesState?.arrayData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCoursesState]);

  useEffect(() => {
    if (memberCoursesState?.isArrayLoaded === false && memberCoursesState?.isArrayLoading === false) {
      if (basePersonState.isObjLoaded === true) {

        getPersonCourses(basePersonState.objData.personId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState?.isObjLoaded, memberCoursesState?.isArrayLoaded]);

  return {
    basePersonState,
    gridDataState: gridDataState,
    isGridLoading: memberCoursesState?.isArrayLoading,
    onGoToMemberCoursesDetailView,
    onEditCourse
  };
};

export default useMemberCourses;