import React, { Fragment } from 'react';

import global from '../../../../../../common/components/GlobalStyle.module.css';

import { formatDate, getTodaysDate } from '../../../../../../common/utils/DateFunctions';
import Checkbox from '../../../../../../common/components/checkboxes/Checkbox';

const LargeGridRow = ({ cert, formState, onRecertifyCheckboxChange, onAdvanceCheckboxChange }) => {
  const todayDate = getTodaysDate();
  const today = new Date(todayDate);
  const expirationDateLocal = new Date(cert?.expirationDate);

  if (formState?.showAllNationalCertificationApplication === false && (today <= expirationDateLocal)) {
    return (
      <Fragment>
        <tr>
          <td>{cert?.positionName} - {cert?.positionAbbreviation}</td>
          <td>{cert?.certificationName}</td>
          <td>{formatDate(cert?.effectiveDate)}</td>
          <td>{formatDate(cert?.expirationDate)}</td>
          <td>
            <Checkbox
              label=""
              name="isRecertifySelected"
              onChange={(e) => { onRecertifyCheckboxChange(e, cert.personOfficialCertificationId) }}
              checked={cert.isRecertifySelected} />
          </td>
          <td>
            <Checkbox
              label=""
              name="isAdvanceSelected"
              onChange={(e) => { onAdvanceCheckboxChange(e, cert.personOfficialCertificationId) }}
              checked={cert.isAdvanceSelected} />
          </td>
        </tr>
      </Fragment >
    );
  } else if (formState?.showAllNationalCertificationApplication === true) {
    return (
      <Fragment>
        <tr>
          <td>{cert?.positionName} - {cert?.positionAbbreviation}</td>
          <td>{cert?.certificationName}</td>
          <td>{formatDate(cert?.effectiveDate)}</td>
          <td>{formatDate(cert?.expirationDate)}</td>
          <td>
            <Checkbox
              label=""
              name="isRecertifySelected"
              onChange={(e) => { onRecertifyCheckboxChange(e, cert.personOfficialCertificationId) }}
              checked={cert.isRecertifySelected} />
          </td>
          <td>
            <Checkbox
              label=""
              name="isAdvanceSelected"
              onChange={(e) => { onAdvanceCheckboxChange(e, cert.personOfficialCertificationId) }}
              checked={cert.isAdvanceSelected} />
          </td>
        </tr>
      </Fragment>
    )
  } else {
    return (
      <Fragment />
    )
  }
}

const OtsOfficialNationalCertificationApplicationLargeWriteGrid = ({ state, gridState, formState, onRecertifyCheckboxChange, onAdvanceCheckboxChange }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Position</th>
          <th>National Certification</th>
          <th>Effective Date</th>
          <th>Expiration Date</th>
          <th>Re-Certify</th>
          <th>Advance</th>
        </tr>
      </thead>
      <tbody>
        {gridState?.gridData?.length > 0 ?
          gridState?.gridData?.map((cert, i) =>
            <LargeGridRow
              key={i}
              cert={cert}
              formState={formState}
              onRecertifyCheckboxChange={onRecertifyCheckboxChange}
              onAdvanceCheckboxChange={onAdvanceCheckboxChange}
            />)
          : state?.isArrayLoading
            ? <tr><td colSpan="6">Loading...</td></tr>
            : <tr><td colSpan="6">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default OtsOfficialNationalCertificationApplicationLargeWriteGrid;