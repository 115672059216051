import { Fragment } from 'react';

import useOtsOfficialNationalCertificationHistory from './UseOtsOfficialNationalCertificationHistory';

import OtsOfficialNationalCertificationHistoryWriteGrid from '../otsOfficialNationalCertificationHistory/grids/OtsOfficialNationalCertificationHistoryWriteGrid';

import YesNoSwitch from '../../../../../common/components/yesNoSwitch/YesNoSwitch';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../../common/utils/Constants';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsOfficialNationalCertificationHistoryWrite = () => {
  const {
    gridState,
    basePersonState,
    otsOfficialNationalCertificationState,
    formState,
    errorState,
    showError,
    onFormValueChanged,
    onUpdateEffectiveDateValueChange,
    onUpdateExpirationDateValueChange,
    onSaveClicked
  } = useOtsOfficialNationalCertificationHistory();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>National Certification History</Headings.H3>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <span className='pull-right'>
            <YesNoSwitch
              label="Show All National Certification History?"
              name="showAllNationalCertificationHistory"
              checked={formState.showAllNationalCertificationHistory === true}
              error={errorState.showAllNationalCertificationHistory}
              message={errorState.showAllNationalCertificationHistory}
              onChange={(value) => { onFormValueChanged('showAllNationalCertificationHistory', value); }}
              isHorizontal={true} />
          </span>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <OtsOfficialNationalCertificationHistoryWriteGrid
            gridState={gridState}
            formState={formState}
            showError={showError}
            state={otsOfficialNationalCertificationState}
            onUpdateEffectiveDateValueChange={onUpdateEffectiveDateValueChange}
            onUpdateExpirationDateValueChange={onUpdateExpirationDateValueChange} />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          {showError && <p className={global.ErrorMessage}>There is at least one error on the grid. To ensure you are viewing all possible errors, please make sure &apos;Show All National Certification History?&apos; is set to &apos;Yes&apos;.</p>}
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || otsOfficialNationalCertificationState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={otsOfficialNationalCertificationState.isSaving} />
    </Fragment >
  );
};

export default OtsOfficialNationalCertificationHistoryWrite;