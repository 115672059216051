import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOfficialEvaluationData from '../../../../state/otsOfficialEvaluations/UseOfficialEvaluationData';
import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useEmbeddedReportData from '../../../../../common/state/embeddedReport/UseEmbeddedReportData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import ToIntIfInt from '../../../../../common/utils/ToIntIfInt';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_STATE = {
  evaluation: {},
  isLoading: true,
  isSaving: false,
  arrayData: [],
  message: '',
  tryRedirect: false
};

const INITIAL_VIEW_STATE = {
  reportParameters: {},
  contextId: '',
  routeName: '',
  isLoading: false,
  showReport: false,
  reportViewerType: '',
  reportName: ''
};

const useOtsMeetEvaluationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { officialEvaluationState, getOfficialEvaluationsByMeetEvaluationId, postOfficialEvaluation,
    putOfficialEvaluation, confirmOfficialEvaluationSave } = useOfficialEvaluationData();
  const { getEmbeddedReportListForRoute } = useEmbeddedReportData();
  const { otsMeetHeaderState } = useOtsMeetData();
  const [state, setState] = useState({ ...INITIAL_STATE });
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);

  const onItemClick = (id, value) => {
    let changed = false;

    const newArray = state.arrayData.map(x => {
      const field = x.evaluationQuestionId === ToIntIfInt(id) ? value : x.field;
      changed = changed === true || field !== x.field;

      return {
        ...x,
        field: x.evaluationQuestionId === ToIntIfInt(id) ? value : x.field
      };
    });


    if (changed === true) {
      setState({ ...state, arrayData: newArray });
    }
  };

  const onItemClickDropdownHandleFirstLoadOnEdit = (id, field) => {
    const newArray = JSON.parse(JSON.stringify(state.arrayData));
    const selectedQuestionIndex = newArray.findIndex(x => x.evaluationQuestionId === id)
    const selectedEvaluationQuestionDropdownId = newArray[selectedQuestionIndex]?.evaluationQuestionDropdown?.find(x => x.dropdownValue === field)?.evaluationQuestionDropdownId || Constants.DEFAULT_ID;
    newArray[selectedQuestionIndex].field = selectedEvaluationQuestionDropdownId;

    setState({ ...state, arrayData: newArray });
  };

  const onItemClickDropdown = (id, value, e) => {
    let changed = false;
    const newArray = state.arrayData.map(x => {
      const field = x.evaluationQuestionId === ToIntIfInt(id) ? value : x.field;
      changed = changed === true || field !== x.field;

      return {
        ...x,
        field: x.evaluationQuestionId === ToIntIfInt(id) ? value : x.field
      };
    });


    if (e && changed === true) {
      setState({ ...state, arrayData: newArray });
    }
  };

  const onSubmit = (e) => {
    //on edit view, convert Boolean field that comes back as text to a bool so that validation works
    const stateArrayDataCopy = state.arrayData.map(x => {
      let field = x.field;
      if (x.typeName === 'Boolean' && (x.field === 'true' || x.field === 'false')) {
        field = JSON.parse(x.field?.toLowerCase())
      }
      return (
        { ...x, field }
      )
    });

    const foundBooleanError = stateArrayDataCopy.find(x => (x.typeName === 'Boolean' && x.field !== true && x.field !== false));
    const foundTextError = state.arrayData.find(x => (x.typeName === 'Text' && !x.evaluationQuestionDropdown && !x.field?.trim()));
    const foundDropdownError = state.arrayData.find(x => (x.typeName === 'Text' && x.evaluationQuestionDropdown && (!x.field || x.field < 0)));
    const foundIntegerError = state.arrayData.find(x => (x.typeName === 'Integer' && !x.field));

    const foundTextLengthError = state.arrayData.find(x => (x.typeName === 'Text' && !x.evaluationQuestionDropdown && x.field && x.field.trim().length > 5000));

    if (foundBooleanError || foundTextError || foundDropdownError || foundIntegerError) {
      setState({ ...state, message: 'You must complete all of the questions in order to continue.' });
    } else if (foundTextLengthError) {
      setState({ ...state, message: 'Text responses cannot exceed 5,000 characters. Please shorten your text responses in order to continue.' });
    }
    else {
      setState({ ...state, message: '', tryRedirect: true });

      const arrayDataCopy = JSON.parse(JSON.stringify(state.arrayData));
      const formattedArrayDataCopy = arrayDataCopy
      for (let i = 0; i < formattedArrayDataCopy.length; i++) {
        if (formattedArrayDataCopy[i].evaluationQuestionDropdown) {
          const dropdownSelection = formattedArrayDataCopy[i].evaluationQuestionDropdown?.find(x => x.evaluationQuestionDropdownId === formattedArrayDataCopy[i].field)
          if (dropdownSelection) {
            formattedArrayDataCopy[i].field = dropdownSelection.dropdownValue
          }
        }
      }

      const hasEvaluation = state?.evaluation?.hasEvaluation ?? false;
      if (hasEvaluation === false) {
        postOfficialEvaluation(location.state?.evaluation?.meetEvaluationId, formattedArrayDataCopy);
      }
      else if (hasEvaluation === true) {
        putOfficialEvaluation(location.state?.evaluation?.meetEvaluationId, formattedArrayDataCopy);
      }
    }
  };

  const onRunMeetEvaluationReport = (meetEvaluationId) => {
    setViewState({
      ...viewState,
      reportParameters: { meetEvaluationId: meetEvaluationId },
      routeName: 'OTS_MEET_MY_OTS_EVALUATIONS',
      reportViewerType: 'ActiveReports',
      reportName: 'Meet Evaluation Report',
      isLoading: true
    });
  };

  const onBackClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OTS_MEET_EVALUATIONS?.route);
  };

  useEffect(() => {
    if (viewState.routeName) {
      const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

      if (reportListPromise) {
        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const reportIdx = stateObj.arrayData.findIndex(x => ((x.reportViewerType === viewState.reportViewerType) && (x.reportName === viewState.reportName)));

            setViewState({
              ...viewState,
              isLoading: false,
              showReport: stateObj.arrayData.length > 0,
              reportInfoId: stateObj.arrayData[reportIdx].reportInfoId || -1,
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showReport: false,
              reportInfoId: -1
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName, viewState.reportViewerType, viewState.routeName]);

  useEffect(() => {
    if (officialEvaluationState.isSaving !== true) {
      if (officialEvaluationState.isSaved === true) {
        setState({ ...state, isSaving: false });
      } else if (location.state && location.state?.evaluation && (
        officialEvaluationState.isArrayLoaded !== true ||
        (officialEvaluationState.isArrayLoaded === true && officialEvaluationState.meetEvaluationId !== location.state?.evaluation?.meetEvaluationId)
      )) {
        getOfficialEvaluationsByMeetEvaluationId(location.state?.evaluation?.meetEvaluationId);
        setState({ ...state, isSaving: false });
      } else if ((state.isLoading === true && officialEvaluationState.isArrayLoading !== true)) {
        const arrayDataFormatted = (officialEvaluationState.arrayData || []).map(x => { return { ...x }; });
        setState({
          ...state,
          evaluation: {
            ...location.state.evaluation,
            meetName: otsMeetHeaderState.objData?.meetName || '',
            oqmNumber: otsMeetHeaderState.objData?.oqmNumber || '',
            dateRange: otsMeetHeaderState.objData?.dateRange || '',
          },
          isLoading: false,
          isSaving: false,
          arrayData: arrayDataFormatted
        });
      }
    } else {
      setState({ ...state, isSaving: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officialEvaluationState]);

  useEffect(() => {
    if (state.tryRedirect === true && officialEvaluationState.isSaved === true) {
      confirmOfficialEvaluationSave();
      navigate(navRoutes.OTS_MEET_EVALUATIONS?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, officialEvaluationState]);

  return {
    ...state,
    viewState,
    setViewState,
    INITIAL_VIEW_STATE,
    onItemClick,
    onItemClickDropdownHandleFirstLoadOnEdit,
    onItemClickDropdown,
    onSubmit,
    onRunMeetEvaluationReport,
    onBackClicked
  };
};

export default useOtsMeetEvaluationForm;