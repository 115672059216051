import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: []
};

const getNationalEvaluatorTitlesData = (state, setState) => {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/otsnationalevaluatortitle`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const NationalEvaluatorTitleData = {
  getNationalEvaluatorTitlesData,
  INITIAL_STATE
};

export default NationalEvaluatorTitleData;
