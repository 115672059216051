/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import EditIcon from '../../../common/components/icons/EditIcon';
import PlusIcon from '../../../common/components/icons/PlusIcon';

import SGColumnHeader from '../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../common/utils/UseSortableGrid';
import SGConstants from '../../../common/utils/SGConstants';

import global from '../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, onPost, onEdit, onCancelClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '95px' }} sortableGridState={sortableGridState} columnLabel={'Sanction #'} columnField={'meetSanctionNumber'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '65px' }} sortableGridState={sortableGridState} columnLabel={'OQM #'} columnField={'oqmNumber'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '115px' }} sortableGridState={sortableGridState} columnLabel={'Organization'} columnField={'organization'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader style={{ minWidth: '125px' }} sortableGridState={sortableGridState} columnLabel={'Classification'} columnField={'meetClassification'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th style={{ minWidth: '165px' }}>Start Date - End Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <Fragment>
              {state.isArrayLoading === true
                ? (
                  <tr>
                    <td colSpan="7">Loading...</td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="7">No Results</td>
                  </tr>
                )
              }
            </Fragment>
          ) : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((meet, i) => (
              <tr key={i}>
                {onCancelClicked || meet.otsMeetId === null || meet.canCreateOtsMeet !== true ?
                  <td>{meet.meetName || ''}</td> :
                  <td><a onClick={(e) => onEdit(e, meet)}>{meet.meetName || ''}</a></td>}
                <td>{meet.meetSanctionNumber || ''}</td>
                <td>{meet.oqmNumber || ''}</td>
                <td>{meet.organization || ''}</td>
                <td>{meet.meetClassification || ''}</td>
                <td>{meet.dateRange || ''}</td>
                {onCancelClicked
                  ? (
                    <td>
                      <button className={global.IconButtonMargin} type="button" onClick={(e) => onEdit(e, meet)}>
                        <PlusIcon />
                      </button>
                    </td>
                  ) : <Fragment>
                    <td>
                      {onPost && meet.otsMeetId === null && meet.canCreateOtsMeet === true &&
                        <button className={global.IconButton} type="button" onClick={(e) => onPost(e, meet)}>
                          <PlusIcon toolTipText='Create OTS Meet' />
                        </button>}
                    </td>
                  </Fragment>
                }
              </tr>
            )) : (
              <tr>
                <td colSpan="7">No Results</td>
              </tr>
            )
        }
      </tbody>
    </table >
  );
};

const SmallGrid = ({ state, onPost, onEdit, onCancelClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {state.isArrayLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Results</div>
            }
          </div>
        </div>
      )
      : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((meet, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{meet.meetName || <span>&nbsp;</span>}</span>&nbsp;
              {onCancelClicked ?
                <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, meet)}>
                  <PlusIcon />
                </button> :
                <Fragment>
                  {meet.canCreateOtsMeet === true &&
                    <Fragment>
                      {onPost && meet.otsMeetId === null ?
                        <button className={global.IconButton} type="button" onClick={(e) => onPost(e, meet)}>
                          <PlusIcon toolTipText='Create OTS Meet' />
                        </button>
                        :
                        <button className={global.IconButton} type="button" onClick={(e) => onEdit(e, meet)}>
                          <EditIcon />
                        </button>}
                    </Fragment>}
                </Fragment>
              }
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Meet Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{meet.meetName || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Sanction Number</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.meetSanctionNumber || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>OQM Number</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.oqmNumber || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Organization</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.organization || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet Classification</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.meetClassification || <span>&nbsp;</span>}</div>
                <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>Start Date - End Date</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Start - End Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{meet.dateRange || <span>&nbsp;</span>}</div>
              </div>
            </div>
          </div>
        ))
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Results</div>
            </div>
          </div>
        )}
  </div >
);

const SearchOtsMeetGrid = ({ searchOtsMeetState, otsMeetState, onPost, onEdit, onFiltersToggle, onCancelClicked }) => (
  <Fragment>
    {otsMeetState.isArrayLoading === false
      ? <div className="row">
        <div className="col-xs-12 col-sm-10">
          {otsMeetState.arrayData.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{otsMeetState.arrayData.length === 1 ? `${otsMeetState.arrayData.length} Search Result` : `${otsMeetState.arrayData.length} Search Results`}</p>}
        </div>
        {onFiltersToggle ?
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={() => onFiltersToggle()}>{searchOtsMeetState.showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
          </div> : <Fragment />}
      </div>
      :
      <Fragment>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        </div>
      </Fragment >
    }
    <LargeGrid state={otsMeetState} onPost={onPost} onEdit={onEdit} onCancelClicked={onCancelClicked} />
    <SmallGrid state={otsMeetState} onPost={onPost} onEdit={onEdit} onCancelClicked={onCancelClicked} />
  </Fragment>
);

export default SearchOtsMeetGrid;