import React, { createContext, useState } from 'react';

import FrequentFlyerTypeData from './FrequentFlyerTypeData';

export const FrequentFlyerTypeStateContext = createContext();

const FrequentFlyerTypeContextProvider = ({ children }) => {
  const stateHook = useState(FrequentFlyerTypeData.INITIAL_STATE);

  return (
    <FrequentFlyerTypeStateContext.Provider value={stateHook}>
      {children}
    </FrequentFlyerTypeStateContext.Provider>
  );
};

export default FrequentFlyerTypeContextProvider;