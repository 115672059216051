import { useContext } from 'react';

import { OfficialSelectionForEvaluationStateContext } from './OfficialSelectionForEvaluationsContextProvider';

import OfficialSelectionForEvaluationData from './OfficialSelectionForEvaluationData';

import Constants from '../../../common/utils/Constants';

const useOfficialSelectionForEvaluationData = () => {
  const [officialSelectionForEvaluationState, setOfficialSelectionForEvaluationState] = useContext(OfficialSelectionForEvaluationStateContext);

  const getOfficialsForEvaluationSelection = (otsMeetId, hasEvaluator) => OfficialSelectionForEvaluationData.getOfficialsForEvaluationSelectionData(otsMeetId, hasEvaluator, officialSelectionForEvaluationState, setOfficialSelectionForEvaluationState);
  const postOfficialSelectionForEvaluation = (otsMeetId, officialSelectionForEvaluationArray) => OfficialSelectionForEvaluationData.postOfficialSelectionForEvaluationData(otsMeetId, officialSelectionForEvaluationArray, officialSelectionForEvaluationState, setOfficialSelectionForEvaluationState);

  const clearOfficialSelectionForEvaluationObjData = () => {
    setOfficialSelectionForEvaluationState({
      ...officialSelectionForEvaluationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOfficialSelectionForEvaluationArrayData = () => {
    setOfficialSelectionForEvaluationState({
      ...officialSelectionForEvaluationState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      otsMeetId: Constants.DEFAULT_ID,
      hasEvaluator: ''
    });
  };

  const confirmOfficialSelectionForEvaluationSave = () => {
    setOfficialSelectionForEvaluationState({
      ...officialSelectionForEvaluationState,
      isSaved: false
    });
  };

  return {
    officialSelectionForEvaluationState,
    setOfficialSelectionForEvaluationState,
    getOfficialsForEvaluationSelection,
    postOfficialSelectionForEvaluation,
    clearOfficialSelectionForEvaluationObjData,
    clearOfficialSelectionForEvaluationArrayData,
    confirmOfficialSelectionForEvaluationSave
  };
};

export default useOfficialSelectionForEvaluationData;