import React, { Fragment } from 'react';

import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ split }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{split.splitNumber || ''}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Split
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {split.splitNumber || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>
          Cumulative Distance
        </div>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Cumul. Distance
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {split.cumulativeDistance || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Time
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {split.splitTime ? formatTimeForDisplay(split.splitTime) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ splits }) => (
  <div className={global.SmallTableDetailTable}>
    {splits.map((split, i) => <DetailGridRow key={i} split={split} />)}
  </div>
);

const GridRowIcons = ({ time, expandedId, onEdit, onExpandClicked }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, time.swimTimeId)}>
      {expandedId === time.swimTimeId ? <HideIcon /> : <ShowIcon />}
    </button>
    <button className={global.IconButtonMobileMargin}
      type="button"
      onClick={(e) => onEdit(time.swimTimeId)}>
      <EditIcon />
    </button>
  </Fragment>
);

const GridRow = ({ time, expandedId, onEdit, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{time.time || ''}</span>&nbsp;
        <GridRowIcons time={time} expandedId={expandedId} onEdit={onEdit} onExpandClicked={onExpandClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Time
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {time.time || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Competitor
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {time.competitor || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Meet Name
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {time.meetName || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Swim Date
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {time.swimDate || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Event
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {time.eventCourse || time.eventGender ? `${time.eventCourse} ${time.eventGender}` : <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Session
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {time.session || time.finishPosition ? `${time.session} ${time.finishPosition}` : <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    {Array.isArray(time.splits) && time.splits.length > 0 && expandedId === time.swimTimeId
      && <DetailGrid splits={time.splits} />
    }
  </Fragment>
);

const MemberTimesSmallGrid = ({ gridData, expandedId, isLoading, onEdit, onExpandClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true ? (
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
          &nbsp;&nbsp;Loading...
        </div>
      </div>
    ) : Array.isArray(gridData) && gridData.length > 0 ?
      gridData.map((time, i) => <GridRow key={i} time={time} expandedId={expandedId} onEdit={onEdit} onExpandClicked={onExpandClicked} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
            &nbsp;&nbsp;No Results
          </div>
        </div>
      )
    }
  </div>
);

export default MemberTimesSmallGrid;