import { formatDate } from '../../../common/utils/DateFunctions';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetMeetOfficialsData = (otsMeetId, officialPositionTypeId, hasEvaluator, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      otsMeetId,
      officialPositionTypeId,
      hasEvaluator,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const otsMeetIdForUrl = otsMeetId ? encodeURIComponent(otsMeetId) : 'NaN';
    const officialPositionTypeIdForUrl = officialPositionTypeId ? encodeURI(officialPositionTypeId) : 'NaN';
    const hasEvaluatorForUrl = hasEvaluator !== undefined ? encodeURI(hasEvaluator) : '';
    const url = `/MeetOfficial/OtsMeet/${otsMeetIdForUrl}/OfficialPositionType/${officialPositionTypeIdForUrl}?hasEvaluator=${hasEvaluatorForUrl}`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options: arrayData.map((official) => { return { id: official.meetOfficialId, name: `${official.firstName || ''} ${official.lastName || ''}` } }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetMeetOfficialsData;