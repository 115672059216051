import { useState } from 'react';

import PersonPersonasData from './PersonPersonasData';

const usePersonPersonasData = () => {
  const [personPersonasState, setPersonPersonasState] = useState(PersonPersonasData.INITIAL_STATE);

  const getPersonPersonas = (personId) => {
    PersonPersonasData.getPersonPersonasData(personId, personPersonasState, setPersonPersonasState);
  };

  return { personPersonasState, getPersonPersonas };
};

export default usePersonPersonasData;