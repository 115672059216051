import React, { createContext, useState } from 'react';

import SearchOtsOfficialData from './SearchOtsOfficialData';

export const SearchOtsOfficialStateContext = createContext();

const SearchOtsOfficialContextProvider = ({ children }) => {
  const stateHook = useState({ ...SearchOtsOfficialData.INITIAL_STATE });

  return (
    <SearchOtsOfficialStateContext.Provider value={stateHook}>
      {children}
    </SearchOtsOfficialStateContext.Provider>
  );
};

export default SearchOtsOfficialContextProvider;