import React, { Fragment } from "react";

import HQBoardMembersGridLarge from "./HQBoardMembersGridLarge";
import HQBoardMembersGridSmall from "./HQBoardMembersGridSmall";

import useExpandedRow from "../../../../common/components/grids/UseExpandedRow";

const HQBoardMembersGrid = ({ data, isLoading, onEdit, onDelete }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <HQBoardMembersGridLarge data={data} expandedId={expandedId} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} onExpandClicked={onClick} />
      <HQBoardMembersGridSmall data={data} expandedId={expandedId} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} onExpandClicked={onClick} />
    </Fragment>
  );
};
export default HQBoardMembersGrid;