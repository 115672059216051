import { isValidDate } from '../../../common/utils/validation';
import Constants from '../../../common/utils/Constants';

export const localValidate = (formState) => {
  const startDate = new Date(formState.startDate);
  const endDate = new Date(formState.endDate);

  let errors = {};
  let filterCount = 0;

  // Host Zone
  if (formState.zoneOrgUnitId !== Constants.DEFAULT_ID) {
    filterCount += 1;
  }

  // Host LSC
  if (formState.lscOrgUnitId !== Constants.DEFAULT_ID) {
    filterCount += 1;
  }

  //Host Club
  if (formState.orgUnitName !== '') {
    filterCount += 1;

    if (formState.orgUnitId === '') {
      errors.orgUnitId = 'Must be a valid Club Name';
    }
  }

  //Meet Name
  if (formState.meetName.trim() !== '') {
    if ((formState.meetName.trim()).length > 200) {
      errors.meetName = 'Meet Name cannot exceed 200 characters';
    } else {
      filterCount += 1;
    }
  }

  //Sanction Number
  if (formState.sanctionNumber.trim() !== '') {
    if (formState.sanctionNumber.trim().length > 20) {
      errors.sanctionNumber = 'Sanction Number cannot exceed 20 characters';
    }
    else {
      filterCount += 1;
    }
  }

  //OQM Number
  if (formState.oqmNumber.trim() !== '') {
    if (formState.oqmNumber.trim().length > 30) {
      errors.oqmNumber = 'OQM Number cannot exceed 30 characters';
    }
    else {
      filterCount += 1;
    }
  }

  //Start Date 
  if (formState.startDate !== Constants.BLANK_DATE_STRING) {
    if (!isValidDate(formState.startDate)) {
      errors.startDate = 'Start Date must be a valid date';
    } else if (endDate < startDate) {
      errors.startDate = 'Start Date must be before the End Date';
    } else {
      filterCount += 1;
    }
  }

  //End Date must be after the Start Date
  if (formState.endDate !== Constants.BLANK_DATE_STRING) {
    if (!isValidDate(formState.endDate)) {
      errors.endDate = 'End Date must be a valid date';
    } else if (endDate < startDate) {
      errors.endDate = 'End Date must be after the Start Date';
    } else {
      filterCount += 1;
    }
  }

  if (formState.meetClassification.length > 0) {
    filterCount += 1;
  }

  if (filterCount < 1) {
    errors.filter = 'Provide at least one filter to search';
  }

  return errors;
};

const SearchOtsMeetsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SearchOtsMeetsValidation;