import { Fragment } from 'react';

import OfficialSelectionForEvaluationOverrideGridLarge from './OfficialSelectionForEvaluationOverrideGridLarge';
import OfficialSelectionForEvaluationOverrideGridSmall from './OfficialSelectionForEvaluationOverrideGridSmall';

const OfficialSelectionForEvaluationOverrideGrid = ({ state, otsMeetHeaderState, gridState, showError, otsPositionWorkedEvaluationLevelEvaluatorNameState,
  onUpdateOfficialNameValueChange, onUpdateOfficialPositionWorkedValueChange, onUpdateEvaluatorNameValueChange, onUpdateEvaluationLevelValueChange, isLarge }) => {
  return (
    <Fragment>
      {isLarge === true ?
        <OfficialSelectionForEvaluationOverrideGridLarge
          state={state}
          otsMeetHeaderState={otsMeetHeaderState}
          gridState={gridState}
          showError={showError}
          otsPositionWorkedEvaluationLevelEvaluatorNameState={otsPositionWorkedEvaluationLevelEvaluatorNameState}
          onUpdateOfficialNameValueChange={onUpdateOfficialNameValueChange}
          onUpdateOfficialPositionWorkedValueChange={onUpdateOfficialPositionWorkedValueChange}
          onUpdateEvaluatorNameValueChange={onUpdateEvaluatorNameValueChange}
          onUpdateEvaluationLevelValueChange={onUpdateEvaluationLevelValueChange}
        /> :
        <OfficialSelectionForEvaluationOverrideGridSmall
          state={state}
          otsMeetHeaderState={otsMeetHeaderState}
          gridState={gridState}
          showError={showError}
          otsPositionWorkedEvaluationLevelEvaluatorNameState={otsPositionWorkedEvaluationLevelEvaluatorNameState}
          onUpdateOfficialNameValueChange={onUpdateOfficialNameValueChange}
          onUpdateOfficialPositionWorkedValueChange={onUpdateOfficialPositionWorkedValueChange}
          onUpdateEvaluatorNameValueChange={onUpdateEvaluatorNameValueChange}
          onUpdateEvaluationLevelValueChange={onUpdateEvaluationLevelValueChange} />
      }
    </Fragment>
  );
};
export default OfficialSelectionForEvaluationOverrideGrid;