import useListGroupForm from "./UseListGroupForm";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

const ListGroupForm = ({ personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj,
  onBackClicked, onFormCompletion }) => {
  const {
    handleSubmit
  } = useListGroupForm(personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj, onFormCompletion);

  return (
    <form>
      <div className="row"></div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="button" onClick={handleSubmit}>Join Group</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ListGroupForm;