import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import FileExclamationIcon from '../../../../../common/components/icons/FileExclamationIcon';

import { formatDate, getTodaysDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRowCourse = ({ officialCourse }) => {
  const todaysDate = getTodaysDate();
  const dateOfToday = new Date(todaysDate);
  const expirationDateForCourse = new Date(officialCourse?.courseExpirationDate);
  const formattedCourseExpirationDate = formatDate(officialCourse?.courseExpirationDate);
  return (
    <tr>
      <td style={{ width: '25%' }}>{officialCourse?.courseName} ({officialCourse?.courseCode})</td>
      <td style={{ width: '25%' }}>{officialCourse?.courseCompletionDate !== null && officialCourse?.coursePassed === true
        ? formatDate(officialCourse?.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
          ? ' '
          : formatDate(officialCourse?.courseCompletionDate)
        : ' '
      }</td>
      <td style={{ width: '25%' }}>
        {officialCourse?.courseExpirationDate !== null && officialCourse?.coursePassed === true
          ? formatDate(officialCourse?.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
            ? 'No Expiration'
            : expirationDateForCourse < dateOfToday
              ? <span style={{ color: 'red' }}>{formattedCourseExpirationDate}&nbsp;&nbsp;<FileExclamationIcon /></span>
              : formatDate(officialCourse?.courseExpirationDate)
          : ' '
        }
      </td>
      <td style={{ width: '25%' }}>
        {officialCourse?.status}
      </td>
    </tr >
  );
}

const GridRowDetailTableCourses = ({ official }) => (
  <td colSpan="7">
    <table className={global.DetailTable}>
      <thead>
        <tr>
          <th style={{ width: '25%' }}>Course Name</th>
          <th style={{ width: '25%' }}>Completion Date</th>
          <th style={{ width: '25%' }}>Expiration Date</th>
          <th style={{ width: '25%' }}>Status</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(official?.courses) && official?.courses?.length > 0
          ? official?.courses?.map((officialCourse, i) =>
            <DetailTableRowCourse
              key={i}
              officialCourse={officialCourse} />)
          : <tr><td colSpan='7'>No Courses</td></tr>
        }
      </tbody>
    </table>
  </td>
);

const LargeGridRow = ({ official, onSelectMember, expandedId, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td>
        <button className={global.AnchorButton}
          onClick={(e) => onSelectMember(e, official)}>
          {official.firstName || ''} {official.lastName || ''} - {official?.memberId}
        </button>
      </td>
      <td>{official?.lscName}</td>
      <td>{official?.clubName}</td>
      <td>{official?.roleName}</td>
      <td>{formatDate(official?.registrationExpirationDate)}</td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => { let id = official?.personId + official?.lscName + official?.clubName; onExpandClicked(e, id) }}>
          {expandedId === official.personId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {
      expandedId === official?.personId + official?.lscName + official?.clubName &&
      <Fragment>
        <tr className={global.Expanded}>
          <GridRowDetailTableCourses official={official} />
        </tr>
      </Fragment >
    }
  </Fragment >
);

const OtsOfficialsLargeGrid = ({ state, onSelectMember, expandedId, onExpandClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name - Member ID</th>
        <th>LSC</th>
        <th>Club Name</th>
        <th>Role</th>
        <th style={{ columnWidth: '80px' }}>Reg. Expires</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {state?.arrayData?.length > 0 ?
        state?.arrayData?.map((official, i) =>
          <LargeGridRow
            key={i}
            official={official}
            onSelectMember={onSelectMember}
            expandedId={expandedId}
            onExpandClicked={onExpandClicked}
          />)
        : state?.isArrayLoading
          ? <tr><td colSpan="6">Loading...</td></tr>
          : <tr><td colSpan="6">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default OtsOfficialsLargeGrid