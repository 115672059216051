import { Fragment } from 'react';

import useMemberUpgrade from './UseMemberUpgrade';

import LargeUpgradeGrid from './LargeUpgradeGrid';
import SmallUpgradeGrid from './SmallUpgradeGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberUpgrade = () => {
  const {
    personMembershipUpgradeState,
    gridState,
    errors,
    state,
    onCheckboxChange,
    onSelectAll,
    onUnselectAll,
    onUpgradeMembership,
    onWarningModalConfirmClicked,
    onWarningModalCancelClicked
  } = useMemberUpgrade();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <Headings.H3>Upgrade Membership</Headings.H3>
        </div>
      </div>
      {gridState.gridData.length > 5 && personMembershipUpgradeState.isArrayLoading === false &&
        < div className='visible-md visible-lg' >
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
            </div>
          </div>
          {errors?.general &&
            <div className="row">
              <div className="col-xs-12">
                <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errors?.general || ''}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <PrimaryButton type="button" onClick={onUpgradeMembership}>Upgrade Selected Memberships</PrimaryButton>&nbsp;
            </div>
          </div>
        </div >}
      {gridState.gridData.length > 0 && personMembershipUpgradeState.isArrayLoading === false &&
        < div className='hidden-md hidden-lg' >
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
            </div>
          </div>
          {errors?.general &&
            <div className="row">
              <div className="col-xs-12">
                <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errors?.general}</p>
              </div>
            </div>}
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <PrimaryButton type="button" onClick={onUpgradeMembership}>Upgrade Selected Memberships</PrimaryButton>&nbsp;
            </div>
          </div>
        </div >}
      <div className='row'>
        <div className='col-xs-12'>
          <Headings.H6>Membership(s) Eligible For Upgrade</Headings.H6>
          <LargeUpgradeGrid state={personMembershipUpgradeState} gridData={gridState.gridData} onCheckboxChange={onCheckboxChange} />
          <SmallUpgradeGrid state={personMembershipUpgradeState} gridData={gridState.gridData} onCheckboxChange={onCheckboxChange} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
        </div>
      </div>
      {errors?.general &&
        <div className="row">
          <div className="col-xs-12">
            <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errors?.general || ''}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onUpgradeMembership}>Upgrade Selected Memberships</PrimaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Upgrading to Unattached'}
        displayPopUp={state.displayWaringModal === true}
        onModalCanceled={onWarningModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>The club you are upgrading in has expired. Completing this upgrade will switch you to unattached.</p>
            <p>Are you sure you want to continue?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onWarningModalConfirmClicked}>Yes, Upgrade to Unattached</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onWarningModalCancelClicked}>No, Cancel Upgrade</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personMembershipUpgradeState?.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personMembershipUpgradeState?.isSaving} />
    </Fragment >
  );
};

export default MemberUpgrade;