import { isRegistrationPeriodInFuture } from '../../../utils/OfferingManagementUtils';

import EditIcon from '../../../../../../common/components/icons/EditIcon';
import SGColumnHeader from '../../../../../../common/components/grids/SGColumnHeader';

import { convertNumberToCurrencyString } from '../../../../../../common/utils/CurrencyUtils';
import { formatDate } from '../../../../../../common/utils/DateFunctions';
import useSortableGrid from '../../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../../common/utils/SGConstants';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ offeringInstance, onEditClicked }) => (
  <tr>
    <td>{offeringInstance.offeringName || ''}</td>
    <td>{offeringInstance.registrationStartDate ? formatDate(offeringInstance.registrationStartDate) : ''} &nbsp; - &nbsp; {offeringInstance.registrationEndDate ? formatDate(offeringInstance.registrationEndDate) : ''}</td>
    <td>{offeringInstance.effectiveDate ? formatDate(offeringInstance.effectiveDate) : ''} &nbsp; - &nbsp; {offeringInstance.expirationDate ? formatDate(offeringInstance.expirationDate) : ''}</td>
    <td>${convertNumberToCurrencyString(offeringInstance.feeAmount)}</td>
    <td>{offeringInstance.maxLscFeeAmount === null ? 'N/A' : `$${convertNumberToCurrencyString(offeringInstance.maxLscFeeAmount)}`}</td>
    <td>
      {isRegistrationPeriodInFuture(offeringInstance.registrationStartDate) === true &&
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onEditClicked(e, offeringInstance)}>
          <EditIcon />
        </button>
      }
    </td>
  </tr>
);

const OfferingInstanceGridLarge = ({ gridData, isLoading, onEditClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Offering Name'} columnField={'offeringName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Reg Start Date - Reg End Date</th>
          <th>Effective Date - Expiration Date</th>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Price'} columnField={'feeAmount'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Max LSC Price'} columnField={'maxLscFeeAmount'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="6">Loading...</td></tr>
          : (Array.isArray(sortableGridState.sortedGridData) === true && sortableGridState.sortedGridData.length > 0)
            ? sortableGridState.sortedGridData.map((offeringInstance, i) => (
              <GridRow
                key={i}
                offeringInstance={offeringInstance}
                onEditClicked={onEditClicked} />)
            ) : <tr><td colSpan="6">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default OfferingInstanceGridLarge;