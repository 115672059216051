import { useEffect, useState } from 'react';
import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

import useOtsOfficialLscData from '../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';
import useOfficialsTrackingSystemOnlineTestsData from '../../../../state/officialsTrackingSystemOnlineTests/UseOfficialsTrackingSystemOnlineTestsData';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_VIEW_STATE = {
  isLoading: true,
  gridData: {...BASIC_INITIAL_STATE}
};

const useOtsOfficialClinics = () => {
  const [ state, setState ] = useState({ ...INITIAL_VIEW_STATE });
  const { otsOfficialHeaderState } = useOtsOfficialLscData();
  const { getOfficialsTrackingSystemOnlineTests } = useOfficialsTrackingSystemOnlineTestsData();

  useEffect(() => {
    if (otsOfficialHeaderState?.objData?.personId){
      const onlineTestsPromise = getOfficialsTrackingSystemOnlineTests(otsOfficialHeaderState.objData.personId, true);
      if (onlineTestsPromise) {
        onlineTestsPromise.then((data) => {
          if (Array.isArray(data.arrayData)) {
            const gridData = data.arrayData.map((test) => {
              return {
                courseName: test.courseName,
                courseCompletionDate: test.courseCompletionDate ? formatDate(test.courseCompletionDate) : '',
                courseExpirationDate: test.courseExpirationDate 
                  ? formatDate(test.courseExpirationDate) 
                  : test.courseCompletionDate ? 'No expiration' : '',
                completionStatus: test.completionStatus
              }
            })
            setState({...INITIAL_VIEW_STATE, isLoading: false, gridData});
          } else {
            setState({...INITIAL_VIEW_STATE, isLoading: false});
          }
        }).catch((e) => {
          setState({...INITIAL_VIEW_STATE, isLoading: false});
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsOfficialHeaderState?.objData?.personId]);

  return {
    ...state
  };
};

export default useOtsOfficialClinics;