import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

const GetPersonPositionCertificationNoteData = (state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/PersonPositionCertificationNote`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const data = JSON.parse(JSON.stringify(arrayData));
          const blankPosition = { personPositionCertificationNoteId: Constants.DEFAULT_ID };
          data.unshift(blankPosition);
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options: data.map((personPositionCertificationNote) => { return { id: personPositionCertificationNote.personPositionCertificationNoteId, name: personPositionCertificationNote.personPositionCertificationNoteId !== Constants.DEFAULT_ID ? `${personPositionCertificationNote.note || ''}` : '--' } }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetPersonPositionCertificationNoteData;