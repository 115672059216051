import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRowIcons = ({ frequentFlyer, onAddEdit, onDelete }) => (
  <Fragment>
    <button className={global.IconButton} type="button" onClick={(e) =>
      onDelete(e, frequentFlyer.personFrequentFlyerId, `${frequentFlyer.frequentFlyerType.typeName} (Frequent Flyer Number: ${frequentFlyer.frequentFlyerNumber})`)}>
      <DeleteIcon />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onAddEdit(e, frequentFlyer.personFrequentFlyerId)}>
      <EditIcon />
    </button>
  </Fragment>
);

const GridRow = ({ frequentFlyer, onAddEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{frequentFlyer.frequentFlyerType?.typeName || ''}</span>&nbsp;
      <GridRowIcons frequentFlyer={frequentFlyer} onAddEdit={onAddEdit} onDelete={onDelete} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Carrier Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {frequentFlyer.frequentFlyerType?.typeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
          Frequent Flyer Number
        </div>
        <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
          {frequentFlyer.frequentFlyerNumber || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const NationalTeamFrequentFlyerSmallGrid = ({ gridState, onAddEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {gridState.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {gridState.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Frequent Flyer Info</div>}
          </div>
        </div>
      ) : Array.isArray(gridState.objData.frequentFlyerDetails)
        && gridState.objData.frequentFlyerDetails.length > 0
        ? gridState.objData.frequentFlyerDetails.map((frequentFlyer, i) => <GridRow key={i} frequentFlyer={frequentFlyer} onAddEdit={onAddEdit} onDelete={onDelete} />
        ) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Frequent Flyer Info</div>
            </div>
          </div>
        )
    }
  </div>
);

export default NationalTeamFrequentFlyerSmallGrid;