import { API_NAMES, initApi } from '../../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import getPersonCourses from './GetPersonCoursesData';
import getPersonCoursesForAdmin from './GetPersonCoursesForAdminData';
// import postEnrollmentMemberCoursesAdmin from './PostEnrollmentMemberCoursesAdminData';
// import putMemberCourseEnrollmentAdmin from './PutLmsUserAccountData';

const postEnrollmentMemberCoursesAdminData = (courseObj, state, setState) => {
  if (courseObj) {
    const api = initApi(API_NAMES.LMS, state, setState);
    const url = '/CourseEnrollments/courseenrollmentadmin';

    return api?.executeObject ? api.executeObject(url, 'POST', courseObj) : null;
  }
};

const putMemberCourseEnrollmentAdminData = (courseEnrollmentId, courseEnrollmentObj, state, setState) => {
  if (courseEnrollmentId > 0 && courseEnrollmentObj) {
    const api = initApi(API_NAMES.LMS, state, setState);
    const lmsCourseEnrollmentIdForUrl = courseEnrollmentId ? encodeURIComponent(courseEnrollmentId) : 'NaN';
    const url = `/CourseEnrollments/courseenrollment/${lmsCourseEnrollmentIdForUrl}/dates`;

    return api?.executeObject ? api.executeObject(url, 'PUT', courseEnrollmentObj) : null;
  }
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonCoursesData = {
  INITIAL_STATE: { ...INITIAL_STATE },
  getPersonCourses,
  getPersonCoursesForAdmin,
  postEnrollmentMemberCoursesAdminData,
  putMemberCourseEnrollmentAdminData
};

export default PersonCoursesData;