import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ test }) => (
  <tr>
    <td>{test.courseName || ''}</td>
    <td>{test.courseCompletionDate || ''}</td>
    <td>{test.courseExpirationDate || ''}</td>
    <td>{test.completionStatus || ''}</td>
  </tr>
);

const OtsOfficialOnlineTestsGridLarge = ({ gridData }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Test Name</th>
          <th>Date Completed</th>
          <th>Expiration Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(gridData) && gridData.length > 0
          ? gridData?.map((test, i) => <LargeGridRow key={i} test={test} />)
          : <tr><td colSpan="4">No Results</td></tr>
        }
      </tbody>
    </table>
  );
};

export default OtsOfficialOnlineTestsGridLarge;