import { useContext } from 'react';

import { OfficialEvaluationStateContext } from './OfficialEvaluationsContextProvider';

import OfficialEvaluationData from './OfficialEvaluationData';

import Constants from '../../../common/utils/Constants';

const useOfficialEvaluationData = () => {
  const [officialEvaluationState, setOfficialEvaluationState] = useContext(OfficialEvaluationStateContext);

  const getOfficialEvaluationsByMeetEvaluationId = (meetEvaluationId) => OfficialEvaluationData.getOfficialEvaluationsByMeetEvaluationIdData(meetEvaluationId, officialEvaluationState, setOfficialEvaluationState);
  const postOfficialEvaluation = (meetEvaluationId, officialEvaluationArray) => OfficialEvaluationData.postOfficialEvaluationData(meetEvaluationId, officialEvaluationArray, officialEvaluationState, setOfficialEvaluationState);
  const putOfficialEvaluation = (meetEvaluationId, officialEvaluationArray) => OfficialEvaluationData.putOfficialEvaluationData(meetEvaluationId, officialEvaluationArray, officialEvaluationState, setOfficialEvaluationState);
  
  const clearOfficialEvaluationObjData = () => {
    setOfficialEvaluationState({
      ...officialEvaluationState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOfficialEvaluationArrayData = () => {
    setOfficialEvaluationState({
      ...officialEvaluationState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: '',
      meetEvaluationId: Constants.DEFAULT_ID
    });
  };

  const confirmOfficialEvaluationSave = () => {
    setOfficialEvaluationState({
      ...officialEvaluationState,
      isSaved: false
    });
  };

  return {
    officialEvaluationState,
    getOfficialEvaluationsByMeetEvaluationId,
    postOfficialEvaluation,
    putOfficialEvaluation,
    clearOfficialEvaluationObjData,
    clearOfficialEvaluationArrayData,
    confirmOfficialEvaluationSave
  };
};

export default useOfficialEvaluationData;