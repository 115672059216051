import React, { Fragment } from 'react';

import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';
import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ organizationId, affiliation, onEditClicked, USAS_ORGANIZATION_ID }) => (
  <tr>
    <td>{affiliation.parentOrgUnitCode || ''} - {affiliation.orgUnitName || ''}</td>
    <td>{affiliation.orgRoleName || ''}</td>
    <td>{affiliation.effectiveDate ? formatDate(affiliation.effectiveDate) : ''}</td>
    <td>{affiliation.expirationDate ? formatDate(affiliation.expirationDate) : ''}</td>
    <td>
      {onEditClicked && organizationId !== USAS_ORGANIZATION_ID && Array.isArray(affiliation.personOrgRoleFields) &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onEditClicked(e, affiliation.personOrgRoleDurationId)}>
          <EditIcon />
        </button>
      }
    </td>
  </tr>
);

const GridRowDetailTable = ({ organizationId, affiliations, onEditClicked, USAS_ORGANIZATION_ID }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Team</th>
        <th>Member Role</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(affiliations) && affiliations.map((affiliation, i) => <DetailTableRow key={i} organizationId={organizationId} affiliation={affiliation} onEditClicked={onEditClicked} USAS_ORGANIZATION_ID={USAS_ORGANIZATION_ID} />)}
    </tbody>
  </table>
);

const GridRow = ({ organization, expandedId, onEditClicked, onExpandClicked, USAS_ORGANIZATION_ID }) => (
  <Fragment>
    <tr>
      <td colSpan='4'>{organization.organizationName || ''}</td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, organization.organizationId)}>
          {expandedId === organization.organizationId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {expandedId === organization.organizationId &&
      <tr className={global.Expanded}>
        {Array.isArray(organization.personAffiliationDurations) && organization.personAffiliationDurations.length > 0
          ? (
            <td colSpan="5">
              <GridRowDetailTable organizationId={organization.organizationId} affiliations={organization.personAffiliationDurations} onEditClicked={onEditClicked} USAS_ORGANIZATION_ID={USAS_ORGANIZATION_ID} />
            </td>
          )
          : (
            <td colSpan="5">
              <table className={global.DetailTable}>
                <thead>
                  <tr>
                    <th>No Affiliations</th>
                  </tr>
                </thead>
              </table>
            </td>
          )
        }
      </tr>
    }
  </Fragment>
);

const MemberAffiliationsGridLarge = ({ gridData, isLoading, expandedId, onEditClicked, onExpandClicked, USAS_ORGANIZATION_ID }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th colSpan='4'>Organization</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true ?
        <tr><td colSpan="5">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0 ?
          gridData.map((organization, i) => Array.isArray(organization.personAffiliationDurations) && <GridRow key={i} organization={organization} expandedId={expandedId} onEditClicked={onEditClicked} onExpandClicked={onExpandClicked} USAS_ORGANIZATION_ID={USAS_ORGANIZATION_ID} />)
          :
          <tr><td colSpan="5">No Affiliations</td></tr>
      }
    </tbody>
  </table >
);

export default MemberAffiliationsGridLarge;