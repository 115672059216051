import React from 'react';

import { formatDate } from '../../../utils/DateFunctions';
import { formatTimeForDisplay } from '../../../utils/TimesUtils';

import global from '../../GlobalStyle.module.css';

const GridRow = ({ eventQualification }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{eventQualification.event?.eventName || ''}</span>&nbsp;</div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Event
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {eventQualification.event?.eventName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Swim Time
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {eventQualification.swimTime ? formatTimeForDisplay(eventQualification.swimTime) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Swim Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {eventQualification.swimDate ? formatDate(eventQualification.swimDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Meet Name
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {eventQualification.meetName || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const GroupQualificationEventGridSmall = ({ data, isLoading }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
            &nbsp;&nbsp;Loading...
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((eventQualification, i) => <GridRow key={i} eventQualification={eventQualification} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
              &nbsp;&nbsp;No Event Qualifications
            </div>
          </div>
        )
    }
  </div>
);

export default GroupQualificationEventGridSmall;