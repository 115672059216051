import { createContext, useState } from 'react';

import PersonNationalTeamFileTypeData from './PersonNationalTeamFileTypeData';

export const PersonNationalTeamFileTypeStateContext = createContext();

const PersonNationalTeamFileTypeContextProvider = ({ children }) => {
  const stateHook = useState(PersonNationalTeamFileTypeData.INITIAL_STATE);

  return (
    <PersonNationalTeamFileTypeStateContext.Provider value={stateHook}>
      {children}
    </PersonNationalTeamFileTypeStateContext.Provider>
  );
};

export default PersonNationalTeamFileTypeContextProvider;