import React, { createContext, useState } from 'react';

import NarrativeTypeData from './NarrativeTypeData';

export const NarrativeTypeStateContext = createContext();

const NarrativeTypeContextProvider = ({ children }) => {
  const stateHook = useState(NarrativeTypeData.INITIAL_STATE);

  return (
    <NarrativeTypeStateContext.Provider value={stateHook}>
      {children}
    </NarrativeTypeStateContext.Provider>
  );
};

export default NarrativeTypeContextProvider;