import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRowIcons = ({ missedDrugTest, onAddEdit, onDelete }) => (
  <Fragment>
    <button className={global.IconButton} type="button" onClick={(e) => onAddEdit(e, missedDrugTest.personMissingDrugTestId)}>
      <EditIcon />
    </button>
  </Fragment>
);

const GridRow = ({ missedDrugTest, onAddEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{missedDrugTest.missingDrugTestDate ? formatDate(missedDrugTest.missingDrugTestDate) : ''}</span>&nbsp;
      <GridRowIcons missedDrugTest={missedDrugTest} onAddEdit={onAddEdit} onDelete={onDelete} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Missed Test
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {missedDrugTest.missingDrugTestDate ? formatDate(missedDrugTest.missingDrugTestDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Testing Body
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {missedDrugTest.testingBody?.bodyName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Status
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {missedDrugTest.drugTestStatus?.statusName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Notified By Body
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {missedDrugTest.notificationFromTestBodyDate ? formatDate(missedDrugTest.notificationFromTestBodyDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Athlete Notified
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {missedDrugTest.athleteNotifiedDate ? formatDate(missedDrugTest.athleteNotifiedDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Appealed
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {missedDrugTest.testAppealedDate ? formatDate(missedDrugTest.testAppealedDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Appeal Response
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {missedDrugTest.testBodyAppealResponseDate ? formatDate(missedDrugTest.testBodyAppealResponseDate) : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const NationalTeamMissedDrugTestsSmallGrid = ({ gridState, onAddEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {gridState.isObjLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            {gridState.isObjLoading === true
              ? <div className={global.SmallTableRowLabels}>Loading...</div>
              : <div className={global.SmallTableRowLabels}>No Missing Drug Tests</div>
            }
          </div>
        </div>
      ) : Array.isArray(gridState.objData.missingDrugTests)
        && gridState.objData.missingDrugTests.length > 0
        ? gridState.objData.missingDrugTests.map((missedDrugTest, i) => <GridRow key={i} missedDrugTest={missedDrugTest} onAddEdit={onAddEdit} onDelete={onDelete} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Missing Drug Tests</div>
            </div>
          </div>
        )
    }
  </div>
);

export default NationalTeamMissedDrugTestsSmallGrid;