import { useEffect, useState } from 'react';

import validate from './MemberDisabilitiesValidation';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import usePersonGeneralData from '../../../../common/state/personGeneral/UsePersonGeneralData';
import useDisabilityTypeData from '../../../../common/state/disabilityType/UseDisabilityTypeData';
import useForm from '../../../../common/utils/UseForm';

const useMemberDisabilities = () => {
  const { disabilityTypeState, getDisabilityTypes } = useDisabilityTypeData();
  const { basePersonState, putBasePerson } = useBasePersonData();
  const { personGeneralState, getPersonGeneral } = usePersonGeneralData();
  const {
    formState,
    errorState,
    setFormData,
    onFormValueChanged,
    handleSubmit,
    resetForm
  } = useForm(initialFormState, submitFormCallBack, validate);
  const [state, setState] = useState({
    isCancelClicked: false,
    isAthlete: true,
    hierarchicalOptions: [],
    displayDisabilityTypeNames: true,
    disabilityTypeNames: '',
    disabilityCategory: ''
  });
  const LEGACY_DISABILITY_TYPES = {
    nonAthleteIds: [22, 23, 24, 26],
    nonAthleteIdToSet: 21,
    athleteIds: [19, 20],
    athleteIdToSet: 8,
    athleteString: "Athlete"
  };

  function initialFormState() {
    return { disabilityTypes: [] };
  };

  function ifDisabilityTypesContainsPotentialLegacyChildren(disabilityTypes, arrayToCompare) {
    if (disabilityTypes.some((disability) => arrayToCompare.includes(disability.id))) {
      return true;
    } else {
      return false;
    }
  }

  function ifDisabilityTypesContainsParentForChildren(disabilityTypes, valueToCompare) {
    if (disabilityTypes.some((disability) => disability.id === valueToCompare)) {
      return true;
    } else {
      return false;
    }
  }

  function isAthlete() {
    if (Array.isArray(personGeneralState.objData.personOrgRole)
      && personGeneralState.objData.personOrgRole.some((orgRole) => orgRole.status === LEGACY_DISABILITY_TYPES.athleteString) === true) {
      return true;
    } else {
      return false;
    }
  }

  function filterHierarchicalOptions() {
    const athleteHierarchicalOptions = [...disabilityTypeState.hierarchicalOptions].filter(option => option.isAthleteDisabilityType);
    const nonAthleteHierarchicalOptions = [...disabilityTypeState.hierarchicalOptions].filter(option => option.isNonAthleteDisabilityType);
    if (isAthlete() === true) {
      return athleteHierarchicalOptions;
    } else {
      return nonAthleteHierarchicalOptions;
    }
  }

  function setLegacyDisabilities(types, setOfDisabilityIds, newParentDisabilityId) {
    if (ifDisabilityTypesContainsPotentialLegacyChildren(types, setOfDisabilityIds)
      && !ifDisabilityTypesContainsParentForChildren(types, newParentDisabilityId)) {
      types.push({ id: newParentDisabilityId });
      onFormValueChanged("id", newParentDisabilityId);
    }
    return types;
  }

  function createDisabilityArrayForPut() {
    const personId = basePersonState.objData.personId;
    return [...formState.disabilityTypes].map(disability => {
      return {
        personId: personId,
        disabilityTypeId: disability.id,
        isAthlete: state.isAthlete
      };
    });
  }

  const createUsasPersonIfUndefinedOrNull = {
    personId: basePersonState.objData.personId,
    maidenName: null,
    suffix: null,
    maidenNameUsedAsLastName: false,
    birthDateConfirmed: false,
    isUsCitizen: false,
    hispanicOrLatinoTypeId: null,
    hispanicOrLatinoType: undefined,
    personDisability: [],
    personEthnicity: [],
    personNarrative: []
  };

  function createPutBasePersonWithNewDisabilities() {
    const disabilityArray = createDisabilityArrayForPut();
    let member = JSON.parse(JSON.stringify(basePersonState.objData));

    if (member.usasPerson === undefined || member.usasPerson === null) {
      member.usasPerson = createUsasPersonIfUndefinedOrNull;
    }

    member = {
      ...member,
      usasPerson: {
        ...member.usasPerson,
        personDisability: disabilityArray
      }
    };
    return member;
  }

  function submitFormCallBack() {
    if (state.isCancelClicked === false) {
      putBasePerson(basePersonState.objData.personId, createPutBasePersonWithNewDisabilities());
    }
    else {
      setState({
        ...state,
        isCancelClicked: false
      });
    }
  };

  useEffect(() => {
    if (personGeneralState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personGeneralState.objData)) {
      getPersonGeneral(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  useEffect(() => {
    if (disabilityTypeState.isArrayLoaded === false
      && disabilityTypeState.isArrayLoading === false) {
      getDisabilityTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabilityTypeState]);

  useEffect(() => {
    if (basePersonState.isObjLoaded === true &&
      personGeneralState.isObjLoaded === true &&
      disabilityTypeState.isArrayLoaded === true
      && disabilityTypeState.hierarchicalOptions.length > 0) {
      const hierarchicalOptions = filterHierarchicalOptions();
      setState({
        ...state,
        hierarchicalOptions
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, personGeneralState, disabilityTypeState.isArrayLoaded, disabilityTypeState.hierarchicalOptions]);

  useEffect(() => {
    if (disabilityTypeState.isArrayLoaded === true
      && state.hierarchicalOptions.length > 0
      && basePersonState.isObjLoaded === true
      && basePersonState.isObjLoading === false
      && personGeneralState.isObjLoaded === true
      && personGeneralState.isObjLoading === false) {
      let newFormState;
      let displayDisabilityTypeNames = true;
      let disabilityCategory = 'Not Specified';
      let disabilityTypeNames = ['Not Specified'];
      if (basePersonState.objData.usasPerson === null) {
        newFormState = {
          ...formState,
          disabilityTypes: []
        };
      } else {
        let disabilityTypes = basePersonState.objData.usasPerson.personDisability.map(x => {
          return { id: x.disabilityTypeId };
        });
        disabilityTypeNames = [];
        // eslint-disable-next-line array-callback-return
        basePersonState.objData.usasPerson.personDisability.map(x => {
          if (x.disabilityType.parentId === null) {
            disabilityCategory = x.disabilityType.typeName;
          } else {
            disabilityTypeNames.push(x.disabilityType.typeName);
          }
        });
        if (disabilityTypeNames.length === 0) {
          displayDisabilityTypeNames = false;
        }
        if (isAthlete() === true) {
          disabilityTypes = setLegacyDisabilities(disabilityTypes, LEGACY_DISABILITY_TYPES.athleteIds, LEGACY_DISABILITY_TYPES.athleteIdToSet);
        } else {
          disabilityTypes = setLegacyDisabilities(disabilityTypes, LEGACY_DISABILITY_TYPES.nonAthleteIds, LEGACY_DISABILITY_TYPES.nonAthleteIdToSet);
        }
        newFormState = {
          ...formState,
          disabilityTypes
        };
      }
      setState({
        ...state,
        displayDisabilityTypeNames,
        disabilityCategory,
        disabilityTypeNames,
        isAthlete: isAthlete()
      });
      setFormData(newFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, personGeneralState, disabilityTypeState.isArrayLoaded, state.hierarchicalOptions]);

  return {
    disabilityTypeState,
    basePersonState,
    personGeneralState,
    state,
    formState,
    errorState,
    hierarchicalOptions: state.hierarchicalOptions,
    onFormValueChanged,
    handleSubmit,
    resetForm
  };
};

export default useMemberDisabilities;

