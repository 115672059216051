import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const NAV_LINK = '/offeringmanagement/lsc/offering';

const useOrgUnitSelection = () => {
  const { LSC_OFFERING_MGMT_ROLE_GROUP_ID } = useEnvironmentVariableData();

  return {
    NAV_LINK,
    LSC_OFFERING_MGMT_ROLE_GROUP_ID
  };
};

export default useOrgUnitSelection;