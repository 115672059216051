import { useEffect, useState } from 'react';

import validate from './CampRegistrationFormValidation';

import { createGroupMemberObj, getGroupPropertyOptions, getNewGroupStatus } from '../../../utils/GroupsUtils';

import useGroupFieldNamesData from '../../../state/groupFieldNames/UseGroupFieldNamesData';
import useOrgGroupPropertiesData from '../../../state/orgGroupProperties/UseOrgGroupPropertiesData';
import useGroupPersonCoachesData from '../../../state/groupPersonCoaches/UseGroupPersonCoachesData';

import UseForm from '../../../utils/UseForm';

const INITIAL_FORM_STATE = {
  groupAttendeeTypeId: '',
  groupAttendeeTypeName: '--',
  coachOfRecordId: '',
  coachOfRecordName: '--'
};

const INITIAL_OPTIONS_STATE = {
  areOptionsLoaded: false,
  attendTypeOptions: []
};

const useCampRegistrationForm = (personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj, disableValidation, onFormCompletion) => {
  const { groupPersonCoachesState } = useGroupPersonCoachesData();
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { formState, errorState, setFormState, onValueTextPairChanged, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, disableValidation === true ? () => { } : validate);
  const [optionsState, setOptionsState] = useState(INITIAL_OPTIONS_STATE);

  useEffect(() => {
    if (groupObj.groupTypeId > 0 && optionsState.areOptionsLoaded === false) {
      if (orgGroupPropertiesState.isArrayLoaded === false || groupObj.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
        const getOrgGroupPropertiesPromise = getOrgGroupProperties(groupObj.groupTypeId);

        if (getOrgGroupPropertiesPromise !== null) {
          getOrgGroupPropertiesPromise.catch((e) => {
            //TODO
          });
        }
      } else {
        const attendTypeOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE, orgGroupPropertiesState.arrayData);

        setOptionsState({
          ...optionsState,
          attendTypeOptions,
          areOptionsLoaded: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupObj, orgGroupPropertiesState.isArrayLoaded]);

  useEffect(() => {
    if (optionsState.areOptionsLoaded === true && memberGroupObj && groupPersonCoachesState.areOptionsLoaded === true
      && groupPersonCoachesState.personId === personId) {
      const personGroupTypeField = memberGroupObj.personGroupTypeField || [];
      const groupAttendeeTypeName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE)?.fieldValue || '';
      const coachOfRecordId = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COACH_OF_RECORD)?.fieldValue || '';

      const groupAttendeeType = optionsState.attendTypeOptions.find(x => x.name === groupAttendeeTypeName);
      const coachOfRecord = groupPersonCoachesState.options.find(x => x.id === coachOfRecordId);

      setFormState({
        ...formState,
        groupAttendeeTypeId: groupAttendeeType?.id || optionsState.attendTypeOptions[0]?.id || '',
        groupAttendeeTypeName: groupAttendeeType?.name || optionsState.attendTypeOptions[0]?.name || '--',
        coachOfRecordId: coachOfRecord?.id || groupPersonCoachesState.options[0]?.id || '',
        coachOfRecordName: coachOfRecord?.name || groupPersonCoachesState.options[0]?.name || '--',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsState.areOptionsLoaded, groupPersonCoachesState.areOptionsLoaded, groupPersonCoachesState.personId, memberGroupObj]);

  function submitFormCallback(formState) {
    const orgGroupId = groupObj?.orgGroupId;
    const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
      ? memberGroupObj.personOrgGroupQualification[0]
      : undefined;

    if (personId && orgGroupId) {
      const groupStatus = getNewGroupStatus(personOrgGroupQualificationStatus, groupObj.requiresApproval);

      const groupMemberObj = createGroupMemberObj(personId, orgGroupId, [
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE, fieldValue: formState.groupAttendeeTypeId },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COACH_OF_RECORD, fieldValue: formState.coachOfRecordId }
      ], orgGroupPropertiesState.arrayData, personOrgGroupId, personOrgGroupQualification, groupStatus);

      onFormCompletion(groupMemberObj);
    }
  };

  return {
    isLoading: groupPersonCoachesState.isArrayLoading,
    formState,
    errorState,
    optionsState,
    onValueTextPairChanged,
    handleSubmit
  };
};

export default useCampRegistrationForm;