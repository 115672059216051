import { useEffect, useState } from 'react';

import validate from './OtsMeetInfoValidation';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';

import useOtsMeetSessionsData from '../../../../../common/state/officialsTrackingSystemMeetSessions/UseOtsMeetSessionsData';

import UseForm from '../../../../../common/utils/UseForm';

const INITIAL_MODAL_STATE = {
  modalTitle: 'Officials Qualifying Meet (OQM) Selection and OQM Meet Session(s) Change Confirmation',
  displayPopUp: false,
}

const useOtsMeetInfo = () => {
  const { otsMeetState, otsMeetHeaderState, getOtsMeet, putOtsMeet } = useOtsMeetData();
  const { otsMeetSessionsState, getMeetSessions, getMeetSessionsSetIsQualifyingForAllSessionsInMeet } = useOtsMeetSessionsData();
  const { formState, errorState, handleSubmit, updateFormState, setFormData, setFormState,
    resetForm } = UseForm(getInitialFormState(), submitFormCallback, validate);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onIsOfficialsQualifyingMeetIsChangingClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
    const otsMeetObj = {
      otsMeetId: otsMeetState.objData?.otsMeetId,
      meetId: otsMeetState.objData?.meetId,
      isOfficialsQualifyingMeet: formState.isOfficialsQualifyingMeet,
      oqmNumber: formState.isOfficialsQualifyingMeet === true ? formState.oqmNumber.trim() : null
    }
    putOtsMeet(otsMeetState.objData?.otsMeetId, otsMeetObj);
    getMeetSessionsSetIsQualifyingForAllSessionsInMeet(otsMeetState.objData?.meetId);
  };

  const onIsOfficialsQualifyingMeetIsNotChangingClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({ ...formState, isOfficialsQualifyingMeet: otsMeetState.objData?.isOfficialsQualifyingMeet });
    setModalState(INITIAL_MODAL_STATE);
  };

  useEffect(() => {
    if (otsMeetHeaderState.isObjLoaded === true && ((otsMeetState.isObjLoaded === false ||
      (otsMeetState.objData?.otsMeetId !== otsMeetHeaderState.objData?.otsMeetId)) || otsMeetSessionsState.isArrayLoaded === false)
      && otsMeetState.isObjLoading === false && otsMeetSessionsState.isArrayLoading === false) {
      getOtsMeet(otsMeetHeaderState.objData?.otsMeetId);
      getMeetSessions(otsMeetHeaderState.objData?.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetState, otsMeetHeaderState, otsMeetSessionsState])

  useEffect(() => {
    if (otsMeetState.isObjLoaded === true) {
      setFormData({
        ...formState,
        isOfficialsQualifyingMeet: otsMeetState.objData?.isOfficialsQualifyingMeet ?? '',
        oqmNumber: otsMeetState.objData?.oqmNumber || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetState])

  function submitFormCallback(formState) {
    let popUpNeeded = false;
    if (formState.isOfficialsQualifyingMeet === false && otsMeetSessionsState.arrayData.length > 0) {
      for (let i = 0; i < otsMeetSessionsState.arrayData.length; i++) {
        if (otsMeetSessionsState.arrayData[i].isOfficialsQualifyingSession === true) {
          popUpNeeded = true;
        }
      }
    }
    if (popUpNeeded === false) {
      const otsMeetObj = {
        otsMeetId: otsMeetState.objData?.otsMeetId,
        meetId: otsMeetState.objData?.meetId,
        isOfficialsQualifyingMeet: formState.isOfficialsQualifyingMeet,
        oqmNumber: formState.isOfficialsQualifyingMeet === true ? formState.oqmNumber.trim() : null
      }
      putOtsMeet(otsMeetState.objData?.otsMeetId, otsMeetObj);
    }
    else {
      setModalState({ ...modalState, displayPopUp: true });
    }
  }

  function getInitialFormState() {
    return {
      isOfficialsQualifyingMeet: '',
      oqmNumber: ''
    };
  }

  return {
    otsMeetState,
    otsMeetSessionsState,
    handleSubmit,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    resetForm,
    modalState,
    onIsOfficialsQualifyingMeetIsChangingClicked,
    onIsOfficialsQualifyingMeetIsNotChangingClicked
  };
};

export default useOtsMeetInfo;