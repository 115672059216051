import { useContext } from 'react';

import { PersonPositionCertificationNoteStateContext } from './PersonPositionCertificationNoteContextProvider';

import PersonPositionCertificationNoteData from './PersonPositionCertificationNoteData';

const usePersonPositionCertificationNoteData = () => {
  const [personPositionCertificationNoteState, setPersonPositionCertificationNoteState] = useContext(PersonPositionCertificationNoteStateContext);

  const getPersonPositionCertificationNotes = () => PersonPositionCertificationNoteData.getPersonPositionCertificationNoteData(personPositionCertificationNoteState, setPersonPositionCertificationNoteState);

  const clearPersonPositionCertificationNoteArrayData = () => {
    setPersonPositionCertificationNoteState({
      ...personPositionCertificationNoteState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  return {
    personPositionCertificationNoteState,
    getPersonPositionCertificationNotes,
    clearPersonPositionCertificationNoteArrayData
  };
};

export default usePersonPositionCertificationNoteData;