import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const DeleteOrgUnitAthleteData = (personRoleDurationId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Deleting...'
    };
    setState(newState);

    const personRoleDurationIdForUrl = personRoleDurationId ? encodeURIComponent(personRoleDurationId) : 'NaN';
    const url = `/OrgUnit/OtherOrganization/Athlete/${personRoleDurationIdForUrl}`;
    CommonHttpHelper(url, 'DELETE', personRoleDurationId)
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoaded: true,
            isSaving: false,
            isSaved: true,
            arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoaded: false,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

export default DeleteOrgUnitAthleteData;