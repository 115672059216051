import React, { Fragment } from 'react';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ athlete, FINA_ORGANIZATION_ID, FINA_ORGANIZATION_NAME, NCAA_ORGANIZATION_ID, NCAA_ORGANIZATION_NAME, ISL_ORGANIZATION_NAME }) => (
  <tr>
    <td>{athlete.firstName || ''} {athlete.lastName || ''}</td>
    <td>{athlete.birthDate ? formatDate(athlete.birthDate) : ''}</td>
    <td>{athlete.competitionGenderType?.typeName || ''}</td>
    <td>{athlete.personOrgRole.length === 0
      ? ''
      : athlete.personOrgRole[0].orgRole.organizationId === FINA_ORGANIZATION_ID
        ? FINA_ORGANIZATION_NAME + " | " + athlete.personOrgRole[0].personOrgRoleDuration[0].orgUnit.orgUnitName
        : (athlete.personOrgRole[0].orgRole.organizationId === NCAA_ORGANIZATION_ID
          ? NCAA_ORGANIZATION_NAME + " | " + athlete.personOrgRole[0].personOrgRoleDuration[0].orgUnit.orgUnitName
          : ISL_ORGANIZATION_NAME + " | " + athlete.personOrgRole[0].personOrgRoleDuration[0].orgUnit.orgUnitName)
    }</td>
    <td>{athlete.personOrgRole.length === 0
      ? ''
      : athlete.personOrgRole[0].personOrgRoleDuration[0].effectiveDate
        ? formatDate(athlete.personOrgRole[0].personOrgRoleDuration[0].effectiveDate)
        : ''
    }</td>
    <td>{athlete.personOrgRole.length === 0
      ? ''
      : athlete.personOrgRole[0].personOrgRoleDuration[0].expirationDate
        ? formatDate(athlete.personOrgRole[0].personOrgRoleDuration[0].expirationDate)
        : ''
    }</td>
  </tr>
);

const LargeGrid = ({ state, FINA_ORGANIZATION_ID, FINA_ORGANIZATION_NAME, NCAA_ORGANIZATION_ID,
  NCAA_ORGANIZATION_NAME, ISL_ORGANIZATION_NAME }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Birth Date</th>
        <th>Competition Category</th>
        <th>Organization</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(state) && state.length > 0
        ? state.map((athlete, i) => <LargeGridRow key={i} athlete={athlete} FINA_ORGANIZATION_ID={FINA_ORGANIZATION_ID} FINA_ORGANIZATION_NAME={FINA_ORGANIZATION_NAME} NCAA_ORGANIZATION_ID={NCAA_ORGANIZATION_ID}
          NCAA_ORGANIZATION_NAME={NCAA_ORGANIZATION_NAME} ISL_ORGANIZATION_NAME={ISL_ORGANIZATION_NAME} />)
        : <tr><td colSpan="6">No Athletes Added</td></tr>
      }
    </tbody>
  </table>
);

const SmallGridRow = ({ athlete, FINA_ORGANIZATION_ID, FINA_ORGANIZATION_NAME, NCAA_ORGANIZATION_ID,
  NCAA_ORGANIZATION_NAME, ISL_ORGANIZATION_NAME }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'> {athlete.firstName || ''} {athlete.lastName || ''}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {athlete.firstName || athlete.lastName ? `${athlete.firstName || ''} ${athlete.lastName || ''}` : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Birth Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {athlete.birthDate ? formatDate(athlete.birthDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3 hidden-xs', global.SmallTableRowLabels].join(' ')}>
          Competition Category
        </div>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Comp. Category
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {athlete.competitionGenderType?.typeName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Organization
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {athlete.personOrgRole.length === 0
            ? <span>&nbsp;</span>
            : athlete.personOrgRole[0].orgRole.organizationId === FINA_ORGANIZATION_ID
              ? FINA_ORGANIZATION_NAME + " | " + athlete.personOrgRole[0].personOrgRoleDuration[0].orgUnit.orgUnitName
              : (athlete.personOrgRole[0].orgRole.organizationId === NCAA_ORGANIZATION_ID
                ? NCAA_ORGANIZATION_NAME + " | " + athlete.personOrgRole[0].personOrgRoleDuration[0].orgUnit.orgUnitName
                : ISL_ORGANIZATION_NAME + " | " + athlete.personOrgRole[0].personOrgRoleDuration[0].orgUnit.orgUnitName)
          }
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Effective Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {athlete.personOrgRole.length === 0
            ? <span>&nbsp;</span>
            : athlete.personOrgRole[0].personOrgRoleDuration[0].effectiveDate
              ? formatDate(athlete.personOrgRole[0].personOrgRoleDuration[0].effectiveDate)
              : <span>&nbsp;</span>
          }
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Expiration Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {athlete.personOrgRole.length === 0
            ? <span>&nbsp;</span>
            : athlete.personOrgRole[0].personOrgRoleDuration[0].expirationDate
              ? formatDate(athlete.personOrgRole[0].personOrgRoleDuration[0].expirationDate)
              : <span>&nbsp;</span>
          }
        </div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ state, FINA_ORGANIZATION_ID, FINA_ORGANIZATION_NAME, NCAA_ORGANIZATION_ID,
  NCAA_ORGANIZATION_NAME, ISL_ORGANIZATION_NAME }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(state) && state.length > 0
      ? state.map((athlete, i) => <SmallGridRow key={i} athlete={athlete} FINA_ORGANIZATION_ID={FINA_ORGANIZATION_ID} FINA_ORGANIZATION_NAME={FINA_ORGANIZATION_NAME} NCAA_ORGANIZATION_ID={NCAA_ORGANIZATION_ID}
        NCAA_ORGANIZATION_NAME={NCAA_ORGANIZATION_NAME} ISL_ORGANIZATION_NAME={ISL_ORGANIZATION_NAME} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Athletes Added</div></div>
        </div>
      )
    }
  </div>
);

const AthleteGrid = ({ state, FINA_ORGANIZATION_ID, FINA_ORGANIZATION_NAME, NCAA_ORGANIZATION_ID,
  NCAA_ORGANIZATION_NAME, ISL_ORGANIZATION_NAME }) => (
  <Fragment>
    <b>Persons Added to the Database</b>
    <LargeGrid state={state} FINA_ORGANIZATION_ID={FINA_ORGANIZATION_ID} FINA_ORGANIZATION_NAME={FINA_ORGANIZATION_NAME} NCAA_ORGANIZATION_ID={NCAA_ORGANIZATION_ID}
      NCAA_ORGANIZATION_NAME={NCAA_ORGANIZATION_NAME} ISL_ORGANIZATION_NAME={ISL_ORGANIZATION_NAME} />
    <SmallGrid state={state} FINA_ORGANIZATION_ID={FINA_ORGANIZATION_ID} FINA_ORGANIZATION_NAME={FINA_ORGANIZATION_NAME} NCAA_ORGANIZATION_ID={NCAA_ORGANIZATION_ID}
      NCAA_ORGANIZATION_NAME={NCAA_ORGANIZATION_NAME} ISL_ORGANIZATION_NAME={ISL_ORGANIZATION_NAME} />
  </Fragment>
);

export default AthleteGrid;