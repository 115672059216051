export const localValidate = (formState) => {
  let errors = {};
  
  return errors;
};

const ProfileCoachDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ProfileCoachDetailValidation;