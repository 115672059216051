import React, { createContext, useState } from 'react';

import OtsOfficialLscData from './OtsOfficialLscData';

export const OtsOfficialLscStateContext = createContext();

const OtsOfficialLscContextProvider = ({ children }) => {
  const stateHook = useState(OtsOfficialLscData.INITIAL_STATE);

  return (
    <OtsOfficialLscStateContext.Provider value={stateHook}>
      {children}
    </OtsOfficialLscStateContext.Provider>
  );
};

export default OtsOfficialLscContextProvider;