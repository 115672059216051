import Constants from "../../../../common/utils/Constants";

export const localValidate = (formState) => {

  const errors = {};
  if (formState.orgUnitId === Constants.DEFAULT_ID) {
    errors.orgUnitId = 'Selection is required';
  }

  return errors;
};

const LSCReportingHQSelectionValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default LSCReportingHQSelectionValidation;