import { Fragment } from 'react';

import useOtsMeetEvaluators from './UseOtsMeetEvaluators';

import Headings from '../../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import LoadingModal from '../../../../../common/components/dialogs/LoadingModal';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Constants from '../../../../../common/utils/Constants';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';
import MeetEvaluatorsGrid from './MeetEvaluatorsGrid';

import global from '../../../../../common/components/GlobalStyle.module.css';
import style from './OtsMeetEvaluators.module.css';

const OtsMeetEvaluators = () => {
  const {
    gridData,
    nationalEvaulators,
    selectedNationalEvaluatorId,
    showError,
    isOfficialsQualifyingMeet,
    isLoading,
    isSaving,
    onNationalEvaluatorChanged,
    onAddButtonClicked,
    deleteModalState,
    onDeleteButtonClicked,
    onDeleteEvaluator,
    onDeleteModalCanceled } = useOtsMeetEvaluators();

  return (
    <div className="row">
      <div className="col-xs-12">
        <Headings.H3>Meet Evaluators</Headings.H3>
      </div>
      {isOfficialsQualifyingMeet === true ? (
        <Fragment>
          <div className="col-xs-12 col-sm-4 usas-extra-top-margin">
            <Dropdown
              error={selectedNationalEvaluatorId < 0 && showError === true ? true : false}
              label="National Evaluator"
              message={selectedNationalEvaluatorId < 0 && showError === true ? 'Please select an evaluator' : ''}
              options={nationalEvaulators}
              name="nationalEvaulatorId"
              value={selectedNationalEvaluatorId}
              onChange={onNationalEvaluatorChanged}
              areValuesIntegers={true} />
          </div>
          <div className={['col-xs-12 col-sm-8', style.ActionButton].join(' ')}>
            <ActionIntraPageButton onClick={onAddButtonClicked}>Add Evaluator</ActionIntraPageButton>
          </div>
          <div className="col-xs-12 usas-extra-top-margin">
            {isLoading && <LoadingModal />}
            <PopUpModal widthPct={50} maxWidth={250} title={Constants.SAVING_MSG} displayPopUp={isSaving} />
            <MeetEvaluatorsGrid gridData={gridData} onDeleteButtonClicked={onDeleteButtonClicked} />
          </div>
        </Fragment>
      ) : (
        <div className="col-xs-12 usas-extra-top-margin">
          Only OQM meets can have national evaluators assigned to them.
        </div>
      )}
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={deleteModalState.modalTitle}
        displayPopUp={deleteModalState.displayPopUp}
        onModalCanceled={onDeleteModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Evaluator Name:</b> {deleteModalState.evaluatorName}</p>
            <p><b>National Certifications:</b> {deleteModalState.nationalCertification}</p>
            <p><b>Mentor Status:</b> {deleteModalState.mentorStatus}</p>
            <p><b>Assigned to an Evaluation?</b> {deleteModalState.hasEvaluationForm === true ? 'Yes' : 'No'}</p>
          </div>
          {deleteModalState.hasEvaluationForm === true &&
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>Unable to Delete Meet Evaluator - Evaluators assigned to an evaluation cannot be deleted.</p>
              <p className={global.ErrorMessage}>To Delete this Meet Evaluator, their assigned evaluation(s) must be deleted first.</p>
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            {deleteModalState.hasEvaluationForm === false &&
              <Fragment>
                <PrimaryButton type="button" onClick={(e) => onDeleteEvaluator(deleteModalState.meetEvaluatorId)}>Delete</PrimaryButton>&nbsp;
              </Fragment>}
            <SecondaryButton type="button" onClick={onDeleteModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </div>
  );
};

export default OtsMeetEvaluators;