import React, { Fragment } from 'react';

import global from '../../../../../../common/components/GlobalStyle.module.css';

import { formatDate, getTodaysDate } from '../../../../../../common/utils/DateFunctions';

const SmallGridRow = ({ official, formState }) => {
  const todayDate = getTodaysDate();
  const today = new Date(todayDate);
  const expirationDateLocal = new Date(official?.expirationDate);

  if (formState?.showAllNationalCertificationHistory === false && (today <= expirationDateLocal)) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{official?.positionTypeName} - {official?.positionAbbreviation}</span>&nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                Position
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {official?.positionTypeName} - {official?.positionAbbreviation}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Natl Certification
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {official?.levelName || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Effective Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(official?.effectiveDate)}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Expiration Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(official?.expirationDate)}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  else if (formState?.showAllNationalCertificationHistory === true) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{official?.positionTypeName} - {official?.positionAbbreviation}</span>&nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                Position
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {official?.positionTypeName} - {official?.positionAbbreviation}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Natl Certification
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {official?.levelName || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Effective Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(official?.effectiveDate)}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Expiration Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(official?.expirationDate)}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  else {
    return (
      <Fragment />
    )
  }
};

const OtsOfficialNationalCertificationHistorySmallReadGrid = ({ state, formState }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(state?.arrayData) && state?.arrayData?.length > 0
      ? state?.arrayData?.map((official, i) =>
        <SmallGridRow
          key={i}
          official={official}
          formState={formState}
        />)
      : (
        <div>
          {state?.isArrayLoading
            ? (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
              </div>
            ) : (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
              </div>
            )
          }
        </div>
      )
    }
  </div>
);

export default OtsOfficialNationalCertificationHistorySmallReadGrid;