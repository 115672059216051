import React, { createContext, useState } from 'react';

import OtsMeetOfficialsData from './OtsMeetOfficialsData';

export const OtsMeetOfficialsStateContext = createContext();

const OtsMeetOfficialsContextProvider = ({ children }) => {
  const stateHook = useState(OtsMeetOfficialsData.INITIAL_STATE);

  return (
    <OtsMeetOfficialsStateContext.Provider value={stateHook}>
      {children}
    </OtsMeetOfficialsStateContext.Provider>
  );
};

export default OtsMeetOfficialsContextProvider;