import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';

import useOtsMeetSessionsData from '../../../../../common/state/officialsTrackingSystemMeetSessions/UseOtsMeetSessionsData';
import useMeetSessionsData from '../../../../../common/state/meetSessions/UseMeetSessionsData';
import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../../common/utils/Constants';

const useOtsMeetSessions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { otsMeetSessionsState, getMeetSessions, deleteMeetSession, confirmOtsMeetSessionSave } = useOtsMeetSessionsData();
  const { resetModalState } = useMeetSessionsData();
  const { navRoutes } = useNavRoutes();

  const onAddSession = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const meetSessionArrayCopy = JSON.parse(JSON.stringify(otsMeetSessionsState.arrayData));
    meetSessionArrayCopy.sort(function (a, b) { return a.sessionOrderId - b.sessionOrderId });
    const prevHighestSessionOrderId = meetSessionArrayCopy?.length > 0 ? Math.max(...meetSessionArrayCopy.map(o => o.sessionOrderId)) : 0;
    navigate(navRoutes.OTS_MEET_SESSIONS_DETAIL?.route, { state: { prevHighestSessionOrderId: prevHighestSessionOrderId } });
  };

  const onEditSession = (e, session) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_SESSIONS_DETAIL?.route, { state: { meetSessionId: session.meetSessionId } });
  }

  const onDeleteSession = (e, meetSessionId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
   
    resetModalState();
    deleteMeetSession(meetSessionId);
  }

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      getMeetSessions(otsMeetHeaderState.objData?.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (otsMeetSessionsState.isArrayLoading !== true && otsMeetSessionsState.isArrayLoaded !== true) {
      getMeetSessions(otsMeetHeaderState.objData?.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetHeaderState, otsMeetSessionsState])

  useEffect(() => {
    if (otsMeetSessionsState.isSaving !== true && otsMeetSessionsState.isSaved === true) {
      confirmOtsMeetSessionSave();
      getMeetSessions(otsMeetHeaderState.objData?.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetSessionsState.isSaving, otsMeetSessionsState.isSaved])

  return {
    otsMeetHeaderState,
    otsMeetSessionsState,
    isLoading: otsMeetSessionsState.isArrayLoading,
    isSaving: otsMeetSessionsState.isSaving,
    Constants,
    navRoutes,
    onAddSession,
    onEditSession,
    onDeleteSession
  };
}

export default useOtsMeetSessions;