import React, { Fragment } from 'react';

import DeleteIcon from '../../../../../../common/components/icons/DeleteIcon';
import Checkbox from '../../../../../../common/components/checkboxes/Checkbox';

import { formatDate, getTodaysDate } from '../../../../../../common/utils/DateFunctions';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const SmallGridRowIcons = ({ officialLscCert, onEditLscCert, onOpenDeleteLscCertificationModal }) => (
  <Fragment>
    {officialLscCert?.canBeDeleted === true
      ? <button className={global.IconButton} type="button" onClick={(e) => onOpenDeleteLscCertificationModal(e, officialLscCert)}><DeleteIcon /> </button>
      : <Fragment />
    }<button className={officialLscCert?.canBeDeleted === true ? global.IconButtonMobileMargin : global.IconButton} type="button" onClick={(e) => onEditLscCert(e, officialLscCert)}>
      Expire
    </button>
  </Fragment>
);

const SmallGridRow = ({ officialLscCert, formState, onEditLscCert, onOpenDeleteLscCertificationModal, foundIndex, onAddOrRemoveLscCertficationForRecertify }) => {
  const todayDate = getTodaysDate();
  const today = new Date(todayDate);
  const expirationDateLocal = new Date(officialLscCert?.expirationDate)

  if (formState?.showAllLscCertificationHistory === false && (officialLscCert?.isActive === true || today <= expirationDateLocal)) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{officialLscCert?.positionTypeName} - {officialLscCert?.positionAbbreviation}</span>&nbsp;
            <SmallGridRowIcons
              officialLscCert={officialLscCert}
              formState={formState}
              onEditLscCert={onEditLscCert}
              onOpenDeleteLscCertificationModal={onOpenDeleteLscCertificationModal}
            />
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                Position
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {officialLscCert?.positionTypeName} - {officialLscCert?.positionAbbreviation}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Sessions Worked
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {officialLscCert?.sessionsWorked}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Certification Level
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {officialLscCert?.levelName || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Effective Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(officialLscCert?.effectiveDate)}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Expiration Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(officialLscCert?.expirationDate)}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Re-Certify?
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {officialLscCert?.isRecertifyAvailable === true &&
                  <Checkbox
                    name="personPositionCertificationId"
                    checked={foundIndex >= 0 ? true : false}
                    onChange={(checked) => { onAddOrRemoveLscCertficationForRecertify(checked, officialLscCert, officialLscCert?.personPositionCertificationId) }} />}
              </div>
            </div>
          </div>
        </div>
      </Fragment >
    )
  } else if (formState?.showAllLscCertificationHistory === true) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{officialLscCert?.positionTypeName} - {officialLscCert?.positionAbbreviation}</span>&nbsp;
            <SmallGridRowIcons
              officialLscCert={officialLscCert}
              onEditLscCert={onEditLscCert}
              onOpenDeleteLscCertificationModal={onOpenDeleteLscCertificationModal}
            />
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                Position
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {officialLscCert?.positionTypeName} - {officialLscCert?.positionAbbreviation}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Sessions Worked
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {officialLscCert?.sessionsWorked}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Certification Level
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {officialLscCert?.levelName || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Effective Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(officialLscCert?.effectiveDate)}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Expiration Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(officialLscCert?.expirationDate)}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Re-Certify?
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {officialLscCert?.isRecertifyAvailable === true &&
                  <Checkbox
                    name="personPositionCertificationId"
                    checked={foundIndex >= 0 ? true : false}
                    onChange={(checked) => { onAddOrRemoveLscCertficationForRecertify(checked, officialLscCert, officialLscCert?.personPositionCertificationId) }} />}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  } else {
    return (
      <Fragment />
    )
  }
}


const OtsOfficialLscCertificationSmallWriteGrid = ({ state, formState, onEditLscCert, onOpenDeleteLscCertificationModal, selectedLscCertifications, onAddOrRemoveLscCertficationForRecertify }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(state?.arrayData?.certifications) && state?.arrayData?.certifications?.length > 0
      ? state?.arrayData?.certifications?.map((officialLscCert, i) => {
        const foundIndex = selectedLscCertifications.findIndex(x => x.personPositionCertificationId === officialLscCert.personPositionCertificationId);
        return (
          <SmallGridRow
            key={i}
            officialLscCert={officialLscCert}
            formState={formState}
            onEditLscCert={onEditLscCert}
            onOpenDeleteLscCertificationModal={onOpenDeleteLscCertificationModal}
            onAddOrRemoveLscCertficationForRecertify={onAddOrRemoveLscCertficationForRecertify}
            foundIndex={foundIndex}
          />)
      })
      : (
        <div>
          {state?.isArrayLoading
            ? (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
              </div>
            ) : (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
              </div>
            )
          }
        </div>
      )
    }
  </div>
);

export default OtsOfficialLscCertificationSmallWriteGrid;