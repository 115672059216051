import React, { Fragment } from 'react';

import MeetOfficialsOfficialGridLarge from './MeetOfficialsOfficialGridLarge';
import MeetOfficialsOfficialGridSmall from './MeetOfficialsOfficialGridSmall';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MeetOfficialsOfficialGrid = ({ state, onEditSessionRole, onDeleteSessionRole }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MeetOfficialsOfficialGridLarge state={state} onEditSessionRole={onEditSessionRole} onDeleteSessionRole={onDeleteSessionRole} expandedId={expandedId} onExpandClicked={onClick} />
      <MeetOfficialsOfficialGridSmall state={state} onEditSessionRole={onEditSessionRole} onDeleteSessionRole={onDeleteSessionRole} expandedId={expandedId} onExpandClicked={onClick} />
    </Fragment>
  );
};

export default MeetOfficialsOfficialGrid;