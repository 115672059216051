
import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useMeetOfficialSessionData from '../../../../state/meetOfficialSession/UseMeetOfficialSessionData';
import useOfficialsTrackingSystemOfficialSelectionData from '../../../../state/officialsTrackingSystemOfficialSelection/UseOfficialsTrackingSystemOfficialSelectionData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useOtsMeetOfficials = () => {
  const navigate = useNavigate();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { meetOfficialSessionState, getMeetOfficialSessionsByOtsMeetId } = useMeetOfficialSessionData();
  const { officialsTrackingSystemOfficialSelectionState, deleteMeetOfficial, confirmSave } = useOfficialsTrackingSystemOfficialSelectionData();
  const { navRoutes } = useNavRoutes();
  const [deleteModalState, setDeleteModalState] = useState(getInitialDeleteModalState());

  const onAddOfficial = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_OFFICIALS_SELECTION?.route);
  };

  const onEditOfficial = (e, official) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_OFFICIALS_OFFICIAL?.route, {
      state: {
        meetOfficialPersonId: official?.personId || '',
        meetOfficialMemberId: official?.memberId || '',
        firstName: official?.firstName || '',
        preferredName: official?.preferredName || '',
        middleName: official?.middleName || '',
        lastName: official?.lastName || '',
        selectedValidMemberId: true,
      }
    });
  };

  const onDeleteOfficialClicked = (e, official) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setDeleteModalState({
      ...deleteModalState,
      displayPopUp: true,
      meetOfficialId: official.meetOfficialId || Constants.DEFAULT_ID,
      officialName: `${official.firstName || ''} ${official.preferredName !== '' && official.preferredName !== official.firstName ? '"' + official.preferredName + '"' : ''} ${official.middleName || ''} ${official.lastName || ''}`,
      memberId: official.memberId || '',
      organization: `${official.lscCode || ''} / ${official.clubName || ''}`,
      registrationExpirationDate: official.registrationExpirationDate ? formatDate(official.registrationExpirationDate) : 'Not Completed',
      aptExpirationDate: official.aptExpirationDate ? formatDate(official.aptExpirationDate) : 'Not Completed',
      bgcExpirationDate: official.bgcExpirationDate ? formatDate(official.bgcExpirationDate) : 'Not Completed',
      cptExpirationDate: official.cptExpirationDate ? formatDate(official.cptExpirationDate) : 'Not Completed',
      sessionsNumber: `${official.sessionsNumber >= 0 ? official.sessionsNumber : ''}`,
      officialRoleTypeName: official.officialRoleTypeName,
      hasEvaluationForm: official.hasEvaluationForm
    });
  };

  const onDeleteOfficial = () => {
    deleteMeetOfficial(deleteModalState.meetOfficialId);
    setDeleteModalState(getInitialDeleteModalState());
  };

  const onDeleteModalCanceled = () => {
    setDeleteModalState(getInitialDeleteModalState());
  };

  useEffect(() => {
    if (otsMeetHeaderState.objData?.otsMeetId) {
      getMeetOfficialSessionsByOtsMeetId(otsMeetHeaderState.objData?.otsMeetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetHeaderState])

  useEffect(() => {
    if (otsMeetHeaderState.objData?.otsMeetId && officialsTrackingSystemOfficialSelectionState.isSaved === true) {
      getMeetOfficialSessionsByOtsMeetId(otsMeetHeaderState.objData?.otsMeetId);
      confirmSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetHeaderState, officialsTrackingSystemOfficialSelectionState.isSaved])

  function getInitialDeleteModalState() {
    return {
      meetOfficialId: Constants.DEFAULT_ID,
      officialName: '',
      memberId: '',
      organization: '',
      registrationExpirationDate: '',
      aptExpirationDate: '',
      bgcExpirationDate: '',
      cptExpirationDate: '',
      sessionsNumber: '',
      officialRoleTypeName: '',
      hasEvaluationForm: '',
      modalTitle: 'Delete Meet Official?',
      displayPopUp: false
    };
  };

  return {
    meetOfficialSessionState,
    isLoading: meetOfficialSessionState.isArrayLoading,
    isSaving: meetOfficialSessionState.isSaving || officialsTrackingSystemOfficialSelectionState.isSaving,
    Constants,
    onAddOfficial,
    onEditOfficial,
    deleteModalState,
    onDeleteOfficialClicked,
    onDeleteOfficial,
    onDeleteModalCanceled,
    passedInMeetEndDate: otsMeetHeaderState.objData?.meetEndDate
  };
}

export default useOtsMeetOfficials;