import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';
import getPersonRequirements from './GetPersonRequirementsData';
import getPersonRequirementsAdvanced from './GetPersonRequirementsAdvancedData';
import postEnrollmentMemberRequirmentAdmin from './PostEnrollmentMemberRequirmentAdminData';
import deletePersonRequirementEnrollment from './DeletePersonRequirementEnrollmentData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  filteredRoleList: []
};

const PersonRequirementsData = {
  INITIAL_STATE,
  getPersonRequirements,
  getPersonRequirementsAdvanced,
  postEnrollmentMemberRequirmentAdmin,
  deletePersonRequirementEnrollment

};

export default PersonRequirementsData;