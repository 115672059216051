
import useFileTypeData from '../../../state/personNationalTeamFileType/UsePersonNationalTeamFileTypeData';

const useFileTypeDropdown = () => {
  const { personNationalTeamFileTypeState } = useFileTypeData();

  return {
    personNationalTeamFileTypeState
  };
};

export default useFileTypeDropdown;