import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../../common/wrappers/ReactRouterDom';

import LscOfferingManagementContextView from './LscOfferingManagementContextView';

import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';

import LscOfferingInstanceCoupon from './lscOfferingInstanceCoupon/LscOfferingInstanceCoupon';

import LscOfferingInstanceOrgUnit from './lscOfferingInstanceOrgUnit/LscOfferingInstanceOrgUnit';
import LscOfferingInstanceOrgUnitAdd from './lscOfferingInstanceOrgUnit/lscOfferingInstanceOrgUnitAdd/LscOfferingInstanceOrgUnitAdd';
import LscOfferingInstanceOrgUnitEdit from './lscOfferingInstanceOrgUnit/lscOfferingInstanceOrgUnitEdit/LscOfferingInstanceOrgUnitEdit';

import OfferingManagementInstanceOrgUnitContextProvider from '../../../state/offeringManagementInstanceOrgUnit/OfferingManagementInstanceOrgUnitContextProvider';

import LoadingModal from '../../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import SelectOrgUnitContextProvider from '../../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import ReportPeriodSeasonContextProvider from '../../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider';

const ContextRoutes = ({ navRoutes }) => (
  <LscOfferingManagementContextView>
    <Routes>
      <Route path={navRoutes['OFFERING_MANAGMENT_LSC_OFFERINGS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OFFERING_MANAGMENT_LSC_OFFERINGS']}
            editElement={<LscOfferingInstanceOrgUnit />} />
        } />
      <Route path={navRoutes['OFFERING_MANAGMENT_LSC_OFFERING_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OFFERING_MANAGMENT_LSC_OFFERING_ADD']}
            editElement={<LscOfferingInstanceOrgUnitAdd />} />
        } />
      <Route path={navRoutes['OFFERING_MANAGMENT_LSC_OFFERING_EDIT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OFFERING_MANAGMENT_LSC_OFFERING_EDIT']}
            editElement={<LscOfferingInstanceOrgUnitEdit />} />
        } />
      <Route path={navRoutes['OFFERING_MANAGMENT_LSC_COUPONS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OFFERING_MANAGMENT_LSC_COUPONS']}
            editElement={<LscOfferingInstanceCoupon />} />
        } />
    </Routes>
  </LscOfferingManagementContextView>
);

const LscOfferingManagementRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['OFFERING_MANAGEMENT_LSC_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['OFFERING_MANAGEMENT_LSC_SELECTION']}
            editElement={<OrgUnitSelection />} />
        }
      />

      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['OFFERING_MANAGEMENT_LSC_SELECTION']?.route} replace />} />
    </Routes>
  );
};

const LscOfferingManagement = () => (
  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <ReportPeriodSeasonContextProvider>
        <OfferingManagementInstanceOrgUnitContextProvider>
          <LscOfferingManagementRoutes />
        </OfferingManagementInstanceOrgUnitContextProvider>
      </ReportPeriodSeasonContextProvider>
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>
);

export default LscOfferingManagement;