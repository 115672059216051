import Constants from '../../../utils/Constants';
import { isValidNumber } from '../../../utils/validation';

const DESCRIPTION_MAX_LENGTH = 1000;

export const SAARegistrationFormValidation = (formState) => {
  let errors = {};

  if (!formState.highSchoolName || formState.highSchoolName.trim() === '') {
    errors.highSchoolName = 'High School Name is required';
  }

  if (!isValidNumber(formState.highSchoolGraduationYear)) {
    errors.highSchoolGraduationYear = 'High School Graduation Year is required';
  }

  if (formState.paraStatusId === Constants.DEFAULT_ID || formState.paraStatusId === '') {
    errors.paraStatusId = 'Para Status is required';
  }

  if (formState.openWaterStatusId === Constants.DEFAULT_ID || formState.openWaterStatusId === '') {
    errors.openWaterStatusId = 'Open Water Status is required';
  }

  if (formState.classYearId === Constants.DEFAULT_ID || formState.classYearId === '') {
    errors.classYearId = 'Class Year is required';
  }

  // only validate gpaFile if no url exists
  if (!formState.gpaUrl) {
    if (!(formState.gpaFile?.size)) {
      errors.gpaFile = 'Transcript File is required';
    } else if (formState.gpaFile?.type !== Constants.FILE_TYPE_PDF && formState.gpaFile?.type !== Constants.FILE_TYPE_JPG
      && formState.gpaFile?.type !== Constants.FILE_TYPE_PNG) {
      errors.gpaFile = 'File type not permitted. Please provide a (*.pdf), (*.png), (*.jpg) or (*.jpeg) file.';
    }
  }

  if (formState.highSchoolGradingSystem.trim() === '') {
    errors.highSchoolGradingSystem = 'Field is required';
  } else if (formState.highSchoolGradingSystem.trim().length > DESCRIPTION_MAX_LENGTH) {
    errors.highSchoolGradingSystem = `Field cannot be greater than ${DESCRIPTION_MAX_LENGTH} characters in length`;
  }

  return errors;
};

export default SAARegistrationFormValidation;