import { isValidDate, } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';

export const localValidate = (formState) => {
    let errors = {};

    const localEffectiveDate = new Date(formState.effectiveDate);
    const localExpirationDate = new Date(formState.expirationDate);

    //Position Worked
    if (formState.officialPositionTypeId === Constants.DEFAULT_ID) {
        errors.officialPositionTypeId = 'Position Worked is required';
    }

    //Certification 
    if (formState.certificationLevelId === Constants.DEFAULT_ID) {
        errors.certificationLevelId = 'Certification Level is required';
    }

    //Effective Date
    if (formState.effectiveDate === Constants.BLANK_DATE_STRING) {
        errors.effectiveDate = 'Effective Date is required';
    } else if (!isValidDate(formState.effectiveDate)) {
        errors.effectiveDate = 'Effective Date must be a valid date';
    } else if (localEffectiveDate > localExpirationDate) {
        errors.effectiveDate = 'Effective Date must be on or before the Expiration Date';
    }

    //Expiration Date
    if (formState.expirationDate === Constants.BLANK_DATE_STRING) {
        errors.expirationDate = 'Expiration Date is required';
    } else if (!isValidDate(formState.expirationDate)) {
        errors.expirationDate = 'Expiration Date must be a valid date';
    } else if (localEffectiveDate > localExpirationDate) {
        errors.expirationDate = 'Expiration Date must be on or after the Effective Date';
    }

    return errors;
};

const OtsOfficialLscCertificationDetailValidation = async (formState) => {
    let errors = localValidate(formState);

    return errors ? errors : {};
};

export default OtsOfficialLscCertificationDetailValidation;