import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postPersonPositionCertificationCertificationData from './PostPersonPositionCertificationCertificationData';
import putPersonPositionCertificationData from './PutPersonPositionCertificationData';
import deletePersonPositionCertificationData from './DeletePersonPositionCertificationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonPositionCertificationData = {
  INITIAL_STATE,
  postPersonPositionCertificationCertificationData,
  putPersonPositionCertificationData,
  deletePersonPositionCertificationData
}

export default PersonPositionCertificationData;