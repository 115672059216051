import { useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../MyLscOtsMeetNavLinks';

import useSecurityData from '../../../../../common/state/security/UseSecurityData';
import usePersonOrgRoleData from '../../../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const TAXONOMIES = ['OTSMeetDetail'];
const SCOPE = 'OTSMeet';

const useMyLscOtsMeetSearchContextView = () => {
  const navigate = useNavigate();
  const { getContextSecurity } = useSecurityData();
  const { clearArrayData } = usePersonOrgRoleData();
  const { selectOrgUnitState, resetSelectOrgUnitState } = useSelectOrgUnitData();

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MY_LSC_OTS_MEETS_SEARCH);
  };

  const onBackToSelectionClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    resetSelectOrgUnitState();
    clearArrayData();

    navigate(NavLinks.MY_LSC_OTS_MEETS_SELECTION)
  }

  useEffect(() => {
    if (selectOrgUnitState.orgUnitId) {
      getContextSecurity(selectOrgUnitState.orgUnitId, TAXONOMIES, SCOPE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOrgUnitState.orgUnitId])

  return {
    selectOrgUnitState,
    onHomeClicked,
    onBackToSelectionClicked
  };
};

export default useMyLscOtsMeetSearchContextView;
