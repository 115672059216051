import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './NationalTeamSupportStaffTypeDetailValidation';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';
import useSupportStaffTypeData from '../../../state/supportStaffType/UseSupportStaffTypeData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';

const useNationalTeamSupportStaffTypeDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personNationalTeamState, putPersonNationalTeam } = usePersonNationalTeamData();
  const { supportStaffTypeState } = useSupportStaffTypeData();
  const { formState, errorState, handleSubmit, updateFormState, onValueTextPairChanged} = useForm(getInitialFormState(), submitFormCallback, validate);
  const [uiState, setUiState] = useState(getInitialUiState());

  const onSaveClicked = (e) => {
    handleSubmit(e);
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
  }


  // Redirects the user to the previous page once all API calls have finished
  useEffect(() => {
    if (uiState.tryRedirect === true && personNationalTeamState.isObjLoading === false && personNationalTeamState.isSaving === false) {
      navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personNationalTeamState, uiState.tryRedirect]);

  function createPutPersonNationalTeamObject() {
    const personNationalTeamObjToPut = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    const supportStaffMembers = personNationalTeamObjToPut.supportStaffMembers;
    const nationalTeamSupportStaffId = location.state.nationalTeamSupportStaffId;
    const selectedSupportStaffIndex = supportStaffMembers.findIndex(e => e.nationalTeamSupportStaffId === nationalTeamSupportStaffId);
    let nationalTeamSupportStaffTypeArray = supportStaffMembers[selectedSupportStaffIndex].nationalTeamSupportStaffType;

    const staffTypeObj = {
      nationalTeamSupportStaffId: nationalTeamSupportStaffId,
      supportStaffTypeId: formState.supportStaffTypeId,
      effectiveDate: formState.effectiveDate,
      expirationDate: formState.expirationDate,
      supportStaffType: null
    }

    nationalTeamSupportStaffTypeArray.push(staffTypeObj);
    personNationalTeamObjToPut.supportStaffMembers[selectedSupportStaffIndex].nationalTeamSupportStaffType = nationalTeamSupportStaffTypeArray;

    return personNationalTeamObjToPut;
  }

  // Holds initial formState
  function getInitialFormState() {
    return {
      nationalTeamSupportStaffId: Constants.DEFAULT_ID,
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      supportStaffTypeId: Constants.DEFAULT_ID
    };
  };

  function submitFormCallback(formState) {
    if (personNationalTeamState.isSaving === false) {
      const personNationalTeamObjToPut = createPutPersonNationalTeamObject();
      if (personNationalTeamObjToPut !== null) {
        putPersonNationalTeam(personNationalTeamState.objData.personId, personNationalTeamObjToPut);
        setUiState({ ...uiState, tryRedirect: true });
      }
    }
  };

  // Holds initial view state
  function getInitialUiState() {
    return {
      tryRedirect: false
    };
  };

  return {
    Constants,
    personNationalTeamState,
    formState,
    errorState,
    uiState,
    supportStaffTypeState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  }

}

export default useNationalTeamSupportStaffTypeDetail;