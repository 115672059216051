import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OtsOfficialLscCertificationDetailValidation';

import useOfficialPositionData from '../../../../state/officialPosition/UseOfficialPositionData';
import useOtsOfficialData from '../../../../state/officialsTrackingSystemOfficialLsc/UseOtsOfficialLscData';
import usePersonPositionCertificationData from '../../../../state/personPositionCertification/UsePersonPositionCertificationData';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate, getTodaysDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  personPositionCertificationId: Constants.DEFAULT_ID,
  officialPositionTypeId: Constants.DEFAULT_ID,
  positionTypeName: '',
  positionAbbreviation: '',
  certificationLevelId: Constants?.DEFAULT_ID,
  levelName: '',
  effectiveDate: Constants?.BLANK_DATE_STRING,
  expirationDate: Constants?.BLANK_DATE_STRING,
};

const INITIAL_STATE = {
  personPositionCertificationId: Constants.DEFAULT_ID,
  positionCertObj: {}
};

const useOtsOfficialLscCertificationDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const [state, setState] = useState(INITIAL_STATE);
  const { officialPositionState } = useOfficialPositionData();
  const { otsOfficialHeaderState } = useOtsOfficialData();
  const { personPositionCertificationState, postPersonPositionCertificationCertification, putPersonPositionCertification } = usePersonPositionCertificationData();
  const { environmentVariableState, LSC_ORG_LEVEL_ID } = useEnvironmentVariableData();
  const {
    formState,
    errorState,
    onValueTextPairChanged,
    onFormValueChanged,
    setFormData,
    handleSubmit
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  function submitFormCallback() {
    if (state?.personPositionCertificationId > 0) {
      //edit
      const personPositionCertificationId = formState?.personPositionCertificationId;
      putPersonPositionCertification(personPositionCertificationId, createPutCertificationObject(formState))
    } else {
      //add
      postPersonPositionCertificationCertification(createPostCertificationObject(formState));
    }
  }

  function createPutCertificationObject(formState) {
    let putObj = {
      personPositionCertificationId: formState?.personPositionCertificationId,
      personId: otsOfficialHeaderState?.objData?.personId,
      positionCertificationTypeId: state?.positionCertObj?.positionCertificationTypeId,
      effectiveDate: formState?.effectiveDate,
      expirationDate: formState?.expirationDate,
      status: Constants.WORKFLOW_STATUS_APPROVED
    };

    return putObj;
  }

  function createPostCertificationObject(formState) {
    let postObj = {
      personId: otsOfficialHeaderState?.objData?.personId,
      certificationLevelId: formState?.certificationLevelId,
      officialPositionTypeId: formState?.officialPositionTypeId,
      effectiveDate: formState?.effectiveDate,
      expirationDate: formState?.expirationDate,
    };

    return postObj;
  }

  const onBackToLscCert = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_OFFICIAL_LSCCERTIFICATIONS?.route);
  };

  const setDateAsToday = () => {
    const today = getTodaysDate();
    setFormData({ ...formState, effectiveDate: formatDate(today) });
  };

  const calculateCountOfYears = () => {
    let countOfYears = 0;
    const today = new Date();
    const year = today.getFullYear();
    countOfYears = year - 2000 + 9;
    return countOfYears;
  };

  const calculateStartYearOffset = () => {
    let startYearOffset = 0;
    const today = new Date();
    const year = today.getFullYear();
    startYearOffset = 2000 - year;
    return startYearOffset;
  };

  useEffect(() => {
    if (Object?.keys(personPositionCertificationState?.objData)?.length > 0 && personPositionCertificationState?.isSaved === true) {
      setTimeout(() => {
        navigate(navRoutes.OTS_OFFICIAL_LSCCERTIFICATIONS?.route);
      }, 750);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personPositionCertificationState.objData, personPositionCertificationState.isSaved]);

  useEffect(() => {
    if (location?.state) {
      const today = getTodaysDate();
      setFormData({ ...formState, effectiveDate: formatDate(today) });
      setState({
        ...state,
        personPositionCertificationId: location?.state?.personPositionCertificationId || Constants.DEFAULT_ID,
        positionCertObj: location?.state?.positionObj || {},
      });
      setDateAsToday();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object?.keys(state?.positionCertObj)?.length > 0 && environmentVariableState.isLoaded === true) {
      const newState = state?.positionCertObj;
      const today = getTodaysDate();

      setFormData({
        ...formState,
        personPositionCertificationId: newState?.personPositionCertificationId,
        officialPositionTypeId: newState?.officialPositionTypeId,
        positionTypeName: newState?.positionTypeName,
        positionAbbreviation: newState?.positionAbbreviation,
        certificationLevelId: newState?.certificationLevelId,
        levelName: newState?.levelName,
        effectiveDate: formatDate(newState?.effectiveDate),
        expirationDate: formatDate(today),
      }, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.positionCertObj, environmentVariableState.isLoaded]);

  return {
    onSubmit: handleSubmit,
    formState,
    errorState,
    onValueTextPairChanged,
    isLoading: officialPositionState.isArrayLoading,
    state,
    onBackToLscCert,
    onFormValueChanged,
    LSC_ORG_LEVEL_ID,
    isSaving: personPositionCertificationState?.isSaving,
    countOfYears: calculateCountOfYears(),
    startYearOffset: calculateStartYearOffset()
  };
};

export default useOtsOfficialLscCertificationDetail;