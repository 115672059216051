import { Fragment } from 'react';

import useNationalTeamSupportStaffTypeDetail from './UseNationalTeamSupportStaffTypeDetail';

import DatePicker from '../../../../common/components/datepickers/DatePicker';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const NationalTeamSupportStaffTypeDetail = () => {
  const {
    Constants,
    personNationalTeamState,
    formState,
    errorState,
    supportStaffTypeState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  } = useNationalTeamSupportStaffTypeDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Support Staff Role</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Support Staff Role*"
              name="supportStaffTypeId"
              value={formState.supportStaffTypeId}
              error={errorState.supportStaffTypeId}
              message={errorState.supportStaffTypeId}
              isLoading={supportStaffTypeState.isLoading}
              options={supportStaffTypeState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'supportStaffTypeId', newValueLabel, 'typeName') }}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Effective Date*"
              name="effectiveDate"
              value={formState.effectiveDate}
              error={errorState.effectiveDate}
              message={errorState.effectiveDate}
              countOfYears={150}
              startYearOffset={-149}
              onChange={(value) => { onFormValueChanged('effectiveDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Expiration Date*"
              name="expirationDate"
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              countOfYears={150}
              startYearOffset={-140}
              onChange={(value) => { onFormValueChanged('expirationDate', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onSaveClicked(e)}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={(e) => onCancelClicked(e)}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personNationalTeamState.isObjLoading || supportStaffTypeState.isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personNationalTeamState.isSaving} />
    </Fragment>
  );
}

export default NationalTeamSupportStaffTypeDetail;