import { isValidName, isValidDate, isValidBirthDate } from '../../../../common/utils/validation';
import { getAge } from '../../../../common/utils/DateFunctions';

export const localValidate = (formState) => {
  let errors = {};

  //First Name
  if (formState.firstName.trim() === '') {
    errors.firstName = 'Legal First Name is required';
  } else if (!isValidName(formState.firstName.trim())) {
    errors.firstName = 'Legal First Name cannot contain numbers, special characters, or exceed 100 characters';
  } else if (formState.firstName.trim() === formState.lastName.trim()) {
    errors.firstName = "Legal First Name and Legal Last Name cannot be the same"
  }

  //Last Name
  if (formState.lastName.trim() === '') {
    errors.lastName = 'Legal Last Name is required';
  } else if (!isValidName(formState.lastName.trim())) {
    errors.lastName = 'Legal Last Name cannot contain numbers, special characters, or exceed 100 characters';
  } else if (formState.firstName.trim() === formState.lastName.trim()) {
    errors.lastName = "Legal First Name and Legal Last Name cannot be the same"
  }

  //Birth Date
  if (!formState.birthDate || !isValidDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date is required';
  }
  else if (!isValidBirthDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date cannot be in the future'
  }
  else if (!(getAge(formState.birthDate) > 4)) {
    errors.birthDate = 'Person must be at least 5 years old'
  }

  if (formState.isAthlete === true) {
    //Competition Category
    if (!formState.competitionGenderTypeId) {
      errors.competitionGenderTypeId = 'Competition Category is required';
    }

    //Organization
    if (!formState.organizationId || formState.organizationId < 0) {
      errors.orgUnitId = 'Organization is required';
    }

    //Org Unit 
    if (!formState.orgUnitId || formState.orgUnitName === '') {
      errors.orgUnitId = `${formState.orgUnitLabel} is required`;
    }

    //Effective Date
    if (!formState.effectiveDate) {
      errors.effectiveDate = 'Effective Date is required';
    }

    //Expiration Date
    if (!formState.expirationDate) {
      errors.expirationDate = 'Expiration Date is required';
    }

    // Check if orgRole is loaded
    if (formState.orgRoleStatus === '') {
      errors.orgRoleStatus = 'An error occurred. Please try again.'
    }
  }

  return errors;
};

const QuickAddAthleteValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default QuickAddAthleteValidation;