import { useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AddHQBoardMemberValidation';

import { navLinks } from '../../UseMemberAdministration';

import useMemberAdministrationHQBoardData from '../../../../../common/state/memberAdministration/hQBoard/UseMemberAdministrationHQBoardData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const useAddHQBoardMember = () => {
  const navigate = useNavigate();
  const { USAS_ORG_UNIT_ID, HQ_BOARD_OF_DIRECTORS_ROLE_GROUP_ID, environmentVariableState } = useEnvironmentVariableData();
  const { memberAdministrationHQBoardState, postHQBoardDirector, putHQBoardDirector } = useMemberAdministrationHQBoardData();
  const { formState, errorState, isSubmitting, onFormValueChanged, handleSubmit, setErrors, setFormData, setIsDirty
  } = useForm(getInitialFormState(), submitFormCallback, validate);

  const onSaveClicked = () => {
    setIsDirty(true);
    handleSubmit();
  };

  const onBackClicked = () => {
    navigate(navLinks.HQ_BOARD);
  };

  useEffect(() => {
    if (memberAdministrationHQBoardState.isSaved === true) {
      navigate(navLinks.HQ_BOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberAdministrationHQBoardState.isSaved]);

  function submitFormCallback() {
    const existingPersonOrgRoles = getExistingPersonOrgRoles(formState.personId);

    if (existingPersonOrgRoles) {
      if (isDurationValid(formState.orgRole[0].id, formState.effectiveDate, formState.expirationDate, existingPersonOrgRoles)) {
        putHQBoardDirector(USAS_ORG_UNIT_ID, formState.personId, HQ_BOARD_OF_DIRECTORS_ROLE_GROUP_ID, createStaffObject(formState, existingPersonOrgRoles));
      } else {
        const overlap = getOverlappingDates(formState.orgRole[0].id, formState.effectiveDate, formState.expirationDate, existingPersonOrgRoles);
        setErrors({
          multipleActiveRoles: `This member has an existing ${formState.orgRole[0].name} role from ${formatDate(overlap?.effectiveDate)} - ${formatDate(overlap?.expirationDate)}. Date overlaps are not allowed.`
        });
      }
    } else {
      postHQBoardDirector(USAS_ORG_UNIT_ID, HQ_BOARD_OF_DIRECTORS_ROLE_GROUP_ID, createStaffObject(formState));
    }
  };

  function getExistingPersonOrgRoles(personId) {
    const existingPerson = memberAdministrationHQBoardState.arrayData.find(x => x.personId === personId);

    if (existingPerson && Array.isArray(existingPerson.orgRoles)) {
      return existingPerson.orgRoles;
    } else {
      return null;
    }
  };

  function isDurationValid(orgRoleId, effectiveDate, expirationDate, orgRoles) {
    let overlappingDate = false;

    for (const role of orgRoles) {
      if (role.orgRoleId === orgRoleId) {
        if (new Date(effectiveDate) <= new Date(role.expirationDate)
          && new Date(expirationDate) >= new Date(role.effectiveDate)) {
          overlappingDate = true;
          break;
        }
      }
    }

    return !overlappingDate;
  };

  function getOverlappingDates(orgRoleId, effectiveDate, expirationDate, orgRoles) {
    let overlappingDuration = {};

    for (const role of orgRoles) {
      if (role.orgRoleId === orgRoleId) {
        if (new Date(effectiveDate) <= new Date(role.expirationDate)
          && new Date(expirationDate) >= new Date(role.effectiveDate)) {
            overlappingDuration = role;
          break;
        }
      }
    }

    return overlappingDuration;
  };

  function createStaffObject(formState, existingPersonOrgRoles) {
    const filteredPersonOrgRoles = [];

    if (Array.isArray(existingPersonOrgRoles)) {
      for (const role of existingPersonOrgRoles) {
        if (role.isCurrent === true) {
          filteredPersonOrgRoles.push({
            orgRoleId: role.orgRoleId,
            orgRoleName: role.orgRoleName,
            personOrgRoleId: role.personOrgRoleId,
            personOrgRoleDurationId: role.personOrgRoleDurationId
          });
        }
      }
    }

    return {
      personId: formState.personId,
      personOrgRole: [
        {
          orgRoleId: formState.orgRole[0].id,
          orgRoleName: formState.orgRole[0].name,
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.expirationDate
        },
        ...filteredPersonOrgRoles
      ]
    };
  };


  function getInitialFormState() {
    return {
      personId: '',
      orgRole: [],
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING,
      memberId: ''
    };
  };

  return {
    isLoading: environmentVariableState.isLoading || isSubmitting,
    isSaving: memberAdministrationHQBoardState.isSaving,
    formState,
    errorState,
    HQ_BOARD_OF_DIRECTORS_ROLE_GROUP_ID: HQ_BOARD_OF_DIRECTORS_ROLE_GROUP_ID || Constants.DEFAULT_ID,
    onFormValueChanged,
    handleSubmit,
    onBackClicked,
    setFormData,
    onSaveClicked
  };
};

export default useAddHQBoardMember;