import React, { Fragment } from 'react';

import useOtsMeetSessionsDetail from './UseOtsMeetSessionsDetail';

import OtsSessionsDetail from './OtsSessionsDetail';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../../common/utils/Constants';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import Headings from '../../../../../common/components/headings/Headings';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsMeetSessionsDetail = () => {
  const { otsMeetSessionsState, meetSessionsState, onSaveButtonClicked, onBackButtonClicked } = useOtsMeetSessionsDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{meetSessionsState.formState.meetSessionId > 0 ? 'Edit' : 'Add'} Session</Headings.H3>
        </div>
      </div>
      <OtsSessionsDetail />
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          {meetSessionsState?.errorState?.error && <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{meetSessionsState?.errorState?.error}</p>}
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSaveButtonClicked}>Save</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackButtonClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={otsMeetSessionsState.isSaving} />
    </Fragment>
  );
};

export default OtsMeetSessionsDetail;