import { BASIC_INITIAL_STATE, PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

import Constants from '../../../common/utils/Constants';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  otsMeetId: Constants.DEFAULT_ID
};

const getOtsPositionWorkedEvaluationLevelEvaluatorNamesByOtsMeetId = (otsMeetId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    let newState = {
      ...state,
      otsMeetId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const otsMeetIdForUrl = otsMeetId ? encodeURIComponent(otsMeetId) : 'NaN'
    const url = `/OfficialPositionType/OtsMeet/${otsMeetIdForUrl}/OtsMeetEvaluator`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            items: arrayData.map(x => { return { id: x.officialPositionTypeId, name: `${x.positionTypeName}` }; }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const OtsPositionWorkedEvaluationLevelEvaluatorNameData = { INITIAL_STATE, getOtsPositionWorkedEvaluationLevelEvaluatorNamesByOtsMeetId };

export default OtsPositionWorkedEvaluationLevelEvaluatorNameData;