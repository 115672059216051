import { useEffect } from 'react';

import useOfficialPositionTypeData from '../../../state/officialPositionType/UseOfficialPositionTypeData';

const useOfficialPositionTypeDropdown = (orgLevelId, hasEvaluationForm) => {
  const { officialPositionTypeState, getOfficialPositionTypes } = useOfficialPositionTypeData();

  useEffect(() => {
    if (officialPositionTypeState.isArrayLoading !== true && (officialPositionTypeState.isArrayLoaded !== true ||
      orgLevelId !== officialPositionTypeState.orgLevelId ||
      hasEvaluationForm !== officialPositionTypeState.hasEvaluationForm)) {
      getOfficialPositionTypes(orgLevelId, hasEvaluationForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officialPositionTypeState, orgLevelId, hasEvaluationForm]);

  return {
    officialPositionTypeState
  };
};

export default useOfficialPositionTypeDropdown;