import { CommonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  organizationId: Constants.DEFAULT_ID,
  treeData: []
};

const getAffiliationsOrgRolesByOrganizationId = (organizationId, state, setState) => {
  if (state.isArrayLoading !== true && organizationId > 0 && organizationId !== state.organizationId && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      organizationId,
      message: 'Loading...'
    };
    setState(newState);

    const organizationIdForUrl = organizationId ? encodeURIComponent(organizationId) : 'NaN';
    const url = `/OrgRole/Organization/Hierarchy/${organizationIdForUrl}?roleGroupName=${'Primary Roles'}`; //TODO make constant
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            organizationId,
            arrayData: arrayData,
            treeData: HierarchicalDataUtils.MapArrayToTreeData(arrayData, 'id', 'name'),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const AffiliationsOrgRoleData = { INITIAL_STATE, getAffiliationsOrgRolesByOrganizationId };

export default AffiliationsOrgRoleData;