import React, { Fragment } from "react";

import useProfileGoalsGrid from "./UseProfileGoalsGrid";

import DeleteIcon from "../../../../common/components/icons/DeleteIcon";
import EditIcon from "../../../../common/components/icons/EditIcon";

import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeGridRow = ({ goal, onEdit, onDelete }) => (
  <tr>
    <td>{goal.event?.eventName}</td>
    <td>{formatTimeForDisplay(goal.goalTime)}</td>
    <td>
      {onEdit !== undefined &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onEdit(goal.personGoalId)}>
          <EditIcon />
        </button>
      }
      {onDelete !== undefined &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={() => onDelete(goal.personGoalId)}>
          <DeleteIcon />
        </button>
      }
    </td>
  </tr>
);

const LargeGrid = ({ data, isLoading, onEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event</th>
        <th>Goal Time</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="3">Loading...</td></tr>
        : data.length > 0
          ? data.map((goal, i) => <LargeGridRow key={i} goal={goal} onEdit={onEdit} onDelete={onDelete} />)
          : <tr><td colSpan="3">No Goals</td></tr>
      }
    </tbody>
  </table>
);

const SmallGridRowIcons = ({ goal, onEdit, onDelete }) => (
  <Fragment>
    {onDelete !== undefined &&
      <button
        className={global.IconButton}
        type="button"
        onClick={() => onDelete(goal.personGoalId)}>
        <DeleteIcon />
      </button>
    }
    {onEdit !== undefined &&
      <button
        className={global.IconButtonMobileMargin}
        type="button"
        onClick={() => onEdit(goal.personGoalId)}>
        <EditIcon />
      </button>
    }
  </Fragment>
);

const SmallGridRow = ({ goal, onEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}>
      <span className='hidden-xs'>{goal.event?.eventName || ''}</span>&nbsp;
      <SmallGridRowIcons goal={goal} onEdit={onEdit} onDelete={onDelete} />
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Event
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {goal.event?.eventName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
          Goal Time
        </div>
        <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
          {formatTimeForDisplay(goal.goalTime) || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const SmallGrid = ({ data, isLoading, onEdit, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      )
      : data.length > 0
        ? data.map((goal, i) => <SmallGridRow key={i} goal={goal} onEdit={onEdit} onDelete={onDelete} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Goals</div>
            </div>
          </div>
        )
    }
  </div>
);

const ProfileGoalsGrid = ({ goalsData, isLoading, gridFilters, onEdit, onDelete }) => {
  const {
    gridDataState
  } = useProfileGoalsGrid(goalsData, gridFilters);

  return (
    <Fragment>
      <LargeGrid data={gridDataState} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} />
      <SmallGrid data={gridDataState} isLoading={isLoading} onEdit={onEdit} onDelete={onDelete} />
    </Fragment>
  );
};

export default ProfileGoalsGrid;