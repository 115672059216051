import usePersonApplications from './UsePersonApplications';

import Grid from './Grid';

import PersonPositionCertificationNoteDropdown from '../../../../components/dropdowns/personPositionCertificationNoteDropdown/PersonPositionCertificationNoteDropdown';

import Headings from '../../../../../common/components/headings/Headings';
import LoadingModal from '../../../../../common/components/dialogs/LoadingModal';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import Textarea from '../../../../../common/components/inputs/Textarea';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';

import global from '../../../../../common/components/GlobalStyle.module.css';

const PersonApplications = ({ personId, personPositionCertificationRequestId, buttonClickHandlers }) => {
  const { isLoading, localOtsCertificationApplicationState, officialPositionCertificationTypeProgressionState,
    showError, showLoading, errorMessage, gridState, error, expirationDateOptions, statusOptions,
    onUpdatePositionCertificationProgressionIdValueChange, onUpdateExpirationDateValueChange, onUpdateStatusValueChange,
    handleSubmit, formState, errorState, onValueTextPairChanged, onFormValueChanged, onSaveClicked, onBackClicked,
    onOfficialHistoryReportLoadClick, onOfficialEvaluationReportLoadClick, onErrorCanceled, onAddRow, onDeleteRow } = usePersonApplications(personId, personPositionCertificationRequestId);

  return (
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 col-md-4">
        <Headings.H3>Open Certification Applications</Headings.H3>
      </div>
      <div className="col-xs-12 col-md-8">
        <ActionIntraPageButton className={"pull-right"} onClick={onOfficialHistoryReportLoadClick}>Open Official&apos;s History Report</ActionIntraPageButton><span className='pull-right'>&nbsp;</span>
        <ActionIntraPageButton className={"pull-right"} onClick={onOfficialEvaluationReportLoadClick}>Open Official&apos;s Evaluation Report</ActionIntraPageButton>
      </div>
      <div className="col-xs-12 usas-extra-top-margin">
        {showLoading === true && <LoadingModal />}
        {errorMessage &&
          <PopUpModal
            widthPct={50}
            maxWidth={250}
            title="Error" onModalCanceled={onErrorCanceled}>{errorMessage}
          </PopUpModal>}
        {isLoading || localOtsCertificationApplicationState.isArrayLoading && <LoadingModal />}
        {!isLoading && error && <p className={global.ErrorMessage}>{error}</p>}
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Expiration Date"
            name="expirationDateId"
            options={expirationDateOptions}
            value={formState.expirationDateId}
            error={errorState.expirationDateId}
            message={errorState.expirationDateId}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'expirationDateId', newValueLabel, 'expirationDateName');
            }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Status"
            name="statusId"
            options={statusOptions}
            value={formState.statusId}
            error={errorState.statusId}
            message={errorState.statusId}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'statusId', newValueLabel, 'statusName');
            }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <button className={global.PlainButton} type="submit">Synchronize</button>
        </div>
      </form>
      <div className="col-xs-12 usas-extra-top-margin">
        {!isLoading && <Grid personId={personId} personPositionCertificationRequestId={personPositionCertificationRequestId} showError={showError} gridState={gridState}
          officialPositionCertificationTypeProgressionState={officialPositionCertificationTypeProgressionState} onAddRow={onAddRow} onDeleteRow={onDeleteRow} onReviewClicked={buttonClickHandlers.onReviewClicked}
          expirationDateOptions={expirationDateOptions} statusOptions={statusOptions} onUpdatePositionCertificationProgressionIdValueChange={onUpdatePositionCertificationProgressionIdValueChange}
          onUpdateExpirationDateValueChange={onUpdateExpirationDateValueChange} onUpdateStatusValueChange={onUpdateStatusValueChange} />}
      </div>
      <div className="col-xs-12 usas-extra-top-margin">
        <PersonPositionCertificationNoteDropdown
          label="Public Notes"
          name="personPositionCertificationNoteId"
          value={formState.personPositionCertificationNoteId}
          error={errorState.personPositionCertificationNoteId}
          message={errorState.personPositionCertificationNoteId}
          onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'personPositionCertificationNoteId', newValueLabel, 'personPositionCertificationNoteName'); }}
        />
      </div>
      <div className="col-xs-12 usas-extra-top-margin">
        <Textarea
          className={global.Textarea}
          label="Additional Public Notes"
          name="publicNotes"
          value={formState.publicNotes}
          error={errorState.publicNotes}
          message={errorState.publicNotes}
          onChange={(value) => { onFormValueChanged('publicNotes', value); }} />
      </div>
      <div className="col-xs-12">
        <Textarea
          className={global.Textarea}
          label="Private Notes"
          name="privateNotes"
          value={formState.privateNotes}
          error={errorState.privateNotes}
          message={errorState.privateNotes}
          onChange={(value) => { onFormValueChanged('privateNotes', value); }} />
      </div>
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
        <SecondaryButton onClick={onBackClicked}>Back</SecondaryButton>
      </div>
    </div>
  );
};

export default PersonApplications;