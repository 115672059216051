import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import Constants from '../../../../common/utils/Constants';
import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

const TITLE = 'Sponsors';

const useNationalTeamSponsors = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personNationalTeamState, getPersonNationalTeam, putPersonNationalTeam } = usePersonNationalTeamData();
  const [modalState, setModalState] = useState(getInitialModalState());

  const onAddEditSponsor = (e, personSponsorId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_SPONSORS_DETAIL?.route, { state: { personSponsorId } });
  }

  const onDeleteSponsor = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const personNationalTeamObj = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    const index = personNationalTeamObj.sponsors.findIndex(e => e.personSponsorId === modalState.personSponsorId);
    if (index > Constants.DEFAULT_ID) {
      personNationalTeamObj.sponsors.splice(index, 1);
      putPersonNationalTeam(personNationalTeamState.objData.personId, personNationalTeamObj);
    }
    setModalState({ ...getInitialModalState() });
  };

  // Called by grid delete button, populates modal with target narrative info
  const onOpenModalClicked = (e, personSponsorId, sponsorName) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      personSponsorId: personSponsorId,
      sponsorName: sponsorName,
      displayPopUp: true
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...getInitialModalState() });
  };

  useEffect(() => {
    if (personNationalTeamState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personNationalTeamState.objData)) {
      getPersonNationalTeam(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  function getInitialModalState() {
    return {
      personSponsorId: Constants.DEFAULT_ID,
      sponsorName: '',
      displayPopUp: false,
      modalTitle: 'Delete this Sponsor?'
    }
  };

  return {
    title: TITLE,
    DEFAULT_ID: Constants.DEFAULT_ID,
    Constants,
    personNationalTeamState,
    modalState,
    onAddEditSponsor,
    onDeleteSponsor,
    onOpenModalClicked,
    onModalCanceled
  }
}

export default useNationalTeamSponsors;