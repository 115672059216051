import React, { Fragment } from 'react';

import Textarea from '../../../../../common/components/inputs/Textarea';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const EvaluationFormTextInput = ({ question, onItemClick, onItemClickDropdown, onItemClickDropdownHandleFirstLoadOnEdit, disabled }) => {
  const options = [{ id: Constants.DEFAULT_ID, name: '--' }];
  question.evaluationQuestionDropdown?.forEach(x => { options.push({ id: x.evaluationQuestionDropdownId, name: x.dropdownValue }) })
  return (
    <div className="row">
      <div className="col-xs-12" dangerouslySetInnerHTML={{ __html: question.acknowledgementFormText }}></div>
      <div className="col-xs-12">
        {disabled === true ?
          <ReadOnly
            name={`question${question.evaluationQuestionId}`}
            label={question.question || ''}
            value={question.field || ''}
          /> :
          <Fragment>
            {question.evaluationQuestionDropdown ?
              <Dropdown
                label={question.question}
                options={options}
                name={"question" + question?.evaluationQuestionId}
                value={question.field ?? Constants.DEFAULT_ID}
                onChange={(value, valueLabel, e) => {
                  if (Number.isInteger(question.field) === true || question.field === null) {
                    onItemClickDropdown(question.evaluationQuestionId, value, e);
                  }
                  else {
                    if (onItemClickDropdownHandleFirstLoadOnEdit) {
                      onItemClickDropdownHandleFirstLoadOnEdit(question.evaluationQuestionId, question.field)
                    }
                    else {
                      onItemClickDropdown(question.evaluationQuestionId, value, e);
                    }
                  }
                }}
                areValuesIntegers={true}
              /> :
              <Textarea
                className={global.Textarea}
                name={`question${question.evaluationQuestionId}`}
                label={question.question || ''}
                value={question.field || ''}
                onChange={value => onItemClick(question.evaluationQuestionId, value)}
              />
            }
          </Fragment>}
      </div>
    </div>
  );
};

export default EvaluationFormTextInput;