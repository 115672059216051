
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OtsMeetOfficialsOfficialFormValidation';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useMeetOfficialSessionData from '../../../../state/meetOfficialSession/UseMeetOfficialSessionData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import Constants from '../../../../../common/utils/Constants';
import UseForm from '../../../../../common/utils/UseForm';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  meetOfficialPersonId: '',
  firstName: '',
  preferredName: '',
  middleName: '',
  lastName: '',
  meetOfficialMemberId: '',
  selectedValidMemberId: false,
  OFFICIALS_ROLES_ROLE_GROUP_ID: ''
};

const INITIAL_MODAL_STATE = {
  meetOfficialSessionId: Constants.DEFAULT_ID,
  modalTitle: "Delete Meet Official's Session Role?",
  displayPopUp: false,
  session: '',
  oqmSession: '',
  roleWorked: '',
  hasEvaluationForm: '',
  numberOfSessions: 0
};

const useOtsMeetOfficialsOfficial = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { meetOfficialSessionState, getMeetOfficialSessionsByOtsMeetIdAndPersonId,
    deleteMeetOfficialSession, confirmMeetOfficialSessionSave } = useMeetOfficialSessionData();
  const { environmentVariableState, OFFICIALS_ROLES_ROLE_GROUP_ID, OFFICIALS_ROLES_ROLE_GROUP_NAME
  } = useEnvironmentVariableData();
  const { formState, errorState, setFormState, onFormValueChanged, handleSubmit, setFormData, isSubmitting
  } = UseForm(INITIAL_FORM_STATE, () => { }, validate);
  const { navRoutes } = useNavRoutes();
  const [state, setState] = useState({ tryGet: false });
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onAddSessionRole = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_OFFICIALS_OFFICIAL_DETAIL?.route, {
      state: {
        meetOfficialPersonId: formState.meetOfficialPersonId, firstName: formState.firstName,
        preferredName: formState.preferredName, middleName: formState.middleName,
        lastName: formState.lastName, meetOfficialMemberId: formState.meetOfficialMemberId,
        selectedValidMemberId: formState.selectedValidMemberId
      }
    });
  };

  const onEditSessionRole = (e, sessionRole) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_OFFICIALS_OFFICIAL_DETAIL?.route, {
      state: {
        isEdit: true,
        meetOfficialPersonId: formState.meetOfficialPersonId, firstName: formState.firstName,
        preferredName: formState.preferredName, middleName: formState.middleName,
        lastName: formState.lastName, meetOfficialMemberId: formState.meetOfficialMemberId,
        selectedValidMemberId: formState.selectedValidMemberId,
        meetOfficialSessionIds: sessionRole.session,
        meetSessionIds: sessionRole.session,
        meetOfficialRoleTypeId: sessionRole.meetOfficialRoleTypeId,
        isApprentice: sessionRole.isApprentice
      }
    });
  };

  const onDeleteSessionRole = (e, meetOfficialSessionId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    deleteMeetOfficialSession(meetOfficialSessionId);
    onModalCanceled();
    setState({ tryGet: true });
  }

  const onBackClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_OFFICIALS?.route);
  };

  const onOpenModalClicked = (e, session, role) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      meetOfficialSessionId: session.meetOfficialSessionId,
      session: `${session.sessionOrderId || ''} (${session.sessionType || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})`,
      oqmSession: session.isOfficialsQualifyingSession === true ? 'Yes' : session.isOfficialsQualifyingSession === false ? 'No' : '',
      roleWorked: `${role.roleTypeName || ''} (${role.roleTypeAbbreviation || ''})`,
      isApprentice: role.isApprentice === true ? 'Apprentice' : 'No',
      hasEvaluationForm: role.hasEvaluationForm,
      numberOfSessions: role.session?.length,
      displayPopUp: true
    });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      if (location.state) {
        setFormData({
          ...formState,
          meetOfficialPersonId: location.state?.meetOfficialPersonId || '',
          meetOfficialMemberId: location.state?.meetOfficialMemberId || '',
          firstName: location.state?.firstName || '',
          preferredName: location.state?.preferredName || '',
          middleName: location.state?.middleName || '',
          lastName: location.state?.lastName || '',
          selectedValidMemberId: location.state?.selectedValidMemberId ?? false,
          OFFICIALS_ROLES_ROLE_GROUP_ID
        });
      }
      else {
        setFormData({
          ...formState,
          OFFICIALS_ROLES_ROLE_GROUP_ID
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState])

  useEffect(() => {
    if (formState.selectedValidMemberId === true && formState.meetOfficialPersonId !== '') {
      getMeetOfficialSessionsByOtsMeetIdAndPersonId(otsMeetHeaderState.objData?.otsMeetId, formState.meetOfficialPersonId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.selectedValidMemberId, formState.meetOfficialPersonId])

  useEffect(() => {
    if (formState.selectedValidMemberId === true && formState.meetOfficialPersonId !== '' &&
      state.tryGet === true && meetOfficialSessionState.isSaved === true) {
      getMeetOfficialSessionsByOtsMeetIdAndPersonId(otsMeetHeaderState.objData?.otsMeetId, formState.meetOfficialPersonId);
      confirmMeetOfficialSessionSave();
      setState({ ...state, tryGet: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGet, meetOfficialSessionState])

  return {
    meetOfficialSessionState,
    isLoading: meetOfficialSessionState.isArrayLoading || isSubmitting,
    isSaving: meetOfficialSessionState.isSaving,
    OFFICIALS_ROLES_ROLE_GROUP_NAME,
    Constants,
    formState,
    errorState,
    handleSubmit,
    setFormState,
    onFormValueChanged,
    onAddSessionRole,
    onEditSessionRole,
    onDeleteSessionRole,
    onBackClicked,
    modalState,
    onOpenModalClicked,
    onModalCanceled
  };
}

export default useOtsMeetOfficialsOfficial;