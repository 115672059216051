import React, { createContext, useState } from 'react';

import ReportPeriodFlatData from './ReportPeriodFlatData';

export const ReportPeriodFlatStateContext = createContext();

const ReportPeriodFlatContextProvider = ({ children }) => {
  const stateHook = useState(ReportPeriodFlatData.INITIAL_STATE);

  return (
    <ReportPeriodFlatStateContext.Provider value={stateHook}>
      {children}
    </ReportPeriodFlatStateContext.Provider>
  );
};

export default ReportPeriodFlatContextProvider;