const GroupFieldNamesData = {
  GROUP_TYPE_NAME_LIST: 'List',
  GROUP_TYPE_NAME_COMPETITOR_LIST: 'Competitor List',
  GROUP_TYPE_NAME_CAMP: 'Camp',
  GROUP_TYPE_NAME_TRAVEL_TRIP: 'Travel Trip',
  GROUP_TYPE_NAME_PRACTICE_GROUP: 'Practice Group',
  GROUP_TYPE_NAME_SAA: 'Scholastic All America',
  GROUP_TYPE_NAME_NATIONAL_TEAM: 'National Team',
  GROUP_TYPE_NAME_CLUB_SITE: 'Club Site',
  ORG_GROUP_TYPE_FIELD_NAME_YEAR: 'Year',
  ORG_GROUP_TYPE_FIELD_NAME_CITY: 'City',
  ORG_GROUP_TYPE_FIELD_NAME_STATE: 'State',
  ORG_GROUP_TYPE_FIELD_NAME_ZIP: 'Zip',
  ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_START_DATE: 'Qualifying Start Date',
  ORG_GROUP_TYPE_FIELD_NAME_QUALIFYING_END_DATE: 'Qualifying End Date',
  ORG_GROUP_TYPE_FIELD_NAME_START_DATE: 'Start Date',
  ORG_GROUP_TYPE_FIELD_NAME_END_DATE: 'End Date',
  ORG_GROUP_TYPE_FIELD_NAME_FACILITY: 'Facility',
  ORG_GROUP_TYPE_FIELD_NAME_TOTAL_ATHLETES: 'Total Number of Invited Athletes',
  ORG_GROUP_TYPE_FIELD_NAME_TRIP_PURPOSE: 'Trip Purpose',
  ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD: 'Time Standard',
  ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P1: 'P1 Time Standard',
  ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P2: 'P2 Time Standard',
  ORG_GROUP_TYPE_FIELD_NAME_TIME_STANDARD_P3: 'P3 Time Standard',
  ORG_GROUP_TYPE_FIELD_NAME_LEAD_COACH: 'Lead Coach',
  ORG_GROUP_TYPE_FIELD_NAME_EFFECTIVE_DATE: 'Effective Date',
  ORG_GROUP_TYPE_FIELD_NAME_EXPIRATION_DATE: 'Expiration Date',
  PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE: 'GroupAttendeeType',
  PERSON_GROUP_TYPE_FIELD_NAME_ROLE_TYPE: 'GroupRoleType',
  PERSON_GROUP_TYPE_FIELD_NAME_COACH_OF_RECORD: 'CoachOfRecord',
  PERSON_GROUP_TYPE_FIELD_NAME_PERSONAL_COACH: 'PersonalCoach',
  PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL: 'High School Name',
  PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL_GRADUATION_YEAR: 'High School Graduation Year',
  PERSON_GROUP_TYPE_FIELD_NAME_GPA: 'GPA',
  PERSON_GROUP_TYPE_FIELD_NAME_UNWEIGHTED_GPA: 'Unweighted GPA',
  PERSON_GROUP_TYPE_FIELD_NAME_GPA_FILE: 'GPA File Upload',
  PERSON_GROUP_TYPE_FIELD_NAME_PARA_STATUS: 'Para Status',
  PERSON_GROUP_TYPE_FIELD_NAME_OPEN_WATER_STATUS: 'Open Water Status',
  PERSON_GROUP_TYPE_FIELD_NAME_CITY_OF_BIRTH: 'City of Birth',
  PERSON_GROUP_TYPE_FIELD_NAME_COUNTRY_OF_BIRTH: 'Country of Birth',
  PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_ADDRESS: 'Fan Mail Address',
  PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_CITY: 'Fan Mail City',
  PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_STATE: 'Fan Mail State',
  PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_ZIP: 'Fan Mail Zip',
  PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_NUMBER: 'Passport Number',
  PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_EFFECTIVE_DATE: 'Passport Effective Date',
  PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_EXPIRATION_DATE: 'Passport Expiration Date',
  PERSON_GROUP_TYPE_FIELD_NAME_PREFERRED_AIRLINE: 'Preferred Airline',
  PERSON_GROUP_TYPE_FIELD_NAME_AIRLINE_STATUS: 'Airline Status',
  PERSON_GROUP_TYPE_FIELD_NAME_KNOWN_TRAVELER_NUMBER: 'Known Traveler Number',
  PERSON_GROUP_TYPE_FIELD_NAME_PRIMARY_HEALTH_PROVIDER: 'Primary Health Provider',
  PERSON_GROUP_TYPE_FIELD_NAME_AISLE_WINDOW_PREFERENCE: 'Aisle/Window Preference',
  PERSON_GROUP_TYPE_FIELD_NAME_AGENT: 'Agent',
  PERSON_GROUP_TYPE_FIELD_NAME_PROFESSIONAL_SWIMMER: 'Professional Swimmer',
  PERSON_GROUP_TYPE_FIELD_NAME_COLLEGE: 'College',
  PERSON_GROUP_TYPE_FIELD_NAME_COLLEGE_GRADUATION_YEAR: 'College Graduation Year',
  PERSON_GROUP_QUALIFICATION_STATUS_INVITED: 'Invited',
  PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL_GRADING_SYSTEM: 'High School Grading System',
  PERSON_GROUP_TYPE_FIELD_NAME_CLASS_YEAR: 'Class Year',
  PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED: 'Potential/Confirmed',
  PERSON_GROUP_QUALIFICATION_STATUS_INVITED: 'Invited'
};

export default GroupFieldNamesData;