import { useEffect, useState } from 'react';

import validate from './NationalTeamRegistrationFormValidation';

import { createGroupMemberObj, getGroupPropertyOptions, getNewGroupStatus } from '../../../utils/GroupsUtils';

import useStatesCombobox from '../../../components/comboboxes/statesCombobox/UseStatesCombobox';

import useGroupFieldNamesData from '../../../state/groupFieldNames/UseGroupFieldNamesData';
import useOrgGroupPropertiesData from '../../../state/orgGroupProperties/UseOrgGroupPropertiesData';

import UseForm from '../../../utils/UseForm';
import Constants from '../../../utils/Constants';
import { formatDate } from '../../../utils/DateFunctions';

const INITIAL_FORM_STATE = {
  groupAttendeeTypeId: '',
  groupAttendeeTypeName: '--',
  groupRoleTypeId: '',
  groupRoleTypeName: '--',
  professionalSwimmer: '',
  cityOfBirth: '',
  countryOfBirth: '',
  fanMailAddress: '',
  fanMailCity: '',
  fanMailStateCode: '',
  fanMailStateName: '',
  fanMailZip: '',
  passportNumber: '',
  passportEffectiveDate: Constants.BLANK_DATE_STRING,
  passportExpirationDate: Constants.BLANK_DATE_STRING,
  knownTravelerNumber: '',
  preferredAirlineId: '',
  preferredAirlineName: '--',
  aisleWindowPreferenceId: '',
  aisleWindowPreferenceName: '--',
  airlineStatus: '',
  primaryHealthProvider: '',
  agent: '',
  highSchoolName: '',
  highSchoolGraduationYear: 'Year',
  collegeName: '',
  collegeGraduationYear: 'Year',
  potentialOrConfirmedId: '',
  potentialOrConfirmedName: '--'
};

const INITIAL_OPTIONS_STATE = {
  areOptionsLoaded: false,
  attendTypeOptions: [],
  roleOptions: [],
  airlineOptions: [],
  aisleWindowOptions: [],
  potentialOrConfirmedOptions: []
};

const useNationalTeamRegistrationForm = (personId, personOrgGroupId, personOrgGroupQualificationStatus, groupObj, memberGroupObj, disableValidation, onFormCompletion) => {
  const { orgGroupPropertiesState, getOrgGroupProperties } = useOrgGroupPropertiesData();
  const { GROUP_CONSTANTS } = useGroupFieldNamesData();
  const { getStateNameById } = useStatesCombobox();
  const { formState, errorState, setFormState, onValueTextPairChanged, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, disableValidation === true ? () => { } : validate);
  const [optionsState, setOptionsState] = useState(INITIAL_OPTIONS_STATE);

  useEffect(() => {
    if (groupObj.groupTypeId > 0 && optionsState.areOptionsLoaded === false) {
      if (orgGroupPropertiesState.isArrayLoaded === false || groupObj.groupTypeId !== orgGroupPropertiesState.groupTypeId) {
        const getOrgGroupPropertiesPromise = getOrgGroupProperties(groupObj.groupTypeId);

        if (getOrgGroupPropertiesPromise !== null) {
          getOrgGroupPropertiesPromise.catch((e) => {
            //TODO
          });
        }
      } else {
        const attendTypeOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE, orgGroupPropertiesState.arrayData);
        const roleOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ROLE_TYPE, orgGroupPropertiesState.arrayData);
        const airlineOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PREFERRED_AIRLINE, orgGroupPropertiesState.arrayData);
        const aisleWindowOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_AISLE_WINDOW_PREFERENCE, orgGroupPropertiesState.arrayData);
        const potentialOrConfirmedOptions = getGroupPropertyOptions(GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED, orgGroupPropertiesState.arrayData);

        potentialOrConfirmedOptions.unshift({ id: '', name: '--' })

        setOptionsState({
          ...optionsState,
          attendTypeOptions,
          roleOptions,
          airlineOptions,
          aisleWindowOptions,
          potentialOrConfirmedOptions,
          areOptionsLoaded: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupObj, orgGroupPropertiesState.isArrayLoaded]);

  useEffect(() => {
    if (optionsState.areOptionsLoaded === true && memberGroupObj) {
      const personGroupTypeField = memberGroupObj.personGroupTypeField || [];
      const groupAttendeeTypeName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE)?.fieldValue || '';
      const groupRoleTypeName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ROLE_TYPE)?.fieldValue || '';
      const preferredAirlineName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PREFERRED_AIRLINE)?.fieldValue || '';
      const aisleWindowPreferenceName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_AISLE_WINDOW_PREFERENCE)?.fieldValue || '';
      const fanMailStateCode = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_STATE)?.fieldValue || '';
      const passportEffectiveDate = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_EFFECTIVE_DATE)?.fieldValue || '';
      const passportExpirationDate = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_EXPIRATION_DATE)?.fieldValue || '';
      const potentialOrConfirmedName = personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED)?.fieldValue || '';

      const groupAttendeeType = optionsState.attendTypeOptions.find(x => x.name === groupAttendeeTypeName);
      const groupRoleType = optionsState.roleOptions.find(x => x.name === groupRoleTypeName);
      const preferredAirline = optionsState.airlineOptions.find(x => x.name === preferredAirlineName);
      const aisleWindowPreference = optionsState.aisleWindowOptions.find(x => x.name === aisleWindowPreferenceName);
      const potentialOrConfirmed = optionsState.potentialOrConfirmedOptions.find(x => x.name === potentialOrConfirmedName);

      setFormState({
        ...formState,
        groupAttendeeTypeId: groupAttendeeType?.id || optionsState.attendTypeOptions[0]?.id || '',
        groupAttendeeTypeName: groupAttendeeType?.name || optionsState.attendTypeOptions[0]?.name || '--',
        groupRoleTypeId: groupRoleType?.id || optionsState.roleOptions[0]?.id || '',
        groupRoleTypeName: groupRoleType?.name || optionsState.roleOptions[0]?.name || '--',
        professionalSwimmer: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PROFESSIONAL_SWIMMER)?.fieldValue || '',
        cityOfBirth: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_CITY_OF_BIRTH)?.fieldValue || '',
        countryOfBirth: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COUNTRY_OF_BIRTH)?.fieldValue || '',
        fanMailAddress: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_ADDRESS)?.fieldValue || '',
        fanMailCity: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_CITY)?.fieldValue || '',
        fanMailStateCode,
        fanMailStateName: getStateNameById(fanMailStateCode),
        fanMailZip: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_ZIP)?.fieldValue || '',
        passportNumber: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_NUMBER)?.fieldValue || '',
        passportEffectiveDate: passportEffectiveDate ? formatDate(passportEffectiveDate) : Constants.BLANK_DATE_STRING,
        passportExpirationDate: passportExpirationDate ? formatDate(passportExpirationDate) : Constants.BLANK_DATE_STRING,
        knownTravelerNumber: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_KNOWN_TRAVELER_NUMBER)?.fieldValue || '',
        preferredAirlineId: preferredAirline?.id || optionsState.airlineOptions[0]?.id || '',
        preferredAirlineName: preferredAirline?.name || optionsState.airlineOptions[0]?.name || '--',
        aisleWindowPreferenceId: aisleWindowPreference?.id || optionsState.aisleWindowOptions[0]?.id || '',
        aisleWindowPreferenceName: aisleWindowPreference?.name || optionsState.aisleWindowOptions[0]?.name || '--',
        airlineStatus: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_AIRLINE_STATUS)?.fieldValue || '',
        primaryHealthProvider: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PRIMARY_HEALTH_PROVIDER)?.fieldValue || '',
        agent: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_AGENT)?.fieldValue || '',
        highSchoolName: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL)?.fieldValue || '',
        highSchoolGraduationYear: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL_GRADUATION_YEAR)?.fieldValue || 'Year',
        collegeName: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COLLEGE)?.fieldValue || '',
        collegeGraduationYear: personGroupTypeField.find(x => x.groupTypeField?.fieldName === GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COLLEGE_GRADUATION_YEAR)?.fieldValue || 'Year',
        potentialOrConfirmedId: potentialOrConfirmed?.id || optionsState.potentialOrConfirmedOptions[0]?.id || '',
        potentialOrConfirmedName: potentialOrConfirmed?.name || optionsState.potentialOrConfirmedOptions[0]?.name || '--'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsState.areOptionsLoaded, memberGroupObj]);

  function submitFormCallback(formState) {
    const orgGroupId = groupObj?.orgGroupId;
    const personOrgGroupQualification = Array.isArray(memberGroupObj.personOrgGroupQualification) && memberGroupObj.personOrgGroupQualification.length > 0
      ? memberGroupObj.personOrgGroupQualification[0]
      : undefined;

    if (personId && orgGroupId) {
      const groupStatus = getNewGroupStatus(personOrgGroupQualificationStatus, groupObj.requiresApproval);

      const groupMemberObj = createGroupMemberObj(personId, orgGroupId, [
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ATTENDEE_TYPE, fieldValue: formState.groupAttendeeTypeId },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_ROLE_TYPE, fieldValue: formState.groupRoleTypeId },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PROFESSIONAL_SWIMMER, fieldValue: formState.professionalSwimmer },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_CITY_OF_BIRTH, fieldValue: formState.cityOfBirth },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COUNTRY_OF_BIRTH, fieldValue: formState.countryOfBirth },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_ADDRESS, fieldValue: formState.fanMailAddress },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_CITY, fieldValue: formState.fanMailCity },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_STATE, fieldValue: formState.fanMailStateCode },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_FAN_MAIL_ZIP, fieldValue: formState.fanMailZip },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_NUMBER, fieldValue: formState.passportNumber },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_EFFECTIVE_DATE, fieldValue: formState.passportEffectiveDate },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PASSPORT_EXPIRATION_DATE, fieldValue: formState.passportExpirationDate },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_KNOWN_TRAVELER_NUMBER, fieldValue: formState.knownTravelerNumber },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PREFERRED_AIRLINE, fieldValue: formState.preferredAirlineId },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_AISLE_WINDOW_PREFERENCE, fieldValue: formState.aisleWindowPreferenceId },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_AIRLINE_STATUS, fieldValue: formState.airlineStatus },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_PRIMARY_HEALTH_PROVIDER, fieldValue: formState.primaryHealthProvider },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_AGENT, fieldValue: formState.agent },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL, fieldValue: formState.highSchoolName },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_HIGH_SCHOOL_GRADUATION_YEAR, fieldValue: formState.highSchoolGraduationYear },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COLLEGE, fieldValue: formState.collegeName },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_COLLEGE_GRADUATION_YEAR, fieldValue: formState.collegeGraduationYear },
        { fieldName: GROUP_CONSTANTS.PERSON_GROUP_TYPE_FIELD_NAME_POTENTIAL_CONFIRMED, fieldValue: formState.potentialOrConfirmedId }
      ], orgGroupPropertiesState.arrayData, personOrgGroupId, personOrgGroupQualification, groupStatus);

      onFormCompletion(groupMemberObj);
    }
  };

  return {
    formState,
    errorState,
    optionsState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit
  };
};

export default useNationalTeamRegistrationForm;