import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../../../common/wrappers/ReactRouterDom";

import useOfferingManagementData from "../../../../../state/offeringManagement/UseOfferingManagementData";

import useNavRoutes from "../../../../../../common/state/security/UseNavRoutes";

const INITIAL_STATE = {
  displayDeletePopup: false,
  deletePopupOfferingObj: {},
  offeringObj: {},
  pageErrorMessage: ''
};

const useHqOfferingTypeUSASEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { offeringManagementState, putOffering, deleteOffering } = useOfferingManagementData();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmitOfferingForm = (newOfferingObj) => {
    const putOfferingPromise = putOffering(newOfferingObj.offeringId, newOfferingObj);

    if (putOfferingPromise ?? false) {
      putOfferingPromise.then((newState) => {
        if (newState ?? false) {
          navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERINGS?.route)
        }
      }).catch((e) => {
        setState({
          ...state,
          pageErrorMessage: e?.message || 'Failed to update offering'
        })
      });
    }
  };

  const onBackClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERINGS?.route)
  };

  const onDeleteClicked = (e, formState) => {
    e?.preventDefault();

    setState({
      ...state,
      displayDeletePopup: true,
      deletePopupOfferingObj: {
        ...state.offeringObj,
        ...formState,
        orgRoleNames: getOfferingOrgRoleForDisplay(formState.offeringOrgRole)
      }
    });
  };

  const onDeletePopupConfirmClicked = (e) => {
    e?.preventDefault();

    const deleteOfferingPromise = deleteOffering(state.deletePopupOfferingObj.offeringId);

    if (deleteOfferingPromise ?? false) {
      deleteOfferingPromise.then((newState) => {
        if (newState ?? false) {
          navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERINGS?.route)
        }
      }).catch((e) => {
        setState({
          ...state,
          displayDeletePopup: false,
          pageErrorMessage: e?.message || 'Failed to delete offering'
        })
      });
    }
  };

  const onDeletePopupCancelClicked = (e) => {
    e?.preventDefault();

    setState({
      ...state,
      displayDeletePopup: false,
      deletePopupOfferingObj: {}
    });
  };

  useEffect(() => {
    if (location.state?.offering) {
      setState({
        ...state,
        offeringObj: {
          ...location.state.offering,
          offeringTypeName: location.state.offering.offeringType?.typeName
        }
      });
    } else {
      setState({
        ...state,
        pageErrorMessage: 'Failed to load offering'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getOfferingOrgRoleForDisplay(offeringOrgRole) {
    let orgRoleNames = '';

    for (let i = 0; i < offeringOrgRole.length; i++) {
      if (orgRoleNames === '') {
        orgRoleNames = `${offeringOrgRole[i].name}`;
      } else if (i === offeringOrgRole.length - 1) {
        orgRoleNames += ` & ${offeringOrgRole[i].name}`;
      } else {
        orgRoleNames += `, ${offeringOrgRole[i].name}`;
      }
    }

    return orgRoleNames;
  }

  return {
    isSaving: offeringManagementState.isObjLoading,
    state,
    onSubmitOfferingForm,
    onBackClicked,
    onDeleteClicked,
    onDeletePopupConfirmClicked,
    onDeletePopupCancelClicked
  };
};

export default useHqOfferingTypeUSASEdit;