import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import { USA_CLUB_MEMBERSHIP_LABEL } from '../../utils/OfferingManagementConstants';

import useOfferingManagementData from '../../../../state/offeringManagement/UseOfferingManagementData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

const INITIAL_STATE = {
  pageErrorMessage: ''
};

const useHqOffering = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { offeringManagementState, getOfferings } = useOfferingManagementData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCreateOfferingClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_TYPE_SELECT?.route);
  };

  const onEditOfferingClicked = (e, offering) => {
    e?.preventDefault();

    if (offering.offeringType?.typeName === USA_CLUB_MEMBERSHIP_LABEL) {
      navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_TYPE_CLUB_EDIT?.route, { state: { offering } });
    } else {
      navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_TYPE_USAS_EDIT?.route, { state: { offering } });
    }
  };

  useEffect(() => {
    // Note: objData is only used for POST/PUT/DELETE so if objData is loaded, refresh arrayData
    if (offeringManagementState.isArrayLoaded === false || offeringManagementState.isObjLoaded === true) {
      const getOfferingsPromise = getOfferings();

      if (getOfferingsPromise ?? false) {
        getOfferingsPromise.catch((e) => {
          setState({
            ...state,
            pageErrorMessage: e?.message || 'Failed to retrieve offerings'
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: offeringManagementState.isArrayLoading,
    state,
    offeringManagementState,
    onCreateOfferingClicked,
    onEditOfferingClicked
  };
};

export default useHqOffering;