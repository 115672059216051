import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';

const CertificationHeader = ({ positionCertificationProgression, showApplicationStatus }) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-6">
        <p className={global.HeaderText}>
          <strong>Application Type:</strong>&nbsp;{positionCertificationProgression.progressionType}
        </p>
      </div>
      <div className="col-xs-12 col-sm-6">
        {positionCertificationProgression.effectiveDate && (<p className={global.HeaderText}>
          <strong>Date Initiated:</strong>&nbsp;{formatDate(positionCertificationProgression.effectiveDate)}
        </p>)}
      </div>
      <div className="col-xs-12 col-sm-6">
        <p className={global.HeaderText}>
          <strong>Position:</strong>&nbsp;{positionCertificationProgression.positionTypeName}
        </p>
      </div>
      <div className="col-xs-12 col-sm-6">
        <p className={global.HeaderText}>
          <strong>Level:</strong>&nbsp;{positionCertificationProgression.levelName}
        </p>
      </div>
      {showApplicationStatus !== false &&
        <div className="col-xs-12 col-sm-6">
          <p className={global.HeaderText}>
            <strong>Application Status:</strong>&nbsp;{positionCertificationProgression.status || 'Initial Request'}
          </p>
        </div>}
    </div>
  );
};

export default CertificationHeader;