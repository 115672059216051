import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import Constants from '../../../../common/utils/Constants';
import { isPersonObjectDataCurrent } from '../utils/MemberUtils';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';

const TITLE = 'Frequent Flyer';

const useNationalTeamFrequentFlyer = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personNationalTeamState, getPersonNationalTeam, putPersonNationalTeam } = usePersonNationalTeamData();
  const [modalState, setModalState] = useState(getInitialModalState());

  const onAddEditFrequentFlyer = (e, personFrequentFlyerId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_FREQUENT_FLYER_DETAIL?.route, { state: { personFrequentFlyerId } });
  }

  // Called by grid delete button, populates modal with target narrative info
  const onOpenModalClicked = (e, personFrequentFlyerId, frequentFlyerNumber) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      personFrequentFlyerId: personFrequentFlyerId,
      frequentFlyerNumber: frequentFlyerNumber,
      displayPopUp: true
    });
  };

  const onDeleteFrequentFlyer = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const personNationalTeamObj = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    const index = personNationalTeamObj.frequentFlyerDetails.findIndex(e => e.personFrequentFlyerId === modalState.personFrequentFlyerId);

    if (index > Constants.DEFAULT_ID) {
      personNationalTeamObj.frequentFlyerDetails.splice(index, 1);
      putPersonNationalTeam(personNationalTeamState.objData.personId, personNationalTeamObj);
      setModalState({ ...getInitialModalState() });
    }
  };

  // Clears modalState and closes modal
  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...getInitialModalState() });
  };

  useEffect(() => {
    if (personNationalTeamState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personNationalTeamState.objData)) {
      getPersonNationalTeam(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  // Holds initial state for delete modal
  function getInitialModalState() {
    return {
      personFrequentFlyerId: Constants.DEFAULT_ID,
      frequentFlyerNumber: '',
      displayPopUp: false,
      modalTitle: 'Delete this Frequent Flyer Info?'
    }
  };

  return {
    title: TITLE,
    DEFAULT_ID: Constants.DEFAULT_ID,
    Constants,
    personNationalTeamState,
    modalState,
    onAddEditFrequentFlyer,
    onDeleteFrequentFlyer,
    onOpenModalClicked,
    onModalCanceled
  };
}

export default useNationalTeamFrequentFlyer;