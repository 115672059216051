import useOtsOfficialOnlineTests from './UseOtsOfficialOnlineTests';

import OtsOfficialOnlineTestsGrid from './OtsOfficialOnlineTestsGrid';

import Headings from '../../../../../common/components/headings/Headings';
import LoadingModal from '../../../../../common/components/dialogs/LoadingModal';

const OtsOfficialOnlineTests = () => {
  const { isLoading, gridData } = useOtsOfficialOnlineTests();

  return (
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <Headings.H3>Online Tests</Headings.H3>
      </div>
      <div className="col-xs-12">
        {isLoading ? <LoadingModal /> : <OtsOfficialOnlineTestsGrid gridData={gridData} />}
      </div>
    </div>
  );
};

export default OtsOfficialOnlineTests;