import { useContext } from 'react';

import { MeetEvaluationPersonOfficialStateContext } from './MeetEvaluationPersonOfficialContextProvider';

import MeetEvaluationPersonOfficialData from './MeetEvaluationPersonOfficialData';

const useMeetEvaluationPersonOfficialData = () => {
  const [meetEvaluationPersonOfficialState, setMeetEvaluationPersonOfficialState] = useContext(MeetEvaluationPersonOfficialStateContext);

  const getMeetEvaluationsOfficialByPersonId = (personId) => MeetEvaluationPersonOfficialData.getMeetEvaluationsOfficialByPersonIdData(personId, meetEvaluationPersonOfficialState, setMeetEvaluationPersonOfficialState);

  const clearMeetEvaluationPersonOfficialObjData = () => {
    setMeetEvaluationPersonOfficialState({
      ...meetEvaluationPersonOfficialState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearMeetEvaluationPersonOfficialArrayData = () => {
    setMeetEvaluationPersonOfficialState({
      ...meetEvaluationPersonOfficialState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmMeetEvaluationPersonOfficialSave = () => {
    setMeetEvaluationPersonOfficialState({
      ...meetEvaluationPersonOfficialState,
      isSaved: false
    });
  };

  return {
    meetEvaluationPersonOfficialState,
    getMeetEvaluationsOfficialByPersonId,
    clearMeetEvaluationPersonOfficialObjData,
    clearMeetEvaluationPersonOfficialArrayData,
    confirmMeetEvaluationPersonOfficialSave
  };
};

export default useMeetEvaluationPersonOfficialData;