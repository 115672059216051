import { useState, useEffect } from 'react';
import useNationalEvaluatorData from '../../../state/otsNationalEvaluator/UseNationalEvaluatorData';
import useNationalEvaluatorTitleData from '../../../state/otsNationalEvaluatorTitle/UseNationalEvaluatorTitleData';
import useOtsOfficialPositionTypeData from '../../../state/otsOfficialPositionType/UseOtsOfficialPositionTypeData';

const INITIAL_VIEW_STATE = {
  isLoading: true,
  showGrid: true,
  showAddForm: false,
  showEditForm: false,
  isSaving: false,
  nationalEvaluatorId: -1,
  gridData: [],
  errorMessage: '',
  lastNameFilter: ''
};

const useNationalEvaluators = () => {
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const { NationalEvaluatorState, getNationalEvaluators, deleteNationalEvaluator } = useNationalEvaluatorData();
  const { getNationalEvaluatorTitles, nationalEvaluatorTitleState } = useNationalEvaluatorTitleData();
  const { getAvailableOfficialPositionTypes, officialPositionTypeState } = useOtsOfficialPositionTypeData();

  const getGridData = () => {
    getNationalEvaluators()
      .then((data) => {
        const gridDataCopy = JSON.parse(JSON.stringify(data.arrayData));
        const gridDataSortedLatestExpirationDateFirst = [];

        for (let i = 0; i < gridDataCopy.length; i++) {
          const sortedLatestExpirationDateFirstOfficialPositionTypes = gridDataCopy[i].officialPositionTypes?.sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate));
          gridDataSortedLatestExpirationDateFirst.push({ ...gridDataCopy[i], officialPositionTypes: sortedLatestExpirationDateFirstOfficialPositionTypes })
        }

        if (state.lastNameFilter === '') {
          setState({
            ...INITIAL_VIEW_STATE,
            isLoading: false,
            gridData: gridDataSortedLatestExpirationDateFirst,
            lastNameFilter: state.lastNameFilter
          });
        }
        else {
          const gridDataSortedLatestExpirationDateFirstFiltered = gridDataSortedLatestExpirationDateFirst.filter(official => official.evaluatorName && official.evaluatorName[0]?.toLowerCase() === state.lastNameFilter);
          setState({ 
            ...INITIAL_VIEW_STATE,
            isLoading: false, 
            gridData: gridDataSortedLatestExpirationDateFirstFiltered,
            lastNameFilter: state.lastNameFilter
           });
        }
      })
      .catch((_e) => {
        setState({
          ...INITIAL_VIEW_STATE,
          isLoading: false,
          errorMessage: 'There was a problem getting the data from the server.  Please try again later.'
        });
      });
  };

  const onAddButtonClicked = () => {
    setState({
      ...state,
      showGrid: false,
      showAddForm: true,
      errorMessage: '',
    });
  };

  const onCallback = (_formState, _setErrors) => {
    getGridData();
  };

  const returnToGrid = () => {
    setState({
      ...state,
      showGrid: true,
      showAddForm: false,
      showEditForm: false
    });
  };

  const onEdit = (nationalEvaluatorId) => {
    if (nationalEvaluatorId > 0) {
      setState({
        ...state,
        showGrid: false,
        showEditForm: true,
        nationalEvaluatorId,
        errorMessage: '',
      });
    }
  };

  const onDelete = (nationalEvaluatorId) => {
    //TODO:  Add dialog 'Are you sure?'
    if (nationalEvaluatorId > 0) {
      setState({
        ...state,
        isSaving: true,
        errorMessage: ''
      });

      deleteNationalEvaluator(nationalEvaluatorId)
        .then(() => {
          getGridData();
        })
        .catch((_e) => {
          setState({
            ...state,
            isSaving: false,
            errorMessage: 'There was a problem saving the data to the server.  Please try again later.',
          });
        });
    }
  };

  const alphabeticalFilterClicked = (letter) => {
    setState({ ...state, lastNameFilter: letter });
  };

  useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const gridDataCopy = JSON.parse(JSON.stringify(NationalEvaluatorState.arrayData));
    const gridDataSortedLatestExpirationDateFirst = [];

    for (let i = 0; i < gridDataCopy.length; i++) {
      const sortedLatestExpirationDateFirstOfficialPositionTypes = gridDataCopy[i].officialPositionTypes?.sort((a, b) => new Date(b.expirationDate) - new Date(a.expirationDate));
      gridDataSortedLatestExpirationDateFirst.push({ ...gridDataCopy[i], officialPositionTypes: sortedLatestExpirationDateFirstOfficialPositionTypes })
    }
    if (state.lastNameFilter === '') {
      setState({
        ...state,
        gridData: gridDataSortedLatestExpirationDateFirst
      });
    }
    else {
      const gridDataSortedLatestExpirationDateFirstFiltered = gridDataSortedLatestExpirationDateFirst.filter(official => official.evaluatorName && official.evaluatorName[0]?.toLowerCase() === state.lastNameFilter);
      setState({ ...state, gridData: gridDataSortedLatestExpirationDateFirstFiltered });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastNameFilter]);

  useEffect(() => {
    if (nationalEvaluatorTitleState.isArrayLoaded === false) {
      getNationalEvaluatorTitles(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (officialPositionTypeState.isArrayLoaded === false) {
      getAvailableOfficialPositionTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...state,
    nationalEvaluatorTitles: nationalEvaluatorTitleState.options,
    officialPositionTypes: officialPositionTypeState.arrayData || [],
    onAddButtonClicked,
    onCallback,
    returnToGrid,
    onEdit,
    onDelete,
    alphabeticalFilterClicked
  };
};

export default useNationalEvaluators;