import { useEffect, useState } from 'react';
import EmbeddedReportData from '../../state/embeddedReport/EmbeddedReportData';

const INITIAL_VIEW_STATE = {frameId: '', title: '', src: '', containerStyle: {}};

const useReport = (embedSlotName, reportParameters) => {
  const [dataState, setDataState] = useState(EmbeddedReportData.INITIAL_STATE);
  const [viewState, setViewState] = useState({...INITIAL_VIEW_STATE});

  useEffect(() => {
    if (embedSlotName) {
      EmbeddedReportData.getEmbeddedReport(embedSlotName, dataState, setDataState)
        .then((stateObj) => {
          if (stateObj.objData.embedSlotName === embedSlotName) {
            setViewState({
              frameId: (embedSlotName || 'report') + '-container', 
              title: stateObj.objData.reportTitle,
              src: stateObj.objData.src,
              containerStyle: {
                padding: 0,
                minHeight: stateObj.objData.minHeight > 0 ? stateObj.objData.minHeight : 600
              }
            });
          } else {
            setViewState({...INITIAL_VIEW_STATE});
          }
        })
        .catch(() => {setViewState({...INITIAL_VIEW_STATE});});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embedSlotName]);

  return {...viewState};
};

export default useReport; 