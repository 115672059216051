import React, { Fragment } from 'react';

import useMemberAffiliationsDetail from './UseMemberAffiliationsDetail';

import AffiliationsTreeView from '../../../components/treeviews/AffiliationsTreeView';
import OrgRoleFieldsSubForm from '../../../components/orgRoleFields/OrgRoleFieldsSubForm';
import AffiliationsOrgRoleTreeView from '../../../components/treeviews/AffiliationsOrgRoleTreeView';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

import global from '../../../../common/components/GlobalStyle.module.css';

const OrganizationFormRow = ({ formState, errorState, onOrgUnitChanged, onOrgRoleChanged }) => (
  <Fragment>
    <div className="col-xs-12 col-sm-6 col-md-4">
      <AffiliationsTreeView
        label="Organization*"
        name="orgUnit"
        value={formState.orgUnit}
        error={errorState.orgUnit}
        message={errorState.orgUnit}
        onChange={(newValue) => { onOrgUnitChanged(newValue) }} />
    </div>
    {formState.organizationId > 0 &&
      <div className="col-xs-12 col-sm-6 col-md-4">
        <AffiliationsOrgRoleTreeView
          label="Member Type or Member Role*"
          name="orgRole"
          value={formState.orgRole}
          error={errorState.orgRole}
          message={errorState.orgRole}
          selectableLeavesOnly={true}
          singleSelect={true}
          onChange={(newValue) => { onOrgRoleChanged(newValue) }}
          organizationId={formState.organizationId} />
      </div>
    }
  </Fragment >
);

const MemberAffiliationsDetail = () => {
  const {
    isSaving,
    isLoading,
    isEdit,
    apiError,
    Constants,
    personGeneralState,
    formState,
    errorState,
    orgRoleFieldState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked,
    onOrgUnitChanged,
    onOrgRoleChanged,
  } = useMemberAffiliationsDetail();

  if (personGeneralState.isObjLoaded !== true) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{isEdit ? "Edit" : "Add"} Affiliation</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          {isEdit ?
            <div className="col-xs-12">
              <p><b>Affiliation:</b> {formState.organizationName} {formState.orgUnit[0]?.name}, {formState.orgRole[0]?.name}</p>
            </div>
            : <OrganizationFormRow
              formState={formState}
              errorState={errorState}
              onOrgUnitChanged={onOrgUnitChanged}
              onOrgRoleChanged={onOrgRoleChanged}
            />
          }
          <div className="col-xs-12 col-sm-6 col-md-4">
            {isEdit //TODO make this editable in edit
              ? (
                <ReadOnly
                  label='Effective Date'
                  name='effectiveDate'
                  value={formState.effectiveDate} />
              ) : (
                <DatePicker
                  label="Effective Date*"
                  name="effectiveDate"
                  value={formState.effectiveDate}
                  error={errorState.effectiveDate}
                  message={errorState.effectiveDate}
                  onChange={(value) => { onFormValueChanged('effectiveDate', value); }}
                  countOfYears={150}
                  startYearOffset={-149} />
              )
            }
          </div>
          {isEdit &&
            <div className="col-xs-12 col-sm-6 col-md-4">
              <ReadOnly
                label='Expiration Date'
                name='expirationDate'
                value={formState.expirationDate} />
            </div>
          }
        </div>
        {orgRoleFieldState.arrayData.length > 0 &&
          <div className='row'>
            <OrgRoleFieldsSubForm
              orgRoleFieldsData={orgRoleFieldState.arrayData}
              formState={formState}
              errorState={errorState}
              onFormValueChanged={onFormValueChanged} />
          </div>
        }
        {errorState.multipleActiveAffiliations &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.multipleActiveAffiliations ? errorState.multipleActiveAffiliations : ''}</p>
            </div>
          </div>
        }
        {apiError === true &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>An unexpected error occurred</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment >
  );
};

export default MemberAffiliationsDetail;