import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';

import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

// import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

// const DetailGridRowIcons = () => (
//   <Fragment>

//   </Fragment>
// );

const DetailGridRow = ({ attachment }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{attachment?.attachmentDescription}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          File Description
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {attachment.attachmentDescription}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ attachment }) => (
  <div className={global.SmallTableDetailTable}>
    <DetailGridRow attachment={attachment} />
  </div>
);

const GridRowIcons = ({ attachment, onEditAttachment, onDelete, expandedId, onExpandClicked }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, attachment.personNationalAttachmentId)}>
      {expandedId === attachment.personNationalAttachmentId ? <HideIcon /> : <ShowIcon />}
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
      onDelete(e, attachment)}>
      <DeleteIcon />
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) =>
      onEditAttachment(e, attachment)}>
      <EditIcon />
    </button>
  </Fragment>
);

const GridRow = ({ attachment, onEditAttachment, onDelete, expandedId, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{attachment.personNationalAttachmentFileTypeName}</span>&nbsp;
        <GridRowIcons attachment={attachment} expandedId={expandedId} onEditAttachment={onEditAttachment} onDelete={onDelete} onExpandClicked={onExpandClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            File Type Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {attachment.personNationalAttachmentFileTypeName}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            File Name
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {attachment.attachmentName}
          </div>
        </div>
      </div>
    </div>
    {expandedId === attachment.personNationalAttachmentId &&
      <DetailGrid attachment={attachment} />
    }
  </Fragment>
);

const NationalTeamAttachmentsSmallGrid = ({ state, onEditAttachment, onDelete, expandedId, onExpandClicked }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {state.isArrayLoading === true ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(state?.arrayData) && state?.arrayData?.length > 0
        ? state?.arrayData?.map((attachment, i) => (
          <GridRow
            key={i}
            expandedId={expandedId}
            attachment={attachment}
            onDelete={onDelete}
            onEditAttachment={onEditAttachment}
            onExpandClicked={onExpandClicked}
          />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Attachments</div>
            </div>
          </div>
        )
      }
    </div>
  </Fragment >
);


export default NationalTeamAttachmentsSmallGrid;