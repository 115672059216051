import React from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ missedDrugTest, onAddEdit, onDelete }) => (
  <tr>
    <td>{missedDrugTest.missingDrugTestDate ? formatDate(missedDrugTest.missingDrugTestDate) : ''}</td>
    <td>{missedDrugTest.testingBody?.bodyName || ''}</td>
    <td>{missedDrugTest.drugTestStatus?.statusName || ''}</td>
    <td>{missedDrugTest.notificationFromTestBodyDate ? formatDate(missedDrugTest.notificationFromTestBodyDate) : ''}</td>
    <td>{missedDrugTest.athleteNotifiedDate ? formatDate(missedDrugTest.athleteNotifiedDate) : ''}</td>
    <td>{missedDrugTest.testAppealedDate ? formatDate(missedDrugTest.testAppealedDate) : ''}</td>
    <td>{missedDrugTest.testBodyAppealResponseDate ? formatDate(missedDrugTest.testBodyAppealResponseDate) : ''}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddEdit(e, missedDrugTest.personMissingDrugTestId)}>
        <EditIcon />
      </button>
    </td>
  </tr>
);

const NationalTeamMissedDrugTestsLargeGrid = ({ gridState, onAddEdit, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Missed Test</th>
        <th>Testing Body</th>
        <th>Status</th>
        <th>Notified By Test Body</th>
        <th>Athlete Notified</th>
        <th>Appealed</th>
        <th>Appeal Response</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {gridState.isObjLoaded !== true
        ? (
          <tr><td colSpan="8">{gridState.isObjLoading === true ? 'Loading...' : 'No Missing Drug Tests'}</td></tr>
        ) : Array.isArray(gridState.objData.missingDrugTests) && gridState.objData.missingDrugTests.length > 0
          ? gridState.objData.missingDrugTests.map((missedDrugTest, i) => <GridRow key={i} missedDrugTest={missedDrugTest} onAddEdit={onAddEdit} onDelete={onDelete} />)
          : <tr><td colSpan="8">No Missing Drug Tests</td></tr>
      }
    </tbody>
  </table>
);

export default NationalTeamMissedDrugTestsLargeGrid;