import { useContext } from 'react';

import { OtsMeetStaffStateContext } from './OtsMeetStaffContextProvider';

import OtsMeetStaffData from './OtsMeetStaffData';

const useOtsMeetStaffData = () => {
  const [otsMeetStaffState, setOtsMeetStaffState] = useContext(OtsMeetStaffStateContext);

  const getOtsMeetStaff = (meetId) => {
    OtsMeetStaffData.getOtsMeetStaffData(meetId, otsMeetStaffState, setOtsMeetStaffState);
  };

  const putOtsMeetStaffData = (meetId, otsMeetStaffObj) => {
    OtsMeetStaffData.putOtsMeetStaffData(meetId, otsMeetStaffObj, otsMeetStaffState, setOtsMeetStaffState);
  };

  const confirmSaveOtsMeetStaff = () => {
    setOtsMeetStaffState({
      ...otsMeetStaffState,
      isSaved: false
    });
  };

  const resetOtsMeetStaffState = () => {
    setOtsMeetStaffState(OtsMeetStaffData.INITIAL_STATE);
  }

  return {
    otsMeetStaffState,
    setOtsMeetStaffState,
    getOtsMeetStaff,
    putOtsMeetStaffData,
    confirmSaveOtsMeetStaff,
    resetOtsMeetStaffState
  };
};

export default useOtsMeetStaffData;