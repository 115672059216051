import Constants from '../../../../../common/utils/Constants';
import { isValidDate } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  if (formState.certificationLevelId === Constants?.DEFAULT_ID && formState.officialPositionTypeId === Constants?.DEFAULT_ID) {
    errors.certificationLevelId = 'Certification Level is required to search for LSC officials';
    errors.officialPositionTypeId = 'Position Worked is required to search for LSC officials';
  }
  if (formState.certificationLevelId === Constants?.DEFAULT_ID) {
    errors.certificationLevelId = 'Certification Level is required to search for LSC officials';
  }
  if (formState.officialPositionTypeId === Constants?.DEFAULT_ID) {
    errors.officialPositionTypeId = 'Position Worked is required to search for LSC officials';
  }

  //Expiration Dates
  const localFromExpirationDate = new Date(formState.fromExpirationDate);
  const localToExpirationDate = new Date(formState.toExpirationDate);

  if (formState.fromExpirationDate !== Constants.BLANK_DATE_STRING && !isValidDate(formState.fromExpirationDate)) {
    errors.fromExpirationDate = 'From Expiration Date must be a valid date';
  }

  if (formState.toExpirationDate !== Constants.BLANK_DATE_STRING && !isValidDate(formState.toExpirationDate)) {
    errors.toExpirationDate = 'To Expiration Date must be a valid date';
  }

  if (!errors.fromExpirationDate && !errors.toExpirationDate) {
    if (localFromExpirationDate > localToExpirationDate) {
      errors.fromExpirationDate = 'From Expiration Date must be on or before the To Expiration Date';
      errors.toExpirationDate = 'To Expiration Date must be on or after the From Expiration Date';
    }
  }
  
  return errors;
};

const OtsOfficialLscBulkCertificationValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OtsOfficialLscBulkCertificationValidation;