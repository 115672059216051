import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOfferingManagementData from '../../../../state/offeringManagement/UseOfferingManagementData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useReportPeriodSeasonData from '../../../../../common/state/reportPeriodSeason/UseReportPeriodSeasonData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import { DEFAULT_ID } from '../../../../../common/utils/Constants';

const INITIAL_STATE = {
  reportPeriodId: DEFAULT_ID,
  periodName: '',
  pageErrorMessage: ''
};

const useHqOfferingInstance = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { reportPeriodSeasonState, getReportPeriodSeason } = useReportPeriodSeasonData();
  const { offeringManagementInstanceState, getOfferingInstances } = useOfferingManagementData();
  const [state, setState] = useState(INITIAL_STATE);

  const onAddOfferingInstanceClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE_ADD?.route, { state: { reportPeriodId: state.reportPeriodId, periodName: state.periodName } });
  };

  const onEditOfferingInstanceClicked = (e, offeringInstance) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE_EDIT?.route, { state: { offeringInstance, reportPeriodId: state.reportPeriodId, periodName: state.periodName } });
  };

  const onReportPeriodChanged = (value, valueLabel) => {
    if (offeringManagementInstanceState.isArrayLoading === false) {
      setState({
        ...state,
        reportPeriodId: value,
        periodName: valueLabel
      });

      getOfferingInstancesHelper(value);
    }
  };

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoading === false) {
      if (reportPeriodSeasonState.isArrayLoaded === true && reportPeriodSeasonState.organizationId === USAS_ORGANIZATION_ID) {
        const defaultReportPeriod = reportPeriodSeasonState.options.find((x) => x.id === offeringManagementInstanceState.reportPeriodId) || reportPeriodSeasonState.currentAndFutureOptions[0];

        if (defaultReportPeriod) {
          onReportPeriodChanged(defaultReportPeriod.id, defaultReportPeriod.name);
        }
      } else if (USAS_ORGANIZATION_ID) {
        getReportPeriodSeason(USAS_ORGANIZATION_ID, false, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodSeasonState, USAS_ORGANIZATION_ID]);

  function getOfferingInstancesHelper(reportPeriodId) {
    // Note: objData is only used for POST/PUT/DELETE so if objData is loaded, refresh arrayData
    if (offeringManagementInstanceState.isArrayLoaded === false || reportPeriodId !== offeringManagementInstanceState.reportPeriodId
      || offeringManagementInstanceState.isObjLoaded === true) {
      const getOfferingInstancesPromise = getOfferingInstances(reportPeriodId);

      if (getOfferingInstancesPromise ?? false) {
        getOfferingInstancesPromise.catch((e) => {
          setState({
            ...state,
            pageErrorMessage: e?.message || 'Failed to retrieve offerings'
          });
        });
      }
    }
  }

  return {
    organizationId: USAS_ORGANIZATION_ID,
    state,
    offeringManagementInstanceState,
    onReportPeriodChanged,
    onAddOfferingInstanceClicked,
    onEditOfferingInstanceClicked,
  };
};

export default useHqOfferingInstance;