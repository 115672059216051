import React, { Fragment } from 'react';

import Checkbox from '../../../../common/components/checkboxes/Checkbox';

import { FormatMoneyWithSymbol } from '../../../../common/utils/FormatMoney';
import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRowIcons = ({ person, onCheckboxChange }) => (
  <div className={global.CheckboxMobile}>
    <Checkbox
      className={global.IconButton}
      label=""
      name="isSelected"
      onChange={(e) => { onCheckboxChange(e, person.memberRegistrationId, person.upgradeToOfferingInstanceId) }}
      checked={person.isSelected || false} />
  </div>
);

const GridRowContent = ({ person }) => (
  <div className={global.SmallTableRowInfo}>
    <div className='row'>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
        Team
      </div>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
        {person?.orgUnitName} ({person?.orgUnitCode})
      </div>
    </div>
    <div className='row'>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
        Current Mem.
      </div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
        {person?.upgradeFromOfferingName || ''}
      </div>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
        Current Price
      </div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
        {FormatMoneyWithSymbol(person.upgradeFromOfferingTotalFeeAmount)}
      </div>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
        Upgraded Mem.
      </div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
        {person?.upgradeToOfferingName || ''}
      </div>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
        Upgraded Price
      </div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
        {FormatMoneyWithSymbol(person.upgradeToOfferingTotalFeeAmount)}
      </div>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
        Upgraded Exp.
      </div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
        {formatDate(person?.expirationDate) || Constants.BLANK_DATE_STRING}
      </div>
      <div className={['col-xs-6', global.SmallTableRowLabels].join(' ')}>
        Price Diff.
      </div>
      <div className={['col-xs-6', global.SmallTableRowData].join(' ')}>
        {FormatMoneyWithSymbol(person?.priceDifference) || ''}
      </div>
    </div>
  </div>
);

const GridRow = ({ person, onCheckboxChange }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{person?.orgUnitName || ''} ({person.orgUnitCode})</span>&nbsp;
          <GridRowIcons person={person} onCheckboxChange={onCheckboxChange} />
        </div>
        <GridRowContent person={person} />
      </div>
    </Fragment>
  );
}

const SmallUpgradeGrid = ({ state, gridData, onCheckboxChange }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (<div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}> &nbsp; </div>
        <div className={global.SmallTableRowInfo}>
          {state.isArrayLoading === true
            ? <div className={global.SmallTableRowLabels}>Loading...</div>
            : <div className={global.SmallTableRowLabels}>You are not eligible for any membership upgrade at this time</div>}
        </div>
      </div>)
      : Array.isArray(gridData) === true && gridData?.length > 0
        ? gridData?.map((person, i) =>
          <GridRow
            key={i}
            person={person}
            onCheckboxChange={onCheckboxChange} />)
        : (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>You are not eligible for any membership upgrade at this time</div>
          </div>
        </div>)
    }
  </div>
);

export default SmallUpgradeGrid;