import React, { Fragment } from 'react';

import useEditHQCommitteeMember from './UseEditHQCommitteeMember';

import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import DatePicker from '../../../../../common/components/datepickers/DatePicker';
import ReadOnly from '../../../../../common/components/readOnly/ReadOnly';
import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const EditHQCommitteeMember = () => {
  const {
    isLoading,
    isSaving,
    viewState,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onBackClicked,
    representativeTypeOptions,
    termLengthOptions,
    onSaveClicked
  } = useEditHQCommitteeMember();
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Edit HQ Committee Role</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"HQ Committee Role"}
              value={viewState.orgRoleName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"Member Id"}
              value={viewState.memberId} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label={"Member Name"}
              value={viewState.memberName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label={"Effective Date*"}
              name={"effectiveDate"}
              value={formState.effectiveDate}
              error={errorState.effectiveDate}
              message={errorState.effectiveDate}
              startYearOffset={-140}
              onChange={(value) => onFormValueChanged('effectiveDate', value)} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label={"Expiration Date*"}
              name={"expirationDate"}
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              startYearOffset={-140}
              onChange={(value) => onFormValueChanged('expirationDate', value)} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label={"Representative Type*"}
              name={"representativeTypeId"}
              value={formState.representativeTypeId}
              options={representativeTypeOptions}
              error={errorState.representativeTypeId}
              message={errorState.representativeTypeId}
              isLoading={false}
              areValuesIntegers={true}
              onChange={(newValue, newName, e) => e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'representativeTypeId', newName, 'representativeTypeName')} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label={"Term Length*"}
              name={"termLengthId"}
              value={formState.termLengthId}
              options={termLengthOptions}
              error={errorState.termLengthId}
              message={errorState.termLengthId}
              isLoading={false}
              areValuesIntegers={true}
              onChange={(newValue, newName, e) => e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'termLengthId', newName, 'termLengthName')} />
          </div>
        </div>
        {errorState.multipleActiveRoles ?
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.multipleActiveRoles}</p>
            </div>
          </div> : <Fragment />
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default EditHQCommitteeMember;