import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../../common/wrappers/ReactRouterDom';

import useOfferingManagementData from '../../../../../state/offeringManagement/UseOfferingManagementData';

import useNavRoutes from '../../../../../../common/state/security/UseNavRoutes';

const INITIAL_STATE = {
  displayDeletePopup: false,
  deletePopupOfferingObj: {},
  offeringInstanceObj: {},
  reportPeriodObj: {},
  pageErrorMessage: ''
};

const useHqOfferingInstanceEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { offeringManagementInstanceState, putOfferingInstance, deleteOfferingInstance } = useOfferingManagementData();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmitOfferingInstanceForm = (newOfferingInstanceObj) => {
    const putOfferingInstancePromise = putOfferingInstance(newOfferingInstanceObj.offeringInstanceId, newOfferingInstanceObj);

    if (putOfferingInstancePromise ?? false) {
      putOfferingInstancePromise.then((newState) => {
        if (newState ?? false) {
          navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE?.route)
        }
      }).catch((e) => {
        setState({
          ...state,
          pageErrorMessage: e?.message || 'Failed to update offering'
        })
      });
    }
  };

  const onBackClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE?.route)
  };

  const onDeleteClicked = (e, formState) => {
    e?.preventDefault();

    setState({
      ...state,
      displayDeletePopup: true,
      deletePopupOfferingObj: {
        ...state.offeringInstanceObj,
        ...formState
      }
    });
  };

  const onDeletePopupConfirmClicked = (e) => {
    e?.preventDefault();

    const deleteOfferingInstancePromise = deleteOfferingInstance(state.deletePopupOfferingObj.offeringInstanceId);

    if (deleteOfferingInstancePromise ?? false) {
      deleteOfferingInstancePromise.then((newState) => {
        if (newState ?? false) {
          navigate(navRoutes.OFFERING_MANAGMENT_HQ_OFFERING_INSTANCE?.route)
        }
      }).catch((e) => {
        setState({
          ...state,
          displayDeletePopup: false,
          pageErrorMessage: e?.message || 'Failed to delete offering'
        })
      });
    }
  };

  const onDeletePopupCancelClicked = (e) => {
    e?.preventDefault();

    setState({
      ...state,
      displayDeletePopup: false,
      deletePopupOfferingObj: {}
    });
  };

  useEffect(() => {
    if (location.state?.offeringInstance && location.state?.reportPeriodId && location.state?.periodName) {
      setState({
        ...state,
        offeringInstanceObj: location.state.offeringInstance,
        reportPeriodObj: {
          reportPeriodId: location.state.reportPeriodId,
          periodName: location.state.periodName
        }
      });
    } else {
      setState({
        ...state,
        pageErrorMessage: 'Failed to load offering'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSaving: offeringManagementInstanceState.isObjLoading,
    state,
    onSubmitOfferingInstanceForm,
    onBackClicked,
    onDeleteClicked,
    onDeletePopupConfirmClicked,
    onDeletePopupCancelClicked
  };
};

export default useHqOfferingInstanceEdit;