import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ session, role, onDeleteSessionRole }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{`${session.sessionOrderId || ''} (${session.sessionType || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})`}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={(e) => onDeleteSessionRole(e, session, role)}>
        <DeleteIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Session
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {`${session.sessionOrderId || ''} (${session.sessionType || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})`}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          OQM Session?
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {session.isOfficialsQualifyingSession === true ? 'Yes' : session.isOfficialsQualifyingSession === false ? 'No' : <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const DetailGrid = ({ sessions, role, onDeleteSessionRole }) => (
  <div className={global.SmallTableDetailTable}>
    {sessions.map((session, i) => <DetailGridRow key={i} session={session} role={role} onDeleteSessionRole={onDeleteSessionRole} />)}
  </div>
);

const SmallGridRowIcons = ({ role, onEditSessionRole, expandedId, onExpandClicked }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={(e) => onExpandClicked(e, `${role.meetOfficialRoleTypeId}+${role.isApprentice}`)}>
      {expandedId === `${role.meetOfficialRoleTypeId}+${role.isApprentice}` ? <HideIcon /> : <ShowIcon />}
    </button>
    <button className={global.IconButtonMobileMargin}
      type="button"
      onClick={(e) => onEditSessionRole(e, role)}>
      <EditIcon />
    </button>
  </Fragment>
);

const GridRow = ({ role, onEditSessionRole, onDeleteSessionRole, expandedId, onExpandClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>{`${role.roleTypeName || <span>&nbsp;</span>} (${role.roleTypeAbbreviation || <span>&nbsp;</span>})`}</span>&nbsp;
        <SmallGridRowIcons role={role} onEditSessionRole={onEditSessionRole} expandedId={expandedId} onExpandClicked={onExpandClicked} />
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Role Worked
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {`${role.roleTypeName || <span>&nbsp;</span>} (${role.roleTypeAbbreviation || <span>&nbsp;</span>})`}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Apprentice?
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {role.isApprentice === true ? 'Apprentice' : 'No'}
          </div>
        </div>
      </div>
    </div>
    {Array.isArray(role.session) && role.session.length > 0 && expandedId === `${role.meetOfficialRoleTypeId}+${role.isApprentice}`
      && <DetailGrid sessions={role.session} role={role} onDeleteSessionRole={onDeleteSessionRole} />
    }
  </Fragment>
);

const MeetOfficialsOfficialGridSmall = ({ state, onEditSessionRole, onDeleteSessionRole, expandedId, onExpandClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true ? (
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          {state.isArrayLoading === true ?
            <div className={global.SmallTableRowLabels}>Loading...</div> :
            <div className={global.SmallTableRowLabels}>No Session Roles</div>
          }
        </div>
      </div>
    ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
      ? state.arrayData.map((role, i) =>
        <GridRow key={i} role={role} expandedId={expandedId} onEditSessionRole={onEditSessionRole}
          onDeleteSessionRole={onDeleteSessionRole} onExpandClicked={onExpandClicked} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Session Roles</div>
          </div>
        </div>
      )
    }
  </div>
);

export default MeetOfficialsOfficialGridSmall;