import React, { Fragment } from 'react';

import MemberContactsGridLarge from './MemberContactsGridLarge';
import MemberContactsGridSmall from './MemberContactsGridSmall';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const MemberContactsGrid = ({ contacts, isLoading, onEditPerson, onDeletePerson,
  onAddEditPhone, onDeletePhone, onAddEditAddress, onDeleteAddress }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <MemberContactsGridLarge
        contacts={contacts}
        isLoading={isLoading}
        expandedId={expandedId}
        onExpandClicked={onClick}
        onEditPerson={onEditPerson}
        onDeletePerson={onDeletePerson}
        onAddEditPhone={onAddEditPhone}
        onDeletePhone={onDeletePhone}
        onAddEditAddress={onAddEditAddress}
        onDeleteAddress={onDeleteAddress} />
      <MemberContactsGridSmall
        contacts={contacts}
        isLoading={isLoading}
        expandedId={expandedId}
        onExpandClicked={onClick}
        onEditPerson={onEditPerson}
        onDeletePerson={onDeletePerson}
        onAddEditPhone={onAddEditPhone}
        onDeletePhone={onDeletePhone}
        onAddEditAddress={onAddEditAddress}
        onDeleteAddress={onDeleteAddress} />
    </Fragment>
  )
};

export default MemberContactsGrid;