import useApprovedClubCombobox from './UseApprovedClubCombobox';

import Combobox from '../Combobox';

import Constants from '../../../utils/Constants';

import global from '../../GlobalStyle.module.css';

const ApprovedClubCombobox = ({ label, name, valueToMatch, error, message, onChange, lscId }) => {
  const { approvedClubsState } = useApprovedClubCombobox(lscId !== Constants.DEFAULT_ID && lscId !== '' && lscId !== undefined ? lscId : '');

  return approvedClubsState.message
    ? <span className={global.LoadingMsg}>{approvedClubsState.message}</span>
    : (
      <Combobox
        label={label}
        name={name}
        isLoading={approvedClubsState.isArrayLoading}
        items={approvedClubsState.items}
        valueToMatch={valueToMatch}
        error={error}
        message={message}
        onChange={onChange} />
    );
};

export default ApprovedClubCombobox;