import { useContext } from 'react';

import { SearchOtsOfficialStateContext } from './SearchOtsOfficialContextProvider';
import { SearchOtsOfficialFilterStateContext } from './SearchOtsOfficialFilterContextProvider'

import SearchOtsOfficialData from './SearchOtsOfficialData';

const useSearchOtsOfficialData = () => {
  const [searchOtsOfficialState, setSearchOtsOfficialState] = useContext(SearchOtsOfficialStateContext);
  const [searchOtsOfficialFilterState, setSearchOtsOfficialFilterState] = useContext(SearchOtsOfficialFilterStateContext);

  const searchForOfficial = (filterObject, sortBy) => {
    setSearchOtsOfficialFilterState({ ...searchOtsOfficialFilterState, filterObject, sortBy });
    //clubOrgUnitName is not used by the API as a filter.  Don't send it
    SearchOtsOfficialData.searchForOfficial(JSON.stringify({...filterObject, clubOrgUnitName: undefined}), sortBy, searchOtsOfficialState, setSearchOtsOfficialState);
  };

  const clearSearchOtsOfficialObjData = () => {
    setSearchOtsOfficialState({
      ...setSearchOtsOfficialState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearSearchOtsOfficialArrayData = () => {
    setSearchOtsOfficialState({
      ...setSearchOtsOfficialState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const resetSearchOtsOfficialFilterState = () => {
    setSearchOtsOfficialFilterState(SearchOtsOfficialData.INITIAL_STATE);
  }

  return {
    searchOtsOfficialState,
    setSearchOtsOfficialState,
    searchForOfficial,
    searchOtsOfficialFilterState,
    setSearchOtsOfficialFilterState,
    clearSearchOtsOfficialObjData,
    clearSearchOtsOfficialArrayData,
    resetSearchOtsOfficialFilterState
  };
};

export default useSearchOtsOfficialData;