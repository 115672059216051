import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const getCurrentCertificationsForUser = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/positioncertificationtypeprogression/person/${personIdForUrl}`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const getAvailableCertificationsForUser = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/positioncertificationtypeprogression/person/${personIdForUrl}/nationalapplication`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const getOpenCertificationApplicationsForPerson = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/positioncertificationtypeprogression/person/${personIdForUrl}/open`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const getCertificationApplicationsHistoryForPerson = (personId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const url = `/positioncertificationtypeprogression/person/${personIdForUrl}/history`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const getCertificationApplicationsForPersonByPersonPositionCertificationRequestId = (personId, personPositionCertificationRequestId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const personPositionCertificationRequestIdForUrl = personPositionCertificationRequestId ? encodeURIComponent(personPositionCertificationRequestId) : 0;
  const url = `/positioncertificationtypeprogression/person/${personIdForUrl}/personPositionCertificationRequest/${personPositionCertificationRequestIdForUrl}/admin`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const putProgressionStatus = (personId, personPositionCertificationRequestId, progressionStatusObj, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
  const personPositionCertificationRequestIdForUrl = personPositionCertificationRequestId ? encodeURIComponent(personPositionCertificationRequestId) : 0;
  const url = `/personpositioncertificationprogression/person/${personIdForUrl}/personPositionCertificationRequest/${personPositionCertificationRequestIdForUrl}`;

  return api?.executeArray ? api.executeArray(url, 'PUT', progressionStatusObj) : null;
};

const getPersonPositionCertificationProgression = (personPositionCertificationProgressionId, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const url = `/personpositioncertificationprogression/${personPositionCertificationProgressionId}`;
  return api?.executeObject ? api.executeObject(url, 'GET') : null;
};

const getOfficialsWithActiveNationalApplication = (state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const url = '/personpositioncertificationprogression/activenationalapplications';
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const postNewApplications = (personId, positionCertificationProgressionIds, state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const url = `/personPositionCertificationProgression/person/${personId}/nationalapplication`;
  return api?.executeObject ? api.executeObject(url, 'POST', positionCertificationProgressionIds) : null;
};

// const postNewObservations = (personPositionCertificationId, requestedOtsSessions, state, setState) => {
//   const api = initApi(API_NAMES.PERSON, state, setState);
//   const url = '/otscertificationapplication/observation';
//   return api?.executeArray ? api.executeArray(url, 'POST', {personPositionCertificationId, requestedOtsSessions}) : null;
// };

const OtsCertificationApplicationData = {
  INITIAL_STATE,
  getCurrentCertificationsForUser,
  getAvailableCertificationsForUser,
  getOpenCertificationApplicationsForPerson,
  getCertificationApplicationsHistoryForPerson,
  getCertificationApplicationsForPersonByPersonPositionCertificationRequestId,
  putProgressionStatus,
  getPersonPositionCertificationProgression,
  getOfficialsWithActiveNationalApplication,
  postNewApplications,
  //postNewObservations
}

export default OtsCertificationApplicationData;