import { Fragment } from 'react';

import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';

import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ roles }) => (
  <tr>
    <td>{roles.goodStandingName || ''}</td>
  </tr>
);

const GridRowDetailTable = ({ course }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Good Standing Types</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(course.goodStandingType) && course.goodStandingType.map((roles, i) => <DetailTableRow key={i} roles={roles} />)}
    </tbody>
  </table>
);

const GridRow = ({ course, formState, expandedId, onClick }) => {
  const courseGoodStandingRole = Array.isArray(course.goodStandingType)
    ? course.goodStandingType.find(x => x.goodStandingName === formState.goodStandingName)
    : null;

  return (
    formState.goodStandingName === courseGoodStandingRole?.goodStandingName
      ? <Fragment>
        <tr>
          <td>{course.courseName} {course.courseCode ? `(${course.courseCode})` : ''}</td>
          <td>{course.courseCompletionDate !== null
            ? formatDate(course.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
              ? ' '
              : formatDate(course.courseCompletionDate)
            : ' '}</td>
          <td>
            {course.courseExpirationDate !== null
              ? formatDate(course.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
                ? `Doesn't Expire`
                : formatDate(course.courseExpirationDate)
              : ' '
            }
          </td>
          <td>
            {course.status}
          </td>
          <td>
            <button
              className={global.IconButton}
              type="button"
              onClick={(e) => onClick(e, course.lmsCourseId)}>
              {expandedId === course.lmsCourseId ? <HideIcon /> : <ShowIcon />}
            </button>
          </td>
        </tr>
        {expandedId === course.lmsCourseId &&
          <tr className={global.Expanded}>
            {Array.isArray(course.orgRoles) && course.orgRoles.length > 0
              && <td colSpan="5">
                <GridRowDetailTable course={course} />
              </td>
            }
          </tr>}
      </Fragment>
      : <Fragment />
  );
};

const MemberRequirementsLargeReadGrid = ({ gridData, isLoading, formState, expandedId, onClick }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Requirement</th>
        <th>Date Completed</th>
        <th>Expiration Date</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((course, i) =>
            <GridRow
              key={i}
              course={course}
              formState={formState}
              expandedId={expandedId}
              onClick={onClick} />
          )
          : <tr><td colSpan="5">No Requirements</td></tr>
      }
    </tbody>
  </table>
);

export default MemberRequirementsLargeReadGrid;