import { Fragment } from 'react';

import useProfilePhotoManagementDetail from './UseProfilePhotoManagementDetail';

import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const ProfilePhotoManagementDetail = () => {
  const {
    personProfileState,
    personProfilePhotoUploadState,
    uiState,
    onUploadPhotoClicked,
    onCancelClicked
  } = useProfilePhotoManagementDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Add Photo</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H6>Profile Photo</Headings.H6>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <label htmlFor="emergency-plan-file-upload" className={global.FileUpload}>
            Upload File (*.jpg)
          </label>
          <input hidden id="emergency-plan-file-upload" type="file" onChange={onUploadPhotoClicked} />
          &nbsp;
          <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
        </div>
      </div>
      {uiState.error.length > 0 &&
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{uiState.error.length > 0 ? uiState.error : ''}</p>
          </div>
        </div>}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personProfileState.isSaving || personProfilePhotoUploadState.isSaving} />
    </Fragment>
  );
};

export default ProfilePhotoManagementDetail;