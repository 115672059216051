import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const getAvailableOfficialPositionTypes = (state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const url = `/officialpositiontype/current`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const OtsOfficialPositionTypeData = {
  INITIAL_STATE, 
  getAvailableOfficialPositionTypes
}

export default OtsOfficialPositionTypeData;