import { useEffect, useState } from 'react';
import { useLocation } from '../../../common/wrappers/ReactRouterDom';

import useBaseMemberData from '../../state/member/baseMember/UseBaseMemberData';

import useBasePersonData from '../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../common/state/security/UseSecurityData';

const INITIAL_VIEW_STATE = {
  memberName: '',
  memberId: '',
  roles: [],
  isIneligible: false,
  ineligibleReasonDescription: ''
};

const useMemberHeader = () => {
  const location = useLocation();
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const { baseMemberState, getBaseMember } = useBaseMemberData();
  const { basePersonState } = useBasePersonData();
  const { securityState } = useSecurityData();
  const { navRoutes } = useNavRoutes();

  const getShowMergeButton = () => {
    // The permission lines may be overkill to have all three for merge -TL
    return location.pathname === navRoutes.MEMBER_INFO?.route
      && navRoutes.MEMBER_INFO?.permission === 'edit'
      && navRoutes.MEMBER_MERGE_ROOT?.permission === 'edit'
      && navRoutes.MEMBER_MERGE_SEARCH?.permission === 'edit'
      && navRoutes.MEMBER_MERGE_SELECTION?.permission === 'edit'
      && basePersonState.objData?.memberId
      && basePersonState.objData?.memberId !== securityState.userInfo?.memberId;
  };

  const personasWithoutParent = (arrayData) => {
    if (Array.isArray(arrayData) === false) {
      return [];
    }
    return arrayData.filter(p => p.personaName !== 'Parent');
  };

  const formatIneligibleReasonDescription = () => {
    if (basePersonState.objData?.usasPerson && basePersonState.objData?.usasPerson?.personIneligibleReason.length > 0) {
      const today = new Date();
      const currentReasons = basePersonState.objData?.usasPerson?.personIneligibleReason.filter(p => (new Date(p.effectiveDate) <= today) && (new Date(p.expirationDate) >= today));
      return currentReasons.map(p => p.ineligibleReason?.reasonDescription).join(', ');
    }
    else {
      return '';
    }
  }

  useEffect(() => {
    if (basePersonState.isObjLoaded === true) {
      getBaseMember(basePersonState.objData?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  useEffect(() => {
    if (basePersonState.isObjLoaded === true && baseMemberState.isObjLoaded === true) {
      const memberNameArray = [];
      memberNameArray.push(basePersonState.objData.firstName);
      if (basePersonState.objData.preferredName !== '' && basePersonState.objData.preferredName !== basePersonState.objData.firstName) {
        memberNameArray.push(`"${basePersonState.objData.preferredName}"`);
      }
      if (basePersonState.objData.middleName) {
        memberNameArray.push(basePersonState.objData.middleName);
      }
      memberNameArray.push(basePersonState.objData.lastName)
      if (basePersonState.objData.maidenName) {
        memberNameArray.push(`(${basePersonState.objData.maidenName})`);
      }
      if (basePersonState.objData.suffix) {
        memberNameArray.push(basePersonState.objData.suffix);
      }
      const goodStandingPersonas = personasWithoutParent(baseMemberState.objData.personas);
      const ineligibleReasonDescription = formatIneligibleReasonDescription();
      setState({
        ...state,
        memberName: memberNameArray.join(' '),
        memberId: basePersonState.objData.memberId,
        roles: goodStandingPersonas || [],
        isIneligible: ineligibleReasonDescription !== '' ? true : false,
        ineligibleReasonDescription: ineligibleReasonDescription
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState, baseMemberState]);

  return {
    ...state,
    showMergeButton: getShowMergeButton()
  };
};

export default useMemberHeader;