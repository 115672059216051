import { useContext } from 'react';

import { ZoneStateContext } from './ZoneContextProvider';

import ZoneData from './ZoneData';

const useZoneData = () => {
  const [zoneState, setZoneState] = useContext(ZoneStateContext);
  const getZones = () => { ZoneData.getZones(zoneState, setZoneState); }

  return {
    zoneState,
    getZones
  };
};

export default useZoneData;