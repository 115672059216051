import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

import postPersonFilesBirthCertificateData from './PostPersonFilesBirthCertificateData';

const BASE_UPLOAD_URL = 'https://memberdocs.blob.core.windows.net/birth-certificates/';
const GET_MEMBER_FILES_BIRTH_CERTIFICATE_PATH = '/PersonFiles/birthCertificate?fileUrl=';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonFilesBirthCertificateData = {
  BASE_UPLOAD_URL,
  GET_MEMBER_FILES_BIRTH_CERTIFICATE_PATH,
  INITIAL_STATE,
  postPersonFilesBirthCertificateData
};

export default PersonFilesBirthCertificateData;