import useCurrentCerts from './UseCurrentCerts';

import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import LoadingModal from '../../../../../common/components/dialogs/LoadingModal';
import Headings from '../../../../../common/components/headings/Headings';
import Grid from './Grid';

import global from '../../../../../common/components/GlobalStyle.module.css';

const CurrentCerts = ({ canApply, buttonClickHandlers }) => {
  const { isLoading, gridData, error } = useCurrentCerts();

  return (
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12 col-md-6">
        <Headings.H3>Current Certifications</Headings.H3>
      </div>
      <div className="col-xs-12 col-md-6">
        {canApply === true && (
          <ActionIntraPageButton className="pull-right" onClick={buttonClickHandlers.onAddClicked}>
            Apply for National Certification
          </ActionIntraPageButton>
        )}
      </div>
      <div className="col-xs-12">
        <p>
          These are the certifications that you currently hold, or are in the application process.  
          {canApply 
            ? <> You may apply for certification/recertification/reinstatement by clicking on the button above.</>
            : <> You are not eligible to initiate a new National Application process at this time.</>}
        </p>
      </div>
      <div className="col-xs-12">
        {isLoading === true && <LoadingModal />}
        {!isLoading && error !== 'Loading...' && <p className={global.ErrorMessage}>{error}</p>}
        {!isLoading && <Grid gridData={gridData} onStatusClicked={buttonClickHandlers.onStatusClicked} />}
      </div>
    </div>
  );
};

export default CurrentCerts;