import React, { Fragment } from 'react';

import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const LargeCancelMembershipGrid = ({ state, onOpenCancelModalClicked }) => (
	<Fragment>
		<table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
			<thead>
				<tr>
					<th>Team</th>
					<th>Member Role</th>
					<th>Effective Date</th>
					<th>Expiration Date</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{state.isArrayLoaded !== true
					? (
						<tr>
							{state.isArrayLoading === true
								? <td colSpan="5">Loading...</td>
								: <td colSpan="5">No Results</td>}
						</tr>)
					: Array.isArray(state.arrayData) === true && state.arrayData.length > 0
						? state.arrayData.map((member, i) => (
							<tr key={i}>
								<td>{member?.orgUnitName}({member?.orgUnitCode})</td>
								<td>{member?.offeringName || ''}</td>
								<td>{formatDate(member?.effectiveDate) || Constants.BLANK_DATE_STRING}</td>
								<td>{formatDate(member?.expirationDate) || Constants.BLANK_DATE_STRING}</td>
								<td><button className={global.AnchorButton} onClick={(e) => onOpenCancelModalClicked(e, member)}><span>Cancel Membership</span></button></td>
							</tr>))
						: (
							<tr>
								<td colSpan="5">No Results</td>
							</tr>
						)
				}
			</tbody>
		</table>
	</Fragment>
);

export default LargeCancelMembershipGrid;