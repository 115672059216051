import React, { createContext, useState } from 'react';

import OtsOfficialNationalCertificationData from './OtsOfficialNationalCertificationData';

export const OtsOfficialNationalCertificationStateContext = createContext();

const OtsOfficialNationalCertificationContextProvider = ({ children }) => {
  const stateHook = useState(OtsOfficialNationalCertificationData.INITIAL_STATE);

  return (
    <OtsOfficialNationalCertificationStateContext.Provider value={stateHook}>
      {children}
    </OtsOfficialNationalCertificationStateContext.Provider>
  );
};

export default OtsOfficialNationalCertificationContextProvider;