import useCertificationReview from './UseCertificationReview';

import PersonApplications from '../personApplications/PersonApplications';

import LeftArrowIcon from '../../../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../../../common/components/PageNotFound';
import Headings from '../../../../../common/components/headings/Headings';

import global from '../../../../../common/components/GlobalStyle.module.css';

const CertificationReviewOfficial = () => {
  const {
    officialName,
    memberId,
    personId,
    personPositionCertificationRequestId,
    buttonClickHandlers } = useCertificationReview();

  return (
    <>
      {officialName && memberId &&
        <>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <button className={global.HeaderButton}>
                <Headings.H3
                  className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
                  excludeBar={true}>
                  Review National Certification Applications
                </Headings.H3>
              </button>
            </div>
            <div className="col-xs-12">
              <hr className="usas-bar-turmeric" />
            </div>
          </div>
        </>}
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={buttonClickHandlers.onGoToOfficialsWithActiveApplicationsClicked}>
            <LeftArrowIcon />  Back to Officials with Active Applications
          </button>
        </div>
      </div>
      {officialName && memberId && (
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <p className={global.HeaderText}>
              <strong>Official Name:</strong>&nbsp;{officialName}
            </p>
          </div>
          <div className="col-xs-12 col-sm-6">
            <p className={[global.HeaderText, 'pull-right'].join(' ')}>
              <strong>Member ID:</strong>&nbsp;{memberId}
            </p>
          </div>
        </div>)}
      {officialName && memberId &&
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PersonApplications personId={personId} personPositionCertificationRequestId={personPositionCertificationRequestId} buttonClickHandlers={buttonClickHandlers} />
          </div>
        </div>}
      {!officialName && !memberId &&
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>}
    </>
  );
}

export default CertificationReviewOfficial;