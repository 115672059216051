import { useEffect } from 'react';

import useOfficialPositionCertificationTypeProgressionData from '../../../state/officialPositionCertificationTypeProgression/UseOfficialPositionCertificationTypeProgressionData';

const useOfficialPositionCertificationTypeProgressionDropdown = (personId, personPositionCertificationRequestId) => {
  const { officialPositionCertificationTypeProgressionState, getOfficialPositionCertificationTypeProgressions } = useOfficialPositionCertificationTypeProgressionData();

  useEffect(() => {
    if (officialPositionCertificationTypeProgressionState.isArrayLoading !== true && (officialPositionCertificationTypeProgressionState.isArrayLoaded !== true ||
      personId !== officialPositionCertificationTypeProgressionState.personId ||
      personPositionCertificationRequestId !== officialPositionCertificationTypeProgressionState.personPositionCertificationRequestId)) {
      getOfficialPositionCertificationTypeProgressions(personId, personPositionCertificationRequestId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officialPositionCertificationTypeProgressionState, personId, personPositionCertificationRequestId]);

  return {
    officialPositionCertificationTypeProgressionState
  };
};

export default useOfficialPositionCertificationTypeProgressionDropdown;