import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getOtsOfficialLscCertificationsByPersonId from './GetOtsOfficialLscCertificationsByPersonIdData';
import putOtsOfficialLscCertificationRecertify from './PutOtsOfficialLscCertificationRecertifyData';


const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const OtsOfficialLscData = {
  INITIAL_STATE: { ...INITIAL_STATE },
  getOtsOfficialLscCertificationsByPersonId,
  putOtsOfficialLscCertificationRecertify
};

export default OtsOfficialLscData;