import useOfficialPositionCertificationTypeProgressionDropdown from './UseOfficialPositionCertificationTypeProgressionDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const OfficialPositionCertificationTypeProgressionDropdown = ({ label, name, value, error, message, onChange, personId, personPositionCertificationRequestId }) => {
  const { officialPositionCertificationTypeProgressionState } = useOfficialPositionCertificationTypeProgressionDropdown(personId, personPositionCertificationRequestId);

  return officialPositionCertificationTypeProgressionState.message
    ? <span className={global.LoadingMsg}>{officialPositionCertificationTypeProgressionState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={officialPositionCertificationTypeProgressionState.options}
        name={name}
        value={value}
        onChange={onChange}
        areValuesIntegers={true}
        isLoading={officialPositionCertificationTypeProgressionState.isArrayLoading} />
    );
};

export default OfficialPositionCertificationTypeProgressionDropdown;