import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../../../common/wrappers/ReactRouterDom';

import { ADD_VIEW_TYPE_ADDITIONAL_OFFERING, ADD_VIEW_TYPE_NEW_OFFERING } from '../../../utils/OfferingManagementConstants';

import useOfferingManagementInstanceOrgUnitData from '../../../../../state/offeringManagementInstanceOrgUnit/UseOfferingManagementInstanceOrgUnitData';

import useNavRoutes from '../../../../../../common/state/security/UseNavRoutes';

const INITIAL_STATE = {
  offeringInstanceObj: {},
  reportPeriodObj: {},
  orgUnitObj: {},
  viewType: null,
  pageErrorMessage: ''
};

const useHqLscOfferingInstanceOrgUnitAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { offeringManagementInstanceOrgUnitState, postOfferingInstanceOrgUnit } = useOfferingManagementInstanceOrgUnitData();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmitOfferingInstanceOrgUnitForm = (newOfferingInstanceOrgUnitObj) => {
    const postOfferingInstanceOrgUnitPromise = postOfferingInstanceOrgUnit(newOfferingInstanceOrgUnitObj);

    if (postOfferingInstanceOrgUnitPromise ?? false) {
      postOfferingInstanceOrgUnitPromise.then((newState) => {
        if (newState ?? false) {
          navigate(navRoutes.OFFERING_MANAGMENT_HQ_LSC_OFFERINGS?.route)
        }
      }).catch((e) => {
        setState({
          ...state,
          pageErrorMessage: e?.message || 'Failed to add offering'
        })
      });
    }
  };

  const onBackClicked = (e) => {
    e?.preventDefault();

    navigate(navRoutes.OFFERING_MANAGMENT_HQ_LSC_OFFERINGS?.route)
  }

  useEffect(() => {
    if (location.state?.orgUnitId && location.state?.orgUnitName
      && location.state?.reportPeriod) {
      let newState = {
        ...state,
        reportPeriodObj: location.state.reportPeriod,
        orgUnitObj: {
          orgUnitId: location.state.orgUnitId,
          orgUnitName: location.state.orgUnitName
        }
      };

      if (location.state?.offeringInstance) {
        setState({
          ...newState,
          offeringInstanceObj: location.state.offeringInstance,
          viewType: ADD_VIEW_TYPE_ADDITIONAL_OFFERING
        });
      } else {
        setState({
          ...newState,
          viewType: ADD_VIEW_TYPE_NEW_OFFERING
        });
      }
    } else {
      setState({
        ...state,
        pageErrorMessage: 'Failed to load report period and LSC'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSaving: offeringManagementInstanceOrgUnitState.isObjLoading,
    state,
    onSubmitOfferingInstanceOrgUnitForm,
    onBackClicked
  };
};

export default useHqLscOfferingInstanceOrgUnitAdd;