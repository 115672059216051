/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ role, orgUnitId, onEdit }) => (
  <tr>
    <td>{role.orgRoleName || ''}</td>
    <td>{role.effectiveDate ? formatDate(role.effectiveDate) : ''}</td>
    <td>{role.expirationDate ? formatDate(role.expirationDate) : ''}</td>
    <td>
      {role.personClubTransferId > 0
        ? 'Transfer Pending'
        : role.isTransferEligible === true
          ? 'Transfer Eligible'
          : 'Transfer Ineligible'
      }
    </td>
    <td>
      {role.personClubTransferId > 0
        ? (
          <button className={global.AnchorButton} onClick={(e) => onEdit(e, role.personOrgRoleDurationId, orgUnitId)}>
            <span>Review Transfer</span>
          </button>
        )
        : role.isTransferEligible === true
          ? (
            <button className={global.AnchorButton} onClick={(e) => onEdit(e, role.personOrgRoleDurationId, orgUnitId)}>
              <span>Initiate Transfer</span>
            </button>
          )
          : <Fragment />
      }
    </td>
  </tr>
);

const GridRowDetailTable = ({ personRoles, orgUnitId, onEdit }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Member Role</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th>Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {personRoles.map((role, i) => <DetailTableRow key={i} role={role} orgUnitId={orgUnitId} onEdit={onEdit} />)}
    </tbody>
  </table>
);

const GridRow = ({ club, expandedId, onEdit, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td colSpan='4'>{club.orgUnitName || ''}</td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, club.orgUnitId)}>
          {expandedId === club.orgUnitId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {expandedId === club.orgUnitId &&
      <tr className={global.Expanded}>
        {Array.isArray(club.personRoles) && club.personRoles.length > 0
          ? <td colSpan="5"><GridRowDetailTable personRoles={club.personRoles} orgUnitId={club.orgUnitId} onEdit={onEdit} /></td>
          : (
            <td colSpan="5">
              <table className={global.DetailTable}>
                <thead>
                  <tr><th>No Roles</th></tr>
                </thead>
              </table>
            </td>
          )
        }
      </tr>
    }
  </Fragment>
);

const MemberClubTransferMemberGridLarge = ({ gridData, expandedId, isLoading, onEdit, onExpandClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th colSpan='4'>Club</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((club, i) => <GridRow key={i} club={club} expandedId={expandedId} onEdit={onEdit} onExpandClicked={onExpandClicked} />)
          : <tr><td colSpan="5">No Results</td></tr>
      }
    </tbody>
  </table>
);

export default MemberClubTransferMemberGridLarge;
