import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getFoundationBoardDirectors from './GetFoundationBoardDirectorsData';
import postFoundationBoardDirector from '../../orgUnitStaff/PostOrgUnitStaffData';
import putFoundationBoardDirector from '../../orgUnitStaff/PutOrgUnitStaffData';
import deleteFoundationBoardDirector from '../../orgUnitStaff/DeletePersonOrgRoleDurationData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberAdministrationFoundationBoardData = {
  INITIAL_STATE,
  getFoundationBoardDirectors,
  postFoundationBoardDirector,
  putFoundationBoardDirector,
  deleteFoundationBoardDirector
};

export default MemberAdministrationFoundationBoardData;