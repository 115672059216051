import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './MyOtsMeetsValidation';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useSearchOtsMeetData from '../../../../state/searchOfficialsTrackingSystemMeet/UseSearchOtsMeetData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';
import useSecurityData from '../../../../../common/state/security/UseSecurityData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const TAXONOMIES = ['OTSMeetDetail'];
const SCOPE = 'OTSMeet';

const sortByOptions = [
  { id: 1, name: "Meet Date (Most Recent to Oldest)", value: "MeetStartDate desc" },
  { id: 2, name: "Meet Name (A-Z)", value: 'MeetName' }];

const INITIAL_FORM_STATE = {
  startDate: Constants.BLANK_DATE_STRING,
  endDate: Constants.BLANK_DATE_STRING,
  sortById: sortByOptions[0].id,
  sortByName: sortByOptions[0].name
};

const useMyOtsMeets = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { otsMeetState, otsMeetHeaderState, setOtsMeetHeaderState, otsMeetFiltersState, getOtsMeetMeetById, postOtsMeet, searchOtsMeets,
    clearOtsMeetObjData, clearOtsMeetHeaderObjData, clearOtsMeetArrayData } = useOtsMeetData();
  const { searchOtsMeetState, setSearchOtsMeetState } = useSearchOtsMeetData();
  const { getContextSecurity, contextSecurityState, resetContextSecurityState } = useSecurityData();
  const { navRoutes, isReadyToRoute } = useNavRoutes();
  const { USAS_ORGANIZATION_ID } = useEnvironmentVariableData();
  const { formState, errorState, handleSubmit, setFormState, setErrors, onFormValueChanged, onValueTextPairChanged, setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [state, setState] = useState({ tryGet: false, tryRedirect: false });
  const [tryPreviousSearch, setTryPreviousSearch] = useState(true);

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  };

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormState(INITIAL_FORM_STATE);
    setErrors({});
    setIsDirty(true);
    handleSubmit();
  };

  const onPostOtsMeet = (e, meet) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const otsMeetObj = {
      meetId: meet.meetId,
      isOfficialsQualifyingMeet: null,
      oqmNumber: null
    }
    postOtsMeet(otsMeetObj);
    setState({ ...state, tryGet: true });
  };

  const onEditOtsMeet = (e, meet) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (meet?.meetId) {
      getOtsMeetMeetById(meet?.meetId);
      setState({ ...state, tryRedirect: true });
    }
  };

  useEffect(() => {
    //Clear out any prior data
    clearOtsMeetObjData();
    clearOtsMeetHeaderObjData();
    clearOtsMeetArrayData();
    resetContextSecurityState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Object.keys(otsMeetFiltersState.filterObject).length > 0 && tryPreviousSearch === true &&
      location.state && location.state?.keepSearchData === true) {
      const oldSearchValues = JSON.parse(JSON.stringify(otsMeetFiltersState.filterObject));

      let sortByIndex = undefined;
      for (let i = 0; i < sortByOptions.length; i++) {
        if (otsMeetFiltersState.sortBy === sortByOptions[i].value) {
          sortByIndex = i;
          break;
        }
      }

      setIsDirty(true);
      setTryPreviousSearch(false);
      setFormState({
        ...formState,
        ...oldSearchValues,
        sortById: sortByIndex !== undefined ? sortByOptions[sortByIndex].id : sortByOptions[0].id,
        sortByName: sortByIndex !== undefined ? sortByOptions[sortByIndex].name : sortByOptions[0].name
      });

      if (otsMeetState.isArrayLoading === false) {
        searchOtsMeets(otsMeetFiltersState.filterObject, otsMeetFiltersState.sortBy || '');
      }
    }
    else {
      //handle initial page load when navigating to page for the first time
      setIsDirty(true);
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.tryGet === true && otsMeetState.isSaved === true) {
      if (otsMeetState.objData?.meetId) {
        getOtsMeetMeetById(otsMeetState.objData?.meetId);
        setState({ ...state, tryGet: false, tryRedirect: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryGet, otsMeetState]);

  useEffect(() => {
    if (isReadyToRoute === true && state.tryRedirect === true && otsMeetHeaderState.isObjLoaded === true) {
      setOtsMeetHeaderState({ ...otsMeetHeaderState, myOtsMeets: true, myLscOtsMeetSearch: false });
      if (contextSecurityState.isObjLoaded === true && (otsMeetHeaderState.objData?.otsMeetId === contextSecurityState.contextId)
        && contextSecurityState.scope === SCOPE) {
        const infoRoute = navRoutes.OTS_MEET_INFO;
        if (infoRoute) {
          navigate(infoRoute?.route);
        }
        else {
          const sessionsRoute = navRoutes.OTS_MEET_SESSIONS;
          if (sessionsRoute) {
            navigate(sessionsRoute?.route);
          }
          else {
            const officialsRoute = navRoutes.OTS_MEET_OFFICIALS;
            if (officialsRoute) {
              navigate(officialsRoute?.route);
            }
          }
        }
      } else if (contextSecurityState.isObjLoading !== true) {
        getContextSecurity(otsMeetHeaderState.objData?.otsMeetId, TAXONOMIES, SCOPE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, contextSecurityState, otsMeetHeaderState, isReadyToRoute, navRoutes]);


  function submitFormCallback(formState) {
    setSearchOtsMeetState({ ...searchOtsMeetState, tryRedirect: false });

    let sortBy = undefined;
    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    if (otsMeetState.isArrayLoading === false) {
      const filterObject = createSearchFilterObj(formState);
      searchOtsMeets(filterObject, sortBy || '');
    }
  };

  function createSearchFilterObj(formState) {
    return {
      startDate: formState.startDate !== Constants.BLANK_DATE_STRING ? formState.startDate : undefined,
      endDate: formState.endDate !== Constants.BLANK_DATE_STRING ? formState.endDate : undefined,
      isPersonMeetReferee: true
    };
  };

  return {
    otsMeetState,
    searchOtsMeetState,
    onPostOtsMeet,
    onEditOtsMeet,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    USAS_ORGANIZATION_ID,
    onFilterClicked,
    onClearFilterClicked,
    sortByOptions,
    otsMeetHeaderState,
    contextSecurityState
  };
};

export default useMyOtsMeets;