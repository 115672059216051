import React from 'react';
import { Fragment } from 'react';

import useMemberEthnicities from './UseMemberEthnicities';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MemberEthnicitiesRead = () => {
  const {
    state,
    basePersonState,
    ethnicityTypeState,
    hispanicOrLatinoTypeState
  } = useMemberEthnicities();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Ethnicities</Headings.H3>
        </div>
      </div>
      <div className={[global.HeaderText, 'row usas-extra-top-margin usas-extra-bottom-margin'].join(' ')}>
        <div className="col-xs-6 col-md-3">
          <p><b>Hispanic or Latino:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{state.hispanicOrLatinoType || <span>&nbsp;</span>}</p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p><b>Additional Races:</b></p>
        </div>
        <div className="col-xs-6 col-md-3">
          <p>{state.additionalRaces || <span>&nbsp;</span>}</p>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || ethnicityTypeState.isArrayLoading ||
          hispanicOrLatinoTypeState.isArrayLoading} />
    </Fragment>
  );
};

export default MemberEthnicitiesRead;