import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';
import usePersonNationalTeamFileTypeData from '../../../state/personNationalTeamFileType/UsePersonNationalTeamFileTypeData';
import useNationalTeamAttachmenFilesData from '../../../state/nationalTeamAttachmentFiles/UseNationalTeamAttachmentFilesData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../common/utils/Constants';

const TITLE = 'Attachments';

const INITIAL_STATE = {
  isGridInfoSet: false
};

const INITIAL_REMOVE_ATTACHMENT_MODAL_STATE = {
  personAttachmentId: Constants.DEFAULT_ID,
  fileName: '',
  fileTypeName: '',
  displayPopUp: false,
  modalTitle: 'Remove this Attachment?'
};

const INITIAL_DELETE_ATTACHMENT_MODAL_STATE = {
  personAttachmentId: Constants.DEFAULT_ID,
  fileName: '',
  fileTypeName: '',
  displayPopUp: false,
  modalTitle: 'Delete this Attachment?'
};

const useNationalTeamAttachments = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const [state, setState] = useState(INITIAL_STATE);
  const [removeAttachmentModalState, setRemoveAttachmentModalState] = useState(INITIAL_REMOVE_ATTACHMENT_MODAL_STATE);
  const [deleteAttahmentModalState, setDeleteAttachmentModalState] = useState(INITIAL_DELETE_ATTACHMENT_MODAL_STATE)
  const { personNationalTeamFileTypeState, getPersonNationalTeamAttachmentFileTypes } = usePersonNationalTeamFileTypeData();
  const { nationalTeamAttachmentFilesState, deleteNationalTeamAttachmentFiles} = useNationalTeamAttachmenFilesData();
  const {
    personNationalTeamAttachmentState,
    deletedPersonNationalTeamAttachmentState,
    getPersonNationalTeamAttachmentsByPersonId,
    deletePersonNationalTeamAttachmentById
  } = usePersonNationalTeamData();

  const onAddAttachment = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const attachmentId = Constants?.DEFAULT_ID
    navigate(navRoutes.MEMBER_NT_ATTACHMENTS_DETAIL?.route, { state: { attachmentId, personNationalTeamAttachmentState } });
  };


  const onEditAttachment = (e, attachment) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const attachmentId = attachment?.personNationalAttachmentId
    navigate(navRoutes.MEMBER_NT_ATTACHMENTS_DETAIL?.route, { state: { attachmentId, personNationalTeamAttachmentState } });
  };


  const setGridIntoToFalse = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, isGridInfoSet: false });
  };


  const onDeletePersonNationalTeamAttachment = (e, id) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    deletePersonNationalTeamAttachmentById(id);
    setGridIntoToFalse();
    setTimeout(() => {
      setDeleteAttachmentModalState({ ...INITIAL_DELETE_ATTACHMENT_MODAL_STATE });
    }, 500)
  };


  const onOpenDeleteAttachmentModal = (e, attachment) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setDeleteAttachmentModalState({
      ...deleteAttahmentModalState,
      personAttachmentId: attachment?.personNationalAttachmentId,
      fileTypeName: attachment?.personNationalAttachmentFileTypeName,
      fileName: attachment?.attachmentName,
      attachmentDescription: attachment?.attachmentDescription,
      attachmentUrl: attachment?.attachmentUrl,
      displayPopUp: true
    });
  };

  const onDeleteAttachmentModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setDeleteAttachmentModalState({ ...INITIAL_DELETE_ATTACHMENT_MODAL_STATE });
  };


  const onRemoveAttachmentModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveAttachmentModalState({ ...INITIAL_REMOVE_ATTACHMENT_MODAL_STATE });
  };


  useEffect(() => {
    if (personNationalTeamFileTypeState?.isArrayLoaded === false && personNationalTeamFileTypeState?.isArrayLoading === false
      && Array.isArray(personNationalTeamFileTypeState?.arrayData) === true && personNationalTeamFileTypeState?.arrayData?.length === 0) {
      getPersonNationalTeamAttachmentFileTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personNationalTeamFileTypeState]);


  useEffect(() => {
    if ((personNationalTeamAttachmentState?.isArrayLoading === false && personNationalTeamAttachmentState?.isArrayLoaded === false)
      || (deletedPersonNationalTeamAttachmentState?.isSaved === true && Object?.keys(deletedPersonNationalTeamAttachmentState?.objData)?.length > 0)) {
      if (state?.isGridInfoSet === false) {
        const personId = basePersonState?.objData?.personId;
        getPersonNationalTeamAttachmentsByPersonId(personId);
        setState({ ...state, isGridInfoSet: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personNationalTeamAttachmentState, deletedPersonNationalTeamAttachmentState?.isSaved, state?.isGridInfoSet]);

  useEffect(() => {
    if (deletedPersonNationalTeamAttachmentState.isSaved === true) {
      deleteNationalTeamAttachmentFiles(deletedPersonNationalTeamAttachmentState.objData.attachmentUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedPersonNationalTeamAttachmentState]);

  return {
    title: TITLE,
    removeAttachmentModalState,
    onAddAttachment,
    onEditAttachment,
    onOpenDeleteAttachmentModal,
    onRemoveAttachmentModalCanceled,
    personNationalTeamAttachmentState,
    deleteAttahmentModalState,
    onDeleteAttachmentModalCanceled,
    onDeletePersonNationalTeamAttachment
  };
};

export default useNationalTeamAttachments;