import React, { createContext, useState } from 'react';

import SponsorData from './SponsorData';

export const SponsorStateContext = createContext();

const SponsorContextProvider = ({ children }) => {
  const stateHook = useState(SponsorData.INITIAL_STATE);

  return (
    <SponsorStateContext.Provider value={stateHook}>
      {children}
    </SponsorStateContext.Provider>
  );
};

export default SponsorContextProvider;