import React, { createContext, useState } from 'react';

import OrgRoleStaffRoleHierarchyData from './OrgRoleStaffRoleHierarchyData';

export const OrgRoleStaffRoleHierarchyStateContext = createContext();

const OrgRoleStaffRoleHierarchyContextProvider = ({ children }) => {
  const stateHook = useState(OrgRoleStaffRoleHierarchyData.INITIAL_STATE);

  return (
    <OrgRoleStaffRoleHierarchyStateContext.Provider value={stateHook}>
      {children}
    </OrgRoleStaffRoleHierarchyStateContext.Provider>
  );
};

export default OrgRoleStaffRoleHierarchyContextProvider;