import { Fragment } from 'react';

import useOfferingInstanceCouponDiscountFormGrid from './UseOfferingInstanceCouponDiscountFormGrid';

import OfferingInstanceCouponDiscountFormGridLarge from './OfferingInstanceCouponDiscountFormGridLarge';
import OfferingInstanceCouponDiscountFormGridSmall from './OfferingInstanceCouponDiscountFormGridSmall';

import global from '../../../../../../common/components/GlobalStyle.module.css';

const OfferingInstanceCouponDiscountFormGrid = ({ gridData, isLoading, onSubmitCoupon }) => {
  const {
    formGridData,
    formGridErrorMessage,
    onCouponValueChanged,
    onSaveCouponValue
  } = useOfferingInstanceCouponDiscountFormGrid(gridData, onSubmitCoupon);

  return (
    <Fragment>
      <OfferingInstanceCouponDiscountFormGridLarge
        gridData={formGridData}
        isLoading={isLoading}
        onCouponValueChanged={onCouponValueChanged}
        onSaveCouponValue={onSaveCouponValue} />
      <OfferingInstanceCouponDiscountFormGridSmall
        gridData={formGridData}
        isLoading={isLoading}
        onCouponValueChanged={onCouponValueChanged}
        onSaveCouponValue={onSaveCouponValue} />
      {formGridErrorMessage && <p className={global.ErrorMessage}>{formGridErrorMessage}</p>}
    </Fragment>
  );
};
export default OfferingInstanceCouponDiscountFormGrid;