import Constants from "../../../../common/utils/Constants";

export const localValidate = (formState) => {

  const HISPANIC_OR_LATINO_TYPE_IDS = {
    iPreferNotToSay: 3
  }
  const errors = {};
  //Hispanic/Latino Ethnicity
  if (!formState.hispanicOrLatinoTypeId || formState.hispanicOrLatinoTypeId === Constants.DEFAULT_ID) {
    errors.hispanicOrLatinoTypeId = 'Hispanic/Latino Ethnicity Designation is required';
  }
  //Race validation, if hispanic or latino selection is "I prefer not to say" or blank and ethnicity type id length is equal to 0
  if ((formState.hispanicOrLatinoTypeId !== HISPANIC_OR_LATINO_TYPE_IDS.iPreferNotToSay && formState.hispanicOrLatinoTypeId !== Constants.DEFAULT_ID) && formState.ethnicityTypeIdArray.length === 0) {
    errors.ethnicityTypeId = 'Race Designation is required';
  }

  return errors;
};

const MemberEthnicitiesValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default MemberEthnicitiesValidation;