import { Fragment } from 'react';

import useProfileSocialMediaDetail from './UseProfileSocialMediaDetail';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Headings from '../../../../common/components/headings/Headings';
import Input from '../../../../common/components/inputs/Input';

import Constants from '../../../../common/utils/Constants';

const ProfileSocialMediaDetail = () => {
  const {
    personProfileState,
    formState,
    errorState,
    socialMediaTypeState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  } = useProfileSocialMediaDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.personSocialMediaId > 0 ? 'Edit' : 'Add'} Social Media Platform</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Social Media Platform*"
              name="socialMediaTypeId"
              value={formState.socialMediaTypeId}
              error={errorState.socialMediaTypeId}
              message={errorState.socialMediaTypeId}
              isLoading={socialMediaTypeState.isLoading}
              options={socialMediaTypeState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'socialMediaTypeId', newValueLabel, 'typeName'); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Handle / Username / URL*"
              name="socialHandle"
              value={formState.socialHandle}
              error={errorState.socialHandle}
              message={errorState.socialHandle}
              onChange={(value) => { onFormValueChanged('socialHandle', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onSaveClicked(e)}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={(e) => onCancelClicked(e)}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personProfileState.isObjLoading || socialMediaTypeState.isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personProfileState.isSaving} />
    </Fragment>
  );
};

export default ProfileSocialMediaDetail;