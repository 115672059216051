import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { isPersonObjectDataCurrent } from '../utils/MemberUtils';
import Constants from '../../../../common/utils/Constants';

import usePersonNationalTeamData from '../../../state/personNationalTeam/UsePersonNationalTeamData';

import useBasePersonData from '../../../../common/state/personBase/UseBasePersonData';
import useNavRoutes from '../../../../common/state/security/UseNavRoutes';

const TITLE = 'Support Staff';

const useNationalTeamSupportStaff = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { basePersonState } = useBasePersonData();
  const { personNationalTeamState, getPersonNationalTeam, putPersonNationalTeam } = usePersonNationalTeamData();
  const [modalState, setModalState] = useState(getInitialModalState());

  const onAddEditSupportStaff = (e, nationalTeamSupportStaffId, nationalTeamSupportStaffTypeId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF_DETAIL?.route, { state: { nationalTeamSupportStaffId, nationalTeamSupportStaffTypeId } });
  }

  const onAddStaffType = (e, nationalTeamSupportStaffId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.MEMBER_NT_SUPPORT_STAFF_TYPE_DETAIL?.route, { state: { nationalTeamSupportStaffId } });
  }

  // Called by grid delete button, populates modal with target narrative info
  const onOpenModalClicked = (e, nationalTeamSupportStaffId, name) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({
      ...modalState,
      nationalTeamSupportStaffId: nationalTeamSupportStaffId,
      name: name,
      displayPopUp: true
    });
  };

  const onDeleteSupportStaff = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const personNationalTeamObj = JSON.parse(JSON.stringify(personNationalTeamState.objData));
    const index = personNationalTeamObj.supportStaffMembers.findIndex(e => e.nationalTeamSupportStaffId === modalState.nationalTeamSupportStaffId);

    if (index > Constants.DEFAULT_ID) {
      personNationalTeamObj.supportStaffMembers.splice(index, 1);
      putPersonNationalTeam(personNationalTeamState.objData.personId, personNationalTeamObj);
      setModalState({ ...getInitialModalState() });
    }
  };

  // Clears modalState and closes modal
  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...getInitialModalState() });
  };

  useEffect(() => {
    if (personNationalTeamState.isObjLoaded === false
      || !isPersonObjectDataCurrent(basePersonState.objData.personId, personNationalTeamState.objData)) {
      getPersonNationalTeam(basePersonState.objData.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePersonState]);

  // Holds initial state for delete modal
  function getInitialModalState() {
    return {
      nationalTeamSupportStaffId: Constants.DEFAULT_ID,
      name: '',
      displayPopUp: false,
      modalTitle: 'Delete this Support Staff?'
    }
  };

  return {
    title: TITLE,
    DEFAULT_ID: Constants.DEFAULT_ID,
    Constants,
    personNationalTeamState,
    modalState,
    onAddEditSupportStaff,
    onDeleteSupportStaff,
    onOpenModalClicked,
    onModalCanceled,
    onAddStaffType
  }
}

export default useNationalTeamSupportStaff;