import { useEffect, useState } from "react";

const useOrgRoleFieldDropdown = (orgRoleFieldLookup) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    const newOptions = [];

    for (const option of orgRoleFieldLookup) {
      newOptions.push({ id: option.fieldValue, name: option.fieldValue});
    }

    setState(newOptions);
  }, [orgRoleFieldLookup]);

  return {
    options: state
  };
};

export default useOrgRoleFieldDropdown;