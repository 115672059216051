import { useContext } from 'react';

import { OfficialPositionStateContext } from './OfficialPositionContextProvider';

import OfficialPositionData from './OfficialPositionData';

const useOfficialPositionData = () => {
  const [officialPositionState, setOfficialPositionState] = useContext(OfficialPositionStateContext);

  const getOfficialPositions = (positionType) => OfficialPositionData.getOfficialPositionData(positionType, officialPositionState, setOfficialPositionState);

  const clearOfficialPositionObjData = () => {
    setOfficialPositionState({
      ...officialPositionState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOfficialPositionArrayData = () => {
    setOfficialPositionState({
      ...officialPositionState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: '',
      positionType: ''
    });
  };

  const confirmOfficialPositionSave = () => {
    setOfficialPositionState({
      ...officialPositionState,
      isSaved: false
    });
  };

  return {
    officialPositionState,
    getOfficialPositions,
    clearOfficialPositionObjData,
    clearOfficialPositionArrayData,
    confirmOfficialPositionSave
  };
};

export default useOfficialPositionData;