import React, { useState, createContext } from 'react';
import EnrollmentData from './EnrollmentData';

export const CoachEnrollmentInCertificationStateContext = createContext();

const CoachEnrollmentInCertificationContextProvider = ({ children }) => {
  const stateHook = useState({...EnrollmentData.INITIAL_STATE});

  return (
    <CoachEnrollmentInCertificationStateContext.Provider value={stateHook}>
      {children}
    </CoachEnrollmentInCertificationStateContext.Provider>
  );
};

export default CoachEnrollmentInCertificationContextProvider;