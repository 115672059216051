
const localValidate = (formState) => {
  let errors = {};

  // Offering Name
  if (formState.offeringName.trim() === '') {
    errors.offeringName = 'Offering Name is required';
  } else if (formState.offeringName.trim().length > 50) {
    errors.offeringName = 'Offering Name cannot be greater than 50 characters';
  }

  // Offering Description
  if (formState.offeringDescription.trim() === '') {
    errors.offeringDescription = 'Offering Description is required';
  } else if (formState.offeringDescription.trim().length > 2000) {
    errors.offeringDescription = 'Offering Description cannot be greater than 2000 characters';
  }

  return errors;
};

const OfferingTypeClubFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OfferingTypeClubFormValidation;