import { useEffect } from "react";

import validate from './OfferingInstanceOrgUnitAddAdditionalFormValidation';

import UseForm from "../../../../../../common/utils/UseForm";
import { BLANK_DATE_STRING } from "../../../../../../common/utils/Constants";
import { formatDate } from "../../../../../../common/utils/DateFunctions";

const INITIAL_FORM_STATE = {
  registrationStartDate: BLANK_DATE_STRING,
  registrationEndDate: BLANK_DATE_STRING,
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING,
  reportPeriodStartDate: null,
  reportPeriodEndDate: null,
  offeringInstanceOrgUnitRegDates: []
};

const useOfferingInstanceOrgUnitAddAdditionalForm = (onSubmitFormCallback, reportPeriodObj, orgUnitObj, offeringInstanceObj) => {
  const { formState, errorState, setFormState, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, formatBeforeSubmit, validate);

  useEffect(() => {
    if (Array.isArray(offeringInstanceObj?.offeringInstanceOrgUnit)) {
      const lastPeriod = offeringInstanceObj.offeringInstanceOrgUnit[offeringInstanceObj.offeringInstanceOrgUnit.length - 1];

      setFormState({
        ...formState,
        effectiveDate: formatDate(lastPeriod.effectiveDate),
        expirationDate: formatDate(lastPeriod.expirationDate),
        reportPeriodStartDate: formatDate(reportPeriodObj.startDate),
        reportPeriodEndDate: formatDate(reportPeriodObj.endDate),
        offeringInstanceOrgUnitRegDates: offeringInstanceObj.offeringInstanceOrgUnit.map((x) => {
          return { startDate: formatDate(x.registrationStartDate), endDate: formatDate(x.registrationEndDate) }
        })
      });
    } else if (offeringInstanceObj) {
      setFormState({
        ...formState,
        effectiveDate: formatDate(offeringInstanceObj.effectiveDate),
        expirationDate: formatDate(offeringInstanceObj.expirationDate),
        reportPeriodStartDate: formatDate(reportPeriodObj.startDate),
        reportPeriodEndDate: formatDate(reportPeriodObj.endDate),
        offeringInstanceOrgUnitRegDates: [
          { startDate: formatDate(offeringInstanceObj.registrationStartDate), endDate: formatDate(offeringInstanceObj.registrationEndDate) }
        ]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringInstanceObj]);

  function formatBeforeSubmit(formState) {
    const submitObj = {
      registrationStartDate: formState.registrationStartDate,
      registrationEndDate: formState.registrationEndDate,
      effectiveDate: formState.effectiveDate,
      expirationDate: formState.expirationDate,

      offeringId: offeringInstanceObj.offeringId,
      offeringInstanceId: offeringInstanceObj.offeringInstanceId,
      offeringInstanceFeeId: offeringInstanceObj.offeringInstanceFeeId,
      feeAmount: offeringInstanceObj.feeAmount,
      reportPeriodId: reportPeriodObj.reportPeriodId,
      orgUnitId: orgUnitObj.orgUnitId
    };

    onSubmitFormCallback(submitObj);
  }

  return {
    formState,
    errorState,

    updateFormState,
    handleSubmit
  };
};

export default useOfferingInstanceOrgUnitAddAdditionalForm;