import React from 'react';

import useOtsOfficialReporting from './UseOtsOfficialReporting';

import ReportList from '../../../../../common/components/embeddedReports/ReportList';

const OtsOfficialReporting = () => {
  const { reportParameters, routeName } = useOtsOfficialReporting();

  return (
    <ReportList
      listTitle="Official Reports"
      routeName={routeName}
      reportParameters={reportParameters} />
  );
};

export default OtsOfficialReporting;