import React, { Fragment } from 'react';

import useMemberAdvancedInfo from './UseMemberAdvancedInfo';

import Headings from '../../../../common/components/headings/Headings';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import CompetitionCategoryRadiobuttonList from '../../../../common/components/radiobuttonlists/competitionCategoryRadiobuttonList/CompetitionCategoryRadiobuttonList';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const MemberAdvancedInfo = () => {
  const {
    Constants,
    basePersonState,
    formState,
    errorState,
    competitionGenderState,
    refreshState,
    onFormValueChanged,
    handleSubmit,
    resetForm,
    onRefreshClicked
  } = useMemberAdvancedInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Member Advanced Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Confirmed Birth Date"
              name="birthDateConfirmed"
              checked={formState.birthDateConfirmed === true}
              error={errorState.birthDateConfirmed}
              message={errorState.birthDateConfirmed}
              onChange={(value) => { onFormValueChanged('birthDateConfirmed', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Maiden Name used as Last Name"
              name="maidenNameUsedAsLastName"
              checked={formState.maidenNameUsedAsLastName === true}
              error={errorState.maidenNameUsedAsLastName}
              message={errorState.maidenNameUsedAsLastName}
              onChange={(value) => { onFormValueChanged('maidenNameUsedAsLastName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Birth Date*"
              name="birthDate"
              value={formState.birthDate}
              error={errorState.birthDate}
              message={errorState.birthDate}
              onChange={(value) => { onFormValueChanged('birthDate', value); }} />
          </div>
          <div className={"col-xs-12 col-sm-6 col-md-4"}>
            <CompetitionCategoryRadiobuttonList
              label="Competition Category*"
              name="competitionGenderTypeId"
              isVertical={false}
              value={Number(formState.competitionGenderTypeId)}
              error={errorState.competitionGenderTypeId}
              message={errorState.competitionGenderTypeId}
              onChange={(value) => { onFormValueChanged('competitionGenderTypeId', Number(value)); }}
            />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ActionIntraPageButton type="button" onClick={onRefreshClicked}>Refresh Good Standing Status</ActionIntraPageButton>
            {refreshState.isSaved && <><br/>Refresh updated {refreshState.objData.numDatesUpdated} date</>}
            {refreshState.isSaved && refreshState.objData.numDatesUpdated > 1 && <>s</>}
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={basePersonState.isObjLoading || competitionGenderState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={basePersonState.isSaving} />
    </Fragment>
  );
};

export default MemberAdvancedInfo;