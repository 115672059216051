import React, { Fragment } from 'react';

import GroupQualificationEventGridLarge from './GroupQualificationEventGridLarge';
import GroupQualificationEventGridSmall from './GroupQualificationEventGridSmall';

const GroupQualificationEventGrid = ({ data, isLoading }) => (
  <Fragment>
    <GroupQualificationEventGridLarge data={data} isLoading={isLoading} />
    <GroupQualificationEventGridSmall data={data} isLoading={isLoading} />
  </Fragment>
);

export default GroupQualificationEventGrid;