import Constants from '../../../common/utils/Constants';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetOfficialLscCertificationByOrgUnitIdData = (orgUnitId, state, setState) => {
  if (state?.isArrayLoading !== true && !state?.message) {
    const newState = {
      ...state,
      orgUnitId: undefined,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';
    const url = `/OfficialCertification/orgUnit/${orgUnitIdForUrl}`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const data = JSON.parse(JSON.stringify(arrayData));
          const blankPosition = { officialCertificationId: Constants.DEFAULT_ID };
          data.unshift(blankPosition);
          setState({
            ...newState,
            orgUnitId: orgUnitId,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options: data.map((orgUnitCertification) => { return { id: orgUnitCertification.officialCertificationId, name: orgUnitCertification?.certificationName}}),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetOfficialLscCertificationByOrgUnitIdData;