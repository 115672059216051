import { formatDate } from '../../../../../common/utils/DateFunctions';
import Constants from '../../../../../common/utils/Constants';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ course }) => (
  <tr>
    <td>{course.courseName} ({course.courseCode})</td>
    <td>{course.courseCompletionDate !== null
      ? formatDate(course.courseCompletionDate) === Constants.DEFAULT_MAX_DATE
        ? ' '
        : formatDate(course.courseCompletionDate)
      : ' '}
    </td>
    <td>
      {course.courseExpirationDate !== null
        ? formatDate(course.courseExpirationDate) === Constants.DEFAULT_MAX_DATE
          ? `Doesn't Expire`
          : formatDate(course.courseExpirationDate)
        : ' '
      }
    </td>
  </tr>
);

const MemberCoursesLargeReadGrid = ({ gridData, isLoading }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Course Name</th>
        <th>Date Completed</th>
        <th>Expiration Date</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="3">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((course, i) =>
            <GridRow
              key={i}
              course={course} />
          )
          : <tr><td colSpan="3">No Courses</td></tr>
      }
    </tbody>
  </table>
);

export default MemberCoursesLargeReadGrid;