import LSCReportingNavLinks from '../LSCReportingNavLinks';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useLSCReportingSelection = () => {
  const { environmentVariableState, LSC_REPORTS_ROLE_GROUP_ID } = useEnvironmentVariableData();

  return {
    isLoaded: environmentVariableState.isLoaded === true,
    NavLink: LSCReportingNavLinks.LSC_REPORTING_REPORTS,
    roleGroupId: LSC_REPORTS_ROLE_GROUP_ID
  };
};

export default useLSCReportingSelection;