import React, { Fragment } from 'react';

import useMemberHeader from './UseMemberHeader';

import BackToSystemLink from '../backToSystemLink.js/BackToSystemLink';
import MemberTabsAndPills from '../memberTabsAndPills/MemberTabsAndPills';
import MergeButton from '../mergeButton/MergeButton';

import ExclamationIcon from '../../../common/components/icons/ExclamationIcon';
import InGoodStandingIcon from '../../../common/components/icons/InGoodStandingIcon';
import QuestionIcon from '../../../common/components/icons/QuestionIcon';

import global from '../../../common/components/GlobalStyle.module.css';

const MemberHeader = () => {
  const { showMergeButton, memberName, memberId, roles, isIneligible, ineligibleReasonDescription } = useMemberHeader();
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <BackToSystemLink />
        </div>
        <div className={[global.HeaderText, 'col-xs-12 col-sm-8'].join(' ')}>
          <div className="usas-extra-bottom-margin"><strong>Member Name: </strong>{memberName}{isIneligible === true && <span style={{ color: '#BA0C2F' }}>&nbsp;&nbsp;<ExclamationIcon toolTipText={'Ineligible - ' + ineligibleReasonDescription} /></span>}</div>
          <div className="usas-extra-bottom-margin"><strong>Member Id: </strong>{memberId}</div>
          {roles &&
            <div className="usas-extra-bottom-margin"><strong>Roles: </strong>{roles.map((r, i) => <span key={i}>{r.personaName}&nbsp;&nbsp;{r.isInGoodStanding === false ? <span key={i} style={{ color: '#BA0C2F' }}><ExclamationIcon toolTipText={r.personaName + ' Not In Good Standing'} /></span> : r.isInGoodStanding === true ? <span style={{ color: '#b1953a' }}><InGoodStandingIcon toolTipText={r.personaName + ' In Good Standing'} /></span> : <span><QuestionIcon toolTipText={r.personaName + ' Calculating Standing'} /></span>}{i < roles.length - 1 ? ',  ' : ''}</span>)}</div>}
        </div>
        <div className="col-xs-12 col-sm-4">
          {showMergeButton === true && <MergeButton />}
        </div>
        <div className="col-xs-12">
          <MemberTabsAndPills />
        </div>
      </div>
    </Fragment>
  );
};

export default MemberHeader;