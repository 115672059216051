import { useEffect } from 'react';

import useOrgRoleHierarchyData from '../../../state/orgRoleHierarchy/UseOrgRoleHierarchyData';

const useOrgRoleTreeView = (organizationId) => {
  const { orgRoleHierarchyState, getOrgRolesHierarchyByOrganizationId } = useOrgRoleHierarchyData();

  useEffect(() => {
    if (organizationId > 0 && organizationId !== orgRoleHierarchyState.organizationId) {
      getOrgRolesHierarchyByOrganizationId(organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, orgRoleHierarchyState]);

  return {
    orgRoleHierarchyState
  };
};

export default useOrgRoleTreeView;