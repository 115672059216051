import React from 'react';

import useMergeButton from './UseMergeButton';

import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';

const MergeButton = () => {
  const {onClick} = useMergeButton();

  return (
    <ActionIntraPageButton className="pull-right" onClick={onClick}>Merge</ActionIntraPageButton>
  );
};

export default MergeButton;