import React, { Fragment } from 'react';

import EditIcon from '../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import PlusIcon from '../../../../common/components/icons/PlusIcon';
import HideIcon from '../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../common/components/icons/ShowIcon';

import { formatPhoneNumber } from '../../../../common/utils/validation';
import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const DetailGridRow = ({ role, onAddEdit }) => (
  <tr>
    <td>{role.supportStaffType?.typeName || ''}</td>
    <td>{role.effectiveDate && formatDate(role.effectiveDate) !== Constants.DEFAULT_MIN_DATE ? formatDate(role.effectiveDate) : ''}</td>
    <td>{role.expirationDate && formatDate(role.expirationDate) !== Constants.DEFAULT_MAX_DATE ? formatDate(role.expirationDate) : ''}</td>
    <td>
      <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddEdit(e, role.nationalTeamSupportStaffId, role.nationalTeamSupportStaffTypeId)}>
        <EditIcon />
      </button>
    </td>
  </tr>
);

const DetailGrid = ({ staff, onAddEdit }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Staff Role</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {staff.nationalTeamSupportStaffType.map((role, i) =>
        <DetailGridRow key={i} role={role} onAddEdit={onAddEdit} />)
      }
    </tbody>
  </table>
);

const GridRow = ({ staff, expandedId, onAddEdit, onDelete, onAddStaffType, onExpandClicked }) => (
  <Fragment>
    <tr>
      <td>{staff.firstName || ''} {staff.lastName || ''}</td>
      <td>{staff.email || ''}</td>
      <td>{staff.phoneNumber ? formatPhoneNumber(staff.phoneNumber) : ''}</td>
      <td>
        <button className={global.IconButtonMargin} type="button" onClick={(e) => onAddStaffType(e, staff.nationalTeamSupportStaffId)}>
          <PlusIcon toolTipText="Add Staff Role" />
        </button>
        <button className={global.IconButtonMargin} type="button" onClick={(e) =>
          onDelete(e, staff.nationalTeamSupportStaffId, `${staff.firstName} ${staff.lastName} (Email: ${staff.email}, Phone Number: ${formatPhoneNumber(staff.phoneNumber)})`)}>
          <DeleteIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, staff.nationalTeamSupportStaffId)}>
          {expandedId === staff.nationalTeamSupportStaffId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {Array.isArray(staff.nationalTeamSupportStaffType) && staff.nationalTeamSupportStaffType.length > 0 && expandedId === staff.nationalTeamSupportStaffId &&
      <tr className={global.Expanded}>
        <td colSpan="4">
          <DetailGrid staff={staff} onAddEdit={onAddEdit} />
        </td>
      </tr>
    }
  </Fragment>
);

const NationalTeamSupportStaffLargeGrid = ({ gridState, expandedId, onAddEdit, onDelete, onAddStaffType, onExpandClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Phone Number</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {gridState.isObjLoaded !== true
        ? (
          <tr><td colSpan="4">{gridState.isObjLoading === true ? 'Loading...' : 'No Support Staff'}</td></tr>
        ) : Array.isArray(gridState.objData.supportStaffMembers) && gridState.objData.supportStaffMembers.length > 0
          ? gridState.objData.supportStaffMembers.map((staff, i) => (
            <GridRow
              key={i}
              staff={staff}
              expandedId={expandedId}
              onAddEdit={onAddEdit}
              onDelete={onDelete}
              onAddStaffType={onAddStaffType}
              onExpandClicked={onExpandClicked}
            />)
          ) : <tr><td colSpan="4">No Support Staff</td></tr>
      }
    </tbody>
  </table>
);

export default NationalTeamSupportStaffLargeGrid;