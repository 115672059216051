import { useEffect, useState } from 'react';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';

const INITIAL_VIEW_STATE = {
  reportParameters: { otsMeetId: '' },
  routeName: ''
};

const useOtsMeetReporting = () => {
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });
  const { otsMeetHeaderState } = useOtsMeetData();

  useEffect(() => {
    if (otsMeetHeaderState.objData.otsMeetId) {
      setViewState({
        ...viewState,
        reportParameters: { otsMeetId: otsMeetHeaderState.objData.otsMeetId },
        routeName: 'OTS_MEET_REPORTING'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetHeaderState]);

  return { ...viewState };
};

export default useOtsMeetReporting;