
import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useOtsMeetData from '../../../../state/officialsTrackingSystemMeet/UseOtsMeetData';
import useOtsMeetOfficialsData from '../../../../state/officialsTrackingSystemMeetOfficials/UseOtsMeetOfficialsData';
import useOtsMeetOfficialData from '../../../../state/otsMeetOfficial/UseOtsMeetOfficialData';
import useOtsPositionWorkedEvaluationLevelEvaluatorNameData from '../../../../state/otsPositionWorkedEvaluationLevelEvaluatorName/UseOtsPositionWorkedEvaluationLevelEvaluatorNameData';
import useOfficialSelectionForEvaluationData from '../../../../state/otsOfficialSelectionForEvaluations/UseOfficialSelectionForEvaluationData';

import useNavRoutes from '../../../../../common/state/security/UseNavRoutes';

import Constants from '../../../../../common/utils/Constants';
import useForm from '../../../../../common/utils/UseForm';

const INITIAL_VIEW_STATE = {
  tryRedirect: false,
  showError: false
};

const useOtsMeetEvaluationsOverride = () => {
  const navigate = useNavigate();
  const { otsMeetHeaderState } = useOtsMeetData();
  const { clearOtsMeetOfficialsArrayData } = useOtsMeetOfficialsData();
  const { otsMeetOfficialState } = useOtsMeetOfficialData();
  const { officialSelectionForEvaluationState, postOfficialSelectionForEvaluation, confirmOfficialSelectionForEvaluationSave } = useOfficialSelectionForEvaluationData();
  const { otsPositionWorkedEvaluationLevelEvaluatorNameState } = useOtsPositionWorkedEvaluationLevelEvaluatorNameData();
  const { navRoutes } = useNavRoutes();
  const [gridState, setGridState] = useState({ arrayData: [] });
  const { formState, errorState, setErrors, handleSubmit, setIsDirty } = useForm({}, submitFormCallback, () => { });
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [isLarge, setIsLarge] = useState(window.innerWidth > 991);

  const onAddOverrideEvaluationClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
    arrayDataCopy.push({
      personId: Constants.DEFAULT_ID,
      selectedMeetOfficialId: Constants.DEFAULT_ID,
      selectedMeetOfficialName: '',
      selectedOfficialPositionTypeId: Constants.DEFAULT_ID,
      selectedOfficialPositionTypeName: '',
      selectedMeetEvaluatorId: Constants.DEFAULT_ID,
      selectedMeetEvaluatorName: '',
      selectedEvaluationLevelId: Constants.DEFAULT_ID,
      selectedEvaluationLevelName: ''
    });

    setGridState({
      ...gridState,
      arrayData: arrayDataCopy
    });
  }

  const onSaveClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setIsDirty(true);
    handleSubmit();
  }

  const onBackClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(navRoutes.OTS_MEET_EVALUATIONS_DETAIL?.route);
  };

  const onUpdateOfficialNameValueChange = (i, value, valueLabel) => {
    const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
    const selectedIndex = i;

    const selectedPersonId = otsMeetOfficialState.arrayData?.find(x => x.meetOfficialId === value)?.personId;
    arrayDataCopy[selectedIndex].personId = selectedPersonId;
    arrayDataCopy[selectedIndex].selectedMeetOfficialId = value;
    arrayDataCopy[selectedIndex].selectedMeetOfficialName = valueLabel;
    setGridState({ ...gridState, arrayData: arrayDataCopy });
  };

  const onUpdateOfficialPositionWorkedValueChange = (i, value, valueLabel) => {
    const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
    const selectedIndex = i;
    arrayDataCopy[selectedIndex].selectedOfficialPositionTypeId = value;
    arrayDataCopy[selectedIndex].selectedOfficialPositionTypeName = valueLabel;
    arrayDataCopy[selectedIndex].selectedMeetEvaluatorId = Constants.DEFAULT_ID;
    arrayDataCopy[selectedIndex].selectedMeetEvaluatorName = '';
    arrayDataCopy[selectedIndex].selectedEvaluationLevelId = Constants.DEFAULT_ID;
    arrayDataCopy[selectedIndex].selectedEvaluationLevelName = '';
    setGridState({ ...gridState, arrayData: arrayDataCopy });
  };

  const onUpdateEvaluatorNameValueChange = (e, i, value, valueLabel) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      const selectedIndex = i;
      arrayDataCopy[selectedIndex].selectedMeetEvaluatorId = value;
      arrayDataCopy[selectedIndex].selectedMeetEvaluatorName = valueLabel;
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  };

  const onUpdateEvaluationLevelValueChange = (e, i, value, valueLabel) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (e) {
      const arrayDataCopy = JSON.parse(JSON.stringify(gridState.arrayData));
      const selectedIndex = i;
      arrayDataCopy[selectedIndex].selectedEvaluationLevelId = value;
      arrayDataCopy[selectedIndex].selectedEvaluationLevelName = valueLabel;
      setGridState({ ...gridState, arrayData: arrayDataCopy });
    }
  };

  const updateMedia = () => {
    setIsLarge(window.innerWidth > 991);
  };

  useEffect(() => {
    //deal with combobox option issue by setting a state to use on a
    // ternary for the large and small selection grids
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    if (officialSelectionForEvaluationState.isArrayLoaded === true) {
      let arrayDataCopy = [{}];
      let formattedArrayData = arrayDataCopy.map((x) => {
        return (
          {
            ...x,
            personId: Constants.DEFAULT_ID,
            selectedMeetOfficialId: Constants.DEFAULT_ID,
            selectedMeetOfficialName: '',
            selectedOfficialPositionTypeId: Constants.DEFAULT_ID,
            selectedOfficialPositionTypeName: '',
            selectedMeetEvaluatorId: Constants.DEFAULT_ID,
            selectedMeetEvaluatorName: '',
            selectedEvaluationLevelId: Constants.DEFAULT_ID,
            selectedEvaluationLevelName: ''
          }
        );
      })
      setGridState({ ...gridState, arrayData: formattedArrayData })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officialSelectionForEvaluationState.isArrayLoaded])

  useEffect(() => {
    if (state.tryRedirect === true && officialSelectionForEvaluationState.isSaved === true) {
      clearOtsMeetOfficialsArrayData();
      confirmOfficialSelectionForEvaluationSave();
      navigate(navRoutes.OTS_MEET_EVALUATIONS?.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, officialSelectionForEvaluationState]);

  function submitFormCallback(formState) {
    const officialSelectionForEvaluationArray = JSON.parse(JSON.stringify(gridState.arrayData));
    const officialSelectionForEvaluationArrayFormatted = officialSelectionForEvaluationArray.map((x) => ({
      meetOfficialId: x.selectedMeetOfficialId,
      officialPositionTypeId: x.selectedOfficialPositionTypeId,
      meetEvaluatorId: x.selectedMeetEvaluatorId,
      evaluationLevelId: x.selectedEvaluationLevelId,
    }));
    let error = false;
    let evaluatorSelectionError = false;
    for (let i = 0; i < officialSelectionForEvaluationArrayFormatted.length; i++) {
      //Check to see if there are any rows where the the selections are missing
      if ((officialSelectionForEvaluationArrayFormatted[i].meetOfficialId < 0 || officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId < 0 ||
        officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId < 0 || officialSelectionForEvaluationArrayFormatted[i].evaluationLevelId < 0) && (
          officialSelectionForEvaluationArrayFormatted[i].meetOfficialId > 0 || officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId > 0 ||
          officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId > 0 || officialSelectionForEvaluationArrayFormatted[i].evaluationLevelId > 0)
      ) {
        error = true;
      }

      if (officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId > 0) {
        //Check to see if there are any rows where the official and the selected evaluator are the same
        const selectedOfficialPersonId = officialSelectionForEvaluationArray.find(x => (x.selectedMeetOfficialId === officialSelectionForEvaluationArrayFormatted[i].meetOfficialId) && (x.selectedOfficialPositionTypeId === officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId))?.personId;

        const selectedMeetEvaluatorPosition = otsPositionWorkedEvaluationLevelEvaluatorNameState.arrayData?.find(x => x.officialPositionTypeId === officialSelectionForEvaluationArrayFormatted[i].officialPositionTypeId);
        const selectedMeetEvaluatorPersonId = selectedMeetEvaluatorPosition?.evaluator?.find(x => x.meetEvaluatorId === officialSelectionForEvaluationArrayFormatted[i].meetEvaluatorId)?.personId;

        if (selectedMeetEvaluatorPersonId === selectedOfficialPersonId) {
          evaluatorSelectionError = true;
        }
      }
    }

    if (error === false && evaluatorSelectionError === false) {
      //Filter out any blank rows and POST
      const officialSelectionForEvaluationArrayFormattedFiltered = officialSelectionForEvaluationArrayFormatted.filter(x => (x.meetOfficialId >= 0 && x.officialPositionTypeId >= 0 && x.meetEvaluatorId >= 0 && x.evaluationLevelId >= 0));
      postOfficialSelectionForEvaluation(otsMeetHeaderState.objData?.otsMeetId, officialSelectionForEvaluationArrayFormattedFiltered);
      setState({ ...state, tryRedirect: true });
    }
    else {
      if (evaluatorSelectionError === true) {
        setErrors({ ...errorState, general: 'An Official cannot have themselves as an Evaluator. Please make the selected Evaluator(s) different from the listed Official(s).' })
      }
      else {
        setErrors({});
      }

      setState({ ...state, showError: error });
    }
  }

  return {
    ...state,
    gridState,
    officialSelectionForEvaluationState,
    otsPositionWorkedEvaluationLevelEvaluatorNameState,
    otsMeetHeaderState,
    isLoading: otsMeetOfficialState.isArrayLoading || otsPositionWorkedEvaluationLevelEvaluatorNameState.isArrayLoading,
    isSaving: officialSelectionForEvaluationState.isSaving,
    formState,
    errorState,
    onUpdateOfficialNameValueChange,
    onUpdateOfficialPositionWorkedValueChange,
    onUpdateEvaluatorNameValueChange,
    onUpdateEvaluationLevelValueChange,
    handleSubmit,
    Constants,
    onSaveClicked,
    onBackClicked,
    isLarge,
    onAddOverrideEvaluationClicked
  };
}

export default useOtsMeetEvaluationsOverride;