import { useState } from 'react';
import OtsCertificationRuleData from './OtsCertificationRuleData';

const useOtsCertificationRuleData = () => {
  const [ state, setState ] = useState({ ...OtsCertificationRuleData.INITIAL_STATE });

  const getOtsCertificationRulesForProgressions = (positionCertificationProgressionIds) => {
    return OtsCertificationRuleData.getOtsCertificationRulesForProgressions(positionCertificationProgressionIds, state, setState);
  }

  const getOtsCertificationRulesForPersonProgression = (personPositionCertificationProgressionId) => {
    return OtsCertificationRuleData.getOtsCertificationRulesForPersonProgression(personPositionCertificationProgressionId, state, setState);
  }

  const setPersonProgressionRule = (personPositionCertificationProgressionRule) => {
    return OtsCertificationRuleData.postPersonPositionCertificationProgressionRule(
      personPositionCertificationProgressionRule, state, setState);
  }

  return {
    getOtsCertificationRulesForProgressions,
    getOtsCertificationRulesForPersonProgression,
    setPersonProgressionRule
  };
};

export default useOtsCertificationRuleData;
