import { isValidSwimTime } from "../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};
  //Event
  if (formState.eventId <= 0) {
    errors.eventId = 'Event is required';
  }
 
  //Goal Time
  if (formState.goalTime.trim() === '') {
    errors.goalTime = 'Goal Time is required';
  } else if (!isValidSwimTime(formState.goalTimeValue)) {
    errors.goalTime = 'Goal Time must be a valid time';
  }

  return errors;
};

const MemberInfoValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MemberInfoValidation;