import { DISABILITY_TYPES, DISPLAY_VALUE_NO_INFO, DISPLAY_VALUE_UNKNOWN_GENDER, MERGE_FIELD_BIRTH_DATE, MERGE_FIELD_COMPETITION_CATEGORY, MERGE_FIELD_CONTACT_INFO, MERGE_FIELD_DISABILITY, MERGE_FIELD_IS_US_CITIZEN, MERGE_FIELD_MIDDLE_NAME, MERGE_FIELD_PERSON_ETHNICITY, MERGE_FIELD_PREFERRED_NAME } from "./MemberMergeConstants";

import { formatDate } from "../../../../common/utils/DateFunctions";
import { formatPhoneNumber } from "../../../../common/utils/validation";

export const createPersonMergeObj = (formGridState, personAData, personBData) => {
  const personBCopy = JSON.parse(JSON.stringify(personBData));
  let personSelections = JSON.parse(JSON.stringify(personAData)); // default all selections to personA

  for (const mergeFieldEntry of formGridState) {
    if (mergeFieldEntry.isPersonBSelected === true) {
      for (const mergeValueKey of mergeFieldEntry.valueKeys) {
        const keys = mergeValueKey.split('.');

        if (keys.length === 2) {
          const objectKey = keys[0];

          // if the object does not exist in personSelections(personA) but does exist in personB
          if ((personSelections[objectKey] === null || personSelections[objectKey] === undefined)
            && (personBCopy[objectKey] !== null && personBCopy[objectKey] !== undefined)) {
            // copy entire object value from personB
            personSelections[objectKey] = personBCopy[objectKey];
          } else if (personBCopy[objectKey] !== null && personBCopy[objectKey] !== undefined) {
            const valueKey = keys[1];

            personSelections[objectKey][valueKey] = personBCopy[objectKey][valueKey];
          }
        } else if (keys.length === 1) {
          const valueKey = keys[0];

          personSelections[valueKey] = personBCopy[valueKey];
        }
      }
    }
  }

  return personSelections;
};

export const getMergeFieldForFormGrid = (mergeField, personAData, personBData) => {
  const disabledValues = getMergeFieldDisabledValues(mergeField.displayValueKey, personAData, personBData);
  const personADisplayValue = getMergeFieldDisplayValue(mergeField.displayValueKey, personAData);
  const personBDisplayValue = getMergeFieldDisplayValue(mergeField.displayValueKey, personBData);

  const initialSelectionAndIsDisabled = getMergeFieldSelectionAndIsDisabled(disabledValues, personADisplayValue, personBDisplayValue);

  return {
    ...mergeField,
    personADisplayValue,
    personBDisplayValue,
    isPersonBSelected: initialSelectionAndIsDisabled.isPersonBSelected,
    isSelectionDisabled: initialSelectionAndIsDisabled.isSelectionDisabled
  };
};

export const getMergeFieldDisplayValue = (displayValueKey, personData) => {
  const personValue = getPersonDataValueByKey(displayValueKey, personData);

  switch (displayValueKey) {
    case MERGE_FIELD_BIRTH_DATE.displayValueKey:
      return personValue ? formatDate(personValue) : DISPLAY_VALUE_NO_INFO;

    case MERGE_FIELD_CONTACT_INFO.displayValueKey:
      return getContactInfoDisplayValue(personValue);

    case MERGE_FIELD_DISABILITY.displayValueKey:
      return getDisabilityDisplayValue(personValue);

    case MERGE_FIELD_PERSON_ETHNICITY.displayValueKey:
      return getPersonEthnicityDisplayValue(personValue);

    case MERGE_FIELD_IS_US_CITIZEN.displayValueKey:
      return getPersonIsUsCitizenDisplayValue(personValue);

    default:
      return personValue ? `${personValue}` : DISPLAY_VALUE_NO_INFO;
  }
};

function getContactInfoDisplayValue(contactInfo) {
  const name = `${contactInfo?.firstName || DISPLAY_VALUE_NO_INFO} ${contactInfo?.lastName || DISPLAY_VALUE_NO_INFO}`;
  const email = contactInfo?.emailAddress || DISPLAY_VALUE_NO_INFO;
  const phone = contactInfo?.phoneNumber ? formatPhoneNumber(contactInfo.phoneNumber) : DISPLAY_VALUE_NO_INFO;

  return `Primary Contact\n Name: ${name}\n Email: ${email}\n Phone: ${phone}`;
}

function getDisabilityDisplayValue(disability) {
  if (Array.isArray(disability) && disability.length > 0) {
    for (const disabilityType of DISABILITY_TYPES) {
      if (disability.findIndex((x) => x.disabilityTypeId === disabilityType.id) >= 0) {
        return disabilityType.name;
      }
    }
  }

  return DISPLAY_VALUE_NO_INFO;
}

function getPersonEthnicityDisplayValue(personEthnicity) {
  if (Array.isArray(personEthnicity) && personEthnicity.length > 0) {
    let ethnicityDisplayValue = '';

    for (let i = 0; i < personEthnicity.length; i++) {
      if (ethnicityDisplayValue === '') {
        ethnicityDisplayValue = `${personEthnicity[i].ethnicityTypeName || DISPLAY_VALUE_NO_INFO}`;
      } else if (i === personEthnicity.length - 1) {
        ethnicityDisplayValue += ` & ${personEthnicity[i].ethnicityTypeName || DISPLAY_VALUE_NO_INFO}`;
      } else {
        ethnicityDisplayValue += `, ${personEthnicity[i].ethnicityTypeName || DISPLAY_VALUE_NO_INFO}`;
      }
    }

    return ethnicityDisplayValue;
  }

  return DISPLAY_VALUE_NO_INFO;
}

function getPersonIsUsCitizenDisplayValue(isUsCitizen) {
  return isUsCitizen === true ? 'Yes' : isUsCitizen === false ? 'No' : DISPLAY_VALUE_NO_INFO;
}

function getMergeFieldDisabledValues(displayValueKey, personAData, personBData) {
  const DEFAULT_DISABLED_VALUES = [DISPLAY_VALUE_NO_INFO];

  switch (displayValueKey) {
    case MERGE_FIELD_COMPETITION_CATEGORY.displayValueKey:
      return personAData.isAthlete === true || personBData.isAthlete === true
        ? [DISPLAY_VALUE_UNKNOWN_GENDER, ...DEFAULT_DISABLED_VALUES]
        : DEFAULT_DISABLED_VALUES;

    case MERGE_FIELD_MIDDLE_NAME.displayValueKey:
    case MERGE_FIELD_PREFERRED_NAME.displayValueKey:
      return [];

    default:
      return DEFAULT_DISABLED_VALUES;
  }
}

function getMergeFieldSelectionAndIsDisabled(disabledValues, personADisplayValue, personBDisplayValue) {
  if (personADisplayValue === personBDisplayValue) {
    return {
      isPersonBSelected: true,
      isSelectionDisabled: true
    };
  }

  const disabledValueAIndex = disabledValues.findIndex((x) => x === personADisplayValue);
  const disabledValueBIndex = disabledValues.findIndex((x) => x === personBDisplayValue);

  if (disabledValueAIndex >= 0 && disabledValueBIndex >= 0) {
    return { // try to select the lowest priority disabled value
      isPersonBSelected: disabledValueBIndex >= disabledValueAIndex,
      isSelectionDisabled: true
    };
  } else if (disabledValueAIndex >= 0) {
    return {
      isPersonBSelected: true,
      isSelectionDisabled: true
    };
  } else if (disabledValueBIndex >= 0) {
    return {
      isPersonBSelected: false,
      isSelectionDisabled: true
    };
  } else {
    return {
      isPersonBSelected: false,
      isSelectionDisabled: false
    };
  }
}

function getPersonDataValueByKey(personValueKey, personData) {
  try {
    const keys = personValueKey.split('.');

    if (keys.length === 2) {
      const objectKey = keys[0];
      const valueKey = keys[1];

      return personData[objectKey][valueKey];
    } else if (keys.length === 1) {
      const valueKey = keys[0];

      return personData[valueKey];
    }
  } catch {
    return null;
  }

  return null;
}

const MemberMergeUtils = {
  createPersonMergeObj,
  getMergeFieldForFormGrid,
  getMergeFieldDisplayValue
};

export default MemberMergeUtils;