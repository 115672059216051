import { useContext } from 'react';

import PersonNationalTeamFileTypeData from './PersonNationalTeamFileTypeData';
import { PersonNationalTeamFileTypeStateContext } from './PersonNationalTeamFileTypeContextProvider';

const usePersonNationalTeamFileTypeData = () => {
  const [personNationalTeamFileTypeState, setPersonNationalTeamFileTypeState] = useContext(PersonNationalTeamFileTypeStateContext);

  const getPersonNationalTeamAttachmentFileTypes = () => {
    PersonNationalTeamFileTypeData.getPersonNationalTeamAttachmentFileTypes(personNationalTeamFileTypeState, setPersonNationalTeamFileTypeState);
  };

  return {
    personNationalTeamFileTypeState,
    setPersonNationalTeamFileTypeState,
    getPersonNationalTeamAttachmentFileTypes
  }
}


export default usePersonNationalTeamFileTypeData;