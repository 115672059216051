import { isValidDate, isValidBirthDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  //Birth Date
  if (formState.birthDate === Constants.BLANK_DATE_STRING) {
    errors.birthDate = 'Birth Date is required';
  } else if (!isValidDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date must be a valid date';
  } else if (!isValidBirthDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date cannot be in the future';
  }

  //Competition Category 
  if (!formState.competitionGenderTypeId) {
    errors.competitionGenderTypeId = 'Competition Category is required';
  }

  return errors;
};

const MemberAdvancedInfoValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MemberAdvancedInfoValidation;