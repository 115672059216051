import React from 'react';

import useMeetOfficialRoleTypeDropdown from './UseMeetOfficialRoleTypeDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const MeetOfficialRoleTypeDropdown = ({ label, name, value, error, message, onChange, orgLevelId, hasEvaluationForm  }) => {
  const { meetOfficialRoleTypeState } = useMeetOfficialRoleTypeDropdown(orgLevelId, hasEvaluationForm);

  return meetOfficialRoleTypeState.message
    ? <span className={global.LoadingMsg}>{meetOfficialRoleTypeState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={meetOfficialRoleTypeState.options}
        name={name}
        value={value}
        onChange={onChange}
        areValuesIntegers={true}
        isLoading={meetOfficialRoleTypeState.isArrayLoading} />
    );
};

export default MeetOfficialRoleTypeDropdown;