import { API_NAMES, initApi } from '../../utils/HttpApiHelper';

const getOrgGroupProperties = (groupTypeId, state, setState) => {
  if (groupTypeId > 0) {
    const newState = {
      ...state,
      groupTypeId
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);

    const groupTypeIdForUrl = groupTypeId ? encodeURIComponent(groupTypeId) : 'NaN';
    const url = `/OrgGroup/properties/${groupTypeIdForUrl}`;
    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const OrgGroupPropertiesData = { getOrgGroupProperties };

export default OrgGroupPropertiesData;