
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

import getPersonOrgRoleAffiliationsData from "./GetPersonOrgRoleAffiliationsData";
import putPersonOrgRoleAffiliationData from "./PutPersonOrgRoleAffiliationData";
import postPersonOrgRoleAffiliationData from "./PostPersonOrgRoleAffiliationData";

const PersonOrgRoleAffiliationsData = {
  INITIAL_STATE: BASIC_INITIAL_STATE,
  getPersonOrgRoleAffiliationsData,
  putPersonOrgRoleAffiliationData,
  postPersonOrgRoleAffiliationData
};

export default PersonOrgRoleAffiliationsData;