/* eslint-disable react/no-unescaped-entities */
import { Fragment } from 'react';

import useOtsMeetOfficialsOfficial from './UseOtsMeetOfficialsOfficial';

import MeetOfficialsOfficialGrid from './MeetOfficialsOfficialGrid';

import Headings from '../../../../../common/components/headings/Headings';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import SearchMemberWithLscPopup from '../../../../../common/components/searches/searchMember/SearchMemberWithLscPopup';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OtsMeetOfficialsOfficial = () => {
  const {
    meetOfficialSessionState,
    OFFICIALS_ROLES_ROLE_GROUP_NAME,
    isLoading,
    isSaving,
    Constants,
    formState,
    errorState,
    handleSubmit,
    setFormState,
    onFormValueChanged,
    onAddSessionRole,
    onEditSessionRole,
    onDeleteSessionRole,
    onBackClicked,
    modalState,
    onOpenModalClicked,
    onModalCanceled
  } = useOtsMeetOfficialsOfficial();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.selectedValidMemberId === true && meetOfficialSessionState.arrayData?.length > 0 ? 'Edit' : 'Add'} Meet Official</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        {formState.selectedValidMemberId === false &&
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4">
              <SearchMemberWithLscPopup
                label="Official Member ID"
                memberIdName="meetOfficialMemberId"
                formState={formState}
                errorState={errorState}
                setFormData={setFormState}
                onFormValueChanged={onFormValueChanged}
                roleGroupName={OFFICIALS_ROLES_ROLE_GROUP_NAME} />
            </div>
          </div>
        }
        {formState.selectedValidMemberId === true &&
          <div className="row">
            <div className="col-xs-12">
              <p><b>Official Name:</b>&nbsp;{formState.firstName || ''} {formState.lastName || ''}</p>
              <p><b>Member ID:</b>&nbsp;{formState.meetOfficialMemberId}</p>
            </div>
            <div className='col-xs-12 usas-extra-top-margin'>
              <ActionIntraPageButton onClick={onAddSessionRole}>Add Session Role</ActionIntraPageButton>
              <MeetOfficialsOfficialGrid
                state={meetOfficialSessionState}
                onEditSessionRole={onEditSessionRole}
                onDeleteSessionRole={onOpenModalClicked}
              />
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className='col-xs-12 usas-extra-top-margin'>
            {formState.selectedValidMemberId === false &&
              <Fragment><PrimaryButton type="submit">Save</PrimaryButton>&nbsp;</Fragment>}
            <SecondaryButton onClick={onBackClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Official Name:</b>&nbsp;{formState.firstName || ''} {formState.lastName || ''}</p>
            <p><b>Member ID:</b>&nbsp;{formState.meetOfficialMemberId}</p>
            <p><b>Session:</b> {modalState.session}</p>
            <p><b>OQM Session?</b> {modalState.oqmSession}</p>
            <p><b>Role Worked:</b> {modalState.roleWorked}</p>
            <p><b>Apprentice?</b> {modalState.isApprentice}</p>
            <p><b>Has an Evaluation?</b> {modalState.hasEvaluationForm === true ? 'Yes' : 'No'}</p>
          </div>
          {modalState.hasEvaluationForm === true && modalState.numberOfSessions === 1 &&
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>Unable to Delete Meet Official's Session Role - Official Roles with an evaluation cannot be deleted.</p>
              <p className={global.ErrorMessage}>To Delete this Meet Official's Session Role, their evaluation(s) associated with this Role must be deleted first.</p>
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            {(modalState.hasEvaluationForm === false || (modalState.hasEvaluationForm === true && modalState.numberOfSessions > 1)) &&
              <Fragment>
                <PrimaryButton type="button" onClick={(e) => onDeleteSessionRole(e, modalState.meetOfficialSessionId)}>Delete</PrimaryButton>&nbsp;
              </Fragment>}
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  )
}

export default OtsMeetOfficialsOfficial;