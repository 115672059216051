import { Fragment } from 'react';

import useProfileFunFactsDetail from './UseProfileFunFactsDetail';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Headings from '../../../../common/components/headings/Headings';
import Textarea from '../../../../common/components/inputs/Textarea';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const ProfileFunFactsDetail = () => {
  const {
    personProfileState,
    formState,
    errorState,
    funFactsTypeState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onSaveClicked,
    onCancelClicked
  } = useProfileFunFactsDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{formState.personFunFactsId > 0 ? 'Edit' : 'Add'} Fun Fact</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Fun Fact Name*"
              name="funFactsId"
              value={formState.funFactsId}
              error={errorState.funFactsId}
              message={errorState.funFactsId}
              isLoading={funFactsTypeState.isLoading}
              options={funFactsTypeState.dataAsOptionsWithNames}
              onChange={(newValue, newValueLabel) => { onValueTextPairChanged(newValue, 'funFactsId', newValueLabel, 'funFactsName'); }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <Textarea
              className={global.Textarea}
              label="Fun Fact Description*"
              name="textValue"
              value={formState.textValue}
              error={errorState.textValue}
              message={errorState.textValue}
              onChange={(value) => { onFormValueChanged('textValue', value); }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => onSaveClicked(e)}>Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={(e) => onCancelClicked(e)}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personProfileState.isObjLoading || funFactsTypeState.isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personProfileState.isSaving} />
    </Fragment>
  );
};

export default ProfileFunFactsDetail;