import { Fragment } from 'react';

import useLSCReportingHQReports from './UseLSCReportingHQReports';

import ReportList from '../../../../common/components/embeddedReports/ReportList';
import LeftArrowIcon from '../../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../../common/components/PageNotFound';

import global from '../../../../common/components/GlobalStyle.module.css'

const LSCReportingHQReports = () => {
  const {
    reportParameters,
    orgUnitId,
    orgUnitName,
    routeName,
    selectOrgUnitState,
    onBackToSelectionClicked
  } = useLSCReportingHQReports();

  if (!orgUnitId) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToSelectionClicked}>
              <LeftArrowIcon />  Back to Selection
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {selectOrgUnitState.showBackToSelection &&
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToSelectionClicked}>
              <LeftArrowIcon />  Back to Selection
            </button>
          </div>
        </div>}
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <p className={global.HeaderText}><strong>Viewing:</strong> {orgUnitName}</p>
        </div>
      </div>
      <ReportList listTitle="HQ LSC Reports"
        routeName={routeName}
        reportParameters={reportParameters} />
    </Fragment>
  );
};

export default LSCReportingHQReports;