import { useState, } from 'react';

import PersonCoursesData from './PersonCoursesData';

const usePersonCourses = () => {
  const [memberCoursesState, setMemberCoursesState] = useState(PersonCoursesData.INITIAL_STATE);
  const [memberCoursesAdminState, setMemberCoursesAdminState] = useState(PersonCoursesData.INITIAL_STATE);
  const [memberPostCoursesAdminState, setMemberPostCoursesAdminState] = useState(PersonCoursesData.INITIAL_STATE);
  const [memberPutCoursesAdminState, setMemberPutCoursesAdminState] = useState(PersonCoursesData.INITIAL_STATE);

  const getPersonCourses = (personId) => {
    PersonCoursesData.getPersonCourses(personId, memberCoursesState, setMemberCoursesState);
  };

  const getPersonCoursesForAdmin = (personId) => {
    PersonCoursesData.getPersonCoursesForAdmin(personId, memberCoursesAdminState, setMemberCoursesAdminState);
  };

  const postEnrollmentMemberCoursesAdmin = (courseObj) => {
    return PersonCoursesData.postEnrollmentMemberCoursesAdminData(courseObj, memberPostCoursesAdminState, setMemberPostCoursesAdminState);
  };

  const putMemberCourseEnrollmentAdmin = (courseEnrollmentId, courseObj) => {
    return PersonCoursesData.putMemberCourseEnrollmentAdminData(courseEnrollmentId, courseObj, memberPutCoursesAdminState, setMemberPutCoursesAdminState);
  };

  const resetMemberPostEnrollmentState = () => {
    setMemberPostCoursesAdminState({ ...PersonCoursesData.INITIAL_STATE });
  };

  const resetMemberPutEnrollmentState = () => {
    setMemberPutCoursesAdminState({ ...PersonCoursesData.INITIAL_STATE });
  };

  return {
    getPersonCourses,
    memberCoursesState,
    setMemberCoursesState,
    memberCoursesAdminState,
    setMemberCoursesAdminState,
    getPersonCoursesForAdmin,
    postEnrollmentMemberCoursesAdmin,
    memberPostCoursesAdminState,
    setMemberPostCoursesAdminState,
    putMemberCourseEnrollmentAdmin,
    memberPutCoursesAdminState,
    setMemberPutCoursesAdminState,
    resetMemberPostEnrollmentState,
    resetMemberPutEnrollmentState
  };
};

export default usePersonCourses;