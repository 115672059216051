import React, { Fragment } from 'react';

import global from '../../../../../../common/components/GlobalStyle.module.css';

import { formatDate, getTodaysDate } from '../../../../../../common/utils/DateFunctions';
import Checkbox from '../../../../../../common/components/checkboxes/Checkbox';

const SmallGridRow = ({ cert, formState, onRecertifyCheckboxChange, onAdvanceCheckboxChange }) => {
  const todayDate = getTodaysDate();
  const today = new Date(todayDate);
  const expirationDateLocal = new Date(cert?.expirationDate);

  if (formState?.showAllNationalCertificationApplication === false && (today <= expirationDateLocal)) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{cert?.positionName} - {cert?.positionAbbreviation}</span>&nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                Position
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {cert?.positionName} - {cert?.positionAbbreviation}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Natl Certification
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {cert?.certificationName || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Effective Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(cert?.effectiveDate)}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Expiration Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(cert?.expirationDate)}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Re-Certify
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                <Checkbox
                  label=""
                  name="isRecertifySelected"
                  onChange={(e) => { onRecertifyCheckboxChange(e, cert.personOfficialCertificationId) }}
                  checked={cert.isRecertifySelected} />
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Advance
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                <Checkbox
                  label=""
                  name="isAdvanceSelected"
                  onChange={(e) => { onAdvanceCheckboxChange(e, cert.personOfficialCertificationId) }}
                  checked={cert.isAdvanceSelected} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
  else if (formState?.showAllNationalCertificationApplication === true) {
    return (
      <Fragment>
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            <span className='hidden-xs'>{cert?.positionName} - {cert?.positionAbbreviation}</span>&nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className='row'>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
                Position
              </div>
              <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
                {cert?.positionName} - {cert?.positionAbbreviation}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Natl Certification
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {cert?.certificationName || <span>&nbsp;</span>}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Effective Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(cert?.effectiveDate)}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Expiration Date
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                {formatDate(cert?.expirationDate)}
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Re-Certify
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                <Checkbox
                  label=""
                  name="isRecertifySelected"
                  onChange={(e) => { onRecertifyCheckboxChange(e, cert.personOfficialCertificationId) }}
                  checked={cert.isRecertifySelected} />
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
                Advance
              </div>
              <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
                <Checkbox
                  label=""
                  name="isAdvanceSelected"
                  onChange={(e) => { onAdvanceCheckboxChange(e, cert.personOfficialCertificationId) }}
                  checked={cert.isAdvanceSelected} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  else {
    return (
      <Fragment />
    )
  }
}

const OtsOfficialNationalCertificationApplicationSmallWriteGrid = ({ state, gridState, formState, onRecertifyCheckboxChange, onAdvanceCheckboxChange }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(gridState?.gridData) && gridState?.gridData?.length > 0
      ? gridState?.gridData?.map((cert, i) =>
        <SmallGridRow
          key={i}
          cert={cert}
          formState={formState}
          onRecertifyCheckboxChange={onRecertifyCheckboxChange}
          onAdvanceCheckboxChange={onAdvanceCheckboxChange}
        />)
      : (
        <div>
          {state?.isArrayLoading
            ? (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>Loading...</div></div>
              </div>
            ) : (
              <div className={global.SmallTableRow}>
                <div className={global.SmallTableRowHead}>&nbsp;</div>
                <div className={global.SmallTableRowInfo}><div className={global.SmallTableRowLabels}>No Results</div></div>
              </div>
            )
          }
        </div>
      )
    }
  </div>
);

export default OtsOfficialNationalCertificationApplicationSmallWriteGrid;