import React, { createContext, useState } from 'react';

import ZoneData from './ZoneData';

export const ZoneStateContext = createContext();

const ZoneContextProvider = ({ children }) => {
  const stateHook = useState(ZoneData.INITIAL_STATE);

  return (
    <ZoneStateContext.Provider value={stateHook}>
      {children}
    </ZoneStateContext.Provider>
  );
};

export default ZoneContextProvider;