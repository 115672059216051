import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getPersonProfileData from './GetPersonProfileData';
import putPersonProfileData from './PutPersonProfileData';
import postPersonProfile from './PostPersonProfileData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonProfileData = {
  INITIAL_STATE, getPersonProfileData, putPersonProfileData, postPersonProfile
};

export default PersonProfileData;