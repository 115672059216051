import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

const GetOfficialPositionData = (positionType, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...',
      positionType: positionType
    };
    setState(newState);

    const positionTypeForUrl = positionType ? encodeURIComponent(positionType) : '';
    const url = positionTypeForUrl ? `/OfficialPosition?positionType=${positionTypeForUrl}` : `/OfficialPosition`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const data = JSON.parse(JSON.stringify(arrayData));
          const blankPosition = { officialPositionId: Constants.DEFAULT_ID };
          data.unshift(blankPosition);
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options: data.map((position) => { return { id: position.officialPositionId, name: position.officialPositionId !== Constants.DEFAULT_ID ? `${position.positionName || ''} (${position.positionAbbreviation || ''})` : '--' } }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetOfficialPositionData;