import React, { createContext, useState } from 'react';

import OtsMeetStaffData from './OtsMeetStaffData';

export const OtsMeetStaffStateContext = createContext();

const OtsMeetStaffContextProvider = ({ children }) => {
  const stateHook = useState(OtsMeetStaffData.INITIAL_STATE);

  return (
    <OtsMeetStaffStateContext.Provider value={stateHook}>
      {children}
    </OtsMeetStaffStateContext.Provider>
  );
};

export default OtsMeetStaffContextProvider;